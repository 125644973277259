import React, { FC, useState } from "react";

import TruncateLength from "util/TruncateLength";
import SourceLink from "components/atoms/SourceLink";
import {
  SOURCE_TYPES,
  renderSourceDate,
  renderSourceIcon
} from "components/molecules/SourceList/utils";

import { ImageSizeToUse } from "util/ImageSizeToUse";
import { InformationSource } from "api/report/report-types";
import S, { classNameOverrides } from "./styles";

interface Props {
  source: InformationSource;
}

const SourceItem: FC<Props> = ({ source }) => {
  const [hasImageErrored, setHasImageErrored] = useState(false);

  const isLackingImage = () => {
    const mainImageNotAvailable = !source?.image || hasImageErrored;
    const publisherImageNotAvailable =
      !source.publisher ||
      !SOURCE_TYPES[source.sourceType]?.publishers?.[source.publisher]?.logo;
    return mainImageNotAvailable && publisherImageNotAvailable;
  };

  const renderFallbackImage = () => {
    const { publisher } = source;
    if (!publisher) return null;
    return SOURCE_TYPES[source.sourceType]?.publishers?.[publisher]?.logo;
  };

  return (
    <>
      {/* @ts-ignore */}
      <SourceLink
        href={source?.url ?? ""}
        ariaLabel="Source item"
        popoverClassName={classNameOverrides.sourceItemLink}
        disabled={!source?.url}
      >
        <S.SourceItemContainer>
          <S.ItemIconContainer>
            {renderSourceIcon(source.sourceType, source.publisher)}
          </S.ItemIconContainer>
          <S.ItemContentsContainer>
            <S.ItemContents>
              <S.SourceTitle
                // @ts-ignore
                href={source?.url}
                rel="noopener noreferrer"
                target="_blank"
                noImage={isLackingImage()}
              >
                <TruncateLength>
                  {source?.title ?? <i>No title</i>}
                </TruncateLength>
              </S.SourceTitle>
              {renderSourceDate({
                sourceType: source.sourceType,
                dateAccessed: source?.dateAccessed,
                datePublished: source?.datePublished
              })}
              {!source?.url && !source?.isAvailableOnline ? (
                <S.SourceUrlPlaceholder>
                  Third-party cached data that does not have an online URL
                </S.SourceUrlPlaceholder>
              ) : (
                <S.SourceUrl
                  // @ts-ignore
                  href={source?.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  noImage={isLackingImage()}
                >
                  <TruncateLength>{source?.url}</TruncateLength>
                </S.SourceUrl>
              )}
            </S.ItemContents>
            {source?.image && !hasImageErrored ? (
              <S.SourceImage
                image={source.image}
                imageSizeToUse={ImageSizeToUse.Small}
                width="64px"
                height="64px"
                lazyLoad={false}
                onError={() => {
                  setHasImageErrored(true);
                }}
              />
            ) : (
              renderFallbackImage()
            )}
          </S.ItemContentsContainer>
        </S.SourceItemContainer>
      </SourceLink>
      {[...new Set(source?.snippets || [])].map(s =>
        typeof s === "string" ? <S.Quote>{s}</S.Quote> : null
      )}
    </>
  );
};

export default SourceItem;
