import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
  Fragment
} from "react";
import { observer } from "mobx-react";

import isEqual from "lodash.isequal";

import StackedBar from "components/molecules/StackedBar";
import {
  PRINTABLE_STATE_TYPES,
  usePrintableReportState
} from "util/hooks/usePrintableState";
import { ReactComponent as RightChevron } from "img/right-chevron.svg";
import { red, grey, blue, orange } from "styles/colors";
import { ButtonKind } from "components/atoms/Button/types";
import SectionFooter from "components/atoms/SectionFooter";
import { locationRiskText } from "util/locationRiskText";
import RiskPill from "components/atoms/RiskPill";
import { useReports } from "util/hooks/useReports";

import {
  CONFIDENCE_CATEGORIES,
  DOWJONES_CONVERSION
} from "util/confidenceCategories";
import { isPDX } from "static-config";
import ConfidenceDropDownMenu from "components/molecules/ConfidenceDropDownMenu";
import MediaResults from "./MediaResults";
import RiskOverTimeChart from "./RiskOverTimeChart";
import AdditionalFilters from "./AdditionalFilters";
import {
  FILTER_LABEL_MAPPINGS,
  FILTER_SECTION_IDS,
  RISK_CATEGORY_MAX_DEFAULT_SHOWN,
  HIGH_RISK_SITES_LABEL,
  RISK_DIRECTION_FILTER_OPTIONS,
  MEDIA_SOURCE_COLORS,
  NO_DATE_STRING,
  UNDATED_FILTER_ID
} from "./util";
import S from "./styles";

const WebAndMediaArticles = ({
  media,
  relevantSourceCount,
  isReportRegenerationOpen
}) => {
  const reportStore = useReports();
  const [selectedConfidenceFilter, setSelectedConfidenceFilter] = useState(
    CONFIDENCE_CATEGORIES.confirmed
  );

  const onConfidenceFilterSelected = selectedFilter => {
    setSelectedConfidenceFilter(selectedFilter);
  };
  const relevantSourceGroups = reportStore.webAndMediaData;
  const nonRelevantSourceGroups = reportStore.riskyWebAndMediaData;

  const broadNonRelevantSourceGroups = nonRelevantSourceGroups.filter(n => {
    return n.dowJonesMatchType === "Broad";
  });
  const nearNonRelevantSourceGroups = nonRelevantSourceGroups.filter(n => {
    return n.dowJonesMatchType === "Near";
  });

  const [isMediaResultsShown, setIsMediaResultsShown] = usePrintableReportState(
    "web-and-media-expanded",
    false,
    PRINTABLE_STATE_TYPES.sectionExpand
  );
  const [isAdditionalFiltersExpanded, setIsAdditionalFiltersExpanded] =
    usePrintableReportState("web-and-media-additional-filters-expanded", true);

  const [hasFilterBeenActive, setHasFilterBeenActive] = useState(false);

  const [activeFilters, setActiveFilters] = usePrintableReportState(
    "web-and-media-active-filter-id",
    []
  );

  const [isResultsExpanded, setIsResultsExpanded] = usePrintableReportState(
    "web-and-media-expanded-fully",
    false,
    PRINTABLE_STATE_TYPES.sectionExpand
  );

  const [isDirectRiskCategoriesExpanded, setIsDirectRiskCategoriesExpanded] =
    useState(false);

  const [
    isIndirectRiskCategoriesExpanded,
    setIsIndirectRiskCategoriesExpanded
  ] = useState(false);

  const [mediaBySourceData, setMediaBySourceData] = useState({
    mediaSourceBuckets: {},
    mediaSourceColors: {}
  });

  const [mediaByRiskOverTimeData, setMediaByRiskOverTimeData] = useState({});

  const [mediaByRiskDirectionData, setMediaByRiskDirectionData] = useState({
    riskDirectionBuckets: {},
    directRiskCategoryBuckets: {},
    indirectRiskCategoryBuckets: {}
  });
  const [mediaByLanguagesData, setMediaByLanguagesData] = useState({});

  const [mediaByOrganisationsData, setMediaByOrganisationsData] = useState({
    organisations: {},
    namesToVariants: {}
  });

  const [mediaByPeopleData, setMediaByPeopleData] = useState({
    people: {},
    namesToVariants: {}
  });

  const [mediaSourceColors, setMediaSourceColors] = useState({});

  const [mediaByLocationsData, setMediaByLocationsData] = useState({
    locations: {},
    nameToVariants: {}
  });

  const [hasDirectRiskCategories, setHasDirectRiskCategories] = useState();
  const [hasIndirectRiskCategories, setHasIndirectRiskCategories] = useState();

  // list of articles state seems redundant - is always the same as relevantSourceGroups ~joseph
  const [listOfArticles, setListOfArticles] = useState(relevantSourceGroups);

  const [filteredArticleIds, setFilteredArticleIds] = useState(
    listOfArticles.map(article => article.sourceId)
  );

  const [sourceGroups, setSourceGroups] = useState(
    filteredArticleIds.map(articleId =>
      listOfArticles.find(article => article.sourceId === articleId)
    )
  );

  const activeFiltersRef = useRef();
  activeFiltersRef.current = activeFilters;

  const resultsSectionRef = useRef(null);
  const webAndMediaSectionRef = useRef(null);
  const mediaByRiskOverTimeDataRef = useRef();
  mediaByRiskOverTimeDataRef.current = mediaByRiskOverTimeData;

  useEffect(() => {
    if (activeFilters.length && !hasFilterBeenActive) {
      setHasFilterBeenActive(true);
    }
  }, [activeFilters, hasFilterBeenActive]);

  useEffect(() => {
    setListOfArticles(relevantSourceGroups);
  }, [relevantSourceGroups]);

  /**
   * Extracts the filter string's components into an array. e.g.:
   * "mediaBySource:newsAndMedia" -> ["mediaBySource", "newsAndMedia"]
   * @param {string} filterString colon-separated string containing the filter's type and name
   * @returns array of the filter's type and name
   */
  const getActiveFilterComponents = filterString => {
    const [filterType, filterSelection] = filterString.split(":");

    return [filterType, filterSelection];
  };

  /**
   * Retrieve the data necessary to render the filter breadcrumbs
   * @returns array of data for each filter
   */
  const getFilterPillsData = () => {
    const filterPillsData = activeFilters.map(filter => {
      const [filterType, filterSelection] = getActiveFilterComponents(filter);
      let pillData = {};

      if (
        filterType === FILTER_SECTION_IDS.mediaBySource ||
        filterType === FILTER_SECTION_IDS.mediaByIndirectRiskCategory ||
        filterType === FILTER_SECTION_IDS.mediaByDirectRiskCategory
      ) {
        const sourceKeys = filterSelection.split("/");
        pillData = {
          label: (
            <S.SourceFilterPill>
              {sourceKeys.map((key, index, keys) => {
                const isLastLabel = index === keys.length - 1;
                return (
                  <Fragment key={key}>
                    <S.SourceFilterPillLabelContainer>
                      <S.SourceFilterPillLabel isLastLabel={isLastLabel}>
                        {FILTER_LABEL_MAPPINGS[key] ?? key}
                      </S.SourceFilterPillLabel>
                      {!isLastLabel && (
                        <RightChevron height="11px" width="11px" />
                      )}
                    </S.SourceFilterPillLabelContainer>
                  </Fragment>
                );
              })}
            </S.SourceFilterPill>
          ),
          id: filterSelection,
          section: filterType
        };

        if (filterType === FILTER_SECTION_IDS.mediaBySource) {
          pillData.color = mediaBySourceData.mediaSourceColors[filterSelection];
        } else if (
          filterType === FILTER_SECTION_IDS.mediaByIndirectRiskCategory
        ) {
          pillData.color = orange.indirectRiskFill;
        } else if (
          filterType === FILTER_SECTION_IDS.mediaByDirectRiskCategory
        ) {
          pillData.color = red.directRiskFill;
        }
      } else if (filterType === FILTER_SECTION_IDS.mediaByRiskDirection) {
        pillData = {
          color: RISK_DIRECTION_FILTER_OPTIONS[filterSelection].color,
          label: RISK_DIRECTION_FILTER_OPTIONS[filterSelection].label,
          id: filterSelection,
          section: filterType
        };
      } else if (
        filterType === FILTER_SECTION_IDS.mediaByOrganisations ||
        filterType === FILTER_SECTION_IDS.mediaByLanguages ||
        filterType === FILTER_SECTION_IDS.mediaByPeople ||
        filterType === FILTER_SECTION_IDS.mediaByLocations
      ) {
        pillData = {
          color: blue.icon,
          label: filterSelection,
          id: filterSelection,
          section: filterType
        };
      } else if (filterType === FILTER_SECTION_IDS.mediaByTime) {
        pillData = {
          color: grey.ghost,
          label: `${
            filterSelection === UNDATED_FILTER_ID ? "No date" : "Date range"
          }`,
          id: filterSelection,
          section: filterType
        };
      }

      return pillData;
    });

    return filterPillsData;
  };

  const getAdditionalFiltersForFilterType = useCallback(
    filterType => {
      return activeFilters.reduce((acc, filter) => {
        const [section, id] = getActiveFilterComponents(filter);
        if (section === filterType) {
          return [...acc, id];
        }

        return acc;
      }, []);
    },
    [activeFilters]
  );

  // Propagate changes to tags if and when tags are deleted
  useEffect(() => {
    // Get source id to tags mapping
    const sourceToTagsMap = {};
    // This was changed for DJ and needs to be checked
    listOfArticles?.forEach(source => {
      sourceToTagsMap[source.sourceId] = source.tags;
    });

    const updatedSourceGroups = sourceGroups.map(source => {
      const sourceObj = { ...source };
      sourceObj.tags = sourceToTagsMap[source.sourceId] ?? source.tags;

      return sourceObj;
    });

    if (!isEqual(updatedSourceGroups, sourceGroups)) {
      setSourceGroups(updatedSourceGroups);
    }
  }, [listOfArticles, sourceGroups]);

  /**
   * Iterates over the articles in the current filter set
   * and estasblishes, which article is part of a collection of
   * stories/dupes, and then filters accordingly.
   */
  const sourceGroupsWithStories = useMemo(() => {
    const result = [];
    sourceGroups.forEach((article, index, articles) => {
      // If the article has no parent, then we don't need
      // to go looking for one
      if (article.parentSourceId === undefined) {
        // Filter out storySources that are not in the filtered
        // set of articles.
        const filteredStorySources =
          article.storySources?.filter(s =>
            articles.find(g => g.sourceId === s.sourceId)
          ) ?? [];

        result.push({
          ...article,
          // Ensure we include this parent inside the storySources array.
          // The Card component relies on this.
          storySources: [{ ...article }, ...filteredStorySources]
        });
      } else {
        // If article has a parent, then we need to handle the parent.
        const isParentInFilteredArticles =
          articles.findIndex(g => g.sourceId === article.parentSourceId) >= 0;
        const isParentInResults =
          result.findIndex(r => r.sourceId === article.parentSourceId) >= 0;

        // If we haven't already accounted for this parent article...
        if (!isParentInResults) {
          // Find the parent object
          const parentObj = listOfArticles.find(
            source => source.sourceId === article.parentSourceId
          );
          const copiedParent = { ...parentObj };
          const filteredStorySources = [];

          // If parent is included in filter set of articles...
          if (isParentInFilteredArticles) {
            // ...then include itself in its list of storySources
            filteredStorySources.push(copiedParent);
          }

          // Filter the parent's list of children articles to those that
          // are included in the filtered set of articles.
          copiedParent.storySources.forEach(s => {
            if (articles.find(g => g.sourceId === s.sourceId)) {
              filteredStorySources.push(s);
            }
          });

          result.push({
            ...copiedParent,
            storySources: filteredStorySources
          });
        }
      }
    });

    return result;
  }, [sourceGroups, listOfArticles]);

  const transformBucketSetToArray = bucket => {
    const result = {};
    Object.keys(bucket).forEach(key => {
      result[key] = Array.from(bucket[key]);
    });

    return result;
  };

  const determineDirectionOfSourceRisk = (tag, currentFilters) => {
    const results = [];
    let activeRiskCategoryFilterId;
    let isDirectRiskPresent = false;
    let isIndirectRiskPresent = false;

    // Find the active risk category filter if we have one.
    currentFilters.some(filter => {
      const [filterType, filterId] = getActiveFilterComponents(filter);

      if (
        filterType === FILTER_SECTION_IDS.mediaByDirectRiskCategory ||
        filterType === FILTER_SECTION_IDS.mediaByIndirectRiskCategory
      ) {
        activeRiskCategoryFilterId = filterId;
        return true;
      }

      return false;
    });

    // If we have an active risk category filter then...
    if (activeRiskCategoryFilterId) {
      // Search through the risk hierarchies to see if one matches
      // the active risk category filter.
      const riskHierarchyIsSelectedRiskCategory = tag.riskHierarchies.some(
        hierarchy => {
          const truncatedPathArray = hierarchy.slice(
            0,
            activeRiskCategoryFilterId.split("/").length
          );

          return truncatedPathArray.join("/") === activeRiskCategoryFilterId;
        }
      );

      // If the hierarchy is captured by the active risk category
      // filter then...
      if (riskHierarchyIsSelectedRiskCategory) {
        // ...capture the directionality of the risk in the tag
        if (!tag.isNotCloseToSubject) {
          isDirectRiskPresent = true;
        } else {
          isIndirectRiskPresent = true;
        }
      }
    } else if (!tag.isNotCloseToSubject) {
      isDirectRiskPresent = true;
    } else {
      isIndirectRiskPresent = true;
    }

    results.push(isDirectRiskPresent, isIndirectRiskPresent);

    return results;
  };

  /**
   * Bucket sources into hierarchical groupings e.g. source provenance and risk categories
   * @param {string[]} dataHierarchy the hierarchy as a list of strings, left to right.
   * @param {string} sourceId ID of the article/source to bucket
   * @param {object} targetDataBucket The bucket object that will contain the source IDs
   * @param {string[]} currentFilters Current selected filters
   * @returns null
   */
  const bucketHierarchicalData = (
    dataHierarchy,
    sourceId,
    targetDataBucket,
    currentFilters,
    articleIds
  ) => {
    const [parentType, ...childrenTypes] = dataHierarchy || [];
    const shouldSourceBeIncluded =
      !currentFilters.length || articleIds.includes(sourceId);
    const sourceBucket = targetDataBucket;

    if (!shouldSourceBeIncluded) {
      return;
    }

    if (!parentType) {
      if (sourceBucket.Other) {
        sourceBucket.Other.add(sourceId);
      }
      return;
    }

    const sourcePathArray = childrenTypes;
    let sourcePathString = parentType;

    if (!sourceBucket[parentType]) {
      sourceBucket[parentType] = new Set();
    }
    sourceBucket[parentType].add(sourceId);

    // Loop over each of the children and produce a string of the format
    // "parent/child/child/child". This will be the key to use in the bucket.
    sourcePathArray.forEach(path => {
      // Attach the latest path to the string
      sourcePathString = `${sourcePathString}/${path}`;
      if (!sourceBucket[sourcePathString]) {
        sourceBucket[sourcePathString] = new Set();
      }

      // Be sure to push this sourceId to this sub-section of the path
      // This is important as we display the counts at each tier of
      // source filters.
      sourceBucket[sourcePathString].add(sourceId);
    });
  };

  /**
   * ============ Bucket functions begin ============
   *
   * We bucket, up front, the sources by each filter type.
   * This is a performance consideration so that each time
   * we filter we don't have to trawl through the data; instead
   * we just look up the relevant filter bucket, extract the source
   * IDs and then render the list of sources
   */

  /**
   * Buckets the source item based on risk direction e.g. direct, indirect
   * or no risk.
   */
  const bucketSourceByRiskDirection = useCallback(
    (source, buckets, currentFilters, articleIds) => {
      let doesSourceContainDirectRisk = false;
      let doesSourceContainIndirectRisk = false;
      // Only include the source if there's no filter active OR
      // the source is in the currently filtered set of articles.
      const shouldSourceBeIncluded =
        !currentFilters.length || articleIds.includes(source.sourceId);

      if (!shouldSourceBeIncluded) {
        return;
      }

      source.tags.forEach(tag => {
        if (
          tag.tagId === "risk" ||
          tag.tagId === locationRiskText.siteRisk.label
        ) {
          // We have to check if there is an active risk category filter/
          // This will determine if this source contains direct or indirect
          // risk. E.g. if an article contains direct ESG risk and indirect
          // crime-judicial risk, and we filter by ESG then this source
          // only has indirect risk as far as the filters are concerned.
          const [isRiskDirect, isRiskIndirect] = determineDirectionOfSourceRisk(
            tag,
            currentFilters
          );

          // Only set the direction if we haven't yet found one with
          // direct risk, otherwise subsequent loops could override a
          // true value with false.
          if (!doesSourceContainDirectRisk) {
            doesSourceContainDirectRisk = isRiskDirect;
          }

          if (!doesSourceContainIndirectRisk) {
            doesSourceContainIndirectRisk = isRiskIndirect;
          }

          // Given we're already looping through the risk tags
          // we may as well bucket the categories at the same time
          if (tag.riskHierarchies) {
            tag.riskHierarchies.forEach(hierarchy => {
              let activeRiskDirectionFilterId;
              const riskDirectionFilter = currentFilters.some(filter => {
                const [filterType, filterId] =
                  getActiveFilterComponents(filter);

                if (filterType === FILTER_SECTION_IDS.mediaByRiskDirection) {
                  activeRiskDirectionFilterId = filterId;
                  return true;
                }

                return false;
              });

              // If there is a risk direction filter currently active, like
              // direct risk, then we need to ensure the risk tag in
              // question is an example of direct risk in the source,
              // and not indirect risk and vice versa.
              if (riskDirectionFilter) {
                const isTagDirectRiskAndDirectRiskFilterIsActive =
                  !tag.isNotCloseToSubject &&
                  activeRiskDirectionFilterId ===
                    RISK_DIRECTION_FILTER_OPTIONS.directRisk.id;
                const isTagIndirectRiskAndIndirectRiskFilterIsActive =
                  tag.isNotCloseToSubject &&
                  activeRiskDirectionFilterId ===
                    RISK_DIRECTION_FILTER_OPTIONS.indirectRisk.id;

                if (isTagDirectRiskAndDirectRiskFilterIsActive) {
                  bucketHierarchicalData(
                    hierarchy,
                    source.sourceId,
                    buckets.directRiskCategoryBuckets,
                    currentFilters,
                    articleIds
                  );
                } else if (isTagIndirectRiskAndIndirectRiskFilterIsActive) {
                  bucketHierarchicalData(
                    hierarchy,
                    source.sourceId,
                    buckets.indirectRiskCategoryBuckets,
                    currentFilters,
                    articleIds
                  );
                }
              } else {
                bucketHierarchicalData(
                  hierarchy,
                  source.sourceId,
                  tag.isNotCloseToSubject
                    ? buckets.indirectRiskCategoryBuckets
                    : buckets.directRiskCategoryBuckets,
                  currentFilters,
                  articleIds
                );
              }
            });
          }
        }
      });

      if (doesSourceContainDirectRisk) {
        buckets.riskDirectionBuckets.directRisk.push(source.sourceId);
      }

      // You'll be adding just those with direct risk again to this bucket
      // as well as those with indirect risk.

      // You need to be able to detect indirect risk, rather than treating
      // it as a fallback.
      if (doesSourceContainIndirectRisk) {
        buckets.riskDirectionBuckets.indirectRisk.push(source.sourceId);
      }

      if (!doesSourceContainDirectRisk && !doesSourceContainIndirectRisk) {
        buckets.riskDirectionBuckets.noRisk.push(source.sourceId);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredArticleIds]
  );

  /**
   * Buckets the source item based on its provenance e.g. News & Media.
   */
  const bucketSourceByType = useCallback(
    (source, buckets, currentFilters, articleIds) => {
      bucketHierarchicalData(
        source.sourceHierarchy,
        source.sourceId,
        buckets.mediaSourceBuckets,
        currentFilters,
        articleIds
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredArticleIds]
  );

  /**
   * Buckets the source by risk based on its date and time.
   */
  const bucketSourceByRiskOverTime = useCallback(
    (source, buckets, currentFilters, articleIds) => {
      let isDirectRisk = false;
      let isRiskPresent = false;
      const date = source.publicationDate
        ? new Date(source.publicationDate)
        : NO_DATE_STRING;
      const shouldSourceBeIncluded =
        !currentFilters.length || articleIds.includes(source.sourceId);
      const sourceBuckets = buckets;

      if (!shouldSourceBeIncluded) {
        return;
      }

      source.tags.some(tag => {
        // Consider both risk tags and tags that reference a risk-based
        // site like FBI Most Wanted.
        if (
          tag.tagId === "risk" ||
          tag.tagId === locationRiskText.siteRisk.label
        ) {
          isRiskPresent = true;

          if (!tag.isNotCloseToSubject) {
            isDirectRisk = true;
            return true;
          }
        }

        return false;
      });

      if (!sourceBuckets[date]) {
        sourceBuckets[date] = {
          directRisk: 0,
          indirectRisk: 0,
          noRisk: 0,
          year: date ?? NO_DATE_STRING,
          directRiskSourcesIds: [],
          indirectRiskSourcesIds: [],
          noRiskSourcesIds: []
        };
      }

      let activeRiskDirectionFilterId;
      currentFilters.some(filter => {
        const [filterType, filterId] = getActiveFilterComponents(filter);

        if (filterType === FILTER_SECTION_IDS.mediaByRiskDirection) {
          activeRiskDirectionFilterId = filterId;
          return true;
        }

        return false;
      });

      if (
        isDirectRisk &&
        (!activeRiskDirectionFilterId ||
          (activeRiskDirectionFilterId &&
            activeRiskDirectionFilterId === "directRisk"))
      ) {
        sourceBuckets[date].directRisk += 1;
        sourceBuckets[date].directRiskSourcesIds.push(source.sourceId);
      } else if (
        isRiskPresent &&
        (!activeRiskDirectionFilterId ||
          (activeRiskDirectionFilterId &&
            activeRiskDirectionFilterId === "indirectRisk"))
      ) {
        sourceBuckets[date].indirectRisk += 1;
        sourceBuckets[date].indirectRiskSourcesIds.push(source.sourceId);
      } else if (
        !activeRiskDirectionFilterId ||
        (activeRiskDirectionFilterId &&
          activeRiskDirectionFilterId === "noRisk")
      ) {
        sourceBuckets[date].noRisk += 1;
        sourceBuckets[date].noRiskSourcesIds.push(source.sourceId);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredArticleIds]
  );

  const bucketSourceByLanguage = useCallback(
    (source, buckets, currentFilters, articleIds) => {
      const shouldSourceBeIncluded =
        !currentFilters.length || articleIds.includes(source.sourceId);
      const sourceBuckets = buckets;

      if (!shouldSourceBeIncluded) {
        return;
      }

      if (!source.detectedLanguage) {
        if (!sourceBuckets.English) {
          sourceBuckets.English = [];
        }
        sourceBuckets.English.push(source.sourceId);
        return;
      }

      if (!sourceBuckets[source.detectedLanguage]) {
        sourceBuckets[source.detectedLanguage] = [];
      }

      sourceBuckets[source.detectedLanguage].push(source.sourceId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredArticleIds]
  );

  const bucketSourceByOrganisations = useCallback(
    (source, buckets, currentFilters, articleIds) => {
      const shouldSourceBeIncluded =
        !currentFilters.length || articleIds.includes(source.sourceId);
      const sourceBuckets = buckets;

      source.tags.forEach(tag => {
        if (
          tag.entity &&
          tag.entity.type === "organisation" &&
          tag.entity.id !== undefined
        ) {
          if (!shouldSourceBeIncluded) {
            if (!sourceBuckets[tag.entity.text]) {
              sourceBuckets[tag.entity.text] = [];
            }
            return;
          }

          if (!sourceBuckets.organisations[tag.entity.text]) {
            sourceBuckets.organisations[tag.entity.text] = [];
          }
          sourceBuckets.organisations[tag.entity.text].push(source.sourceId);

          const nameVariants = tag.entity.longerFormText ?? "";

          // Inconsistent spacing in the data so we need to remove all the spaces to do the comparison.
          if (
            nameVariants.replace(/\s/g, "") !==
            tag.entity.text.replace(/\s/g, "")
          ) {
            sourceBuckets.namesToVariants[tag.entity.text] = nameVariants;
          } else {
            // If the variants are the same as just the solo name
            // then don't include any variants.
            sourceBuckets.namesToVariants[tag.entity.text] = "";
          }
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredArticleIds]
  );

  const bucketSourceByPeople = useCallback(
    (source, buckets, currentFilters, articleIds) => {
      const shouldSourceBeIncluded =
        !currentFilters.length || articleIds.includes(source.sourceId);
      const sourceBuckets = buckets;

      source.tags.forEach(tag => {
        if (
          tag.entity &&
          tag.entity.type === "person" &&
          tag.entity.id !== undefined
        ) {
          if (!shouldSourceBeIncluded) {
            if (!sourceBuckets[tag.entity.text]) {
              sourceBuckets[tag.entity.text] = [];
            }
            return;
          }

          if (!sourceBuckets.people[tag.entity.text]) {
            sourceBuckets.people[tag.entity.text] = [];
          }
          sourceBuckets.people[tag.entity.text].push(source.sourceId);

          const nameVariants = tag.entity.longerFormText ?? "";

          // Inconsistent spacing in the data so we need to remove all the spaces to do the comparison.
          if (
            nameVariants.replace(/\s/g, "") !==
            tag.entity.text.replace(/\s/g, "")
          ) {
            sourceBuckets.namesToVariants[tag.entity.text] = nameVariants;
          } else {
            // If the variants are the same as just the solo name
            // then don't include any variants.
            sourceBuckets.namesToVariants[tag.entity.text] = "";
          }
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredArticleIds]
  );

  const bucketSourceByLocation = useCallback(
    (source, buckets, currentFilters, articleIds) => {
      const shouldSourceBeIncluded =
        !currentFilters.length || articleIds.includes(source.sourceId);
      const sourceBuckets = buckets;

      source.tags.forEach(tag => {
        if (
          tag.entity &&
          tag.entity.type === "location" &&
          tag.entity.id !== undefined
        ) {
          if (!shouldSourceBeIncluded) {
            if (!sourceBuckets[tag.entity.text]) {
              sourceBuckets[tag.entity.text] = [];
            }
            return;
          }

          if (!sourceBuckets.locations[tag.entity.text]) {
            sourceBuckets.locations[tag.entity.text] = [];
          }
          sourceBuckets.locations[tag.entity.text].push(source.sourceId);

          const nameVariants = tag.entity.longerFormText ?? "";

          // Inconsistent spacing in the data so we need to remove all the spaces to do the comparison.
          if (
            nameVariants.replace(/\s/g, "") !==
            tag.entity.text.replace(/\s/g, "")
          ) {
            sourceBuckets.namesToVariants[tag.entity.text] = nameVariants;
          } else {
            // If the variants are the same as just the solo name
            // then don't include any variants.
            sourceBuckets.namesToVariants[tag.entity.text] = "";
          }
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredArticleIds]
  );

  /**
   * Orchestration function to call each of the above bucketing functions
   * for each of the sources.
   */
  const bucketDataByFilters = useCallback(
    ({ currentFilters = [], articleIds = filteredArticleIds }) => {
      const riskOverTimeBuckets = {};
      const riskBuckets = {
        riskDirectionBuckets: {
          directRisk: [],
          indirectRisk: [],
          noRisk: []
        },
        directRiskCategoryBuckets: {},
        indirectRiskCategoryBuckets: {}
      };
      const sourceBuckets = {
        mediaSourceBuckets: {
          Other: new Set()
        },
        mediaSourceColors: {
          Other: "#4b4d56"
        }
      };
      const languageBuckets = {};
      const organisationBuckets = {
        organisations: {},
        namesToVariants: {}
      };
      const peopleBuckets = {
        people: {},
        namesToVariants: {}
      };
      const locationBuckets = {
        locations: {},
        namesToVariants: {}
      };

      listOfArticles.forEach(source => {
        bucketSourceByRiskOverTime(
          source,
          riskOverTimeBuckets,
          currentFilters,
          articleIds
        );
        bucketSourceByRiskDirection(
          source,
          riskBuckets,
          currentFilters,
          articleIds
        );
        bucketSourceByType(source, sourceBuckets, currentFilters, articleIds);
        bucketSourceByLanguage(
          source,
          languageBuckets,
          currentFilters,
          articleIds
        );
        bucketSourceByOrganisations(
          source,
          organisationBuckets,
          currentFilters,
          articleIds
        );
        bucketSourceByPeople(source, peopleBuckets, currentFilters, articleIds);
        bucketSourceByLocation(
          source,
          locationBuckets,
          currentFilters,
          articleIds
        );
      });

      riskBuckets.directRiskCategoryBuckets = transformBucketSetToArray(
        riskBuckets.directRiskCategoryBuckets
      );
      riskBuckets.indirectRiskCategoryBuckets = transformBucketSetToArray(
        riskBuckets.indirectRiskCategoryBuckets
      );

      sourceBuckets.mediaSourceBuckets = transformBucketSetToArray(
        sourceBuckets.mediaSourceBuckets
      );

      if (
        !Object.keys(mediaSourceColors).length &&
        Object.keys(sourceBuckets.mediaSourceBuckets).length
      ) {
        let colorIndex = 0;
        Object.keys(sourceBuckets.mediaSourceBuckets).forEach(b => {
          const bucketKeyArray = b.split("/");
          // If we're dealing with a root source
          if (bucketKeyArray.length === 1) {
            let colorToUse;
            if (!sourceBuckets.mediaSourceColors[b]) {
              if (b === "Other") {
                colorToUse = sourceBuckets.mediaSourceColors.Other;
              } else if (bucketKeyArray[0] === HIGH_RISK_SITES_LABEL) {
                colorToUse = red.directRisk;
              } else {
                colorToUse =
                  MEDIA_SOURCE_COLORS[
                    colorIndex % (MEDIA_SOURCE_COLORS.length - 1)
                  ];
                // Only update the index if we've had to choose a new colour.
                // If we're dealing with a child then we'll just use the parent's
                // colour.
                colorIndex += 1;
              }
              sourceBuckets.mediaSourceColors[b] = colorToUse;
            }
            // If the root parent of this child node does not yet have a color
            // then set it.
          } else if (!sourceBuckets.mediaSourceColors[bucketKeyArray[0]]) {
            const colorToUse =
              MEDIA_SOURCE_COLORS[
                colorIndex % (MEDIA_SOURCE_COLORS.length - 1)
              ];
            sourceBuckets.mediaSourceColors[bucketKeyArray[0]] = colorToUse;
            // Now set this child node's colour to same the colour.
            sourceBuckets.mediaSourceColors[b] = colorToUse;
            colorIndex += 1;
          } else {
            // Otherwise just use the colour given to the root parent node.
            sourceBuckets.mediaSourceColors[b] =
              sourceBuckets.mediaSourceColors[bucketKeyArray[0]];
          }
        });
      } else {
        sourceBuckets.mediaSourceColors = mediaSourceColors;
      }

      return {
        riskBuckets,
        sourceBuckets,
        riskOverTimeBuckets,
        languageBuckets,
        peopleBuckets,
        organisationBuckets,
        locationBuckets
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      bucketSourceByRiskOverTime,
      bucketSourceByRiskDirection,
      bucketSourceByType,
      bucketSourceByLanguage,
      bucketSourceByOrganisations,
      bucketSourceByPeople,
      bucketSourceByLocation,
      listOfArticles,
      mediaSourceColors
    ]
  );

  /**
   * ============ Bucket functions end ============
   */

  /**
   * The key useEffect that listens for changes to state and props and
   * updates the buckets accordingly. In updating the buckets, the filter
   * counts will also update.
   *
   * The pattern used for data flow, in this component, is:
   * 1. Select a filter
   * 2. Fetch the IDs associated with that filter (in the buckets)
   * 3. Use those IDs to filter down the IDs for each bucket
   * 4. Render the results using the filtered IDs
   */
  useEffect(() => {
    const {
      riskOverTimeBuckets,
      riskBuckets,
      sourceBuckets,
      languageBuckets,
      organisationBuckets,
      peopleBuckets,
      locationBuckets
    } = bucketDataByFilters({ currentFilters: activeFilters });

    setMediaByRiskOverTimeData(riskOverTimeBuckets);
    setMediaByRiskDirectionData(riskBuckets);
    setMediaBySourceData(sourceBuckets);
    setMediaByLanguagesData(languageBuckets);
    setMediaByOrganisationsData(organisationBuckets);
    setMediaByPeopleData(peopleBuckets);
    setMediaSourceColors(sourceBuckets.mediaSourceColors);
    setMediaByLocationsData(locationBuckets);

    /**
     * I needed to cover two cases (this covers the first case, storing the initial state of the risk cats):
     * 1. There are both direct and indirect risk cats present, but the user has filtered to just direct risk.
     * In that case don't show the "none identified" placeholder.
     * 2. There was only, for example, direct risk cats present to begin with. In that case, display the
     * "none identified" placeholder for the indirect risks section, as we genuinely, didn't find anything.
     */
    if (
      hasDirectRiskCategories === undefined &&
      hasIndirectRiskCategories === undefined
    ) {
      setHasDirectRiskCategories(
        Object.keys(riskBuckets.directRiskCategoryBuckets)?.length > 0
      );
      setHasIndirectRiskCategories(
        Object.keys(riskBuckets.indirectRiskCategoryBuckets)?.length > 0
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfArticles, activeFilters, filteredArticleIds, bucketDataByFilters]);

  const simulateReapplyingAllFiltersToArticleIds = filters => {
    /**
     * To remove a filter we have to:
     * 1. Simulate removing all the filters to get the original buckets
     *
     * 2. Loop through the now updated filters and for each filter reapply
     *    it to the local set of buckets.
     *
     * 3. Rebucket the new list of article IDs from the current loop iteration.
     *
     * 4. Once the loop has finished the latest set of filters has been re-applied
     *    and you have your list of filtered article IDs. Setting these in state will
     *    trigger the useEffect to re-compute the buckets, which will update all
     *    the filter counts in the app.
     */

    let {
      riskOverTimeBuckets: mediaByRiskOverTime,
      riskBuckets: riskChartData,
      sourceBuckets: mediaBySource,
      languageBuckets: mediaByLanguages,
      organisationBuckets: mediaByOrganisations,
      peopleBuckets: mediaByPeople,
      locationBuckets: mediaByLocations
    } = bucketDataByFilters({});

    let copyOfFilteredArticleIds = [
      ...listOfArticles.map(article => article.sourceId)
    ];

    // Loop over each filter and apply it to the article IDs. This effectively
    // simulates the process of reapplying the filters in turn.
    filters.forEach(filter => {
      const [filterCategory, filterId] = getActiveFilterComponents(filter);

      // By using "|| []" below when checking the "buckets", we handle the case where a
      // filter may be applied, but then the sources might change such that that "bucket"
      // no longer exists
      // This can happen when risks are muted/killed
      // In this situation, the UI will show the filter as still applied, but there will
      // be no sources shown, "No results found" will show instead
      if (filterCategory === FILTER_SECTION_IDS.mediaBySource) {
        copyOfFilteredArticleIds = copyOfFilteredArticleIds.filter(articleId =>
          (mediaBySource.mediaSourceBuckets[filterId] || []).includes(articleId)
        );
      } else if (
        filterCategory === FILTER_SECTION_IDS.mediaByDirectRiskCategory
      ) {
        copyOfFilteredArticleIds = copyOfFilteredArticleIds.filter(articleId =>
          (riskChartData.directRiskCategoryBuckets[filterId] || []).includes(
            articleId
          )
        );
      } else if (
        filterCategory === FILTER_SECTION_IDS.mediaByIndirectRiskCategory
      ) {
        copyOfFilteredArticleIds = copyOfFilteredArticleIds.filter(articleId =>
          (riskChartData.indirectRiskCategoryBuckets[filterId] || []).includes(
            articleId
          )
        );
      } else if (filterCategory === FILTER_SECTION_IDS.mediaByRiskDirection) {
        copyOfFilteredArticleIds = copyOfFilteredArticleIds.filter(articleId =>
          (riskChartData.riskDirectionBuckets[filterId] || []).includes(
            articleId
          )
        );
      } else if (filterCategory === FILTER_SECTION_IDS.mediaByLanguages) {
        copyOfFilteredArticleIds = copyOfFilteredArticleIds.filter(articleId =>
          (mediaByLanguages[filterId] || []).includes(articleId)
        );
      } else if (filterCategory === FILTER_SECTION_IDS.mediaByOrganisations) {
        copyOfFilteredArticleIds = copyOfFilteredArticleIds.filter(articleId =>
          (mediaByOrganisations.organisations[filterId] || []).includes(
            articleId
          )
        );
      } else if (filterCategory === FILTER_SECTION_IDS.mediaByPeople) {
        copyOfFilteredArticleIds = copyOfFilteredArticleIds.filter(articleId =>
          (mediaByPeople.people[filterId] || []).includes(articleId)
        );
      } else if (filterCategory === FILTER_SECTION_IDS.mediaByLocations) {
        copyOfFilteredArticleIds = copyOfFilteredArticleIds.filter(articleId =>
          (mediaByLocations.locations[filterId] || []).includes(articleId)
        );
      } else if (filterCategory === FILTER_SECTION_IDS.mediaByTime) {
        const articleIds = [];

        if (filterId === UNDATED_FILTER_ID) {
          articleIds.push(
            ...mediaByRiskOverTime[NO_DATE_STRING].directRiskSourcesIds.filter(
              sourceId => copyOfFilteredArticleIds.includes(sourceId)
            ),
            ...mediaByRiskOverTime[
              NO_DATE_STRING
            ].indirectRiskSourcesIds.filter(sourceId =>
              copyOfFilteredArticleIds.includes(sourceId)
            ),
            ...mediaByRiskOverTime[NO_DATE_STRING].noRiskSourcesIds.filter(
              sourceId => copyOfFilteredArticleIds.includes(sourceId)
            )
          );
        } else {
          const [startDateAsMillis, endDateAsMillis] = filterId.split("|");
          const startDate = new Date(Number(startDateAsMillis));
          const endDate = new Date(Number(endDateAsMillis));

          Object.keys(mediaByRiskOverTime).forEach(date => {
            const yearAsDate = new Date(date);
            // If the date of this bucket falls within the selected date range
            // then we want to include it.
            if (yearAsDate >= startDate && yearAsDate <= endDate) {
              articleIds.push(
                ...mediaByRiskOverTime[date].directRiskSourcesIds.filter(
                  sourceId => copyOfFilteredArticleIds.includes(sourceId)
                ),
                ...mediaByRiskOverTime[date].indirectRiskSourcesIds.filter(
                  sourceId => copyOfFilteredArticleIds.includes(sourceId)
                ),
                ...mediaByRiskOverTime[date].noRiskSourcesIds.filter(sourceId =>
                  copyOfFilteredArticleIds.includes(sourceId)
                )
              );
            }
          });
        }

        copyOfFilteredArticleIds = articleIds;
      }

      // Bucket the updated article IDs again so that we have
      // accurate buckets.
      const newBuckets = bucketDataByFilters({
        currentFilters: filters,
        articleIds: copyOfFilteredArticleIds
      });

      mediaByRiskOverTime = newBuckets.riskOverTimeBuckets;
      riskChartData = newBuckets.riskBuckets;
      mediaBySource = newBuckets.sourceBuckets;
      mediaByLanguages = newBuckets.languageBuckets;
      mediaByOrganisations = newBuckets.organisationBuckets;
      mediaByPeople = newBuckets.peopleBuckets;
      mediaByLocations = newBuckets.locationBuckets;
    });

    return {
      simulatedFilteredArticleIds: copyOfFilteredArticleIds,
      riskChartData
    };
  };

  useEffect(() => {
    // You need to apply all the filters in activeFilters if it's populated
    // this will only be the case for PDF export.
    if (activeFilters.length) {
      const { simulatedFilteredArticleIds } =
        simulateReapplyingAllFiltersToArticleIds(activeFilters);

      const sourceGroupings = simulatedFilteredArticleIds.map(articleId =>
        listOfArticles.find(article => article.sourceId === articleId)
      );

      setSourceGroups(sourceGroupings);
      setFilteredArticleIds(simulatedFilteredArticleIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfArticles]);

  const resetAllFilters = useCallback(
    sourceGroupArticles => {
      setActiveFilters([]);
      setSourceGroups(sourceGroupArticles);
      setFilteredArticleIds(listOfArticles.map(article => article.sourceId));
    },
    [listOfArticles, setActiveFilters]
  );

  const onFilterRemoved = useCallback(
    (filterCategory, filterId) => {
      const indexOfFilterToRemove = activeFilters.findIndex(filter => {
        const [filterSection, filterIdentifier] =
          getActiveFilterComponents(filter);

        return (
          filterId === filterIdentifier && filterSection === filterCategory
        );
      });

      let newFilters;
      const sourceKeys = filterId.split("/");

      if (
        (filterCategory === FILTER_SECTION_IDS.mediaBySource ||
          filterCategory === FILTER_SECTION_IDS.mediaByDirectRiskCategory ||
          filterCategory === FILTER_SECTION_IDS.mediaByIndirectRiskCategory) &&
        sourceKeys.length > 1
      ) {
        const updatedHierarchyPath = sourceKeys.slice(0, sourceKeys.length - 1);
        newFilters = [
          ...activeFilters.slice(0, indexOfFilterToRemove),
          `${filterCategory}:${updatedHierarchyPath.join("/")}`,
          ...activeFilters.slice(indexOfFilterToRemove + 1)
        ];
      } else {
        newFilters = [
          ...activeFilters.slice(0, indexOfFilterToRemove),
          ...activeFilters.slice(indexOfFilterToRemove + 1)
        ];
      }

      const { simulatedFilteredArticleIds } =
        simulateReapplyingAllFiltersToArticleIds(newFilters);

      setSourceGroups(
        simulatedFilteredArticleIds.map(articleId =>
          listOfArticles.find(article => article.sourceId === articleId)
        )
      );
      setFilteredArticleIds(simulatedFilteredArticleIds);
      setActiveFilters(newFilters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activeFilters,
      bucketDataByFilters,
      listOfArticles,
      setActiveFilters,
      mediaByRiskOverTimeData
    ]
  );

  const onToggleExpandResultsSection = () => {
    setIsResultsExpanded(prevState => !prevState);
    if (isResultsExpanded) {
      // Then we must be collapsing the results so ensure
      // the results section remains in view.

      const rect = resultsSectionRef.current.getBoundingClientRect();

      // If results section's top is now hidden i.e. above the viewport then:
      if (rect.top <= 0) {
        // Bring the section into view
        webAndMediaSectionRef.current.scrollIntoView();
      }
    }
  };

  const filterDataByTime = ({ startDate, endDate }) => {
    const articleIds = [];
    let updatedActiveFilterIds = [...activeFiltersRef.current];

    const indexOfFilter = updatedActiveFilterIds.findIndex(filter => {
      const [filterType] = getActiveFilterComponents(filter);

      return filterType === FILTER_SECTION_IDS.mediaByTime;
    });
    const filterTimeDetails =
      !startDate && !endDate
        ? UNDATED_FILTER_ID
        : `${startDate.getTime()}|${endDate.getTime()}`;
    const timeFilter = `${FILTER_SECTION_IDS.mediaByTime}:${filterTimeDetails}`;

    if (indexOfFilter < 0) {
      updatedActiveFilterIds = [...updatedActiveFilterIds, timeFilter];
    } else {
      // Reposition the filter so it appears as the latest filter in the breadcrumbs
      updatedActiveFilterIds = [
        ...updatedActiveFilterIds.slice(0, indexOfFilter),
        ...updatedActiveFilterIds.slice(indexOfFilter + 1),
        timeFilter
      ];
    }

    // If there are no dates then we can assume that the 'Undated' bar was selected
    if (!startDate && !endDate) {
      articleIds.push(
        ...mediaByRiskOverTimeDataRef.current[NO_DATE_STRING]
          .directRiskSourcesIds,
        ...mediaByRiskOverTimeDataRef.current[NO_DATE_STRING]
          .indirectRiskSourcesIds,
        ...mediaByRiskOverTimeDataRef.current[NO_DATE_STRING].noRiskSourcesIds
      );
    } else {
      Object.keys(mediaByRiskOverTimeDataRef.current).forEach(year => {
        const yearAsDate = new Date(year);
        if (yearAsDate >= startDate && yearAsDate <= endDate) {
          articleIds.push(
            ...mediaByRiskOverTimeDataRef.current[year].directRiskSourcesIds,
            ...mediaByRiskOverTimeDataRef.current[year].indirectRiskSourcesIds,
            ...mediaByRiskOverTimeDataRef.current[year].noRiskSourcesIds
          );
        }
      });
    }

    const sourceGroupings = articleIds.map(articleId =>
      listOfArticles.find(article => article.sourceId === articleId)
    );

    setSourceGroups(sourceGroupings);
    setFilteredArticleIds(articleIds);
    setActiveFilters(updatedActiveFilterIds);
    setIsMediaResultsShown(true);
  };

  const filterDataByRiskDirection = riskDirectionId => {
    const updatedRiskDirectionId = riskDirectionId;
    const articleIdsForSelectedRisk =
      mediaByRiskDirectionData.riskDirectionBuckets[updatedRiskDirectionId];

    let updatedActiveFilterIds = [];
    let sourceGroupings;

    let existingRiskDirectionFilterId;
    const riskDirectionFilterIndex = activeFilters.findIndex(filter => {
      const [filterType, filterId] = getActiveFilterComponents(filter);
      if (filterType === FILTER_SECTION_IDS.mediaByRiskDirection) {
        existingRiskDirectionFilterId = filterId;
        return true;
      }
      return false;
    });

    if (
      riskDirectionFilterIndex > -1 &&
      existingRiskDirectionFilterId === riskDirectionId
    ) {
      // If there is a risk direction already active and this filter
      // is the same as the selected one then remove it.
      return onFilterRemoved(
        FILTER_SECTION_IDS.mediaByRiskDirection,
        riskDirectionId
      );
    }

    if (riskDirectionFilterIndex > -1) {
      // If we've selected a different risk direction filter.
      // Then install the new filter in the old risk direction
      // filter's place.
      updatedActiveFilterIds = [
        ...activeFilters.slice(0, riskDirectionFilterIndex),
        `${FILTER_SECTION_IDS.mediaByRiskDirection}:${riskDirectionId}`,
        ...activeFilters.slice(riskDirectionFilterIndex + 1)
      ];
      sourceGroupings = articleIdsForSelectedRisk.map(articleId =>
        listOfArticles.find(article => article.sourceId === articleId)
      );
    } else {
      // Otherwise it's a new filter so just add it to the existing set of filters.
      updatedActiveFilterIds = [
        ...activeFilters,
        `${FILTER_SECTION_IDS.mediaByRiskDirection}:${riskDirectionId}`
      ];
      sourceGroupings = articleIdsForSelectedRisk.map(articleId =>
        listOfArticles.find(article => article.sourceId === articleId)
      );
    }

    setFilteredArticleIds(articleIdsForSelectedRisk);
    setSourceGroups(sourceGroupings);
    setActiveFilters(updatedActiveFilterIds);
    setIsMediaResultsShown(true);

    return null;
  };

  const filterDataByHierarchicalFilter = (
    selectedFilterId,
    articleIds,
    selectedFilterType
  ) => {
    let updatedActiveFilterIds = [...activeFilters];

    const isFilterAlreadyActive = activeFilters.some(filter => {
      const [filterType, filterId] = getActiveFilterComponents(filter);

      return selectedFilterType === filterType && filterId === selectedFilterId;
    });

    if (!isFilterAlreadyActive) {
      let shouldIncludeActiveDirectionalityFilter = true;
      // We don't need to record all parts of the selected source's hierarchy,
      // just the part of the hierarchy that represents the selected source.
      // We need to remove the parent parts of the hierarchy from the filters.
      // If we don't we'll end up displaying a filter pill for each step in the
      // hierarchy.
      updatedActiveFilterIds = updatedActiveFilterIds.filter(filter => {
        const [filterType, filterSelection] = getActiveFilterComponents(filter);

        let shouldIncludeFilter = true;
        if (selectedFilterType === filterType) {
          // Include the filter if it's a completely different hierarchy to the selected
          // one.
          shouldIncludeFilter = !selectedFilterId.startsWith(filterSelection);
        }

        if (filterType === FILTER_SECTION_IDS.mediaByRiskDirection) {
          shouldIncludeActiveDirectionalityFilter = false;
        }

        return shouldIncludeFilter;
      });

      // Default apply risk directionality filter
      if (
        selectedFilterType === FILTER_SECTION_IDS.mediaByDirectRiskCategory &&
        shouldIncludeActiveDirectionalityFilter
      ) {
        updatedActiveFilterIds.push(
          `${FILTER_SECTION_IDS.mediaByRiskDirection}:${RISK_DIRECTION_FILTER_OPTIONS.directRisk.id}`
        );
      } else if (
        selectedFilterType === FILTER_SECTION_IDS.mediaByIndirectRiskCategory &&
        shouldIncludeActiveDirectionalityFilter
      ) {
        updatedActiveFilterIds.push(
          `${FILTER_SECTION_IDS.mediaByRiskDirection}:${RISK_DIRECTION_FILTER_OPTIONS.indirectRisk.id}`
        );
      }

      updatedActiveFilterIds.push(`${selectedFilterType}:${selectedFilterId}`);

      const sourceGroupings = articleIds.map(articleId =>
        listOfArticles.find(article => article.sourceId === articleId)
      );

      setSourceGroups(sourceGroupings);
      setActiveFilters(updatedActiveFilterIds);
      setFilteredArticleIds(articleIds);
      setIsMediaResultsShown(true);
    } else {
      onFilterRemoved(selectedFilterType, selectedFilterId);
    }
  };

  const filterDataBySource = sourceId => {
    const articleIds = mediaBySourceData.mediaSourceBuckets[sourceId];
    filterDataByHierarchicalFilter(
      sourceId,
      articleIds,
      FILTER_SECTION_IDS.mediaBySource
    );
  };

  const filterDataByDirectRiskCategory = riskCategory => {
    const articleIds =
      mediaByRiskDirectionData.directRiskCategoryBuckets[riskCategory];
    filterDataByHierarchicalFilter(
      riskCategory,
      articleIds,
      FILTER_SECTION_IDS.mediaByDirectRiskCategory
    );
  };

  const filterDataByIndirectRiskCategory = riskCategory => {
    const articleIds =
      mediaByRiskDirectionData.indirectRiskCategoryBuckets[riskCategory];
    filterDataByHierarchicalFilter(
      riskCategory,
      articleIds,
      FILTER_SECTION_IDS.mediaByIndirectRiskCategory
    );
  };

  const filterDataByOrganisations = selectedFilterId => {
    const articleIds = mediaByOrganisationsData.organisations[selectedFilterId];
    const updatedActiveFilterIds = [
      ...activeFilters,
      `${FILTER_SECTION_IDS.mediaByOrganisations}:${selectedFilterId}`
    ];
    const sourceGroupings = articleIds.map(articleId =>
      listOfArticles.find(article => article.sourceId === articleId)
    );

    setSourceGroups(sourceGroupings);
    setActiveFilters(updatedActiveFilterIds);
    setFilteredArticleIds(articleIds);
    setIsMediaResultsShown(true);
  };

  const filterDataByPeople = selectedFilterId => {
    const articleIds = mediaByPeopleData.people[selectedFilterId];
    const updatedActiveFilterIds = [
      ...activeFilters,
      `${FILTER_SECTION_IDS.mediaByPeople}:${selectedFilterId}`
    ];
    const sourceGroupings = articleIds.map(articleId =>
      listOfArticles.find(article => article.sourceId === articleId)
    );

    setActiveFilters(updatedActiveFilterIds);
    setFilteredArticleIds(articleIds);
    setIsMediaResultsShown(true);
    setSourceGroups(sourceGroupings);
  };

  const filterDataByLanguage = selectedFilterId => {
    const articleIds = mediaByLanguagesData[selectedFilterId];
    const updatedActiveFilterIds = [
      ...activeFilters,
      `${FILTER_SECTION_IDS.mediaByLanguages}:${selectedFilterId}`
    ];
    const sourceGroupings = articleIds.map(articleId =>
      listOfArticles.find(article => article.sourceId === articleId)
    );

    setSourceGroups(sourceGroupings);
    setActiveFilters(updatedActiveFilterIds);
    setFilteredArticleIds(articleIds);
    setIsMediaResultsShown(true);
  };

  const filterDataByLocation = selectedFilterId => {
    const articleIds = mediaByLocationsData.locations[selectedFilterId];
    const updatedActiveFilterIds = [
      ...activeFilters,
      `${FILTER_SECTION_IDS.mediaByLocations}:${selectedFilterId}`
    ];
    const sourceGroupings = articleIds.map(articleId =>
      listOfArticles.find(article => article.sourceId === articleId)
    );

    setSourceGroups(sourceGroupings);
    setActiveFilters(updatedActiveFilterIds);
    setFilteredArticleIds(articleIds);
    setIsMediaResultsShown(true);
  };

  const transformRiskChartData = useMemo(() => {
    return Object.values(mediaByRiskOverTimeData)
      .sort((a, b) => {
        if (a.year < b.year) {
          return -1;
        }

        return 1;
      })
      .filter(data => data.year !== NO_DATE_STRING);
  }, [mediaByRiskOverTimeData]);

  const transformUndatedRiskChartData = useMemo(() => {
    return Object.values(mediaByRiskOverTimeData).filter(
      data => data.year === NO_DATE_STRING
    );
  }, [mediaByRiskOverTimeData]);

  const renderMediaResults = () => {
    // We're only interested in risk filters, so filter out
    // the rest.
    let activeRiskDirectionality;
    const riskFilters = activeFilters
      .filter(
        f =>
          f.startsWith("mediaByDirectRiskCategory:") ||
          f.startsWith("mediaByIndirectRiskCategory:")
      )
      .map(f => {
        // We want just the risk hierarchy that makes up the
        // filter.
        const hierarchyRegex =
          /(mediaByDirectRiskCategory:|mediaByIndirectRiskCategory:)(.*)/gm;
        const groups = hierarchyRegex.exec(f);
        const filterType = groups[1].slice(0, -1);

        if (filterType === FILTER_SECTION_IDS.mediaByDirectRiskCategory) {
          activeRiskDirectionality = "direct";
        } else if (
          filterType === FILTER_SECTION_IDS.mediaByIndirectRiskCategory
        ) {
          activeRiskDirectionality = "indirect";
        }

        if (groups && groups.length === 3) {
          // the hierarchy appears in the final capture group.
          const hierarchy = groups[2];

          // Pull the hierarchy string apart into an array.
          return hierarchy.split("/");
        }

        return [];
      });

    return (
      <MediaResults
        sourceGroups={sourceGroupsWithStories}
        sourceCount={relevantSourceCount}
        showHeader={false}
        defaultExpanded
        activeRiskCategoryFilters={riskFilters}
        activeRiskDirectionality={activeRiskDirectionality}
      />
    );
  };

  /**
   * Establishes, for a given hierarchical filter type, which filter pills should be visible.
   * This determination is made based on the parent-child relationship between filters e.g.
   * "ESG/Governance" etc.
   * @param {string[]} mediaByHierarchicalData media sources bucketed into hierarchical groups
   * @param {object} dataBucket media articles bucketed by the particular hierarchical filter
   * @param {*} colorsBucket colors bucketed by hierarchical filters (used only by source filtering)
   * @param {*} hierarchicalFilterId ID of the filter type e.g. risk category or media sources
   * @returns array of filter pill data to display in the UI
   */
  const getHierarchicalFilters = (
    mediaByHierarchicalData,
    dataBucket,
    colorsBucket,
    hierarchicalFilterId
  ) => {
    return mediaByHierarchicalData.reduce((acc, hierarchyPath) => {
      const hierarchyPathArray = hierarchyPath.split("/");
      // Label is always the leaf node in the source hierarchy.
      const label = hierarchyPathArray[hierarchyPathArray.length - 1];

      // Current source is a parent if we find other keys that start
      // with the same key but also have more fields following it e.g.
      // if current source is "news/uk" and if we find a key "news/uk/local"
      // then the latter is clearly child of the former.
      const isFilterAParentFilter =
        mediaByHierarchicalData.filter(key =>
          key.startsWith(`${hierarchyPath}/`)
        ).length > 0;

      // Current source is a child if the path has more than one node e.g.
      // "news/uk"
      const isFilterAChildFilter = hierarchyPathArray.length > 1;
      const isSourcePartOfFilterHierarchy = filter => {
        const filterArray = filter.split("/");

        // We need to establish if the hierarchy path is part of the
        // same hierarchy represented by the current `filter`.

        // We do this by shrinking the hierarchy path to the same
        // length as the current `filter` and then see if they are equal.
        // If they're equal then they form the same hierarchy.

        // We have to shrink the path first because the path might be a deeper
        // part of the hierarchy e.g. a filter of `"esg/governance" shares
        // the same hierarchy as a path of `"esg/governance/insolvency".
        const truncatedPathArray = hierarchyPathArray.slice(
          0,
          filterArray.length
        );

        return truncatedPathArray.join("/") === filter;
      };

      let isHidden = false;

      if (isFilterAParentFilter) {
        // Source should be hidden if any of its children _are_ selected
        // In this case we want to show the children and not the parent.
        // Or if the source is not part of the selected filter hierarchy.
        isHidden = activeFilters.some(filter => {
          const [id, selection] = getActiveFilterComponents(filter);
          // If we're already filtering by the hierarchical filter then...
          if (id === hierarchicalFilterId) {
            // ...ensure only members of that hierarchy are even considered
            if (!isSourcePartOfFilterHierarchy(selection)) {
              return true;
            }

            return hierarchyPath === selection;
          }
          return false;
        });
      }

      if (isFilterAChildFilter && !isHidden) {
        // Source should be hidden if its parent is _not_ selected
        // Or if the source is not part of the selected filter hierarchy.
        const parent = hierarchyPathArray
          .slice(0, hierarchyPathArray.length - 1)
          .join("/");
        isHidden =
          activeFilters.length === 0 ||
          activeFilters.every(filter => {
            const [id, selection] = getActiveFilterComponents(filter);
            const selectionArray = selection.split("/");

            // If we're already filtering by the hierarchical filter then...
            if (id === hierarchicalFilterId) {
              // ...ensure only members of that hierarchy are even considered
              if (!isSourcePartOfFilterHierarchy(selection)) {
                return true;
              }

              // Check to see if the parent filter of the hierarchy filter
              // has been selected. We have to shrink the current filter
              // array to match the parent as the current filter could include
              // the parent but represent a deeper part of the hierarchy e.g.
              // a current filter of "esg/governance/regulatory/fraud"
              // and a parent of "esg/governance" would determine that the parent
              // is selected as the latter's path is a subset of the former's.
              const truncatedSelection = selectionArray.slice(
                0,
                parent.split("/").length
              );

              return truncatedSelection.join("/") !== parent;
            }
            return true;
          });
      }

      if (!isHidden) {
        return [
          ...acc,
          {
            id: hierarchyPath,
            label: FILTER_LABEL_MAPPINGS[label] ?? label,
            count: dataBucket[hierarchyPath].length,
            color: colorsBucket[hierarchyPath],
            opacity: hierarchyPath.indexOf(HIGH_RISK_SITES_LABEL) > -1 ? 1 : 0.6
          }
        ];
      }

      return acc;
    }, []);
  };

  const renderLeftPane = () => {
    let currentlyFilteredRisk;
    const currentlyFilteredDirectRiskCategories = [];
    const currentlyFilteredIndirectRiskCategories = [];

    if (activeFilters.length) {
      activeFilters.forEach(filterId => {
        const [filterType, filterIdentifier] =
          getActiveFilterComponents(filterId);
        if (filterType === FILTER_SECTION_IDS.mediaByRiskDirection) {
          currentlyFilteredRisk = filterIdentifier;
        } else if (
          filterType === FILTER_SECTION_IDS.mediaByDirectRiskCategory
        ) {
          currentlyFilteredDirectRiskCategories.push(filterIdentifier);
        } else if (
          filterType === FILTER_SECTION_IDS.mediaByIndirectRiskCategory
        ) {
          currentlyFilteredIndirectRiskCategories.push(filterIdentifier);
        }
      });
    }

    const riskDirectionBars = [
      {
        id: RISK_DIRECTION_FILTER_OPTIONS.directRisk.id,
        count:
          mediaByRiskDirectionData?.riskDirectionBuckets?.directRisk?.length,
        label: RISK_DIRECTION_FILTER_OPTIONS.directRisk.label,
        color: red.directRiskFill,
        labelColor: red.directRiskOutline,
        isObscured:
          currentlyFilteredRisk &&
          currentlyFilteredRisk !== RISK_DIRECTION_FILTER_OPTIONS.directRisk.id
      },
      {
        id: RISK_DIRECTION_FILTER_OPTIONS.indirectRisk.id,
        count:
          mediaByRiskDirectionData?.riskDirectionBuckets?.indirectRisk?.length,
        label: RISK_DIRECTION_FILTER_OPTIONS.indirectRisk.label,
        color: orange.indirectRiskFill,
        labelColor: orange.indirectRisk,
        isObscured:
          currentlyFilteredRisk &&
          currentlyFilteredRisk !==
            RISK_DIRECTION_FILTER_OPTIONS.indirectRisk.id
      },
      {
        id: RISK_DIRECTION_FILTER_OPTIONS.noRisk.id,
        count: mediaByRiskDirectionData?.riskDirectionBuckets?.noRisk?.length,
        label: RISK_DIRECTION_FILTER_OPTIONS.noRisk.label,
        color: grey.ghost,
        isObscured:
          currentlyFilteredRisk &&
          currentlyFilteredRisk !== RISK_DIRECTION_FILTER_OPTIONS.noRisk.id
      }
    ].filter(bar => !currentlyFilteredRisk || bar.id === currentlyFilteredRisk);

    const directRiskCategoryHierarchies = getHierarchicalFilters(
      Object.keys(mediaByRiskDirectionData.directRiskCategoryBuckets),
      mediaByRiskDirectionData.directRiskCategoryBuckets,
      {},
      FILTER_SECTION_IDS.mediaByDirectRiskCategory
    );

    const indirectRiskCategoryHierarchies = getHierarchicalFilters(
      Object.keys(mediaByRiskDirectionData.indirectRiskCategoryBuckets),
      mediaByRiskDirectionData.indirectRiskCategoryBuckets,
      {},
      FILTER_SECTION_IDS.mediaByIndirectRiskCategory
    );

    directRiskCategoryHierarchies.sort((catA, catB) => catB.count - catA.count);
    indirectRiskCategoryHierarchies.sort(
      (catA, catB) => catB.count - catA.count
    );

    const directRiskCategoryPills = directRiskCategoryHierarchies.map(cat => {
      const tier2s = Object.keys(
        mediaByRiskDirectionData.directRiskCategoryBuckets
      )
        .filter(riskCatBucketKey => {
          if (cat.id.split("/").length > 1) {
            return riskCatBucketKey === cat.id;
          }

          return riskCatBucketKey.startsWith(`${cat.id}/`);
        })
        .map(riskCatBucketKey => riskCatBucketKey.split("/"));

      return (
        <RiskPill
          key={cat.id}
          onClick={() => {
            if (cat.count > 0) {
              filterDataByDirectRiskCategory(cat.id);
            }
          }}
          isActive={currentlyFilteredDirectRiskCategories.includes(cat.id)}
          count={cat.count}
          label={cat.label}
          riskHierarchies={tier2s}
          ariaLabel={`Filter by ${cat.label} (direct)`}
          color={red.directRiskOutline}
          removesAllInstancesOfRiskCategory
        />
      );
    });

    const indirectRiskCategoryPills = indirectRiskCategoryHierarchies.map(
      cat => {
        const tier2s = Object.keys(
          mediaByRiskDirectionData.indirectRiskCategoryBuckets
        )
          .filter(riskCatBucketKey => {
            if (cat.id.split("/").length > 1) {
              return riskCatBucketKey === cat.id;
            }

            return riskCatBucketKey.startsWith(`${cat.id}/`);
          })
          .map(riskCatBucketKey => riskCatBucketKey.split("/"));

        return (
          <RiskPill
            key={cat.id}
            onClick={() => {
              if (cat.count > 0) {
                filterDataByIndirectRiskCategory(cat.id);
              }
            }}
            isActive={currentlyFilteredIndirectRiskCategories.includes(cat.id)}
            count={cat.count}
            label={cat.label}
            riskHierarchies={tier2s}
            ariaLabel={`Filter by ${cat.label} (indirect)`}
            removesAllInstancesOfRiskCategory
            color={orange.indirectRisk}
            fillColor={orange.indirectRiskFill}
          />
        );
      }
    );

    return (
      <S.CategoriesScrollContainer>
        <StackedBar
          total={filteredArticleIds.length}
          showTotal={false}
          bars={riskDirectionBars}
          onBarSectionClick={bar => filterDataByRiskDirection(bar.id)}
          selectedBarId={currentlyFilteredRisk}
          title="Risk inspector"
          showZeroCountBars
          rightAlignLastPill={riskDirectionBars.length > 1}
        />
        <S.RiskCategories>
          <S.RiskCategoryTitle>
            Directly linked risk categories
          </S.RiskCategoryTitle>
          <S.RiskCategoryContainer>
            {directRiskCategoryHierarchies.length > 0
              ? directRiskCategoryPills.slice(
                  0,
                  isDirectRiskCategoriesExpanded
                    ? directRiskCategoryPills.length
                    : RISK_CATEGORY_MAX_DEFAULT_SHOWN
                )
              : null}
            {!hasDirectRiskCategories && (
              <S.NoneIdentified>None identified</S.NoneIdentified>
            )}
            {directRiskCategoryPills?.length >
            RISK_CATEGORY_MAX_DEFAULT_SHOWN ? (
              <S.RiskCountButton
                onClick={e => {
                  e.stopPropagation();
                  setIsDirectRiskCategoriesExpanded(prevState => !prevState);
                }}
                kind={ButtonKind.secondary}
              >
                {isDirectRiskCategoriesExpanded
                  ? "Show less"
                  : `+${
                      directRiskCategoryPills.length -
                      RISK_CATEGORY_MAX_DEFAULT_SHOWN
                    }`}
              </S.RiskCountButton>
            ) : null}
          </S.RiskCategoryContainer>
        </S.RiskCategories>
        <S.RiskCategories>
          <S.RiskCategoryTitle>
            Indirect linked risk categories
          </S.RiskCategoryTitle>
          <S.RiskCategoryContainer>
            {indirectRiskCategoryHierarchies.length > 0
              ? indirectRiskCategoryPills.slice(
                  0,
                  isIndirectRiskCategoriesExpanded
                    ? indirectRiskCategoryPills.length
                    : RISK_CATEGORY_MAX_DEFAULT_SHOWN
                )
              : null}
            {!hasIndirectRiskCategories && (
              <S.NoneIdentified>None identified</S.NoneIdentified>
            )}
            {indirectRiskCategoryPills?.length >
            RISK_CATEGORY_MAX_DEFAULT_SHOWN ? (
              <S.RiskCountButton
                onClick={e => {
                  e.stopPropagation();
                  setIsIndirectRiskCategoriesExpanded(prevState => !prevState);
                }}
                kind={ButtonKind.secondary}
              >
                {isIndirectRiskCategoriesExpanded
                  ? "Show less"
                  : `+${
                      indirectRiskCategoryPills.length -
                      RISK_CATEGORY_MAX_DEFAULT_SHOWN
                    }`}
              </S.RiskCountButton>
            ) : null}
          </S.RiskCategoryContainer>
        </S.RiskCategories>
      </S.CategoriesScrollContainer>
    );
  };

  const renderRightPane = () => {
    const mediaSourceBucketKeys = Object.keys(
      mediaBySourceData.mediaSourceBuckets
    );
    const sortedMediaSources = mediaSourceBucketKeys.sort((a, b) => {
      // Get the first tier of the heirarchy to check against
      if (a.split("/")[0] === "Other") {
        return 1;
      }

      if (b.split("/")[0] === "Other") {
        return -1;
      }

      if (
        mediaBySourceData.mediaSourceBuckets[a].length >
        mediaBySourceData.mediaSourceBuckets[b].length
      ) {
        return -1;
      }
      return 1;
    });

    const CORRUPTION_SITES = "High Risk Sites";

    // Find the presence of corruption sites before we build the top n
    let isCorruptionSitesMediaSourcePresent = false;
    for (let i = 0; i < sortedMediaSources?.length; i += 1) {
      const label = sortedMediaSources[i].split("/")[0];

      if (label === CORRUPTION_SITES) {
        isCorruptionSitesMediaSourcePresent = true;
        break;
      }
    }

    // Only display the top 5 media sources
    const uniqueTopLevelMediaSources = new Set();
    const DISPLAY_LIMIT = 5;
    const filteredAndSortedMediaSources = sortedMediaSources.reduce(
      (acc, hierarchy) => {
        // Use top level media source label
        const label = hierarchy.split("/")[0];

        // Special case corruption sites to always be included in the 5th position (if present)
        if (
          isCorruptionSitesMediaSourcePresent &&
          !uniqueTopLevelMediaSources.has(CORRUPTION_SITES)
        ) {
          uniqueTopLevelMediaSources.add(CORRUPTION_SITES);
        }

        // We only want the top n top level media sources
        if (
          !uniqueTopLevelMediaSources.has(label) &&
          uniqueTopLevelMediaSources.size !== DISPLAY_LIMIT
        ) {
          uniqueTopLevelMediaSources.add(label);
        }

        if (uniqueTopLevelMediaSources.has(label)) {
          acc.push(hierarchy);
        }

        return acc;
      },
      []
    );

    const mediaBySourceBars = getHierarchicalFilters(
      filteredAndSortedMediaSources,
      mediaBySourceData.mediaSourceBuckets,
      mediaBySourceData.mediaSourceColors,
      FILTER_SECTION_IDS.mediaBySource
    );

    const limitedMediaBySourceBars = mediaBySourceBars?.slice(0, DISPLAY_LIMIT);

    let currentlyFilteredRisk;

    if (activeFilters.length) {
      activeFilters.forEach(filter => {
        const [filterType, filterId] = getActiveFilterComponents(filter);
        if (filterType === FILTER_SECTION_IDS.mediaBySource) {
          currentlyFilteredRisk = filterId;
        }
      });
    }

    return (
      <div>
        <StackedBar
          total={limitedMediaBySourceBars?.reduce((acc, curr) => {
            // eslint-disable-next-line no-return-assign, no-param-reassign
            return (acc += curr.count);
          }, 0)}
          showTotal={false}
          bars={limitedMediaBySourceBars}
          onBarSectionClick={bar => filterDataBySource(bar.id)}
          displayLabelsPermanently
          selectedBarId={currentlyFilteredRisk}
          title="Top 5 sources"
          hasFixedHeight
        />
        <RiskOverTimeChart
          data={transformRiskChartData}
          undatedData={transformUndatedRiskChartData}
          onDateRangeChange={filterDataByTime}
          isFilterActive={activeFilters.some(f => {
            const [filterId] = getActiveFilterComponents(f);
            return filterId === FILTER_SECTION_IDS.mediaByTime;
          })}
        />
      </div>
    );
  };

  const itemString = sourceGroups.length === 1 ? "item" : "items";

  const sourceSwitcher = useCallback(
    clickedConfidence => {
      if (clickedConfidence === CONFIDENCE_CATEGORIES.confirmed) {
        setListOfArticles(relevantSourceGroups);
        onConfidenceFilterSelected(CONFIDENCE_CATEGORIES.confirmed);
        resetAllFilters(relevantSourceGroups);
      } else if (clickedConfidence === CONFIDENCE_CATEGORIES.unconfirmed) {
        setListOfArticles(nearNonRelevantSourceGroups);
        onConfidenceFilterSelected(CONFIDENCE_CATEGORIES.unconfirmed);
        resetAllFilters(nearNonRelevantSourceGroups);
      } else if (clickedConfidence === CONFIDENCE_CATEGORIES.discarded) {
        setListOfArticles(broadNonRelevantSourceGroups);
        onConfidenceFilterSelected(CONFIDENCE_CATEGORIES.discarded);
        resetAllFilters(broadNonRelevantSourceGroups);
      }
    },
    [
      broadNonRelevantSourceGroups,
      nearNonRelevantSourceGroups,
      resetAllFilters,
      relevantSourceGroups
    ]
  );

  const renderBannerFallbackLabel = () => {
    return hasFilterBeenActive
      ? "No active filters"
      : "Web content and news articles relating to the brand surrounding your subject.";
  };

  if (media) {
    const isFilterActive = activeFilters.length > 0;

    let filterOptions = {};
    if (isFilterActive) {
      filterOptions = getFilterPillsData();
    }

    const renderedSectionContent = (
      <div>
        <S.Panel ref={webAndMediaSectionRef}>
          <S.SectionHeader>
            <div>
              <S.ActiveFiltersContainer>
                <S.ActiveFiltersLabel>
                  {isFilterActive
                    ? "Active filters:"
                    : renderBannerFallbackLabel()}
                </S.ActiveFiltersLabel>{" "}
                <S.ActiveFilterPillsContainer>
                  {isFilterActive &&
                    filterOptions.map(option => {
                      return (
                        <S.ActiveFilterPill
                          key={option.id}
                          backgroundColor={option.color}
                        >
                          <S.ActiveFilterPillLabel>
                            {option.label}
                          </S.ActiveFilterPillLabel>
                          <S.CrossIcon
                            onClick={() =>
                              onFilterRemoved(option.section, option.id)
                            }
                          />
                        </S.ActiveFilterPill>
                      );
                    })}
                  {isFilterActive && (
                    <S.ResetFiltersButton
                      kind={ButtonKind.tertiary}
                      onClick={() => resetAllFilters(listOfArticles)}
                    >
                      Reset all
                    </S.ResetFiltersButton>
                  )}
                </S.ActiveFilterPillsContainer>
              </S.ActiveFiltersContainer>
            </div>
            {isPDX ? (
              <ConfidenceDropDownMenu
                isPdx
                inputtedHoveredState={selectedConfidenceFilter}
                dropDownButtonText={
                  DOWJONES_CONVERSION[selectedConfidenceFilter]
                }
                dropDownMenuItem1={{
                  label: relevantSourceCount,
                  onClickHandler: () =>
                    sourceSwitcher(CONFIDENCE_CATEGORIES.confirmed)
                }}
                dropDownMenuItem2={{
                  label: nearNonRelevantSourceGroups.length,
                  onClickHandler: () =>
                    sourceSwitcher(CONFIDENCE_CATEGORIES.unconfirmed)
                }}
                dropDownMenuItem3={{
                  label: broadNonRelevantSourceGroups.length,
                  onClickHandler: () =>
                    sourceSwitcher(CONFIDENCE_CATEGORIES.discarded)
                }}
              />
            ) : (
              <S.SectionTotal>{sourceGroups.length}</S.SectionTotal>
            )}
          </S.SectionHeader>
          <S.TopSectionContainer>
            <S.LeftPaneContainer>{renderLeftPane()}</S.LeftPaneContainer>
            <S.RightSectionContainer isLeftPanelEmpty={false}>
              {renderRightPane()}
            </S.RightSectionContainer>
          </S.TopSectionContainer>
          <S.ShowDetailButtonContainer>
            <S.DetailButtonDividerLine />
            <S.ShowAdditionalFiltersButton
              kind={ButtonKind.secondary}
              onClick={() =>
                setIsAdditionalFiltersExpanded(!isAdditionalFiltersExpanded)
              }
            >
              {isAdditionalFiltersExpanded ? "Hide" : "Show"} additional filters
            </S.ShowAdditionalFiltersButton>
            <S.DetailButtonDividerLine />
          </S.ShowDetailButtonContainer>
          {isAdditionalFiltersExpanded && (
            <AdditionalFilters
              sourceGroups={sourceGroups}
              sourceCount={relevantSourceCount}
              onOrganisationFilterSelected={filterDataByOrganisations}
              onPeopleFilterSelected={filterDataByPeople}
              onLanguagesFilterSelected={filterDataByLanguage}
              onLocationsFilterSelected={filterDataByLocation}
              articlesByLanguage={mediaByLanguagesData}
              articlesByOrganisations={mediaByOrganisationsData.organisations}
              articlesByPeople={mediaByPeopleData.people}
              peopleNameVariants={mediaByPeopleData.namesToVariants}
              organisationNameVariants={
                mediaByOrganisationsData.namesToVariants
              }
              articlesByLocation={mediaByLocationsData.locations}
              locationNameVariants={mediaByLocationsData.nameToVariants}
              selectedLanguageFilters={getAdditionalFiltersForFilterType(
                FILTER_SECTION_IDS.mediaByLanguages
              )}
              selectedOrganisationsFilters={getAdditionalFiltersForFilterType(
                FILTER_SECTION_IDS.mediaByOrganisations
              )}
              selectedPeopleFilters={getAdditionalFiltersForFilterType(
                FILTER_SECTION_IDS.mediaByPeople
              )}
              selectedLocationFilters={getAdditionalFiltersForFilterType(
                FILTER_SECTION_IDS.mediaByLocations
              )}
            />
          )}
          <S.MediaShowResultsButton
            resultsCount={sourceGroupsWithStories.length}
            onShowResultsClick={newVal => setIsMediaResultsShown(newVal)}
            resultsString={itemString}
            isShowingResults={isMediaResultsShown}
          />
          <S.ResultsSection
            isResultsShown={isMediaResultsShown}
            isResultsExpanded={isResultsExpanded}
            ref={resultsSectionRef}
          >
            {isMediaResultsShown && renderMediaResults()}
          </S.ResultsSection>
          <S.CustomStickyExpandButton
            isReportRegenerationOpen={isReportRegenerationOpen}
            isResultsExpanded={isResultsExpanded}
            onToggleExpandResultsSection={onToggleExpandResultsSection}
            resultsSectionRef={resultsSectionRef}
            hideDividingLines={false}
          />
        </S.Panel>
        <SectionFooter />
      </div>
    );
    return renderedSectionContent;
  }

  return null;
};

export default observer(WebAndMediaArticles);
