import React, { FC, useEffect, useState } from "react";
import { Check } from "react-feather";

import { Auth } from "aws-amplify";

import { useNavigate } from "react-router-dom";
import ModalContainer from "components/molecules/ModalContainer";

import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";

import ErrorBanner from "components/atoms/ErrorBanner";

import Input from "components/atoms/Input";

import {
  PasswordState,
  getPasswordState,
  getInvalidPasswordReason
} from "util/passwordValidation";

import config from "config";
import S from "./styles";

const enum FormState {
  idle = "idle",
  loading = "loading",
  submitted = "submitted",
  error = "error",
  expired = "expired",
  newRequestSubmitted = "newResetSubmitted"
}

interface Props {
  loginUrl: string;
}

const ResetPassword: FC<Props> = ({ loginUrl }) => {
  const [passwordState, setPasswordState] = useState<PasswordState>(
    PasswordState.invalid
  );
  const [formState, setFormState] = useState<FormState>(FormState.idle);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const email = params.get("email");
  const token = params.get("token");

  const onPasswordChange = (value: string) => {
    setPasswordState(
      getPasswordState({
        field: "password",
        value,
        state: {
          password: newPassword,
          passwordConfirmation: newPasswordConfirmation
        }
      })
    );
    setNewPassword(value);
  };

  const onPasswordConfirmationChange = (value: string) => {
    setPasswordState(
      getPasswordState({
        field: "passwordConfirmation",
        value,
        state: {
          password: newPassword,
          passwordConfirmation: newPasswordConfirmation
        }
      })
    );
    setNewPasswordConfirmation(value);
  };

  const onSubmit = () => {
    if (!email || !token) return;
    setFormState(FormState.loading);
    Auth.forgotPasswordSubmit(
      `${email.toLowerCase()}+${config.tenantId}`,
      token,
      newPassword
    )
      .then(() => {
        setFormState(FormState.submitted);
        navigate(loginUrl);
      })
      .catch(error => {
        if (
          error.message ===
          "Invalid code provided, please request a code again."
        ) {
          setFormState(FormState.expired);
        } else {
          setFormState(FormState.error);
        }
        console.error(error);
      });
  };

  const onRequestNewResetEmail = async () => {
    if (!email) return;

    if (formState === FormState.newRequestSubmitted) {
      navigate(loginUrl);
      return;
    }

    try {
      await Auth.forgotPassword(`${email.toLowerCase()}+${config.tenantId}`);
      setFormState(FormState.newRequestSubmitted);
    } catch (e: any) {
      console.error("Password reset error", { message: e.message, ...e });
      setFormState(FormState.error);
    }
  };

  const onDismissError = () => setFormState(FormState.idle);

  const renderRequestNewReset = () => {
    return (
      <>
        <S.Description>
          <S.Title level={4}>
            {formState === FormState.newRequestSubmitted
              ? "New request sent"
              : "Whoops, that's an expired link"}
          </S.Title>
        </S.Description>

        <S.Explainer>
          {formState === FormState.newRequestSubmitted
            ? "Check your email for a new reset password link."
            : "For security reasons, password reset links expire after a little while. You can request a new password reset email below."}
        </S.Explainer>

        <ButtonNew
          onClick={onRequestNewResetEmail}
          type={ButtonType.Filled}
          size={ButtonSize.Medium}
          text={
            formState === FormState.newRequestSubmitted
              ? "Ok"
              : "Request a new reset email"
          }
        />
      </>
    );
  };

  useEffect(() => {
    if (!email || !token) {
      navigate(loginUrl);
    }
  }, [email, token, navigate, loginUrl]);

  if (!email || !token) {
    return null;
  }

  const canSubmit =
    !!newPassword &&
    !!newPasswordConfirmation &&
    formState !== FormState.loading &&
    passwordState === PasswordState.valid;

  return (
    <ModalContainer toggleOpen={() => null} isOpen width={540}>
      <S.Container>
        <S.XapienLogo />

        <S.Title level={5}>
          Xapien is a fully-automated research platform
        </S.Title>

        {formState === FormState.expired ||
        formState === FormState.newRequestSubmitted ? (
          renderRequestNewReset()
        ) : (
          <>
            {formState === FormState.error && (
              <ErrorBanner
                text="There has been a problem with your request. Please check and try again."
                onClick={onDismissError}
              />
            )}

            <S.Description>
              <S.Title level={4}>Choose your new password</S.Title>
            </S.Description>

            <S.Subtitle>
              It must be at least 8 characters and contain at least one of each
              of the following: capital letter, lower case letter, number and
              symbol.
            </S.Subtitle>

            <S.Inputs>
              <Input
                inputType="password"
                placeholder="New password"
                onChange={onPasswordChange}
                value={newPassword}
                autoComplete="new-password"
              />
              <Input
                inputType="password"
                placeholder="Confirm password"
                onChange={onPasswordConfirmationChange}
                value={newPasswordConfirmation}
                autoComplete="new-password"
              />
            </S.Inputs>

            {passwordState === PasswordState.valid ? (
              <S.PasswordValid>
                Password strength: Strong <Check />
              </S.PasswordValid>
            ) : (
              <S.PasswordInvalid>
                {getInvalidPasswordReason(passwordState)}
              </S.PasswordInvalid>
            )}
            <S.Warning>
              If you did not initiate this request, please contact us at{" "}
              <S.Link href="mailto:support@xapien.com">
                support@xapien.com
              </S.Link>
            </S.Warning>

            <ButtonNew
              onClick={onSubmit}
              type={ButtonType.Filled}
              size={ButtonSize.Medium}
              text={formState === FormState.loading ? "Submitting..." : "Go"}
              disabled={!canSubmit}
            />
          </>
        )}
      </S.Container>
    </ModalContainer>
  );
};

export default ResetPassword;
