import React, { useEffect, useMemo, useState } from "react";
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ChevronLeft } from "react-feather";

import { CollectionSearchContextProvider } from "util/hooks/useCollectionSearch";
import {
  CollectionListActions,
  CollectionListContextProvider,
  useCollectionList
} from "util/hooks/useCollectionList";

import CollectionSearch from "components/organisms/CollectionSearch";
import CollectionList from "components/organisms/CollectionList";
import ReportCard from "components/organisms/ReportCard";

import Api, { USER_REPORTS_COLLECTION_ID } from "api/userReports";
import { CollectionInputType } from "util/hooks/useCollectionList/types";
import Heading from "components/atoms/Heading";
import OrganisationApi from "api/organisations";
import Skeleton from "components/atoms/Skeleton";

import S from "./styles";

const UserReportsListing = () => {
  const { state, dispatch } = useCollectionList();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [searchParams] = useSearchParams();
  const queryUserName = searchParams.get("name");
  const queryUserEmail = searchParams.get("email");
  const OrgApi = useMemo(() => new OrganisationApi(), []);
  const [userName, setUserName] = useState<string | null>(queryUserName);
  const [userEmail, setUserEmail] = useState<string | null>(queryUserEmail);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getUserDetails = async () => {
      setLoading(true);
      const { response: details } = await OrgApi.getOrgUserDetails(userId!);
      setUserName(`${details?.firstName ?? ""} ${details?.lastName ?? ""}`);
      setUserEmail(details?.email ?? "");
      setLoading(false);
    };

    // If we can pull straight from the query params, this will provide us with immediate rendering, else if we can't,
    // fetch from endpoint
    if (!userName) {
      getUserDetails();
    }
  }, [OrgApi, userId, userName]);

  if (userId) {
    const collectionInputConfig = state.collections.find(
      collection => collection.id === USER_REPORTS_COLLECTION_ID
    );

    if (
      collectionInputConfig &&
      collectionInputConfig.input.type === CollectionInputType.list &&
      collectionInputConfig.input?.filterByUserId === undefined
    ) {
      // Hacky... We want to delay this dispatch to prevent chasing state else,
      // on mount, the collection list provider will trigger the first fetch of items. Within the same window,
      // we'll need to update the input field of the collection (to use as a param in the query).
      // The first fetch will complete, writing the status of the collection to be "complete"
      // and therefore preventing the refetch of the items with the new input param.
      setTimeout(
        () =>
          dispatch({
            type: CollectionListActions.updateCollectionInput,
            id: USER_REPORTS_COLLECTION_ID,
            input: {
              ...collectionInputConfig.input,
              filterByUserId: userId
            }
          }),
        100
      );
    }
  }

  return (
    <AnimatePresence>
      <S.ViewContainer>
        <S.ViewInnerContainer>
          <S.Breadcrumb
            onClick={() => {
              navigate(`/users`);
            }}
          >
            <ChevronLeft />
            Back to Users
          </S.Breadcrumb>
          <CollectionSearch
            placeholder={`Search ${userName}`}
            filterByUserId={userId!}
          />
          <CollectionList
            showCount
            CardComponent={ReportCard}
            navigationComponent={
              loading ? (
                <Skeleton width={150} height={38} />
              ) : (
                <Heading level={4}>{userName}</Heading>
              )
            }
            createItemComponent={
              loading ? (
                <Skeleton width={200} height={28} />
              ) : (
                <p>{userEmail ?? ""}</p>
              )
            }
            emptyCollectionText="No reports"
          />
        </S.ViewInnerContainer>
      </S.ViewContainer>
    </AnimatePresence>
  );
};

const UserReports = () => {
  return (
    <CollectionSearchContextProvider Api={Api}>
      <CollectionListContextProvider ConstructApi={Api}>
        <UserReportsListing />
      </CollectionListContextProvider>
    </CollectionSearchContextProvider>
  );
};

export default UserReports;
