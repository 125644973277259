import React, { useRef, useState } from "react";
import { Eye, Edit, User, Users, Trash2, Link } from "react-feather";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

import ModalContainer from "components/molecules/ModalContainer";
import Heading from "components/atoms/Heading";
import { ReportSharePermission } from "api/reports/types";
import useShareReport from "util/hooks/useShareReport";
import { SearchItemType } from "api/search";
import { UserTransferDetails } from "components/organisms/TransferOwnerModal/types";
import TransferOwnerModal from "components/organisms/TransferOwnerModal";
import TitleCard from "components/molecules/TitleCard";
import useUserSettings from "util/hooks/useUserSettings";
import DeleteShareLinkModal from "components/organisms/DeleteShareLinkModal";
import ShareListItem from "components/molecules/ListItemWithOptions";

import type { Report } from "api/reports";
import CreateLinkControl from "./CreateLinkControl";
import SharePermissionItem from "./SharePermissionItem";
import AddShareEntryControl from "./AddShareEntryControl";
import SharePermissionItemSkeleton from "./SharePermissionItemSkeleton";
import ShareConfirmedBanner from "./ShareConfirmedBanner";

import S, { classNameOverrides } from "./styles";

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  authorId: string;
  authorName: string;
  authorEmail: string;
  reportId: string;
  reportSubject: string;
  reportContext: string;
  reportImageSrc?: string;
  permissions: Report["permissions"];
}

const shareLinkOptions = [
  { id: "can_view", label: "Can view", icon: Eye },
  {
    id: "delete_link",
    label: "Delete link",
    icon: Trash2,
    className: classNameOverrides.deleteOption
  }
];

const ShareReportModal = ({
  isOpen,
  toggleOpen,
  authorId,
  authorName,
  authorEmail,
  reportId,
  reportSubject,
  reportContext,
  reportImageSrc,
  permissions
}: Props) => {
  const {
    sharedWith,
    generatedLink,
    toggleShareModal,
    toggleTransferOwnerModal,
    toggleDeleteShareLinkModal,
    isTransferOwnerModalOpen,
    isDeleteShareLinkModalOpen,
    isSharedWithLoading
  } = useShareReport();
  const { state } = useUserSettings();
  const { userId } = state.userDetails;

  const [userToTransferTo, setUserToTransferTo] = useState<UserTransferDetails>(
    { userId: "", title: "", subtitle: "" }
  );

  const shareSuccessRef = useRef<NodeJS.Timeout>();
  const [shareSuccess, setShareSuccess] = useState<{
    newShares: string[];
    success: boolean;
  }>({
    newShares: [],
    success: false
  });

  const onTransferOwnership = (userDetails: UserTransferDetails) => {
    setUserToTransferTo(userDetails);
    toggleShareModal();
    toggleTransferOwnerModal();
  };

  const getDropdownOptions = (itemType?: SearchItemType) => {
    return [
      { id: ReportSharePermission.Read, label: "Can view", icon: Eye },
      { id: ReportSharePermission.Write, label: "Can edit", icon: Edit },
      // Disable transfer ownership if they are not the owner or the share item is a group
      {
        id: ReportSharePermission.Transfer,
        label: "Transfer ownership",
        icon: User,
        showSeparator: true,
        disabled: itemType === SearchItemType.Group || authorId !== userId
      },
      {
        id: ReportSharePermission.Delete,
        label: "Remove access",
        icon: Trash2,
        className: classNameOverrides.deleteOption
      }
    ];
  };

  const onShareSuccess = (shares: string[]) => {
    // Existing share success timeout
    if (shareSuccessRef.current) {
      clearTimeout(shareSuccessRef.current);
    }

    setShareSuccess({ newShares: shares, success: true });

    const timeoutId = setTimeout(
      () => setShareSuccess({ newShares: [], success: false }),
      3000
    );
    shareSuccessRef.current = timeoutId;
  };

  const onDeleteLink = () => {
    toggleDeleteShareLinkModal();
    toggleShareModal();
  };

  return (
    <>
      <ModalContainer
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        onExitClick={toggleOpen}
        title={`Share report '${reportSubject}'`}
      >
        <S.ShareInputs>
          <Heading level={6}>Share with other users and groups</Heading>
          <AddShareEntryControl onShareSuccess={onShareSuccess} />
        </S.ShareInputs>
        <S.SharedWithList>
          <S.SharedWithTitle>People with access</S.SharedWithTitle>
          <S.SharedWithItemsContainer>
            {isSharedWithLoading && <SharePermissionItemSkeleton />}
            {!isSharedWithLoading && (
              <>
                <ShareListItem
                  options={shareLinkOptions}
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  icon={<Link />}
                  title="Anyone with the link"
                  subtitle={`Secure link ${
                    generatedLink ? "" : "(not created yet)"
                  }`}
                  selectedOption={shareLinkOptions[0]}
                  onSelectOption={option =>
                    option.id === shareLinkOptions[1].id && onDeleteLink()
                  }
                  className={
                    generatedLink
                      ? classNameOverrides.generatedLink
                      : classNameOverrides.nonGeneratedLink
                  }
                  disabled={!generatedLink}
                />
                <S.OwnerShareListItem
                  options={[]}
                  icon={<User />}
                  title={authorName}
                  subtitle={authorEmail}
                  selectedOption={{ id: "", label: "Owner", icon: User }}
                  onSelectOption={() => {}}
                  disabled
                />
                <AnimatePresence>
                  {sharedWith
                    .filter(share => share.id !== authorId)
                    .map((share, index) => (
                      <motion.div
                        key={share.id}
                        initial={{ height: 0 }}
                        animate={{
                          height: 48
                        }}
                        exit={{ height: 0 }}
                        transition={{ duration: 0.3, delay: index * 0.1 }}
                      >
                        <SharePermissionItem
                          permissions={permissions}
                          key={share.id}
                          shareEntryId={share.id}
                          title={share.title}
                          subtitle={share.subtitle}
                          icon={
                            share.type === SearchItemType.User ? (
                              <User />
                            ) : (
                              <Users />
                            )
                          }
                          options={getDropdownOptions(share.type)}
                          savedOption={
                            getDropdownOptions().find(option => {
                              return (
                                (option.id as string) ===
                                (share.permission as string)
                              );
                            })!
                          }
                          onTransferOwnership={onTransferOwnership}
                        />
                      </motion.div>
                    ))}
                </AnimatePresence>
              </>
            )}
          </S.SharedWithItemsContainer>
        </S.SharedWithList>
        {permissions.canViewShareLink && (
          <CreateLinkControl permissions={permissions} />
        )}
        <ShareConfirmedBanner shareSuccess={shareSuccess} />
      </ModalContainer>
      <TransferOwnerModal
        isOpen={isTransferOwnerModalOpen}
        toggleOpen={() => {
          toggleTransferOwnerModal();
          toggleShareModal();
        }}
        reportId={reportId}
        recipientUserId={userToTransferTo.userId}
      >
        <TitleCard title={reportSubject} subtitle={reportContext} />
        <div>To</div>
        <S.TitleCardContainer>
          <TitleCard
            title={userToTransferTo.title}
            subtitle={userToTransferTo.subtitle}
          />
        </S.TitleCardContainer>
      </TransferOwnerModal>
      <DeleteShareLinkModal
        isOpen={isDeleteShareLinkModalOpen}
        toggleOpen={() => {
          toggleDeleteShareLinkModal();
          toggleShareModal();
        }}
      >
        <TitleCard
          title={reportSubject}
          subtitle={reportContext}
          imageSrc={reportImageSrc}
        />
        <S.DeleteLinkContainer>{generatedLink}</S.DeleteLinkContainer>
        <Heading level={6}>
          Anyone using this link will lose access to this report
        </Heading>
        <S.DeleteLinkExplainer>
          You can create a new link and re-share at any time
        </S.DeleteLinkExplainer>
      </DeleteShareLinkModal>
    </>
  );
};

export default ShareReportModal;
