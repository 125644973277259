import { styled } from "@linaria/react";
import { Modal as RSModal } from "reactstrap";

import HeadingComponent from "components/atoms/Heading";

import { blue, standardColors, grey, green } from "styles/colors";
import { fontSize } from "styles/fonts";

const ExitButton = styled.button`
  all: unset;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;

  &:not(:focus-visible) {
    outline: none;
  }
`;

const Modal = styled(RSModal)`
  max-width: ${({ width }) => (width ? `${width}px` : "43rem")};

  & > div {
    border: ${({ variant }) => (variant === "light" ? "0" : "")};
    background-color: ${({ variant }) =>
      variant === "light" ? standardColors.white : blue.portalBlue};
    border-radius: 0.75rem;
    padding: ${({ variant }) => (variant === "light" ? 0 : "2rem")};
    position: relative;
    display: flex;
    gap: 1.5rem;
    color: ${({ variant }) =>
      variant === "light" ? grey.dark : standardColors.white};
  }
`;

const DarkHeading = styled(HeadingComponent)``;

const LightHeading = styled(HeadingComponent)`
  color: ${standardColors.white};
  background-color: ${green.xapienGreen};
  border-radius: 0.75rem 0.75rem 0 0;
  padding: 0.5rem 0.8rem 0.4rem;
  font-size: ${fontSize.md};
  display: flex;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }
`;

const ModalContent = styled.div`
  padding: 0 1rem 1rem;
`;

const S = {
  Modal,
  ExitButton,
  DarkHeading,
  LightHeading,
  ModalContent
};

export default S;
