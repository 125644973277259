import { css } from "@linaria/core";

import { fontSize } from "styles/fonts";

const title = css`
  font-size: ${fontSize.md} !important;
  margin-bottom: 0 !important;
  padding-bottom: 5px;

  &:first-letter {
    text-transform: capitalize;
  }
`;

const list = css`
  padding: 0 0 10px 0 !important;
  border: none !important;
`;

const classNameOverrides = {
  title,
  list
};

export { classNameOverrides };
