import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";

import TruncateLength from "util/TruncateLength";
import { usePrintableReportState } from "util/hooks/usePrintableState";

import {
  UNRESOLVABLE_ADDRESS_STRING,
  UNSPECIFIED_ADDRESS_STRING,
  generateUniqueCityString
} from "components/organisms/Locations/utils";

import S from "./styles";

const BAR_MAX_WIDTH = 222;
const BAR_MIN_WIDTH = 16;
const LOCATION_BAR_DISPLAY_LIMIT = 7;

const RiskImage = ({ assigner }) => {
  if (assigner === "EU") {
    return <S.European alt="risk" />;
  }
  if (assigner === "TransparencyInternational") {
    return <S.Transparency alt="risk" />;
  }
  if (assigner === "HMTreasury") {
    return <S.Treasury alt="risk" />;
  }
  return null;
};
const Bar = ({
  location,
  riskFlags,
  highest,
  count,
  addFilter,
  removeFilter,
  selectedCount,
  defaultSelectedCount,
  isParentRisky,
  isChild
}) => {
  const [isChecked, setChecked] = useState(selectedCount === count);

  const getConstrainedBarWidth = width => {
    if (width < BAR_MIN_WIDTH) {
      return `${BAR_MIN_WIDTH}px`;
    }

    if (width > BAR_MAX_WIDTH) {
      return `${BAR_MAX_WIDTH}px`;
    }

    return `${width}px`;
  };

  const getBarWidth = () => {
    const width = (count / highest) * BAR_MAX_WIDTH;

    return getConstrainedBarWidth(width);
  };

  const getDefaultSelectedBarWidth = () => {
    const width = (defaultSelectedCount / count) * BAR_MAX_WIDTH;

    return getConstrainedBarWidth(width);
  };

  const getSelectedBarWidth = () => {
    const width = (selectedCount / count) * BAR_MAX_WIDTH;

    return getConstrainedBarWidth(width);
  };

  const getInactiveBarWidth = () => {
    const width =
      ((count - (selectedCount > 0 ? selectedCount : defaultSelectedCount)) /
        count) *
      BAR_MAX_WIDTH;

    return getConstrainedBarWidth(width);
  };

  const toggleChecked = e => {
    e.preventDefault();
    const isLocationFilterSelected = !isChecked;
    setChecked(isLocationFilterSelected);

    if (isLocationFilterSelected) {
      addFilter(location);
    } else {
      removeFilter(location);
    }
  };

  useEffect(() => {
    if (isChild) {
      if (selectedCount > 0) {
        setChecked(true);
      } else {
        setChecked(false);
      }
    } else if (selectedCount === 0) {
      setChecked(false);
    } else if (selectedCount === count) {
      setChecked(true);
    }
  }, [selectedCount, isChild, count]);

  const inactiveCount =
    selectedCount > 0 ? count - selectedCount : count - defaultSelectedCount;

  const isDisabled = inactiveCount === count;
  const isPartiallySelected = selectedCount > 0 && selectedCount < count;
  const isBarFullyInactive =
    selectedCount === 0 || selectedCount === defaultSelectedCount;

  return (
    <>
      <S.LabelWrapper
        onClick={isDisabled ? null : toggleChecked}
        isDisabled={isDisabled}
        isChild={isChild}
      >
        <S.CheckboxAndLabel numRiskIcons={riskFlags?.length}>
          <input type="checkbox" id={location} defaultChecked={isChecked} />
          <S.Label
            isDisabled={isDisabled}
            htmlFor={location}
            isPartiallySelected={isPartiallySelected}
            isFullySelected={count === selectedCount}
            hasRisk={riskFlags?.length > 0 || isParentRisky}
          >
            <span>
              <TruncateLength>{location}</TruncateLength>
            </span>
          </S.Label>
        </S.CheckboxAndLabel>
        {riskFlags && riskFlags.length > 0 && (
          <S.RiskFlagsContainer>
            {riskFlags.map((r, i) => (
              <RiskImage key={i} assigner={r.assigner} />
            ))}
          </S.RiskFlagsContainer>
        )}
      </S.LabelWrapper>
      <S.CountryBarOuterWrapper
        isDisabled={isDisabled}
        onClick={isDisabled ? null : toggleChecked}
        alignTextRight={
          (selectedCount > 0 || defaultSelectedCount > 0) && inactiveCount > 0
        }
      >
        <S.CountryBarInnerWrapper barWidth={getBarWidth()}>
          {selectedCount > 0 && (
            <S.SelectedBar
              inactiveCount={inactiveCount === 0}
              barWidth={getSelectedBarWidth()}
              hasRisk={riskFlags?.length > 0 || isParentRisky}
            >
              <div>{selectedCount}</div>
            </S.SelectedBar>
          )}
          {selectedCount === 0 && defaultSelectedCount > 0 && (
            <S.DefaultSelectedBar
              inactiveCount={inactiveCount === 0}
              hasRisk={riskFlags?.length > 0 || isParentRisky}
              barWidth={getDefaultSelectedBarWidth()}
            >
              <div>{defaultSelectedCount}</div>
            </S.DefaultSelectedBar>
          )}
          {inactiveCount > 0 && (
            <S.InactiveBar
              isBarFullyInactive={isBarFullyInactive}
              hasZeroCount={selectedCount === 0 && defaultSelectedCount === 0}
              barWidth={getInactiveBarWidth()}
            >
              <div>{inactiveCount}</div>
            </S.InactiveBar>
          )}
        </S.CountryBarInnerWrapper>
      </S.CountryBarOuterWrapper>
    </>
  );
};

const Bars = ({
  expanded,
  country,
  filteredLocations,
  selectedCities,
  addCountry,
  removeCountry,
  activeFilter,
  addCity,
  removeCity,
  highest
}) => {
  const [isExpanded, setExpanded] = usePrintableReportState(
    `${country.countryName}-bar-expanded`,
    expanded
  );
  const toggleExpanded = () => setExpanded(!isExpanded);

  let uniqueCitiesWithCount = [];

  country.cities.forEach(c => {
    const index = uniqueCitiesWithCount.findIndex(u => u.cityName === c);

    if (index > -1) {
      uniqueCitiesWithCount[index] = {
        ...uniqueCitiesWithCount[index],
        count: uniqueCitiesWithCount[index].count + 1
      };
    } else {
      uniqueCitiesWithCount.push({
        cityName: c,
        count: 1
      });
    }
  });

  uniqueCitiesWithCount = uniqueCitiesWithCount.sort((a, b) => {
    // Push unspecified address filter to the bottom of the
    // list
    if (a.cityName === UNSPECIFIED_ADDRESS_STRING) {
      return 1;
    }

    if (b.cityName === UNSPECIFIED_ADDRESS_STRING) {
      return -1;
    }
    return b.count - a.count;
  });

  const getCountrySelectionCount = () => {
    return filteredLocations.filter(
      l =>
        l.filterInfo.countryName === country.countryName &&
        selectedCities &&
        selectedCities.includes(
          generateUniqueCityString(country.countryName, l.filterInfo.city)
        )
    ).length;
  };

  const getDefaultSelectedCount = () =>
    filteredLocations.filter(
      l => l.filterInfo.countryName === country.countryName
    ).length;

  const selectedCount = getCountrySelectionCount();
  const defaultSelectedCount = getDefaultSelectedCount();

  const inactiveCount =
    selectedCount > 0
      ? country.cities.length - selectedCount
      : country.cities.length - defaultSelectedCount;

  const isDisabled = inactiveCount === country.cities.length;

  return (
    <>
      <div className="location-bar-wrapper d-flex">
        {country.countryName !== UNRESOLVABLE_ADDRESS_STRING &&
        country.cities.length !== 0 ? (
          <button
            type="button"
            onClick={toggleExpanded}
            className={`location-bar__expand-country ${
              isDisabled ? "faded-button" : ""
            }`}
          >
            <S.CollapseArrow className={isExpanded ? "isExpanded" : ""} />
          </button>
        ) : (
          <span className="toggle-icon" />
        )}
        <Bar
          count={country.cities.length > 0 ? country.cities.length : 1}
          country={country}
          location={country.countryName}
          riskFlags={country.countryRiskFlags}
          highest={highest}
          addFilter={addCountry}
          removeFilter={removeCountry}
          selectedCount={selectedCount}
          activeFilter={activeFilter}
          defaultSelectedCount={getDefaultSelectedCount()}
          isChild={false}
        />
      </div>
      <Collapse isOpen={isExpanded}>
        <div>
          {uniqueCitiesWithCount.map(cityWithCount => {
            const defaultFilteredLocationsSelectedCount =
              filteredLocations.filter(l => {
                return (
                  l.filterInfo.city === cityWithCount.cityName &&
                  l.filterInfo.countryName === country.countryName
                );
              }).length;

            const selectedCityCount = selectedCities.includes(
              generateUniqueCityString(
                country.countryName,
                cityWithCount.cityName
              )
            )
              ? filteredLocations.filter(l => {
                  return (
                    l.filterInfo &&
                    l.filterInfo.city === cityWithCount.cityName &&
                    l.filterInfo.countryName === country.countryName
                  );
                }).length
              : 0;

            return (
              <div
                key={generateUniqueCityString(
                  country.countryName,
                  cityWithCount.cityName
                )}
                className="location-bar-wrapper d-flex"
              >
                <Bar
                  count={cityWithCount.count}
                  location={cityWithCount.cityName}
                  addFilter={cityToAdd =>
                    addCity(cityToAdd, country.countryName)
                  }
                  removeFilter={cityToRemove =>
                    removeCity(cityToRemove, country.countryName)
                  }
                  selectedLocations={selectedCities}
                  selectedCount={selectedCityCount}
                  defaultSelectedCount={defaultFilteredLocationsSelectedCount}
                  isParentRisky={country.countryRiskFlags?.length > 0}
                  isChild
                />
              </div>
            );
          })}
        </div>
      </Collapse>
    </>
  );
};

const LocationBar = props => {
  const {
    locations,
    selectedCities,
    setSelectedCities,
    activeFilter,
    filteredLocations
  } = props;

  const countries = locations.map(location => ({
    ...location.filterInfo,
    countryRiskFlags: location.countryRiskFlags
  }));

  let uniqueCountries = [];

  countries.forEach(country => {
    const index = uniqueCountries.findIndex(u => {
      if (u.countryName && country.countryName) {
        return (
          u.countryName?.toLowerCase() === country.countryName?.toLowerCase()
        );
      }
      return -1;
    });

    if (index > -1) {
      uniqueCountries[index] = {
        ...uniqueCountries[index],
        cities:
          country.countryName?.toLowerCase() !== country.city?.toLowerCase()
            ? [...uniqueCountries[index].cities, country.city]
            : uniqueCountries[index].cities
      };
    } else {
      uniqueCountries.push({
        ...country,
        countryName: country.countryName,
        cities:
          country.countryName?.toLowerCase() !== country.city?.toLowerCase()
            ? [country.city]
            : []
      });
    }
  });

  uniqueCountries = uniqueCountries.sort((a, b) => {
    // Push unresolved address filter to the bottom of the
    // list
    if (a.countryName === UNRESOLVABLE_ADDRESS_STRING) {
      return 1;
    }

    if (b.countryName === UNRESOLVABLE_ADDRESS_STRING) {
      return -1;
    }

    return (b.cities ? b.cities.length : 0) - (a.cities ? a.cities.length : 0);
  });

  const addCountry = country => {
    const filteredCities = filteredLocations.reduce((acc, location) => {
      if (
        location.filterInfo &&
        location.filterInfo.city &&
        location.filterInfo.countryName === country
      ) {
        return [
          ...acc,
          generateUniqueCityString(country, location.filterInfo.city)
        ];
      }
      return acc;
    }, []);

    setSelectedCities([...selectedCities, ...filteredCities]);
  };
  const removeCountry = country => {
    const updatedCities = selectedCities.filter(c => {
      const [countryPart] = c.split("::");
      return countryPart !== country;
    });
    setSelectedCities(updatedCities);
  };
  const addCity = (city, country) => {
    const cityString = generateUniqueCityString(country, city);
    setSelectedCities([...selectedCities, cityString]);
  };
  const removeCity = (city, country) => {
    const cityString = generateUniqueCityString(country, city);
    const updatedCities = selectedCities.filter(f => f !== cityString);
    setSelectedCities(updatedCities);
  };

  const highest = uniqueCountries.reduce((acc, country) => {
    const numberOfCities = country?.cities?.length;
    if (numberOfCities && numberOfCities > acc) {
      return numberOfCities;
    }

    return acc;
  }, 0);

  // Determine whether to expand location bar by default
  let remaining = LOCATION_BAR_DISPLAY_LIMIT - uniqueCountries.length;

  return (
    <S.LocationsBarContainer>
      {uniqueCountries
        .reverse()
        .map((uniqueCountry, i) => {
          let shouldDefaultExpand = false;
          const currentRemaining =
            remaining - [...new Set(uniqueCountry.cities)].length;
          if (currentRemaining >= 0 || i === 0) {
            shouldDefaultExpand = true;
            remaining = currentRemaining;
          }

          return (
            <Bars
              key={`${uniqueCountry.countryName}${i}`}
              country={uniqueCountry}
              highest={highest}
              addCountry={addCountry}
              removeCountry={removeCountry}
              selectedCities={selectedCities}
              addCity={addCity}
              removeCity={removeCity}
              activeFilter={activeFilter}
              filteredLocations={filteredLocations}
              expanded={shouldDefaultExpand}
            />
          );
        })
        .reverse()}
    </S.LocationsBarContainer>
  );
};

export default LocationBar;
