import { styled } from "@linaria/react";

import { grey, standardColors } from "styles/colors";
import { fontSize, family } from "styles/fonts";

import StickyExpandButton from "pages/report/StickyExpandButton";

import Event from "./Event";

const LineContainer = styled.div`
  position: absolute;
  width: ${({ isOnlyEvent }) => (isOnlyEvent ? 0 : 1)}px;
  background-color: ${grey.rule};
  height: 100%;
  left: ${({ isOnlyEvent }) => (isOnlyEvent ? "37px" : "calc(50% - 0.5px)")};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const LineTerminus = styled.div`
  height: 1px;
  width: 10px;
  background-color: ${grey.rule};
`;

// Covers the top of the timeline line so
// it doesn't poke through the top node.
const LineTopCover = styled.div`
  height: 13px;
  width: 1px;
  background-color: ${grey.panel};
`;

const Line = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${grey.rule};
`;

const TimelineContainer = styled.div`
  position: relative;
  padding: 0 35px;
  width: ${({ isOnlyEvent }) => (isOnlyEvent ? "700px" : "auto")};

  ul {
    padding: 0;
    margin: 0;
  }
`;

const TimelineEvent = styled(Event)`
  max-width: ${({ isOnlyEvent }) => (isOnlyEvent ? "550px" : "100%")};
`;

const ListItem = styled.li`
  list-style: none;
  position: relative;
  clear: both;

  ${TimelineEvent} {
    width: 50%;
    margin-top: -75px;
  }

  &:first-child {
    ${TimelineEvent} {
      margin-top: 0 !important;
    }
  }

  &:nth-child(even) {
    ${TimelineEvent} {
      float: left;
    }
  }

  &:nth-child(odd) {
    ${TimelineEvent} {
      float: right;
    }
  }
`;

const ClearingElement = styled.div`
  clear: both;
`;

const SectionContent = styled.div`
  background-color: ${grey.panel};
  width: 100%;
  padding: 10px 0;
  max-height: ${({ isResultsExpanded }) => {
    if (isResultsExpanded) {
      return "inherit";
    }

    return "670px";
  }};
  overflow-y: auto;
`;

const SectionBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  width: 100%;
  background-color: ${grey.panel};
  margin-bottom: 2px;
  padding: 0 16px;
`;

const SectionBannerInfo = styled.p`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const SectionCount = styled.span`
  font-size: ${fontSize["2xl"]};
  color: ${grey.dark};
`;

const CustomStickyExpandButton = styled(StickyExpandButton)`
  padding: 10px 16px;
  border-top: 2px solid ${standardColors.white};
`;

const Fluenci = styled.span`
  color: ${grey.dark};
  font-family: ${family.interSemiBold};
`;

const S = {
  ListItem,
  TimelineContainer,
  LineContainer,
  LineTopCover,
  LineTerminus,
  Line,
  TimelineEvent,
  ClearingElement,
  SectionContent,
  SectionBanner,
  SectionBannerInfo,
  SectionCount,
  CustomStickyExpandButton,
  Fluenci
};

export default S;
