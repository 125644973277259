import type { ContentNode } from "api/insight-reports";

const UNORDERED_LIST_REGEX =
  /\[BUL_LIST\]((?:\[BUL\].*?\[\/BUL\])+)\[\/BUL_LIST\]/s;
const ORDERED_LIST_REGEX =
  /\[NUM_LIST\]((?:\[NUM\].*?\[\/NUM\])+)\[\/NUM_LIST\]/s;
const LIST_CLOSING_TAGS_REGEX = /\[\/BUL\]|\[\/NUM\]/;
const LIST_OPENING_TAGS_REGEX = /\[BUL\]|\[NUM\]/;

const LIST_REGEX = new RegExp(
  `${UNORDERED_LIST_REGEX.source}|${ORDERED_LIST_REGEX.source}`
);

enum ListType {
  "unordered" = "unordered",
  "ordered" = "ordered"
}

const isUnorderedListType = (listType: ListType) =>
  listType === ListType.unordered;

const generateListString = ({
  listType,
  listItems,
  asHtml = false
}: {
  listType: ListType;
  listItems: string[];
  asHtml?: boolean;
}): string => {
  const getMarker = (index: number) =>
    isUnorderedListType(listType) ? "•" : `${index + 1})`;

  if (asHtml) {
    return (
      (isUnorderedListType(listType) ? "<ul>" : "<ol>") +
      listItems.map(item => `<li>${item}</li>`).join("") +
      (isUnorderedListType(listType) ? "</ul>" : "</ol>")
    );
  }

  return listItems
    .map((item, index) => `\n${getMarker(index)} ${item}`)
    .join("");
};

export const parseListString = (input: string, asHtml = false): string => {
  if (!LIST_REGEX.test(input)) return input;

  const listType = UNORDERED_LIST_REGEX.test(input)
    ? ListType.unordered
    : ListType.ordered;

  const match = isUnorderedListType(listType)
    ? input.match(UNORDERED_LIST_REGEX)
    : input.match(ORDERED_LIST_REGEX);

  if (!match) return input;

  const nonListBefore = input.slice(0, match.index);
  const nonListAfter = input.slice((match.index ?? 0) + match[0].length);
  const listPart = match[1];

  const listItems = listPart
    .split(LIST_CLOSING_TAGS_REGEX)
    .map(part => part.replace(LIST_OPENING_TAGS_REGEX, ""))
    .filter(Boolean);

  const listString = generateListString({
    listType,
    listItems,
    asHtml
  });

  return `${nonListBefore}\n${listString}\n${parseListString(
    nonListAfter,
    asHtml
  )}`;
};

const parseWhitespace = (input: string): string => {
  return input.replace(/\n/g, "<br/>");
};

export const copyToClipboard = ({
  richText,
  plainText
}: {
  richText: string;
  plainText: string;
}) => {
  if (typeof ClipboardItem === "undefined") {
    const clipboardFallback = async () => {
      await navigator.clipboard.writeText(plainText);
    };
    clipboardFallback();

    return;
  }

  const clipboardItem = new ClipboardItem({
    "text/plain": new Blob([plainText], { type: "text/plain" }),
    "text/html": new Blob([richText], { type: "text/html" })
  });

  navigator.clipboard.write([clipboardItem]);
};

export const renderAsPlainText = ({
  nodeType,
  value,
  content
}: ContentNode): string => {
  if (nodeType === "text") {
    return value;
  }

  const textContent =
    content?.map(contentNode => renderAsPlainText(contentNode)).join("") ?? "";

  return `${parseListString(textContent)}\n`;
};

export const renderAsRichText = ({
  nodeType,
  value,
  content
}: ContentNode): string => {
  const renderSubContent = () =>
    content?.map(contentNode => renderAsRichText(contentNode)).join("") ?? "";

  switch (nodeType) {
    case "h1": {
      return `<h1>${renderSubContent()}</h1>`;
    }

    case "h2": {
      return `<h2>${renderSubContent()}</h2>`;
    }

    case "h3": {
      return `<h3>${renderSubContent()}</h3>`;
    }

    case "h4": {
      return `<h4>${renderSubContent()}</h4>`;
    }

    case "div": {
      return renderSubContent();
    }

    case "p": {
      return `<p>${renderSubContent()}</p>`;
    }

    case "text": {
      return parseWhitespace(parseListString(value, true));
    }
    default: {
      return parseWhitespace(parseListString(value, true));
    }
  }
};
