import { useState } from "react";
import { useLocation } from "react-router-dom";

import { Subsection, Fragment } from "api/insight-reports/types";
import { useEnquiryId } from "util/hooks/useEnquiryId";
import { useSubjectName } from "util/hooks/useSubjectName";

import Api from "api/insight-reports";
import {usePersonaId} from "../usePersonaId";

export const useInsightsSources = ({ answer }: { answer: Subsection }) => {
  const [error, setError] = useState<string>();
  const [sourcedFragments, setSourcedFragments] = useState<Fragment[]>([]);
  const [loading, setLoading] = useState(false);
  const enquiryId = useEnquiryId();
  const personaId = usePersonaId();
  const subjectName = useSubjectName();
  const location = useLocation();

  const InsightsApi = new Api();

  const fetchSources = async () => {
    if (enquiryId && subjectName) {
      setLoading(true);
      setError(undefined);

      const params = new URLSearchParams(location.search);

      const { response, status, message } = await InsightsApi.getSources({
        reportId: enquiryId,
        personaId: personaId,
        subjectName,
        sectionId: "00000000-0000-0000-0000-000000000000",
        subsectionId: answer.id,
        shareToken: params.get("token")
      });

      if (!status) {
        setError(message);
        setSourcedFragments([]);
        return;
      }

      const fragments = response!.elements[0].cited_fragments.map(frag => ({
        text: frag.text,
        supportingSourceSentences: frag.supporting_source_sentences.map(
          sentence => ({
            text: sentence.text,
            title: sentence.title,
            date: sentence.date,
            wamSourceIds: sentence.wam_source_ids
          })
        )
      }));

      setSourcedFragments(fragments);
      setLoading(false);
    } else {
      console.error("Source fetching not properly configured");
      setSourcedFragments([]);
    }
  };

  return {
    sourcedFragments,
    fetchSources,
    error,
    loading
  };
};
