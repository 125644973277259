import React, {
  useState,
  useRef,
  CSSProperties,
  MouseEventHandler
} from "react";
import { inject, observer } from "mobx-react";

import Checkbox from "components/atoms/Checkbox";
import ConfirmationForm from "components/molecules/ConfirmationForm";
import Popover from "components/atoms/Popover";
import { ReactComponent as CrossInCircle } from "img/icons/cross-in-circle.svg";
import useViewerMode from "util/hooks/useViewerMode";
import { red } from "styles/colors";

import S from "./styles";

const HIDE_KILL_RISK_WARNING = "hide-kill-risk-warning";

interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onRiskRemoved?: () => void;
  isActive: boolean;
  removesAllInstancesOfRiskCategory?: boolean;
  sourceId?: string;
  label?: string;
  alwaysShowKillButton?: boolean;
  riskHierarchies?: string[][];
  ariaChecked?: boolean;
  ariaLabel?: string;
  reportStore?: any;
  color?: string;
  fillColor?: string;
  isKillable?: boolean;
  customModalText?: string;
  count?: number;
  isClickable?: boolean;
  className?: string;
  style?: CSSProperties;
}

const RiskPill = ({
  onClick = () => {},
  onRiskRemoved = () => {},
  isActive,
  label,
  count,
  alwaysShowKillButton,
  ariaLabel,
  ariaChecked,
  removesAllInstancesOfRiskCategory,
  reportStore,
  sourceId,
  riskHierarchies,
  color = red.directRisk,
  fillColor = red.directRisk,
  isKillable = true,
  customModalText,
  className,
  style,
  isClickable
}: Props) => {
  const [isKillButtonVisible, setIsKillButtonVisible] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isDismissWarningChecked, setIsDismissWarningChecked] = useState(false);
  const { isViewerModeEnabled } = useViewerMode();
  const isKillButtonDisabled =
    isViewerModeEnabled || !isKillable || reportStore.isReportSaving;

  const riskPillRef = useRef(null);
  const labelRef = useRef(null);
  const isSaving = reportStore.isReportSaving;

  const togglePopover = () => {
    // If we're closing the popover then reset
    // the checkbox state.
    if (isPopoverOpen) {
      setIsDismissWarningChecked(false);
    }

    setIsKillButtonVisible(false);
    setIsPopoverOpen(prevState => !prevState);
  };

  const removeRisk = () => {
    const updatedCategory = {
      riskHierarchies,
      sourceId
    };

    reportStore.removeRiskCategory(updatedCategory);

    onRiskRemoved();
  };

  const onKillClick: MouseEventHandler<HTMLDivElement> | undefined = e => {
    // Prevent the pill from triggering its click event
    e.stopPropagation();

    if (window.localStorage.getItem(HIDE_KILL_RISK_WARNING) === "true") {
      removeRisk();
      return;
    }

    togglePopover();
  };

  const renderKillButton = () => {
    return isSaving ? (
      // @ts-ignore
      <S.Tooltip tooltip="Saving in progress...">
        {isKillButtonDisabled ? (
          <S.KillButtonDisabled isActive={isActive} color={color}>
            <CrossInCircle />
          </S.KillButtonDisabled>
        ) : (
          <S.KillButton onClick={onKillClick} isActive={isActive} color={color}>
            <CrossInCircle />
          </S.KillButton>
        )}
      </S.Tooltip>
    ) : isKillButtonDisabled ? (
      <S.KillButtonDisabled isActive={isActive} color={color}>
        <CrossInCircle />
      </S.KillButtonDisabled>
    ) : (
      <S.KillButton onClick={onKillClick} isActive={isActive} color={color}>
        <CrossInCircle />
      </S.KillButton>
    );
  };

  const onRemoveRiskClick = () => {
    if (isDismissWarningChecked) {
      window.localStorage.setItem(HIDE_KILL_RISK_WARNING, "true");
    }

    removeRisk();
    togglePopover();
  };

  return (
    <Popover
      alignment="right"
      position="fixed"
      trigger="click"
      isOpenOverride={isPopoverOpen}
      onRequestClose={() => setIsPopoverOpen(false)}
      content={
        // @ts-ignore
        <ConfirmationForm
          header="Remove risk"
          body={
            removesAllInstancesOfRiskCategory ? (
              <>
                You are about to remove the risk category for <b>‘{label}’</b>.
              </>
            ) : (
              <>
                You are about to remove the risk <b>‘{label}’</b> from this
                article.
                <S.RemovalDisclaimer>
                  {customModalText ??
                    `
                     It will no longer be marked as a risk in this article, but could still be identified as risk in other content. To remove it for
                     all content, use the 'Risk categories' controls at the top of this section.
                    `}
                </S.RemovalDisclaimer>
              </>
            )
          }
          footerTertiaryNode={
            <Checkbox
              checked={isDismissWarningChecked}
              onChange={() =>
                setIsDismissWarningChecked(prevState => !prevState)
              }
              label="Don't ask me again"
              readOnly={undefined}
              style={undefined}
              className={undefined}
            />
          }
          secondaryActionString="Cancel"
          primaryActionString="Remove"
          isPrimaryActionDanger
          togglePopover={togglePopover}
          onPrimaryActionClick={onRemoveRiskClick}
          reportStore={reportStore}
        />
      }
      disableHideOnClip
    >
      <S.RiskPill
        ref={riskPillRef}
        className={className}
        style={style}
        onClick={onClick}
        isActive={isActive}
        ariaLabel={ariaLabel}
        isCountZero={count === 0}
        color={color}
        fillColor={fillColor}
        onMouseEnter={() => {
          if (isKillButtonDisabled) {
            return;
          }
          if (!isPopoverOpen) {
            setIsKillButtonVisible(true);
          }
        }}
        onMouseLeave={() => {
          if (isKillButtonDisabled) {
            return;
          }
          if (!isPopoverOpen) {
            setIsKillButtonVisible(false);
          }
        }}
        ariaChecked={ariaChecked}
        isClickable={isClickable}
      >
        <S.PillLabel>
          <div ref={labelRef}>
            {label} {count !== undefined ? "|" : ""}
          </div>
          <S.RightPart canKill={!isKillButtonDisabled}>
            {alwaysShowKillButton ? (
              renderKillButton()
            ) : (
              <S.CountDiv isKillButtonVisible={isKillButtonVisible}>
                <span>{count}</span>
                {!isKillButtonDisabled && (
                  <S.KillDiv
                    isKillButtonVisible={
                      isKillButtonVisible && !isKillButtonDisabled
                    }
                  >
                    {renderKillButton()}
                  </S.KillDiv>
                )}
              </S.CountDiv>
            )}
          </S.RightPart>
        </S.PillLabel>
      </S.RiskPill>
    </Popover>
  );
};

export default inject("reportStore")(observer(RiskPill));
