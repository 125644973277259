import React, { ReactNode, FC } from "react";
import { useCollectionList } from "util/hooks/useCollectionList";
import CollectionItem from "components/organisms/CollectionItem";
import type { CardVariant } from "components/molecules/Card/types";

import S from "./styles";

interface Props<T extends {id: string}>  {
  navigationComponent?: ReactNode;
  createItemComponent?: ReactNode;
  showCount?: boolean;
  showTitle?: boolean;
  showTitleCount?: boolean;
  sortable?: boolean;
  toggles?: ReactNode[];
  cardVariant?: CardVariant;
  CardComponent: FC<any>;
  filterItemsFn?: (item: T) => boolean;
  emptyCollectionText?: string;
}

const CollectionList = <T extends {id: string}> ({
  showCount,
  showTitle,
  showTitleCount,
  sortable,
  navigationComponent,
  createItemComponent,
  toggles,
  cardVariant,
  CardComponent,
  filterItemsFn,
  emptyCollectionText
}: Props<T>) => {
  const {
    state: { collections }
  } = useCollectionList();

  return (
    <S.Container>
      {collections
        .filter(item => !item.hidden)
        .map(
          (
            { id, title, items, totalItemCount, status, view, hiddenIfEmpty },
            index
          ) => (
            <CollectionItem
              key={`CollectionItem-${id}`}
              id={id}
              title={title}
              items={items}
              view={view}
              totalItemCount={totalItemCount}
              sortable={!!sortable}
              showCount={!!showCount}
              showTitle={!!showTitle}
              showTitleCount={!!showTitleCount}
              navigationComponent={
                index === 0 ? navigationComponent : undefined
              }
              createItemComponent={createItemComponent}
              status={status}
              toggles={index === 0 ? toggles : undefined}
              cardVariant={cardVariant}
              CardComponent={CardComponent}
              filterItemsFn={filterItemsFn}
              emptyCollectionText={emptyCollectionText}
              hiddenIfEmpty={hiddenIfEmpty}
            />
          )
        )}
    </S.Container>
  );
};

export default CollectionList;
