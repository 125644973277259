import React from "react";

const TagsNames = Object.freeze({
  Underline: "underline",
  Highlight: "highlight"
});

export const StyledCardText = props => {
  const { children } = props;
  const text = typeof children === typeof "" ? children : undefined;

  if (!(text && text.length)) {
    return false;
  }

  const textLabel = props.textLabel || "text";

  const splitStyledText = (tagName, t, existingStyles) => {
    const withoutEnd = [
      ...t.matchAll(
        new RegExp(
          `<${tagName}(?: style-id="([^"]*)")?>([\\s\\S]*?)<\\/${tagName}>`,
          "g"
        )
      )
    ]
      .map(match => {
        const styleId = match[1];
        const content = match[2];
        const start = match.index;
        const length = match[0].length;
        const stop = start + length;
        const styles = (existingStyles || []).concat([{ styleId, tagName }]);
        return { styles, content, start, stop, length };
      })
      .reduce((acc, curr) => {
        const prev = acc && acc.length && acc[acc.length - 1];
        if (prev) {
          if (prev.stop === curr.start) {
            return [...acc, curr];
          } else {
            const start = prev.stop;
            const stop = curr.start;
            const length = stop - start;
            const content = t.slice(start, stop);
            const styles = existingStyles || [];
            return [...acc, { start, stop, length, content, styles }, curr];
          }
        } else if (curr.start === 0) {
          return [curr];
        } else {
          const stop = curr.start;
          const content = t.slice(0, stop);
          const styles = existingStyles || [];
          return [
            { start: 0, stop, length: curr.start, content, styles },
            curr
          ];
        }
      }, []);
    const penultimate = withoutEnd.length && withoutEnd[withoutEnd.length - 1];

    if (penultimate) {
      if (penultimate.stop !== t.length) {
        return [
          ...withoutEnd,
          {
            styles: existingStyles || [],
            start: penultimate.stop,
            stop: t.length,
            length: t.length - penultimate.stop,
            content: t.slice(penultimate.stop, t.length)
          }
        ];
      } else {
        return withoutEnd;
      }
    } else {
      return [
        {
          start: 0,
          stop: t.length,
          length: t.length,
          content: t,
          styles: existingStyles || []
        }
      ];
    }
  };

  const bits = splitStyledText(TagsNames.Underline, text).flatMap(b =>
    splitStyledText(TagsNames.Highlight, b.content, b.styles)
  );

  const renderBit = (bit, index) => {
    const { content, styles } = bit;
    if (styles && styles.length) {
      const highlight = styles.some(s => s.tagName === TagsNames.Highlight);
      const underlineStyle = styles.find(
        s => s.tagName === TagsNames.Underline
      );
      const underlineStyleId = underlineStyle && underlineStyle.styleId;
      const className = `${highlight ? `highlight-${textLabel}` : ""} ${
        underlineStyleId
          ? `styled-card-text-underline underline-${underlineStyleId}`
          : ""
      }`;
      return (
        <span key={index} {...{ className }}>
          {content}
        </span>
      );
    } else {
      return content;
    }
  };
  return <>{bits.map(renderBit)}</>;
};
