import { createContext } from "react";

import type { RegistrationState, RegistrationAction } from "./types";
import { RegistrationActions, RegistrationStatus } from "./types";

export const initialState: RegistrationState = {
  token: "",
  tokenExpiry: "",
  email: "",
  firstName: "",
  lastName: "",
  jobTitle: "",
  password: "",
  confirmationPassword: "",
  status: RegistrationStatus.unregistered,
  termsAccepted: false,
  isMigration: false
};

export const registrationReducer = (
  state: RegistrationState,
  action: RegistrationAction
) => {
  switch (action.type) {
    case RegistrationActions.tokenExpired: {
      return { ...state, status: RegistrationStatus.tokenExpired };
    }
    case RegistrationActions.updateField: {
      return { ...state, [action.field]: action.value };
    }
    case RegistrationActions.updateTermsAccepted: {
      return { ...state, termsAccepted: action.value };
    }
    case RegistrationActions.registrationSubmitted: {
      return { ...state, status: RegistrationStatus.registrationSubmitted };
    }
    case RegistrationActions.registrationFailed: {
      return {
        ...state,
        status: RegistrationStatus.fieldsSubmitted,
        error: action.error
      };
    }
    case RegistrationActions.fieldsSubmitted: {
      return { ...state, status: RegistrationStatus.fieldsSubmitted };
    }
    case RegistrationActions.editFields: {
      return { ...state, status: RegistrationStatus.unregistered };
    }
    case RegistrationActions.dismissError: {
      return {
        ...state,
        error: undefined
      };
    }
    default: {
      return state;
    }
  }
};

export const RegistrationContext = createContext({
  state: initialState,
  dispatch: (_action: RegistrationAction) => {}
});
