import React, { useContext, useMemo } from "react";
import ErrorBoundary from "util/ErrorBoundary";

import { UserVerificationProvider } from "util/hooks/useUserVerification";
import {
  ReportPrintableStateContext,
  GlobalPrintableStateContext
} from "util/hooks/usePrintableState";
import { ImageStoreContext } from "state/ImageStore";
import { ActiveFeatuesContextProvider } from "services/features";
import {
  Version,
  VersionContextProvider,
  currentVersion
} from "services/version";
import { ErrorOldReport } from "pages/report/ErrorOldReport";
import { EnquiryIdContext } from "util/hooks/useEnquiryId";
import { PersonaIdContext } from "util/hooks/usePersonaId";
import { EntityTypeContextProvider } from "util/context/EntityTypeContext";
import { SourceMappingContext } from "util/hooks/useSourceMapping/context";
import { OldInsightsReportProvider } from "util/hooks/useOldInsightsReport/provider";
import { InsightReportProvider } from "util/hooks/useInsightReport";
import { SubjectNameContext } from "util/hooks/useSubjectName/context";
import { ReportSubjectEntityTypeProvider } from "util/hooks/useReportSubjectEntityType/provider";
import { EntityType } from "util/hooks/useReportSubjectEntityType/context";

/// sets up various contexts for a given report
export const ReportContextProvider = props => {
  const { report, enquiryId, personaId, imageStore, children } = props;

  const imageStoreToUse = useMemo(() => {
    if (!imageStore) {
      console.error("No image store");
      return new Map();
    }
    return imageStore;
  }, [imageStore]);

  const { state, setState, removeState } = useContext(
    GlobalPrintableStateContext
  );

  const removeEnquiryIdRegExp = new RegExp(`${enquiryId}-(.*)`);
  const reportPrintableState = useMemo(
    () => {
      const stateMap = new Map();
      (state || new Map()).forEach((v, k) => {
        try {
          const reMatch = k.match(removeEnquiryIdRegExp);
          if (reMatch) {
            stateMap.set(reMatch[1], v);
          }
        } catch (e) {
          console.error("Error loading print state", { e, k, v });
        }
      });
      return stateMap;
    },
    // TODO: include missing dependencies
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [state]
  );
  const reportPrintableStateStore = useMemo(
    () => {
      return {
        state: reportPrintableState,
        setState: (k, v, type) => setState(`${enquiryId}-${k}`, v, type),
        removeState: k => removeState(`${enquiryId}-${k}`)
      };
    },
    // TODO: include missing dependencies
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [reportPrintableState]
  );
  const version =
    report.generationVersion &&
    new Version(
      report.generationVersion.major,
      report.generationVersion.minor,
      report.generationVersion.patch
    );

  const isVersionAfterOrEqual =
    version && version.isAfterOrEqualTo(currentVersion);

  // eslint-disable-next-line react/no-unstable-nested-components
  const ErrorFallback = ({ error }) => {
    // Backwards compat for contexts that are an array of strings vs. objects
    const contextValues = report.contexts?.map(context => {
      if (typeof context === "object" && context !== null) {
        return context.item;
      }
      return context;
    });

    return (
      <ErrorOldReport
        error={error}
        subject={report.subject}
        contexts={contextValues}
        currentVersion={currentVersion.toString()}
        reportVersion={version.toString()}
      />
    );
  };

  return (
    <UserVerificationProvider>
      <ImageStoreContext.Provider value={imageStoreToUse}>
        <ReportPrintableStateContext.Provider value={reportPrintableStateStore}>
          <ActiveFeatuesContextProvider featureNames={report.activeFeatures}>
            <EnquiryIdContext.Provider value={enquiryId}>
              <PersonaIdContext.Provider value={personaId}>
                <VersionContextProvider version={version}>
                  <EntityTypeContextProvider
                    value={report.reportMetadata?.subjectType}
                  >
                    <InsightReportProvider>
                      <ReportSubjectEntityTypeProvider
                        subjectEntityType={
                          report.reportMetadata?.subjectType === "Person"
                            ? EntityType.Person
                            : EntityType.Organisation
                        }
                      >
                        <SubjectNameContext.Provider value={report.subject}>
                          <SourceMappingContext.Provider
                            value={report?.reportAbstract?.sourceMapping}
                          >
                            <OldInsightsReportProvider>
                              {isVersionAfterOrEqual ? (
                                children
                              ) : (
                                <ErrorBoundary
                                  FallbackComponent={ErrorFallback}
                                  onError={e =>
                                    console.error("Error showing old report ", {
                                      e,
                                      currentVersion: currentVersion.toString(),
                                      version: version.toString()
                                    })
                                  }
                                >
                                  {children}
                                </ErrorBoundary>
                              )}
                            </OldInsightsReportProvider>
                          </SourceMappingContext.Provider>
                        </SubjectNameContext.Provider>
                      </ReportSubjectEntityTypeProvider>
                    </InsightReportProvider>
                  </EntityTypeContextProvider>
                </VersionContextProvider>
              </PersonaIdContext.Provider>
            </EnquiryIdContext.Provider>
          </ActiveFeatuesContextProvider>
        </ReportPrintableStateContext.Provider>
      </ImageStoreContext.Provider>
    </UserVerificationProvider>
  );
};
