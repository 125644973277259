import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import { grey, purple, standardColors } from "styles/colors";
import { fontSize, family } from "styles/fonts";
import TruncateLength from "util/TruncateLength";

const NoData = styled.div`
  font-style: italic;
  color: ${grey.ghost};
  font-size: ${fontSize.sm};
`;

const QuoteSuperscript = styled.sup`
  top: -2px;
`;

const QuoteContainer = styled.span`
  font-size: ${fontSize.sm};
`;

const Quote = styled.q`
  font-style: italic;
`;

const RiskSummaryTitle = styled.div`
  font-size: ${fontSize.md};
  font-family: ${family.interDisplay};
  max-width: 473px;
  margin-top: ${({ isFirstInList }) => (isFirstInList ? "0" : "16px")};
  font-family: ${family.interSemiBold};
`;

const RiskCategories = styled.div`
  margin-top: 8px;
  font-size: ${fontSize.sm};
`;

const RiskSummaryDate = styled.div`
  font-size: ${fontSize.sm};
  margin-bottom: 5px;
  color: ${grey.mid};
`;

const SectionContainer = styled.div`
  border: 1px solid ${standardColors.white};
  border-left: 0;
  border-top: 0;
  padding: 16px;
  background-color: ${grey.panel};
  page-break-inside: avoid;
`;

const SectionHeader = styled.div`
  margin-bottom: 10px;
  font-size: ${fontSize.lg};
  color: ${grey.dark};
  font-family: ${family.interDisplay};
`;

const SanctionReason = styled.span`
  font-family: ${family.interLight};
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0 0 0 10px;
  padding-left: 0;
`;

const SanctionedStatement = styled.div`
  font-size: ${fontSize.sm};
`;

const Dates = styled.span`
  display: inline-block;
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

const MonetaryValue = styled.span`
  font-size: ${fontSize.sm};
`;

const SanctionItem = styled.div`
  padding-bottom: 8px;
  display: flex;
`;

const ReferenceSuperscript = styled.sup`
  color: ${grey.mid};
  font-family: ${family.interLight};
  padding-right: 5px;
`;

const RiskCategoryPill = styled.div`
  padding: 0px 8px;
  border: 1px solid ${grey.mid};
  border-radius: 16px;
  font-size: ${fontSize.sm};
  height: 21px;
  text-transform: capitalize;
  color: ${grey.mid};
  margin: 0 4px 4px 0;
`;

const RiskFlags = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`;

const Content = styled.div`
  background-color: ${grey.panel};
`;

const SanctionSource = styled.span`
  font-size: ${fontSize.sm};
`;

const SanctionDates = styled.span`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

const NoDate = styled.i``;

const SanctionsFooter = styled.footer`
  font-size: ${fontSize.sm};
  padding-top: 10px;
  color: ${grey.mid};
`;

const Info = styled.div`
  height: 38px;
  background-color: ${grey.panel};
  padding: 8px 16px;
  font-size: ${fontSize.sm};
  color: ${purple.mid};
`;

const Truncate = styled(TruncateLength)`
  display: inline;
`;

/** Classname overrides */

const quoteListItem = css`
  padding-bottom: 8px;
`;

const listItem = css`
  padding-bottom: 8px;
`;

const sourceSuperscript = css`
  &:after {
    /* This is overriding the existing :after rule so the !important
    is required */
    content: "" !important;
  }
`;

const listOfSummaries = css`
  /* Prevents FireFox bug where items would sometimes overlap.
   https://digital-insight.atlassian.net/browse/DI-3842
 */
  & > ul {
    display: flex;
    flex-direction: column;
  }
`;

const masonryGrid = css`
  display: flex;
  width: auto;
`;

const masonryColumn = css`
  background-clip: padding-box;
  &:not(:last-child) {
    border-right: 2px solid white;
  }

  & > div {
    border-top: 2px solid white !important;
    /* Overriding 'list' styling */
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
  }
`;

const S = {
  NoData,
  QuoteContainer,
  Quote,
  QuoteSuperscript,
  RiskSummaryTitle,
  RiskCategories,
  RiskSummaryDate,
  SectionContainer,
  SectionHeader,
  SanctionReason,
  SanctionedStatement,
  List,
  Dates,
  MonetaryValue,
  SanctionItem,
  ReferenceSuperscript,
  RiskCategoryPill,
  RiskFlags,
  Content,
  SanctionSource,
  SanctionDates,
  NoDate,
  SanctionsFooter,
  Info,
  Truncate
};

export const classNameOverrides = {
  quoteListItem,
  sourceSuperscript,
  listOfSummaries,
  masonryGrid,
  masonryColumn,
  listItem
};

export default S;
