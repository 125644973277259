import { styled } from "@linaria/react";

import { fontSize, family } from "styles/fonts";
import { grey, red, standardColors } from "styles/colors";
import { hexToRgb } from "styles/hexToRgb";
import StickyExpandButton from "pages/report/StickyExpandButton";
import ShowResultsButton from "pages/report/ShowResultsButton";

import { ReactComponent as CrossInCircle } from "img/icons/cross-in-circle.svg";

import Button from "components/atoms/Button";

const Panel = styled.div`
  position: relative;
  width: 100%;
  background-color: ${grey.panel};
  page-break-inside: avoid;
  padding: 16px;
  min-height: 30px;
`;

const SectionHeader = styled.div`
  min-height: 36px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${grey.rule};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FilterHeader = styled.div`
  color: ${grey.mid};
  font-size: ${fontSize.sm};
  padding-right: 15px;
`;

const ResultsSection = styled.div`
  max-height: ${({ isResultsExpanded, isResultsShown }) => {
    if (isResultsExpanded) {
      return "inherit";
    }

    if (isResultsShown) {
      return "600px";
    }

    return 0;
  }};
  overflow-y: auto;
  background-color: ${grey.panel};
`;

const SectionTitle = styled.h1`
  font-size: ${fontSize.lg};
  font-family: ${family.interDisplay};
  margin: 0;
  padding-right: 10px;
`;

const SectionTotal = styled.h1`
  margin: 0;
`;

const ActiveFiltersContainer = styled.div`
  display: flex;
  min-height: 32px;
`;

const ActiveFilterPillsContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 32px;
  flex-wrap: wrap;
`;

const ActiveFilterPill = styled.div`
  font-size: ${fontSize.sm};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 17px;
  color: ${standardColors.white};
  padding: 1px 7px 1px 11px;
  display: flex;
  text-transform: capitalize;
  justify-content: space-between;
  margin-left: 10px;
`;

const ActiveFilterPillLabel = styled.span`
  padding-right: 5px;
  font-size: ${fontSize.sm};
`;

const ActiveFiltersLabel = styled.span`
  color: ${grey.mid};
  font-size: ${fontSize.sm};
  margin: auto 0;
  min-width: 90px;
`;

const CrossIcon = styled(CrossInCircle)`
  height: 20px;
  width: 20px;

  circle {
    stroke: transparent;
  }

  &:hover {
    cursor: pointer;
  }
`;

const ResetFiltersButton = styled(Button)`
  min-width: 50px;
  color: ${grey.mid} !important;
  margin-left: 10px;

  &:hover {
    border-bottom: 1px solid ${grey.rule} !important;
  }
`;

const ShowDetailButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const DetailButtonDividerLine = styled.div`
  height: 1px;
  background-color: ${grey.rule};
  flex-grow: 0.5;
`;

const ShowAdditionalFiltersButton = styled(Button)`
  color: ${grey.mid} !important;
  padding: 0 14px !important;
  min-height: 27px !important;
  min-width: 110px;
  background-color: transparent !important;
  font-size: ${fontSize.sm} !important;
  margin: 0 12px;
  border: 1px solid ${grey.rule} !important;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px rgba(210, 211, 216, 0.33) !important;
  }
`;

const TopSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 16px;
`;

const LeftPaneContainer = styled.div`
  width: 100%;
  padding-right: 25px;
`;

const RightSectionContainer = styled.div`
  /* When there's no data in the left panel the Show detail button
     can get too close to the right hand panel. We're just adding
     a bit of breathing room here.
  */
  margin-bottom: ${({ isLeftPanelEmpty }) => (isLeftPanelEmpty ? "6px" : 0)};
  width: 100%;
  padding-left: 25px;
`;

const TotalMediaCount = styled.div`
  font-size: ${fontSize.xl};
  text-align: right;
`;

const RiskCategoryTitle = styled.div`
  font-family: ${family.interSemiBold};
  font-size: ${fontSize.sm};
  padding-bottom: 10px;
`;

const CategoriesScrollContainer = styled.div`
  max-height: 310px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const RiskCategoryContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  padding: 0 4px;
  gap: 6px;
`;

const RiskCategoryPill = styled.div`
  padding: 0px 8px;
  border: 1px solid
    ${({ isCountZero }) => (isCountZero ? grey.ghost : red.directRisk)};
  border-radius: 16px;
  margin-right: 6px;
  transition: box-shadow 0.2s;
  font-size: ${fontSize.sm};
  margin-bottom: 6px;
  text-transform: capitalize;
  background-color: ${({ isActive }) =>
    isActive ? red.directRisk : "transparent"};
  color: ${({ isCountZero, isActive }) => {
    if (isCountZero) {
      return grey.ghost;
    }
    if (isActive) {
      return standardColors.white;
    }
    return red.directRisk;
  }};

  &:hover {
    box-shadow: 0 0 0 4px
      rgba(
        ${({ isCountZero }) => {
          if (!isCountZero) {
            const { r, g, b } = hexToRgb(red.directRisk);
            return `${r}, ${g}, ${b}`;
          }
          return "";
        }},
        0.25
      );
    cursor: ${({ isCountZero }) => (isCountZero ? "default" : "pointer")};
  }
`;

const SourceFilterPill = styled.div`
  display: flex;
  align-items: center;
`;

const SourceFilterPillLabelContainer = styled.span`
  padding: 0 3px 0 3px;
  display: flex;
  align-items: center;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

const SourceFilterPillLabel = styled.span`
  padding-right: ${({ isLastLabel }) => (isLastLabel ? 0 : 6)}px;
`;

const CustomStickyExpandButton = styled(StickyExpandButton)`
  background-color: ${grey.panel};
`;

const RiskCategories = styled.div`
  padding-bottom: 20px;
`;

const MediaShowResultsButton = styled(ShowResultsButton)`
  padding-top: 18px;
  padding-bottom: ${({ isShowingResults }) =>
    isShowingResults ? "22px" : "10px"};
`;

const NoneIdentified = styled.div`
  color: ${grey.ghost};
  font-size: ${fontSize.sm};
  font-style: italic;
`;

const RiskCountButton = styled(Button)`
  background-color: ${grey.faint} !important;
  font-size: ${fontSize.sm};
  color: ${grey.mid} !important;
  padding: 0 10px !important;
  min-height: 21px !important;
  border: 1px solid transparent !important;
  min-width: 10px !important;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px
      ${() => {
        const { r, g, b } = hexToRgb(grey.ghost);
        return `rgba(${r}, ${g}, ${b}, 0.3)`;
      }} !important;
  }
`;
const TooltipContent = styled.div`
  text-align: left;
  padding: 2px;
  width: ${({ width }) => width ?? 275}px;
  max-width: 275px;
  max-height: 325px;
  overflow-y: auto;
`;

const S = {
  Panel,
  SectionHeader,
  ResultsSection,
  FilterHeader,
  SectionTitle,
  SectionTotal,
  ActiveFiltersContainer,
  ActiveFilterPillsContainer,
  ActiveFilterPill,
  ActiveFilterPillLabel,
  ActiveFiltersLabel,
  CrossIcon,
  ResetFiltersButton,
  ShowDetailButtonContainer,
  DetailButtonDividerLine,
  ShowAdditionalFiltersButton,
  TotalMediaCount,
  RiskCategoryTitle,
  RiskCategoryPill,
  RiskCategoryContainer,
  CategoriesScrollContainer,
  TopSectionContainer,
  LeftPaneContainer,
  RightSectionContainer,
  SourceFilterPill,
  SourceFilterPillLabelContainer,
  SourceFilterPillLabel,
  CustomStickyExpandButton,
  RiskCategories,
  MediaShowResultsButton,
  NoneIdentified,
  RiskCountButton,
  TooltipContent
};

export default S;
