import { useContext } from "react";

import { InsightReportContext, isShowingInsightsReport } from "./context";
import { InsightReportProvider } from "./provider";
import { InsightReportActions } from "./types";

const useInsightReport = () => {
  const context = useContext(InsightReportContext);

  return context;
};

export {
  useInsightReport,
  InsightReportContext,
  InsightReportProvider,
  InsightReportActions,
  isShowingInsightsReport
};
