// @ts-nocheck
import { styled } from "@linaria/react";
import { motion } from "framer-motion/dist/framer-motion";

import { standardColors, grey } from "styles/colors";
import { fontSize, family } from "styles/fonts";

interface PopoverProps {
  position: string;
  borderRadius: number;
}

const Popover = styled(motion.div)<PopoverProps>`
  position: ${(props: PopoverProps) => props.position};
  background-color: ${standardColors.white};
  color: ${grey.dark};
  padding: 0;
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};

  font-family: ${family.interLight};
  z-index: 30;
  box-shadow: 0px 0px 26px 0px #0000007d;
  font-size: ${fontSize.sm};
  overflow-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const Arrow = styled.div<{ left: string; top: string }>`
  z-index: -1;
  position: absolute;
  background: ${grey.panel};
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  left: ${props => props.left};
  top: ${props => props.top};
`;

const ContentContainer = styled.div<{ maxWidth: string }>`
  max-width: ${({ maxWidth }) => maxWidth};
`;

const S = {
  Popover,
  Arrow,
  ContentContainer
};

export default S;
