import React, { FC, Fragment } from "react";

import S from "./styles";

interface NavigationItem {
  title?: string;
  onClick: () => void;
  isActive: boolean;
}

interface Props {
  items: NavigationItem[];
}

const CollectionNavigation: FC<Props> = ({ items }) => {
  return (
    <S.Container>
      {items.map(({ title, isActive, onClick }, i) => (
        <Fragment key={`CollectionNavigationItem-${title}`}>
          <S.NavItem isActive={isActive} onClick={onClick}>
            {title}
          </S.NavItem>
          {i !== items.length - 1 && <S.Divider />}
        </Fragment>
      ))}
    </S.Container>
  );
};

export default CollectionNavigation;
