import SharedUserInterfaceStore from "./SharedUserInterfaceStore";
import DefineStore from "./DefineStore";
import ReportStore from "./ReportStore";
import EnquiryStore from "./EnquiryStore";
import DiagnosticsStore from "./DiagnosticsStore";
import EnquirySummaryStore from "./EnquirySummaryStore";
import FeatureStore from "./FeatureStore";
import { UserAssessmentStore } from "./UserAssessmentStore";

export const sharedUserInterfaceStore = new SharedUserInterfaceStore();
export const enquiryStore = new EnquiryStore(sharedUserInterfaceStore);
export const defineStore = new DefineStore(sharedUserInterfaceStore);
export const reportStore = new ReportStore(sharedUserInterfaceStore);
export const diagnosticsStore = new DiagnosticsStore(sharedUserInterfaceStore);
export const enquirySummaryStore = new EnquirySummaryStore(
  sharedUserInterfaceStore
);
export const featureStore = new FeatureStore(sharedUserInterfaceStore);
export const userAssessmentStore = new UserAssessmentStore(
  sharedUserInterfaceStore,
  reportStore
);
