import React from "react";
import { NavItem } from "reactstrap";

import ReactTooltip from "components/atoms/ReactTooltip";
import { ReactComponent as Share } from "img/icons/menu/reportPage/icon_share_report.svg";
import useShareReport from "util/hooks/useShareReport";
import ShareReportModal from "components/organisms/ShareReportModal";
import { ReportPermissions } from "api/reports/types";

import S from "./styles";

interface Props {
  authorId: string;
  authorName: string;
  authorEmail: string;
  reportId: string;
  reportSubject: string;
  reportContext: string;
  reportImageSrc: string;
  permissions: ReportPermissions;
}

const ShareReportButton = ({
  authorId,
  authorEmail,
  authorName,
  reportId,
  reportContext,
  reportImageSrc,
  reportSubject,
  permissions
}: Props) => {
  const { toggleShareModal, isShareModalOpen } = useShareReport();

  return (
    <>
      <NavItem onClick={() => toggleShareModal()}>
        <S.ShareButtonContainer>
          <ReactTooltip
            tooltip="Share report"
            open={undefined}
            style={undefined}
            className={undefined}
          >
            <S.ShareButton aria-label="Share report">
              <Share />
            </S.ShareButton>
          </ReactTooltip>
        </S.ShareButtonContainer>
      </NavItem>
      <ShareReportModal
        isOpen={isShareModalOpen}
        toggleOpen={toggleShareModal}
        authorId={authorId}
        authorName={authorName}
        authorEmail={authorEmail}
        reportId={reportId}
        reportSubject={reportSubject}
        reportContext={reportContext}
        reportImageSrc={reportImageSrc}
        permissions={permissions}
      />
    </>
  );
};

export default ShareReportButton;
