import React, { Fragment } from "react";

import ScreeningInfographic from "components/molecules/InfoGraphic/ScreeningInfoGraphic";
import { personReportSectionTitles } from "util/reportSectionTitles";
import { SCREENING_FILTERS, SCREENING_LIST_TYPES } from "util/screeningUtils";
import { REPORT_TYPES } from "util/reportTypes";

const ThemedScreeningInfographics = ({
  screeningData,
  reportSectionRefs,
  reportType,
  CustomInfographic
}) => {
  const confirmedSanctionsData = screeningData?.sanctions?.confirmed;
  const unconfirmedSanctionsData = screeningData?.sanctions?.unconfirmed;
  const confirmedWatchlistsData = screeningData?.watchlists?.confirmed;
  const unconfirmedWatchlistsData = screeningData?.watchlists?.unconfirmed;
  const confirmedPepsData = screeningData?.peps?.confirmed;
  const unconfirmedPepsData = screeningData?.peps?.unconfirmed;

  const calculateScreeningCounts = screeningData => {
    return screeningData?.reduce((total, entity) => {
      return (total += entity?.listings?.length ?? 0);
    }, 0);
  };

  const confirmedSanctionsCount =
    calculateScreeningCounts(confirmedSanctionsData) ??
    screeningData?.sanctions?.confirmedCount; // Backwards compat
  const confirmedWatchlistsCount =
    calculateScreeningCounts(confirmedWatchlistsData) ??
    screeningData?.watchlists?.confirmedCount; // Backwards compat
  const confirmedPepsCount =
    calculateScreeningCounts(confirmedPepsData) ??
    screeningData?.peps?.confirmedCount; // Backwards compat
  const unconfirmedSanctionsCount = calculateScreeningCounts(
    unconfirmedSanctionsData
  );
  const unconfirmedWatchlistsCount = calculateScreeningCounts(
    unconfirmedWatchlistsData
  );
  const unconfirmedPepsCount = calculateScreeningCounts(unconfirmedPepsData);

  return (
    <Fragment>
      <ScreeningInfographic
        confirmedScreeningData={confirmedSanctionsData}
        confirmedScreeningCount={confirmedSanctionsCount}
        unconfirmedScreeningCount={unconfirmedSanctionsCount}
        listType={SCREENING_LIST_TYPES.sanctions}
        entityType={reportType}
        sectionToJumpToRef={
          reportSectionRefs &&
          reportSectionRefs[personReportSectionTitles.SCREENING]
        }
        listLabel={SCREENING_FILTERS.sanctions.label}
      />
      <ScreeningInfographic
        confirmedScreeningData={confirmedWatchlistsData}
        confirmedScreeningCount={confirmedWatchlistsCount}
        unconfirmedScreeningCount={unconfirmedWatchlistsCount}
        listType={SCREENING_LIST_TYPES.watchlists}
        entityType={reportType}
        sectionToJumpToRef={
          reportSectionRefs &&
          reportSectionRefs[personReportSectionTitles.SCREENING]
        }
        listLabel={SCREENING_FILTERS.watchLists.label}
      />
      {reportType === REPORT_TYPES.person ? (
        <ScreeningInfographic
          confirmedScreeningData={confirmedPepsData}
          confirmedScreeningCount={confirmedPepsCount}
          unconfirmedScreeningCount={unconfirmedPepsCount}
          listType={SCREENING_LIST_TYPES.peps}
          entityType={reportType}
          sectionToJumpToRef={
            reportSectionRefs &&
            reportSectionRefs[personReportSectionTitles.SCREENING]
          }
          listLabel={SCREENING_FILTERS.peps.label}
        />
      ) : (
        // Organisation reports share the same State owned companies infographic for both PDX, and non PDX reports.
        // So this is passed through at the level of the OrganisationOverview component.
        CustomInfographic
      )}
    </Fragment>
  );
};

export default ThemedScreeningInfographics;
