import React, { useRef, useContext, useState, useEffect, useMemo } from "react";

import Masonry from "react-masonry-css";
import { motion } from "framer-motion/dist/framer-motion";

import { UserVerificationContext } from "util/hooks/useUserVerification";
import {
  PRINTABLE_STATE_TYPES,
  usePrintableReportState
} from "util/hooks/usePrintableState";
import {
  CONFIDENCE_CATEGORIES,
  DOWJONES_CONVERSION
} from "util/confidenceCategories";
import ConfidenceDropDownMenu from "components/molecules/ConfidenceDropDownMenu";
import { AssessmentOptions } from "pages/report/AssessmentOptions";
import { usePreviousValue } from "util/hooks/usePreviousValue.js";
import SectionFooter from "components/atoms/SectionFooter";
import DropdownButton from "components/molecules/DropdownButton";
import ModalWithTabs from "components/molecules/ModalWithTabs";
import { ReactComponent as BuildingIcon } from "img/building.svg";
import { ReactComponent as BuildingsIcon } from "img/buildings-multiple.svg";

import theme from "theme";

import { isPDX } from "static-config";
import ModalContent from "./ModalContent";
import S, { classNameOverrides } from "./styles";
import SummaryCard from "./SummaryCard";

const userAssessmentMapping = {
  confirmed: AssessmentOptions.RightPerson,
  discarded: AssessmentOptions.WrongPerson
};

const breakpointColumnsObj = {
  default: 2,
  1000: 1
};

const NO_RESULTS_CONTAINER_HEIGHT = 150;
const ANIMATION_DURATION = 0.4;

// Format json to include original assignments
const getAssignmentsWithOriginalConfidence = data => {
  // Deep copy original data and add new field, originalSetConfidence
  const dataCopy = {
    confirmed: [
      ...(data.confirmed?.map(hit => ({
        ...hit,
        originalSetConfidence: CONFIDENCE_CATEGORIES.confirmed,
        currentSetConfidence: CONFIDENCE_CATEGORIES.confirmed
      })) ?? [])
    ],
    unconfirmed: [
      ...(data.unconfirmed?.map(hit => ({
        ...hit,
        originalSetConfidence: CONFIDENCE_CATEGORIES.unconfirmed,
        currentSetConfidence: CONFIDENCE_CATEGORIES.unconfirmed
      })) ?? [])
    ],
    discarded: [
      ...(data.discarded?.map(hit => ({
        ...hit,
        originalSetConfidence: CONFIDENCE_CATEGORIES.discarded,
        currentSetConfidence: CONFIDENCE_CATEGORIES.discarded
      })) ?? [])
    ]
  };

  return dataCopy;
};

const RelatedOrganisations = ({ isReportRegenerationOpen, originalData }) => {
  // Note: this should be ok, as we don't have complex structures within the data, only nested objects with primitive data types
  const [data, setData] = useState(
    getAssignmentsWithOriginalConfidence(originalData)
  );
  const [userVerifications, setUserVerifications, , setUserVerificationsCount] =
    useContext(UserVerificationContext);
  const assessmentCount = Array.from(userVerifications?.values() ?? [])?.filter(
    value => value !== 0 && value !== AssessmentOptions.NoUserAssessment
  )?.length;
  const prevAssessmentCount = usePreviousValue(assessmentCount);

  const [isResultsExpanded, setIsResultsExpanded] = usePrintableReportState(
    "employment-overview-expanded-fully",
    false,
    PRINTABLE_STATE_TYPES.sectionExpand
  );

  const [selectedConfidenceFilter, setSelectedConfidenceFilter] = useState(
    CONFIDENCE_CATEGORIES.confirmed
  );
  const [isShowingExpandButton, setIsShowingExpandButton] = useState();

  const [selectedRelatedOrg, setSelectedRelatedOrg] = useState();

  const [isModalOpen, setIsModalOpen] = usePrintableReportState(
    "screening-modal-open",
    false
  );

  // Reset back to the original data (before any recent user changes)
  useEffect(() => {
    // If the previous count of assessments was non-zero and is _now_ zero, then we know the
    // assessments have been cleared.
    if (
      prevAssessmentCount !== 0 &&
      prevAssessmentCount !== undefined &&
      assessmentCount === 0
    ) {
      setData(getAssignmentsWithOriginalConfidence(originalData)); // Reset
    }
  }, [assessmentCount, originalData, prevAssessmentCount]);

  const onConfidenceFilterSelected = selectedFilter => {
    setSelectedConfidenceFilter(selectedFilter);
  };

  const relatedOrgsRef = useRef(null);

  const onToggleExpandResultsSection = () => {
    setIsResultsExpanded(prevState => !prevState);
    if (isResultsExpanded) {
      // Then we must be collapsing the results so ensure
      // the results section remains in view.

      const rect = relatedOrgsRef.current.getBoundingClientRect();

      // If results section's top is now hidden i.e. above the viewport then:
      if (rect.top <= 0) {
        // Bring the section into view
        relatedOrgsRef.current.scrollIntoView();
      }
    }
  };

  const selectedBucketCount = originalData[`${selectedConfidenceFilter}Count`];

  const itemString = selectedBucketCount === 1 ? "item" : "items";

  const selectedBucketData = data[selectedConfidenceFilter];
  const relatedLookupData = originalData.relatedLookup; // Contains orgs that are parents/children of those in any bucket

  // Mapping of entity ids to org data
  const dataMap = useMemo(() => {
    // Start with the dictionary for "related organisations" and add the "subject organisations" from the chosen bucket
    const map = relatedLookupData ?? {};
    selectedBucketData?.forEach(bucketData => {
      map[bucketData.id] = bucketData;
    });

    // BFS
    const getParentOrganisations = orgData => {
      const parents = []; // Stores flat list of transitive parents
      const queue = []; // Maintains list of orgs to store into the `parents` list
      const visited = []; // Maintains list of visited orgs to prevent cycle

      // Initialise
      orgData.parents?.forEach(parent => {
        queue.push(parent);
        visited.push(parent);
      });

      while (queue.length) {
        const org = map[queue.shift()];
        parents.push(org);
        org?.parents?.forEach(parent => {
          if (visited.findIndex(id => id === parent) === -1) {
            queue.push(parent);
            visited.push(parent);
          }
        });
      }

      return parents;
    };

    // Lets build up each orgs' list of parents while we're here
    Object.keys(map)?.forEach(id => {
      const orgData = map[id];
      map[id] = {
        ...orgData,
        orgParents: getParentOrganisations(orgData)
      };
    });

    return map;
  }, [selectedBucketData, relatedLookupData]);

  const onConfidenceChange = (newConfidence, originalConfidence, orgIds) => {
    const orgToMoveIndex = data[originalConfidence].findIndex(org => {
      return org.userAssessmentIds?.join() === orgIds?.join();
    });
    const orgToMove = data[originalConfidence][orgToMoveIndex];

    orgToMove.currentSetConfidence = newConfidence;

    setData({ ...data });

    // Insert regen ids into map and update user changes count
    const newUserVerifications = new Map(userVerifications);
    if (newConfidence !== orgToMove.originalSetConfidence) {
      // Update the user verification for the org (represented by multiple ids) to the new confidence
      orgIds?.forEach(orgId => {
        newUserVerifications.set(orgId, userAssessmentMapping[newConfidence]);
      });
      setUserVerificationsCount(prev => prev + 1);
    } else {
      orgIds?.forEach(orgId => {
        newUserVerifications.set(orgId, 0); // Clear
      });
      setUserVerificationsCount(prev => prev - 1);
    }
    setUserVerifications(newUserVerifications);
  };

  const renderSectionHeader = () => {
    return (
      <S.SectionBanner>
        <S.InnerSectionBannerContent>
          <S.SectionBannerInfo>
            Non-exhaustive list of directly-related organisations sourced from
            multiple providers.
            <br />
            Subsidiaries and parent organisations shown only when present in
            data received from our providers.
          </S.SectionBannerInfo>
          <S.CountAndMenu>
            {isPDX ? (
              <ConfidenceDropDownMenu
                isPdx
                inputtedHoveredState={selectedConfidenceFilter}
                dropDownButtonText={
                  DOWJONES_CONVERSION[selectedConfidenceFilter]
                }
                dropDownMenuItem1={{
                  label: originalData.confirmedCount,
                  onClickHandler: () =>
                    onConfidenceFilterSelected(CONFIDENCE_CATEGORIES.confirmed)
                }}
                dropDownMenuItem2={{
                  label: originalData.unconfirmedCount,
                  onClickHandler: () =>
                    onConfidenceFilterSelected(
                      CONFIDENCE_CATEGORIES.unconfirmed
                    )
                }}
                dropDownMenuItem3={{
                  label: originalData.discardedCount,
                  onClickHandler: () =>
                    onConfidenceFilterSelected(CONFIDENCE_CATEGORIES.discarded)
                }}
              />
            ) : (
              <>
                <DropdownButton
                  label={`${selectedConfidenceFilter} results`}
                  enabled
                >
                  <S.OptionsDropdownMenuItem
                    onClick={() => {
                      onConfidenceFilterSelected(
                        CONFIDENCE_CATEGORIES.confirmed
                      );
                    }}
                  >
                    Confirmed
                    <S.ConfidencebucketCount>
                      {originalData.confirmedCount}
                    </S.ConfidencebucketCount>
                  </S.OptionsDropdownMenuItem>
                  <S.OptionsDropdownMenuItem
                    onClick={() => {
                      onConfidenceFilterSelected(
                        CONFIDENCE_CATEGORIES.unconfirmed
                      );
                    }}
                  >
                    Unconfirmed
                    <S.ConfidencebucketCount>
                      {originalData.unconfirmedCount}
                    </S.ConfidencebucketCount>
                  </S.OptionsDropdownMenuItem>
                  <S.OptionsDropdownMenuItem
                    onClick={() => {
                      onConfidenceFilterSelected(
                        CONFIDENCE_CATEGORIES.discarded
                      );
                    }}
                  >
                    Discarded
                    <S.ConfidencebucketCount>
                      {originalData.discardedCount}
                    </S.ConfidencebucketCount>
                  </S.OptionsDropdownMenuItem>
                </DropdownButton>
                <S.SectionCount>{selectedBucketCount}</S.SectionCount>
              </>
            )}
          </S.CountAndMenu>
        </S.InnerSectionBannerContent>
      </S.SectionBanner>
    );
  };

  const renderOrganisations = () => {
    return (
      <motion.div
        animate={
          !selectedBucketData.length
            ? { height: NO_RESULTS_CONTAINER_HEIGHT }
            : { height: "auto" }
        }
        transition={{ duration: ANIMATION_DURATION }}
      >
        {!selectedBucketData.length && (
          <S.NoResults
            key="no-results"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: ANIMATION_DURATION }}
            height={NO_RESULTS_CONTAINER_HEIGHT}
          >
            No results
          </S.NoResults>
        )}
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className={classNameOverrides.masonryGrid}
          columnClassName={classNameOverrides.masonryColumn}
        >
          {selectedBucketData
            .filter(item => item.display)
            .map(item => {
              const fullTransitiveListOfParents = dataMap[item.id]?.orgParents;
              const totalNumParentsAndSubsidiaries =
                fullTransitiveListOfParents.length || item.subsidiaries.length;
              return (
                <SummaryCard
                  key={item.id}
                  orgId={item.id}
                  name={item.name}
                  industry={item.industry}
                  location={item.incorporatedCountry}
                  organisationType={item.organisationType}
                  parents={fullTransitiveListOfParents}
                  subsidiaryIds={item.subsidiaries}
                  dateOfIncorporation={item.startDate}
                  orgStatus={item.orgStatus}
                  riskList={item.risk}
                  image={
                    <S.OverviewImageContainer
                      backgroundColor={theme.primaryColor}
                    >
                      {totalNumParentsAndSubsidiaries > 0 ? (
                        <div>
                          <S.BuildingsIconContainer>
                            <BuildingsIcon />
                          </S.BuildingsIconContainer>
                          <S.WhiteTextCentered>
                            +{totalNumParentsAndSubsidiaries}
                          </S.WhiteTextCentered>
                        </div>
                      ) : (
                        <BuildingIcon />
                      )}
                    </S.OverviewImageContainer>
                  }
                  onCardClick={() => {
                    setSelectedRelatedOrg(item);
                    setIsModalOpen(prev => !prev);
                  }}
                  originalSetConfidence={
                    item.originalSetConfidence ?? selectedConfidenceFilter
                  }
                  currentSetConfidence={item.currentSetConfidence}
                  onConfidenceChange={onConfidenceChange}
                  userAssessmentIds={item.userAssessmentIds}
                  userAssessment={
                    item.userAssessment ?? AssessmentOptions.NoUserAssessment
                  }
                />
              );
            })}
        </Masonry>
      </motion.div>
    );
  };

  return (
    <div>
      <S.RelatedOrganisations
        ref={relatedOrgsRef}
        isResultsExpanded={isResultsExpanded}
      >
        {renderSectionHeader()}
        {renderOrganisations()}
      </S.RelatedOrganisations>
      {!isShowingExpandButton && <SectionFooter />}
      <S.CustomStickyExpandButton
        additionalText={`${selectedBucketCount} ${itemString}`}
        isReportRegenerationOpen={isReportRegenerationOpen}
        isResultsExpanded={isResultsExpanded}
        onToggleExpandResultsSection={onToggleExpandResultsSection}
        resultsSectionRef={relatedOrgsRef}
        shouldShowButtonCallback={setIsShowingExpandButton}
      />
      <ModalWithTabs
        dataMap={dataMap}
        data={selectedRelatedOrg}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        ModalContent={ModalContent}
      />
    </div>
  );
};

export default RelatedOrganisations;
