import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  Fragment
} from "react";

import Assertions from "api/assertions/Assertions";
import { AssertionModeContext } from "util/context/AssertionModeContext";

import { useEnquiryId } from "./useEnquiryId";

export const AssertionState = Object.freeze({
  BeingMade: 0,
  HoldsTrue: 1,
  Violated: 2,
  BeingDeleted: 3
  // could maybe add a "None" state, but for now going to use undefined for that
});

export class AssertionsStore {
  constructor(
    assertionsAPI,
    setAssertions,
    assertions,
    testCaseId,
    setTestCaseId,
    enquiryId
  ) {
    this.setAssertions = setAssertions;
    this.assertionsAPI = assertionsAPI;
    this.assertions = assertions;
    this.testCaseId = testCaseId;
    this.setTestCaseId = setTestCaseId;
    this.enquiryId = enquiryId;
  }

  async insertAssertion(assertion) {
    /**
     * @param   {Object} assertion
     */
    // setAssertions to be all the previous assertions, plus the new assertion with state "BeingMade"
    this.setAssertions(prev => [
      ...prev,
      { assertion: assertion, state: AssertionState.BeingMade }
    ]);
    try {
      // insert and validate the new assertion, saving its assertionId and state in "result"
      const result = await this.validateNewAssertion(assertion);
      // Consider - different error handling if insert succeeds but validation throws an error?
      // (seems unlikely, so we aren't bothering for now)
      // setAssertions to be all the previous assertions, updating the state and id for the new assertion
      this.setAssertions(prev =>
        prev.map(a =>
          a.assertion === assertion
            ? {
                assertion: assertion,
                state: result.state,
                assertionId: result.assertionId
              }
            : a
        )
      );
    } catch (e) {
      console.error("Assertion insert error", e);
      // remove the assertion if the insert failed
      this.setAssertions(prev => prev.filter(a => a.assertion !== assertion));
    }
  }

  async validateNewAssertion(assertion) {
    const res = JSON.parse(
      await this.assertionsAPI.insertAssertion(assertion, this.enquiryId)
    );
    if (res.AssertionResult.Passed) {
      return { state: AssertionState.HoldsTrue, assertionId: res.AssertionId };
    } else {
      return { state: AssertionState.Violated, assertionId: res.AssertionId };
    }
  }

  async deleteAssertion(assertionId) {
    /**
     * @param {String} assertionId
     */

    // Find assertion with specified assertionId and save its state (in-case the deletion fails)
    const state = this.assertions.find(
      a => a.assertionId === assertionId
    ).state;
    try {
      // update state of the assertion
      this.setAssertions(prev =>
        prev.map(a =>
          a.assertionId === assertionId
            ? {
                assertion: a.assertion,
                assertionId: a.assertionId,
                state: AssertionState.BeingDeleted
              }
            : a
        )
      );
      // send delete request to api
      await this.assertionsAPI.deleteAssertion(assertionId);
      // remove deleted assertion from list
      this.setAssertions(prev =>
        prev.filter(a => a.assertionId !== assertionId)
      );
    } catch (e) {
      console.error("Assertion deletion error", e);
      this.setAssertions(prev =>
        prev.map(a =>
          a.assertionId === assertionId
            ? { assertion: a.assertion, assertionId: a.assertionId, state }
            : a
        )
      );
    }
  }

  async loadAssertionsAndResults(enquiryId) {
    /**
     * @param {String} enquiryId
     */
    console.log("Loading assertions against this report", { enquiryId });
    this.setAssertions(
      await this.assertionsAPI.validateReport(enquiryId).then(res =>
        res.map(result => ({
          assertion: result.Assertion,
          assertionId: result.Assertion.AssertionId,
          state: result.AssertionResult.Passed
            ? AssertionState.HoldsTrue
            : AssertionState.Violated
        }))
      )
    );
  }

  // run when someone opens assertion mode
  // unbeknownst to the frontend, the backend will create the test case id if that's the first time it's seen this enquiry input
  // might mean there are test cases hanging around with no assertions
  // on them but big whop
  async loadTestCaseId(enquiryId) {
    // Need to use enquiryId rather than enquiryInput as the front-end does not know of context types
    this.setTestCaseId(
      await this.assertionsAPI.getTestCaseFromReport(enquiryId)
    );
  }
}

export const AssertionsContext = createContext({
  assertions: [],
  assertionsStore: new AssertionsStore(null, null, [], null, null)
});

export const GlobalAssertionsProvider = ({ children }) => {
  const assertionsAPI = new Assertions();
  const [assertions, setAssertions] = useState([]);
  const [testCaseId, setTestCaseId] = useState([]);
  const enquiryId = useEnquiryId();
  const assertionsStore = new AssertionsStore(
    assertionsAPI,
    setAssertions,
    assertions,
    testCaseId,
    setTestCaseId,
    enquiryId
  );

  useEffect(() => {
    assertionsStore.loadAssertionsAndResults(enquiryId);
  }, [enquiryId]);

  useEffect(() => {
    assertionsStore.loadTestCaseId(enquiryId);
  }, [enquiryId]);

  const assertionsContext = {
    assertions,
    assertionsStore,
    testCaseId
  };
  return (
    <AssertionsContext.Provider value={assertionsContext}>
      {children}
    </AssertionsContext.Provider>
  );
};

export const GlobalAssertionsProviderWrapper = ({ children }) => {
  const assertionModeEnabled = useContext(AssertionModeContext).enabled;
  return assertionModeEnabled ? (
    <GlobalAssertionsProvider>{children}</GlobalAssertionsProvider>
  ) : (
    <Fragment>{children}</Fragment>
  );
};
