import { styled } from "@linaria/react";

import { grey, blue } from "styles/colors";
import { fontSize, family } from "styles/fonts";
import { hexToRgb } from "styles/hexToRgb";

const SubHeading = styled.div`
  color: ${grey.mid};
  margin-left: 8px;
  white-space: nowrap;
  font-weight: normal;
  font-size: ${fontSize.sm};
`;

const InfoSummaryRow = styled.div`
  cursor: ${({ interactive }) => (interactive ? "pointer" : "default")};
  background-color: ${grey.panel};
  border-radius: 6px;
  padding: 16px;
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  &:hover {
    background-color: ${({ interactive }) => {
      if (interactive) {
        const { r, g, b } = hexToRgb(blue.icon);
        return `rgba(${r}, ${g}, ${b}, 0.15)`;
      }
      return grey.panel;
    }};
  }

  &:hover ${SubHeading} {
    color: ${({ interactive }) => (interactive ? blue.icon : grey.mid)};
  }

  transition: background-color 0.3s;
`;

const RightContent = styled.div`
  flex: 1;
  font-size: ${fontSize.sm};
`;

const Headings = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Heading = styled.h5`
  font-family: ${family.interDisplay};
  font-size: ${fontSize.sm};
  margin: 0;
  max-width: 300px;
`;

const S = {
  InfoSummaryRow,
  RightContent,
  Headings,
  Heading,
  SubHeading
};

export default S;
