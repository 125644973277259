/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Idam_Contracts_Users_GetPendingMigrationResponse } from "../models/Idam_Contracts_Users_GetPendingMigrationResponse";
import type { Idam_Contracts_Users_GettingStartedRequest } from "../models/Idam_Contracts_Users_GettingStartedRequest";
import type { Idam_Contracts_Users_GetUserAvatarResponse } from "../models/Idam_Contracts_Users_GetUserAvatarResponse";
import type { Idam_Contracts_Users_GetUserGroupsResponse } from "../models/Idam_Contracts_Users_GetUserGroupsResponse";
import type { Idam_Contracts_Users_GetUserPermissions } from "../models/Idam_Contracts_Users_GetUserPermissions";
import type { Idam_Contracts_Users_GetUserResponse } from "../models/Idam_Contracts_Users_GetUserResponse";
import type { Idam_Contracts_Users_SendMigrationEmailRequest } from "../models/Idam_Contracts_Users_SendMigrationEmailRequest";
import type { Idam_Contracts_Users_UpdateUserRequest } from "../models/Idam_Contracts_Users_UpdateUserRequest";
import type { Idam_Contracts_Users_UserPreferencesRequest } from "../models/Idam_Contracts_Users_UserPreferencesRequest";
import type { Idam_Contracts_Users_UserPreferencesResponse } from "../models/Idam_Contracts_Users_UserPreferencesResponse";
import type { Idam_Contracts_Users_ValidateInviteResponse } from "../models/Idam_Contracts_Users_ValidateInviteResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class IdentityUserApiService {
  /**
   * @returns Idam_Contracts_Users_GetUserResponse Success
   * @throws ApiError
   */
  public static getUsers(): CancelablePromise<Idam_Contracts_Users_GetUserResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/users"
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static putUsers({
    requestBody
  }: {
    requestBody?: Idam_Contracts_Users_UpdateUserRequest;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/users",
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static putUsersPreferences({
    requestBody
  }: {
    requestBody?: Idam_Contracts_Users_UserPreferencesRequest;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/users/preferences",
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns Idam_Contracts_Users_UserPreferencesResponse Success
   * @throws ApiError
   */
  public static getUsersPreferences(): CancelablePromise<Idam_Contracts_Users_UserPreferencesResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/users/preferences"
    });
  }

  /**
   * @returns Idam_Contracts_Users_GetUserGroupsResponse Success
   * @throws ApiError
   */
  public static getUsersGroups(): CancelablePromise<
    Array<Idam_Contracts_Users_GetUserGroupsResponse>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/users/groups"
    });
  }

  /**
   * @returns Idam_Contracts_Users_GetPendingMigrationResponse Success
   * @throws ApiError
   */
  public static getUsersMigrationPending({
    email,
    tenantId
  }: {
    email?: string;
    tenantId?: string;
  }): CancelablePromise<Idam_Contracts_Users_GetPendingMigrationResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/users/migration-pending",
      query: {
        email: email,
        tenantId: tenantId
      }
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static putUsersMigrationEmail({
    requestBody
  }: {
    requestBody?: Idam_Contracts_Users_SendMigrationEmailRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/users/migration-email",
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static putUsersAvatar({
    formData
  }: {
    formData?: {
      profileImage?: Blob;
    };
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/users/avatar",
      formData: formData,
      mediaType: "multipart/form-data"
    });
  }

  /**
   * @returns Idam_Contracts_Users_GetUserAvatarResponse Success
   * @throws ApiError
   */
  public static getUsersAvatar(): CancelablePromise<Idam_Contracts_Users_GetUserAvatarResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/users/avatar"
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static postUsersGettingStarted({
    base64Token,
    requestBody
  }: {
    base64Token: string;
    requestBody?: Idam_Contracts_Users_GettingStartedRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/users/getting-started/{base64token}",
      path: {
        base64token: base64Token
      },
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns Idam_Contracts_Users_ValidateInviteResponse Success
   * @throws ApiError
   */
  public static postUsersGettingStartedValidate({
    base64Token
  }: {
    base64Token: string;
  }): CancelablePromise<Idam_Contracts_Users_ValidateInviteResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/users/getting-started/{base64token}/validate",
      path: {
        base64token: base64Token
      }
    });
  }

  /**
   * @returns Idam_Contracts_Users_GetUserPermissions Success
   * @throws ApiError
   */
  public static getUsersPermissions(): CancelablePromise<Idam_Contracts_Users_GetUserPermissions> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/users/permissions"
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static postUsersGettingMigrated({
    base64Token,
    requestBody
  }: {
    base64Token: string;
    requestBody?: Idam_Contracts_Users_GettingStartedRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/users/getting-migrated/{base64token}",
      path: {
        base64token: base64Token
      },
      body: requestBody,
      mediaType: "application/json"
    });
  }
}
