import { styled } from "@linaria/react";

import { family, fontSize } from "styles/fonts";
import { grey } from "styles/colors";

const Container = styled.div`
  width: 34px;
  max-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${fontSize["2xs"]};

  img {
    margin-top: 6px;
    width: 12px;
    height: 12px;
    opacity: 0.6;
    transition: all 0.3s ease-in-out;
    margin-bottom: 6px;
    margin-top: 6px;

    &:hover {
      width: 24px;
      height: 24px;
      margin-top: 0;
    }
  }
`;

const CountryCode = styled.div`
  font-size: ${fontSize["2xs"]};
  color: ${grey.mid};
  font-family: ${family.interSemiBold};
`;

const S = {
  Container,
  CountryCode
};

export default S;
