import { tenant, Tenants } from "static-config";
import { stage, qa, diDemo, research } from "./services/stage";

export const devOptions = {
  newExpander: true,
  pdfExportOriginUrl: process.env.REACT_APP_local_pdf_export_origin_url
};

const getEnvironmentUrl = () => {
  const environmentUrl = "app.xapien.com";
  return `${stage}.${environmentUrl}`;
};

const getPortalCognitoUserPoolId = () => {
  switch (stage) {
    case diDemo:
      return "us-west-2_qLwzTveQN";
    case qa:
      return "us-west-2_vPaBAqzDJ";
    case research:
      return "us-west-2_J61FrGDyb";
    default:
      return "us-west-2_A29go6fav";
  }
};

const getPortalCognitoWebAppClientId = () => {
  switch (stage) {
    case diDemo:
      return "ge02tm49k4neb769djnf1epl";
    case qa:
      return "1oou8rift2o9uhrki1m2itafev";
    case research:
      return "619sr9r0i8mij3rf1011bdotn1";
    default:
      return "22d2chdq3m14jhu0o5pk8nklpb";
  }
};

const getPortalCognitoAuthUrl = () =>
  `https://auth.identity.${stage}.xapien.com`;

const getHubCognitoUserPoolId = () => {
  switch (stage) {
    case diDemo:
      return "us-west-2_YW5Phsc3i";
    case qa:
      return "us-west-2_jQYo2Fn3W";
    case research:
      return "us-west-2_7JN4yO2fv";
    default:
      return "us-west-2_YPIDG2MsT";
  }
};

const getHubCognitoWebAppClientId = () => {
  switch (stage) {
    case diDemo:
      return "3cbka941k4d2cojhd1od3ksqsv";
    case qa:
      return "1u12jb57h2kmun9ltj7cq57bd0";
    case research:
      return "4t28dr35sijajfl1rb3440qsfb";
    default:
      return "5lqcg0mcvqpjm4t393luo6q004";
  }
};

const getHubCognitoAuthUrl = () =>
  `https://tenant-auth.identity.${stage}.xapien.com`;

const getIdamApiUrl = () => `https://api.identity.${stage}.xapien.com`;

const getPortalApiUrl = () => `https://api.portal.${stage}.xapien.com`;

const getInsightsApiUrl = () => `https://api.insights.${stage}.xapien.com`;

const getTenantId = () => {
  const defaultDevTenantId = "ef14c0fe-1f85-4df2-8246-bbe709a15003";
  // You will need to append this to the email to get the username for cognito!
  const isProjectDx = tenant === Tenants.projectDX;
  switch (stage) {
    case "di-demo":
      return isProjectDx
        ? "8e507211-3fbe-488b-9f2d-20fd06a337e6"
        : "a852c7d6-9f2c-4705-ba96-3951412f72d0";
    case "qa":
      return isProjectDx
        ? "63ee2c1b-ff85-45d4-8038-399d260d420a"
        : "437019bf-5065-42ef-8e14-32bab77e9317";
    case "devel":
      return isProjectDx
        ? "3a914e61-eb77-4647-8b65-ace88f212602"
        : defaultDevTenantId;
    case "research":
      return isProjectDx
        ? "048d1b0b-ac78-4787-bfc6-406522e5bb22"
        : "62ea11c7-39c1-4e2f-aa97-a8540d212084";
    default:
      return defaultDevTenantId;
  }
};

const getApmServerUrl = () => {
  switch (stage) {
    case "di-demo":
      return "https://5c8c0ace641742958f1418befeca2440.apm.us-west-2.aws.cloud.es.io:443";
    default:
      return "https://a02c30a2f0ad468382c815f60a8c5757.apm.us-west-2.aws.cloud.es.io:443";
  }
};

const config = {
  stage,
  tenantId: getTenantId(),
  skipWaitingScreen: false,
  inDebugMode: false,
  preparingReportPollIntervalMs: 3000,
  diagnosticIntervalMs: 3000,
  diagnosticSlowerIntervalMs: 20000,
  environmentUrl: getEnvironmentUrl(),
  tenant: process.env.REACT_APP_TENANT ?? "default",
  useNewLogin: process.env.REACT_APP_USE_NEW_LOGIN,
  amplify: {
    portal: {
      Auth: {
        region: process.env.REACT_APP_AMPLIFY_REGION,
        userPoolId: getPortalCognitoUserPoolId(),
        userPoolWebClientId: getPortalCognitoWebAppClientId(),
        oauth: {
          domain: getPortalCognitoAuthUrl(),
          scope: ["email", "profile", "openid"],
          redirectSignIn: process.env.REACT_APP_AMPLIFY_REDIRECT_SIGN_IN,
          redirectSignOut: process.env.REACT_APP_AMPLIFY_REDIRECT_SIGN_OUT,
          responseType: "code"
        }
      }
    },
    hub: {
      Auth: {
        region: process.env.REACT_APP_AMPLIFY_REGION,
        userPoolId: getHubCognitoUserPoolId(),
        userPoolWebClientId: getHubCognitoWebAppClientId(),
        oauth: {
          domain: getHubCognitoAuthUrl(),
          scope: ["email", "profile", "openid"],
          redirectSignIn: process.env.REACT_APP_AMPLIFY_REDIRECT_SIGN_IN,
          redirectSignOut: process.env.REACT_APP_AMPLIFY_REDIRECT_SIGN_OUT,
          responseType: "code"
        }
      }
    }
  },
  apmServerUrl: getApmServerUrl(),
  idamApiUrl: getIdamApiUrl(),
  portalApiUrl: getPortalApiUrl(),
  insightsApiUrl: getInsightsApiUrl()
};

export default config;
