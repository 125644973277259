import { css } from "@linaria/core";
import { styled } from "@linaria/react";

import { breakpointsMin as bp } from "styles/breakpoints";
import {
  family,
  fontSize,
  lineHeight,
  headingLetterSpacing
} from "styles/fonts";

const commonStyles = {
  fontWeight: 700,
  fontFamily: family.interDisplay,
  margin: 0 // Leave margin up to consumer by default to override
};

const size0 = css`
  ${commonStyles};
  font-size: ${fontSize["4xl"]};
  line-height: ${lineHeight.xl};
  letter-spacing: ${headingLetterSpacing["4xl"]};

  ${bp.medium} {
    font-size: ${fontSize["5xl"]};
    line-height: ${lineHeight.sm};
    letter-spacing: ${headingLetterSpacing["5xl"]};
  }
`;

const size1 = css`
  ${commonStyles};
  font-size: ${fontSize["3xl"]};
  line-height: ${lineHeight.md};
  letter-spacing: ${headingLetterSpacing["3xl"]};

  ${bp.medium} {
    font-size: ${fontSize["4xl"]};
    line-height: ${lineHeight.xl};
    letter-spacing: ${headingLetterSpacing["4xl"]};
  }
`;

const size2 = css`
  ${commonStyles};
  font-size: ${fontSize["2xl"]};
  line-height: ${lineHeight.xl};
  letter-spacing: ${headingLetterSpacing["2xl"]};

  ${bp.medium} {
    font-size: ${fontSize["3xl"]};
    line-height: ${lineHeight.md};
    letter-spacing: ${headingLetterSpacing["3xl"]};
  }
`;

const size3 = css`
  ${commonStyles};
  font-size: ${fontSize.xl};
  line-height: ${lineHeight.md};
  letter-spacing: ${headingLetterSpacing.xl};

  ${bp.medium} {
    font-size: ${fontSize["2xl"]};
    line-height: ${lineHeight.xl};
    letter-spacing: ${headingLetterSpacing["2xl"]};
  }
`;

const size4 = css`
  ${commonStyles};
  font-size: ${fontSize.lg};
  line-height: ${lineHeight.lg};
  letter-spacing: ${headingLetterSpacing.lg};

  ${bp.medium} {
    font-size: ${fontSize.xl};
    line-height: ${lineHeight.md};
    letter-spacing: ${headingLetterSpacing.xl};
  }
`;

const size5 = css`
  ${commonStyles};
  font-size: ${fontSize.md};
  line-height: ${lineHeight.xl};
  letter-spacing: ${headingLetterSpacing.md};

  ${bp.medium} {
    font-size: ${fontSize.lg};
    line-height: ${lineHeight.lg};
    letter-spacing: ${headingLetterSpacing.lg};
  }
`;

const size6 = css`
  ${commonStyles};
  font-size: ${fontSize.sm};
  line-height: ${lineHeight.lg};
  letter-spacing: ${headingLetterSpacing.sm};

  ${bp.medium} {
    font-size: ${fontSize.md};
    line-height: ${lineHeight.xl};
    letter-spacing: ${headingLetterSpacing.md};
  }
`;

const H1 = styled.h1``;
const H2 = styled.h2``;
const H3 = styled.h3``;
const H4 = styled.h4``;
const H5 = styled.h5``;
const H6 = styled.h6``;

const S = { H1, H2, H3, H4, H5, H6 };
const classes = {
  size0,
  size1,
  size2,
  size3,
  size4,
  size5,
  size6
};

export { classes };

export default S;
