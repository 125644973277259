import React, { FC, useEffect, useState, MouseEventHandler } from "react";
import { QRCodeSVG } from "qrcode.react";
import { Auth } from "aws-amplify";

import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";

import { useAuthentication } from "util/hooks/useAuthentication";

import googleIcon from "img/mfa/google.png";
import lastPassIcon from "img/mfa/lastpass.png";
import microsoftIcon from "img/mfa/microsoft.png";

import S from "./styles";

interface Props {
  onComplete: MouseEventHandler<HTMLButtonElement>;
}

const AUTHENTICATOR_APPS = [
  {
    name: "Google",
    iconImageSrc: googleIcon,
    url: "https://apps.apple.com/app/google-authenticator/id388497605"
  },
  {
    name: "Lastpass",
    iconImageSrc: lastPassIcon,
    url: "https://apps.apple.com/ag/app/lastpass-authenticator/id1079110004"
  },
  {
    name: "Microsoft",
    iconImageSrc: microsoftIcon,
    url: "https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
  }
];

const MfaSetup: FC<Props> = ({ onComplete }) => {
  const [showQrCode, setShowQrCode] = useState(false);
  const [qrCode, setQrCode] = useState<undefined | string>();
  const { state } = useAuthentication();

  const onReady: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    setShowQrCode(true);
  };

  useEffect(() => {
    if (
      !state.session?.challengeName ||
      state.session?.challengeName === "MFA_SETUP"
    ) {
      Auth.setupTOTP(state.session).then(secret => {
        const username = state?.session?.getUsername();

        if (username) {
          setQrCode(`otpauth://totp/Xapien:${username}?secret=${secret}`);
        }
      });
    }
  }, [state.session]);

  return showQrCode ? (
    <>
      <S.Subtitle level={4}>Scan this code with your mobile device</S.Subtitle>

      {qrCode && (
        <S.QRCodeContainer>
          <QRCodeSVG value={qrCode} />
        </S.QRCodeContainer>
      )}

      <ButtonNew
        onClick={onComplete}
        type={ButtonType.Filled}
        size={ButtonSize.Medium}
        text="Next"
      />
    </>
  ) : (
    <>
      <S.Subtitle level={4}>
        You will need a mobile authentication app, such as
      </S.Subtitle>

      <S.AuthenticationApps>
        {AUTHENTICATOR_APPS.map(({ name, iconImageSrc, url }) => (
          <S.DownloadOptionContainer key={`DownloadOptionContainer-${name}`}>
            <S.DownloadOption href={url} target="_blank">
              <img src={iconImageSrc} alt={`${name} Authenticator`} /> {name}
            </S.DownloadOption>

            <S.DownloadLink href={url} target="_blank">
              Download
            </S.DownloadLink>
          </S.DownloadOptionContainer>
        ))}
      </S.AuthenticationApps>

      <ButtonNew
        onClick={onReady}
        type={ButtonType.Filled}
        size={ButtonSize.Medium}
        text="Ready"
      />
    </>
  );
};

export default MfaSetup;
