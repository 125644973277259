import React, { Fragment } from "react";

import ReactTooltip from "components/atoms/ReactTooltip";

import S from "./styles";

const BASE_PATH = "/images/icons/flags/";

const Flag = ({ code, name, showCodeString = true, className, style }) => {
  const image = (
    <img alt={name} src={BASE_PATH + code?.toUpperCase() + ".svg"} />
  );

  return (
    <S.Container style={style} className={className}>
      <div>
        {name ? (
          <ReactTooltip tooltip={name}>{image}</ReactTooltip>
        ) : (
          <Fragment>{image}</Fragment>
        )}
      </div>
      {showCodeString && <S.CountryCode>{code.toUpperCase()}</S.CountryCode>}
    </S.Container>
  );
};

export default Flag;
