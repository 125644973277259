import React from "react";

// eslint-disable-next-line import/no-extraneous-dependencies
import { CognitoUser } from "@aws-amplify/auth";

// eslint-disable-next-line import/no-extraneous-dependencies
import { ProductFruits } from "react-product-fruits";

import { useAuthentication } from "./hooks/useAuthentication";

const ProductFruitsTour = () => {
  const {
    state: { session }
  } = useAuthentication();

  if (!session) {
    return null;
  }

  return (
    <ProductFruits
      workspaceCode="eqYouRdKprdiZhIW"
      language="en"
      user={{
        username:
          session instanceof CognitoUser ? session.getUsername() : "unknown"
      }}
    />
  );
};

export default ProductFruitsTour;
