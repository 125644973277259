import React, {
  FC,
  useEffect,
  useState,
  SyntheticEvent,
  FocusEvent,
  SVGProps
} from "react";
import { Spinner } from "reactstrap";

import { Autocomplete, TextField } from "@mui/material";

import { ChevronDown, Send, Users } from "react-feather";

import { blue } from "styles/colors";

import Popover from "components/atoms/Popover";
import MenuItem from "components/molecules/Menu/MenuItem";
import Chip from "components/atoms/Chip";

import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import type { Group } from "api/user/types";
import { ChipVariant, ChipSize } from "components/atoms/Chip/types";
import { formatGroupName } from "api/reports/utils";
import UserApi from "api/user";

import InputTags from "./InputTags";

import S from "./styles";

interface Props {
  onDropdownOptionChange: (value: { id: string; label: string }) => void;
  onChange: (event: SyntheticEvent, value: string[]) => void;
  onInputChange: (event: SyntheticEvent, value: string) => void;
  onSubmit: () => void;
  onSetValue: (value: string[]) => void;
  dropdownOptions: { id: string; label: string }[];
  selectedDropdownOption: { id: string; label: string };
  onToggleOpen: () => void;
  inputValue: string;
  value: string[];
  selectedGroups: string[];
  onDeleteGroup: (id: string) => void;
  onGroupClick: (id: string) => void;
  isLoading: boolean;
}

const InviteForm: FC<Props> = ({
  onDropdownOptionChange,
  onChange,
  onInputChange,
  onSubmit,
  inputValue,
  dropdownOptions,
  selectedDropdownOption,
  value,
  onSetValue,
  onToggleOpen,
  selectedGroups,
  onDeleteGroup,
  onGroupClick,
  isLoading
}) => {
  const [groups, setGroups] = useState<Group[]>([]);
  const [showGroups, setShowGroups] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const getAndSetUserGroups = async () => {
      const api = new UserApi();

      const groupList = await api.getUserGroups();

      setGroups(groupList);
    };

    getAndSetUserGroups();
  }, []);

  const handleOnGroupClick = (id: string) => {
    setShowGroups(false);
    onGroupClick(id);
  };

  const onToggleShowGroups = () => {
    setShowGroups(!showGroups);
  };

  const addTagIfValid = (tag: string) => {
    if ((tag ?? "").trim().length) {
      const tags = tag.split(/,| /).filter(t => t.trim().length);
      onSetValue(value.concat(tags));
    }
  };

  const renderDropdown = () => {
    const dropdownMenu = (
      <S.Menu>
        {dropdownOptions.map(option => {
          return (
            <MenuItem
              key={option.id}
              onMenuItemClick={() => {
                onDropdownOptionChange(option);
                setIsDropdownOpen(false);
              }}
              text={option.label}
              disabled={option.id === selectedDropdownOption.id}
            />
          );
        })}
      </S.Menu>
    );

    return (
      <Popover
        // @ts-ignore
        isOpenOverride={isDropdownOpen}
        // @ts-ignore
        onRequestClose={() => setIsDropdownOpen(false)}
        alignment="bottom-end"
        hideArrow
        trigger="click"
        content={dropdownMenu}
        disableHideOnClip={undefined}
        className={undefined}
        style={undefined}
      >
        <S.OptionsButton
          type="button"
          onClick={() => setIsDropdownOpen(prev => !prev)}
        >
          {selectedDropdownOption.label}{" "}
          <ChevronDown
            size={16}
            color={blue.xapienDark}
            style={{ marginBottom: 4 }}
          />
        </S.OptionsButton>
      </Popover>
    );
  };

  return (
    <S.Container>
      <S.Title level={6}>New users will receive an email invitation</S.Title>

      <S.UserSearch>
        <S.Autocomplete>
          <Autocomplete
            options={[]}
            onChange={onChange}
            onInputChange={onInputChange}
            onBlur={(event: FocusEvent<HTMLElement>) => {
              addTagIfValid((event.target as HTMLInputElement).value);
            }}
            value={value}
            inputValue={inputValue}
            multiple
            filterOptions={x => x}
            PopperComponent={() => {
              return null;
            }}
            renderTags={InputTags}
            renderInput={params => (
              <TextField
                {...params}
                placeholder={value && value.length ? undefined : "Enter email"}
                onSubmit={onSubmit}
                onKeyDown={({ code, target }) => {
                  if (code === "Enter") {
                    addTagIfValid((target as HTMLInputElement).value);
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: null
                }}
              />
            )}
          />
          {renderDropdown()}
        </S.Autocomplete>
      </S.UserSearch>

      <S.Divider />

      <S.Title level={6}>
        Include group membership <S.Optional>(optional)</S.Optional>
      </S.Title>

      <S.GroupsPopover
        disableHideOnClip={false}
        // @ts-ignore
        isOpenOverride={showGroups}
        // @ts-ignore
        onRequestClose={() => setShowGroups(false)}
        interactive
        hideArrow
        alignment="bottom-start"
        content={
          <S.Menu>
            {groups.map(({ groupId, name }) => (
              <MenuItem
                onMenuItemClick={() => handleOnGroupClick(groupId)}
                IconLeading={Users}
                key={`GroupMenuItem-${groupId}`}
                text={
                  `${formatGroupName(name)} ${
                    selectedGroups.includes(groupId) ? "(selected)" : ""
                  }` ?? ""
                }
                disabled={selectedGroups.includes(groupId)}
              />
            ))}
          </S.Menu>
        }
      >
        <ButtonNew
          onClick={onToggleShowGroups}
          type={ButtonType.Outlined}
          size={ButtonSize.Medium}
          text="Select group"
          IconTrailing={ChevronDown}
          disabled={groups.length === 0}
        />
      </S.GroupsPopover>

      <S.Groups>
        {selectedGroups.map(id => (
          <Chip
            size={ChipSize.Medium}
            variant={ChipVariant.FilledDark}
            label={
              groups.find(group => group.groupId === id)?.name ?? "unknown"
            }
            onDelete={() => onDeleteGroup(id)}
          />
        ))}
      </S.Groups>

      <S.Subtext>
        You can only include groups of which you are a member
      </S.Subtext>

      <S.Buttons>
        <ButtonNew
          onClick={onToggleOpen}
          type={ButtonType.Outlined}
          size={ButtonSize.Medium}
          text="Cancel"
        />

        <ButtonNew
          onClick={onSubmit}
          type={ButtonType.Filled}
          size={ButtonSize.Medium}
          disabled={value.length === 0 || isLoading}
          text="Invite"
          IconTrailing={
            isLoading
              ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
              : Send
          }
        />
      </S.Buttons>
    </S.Container>
  );
};

export default InviteForm;
