import React from "react";
import { Collapse } from "reactstrap";

import TruncateLength from "util/TruncateLength";
import { generateUniqueId } from "util/common";
import { ReactComponent as DownloadIcon } from "img/icons/actions/icon_open_link.svg";
import ReactTooltip from "components/atoms/ReactTooltip";
import Flag from "pages/report/Flag";
import { getDurationStringFromDates } from "util/getDurationStringFromDates";
import { getDurationSinceStartDate } from "util/getDurationSinceStartDate";

import S from "./styles";

const ExtraInfoToggler = props => {
  const {
    primaryInformation,
    secondaryInformation,
    roleData = false,
    isExpanded,
    displayToggler = true,
    link = false,
    sourceLinks,
    orgDescription,
    numberOfRoles,
    country,
    previousOrgNames,
    uboRole,
    industries,
    matchedName
  } = props;
  const handleClick = () => {
    props.toggleExpanded();
  };

  const renderSourceLinks = sourceLinksData => {
    if (!sourceLinksData) return false;

    return sourceLinksData.map(item => {
      return (
        <S.SourceLink
          href={item.url}
          target="_blank"
          rel="noopener noreferrer"
          key={item.url}
        >
          <div className="d-flex align-items-center">
            <DownloadIcon className="link-icon" />
            <span style={{ width: "94%" }}>
              <TruncateLength>{item.label}</TruncateLength>
            </span>
          </div>
        </S.SourceLink>
      );
    });
  };

  const renderPreviousNames = () => {
    if (previousOrgNames && previousOrgNames.length > 0) {
      return (
        <div
          className="secondary-information pb-3 bottom-border"
          key={`secondardInformationHeading_${generateUniqueId()}`}
        >
          {previousOrgNames.map((org, i) => (
            <div key={i}>
              <div>
                <span className="secondary-information--information--org-name">
                  {org.name}
                </span>
              </div>
              <div>
                {(org.startDate || org.endDate) && (
                  <span>
                    {" "}
                    ({org.startDate && org.startDate.year}
                    {org.startDate && org.endDate && " - "}
                    {org.endDate && org.endDate.year})
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const renderIndustries = () => {
    if (industries && industries.length > 0) {
      return (
        <div
          className="secondary-information pb-3 bottom-border"
          key={`secondardInformationHeading_${generateUniqueId()}`}
        >
          <div className="mt-2">Industries</div>
          {industries.map((industry, i) => (
            <div key={i}>
              <span className="secondary-information--information--org-name">
                {industry}
              </span>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const renderCompanyDescription = orgDescription => {
    if (!orgDescription) return false;

    return (
      <p className="company-description bottom-border">
        <TruncateLength text={orgDescription.text} length={200} />
      </p>
    );
  };
  const processDates = data => {
    const dateString = getDurationStringFromDates({
      startDate: data.startDate,
      endDate: data.endDate,
      isKnownToBeOngoing: data.isOngoing
    });

    const durationString = getDurationSinceStartDate({
      startDate: data.startDate,
      endDate: data.endDate
    });

    return `${dateString ?? ""} ${durationString ?? ""}`;
  };

  const renderUboRole = () => {
    if (uboRole) {
      const uboRoleDateString = getDurationStringFromDates({
        startDate: uboRole?.startDate,
        endDate: uboRole?.endDate,
        isKnownToBeOngoing: uboRole?.isOngoing
      });

      const uboRoleDurationString = getDurationSinceStartDate({
        startDate: uboRole?.startDate,
        endDate: uboRole?.endDate
      });

      return (
        <div>
          <div className="secondary-information secondary-information--information--ubo">
            Beneficial owner
          </div>
          <div
            className={`secondary-information secondary-information--information ${
              uboRole.isOngoing
                ? "secondary-information--information--ongoing-role"
                : ""
            }`}
          >
            {" "}
            {uboRoleDateString ?? ""} {uboRoleDurationString ?? ""}
          </div>
        </div>
      );
    }

    return null;
  };

  const RenderPrimaryInformation = props => {
    const { primaryInformation, isExpanded } = props;
    const onGoingRole = primaryInformation.isOngoing ? true : false;

    const roleInformationClass = `secondary-information secondary-information--information ${
      onGoingRole ? "secondary-information--information--ongoing-role" : ""
    }`;
    const informationClass = `${
      roleData
        ? roleInformationClass
        : "primary-information primary-information__information"
    }`;
    let title = "";
    if (link) {
      title = (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <TruncateLength>{primaryInformation.title}</TruncateLength>
        </a>
      );
    } else if (numberOfRoles > 1) {
      if (!isExpanded) {
        title = `${numberOfRoles} ${
          uboRole !== undefined ? "other roles" : "Roles"
        }`;
      }
    } else {
      title = <TruncateLength>{primaryInformation.title}</TruncateLength>;
    }

    const uboDiv = renderUboRole();

    const titleDiv = (
      <div
        className={`${
          roleData
            ? `secondary-information secondary-information--heading`
            : "primary-information primary-information__heading"
        }`}
      >
        {title}
      </div>
    );
    const informationDiv = (
      <div className={informationClass}>{primaryInformation.value}</div>
    );
    const previousNamesDiv = previousNames => (
      <div
        className="secondary-information secondary-information--information"
        style={{
          display: `${roleData ? "none" : "block"}`
        }}
      >
        {previousNames.length > 1 ? (
          <div className="cursor-pointer" onClick={handleClick}>
            <ReactTooltip
              tooltip={
                isExpanded
                  ? "click to collapse"
                  : "click to expand for more info"
              }
            >
              <span className="secondary-information--information--name-change">
                {` ${previousNames.length} previous names`}
              </span>
            </ReactTooltip>
          </div>
        ) : (
          <div className=" cursor-pointer" onClick={handleClick}>
            <TruncateLength>
              <ReactTooltip
                tooltip={
                  isExpanded
                    ? "click to collapse"
                    : "click to expand for more info"
                }
              >
                <span className="secondary-information--information--name-change">
                  {"Previous name:"}
                </span>
                {!isExpanded && (
                  <span className="secondary-information--information--org-name">{` ${previousNames[0].name}`}</span>
                )}
              </ReactTooltip>
            </TruncateLength>
          </div>
        )}{" "}
      </div>
    );

    return (
      <>
        {uboRole !== undefined ? uboDiv : false}
        {title ? titleDiv : false}
        {matchedName}
        {previousOrgNames && previousOrgNames.length > 0
          ? previousNamesDiv(previousOrgNames)
          : false}
        {primaryInformation.value ? informationDiv : false}
      </>
    );
  };
  const renderSecondaryInformation = secondaryInformation => {
    const addNewLines = items => {
      if (items.length < 2) return items;

      return items.map((item, i) => {
        return i < item.length - 1 ? `${item}, ` : item;
      });
    };

    if (roleData) {
      return secondaryInformation.map((data, index) => {
        let roleDates = processDates(data);
        return (
          <div key={`professionalInformation_${index}`} className="info-block">
            <div className="secondary-information secondary-information--heading">
              {data.roleTitle}
            </div>
            <div
              className={`secondary-information secondary-information--information ${
                data.isOngoing
                  ? "secondary-information--information--ongoing-role"
                  : ""
              }`}
            >
              {roleDates}
            </div>
          </div>
        );
      });
    }
    return (
      <div
        className="secondary-information bottom-border pt-3 pb-3"
        key={`secondardInformationHeading_${generateUniqueId()}`}
      >
        {addNewLines(secondaryInformation)}
      </div>
    );
  };

  return (
    <div className="extra-info-toggler">
      <div
        className={`report-section-content-inner row pr-0 ${
          country && country.countryCode ? "pl-0" : ""
        }`}
      >
        <div className={displayToggler ? `col-11` : "col-12"}>
          <div className="d-flex">
            {country && country.countryCode && (
              <Flag code={country.countryCode} name={country.countryName} />
            )}
            <div
              className={
                country && country.countryCode
                  ? "title-with-flag"
                  : roleData
                  ? "mw-100"
                  : "title-without-flag"
              }
            >
              <RenderPrimaryInformation
                primaryInformation={primaryInformation}
                isExpanded={isExpanded}
              />
              <Collapse isOpen={isExpanded}>
                {renderPreviousNames()}
                {secondaryInformation && secondaryInformation.length > 0 && (
                  <div className="role-information">
                    {renderSecondaryInformation(secondaryInformation)}
                  </div>
                )}
                {sourceLinks && sourceLinks.length > 0 && (
                  <div className="bottom-border pb-3">
                    {renderSourceLinks(sourceLinks)}
                  </div>
                )}
                {renderIndustries()}
                {renderCompanyDescription(orgDescription)}
              </Collapse>
            </div>
          </div>
        </div>
        {displayToggler ? (
          <div className="toggle-extra-information col-1 flex-row">
            <div
              onClick={handleClick}
              className=""
              style={{
                display: !roleData && displayToggler ? "block" : "none"
              }}
            >
              <S.Arrow alt="Collapse" isExpanded={isExpanded} />
            </div>
          </div>
        ) : (
          false
        )}
      </div>
    </div>
  );
};

export default ExtraInfoToggler;
