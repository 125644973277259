import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Report from "pages/report/Report";
import { ReportContextProvider } from "pages/report/ReportContextProvider";
import { ReportLoadingStatus } from "state/ReportStore";
import RequestAccessModal from "components/organisms/RequestAccessModal";
import GrantAccessModal from "components/organisms/GrantAccessModal";
import useViewerMode from "util/hooks/useViewerMode";

import type EnquiryStore from "state/EnquiryStore";
import type ReportStore from "state/ReportStore";
import type DefineStore from "state/DefineStore";
import type { UserAssessmentStore } from "state/UserAssessmentStore";

interface Props {
  enquiryStore?: EnquiryStore;
  reportStore?: ReportStore;
  defineStore?: DefineStore;
  userAssessmentStore?: UserAssessmentStore;
}

const ReportContainer = ({
  enquiryStore,
  reportStore,
  defineStore,
  userAssessmentStore
}: Props) => {
  const navigate = useNavigate();
  const params = useParams();
  const { enquiryId, personaId } = params;
  console.log("PARAMS",params,enquiryId,personaId);
  const [searchParams] = useSearchParams();
  const {
    setIsViewerModeEnabled,
    setIsSharedReportLink,
    isViewerModeEnabled,
    isSharedReportLink
  } = useViewerMode();

  const runId = searchParams.get("runId");

  const {
    // @ts-ignore - TODO: remove once reportStore is typed
    reportMeta: { permissions }
  } = reportStore;

  useEffect(() => {
    if (!isViewerModeEnabled && !permissions?.canEdit) {
      setIsViewerModeEnabled(true);
      setIsSharedReportLink(false);
    } else if (isViewerModeEnabled && permissions?.canEdit) {
      setIsViewerModeEnabled(false);
    }
  }, [
    isViewerModeEnabled,
    permissions?.canEdit,
    setIsViewerModeEnabled,
    setIsSharedReportLink
  ]);

  useEffect(() => {
    if (!enquiryStore || !reportStore) return;
    enquiryStore.setCurrentEnquiryId(enquiryId);

    // @ts-ignore - TODO: remove once reportStore is typed
    if (reportStore.loadingStatus !== ReportLoadingStatus.loading) {
      reportStore.fetch(enquiryId, personaId, undefined, undefined, runId);
    }

    const getReportStatus = async () => {
      try {
        if (isSharedReportLink) return;
        const status = await enquiryStore.status(enquiryId);
        // Check whether the report is in the process of being generated
        if (!status.reportReady) {
          navigate(`/report/preparing/${enquiryId}`);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (!runId) {
      getReportStatus();
    }
  }, [
    enquiryId,
    enquiryStore,
    navigate,
    runId,
    reportStore,
    isSharedReportLink
  ]);

  if (!reportStore || !enquiryStore || !reportStore || !defineStore) {
    return null;
  }

  const { imageMap, loadingStatus, isReportRegenerationOpen } = reportStore;
  const report = { ...reportStore.report };
  const { nameOnlySearch } = defineStore;

  const reportProps = {
    report,
    loadingStatus,
    enquiryId,
    isReportRegenerationOpen,
    nameOnlySearch,
    enquiryStore,
    reportStore,
    userAssessmentStore
  };

  if (searchParams.get("mode") === "accessRequest") {
    return <GrantAccessModal />;
  }

  // TODO: Remove this casting once reportStore is typed
  if (
    (reportStore.loadingStatus as unknown as number) ===
    ReportLoadingStatus.unauthorised
  ) {
    return <RequestAccessModal />;
  }
  console.log("LOADING REPORT",enquiryId,personaId);
  return (
    <ReportContextProvider {...{ report, enquiryId, personaId, imageStore: imageMap }}>
      <Report {...reportProps} />
    </ReportContextProvider>
  );
};

export default inject(
  "reportStore",
  "userAssessmentStore",
  "enquiryStore",
  "defineStore"
)(observer(ReportContainer));
