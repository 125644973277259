import { styled } from "@linaria/react";

import Heading from "components/atoms/Heading";
import BaseErrorBanner from "components/atoms/ErrorBanner";

import { ReactComponent as Logo } from "img/icons/xapien-logo-icon.svg";

// @ts-ignore
import theme from "theme";

import { blue, grey, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const XapienLogo = styled(Logo)`
  width: 48px;
  height: 48px;
  path: {
    fill: ${blue.xapienLight};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  input {
    width: 100%;
  }
`;

const Title = styled(Heading)`
  color: ${theme.alternativePrimaryColor ?? blue.blueDiamond};
`;

const Subtitle = styled(Heading)`
  color: ${standardColors.white};
  font-size: ${fontSize.xl};
  text-align: center;
`;

const DownloadOptionContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
`;

const DownloadOption = styled.a`
  width: 100%;
  background: ${blue.portalPanel};
  border-radius: 9px;
  padding: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  color: ${standardColors.white};
  text-decoration: none;

  img {
    width: 32px;
    height: 32px;
  }
`;

const DownloadLink = styled.a`
  color: ${blue.xapienLight};
  text-decoration: none;
`;

const AuthenticationApps = styled.div`
  padding: 1rem 0;
  display: flex;
  gap: 1rem;
  width: 100%;
`;

const Description = styled.p`
  margin: 0;
  text-align: center;
  color: ${grey.ghost};
  font-size: ${fontSize.sm};
`;

const QRCodeContainer = styled.div`
  background: white;
  padding: 1rem;
  border-radius: 1rem;
`;

const ErrorBanner = styled(BaseErrorBanner)`
  justify-content: center;
`;

const S = {
  ErrorBanner,
  QRCodeContainer,
  Container,
  Title,
  Subtitle,
  Inputs,
  XapienLogo,
  Description,
  AuthenticationApps,
  DownloadOption,
  DownloadLink,
  DownloadOptionContainer
};

export default S;
