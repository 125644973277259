import React, { useState } from "react";

import { ChevronRight, ChevronLeft } from "react-feather";

import { Resizable } from "re-resizable";

import { deduplicateSources } from "components/organisms/WithInspector/util";

import { InformationSource } from "api/report/report-types";
import SourceItem from "./SourceItem";

import S from "./styles";

interface SourceListInnerProps {
  sources?: InformationSource[];
  selectedFilter?: any;
  renderSourceItems?: (
    sources: InformationSource[],
    filter: any,
    ref: any,
    page: number,
    paginationComponent: JSX.Element
  ) => any;
  hideSourceCount?: boolean;
  style?: any;
  className?: string;
  sourcing?: InformationSource[];
  withPagination?: boolean;
}

const SourceListPagination = ({
  setSourcePage,
  sourcePage,
  allSourcesLength
}: {
  setSourcePage: (page: number) => void;
  sourcePage: number;
  allSourcesLength: number;
}) => {
  return (
    <S.Pagination>
      <S.PaginationButton
        disabled={sourcePage === 0}
        onClick={() => setSourcePage(sourcePage - 1)}
      >
        <ChevronLeft />
      </S.PaginationButton>
      <S.CurrentPage>{sourcePage + 1}</S.CurrentPage>
      <S.PaginationButton
        disabled={sourcePage === allSourcesLength - 1}
        onClick={() => setSourcePage(sourcePage + 1)}
      >
        <ChevronRight />
      </S.PaginationButton>
    </S.Pagination>
  );
};

const SourceList = React.forwardRef<HTMLDivElement, SourceListInnerProps>(
  (
    {
      sources,
      selectedFilter,
      renderSourceItems,
      hideSourceCount,
      style,
      className,
      sourcing,
      withPagination
    },
    ref
  ) => {
    const [sourcePage, setSourcePage] = useState(0);
    const dedupedSources: InformationSource[] = deduplicateSources(
      sources || []
    );

    const getSourcesLabel = () => {
      const len = dedupedSources?.length ?? 0;
      if (len > 1 || len === 0) {
        return "sources";
      }
      return "source";
    };

    const allSources = [...(sourcing || []), ...(dedupedSources || [])];

    const paginationComponent = (
      <SourceListPagination
        setSourcePage={setSourcePage}
        sourcePage={sourcePage}
        allSourcesLength={allSources.length}
      />
    );

    return (
      <S.SourceListContainer paddingTop={hideSourceCount ? "8px" : "0"}>
        {!hideSourceCount ? (
          <S.Options>
            <div>
              {dedupedSources?.length ?? 0} {getSourcesLabel()}
            </div>
          </S.Options>
        ) : null}
        <Resizable
          handleStyles={{ bottom: { bottom: 0 } }}
          bounds="window"
          minHeight={withPagination ? undefined : 200}
          defaultSize={
            withPagination
              ? undefined
              : { height: 262, width: undefined as any }
          }
          style={withPagination ? undefined : { padding: "0 8px" }}
          enable={{
            top: false,
            right: false,
            bottom: true,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
          }}
        >
          {renderSourceItems ? (
            renderSourceItems(
              dedupedSources,
              selectedFilter,
              ref,
              sourcePage,
              paginationComponent
            )
          ) : (
            <S.SourceItemsContainer
              ref={ref}
              role="list"
              className={className}
              style={style}
            >
              {sourcing?.map(source => (
                <SourceItem key={source.url} source={source} />
              ))}
              {dedupedSources?.map(source => (
                <SourceItem key={source.url} source={source} />
              ))}
            </S.SourceItemsContainer>
          )}
        </Resizable>
      </S.SourceListContainer>
    );
  }
);

export default SourceList;
