import { styled } from "@linaria/react";

import { ReactComponent as RightChevronSvg } from "img/right-chevron.svg";

import { fontSize, family, headingLetterSpacing } from "styles/fonts";
import { grey, standardColors } from "styles/colors";

const SectionHeaderOuterContainer = styled.div`
  background-color: ${standardColors.white};
  position: sticky;
  top: 54px;
  z-index: 7;
`;

const SectionHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${fontSize.lg};
  letter-spacing: ${headingLetterSpacing.lg};
  font-family: ${family.interDisplay};
  border-radius: ${({ className }) =>
    (className && className.match("isExpanded")) ||
    (className && !className.match("isContractable"))
      ? "12px 12px 0px 0px"
      : "12px"};
  background-color: ${grey.light};
  color: ${grey.dark};
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 2px;
  margin-top: 0px;
  height: 32px !important;
  cursor: ${({ className }) =>
    className && className.match("isContractable") ? "pointer" : "default"};
  border-bottom: ${({ className }) =>
    (className && className.match("isExpanded")) ||
    (className && !className.match("isContractable"))
      ? `1px solid ${grey.ghost}`
      : "none"};
  transition: 0.2s border-radius;
`;

const SectionHeaderRightSideContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SectionCount = styled.div`
  font-size: ${fontSize.xl};
  color: ${grey.dark};
  display: inline-block;
  margin-top: 2px;
`;

const SectionCollapseIcon = styled(RightChevronSvg)`
  height: 15px;
  position: relative;
  top: 2px;
  margin-left: 20px;
  vertical-align: text-bottom;
  width: 1rem;
  max-height: 38px;
  transform: ${({ className }) =>
    className === "isExpanded" ? "rotate(0deg)" : "rotate(90deg)"};
  margin-top: ${({ className }) =>
    className === "isExpanded" ? "-4px" : "-1px"};

  path {
    fill: ${grey.dark};
  }
`;

const S = {
  SectionHeaderContainer,
  SectionHeaderRightSideContainer,
  SectionCount,
  SectionCollapseIcon,
  SectionHeaderOuterContainer
};

export default S;
