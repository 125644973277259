import { ReportService } from "api/portal/services/ReportService";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import ModalContainer from "components/molecules/ModalContainer";
import React, { Reducer, useEffect, useReducer, useState } from "react";
import { S } from "./styles";

const FDocComponent = ({ fDoc }: { fDoc: any }) => {
  const fDocPrettyJSON = JSON.stringify(fDoc, undefined, 4);
  return (
    <>
      <S.FDocCodeBlock>{fDocPrettyJSON}</S.FDocCodeBlock>
      <S.FDocJSONLink
        href={`data:text/json;charset=utf-8,${encodeURIComponent(
          fDocPrettyJSON
        )}`}
        download="FDoc.json" // TODO - differentiate file names somehow
        // download="fdoc.json"
        // target="_blank"
        // rel="noreferrer"
      >
        FDoc.json
      </S.FDocJSONLink>
    </>
  );
};

enum FDocLoadingStatus {
  NotLoading,
  Loading,
  Loaded,
  Failed
}
type FDocNotLoadingState = { status: FDocLoadingStatus.NotLoading };
type FDocLoadingState = { status: FDocLoadingStatus.Loading };
type FDocLoadedState = { status: FDocLoadingStatus.Loaded; fDocs: any[] };
type FDocLoadingFailedState = { status: FDocLoadingStatus.Failed };
type FDocState =
  | FDocNotLoadingState
  | FDocLoadingState
  | FDocLoadedState
  | FDocLoadingFailedState;

enum FDocActionTypes {
  Load,
  Loaded,
  Failed
}
type FDocLoadAction = { type: FDocActionTypes.Load };
type FDocLoadedAction = { type: FDocActionTypes.Loaded; fDocs: any[] };
type FDocLoadingAction = { type: FDocActionTypes.Failed };
type FDocAction = FDocLoadAction | FDocLoadedAction | FDocLoadingAction;

function assertNever(x: never): never {
  throw new Error(`Unexpected object: ${x}`);
}
const fDocStateReducer: Reducer<FDocState, FDocAction> = (state, action) => {
  switch (action.type) {
    case FDocActionTypes.Load:
      return {
        status: FDocLoadingStatus.Loading
      };
    case FDocActionTypes.Loaded:
      return { status: FDocLoadingStatus.Loaded, fDocs: action.fDocs };
    case FDocActionTypes.Failed:
      return { status: FDocLoadingStatus.Failed };
    default:
      return assertNever(action); // ensures exhaustive switch
  }
};

type ViewFDocButtonProps = { sourceUrl: string; enquiryId: string };

export const ViewFDocButton = ({
  sourceUrl,
  enquiryId
}: ViewFDocButtonProps) => {
  const [isFDocsDiaglogueOpen, setIsFDocsDialogueOpen] = useState(false);
  const toggleIsFDocsDialogueOpen = () => setIsFDocsDialogueOpen(v => !v);
  const [fDocState, dispatchFDocAction] = useReducer(fDocStateReducer, {
    status: FDocLoadingStatus.NotLoading
  });
  useEffect(() => {
    if (
      isFDocsDiaglogueOpen &&
      fDocState.status === FDocLoadingStatus.NotLoading
    ) {
      const loadFDoc = async () => {
        const sourceUrlEncoded = encodeURIComponent(sourceUrl);
        const fDocRequestParameters = {
          enquiryId,
          sourceUrl: sourceUrlEncoded
        };
        // console.log("FDoc Request", fDocRequestParameters);
        const fDocResponse = await ReportService.getReportsFdoc(
          fDocRequestParameters
        );
        if (fDocResponse.rawFDocs) {
          dispatchFDocAction({
            type: FDocActionTypes.Loaded,
            fDocs: fDocResponse.rawFDocs
          });
        } else {
          dispatchFDocAction({
            type: FDocActionTypes.Failed
          });
        }
      };
      loadFDoc();
      dispatchFDocAction({ type: FDocActionTypes.Load });
    }
  }, [isFDocsDiaglogueOpen, fDocState.status, enquiryId, sourceUrl]);
  const fDocContent = fDocState.status === FDocLoadingStatus.Loaded && (
    <>
      {fDocState.fDocs.map(fDoc => (
        <FDocComponent fDoc={fDoc} />
      ))}
    </>
  );
  if (typeof sourceUrl !== "string") {
    const errorMessage = "Source URL must be string";
    console.error(errorMessage, { sourceUrl });
    return undefined;
  }
  return (
    <>
      <ButtonNew
        text="View FDocs"
        size={ButtonSize.Small}
        type={ButtonType.Filled}
        onClick={toggleIsFDocsDialogueOpen}
      />
      <ModalContainer
        isOpen={isFDocsDiaglogueOpen}
        toggleOpen={toggleIsFDocsDialogueOpen}
        title="FDocs"
        width={1200} // cheap and nasty hack
      >
        {fDocState.status === FDocLoadingStatus.Loading
          ? "Loading FDoc..."
          : fDocState.status === FDocLoadingStatus.Loaded
          ? fDocContent
          : "FDoc loading failed"}
      </ModalContainer>
    </>
  );
};
