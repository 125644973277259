import React from "react";

import S from "./styles";

const ExpandButton = ({
  data = [],
  isSectionExpandable,
  isSectionExpanded,
  onClick = () => {}
}) => {
  if (!isSectionExpandable) return false;
  return (
    <S.ButtonDiv onClick={onClick}>
      {isSectionExpanded ? "Show fewer" : `Show all (${data.length})`}
    </S.ButtonDiv>
  );
};

export default ExpandButton;
