import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";

import Api from "api/tenant";

const useCreateOrg = () => {
  const [{ fetching, error, errorMessage }, dispatch] = useFetchReducer();

  const TenantApi = new Api();

  const createOrganisation = async (
    orgName: string,
    admins: string[],
    users: string[],
    orgDescription?: string
  ) => {
    dispatch({ type: RequestActions.SendRequest });

    const { status, message } = await TenantApi.createOrganisation(
      orgName,
      orgDescription,
      users,
      admins
    );

    if (!status) {
      dispatch({
        type: RequestActions.SetError,
        errorMessage: message
      });
      return false;
    }

    dispatch({ type: RequestActions.SetSuccess });
    return true;
  };

  const reset = () => {
    dispatch({ type: RequestActions.Reset });
  };

  return { fetching, error, errorMessage, createOrganisation, reset };
};

export default useCreateOrg;
