import { styled } from "@linaria/react";

import { Spinner as ReactSpinner } from "reactstrap";
import { grey } from "styles/colors";

const SpinnerContainer = styled.div`
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
`;

const ImageContainer = styled.div`
  position: relative;
  min-width: ${props => props.width};
  min-height: ${props => props.height};
`;

const ImageInnerContainer = styled.div`
  width: inherit;
  height: inherit;
  opacity: ${props => (props.hasImageLoaded ? 1 : 0)};
  transition: opacity 0.2s;
  object-fit: contain;
`;

const Spinner = styled(ReactSpinner)`
  color: ${grey.ghost};
  opacity: 0.5;

  & > span {
    display: none;
  }
`;

const S = {
  SpinnerContainer,
  ImageContainer,
  ImageInnerContainer,
  Spinner
};

export default S;
