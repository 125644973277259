import http from "../../services/http";
import endpoints from "../../services/endpoints";
import {ReportCollectionId} from "../reports/types";
import {
  COLLECTION_ITEMS_PER_PAGE,
  CollectionInputType,
  CollectionStatus
} from "../../util/hooks/useCollectionList/types";
import {CollectionListView} from "../../components/molecules/CollectionListControls";
import {OpenAPI, ReportService} from "../portal";
import {buildReportCardTags, buildReportScreeningFlags, mapReportStateToStatus, mapReportType} from "../reports/utils";
import {IListableApi} from "../../util/hooks/useCollectionList/provider";
import {CollectionItem} from "../../util/hooks/useCollectionList/types";
import {request as __request} from "../portal/core/request";

export interface EnquirySummary {
  enquiry_id: string;
  persona_summaries: PersonaSummary[];
}

export interface ResolutionAccuracyWarning {
  category: string;
  certainty: string;
}

export interface PersonaSummary {
  persona_id: string;
  name: string;
  summary: string;
  url: string;
  subject_score: number; // Assuming double is represented as number in TypeScript
  subject_score_percentage: number; // Assuming double is represented as number in TypeScript
  debug_score_string: string;
  resolution_accuracy_warnings: ResolutionAccuracyWarning[];
  number_of_dres: number;
}

export interface PersonaSummaryWithId extends PersonaSummary {
  id: string;
}


export default class EnquirySummaryAPI {
  inProgress: (arg0: boolean) => boolean
  constructor(inProgress: (arg0: boolean) => boolean) {
    this.inProgress = inProgress;
  }

  async enquirySummary(enquiryId: string, runId: string|null): Promise<any> {
    this.inProgress(true);
    try {
      let url;
      let path;
      if (runId == null) {
        url = "/enquirySummary/{enquiryId}";
        path = {
          enquiryId: enquiryId
        };
      } else {
        url = "/enquirySummary/{enquiryId}?runId={runId}";
        path = {
          enquiryId: enquiryId,
          runId: runId
        };
      }
      
      return __request(OpenAPI, {
        method: "GET",
        url: url,
        path: path,
        errors: {
          400: `Bad Request`,
          404: `Not Found`
        }
      });
    } finally {
      this.inProgress(false);
    }
  }
}

export function MakeApi(items: PersonaSummary[]): new () => IListableApi<PersonaSummaryWithId> {
  class Api implements IListableApi<PersonaSummaryWithId> {
    getEmptyCollections() {
      const x: CollectionItem<PersonaSummaryWithId> =  {
        id: "personas",
        title: "All Personas",
        limit: COLLECTION_ITEMS_PER_PAGE,
        offset: 0,
        order: "desc",
        view: CollectionListView.grid,
        pollingEnabled: true,
        items: [],
        totalItemCount: 0,
        status: CollectionStatus.stale,
        hidden: false,
        hiddenIfEmpty: false,
        input: {
          type: CollectionInputType.list,
        }
      };
      
      return {
        collections: [
          x
        ]
      };
    }

    async list() {
      try {
        const mappedItems = items.map((item, index) => {
          var x: PersonaSummaryWithId =  {
            ...item,
            id: String(index)
          };
          return x;
        });
        await new Promise((resolve) => setTimeout(resolve, 3000));  
        return { items: mappedItems, totalItemCount: items.length };
      } catch (e) {
        console.error(e);
        return { items: [], totalItemCount: 0 };
      }
    }
  }
  return Api;
}