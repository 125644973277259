import { styled } from "@linaria/react";

import ModalContainerComponent from "components/molecules/ModalContainer";

import { blue } from "styles/colors";
import { hexToRgb } from "styles/hexToRgb";
import { fontSize } from "styles/fonts";
import { motion } from "framer-motion/dist/framer-motion";

const ModalTitle = styled.div`
  color: ${blue.xapienLight};
  font-size: ${fontSize.sm};
`;

const ModalContainer = styled(ModalContainerComponent)`
  & > .modal-content {
    align-items: center;
    position: relative;
  }
`;

const ModalText = styled.p`
  margin: 0;
  text-align: center;
`;

const ExistingAccountButton = styled.button`
  all: unset;
  cursor: pointer;
  color: ${blue.xapienLight};

  &:hover {
    color: ${() => {
      const { r, g, b } = hexToRgb(blue.xapienLight);
      return `rgba(${r}, ${g}, ${b}, 0.80)`;
    }};
  }

  &:not(:focus-visible) {
    outline: none;
  }
`;

const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 26.5rem;
`;

const ConfirmationText = styled.p`
  margin: 0;
`;

const BackButton = styled.button`
  all: unset;
  border-radius: 100px;
  background-color: ${blue.panel};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 1rem;
  left: 1rem;
  cursor: pointer;

  & > svg {
    padding-right: 2px;
  }

  &:hover {
    background-color: ${() => {
      const { r, g, b } = hexToRgb(blue.panel);
      return `rgba(${r}, ${g}, ${b}, 0.75)`;
    }};
  }

  &:not(:focus-visible) {
    outline: none;
  }
`;

const Confirmation = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
`;

const S = {
  ModalTitle,
  ModalContainer,
  ModalText,
  ExistingAccountButton,
  FormFields,
  ConfirmationText,
  BackButton,
  Confirmation
};

export default S;
