import { createContext } from "react";

import type { InsightReportSection } from "api/insight-reports/types";

import type {
  Report as RawReport,
  Section as RawSection,
  Subsection as RawSubsection,
  Element as RawElement
} from "api/insights";

import type { InsightReportState, InsightReportAction } from "./types";
import { InsightReportActions, InsightReportStatus } from "./types";

import { convertRawReport } from "./utils";

const HIDE_WELCOME_DISCLAIMER_LOCAL_STORAGE_KEY = "hide_xi_welcome_disclaimer";

export const initialState: InsightReportState = {
  activeSectionSlug: "overview",
  status: InsightReportStatus.idle,
  report: undefined,
  rawReport: undefined,
  errorMessage: undefined,
  fetchedSourcesNodeIds: [],
  isShowingWelcomeDisclaimer:
    window.localStorage.getItem(HIDE_WELCOME_DISCLAIMER_LOCAL_STORAGE_KEY) !==
    "true"
};

export const removeSectionFromReportBySectionId = (
  report: RawReport,
  sectionId: string
): RawReport => {
  const updatedSections = report.sections.filter(
    section => section.id !== sectionId
  );

  return {
    ...report,
    sections: updatedSections
  };
};

export const removeSubSectionFromReportBySubSectionId = (
  report: RawReport,
  subsectionId: string
): RawReport => {
  const topSection = report.sections.find((s: RawSection) =>
    s.subsections.find((sub: RawSubsection) => sub.id === subsectionId)
  );

  if (!topSection) return report;

  const updatedSubSections = topSection.subsections.filter(
    (sub: RawSubsection) => sub.id !== subsectionId
  );

  const updatedSection = { ...topSection, subsections: updatedSubSections };

  return {
    ...report,
    sections: report.sections.map((s: RawSection) =>
      s.id === updatedSection.id ? updatedSection : s
    )
  };
};

export const removeElementFromReportByElementId = (
  report: RawReport,
  elementId: string
): RawReport => {
  const topSection = report.sections.find((s: RawSection) =>
    s.subsections.find((sub: RawSubsection) =>
      sub.elements.find(element => element.id === elementId)
    )
  );

  if (!topSection) return report;

  const subSection = topSection.subsections.find((sub: RawSubsection) =>
    sub.elements.find(element => element.id === elementId)
  );

  if (!subSection) return report;

  const updatedElements = subSection.elements.filter(
    (element: RawElement) => element.id !== elementId
  );

  const updatedSubSection = { ...subSection, elements: updatedElements };

  const updatedSection = {
    ...topSection,
    subsections: topSection.subsections.map(sub =>
      sub.id === subSection.id ? updatedSubSection : sub
    )
  };

  return {
    ...report,
    sections: report.sections.map((s: RawSection) =>
      s.id === updatedSection.id ? updatedSection : s
    )
  };
};

export const insightReportReducer = (
  state: InsightReportState,
  action: InsightReportAction
) => {
  switch (action.type) {
    case InsightReportActions.updateActiveSectionSlug: {
      return {
        ...state,
        activeSectionSlug: action.slug,
        previousSectionSlug: state.activeSectionSlug
      };
    }
    case InsightReportActions.showPreviousSectionSlug: {
      if (!state.previousSectionSlug) {
        return {
          ...state,
          activeSectionSlug: "overview"
        };
      }

      return {
        ...state,
        activeSectionSlug: state.previousSectionSlug
      };
    }
    case InsightReportActions.removeReportSection: {
      return {
        ...state,
        status: InsightReportStatus.removingReportSection,
        sectionIdToRemove: action.sectionId
      };
    }
    case InsightReportActions.removeReportSubSection: {
      return {
        ...state,
        status: InsightReportStatus.removingReportSubSection,
        sectionIdToRemove: action.sectionId,
        subsectionIdToRemove: action.subSectionId
      };
    }
    case InsightReportActions.removeReportElement: {
      return {
        ...state,
        status: InsightReportStatus.removingReportElement,
        sectionIdToRemove: action.sectionId,
        subsectionIdToRemove: action.subSectionId,
        elementIdToRemove: action.elementId
      };
    }
    case InsightReportActions.fetchReport: {
      return { ...state, status: InsightReportStatus.fetching };
    }
    case InsightReportActions.updateReport: {
      const report = convertRawReport(action.reportId, action.rawReport);
      const activeSectionSlug = report.sections.find(
        (section: InsightReportSection) =>
          section.slug === state.activeSectionSlug
      )
        ? state.activeSectionSlug
        : report.sections[0].slug;

      return {
        ...state,
        activeSectionSlug,
        status: InsightReportStatus.idle,
        report,
        rawReport: action.rawReport,
        sectionIdToRemove: undefined,
        subsectionIdToRemove: undefined,
        errorMessage: undefined
      };
    }
    case InsightReportActions.errorFetchingReport: {
      return {
        ...state,
        status: InsightReportStatus.errorFetchingReport,
        errorMessage: action.errorMessage
      };
    }
    case InsightReportActions.errorFetchingSources: {
      return {
        ...state,
        status: InsightReportStatus.errorFetchingSources
      };
    }
    case InsightReportActions.updatingFetchingSources: {
      const nodeIds = new Set(state.fetchedSourcesNodeIds);
      nodeIds.add(action.nodeId);

      return {
        ...state,
        status: InsightReportStatus.fetchingSources,
        fetchingSourceNodeId: action.nodeId,
        fetchedSourcesNodeIds: [...nodeIds]
      };
    }
    case InsightReportActions.updateReportSubSection: {
      if (!state.report) return state;
      if (!state.rawReport) return state;

      const topSection = state.rawReport.sections.find((s: RawSection) =>
        s.subsections.find(
          (sub: RawSubsection) => sub.id === action.subsectionId
        )
      );

      if (!topSection) return state;

      const updatedSubSections = topSection.subsections.map(
        (sub: RawSubsection) =>
          sub.id === action.subsectionId ? action.subSection : sub
      );

      const updatedSection = { ...topSection, subsections: updatedSubSections };

      const updatedRawReport = {
        ...state.rawReport,
        sections: state.rawReport.sections.map((s: RawSection) =>
          s.id === updatedSection.id ? updatedSection : s
        )
      };

      // @ts-ignore TODO: Fix this after merging this :)
      const report = convertRawReport(state.report.id, updatedRawReport);

      return {
        ...state,
        report,
        rawReport: updatedRawReport,
        status: InsightReportStatus.idle
      };
    }
    case InsightReportActions.showWelcomeDisclaimer: {
      return { ...state, isShowingWelcomeDisclaimer: true };
    }
    case InsightReportActions.hideWelcomeDisclaimer: {
      window.localStorage.setItem(
        HIDE_WELCOME_DISCLAIMER_LOCAL_STORAGE_KEY,
        "true"
      );
      return { ...state, isShowingWelcomeDisclaimer: false };
    }
    case InsightReportActions.removeReport: {
      return {
        ...state,
        rawReport: undefined,
        status: InsightReportStatus.removed
      };
    }
    case InsightReportActions.regenerateReport: {
      return {
        ...state,
        status: InsightReportStatus.regeneratingReport
      };
    }
    default:
      return state;
  }
};

export const isShowingInsightsReport = (
  state: InsightReportState,
  report: any
) => {
  const { status } = state;
  const isShowingReport =
    report &&
    (status === InsightReportStatus.idle ||
      status === InsightReportStatus.errorRemovingReportSection ||
      status === InsightReportStatus.removingReportSection ||
      status === InsightReportStatus.fetchingSources ||
      status === InsightReportStatus.removingReportSubSection ||
      status === InsightReportStatus.removingReportElement ||
      status === InsightReportStatus.errorFetchingSources);

  return isShowingReport;
};

export const InsightReportContext = createContext({
  state: initialState,
  dispatch: (_action: InsightReportAction) => {}
});
