import React from "react";
import { MoreVertical, UserPlus } from "react-feather";

import Popover from "components/atoms/Popover";
import Menu from "components/molecules/Menu";
import MenuItem from "components/molecules/Menu/MenuItem";

import { ReactComponent as CircleHollow } from "img/icons/circle-hollow.svg";


import S from "components/organisms/ReportCardMenu/styles";

const PersonaCardMenu = () => {
    return (
        // Stop click event from opening report
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div data-cy="report-card-menu" onClick={e => e.stopPropagation()}>
            <Popover
                hideArrow
                alignment="bottom-end"
                trigger="click"
                content={
                    <Menu>
                        <MenuItem
                            IconLeading={UserPlus}
                            text="Option 1"
                            onMenuItemClick={() => (console.log("Option2"))}
                        />
                        <MenuItem
                            IconLeading={CircleHollow}
                            text={"Option 2"}
                            showSeparator
                            onMenuItemClick={() => (console.log("Option2"))}
                        />
                    </Menu>
                }
                disableHideOnClip={undefined}
                className={undefined}
                style={undefined}
            >
                <S.MenuButton>
                    <MoreVertical />
                </S.MenuButton>
            </Popover>

            {/*Define modals here*/}
        </div>
    );
};

export default PersonaCardMenu;
