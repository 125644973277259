import React from "react";

import PersonasOverview from "./PersonasOverview";
import PageTitle from "../../components/atoms/PageTitle";

const EnquirySummary = () => {

  return (
      <div className="shell-outer-padded">
        <div className="shell-inner">
          <div className="simple-info">
            <PageTitle title="Xapien | Enquiry Summary" />
            <h1 className="large" style={{ marginTop: 0 }}>
              Enquiry Summary
            </h1>
            <PersonasOverview />
          </div>
        </div>
      </div>
  );
};

export default EnquirySummary;
