import React from "react";

import { Info, X } from "react-feather";

import S from "./styles";

const DisabledConfirmingModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <S.Container>
      <S.CloseButton onClick={onClose}>
        <X />
      </S.CloseButton>
      <Info />
      <S.Heading level={3}>Tell Xapien what you know</S.Heading>
      <S.Paragraph>
        Confirming and discarding data is currently disabled.
      </S.Paragraph>
      <S.Paragraph>
        If you require any further support, please
        <br />
        contact{" "}
        <a
          href="mailto:support@xapien.com?subject=Tell Xapien what you know"
          target="_blank"
          rel="noreferrer"
        >
          Customer Success
        </a>
        .
      </S.Paragraph>
    </S.Container>
  );
};

export default DisabledConfirmingModal;
