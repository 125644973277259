import React, { ReactNode, FC, useState } from "react";
import { Copy, Edit, CheckCircle, MessageCircle, Trash2 } from "react-feather";

import Popover from "components/atoms/Popover";
import Menu from "components/molecules/Menu";
import MenuItem from "components/molecules/Menu/MenuItem";

import {
  Report as RawReport,
  Section as RawSection,
  Subsection as RawSubsection
} from "api/insights";

import { useInsightReport } from "util/hooks/useInsightReport";

import RemoveSectionModal from "components/organisms/InsightReport/RemoveSectionModal";
import ProvideFeedbackModal from "components/organisms/InsightReport/ProvideFeedbackModal";

import { copyToClipboard } from "components/organisms/InsightReport/utils";

import S from "./styles";

interface Props {
  id: string;
  subject?: string;
  children: ReactNode;
  richText: string;
  plainText: string;
  sectionTitle?: ReactNode;
}

const getSectionBySubsectionId = (id: string, rawReport: RawReport) => {
  return rawReport.sections.find(s => s.subsections.find(sub => sub.id === id));
};

const getSectionByElementId = (id: string, rawReport: RawReport) => {
  return rawReport.sections.find(s =>
    s.subsections.find(sub => sub.elements.find(element => element.id === id))
  );
};

const getSubsectionBySubsectionId = (id: string, section: RawSection) => {
  return section.subsections.find(sub => sub.id === id);
};

const getSubsectionByElementId = (id: string, section: RawSection) => {
  return section.subsections.find(sub =>
    sub.elements.find(element => element.id === id)
  );
};

const getElementBySubSection = (id: string, subSection: RawSubsection) => {
  return subSection.elements.find(element => element.id === id);
};

const WithContextMenu: FC<Props> = ({
  id,
  subject,
  children,
  richText,
  plainText,
  sectionTitle
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isRemovingSection, setIsRemovingSection] = useState(false);
  const [isProvidingFeedback, setIsProvidingFeedback] = useState(false);
  const { state } = useInsightReport();

  if (!state.rawReport) return null;

  const section =
    getSectionBySubsectionId(id, state.rawReport) ??
    getSectionByElementId(id, state.rawReport);

  let subSection = section
    ? getSubsectionBySubsectionId(id, section)
    : undefined;

  if (section && !subSection) {
    subSection = getSubsectionByElementId(id, section);
  }

  const element =
    section && subSection ? getElementBySubSection(id, subSection) : undefined;

  const onEdit = () => {
    // eslint-disable-next-line no-alert
    alert(`Editing ${id}`);
  };

  const onCopy = async () => {
    setIsCopied(true);

    copyToClipboard({ richText, plainText });
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const onRemove = () => {
    setIsRemovingSection(prev => !prev);
  };

  const onProvideFeedback = () => {
    setIsProvidingFeedback(prev => !prev);
  };

  const getRemovalTitle = () => {
    if (element && element.title) {
      return element.title;
    }

    if (subSection && subSection.title) {
      return subSection.title;
    }

    return sectionTitle;
  };

  const removalTitle = getRemovalTitle();

  const feedbackContext = [
    { key: "Report", value: subject },
    { key: "Section", value: sectionTitle },
    { key: "Sub-section", value: subSection?.title }
  ];

  return (
    <S.ContextMenuContainer isRemovingSection={isRemovingSection}>
      <S.ContextMenuControl>
        <Popover
          alignment="bottom-end"
          trigger="click"
          content={
            <Menu>
              <MenuItem
                disabled
                IconLeading={Edit}
                text="Edit title"
                onMenuItemClick={onEdit}
              />
              <MenuItem
                IconLeading={MessageCircle}
                text="Provide feedback"
                onMenuItemClick={onProvideFeedback}
              />
              <MenuItem
                IconLeading={isCopied ? CheckCircle : Copy}
                text={isCopied ? "Copied!" : "Copy to clipboard"}
                onMenuItemClick={onCopy}
              />
              <MenuItem
                isDeleteAction
                IconLeading={Trash2}
                text="Delete section"
                onMenuItemClick={onRemove}
              />
            </Menu>
          }
        >
          <S.MenuControl />
        </Popover>
      </S.ContextMenuControl>
      {children}

      <ProvideFeedbackModal
        isOpen={isProvidingFeedback}
        onToggle={onProvideFeedback}
        context={feedbackContext}
      />

      <RemoveSectionModal
        isOpen={isRemovingSection}
        onToggle={onRemove}
        sectionId={section ? section.id : id}
        subSectionId={subSection ? (subSection as RawSubsection).id : undefined}
        elementId={element?.id}
        title={(removalTitle as string) ?? ""}
      />
    </S.ContextMenuContainer>
  );
};

export default WithContextMenu;
