import React, { useState } from "react";

import { GetReportErrorCodes } from "api/insight-reports";

import {
  useInsightReport,
  isShowingInsightsReport
} from "util/hooks/useInsightReport";

import { Section } from "pages/report/Section";

import { InsightReportStatus } from "util/hooks/useInsightReport/types";
import MainMenuControl from "./MainMenuControl";
import ReportContent from "./ReportContent";
import LoadingReport from "./LoadingReport";
import ErrorReport from "./ErrorReport";
import ErrorGenerating from "./ErrorGenerating";
import WelcomeDisclaimer from "./WelcomeDisclaimer";

import S from "./styles";

const InsightReport = () => {
  const { state } = useInsightReport();
  const [isExpanded, setExpanded] = useState(true);

  const { status, report, errorMessage, isShowingWelcomeDisclaimer } = state;

  const isShowingReport = isShowingInsightsReport(state, report);

  const isShowingGeneratingError =
    status === InsightReportStatus.errorFetchingReport &&
    errorMessage === GetReportErrorCodes.NO_WAM_ERROR;

  const isShowingUnknownError =
    (status === InsightReportStatus.regeneratingReport ||
      status === InsightReportStatus.errorFetchingReport) &&
    (errorMessage === GetReportErrorCodes.FAILED_GENERATING_ERROR ||
      errorMessage === GetReportErrorCodes.GENERIC_ERROR);

  const isShowingLoading =
    status === InsightReportStatus.fetching ||
    errorMessage === GetReportErrorCodes.IN_PROGRESS_ERROR;

  // As we wait for a "not found" error to determine whether we hide the
  // XI report, the XI section may show until we have that error, which results
  // in a flickering UI for users who don't have XI enabled.
  const reportNotYetFetched =
    errorMessage !== GetReportErrorCodes.IN_PROGRESS_ERROR &&
    !report &&
    (status === InsightReportStatus.idle ||
      status === InsightReportStatus.fetching);

  if (
    errorMessage === GetReportErrorCodes.NOT_FOUND_ERROR ||
    reportNotYetFetched
  ) {
    return null;
  }

  if (status === InsightReportStatus.removed) {
    return null;
  }

  return (
    <Section
      isExpanded={isExpanded}
      setExpanded={setExpanded}
      title="Xapien Insights"
    >
      {isShowingGeneratingError ? (
        <S.Panel>
          <ErrorGenerating />
        </S.Panel>
      ) : (
        <>
          {isShowingWelcomeDisclaimer && <WelcomeDisclaimer />}
          <S.Panel>
            <S.Title>
              <S.LogoContainer>
                <S.LogoIcon />
              </S.LogoContainer>
              <span>
                Xapien Insights is under development - we cannot fully assure
                the accuracy of all responses.{" "}
                <strong>Please check the sourcing.</strong>
              </span>
              <MainMenuControl report={report} />
            </S.Title>
          </S.Panel>
          <S.Divider />
          <S.Panel>
            <S.Container>
              {isShowingUnknownError && <ErrorReport />}
              {isShowingLoading && <LoadingReport />}
              {isShowingReport && <ReportContent />}
            </S.Container>
          </S.Panel>
        </>
      )}
    </Section>
  );
};

export default InsightReport;
