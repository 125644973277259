import { styled } from "@linaria/react";

import { grey, blue } from "styles/colors";

import { isPDX } from "static-config";

import { ReactComponent as GoogleLogoSvg } from "img/google-logo.svg";

const SourceLinkPopoverContainer = styled.div`
  margin-top: 20px;
`;

const TranslatedLabel = styled.div`
  margin-top: 14px;
  margin-bottom: -2px;
`;

const Subheading = styled.div`
  border-left: 4px solid ${blue.blueGrey};
  padding-left: 0.5rem;
  color: ${grey.mid};
  font-style: italic;
  margin: 0.2rem;
`;

const SourceCardInnerPanel = styled.div<{
  hasSubHeading: boolean;
  hide: boolean;
}>`
  min-height: ${({ hasSubHeading }) =>
    isPDX || !hasSubHeading ? "6rem" : "18.75rem"};
  visibility: ${({ hide }) => (hide ? "hidden" : "visible")};

  .di-card-main-text {
    hr {
      display: none;
    }
  }
`;

const GoogleLogo = styled(GoogleLogoSvg)`
  margin-left: 5px;
  height: 16px;
  width: 122px;
  filter: grayscale(100%);
`;

const S = {
  SourceLinkPopoverContainer,
  TranslatedLabel,
  Subheading,
  SourceCardInnerPanel,
  GoogleLogo
};

export default S;
