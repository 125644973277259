import React, { useState, useEffect, useCallback } from "react";

import isEmpty from "lodash.isempty";
import { inject, observer } from "mobx-react";

import TextEditor from "components/molecules/TextEditor";
import { Section } from "pages/report/Section";
import SectionFooter from "components/atoms/SectionFooter";
import { useUserNotes } from "util/hooks/useUserNotes";
import useViewerMode from "util/hooks/useViewerMode";

import S from "./styles";

const Notes = React.forwardRef(
  ({ title, isExpanded, setExpanded, enquiryStore }, ref) => {
    const { isSharedReportLink } = useViewerMode();
    const userNotesStore = useUserNotes();

    const [savedNotesContent, setSavedNotesContent] = useState({
      type: "doc",
      content: [{ type: "paragraph" }]
    });

    const getUserNotes = useCallback(async () => {
      const notes = await userNotesStore.getUserNotes(
        enquiryStore.currentEnquiryId
      );
      // Guard against setting the state to an empty object. We'll rely on
      // falling back to the initial state that is set on mount.
      if (!isEmpty(notes)) {
        setSavedNotesContent(notes);
      }
    }, [userNotesStore, enquiryStore]);

    useEffect(() => {
      if (isSharedReportLink) return;

      getUserNotes();
    }, [getUserNotes, isSharedReportLink]);

    const saveUserNotes = async userNotes => {
      const response = await userNotesStore.setUserNotes(
        enquiryStore.currentEnquiryId,
        userNotes
      );

      // Only store the notes when the save is successful
      if (response) {
        setSavedNotesContent(userNotes);
      }

      // The response is a boolean indicating whether the save was successful or not.
      // This is later handled by the text editor.
      return response;
    };

    return (
      <Section
        title={
          <S.TitleContainer>
            <S.PencilIcon />
            {title}
          </S.TitleContainer>
        }
        isExpanded={isExpanded}
        setExpanded={setExpanded}
        ref={ref}
      >
        <S.NotesContainer>
          <TextEditor
            content={savedNotesContent}
            onSave={saveUserNotes}
            isSaving={userNotesStore.isSaving}
            editorId={`note-editor-${enquiryStore.currentEnquiryId}`}
          />
        </S.NotesContainer>
        <SectionFooter />
      </Section>
    );
  }
);

export default inject("enquiryStore")(observer(Notes));
