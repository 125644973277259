import { styled } from "@linaria/react";

import { motion } from "framer-motion/dist/framer-motion";

import { ReactComponent as SendPlane } from "img/icons/send-plane.svg";

import { AlertCircle, X } from "react-feather";
import { grey, red, purple, standardColors } from "styles/colors";
import { fontSize, family } from "styles/fonts";

const InsightsChat = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  position: relative;
`;

const ChatDescription = styled.p`
  font-size: ${fontSize.sm};
  margin: 0;
`;

const ChatAndInputSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ChatSection = styled.div`
  overflow: auto;
  flex: 1;
  padding-bottom: 1rem;
`;

const Send = styled(SendPlane)``;

const SendButton = styled.button`
  all: unset;
  border-radius: 50%;
  background: ${purple.darker};
  color: ${standardColors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  cursor: pointer;

  &:not(:focus-visible, :hover) {
    outline: none;
  }

  &:disabled {
    outline: none;
    cursor: not-allowed;
  }
`;

const Alert = styled(AlertCircle)`
  stroke: ${red.directRiskOutline};
  margin-top: 0.25rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25em;
`;

const TooltipContent = styled.p`
  margin-bottom: 0;
  padding: 0.875rem;
  font-size: ${fontSize.sm};
`;

const SuggestedQuestions = styled(motion.div)`
  position: absolute;
  background-color: ${grey.panel};
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  bottom: 97px;
  padding-top: 36px;
`;

const Suggestion = styled.button`
  all: unset;
  width: 310px;
  box-sizing: border-box;
  padding: 12px;
  color: ${purple.darker};
  background-color: ${purple.lighter};
  font-size: ${fontSize.sm};
  transition: background-color 0.3s;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:not(:focus-visible) {
    outline: none;
  }

  &:hover,
  &:focus {
    color: ${purple.darker};
    background-color: ${purple.lightish};
  }
`;

const AskInputContainer = styled.div`
  padding: 1rem 0;
  height: 97px;
  display: flex;
  flex-direction: column;

  .MuiInputBase-root {
    border-radius: 40px;
    box-shadow: 0px 0px 4px 0px #00000040 inset;
    padding-right: 4px;
    font-size: ${fontSize.sm};

    &:hover,
    &:focus,
    &:active,
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${purple.darker};
        box-shadow: 0px 0px 6px 0px ${purple.darker};
        outline: none;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      outline: none;
    }

    input {
      outline: none;
      padding: 12px 20px;
    }
  }
`;

const Heading = styled.h1`
  font-size: ${fontSize.lg};
  font-family: ${family.interDisplay};
`;

const CloseButton = styled.button`
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  background: ${standardColors.white};
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${grey.rule};
  }
`;

const CloseButtonIcon = styled(X)``;

const S = {
  CloseButton,
  CloseButtonIcon,
  AskInputContainer,
  InsightsChat,
  ChatDescription,
  ChatSection,
  ChatAndInputSection,
  Send,
  SendButton,
  Alert,
  HeaderContainer,
  TooltipContent,
  SuggestedQuestions,
  Suggestion,
  Heading
};

export default S;
