import { createContext, useContext } from "react";

export const PersonaIdContext = createContext<string | undefined>(undefined);
export const usePersonaId = (): string => {
  const id = useContext(PersonaIdContext);
  if (id && id.length) {
    return id;
  }
  throw new Error("No Persona ID");
};
