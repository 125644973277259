import React, { useState } from "react";

import Group from "./Group";
import ListItem from "./ListItem";
import ExpandButton from "./ExpandButton";
import S from "./styles";

const List = ({
  title,
  footerText,
  list,
  initialItemsShownCount = 10,
  subTextWithoutSourcing = false,
  withoutBulletPoint = false,
  textIsWrappedWithSourcing = false,
  className,
  titleClassName
}) => {
  const [isExpanded, setExpand] = useState(false);
  const expandable = list.length > initialItemsShownCount;
  const numberToDisplay = isExpanded
    ? list.length
    : Math.min(list.length, initialItemsShownCount);

  const toggleExpand = () => {
    setExpand(!isExpanded);
  };

  return (
    <div className={`list ${className}`}>
      <div className={`heading ${titleClassName}`}>{title}</div>
      {list?.length ? (
        <ul>
          {list
            .slice(0, numberToDisplay)
            .map((l, index) =>
              l.group && l.group.length > 0 ? (
                <Group
                  key={`ListGroup-${l.org}-${index}`}
                  groupData={l}
                  groupLabel={l.groupLabel}
                  textIsWrappedWithSourcing={textIsWrappedWithSourcing}
                />
              ) : (
                <ListItem
                  key={`ListItem-${l.org}-${index}`}
                  item={l}
                  withoutBulletPoint={withoutBulletPoint}
                  subTextWithoutSourcing={subTextWithoutSourcing}
                  textIsWrappedWithSourcing={textIsWrappedWithSourcing}
                />
              )
            )}
        </ul>
      ) : (
        <S.NoData>None identified</S.NoData>
      )}
      <ExpandButton
        data={list}
        isSectionExpandable={expandable}
        isSectionExpanded={isExpanded}
        onClick={toggleExpand}
      />
      {footerText && list?.length > 0 ? (
        <footer className="experimental-section__list-footer">
          {footerText}
        </footer>
      ) : null}
    </div>
  );
};

export default React.memo(List);
