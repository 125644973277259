import React, {
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
  useContext,
  useCallback
} from "react";
// @ts-ignore
import clamp from "clamp-js";
// @ts-ignore
import { sanitize } from "dompurify";

import { ButtonKind } from "components/atoms/Button/types";
import { SearchAssistExperimentalModeContext } from "util/context/SearchAssistExperimetalModeContext";
import { OrgSuggestion } from "api/define/types";

// @ts-ignore
import theme from "theme";

import S from "./styles";

interface Props {
  orgSuggestions: OrgSuggestion[];
  onSearchSuggestionSelected: (
    suggestionSubject: string,
    suggestionContext: string
  ) => void;
  suggestionObject: OrgSuggestion;
}

const SubjectSelectionStage = ({
  orgSuggestions = [],
  onSearchSuggestionSelected,
  suggestionObject
}: Props) => {
  const singleGoButtonRef = useRef<HTMLButtonElement>(null);
  const suggestionDescriptionRef = useRef(null);

  const [logoErrored, setLogoErrored] = useState(false);

  const searchAssistExperimentalMode = useContext(
    SearchAssistExperimentalModeContext
  ).enabled;

  useEffect(() => {
    singleGoButtonRef?.current?.focus();
  }, []);

  const clampTextFields = useCallback(() => {
    if (suggestionDescriptionRef?.current) {
      if (searchAssistExperimentalMode) {
        clamp(suggestionDescriptionRef.current, {
          clamp: "auto" // basically no clamping - helpful for development
        });
      } else {
        clamp(suggestionDescriptionRef.current, {
          clamp: 3
        });
      }
    }
  }, [searchAssistExperimentalMode]);

  useLayoutEffect(() => {
    clampTextFields();
  }, [clampTextFields]);

  return (
    <S.SuggestionItem>
      <S.LogoContainer>
        {suggestionObject.logo && !logoErrored ? (
          <S.OrgLogo
            src={`data:image/png;base64,${suggestionObject.logo}`}
            alt="Organisation logo"
            width="85"
            height="85"
            onError={() => {
              setLogoErrored(true);
            }}
          />
        ) : (
          <S.OrgPlaceholderIcon />
        )}
      </S.LogoContainer>
      <S.SuggestionBodyContainer>
        <S.SuggestionName>{suggestionObject.name}</S.SuggestionName>
        <S.SuggestionUrl>{suggestionObject.url}</S.SuggestionUrl>
        <S.SuggestionStraplineAndDescription ref={suggestionDescriptionRef}>
          {suggestionObject.strapline && (
            <S.SuggestionStrapline>
              {suggestionObject.strapline}.{" "}
            </S.SuggestionStrapline>
          )}
          <S.SuggestionDescription
            dangerouslySetInnerHTML={{
              __html: sanitize(suggestionObject.description)
            }}
          />
        </S.SuggestionStraplineAndDescription>
      </S.SuggestionBodyContainer>
      <S.GoButton
        ref={orgSuggestions.length === 1 ? singleGoButtonRef : null}
        onClick={() =>
          onSearchSuggestionSelected(
            suggestionObject.name,
            suggestionObject.fullUrl
          )
        }
        kind={ButtonKind.primary}
        isHighlighted={orgSuggestions.length === 1}
        backgroundColor={theme.alternativePrimaryColor}
      >
        Go
      </S.GoButton>
    </S.SuggestionItem>
  );
};

export default SubjectSelectionStage;
