import React from "react";
import {CollectionListContextProvider} from "../../../util/hooks/useCollectionList";
import {MakeApi, PersonaSummary} from "../../../api/enquirySummary/EnquirySummaryAPI";
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";
import S from "../../Portal/Reports/styles";
import PersonaList from "../PersonaList";

interface Props {
    personaSummaries: PersonaSummary[]
}

const Overview = ({
  personaSummaries
}: Props) => {
    return (
      <CollectionListContextProvider ConstructApi={MakeApi(personaSummaries)}>
          <AnimatePresence>
              <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
              >
                  <S.ViewContainer>
                      <S.ViewInnerContainer>
                          <PersonaList/>
                      </S.ViewInnerContainer>
                  </S.ViewContainer>
              </motion.div>
          </AnimatePresence>
      </CollectionListContextProvider>
  );
};

export default Overview;
