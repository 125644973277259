import { styled } from "@linaria/react";

import { motion } from "framer-motion/dist/framer-motion";

import { hexToRgb } from "styles/hexToRgb";
import { green, grey, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const Panel = styled.div`
  position: relative;
  width: 100%;
  background-color: ${grey.panel};
  page-break-inside: avoid;
  padding: 16px;
  min-height: 30px;
`;

const ConfidenceFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 19px;
  border-bottom: 1px solid ${grey.rule};
  margin-bottom: 16px;
`;

const ConfidenceButton = styled.button`
  height: 36px;
  width: 318px;
  width: 100%;
  flex: 1;
  border: 1px solid
    ${props =>
      props.isSelected ? props.color ?? green.xapienGreen : grey.ghost};
  border-radius: 20px;
  color: ${props => (props.isSelected ? standardColors.white : grey.mid)};
  font-size: ${fontSize.lg};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background-color: ${props =>
    props.isSelected ? props.color ?? green.xapienGreen : grey.faint};
  transition: box-shadow 0.2s;

  h2 {
    margin: 0;
  }

  &:hover,
  &:focus {
    outline: none;
    box-shadow: 0 0 0 4px
      ${props => {
        if (props.isSelected) {
          const { r, g, b } = hexToRgb(props.color ?? green.xapienGreen);
          return `rgba(${r}, ${g}, ${b}, 0.33)`;
        }
        return "rgb(210 211 216 / 33%)";
      }};
  }
`;

const SectionBannerText = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

const SectionCount = styled.div`
  font-size: ${fontSize["2xl"]};
  color: ${grey.dark};
`;

const Motion = styled(motion.div)`
  flex: 1;
  margin: 0 16px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const SectionBanner = styled.div`
  width: 100%;
  background-color: ${grey.panel};
  border-radius: 2px;
  padding: 8px 16px;
  height: 54px;
  justify-content: space-between;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

const EmptySectionBanner = styled.div`
  background-color: ${grey.panel};
  height: 54px;
  display: flex;
  align-items: center;
  color: ${grey.mid};
  font-size: ${fontSize.sm};
  padding: 16px;
`;

const S = {
  Panel,
  ConfidenceFilters,
  ConfidenceButton,
  SectionBanner,
  SectionBannerText,
  SectionCount,
  Motion,
  EmptySectionBanner
};

export default S;
