import { styled } from "@linaria/react";

import Flag from "pages/report/Flag";
import { ReactComponent as OrgSvg } from "img/building.svg";
import AccordionComponent from "components/molecules/Accordion";
import InfoSummaryRowComponent from "components/molecules/InfoSummaryRow";

import { fontSize, family } from "styles/fonts";
import { grey, blue, green, yellow, red, purple } from "styles/colors";
import { hexToRgb } from "styles/hexToRgb";

const CountryContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${grey.dark};
`;

const Accordion = styled(AccordionComponent)`
  border-bottom: none !important;
  padding-top: 13px !important;
  margin-left: -16px;
  width: fit-content;

  & > div {
    margin-left: ${({ countryFlagDisplaying }) =>
      countryFlagDisplaying ? 0 : "-31px"};
  }

  & > button {
    flex-direction: row-reverse;
    justify-content: start;
    gap: 3px;
  }
`;

const PreviousNamesAccordion = styled(Accordion)`
  padding-top: 2px !important;
  padding-bottom: 0 !important;
  margin-top: 4px;

  & > button {
    color: ${blue.mid};
    font-family: ${family.interSemiBold};
  }
`;

const Address = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  margin-left: 47px;
  margin-top: 4px;
`;

const OrgIconContainer = styled.div`
  min-width: 36px;
  height: 36px;
  border-radius: 3px;
  background-color: ${green.xapienGreen};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

const OrgIcon = styled(OrgSvg)`
  transform: scale(0.65);
`;

const InfoSummaryRow = styled(InfoSummaryRowComponent)`
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  ul {
    padding: 0 14px;
    margin: 0;
  }
`;

const CompanyLocation = styled.div`
  color: ${grey.mid};
  margin-bottom: 8px;
`;

const StatusTags = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`;

const StatusTag = styled.div`
  --statuscolor: ${status => {
    let color;
    switch (status) {
      case "Active":
        color = green.secondary;
        break;
      case "Warning":
        color = red.directRisk;
        break;
      case "Inactive":
        color = yellow.mud;
        break;
      default:
        color = grey.dark;
    }
    const { r, g, b } = hexToRgb(color);
    const opacity = status === "Warning" ? 1 : 0.6;
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }};
  border-radius: 11px;
  background-color: transparent;
  border: 1px solid var(--statuscolor);
  color: var(--statuscolor);
  padding: 0 8px;
  font-size: ${fontSize.sm};
  line-height: 18px;
  height: 22px;
  width: fit-content;
  max-width: 165px;
`;

const CustomFlag = styled(Flag)`
  width: 31px;
  img {
    width: 24px !important;
    height: 16px !important;
    margin: 0;
    margin-top: 1px !important;
    margin-right: 5px !important;
    border-radius: 2px !important;
  }
`;

const PreviousName = styled.div`
  color: ${blue.mid};
  font-family: ${family.interSemiBold};
  font-size: ${fontSize.sm};
`;

const SinglePreviousName = styled(PreviousName)`
  margin-top: 4px;
`;

const Text = styled.span`
  color: ${grey.dark};
  font-family: ${family.interRegular};
`;

const PreviousNamesList = styled.ul`
  margin-left: 52px !important;
`;

const ListItem = styled.li`
  &::marker {
    color: ${grey.mid};
    font-size: ${fontSize.md};
  }
`;

const SingleAddressContainer = styled.div`
  padding-top: 13px;
  padding-bottom: 3px;
`;

const Date = styled.span`
  color: ${grey.mid};
`;

const PersonDetails = styled.div`
  font-size: ${fontSize.sm};
  border-top: 1px solid ${grey.rule};
  padding-top: 10px;
  margin-top: 12px;

  & > div:not(:first-child) {
    margin-top: 12px;
  }
`;

const OfficershipRole = styled.div`
  & + & {
    margin-top: 4px;
  }
`;

const Role = styled.div`
  color: ${grey.mid};
`;

const RoleDate = styled.span`
  color: ${({ isOngoing }) => (isOngoing ? green.secondary : grey.dark)};
`;

const RoleDuration = styled.span`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

const CompanyIdentifier = styled.div`
  padding-top: 5px;
`;

const Label = styled.span`
  color: ${grey.mid};
`;

const IndustryType = styled.div`
  color: ${grey.mid};
`;

const ROLE_COLOR_MAP = {
  UBO: yellow.mud,
  PSC: blue.blueGreen,
  Officer: purple.dark
};

const RolePill = styled.div`
  width: fit-content;
  margin: 4px 0;
  padding: 0px 8px;
  border: 1px solid ${grey.ghost};
  border-radius: 16px;
  margin-right: 5px;
  transition: box-shadow 0.2s;
  font-size: ${fontSize.sm};
  text-transform: capitalize;
  opacity: 0.6;
  border-color: ${({ roleType, roleIsOngoing }) =>
    roleIsOngoing ? ROLE_COLOR_MAP[roleType] : grey.mid};
  color: ${({ roleType, roleIsOngoing }) =>
    roleIsOngoing ? ROLE_COLOR_MAP[roleType] : grey.mid};

  &:last-child {
    margin-right: 0;
  }
`;

const S = {
  CountryContainer,
  Accordion,
  PreviousNamesAccordion,
  Address,
  OrgIcon,
  OrgIconContainer,
  InfoSummaryRow,
  StatusTags,
  StatusTag,
  CompanyLocation,
  CustomFlag,
  PreviousName,
  SinglePreviousName,
  Text,
  PreviousNamesList,
  ListItem,
  SingleAddressContainer,
  Date,
  OfficershipRole,
  Role,
  RoleDate,
  RoleDuration,
  PersonDetails,
  CompanyIdentifier,
  Label,
  IndustryType,
  RolePill
};

export default S;
