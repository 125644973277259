import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  Fragment,
  useCallback
} from "react";
import Masonry from "react-masonry-css";

import {
  PRINTABLE_STATE_TYPES,
  usePrintableReportState
} from "util/hooks/usePrintableState";
import MapboxComponent from "components/molecules/MapboxComponent";
import { ImageSizeToUse } from "util/ImageSizeToUse";
import { TruncateLength } from "util/TruncateLength";
import { ReactComponent as MissingStreetView } from "img/icons/missing-street-view.svg";
import LocationRiskTag from "components/atoms/LocationRiskTag";
import SectionFooter from "components/atoms/SectionFooter";
import SourceLink from "components/atoms/SourceLink";
import { formatDate } from "util/common";

import { grey } from "styles/colors";

import { generateUniqueCityString } from "./utils";
import LocationBar from "./LocationsBar";
import S, { classNameOverrides } from "./styles";

const Roles = ({ roles, title, rolesLimit = 5 }) => {
  const [isShowingAll, setIsShowingAll] = useState(false);

  let updatedRoles = [...roles];
  updatedRoles.sort((a, b) =>
    a.organisationName.localeCompare(b.organisationName)
  );
  const rolesExceedLimits = roles.length > rolesLimit;

  if (roles.length <= 0) {
    return null;
  }
  if (rolesExceedLimits && !isShowingAll) {
    updatedRoles = updatedRoles.slice(0, rolesLimit);
  }
  return (
    <S.LocationRolesWrapper>
      <S.RoleSectionTitle>{title}</S.RoleSectionTitle>
      <S.LocationRolesList>
        {updatedRoles.map(role => (
          <S.LocationRoleItem key={`${role.organisationName}${role.start}`}>
            {role.links && role.links.length ? (
              <SourceLink href={role.links}>
                <S.LocationOrgName isDatePresent={role.start}>
                  <TruncateLength>{role.organisationName}</TruncateLength>
                </S.LocationOrgName>
              </SourceLink>
            ) : (
              <S.LocationOrgName isDatePresent={role.start}>
                <TruncateLength>{role.organisationName}</TruncateLength>
              </S.LocationOrgName>
            )}
            {role.start && <S.RoleYear>{` ${role.start.year}`}</S.RoleYear>}
            <S.InfoIcon
              tooltipAlignment="right"
              tooltipPosition="fixed"
              infoContent={
                <S.TooltipContainer>
                  <S.TooltipHeader>Original address data</S.TooltipHeader>
                  <S.TooltipBody>
                    {role.start && (
                      <S.AddressDate>{formatDate(role.start)}</S.AddressDate>
                    )}
                    <S.AddressTooltip>
                      {role.address.map(line => (
                        <div key={line}>{line}</div>
                      ))}
                    </S.AddressTooltip>
                  </S.TooltipBody>
                </S.TooltipContainer>
              }
              tooltipContentClassName={
                classNameOverrides.infoIconTooltipContent
              }
            />
          </S.LocationRoleItem>
        ))}
        {rolesExceedLimits && (
          <S.ShowAllButton onClick={() => setIsShowingAll(!isShowingAll)}>
            Show {isShowingAll ? "less" : `all ${roles.length}`}
          </S.ShowAllButton>
        )}
      </S.LocationRolesList>
    </S.LocationRolesWrapper>
  );
};

const FullLocationCard = ({
  image,
  address,
  correspondenceAddressRoles,
  registeredAddressCompanies,
  riskFlags
}) => {
  return (
    <S.LocationCard data-index={image.id}>
      {image.src ? (
        <S.Image
          alt="Related photograph"
          image={image}
          imageSizeToUse={ImageSizeToUse.Medium}
          lazyLoad={false}
          isCopyrighted
        />
      ) : (
        <S.MissingStreetViewImageContainer>
          <MissingStreetView />
          <S.NoStreetviewInfo>
            Address could not be resolved to a streetview image
          </S.NoStreetviewInfo>
        </S.MissingStreetViewImageContainer>
      )}
      <S.LocationCardContent>
        <div className="col-4 mb-2">
          {riskFlags && riskFlags.length > 0 && (
            <S.LocationRiskFlagsContainer>
              {riskFlags.map(r => (
                <LocationRiskTag key={r.assigner} riskAssigner={r.assigner} />
              ))}
            </S.LocationRiskFlagsContainer>
          )}
          <div className="mb-2">
            {address.map(line => (
              <div key={line}>
                <TruncateLength>{line}</TruncateLength>
              </div>
            ))}
          </div>
        </div>
        <div className="col-8 pl-3">
          {correspondenceAddressRoles && (
            <Roles
              roles={correspondenceAddressRoles}
              title="Correspondence address for"
            />
          )}
          {registeredAddressCompanies && (
            <Roles
              roles={registeredAddressCompanies}
              title="Registered address for"
            />
          )}
        </div>
      </S.LocationCardContent>
    </S.LocationCard>
  );
};

const FilterButton = ({ onClick, active = false, children, disabled }) => (
  <S.FilterButtonContainer>
    <button
      type="button"
      disabled={disabled}
      aria-haspopup="true"
      aria-expanded="false"
      className={`locations__filter-button ${
        active ? "sort-filter-pill-filled" : "locations__sort-filter-pill"
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  </S.FilterButtonContainer>
);

const Locations = ({ locationData, isReportRegenerationOpen }) => {
  const locationsRef = useRef();
  const resultsSectionRef = useRef();
  const [isShowingExpandButton, setIsShowingExpandButton] = useState();

  const formatLocationData = () => {
    return locationData?.map(location => {
      return {
        ...location,
        correspondenceAddressRoles: location.correspondenceAddressRoles.map(
          role => {
            const linkedCompany =
              location.originalAddresses &&
              location.originalAddresses.find(address =>
                address.linkedCompanies.find(
                  company =>
                    company.companyIdentifier === role.organisationIdentifier
                )
              );
            return {
              ...role,
              address: linkedCompany ? linkedCompany.address : []
            };
          }
        ),
        registeredAddressCompanies: location.registeredAddressCompanies.map(
          role => {
            const linkedCompany =
              location.originalAddresses &&
              location.originalAddresses.find(address =>
                address.linkedCompanies.find(
                  company =>
                    company.companyIdentifier === role.organisationIdentifier
                )
              );
            return {
              ...role,
              address: linkedCompany ? linkedCompany.address : []
            };
          }
        )
      };
    });
  };

  const locations = formatLocationData();
  const dateRange = useMemo(() => {
    const result = { smallestDate: Infinity, largestDate: 0 };
    locations?.forEach(loc => {
      if (
        loc.earliestStartDate &&
        loc.earliestStartDate.year &&
        loc.earliestStartDate.year < result.smallestDate
      ) {
        result.smallestDate = loc.earliestStartDate.year;
      }

      if (
        loc.mostRecentEndDate &&
        loc.mostRecentEndDate.year &&
        loc.mostRecentEndDate.year > result.largestDate
      ) {
        result.largestDate = loc.mostRecentEndDate.year;
      }

      return result;
    });

    if (result.smallestDate === Infinity) {
      result.smallestDate = "";
    }

    if (!result.largestDate) {
      result.largestDate = "Present";
    }

    return result;
  }, [locations]);

  const [corresAddFilterApplied, setCorresAddFilterApplied] =
    usePrintableReportState("corres-filter-applied", false);
  const [regAddFilterApplied, setRegAddFilterApplied] = usePrintableReportState(
    "reg-filter-applied",
    false
  );
  const [selectedCities, setSelectedCities] = usePrintableReportState(
    "selected-cities",
    []
  );
  const [selectedLocation, setSelectedLocation] = useState({});
  const [isLocationResultsShown, setIsLocationResultsShown] =
    usePrintableReportState(
      "locations-expanded",
      false,
      PRINTABLE_STATE_TYPES.sectionExpand
    );
  const [isResultsExpanded, setIsResultsExpanded] = usePrintableReportState(
    "locations-expanded-fully",
    false,
    PRINTABLE_STATE_TYPES.sectionExpand
  );

  const activeFilter =
    corresAddFilterApplied || regAddFilterApplied || selectedCities.length > 0;

  const getFilteredLocationsByAddress = useCallback(() => {
    let filteredLocations = locations;
    if (corresAddFilterApplied) {
      filteredLocations = filteredLocations.filter(
        l =>
          l.correspondenceAddressRoles &&
          l.correspondenceAddressRoles.length > 0
      );
    }
    if (regAddFilterApplied) {
      filteredLocations = filteredLocations.filter(
        l =>
          l.registeredAddressCompanies &&
          l.registeredAddressCompanies.length > 0
      );
    }
    return filteredLocations;
  }, [corresAddFilterApplied, locations, regAddFilterApplied]);

  const getFilteredLocations = useCallback(() => {
    let filteredLocations = getFilteredLocationsByAddress();

    if (selectedCities.length > 0) {
      filteredLocations = filteredLocations.filter(
        l =>
          l.filterInfo !== null &&
          selectedCities.includes(
            generateUniqueCityString(
              l.filterInfo.countryName,
              l.filterInfo.city
            )
          )
      );
    }
    return filteredLocations;
  }, [getFilteredLocationsByAddress, selectedCities]);

  /**
   * For PDF export purposes - Update the filteredLocations variable on the initial load and on any subsequent
   * changes to the filtering options. We want the data to be reflective of the options selected, so it then displays
   * correctly when the report is exported to a PDF.
   */
  const filteredLocations = useMemo(() => {
    if (activeFilter && !isLocationResultsShown) {
      setIsLocationResultsShown(true);
    }

    return getFilteredLocations();
  }, [
    activeFilter,
    getFilteredLocations,
    isLocationResultsShown,
    setIsLocationResultsShown
  ]);

  useEffect(() => {
    const element = document.querySelector(
      `div[data-index="${selectedLocation.id}"]`
    );

    // Style temporarily before transitioning out
    if (element) {
      element.style.backgroundColor = "rgb(217, 234, 241)";

      setTimeout(() => {
        element.style.transition = "all 1s";
        element.style.backgroundColor = grey.panel;
      }, 3000);
    }

    resultsSectionRef?.current?.scrollTo({
      left: 0,
      top: (element?.offsetTop ?? 0) - (element?.parentNode.offsetTop ?? 0),
      behavior: "smooth"
    });
  }, [selectedLocation]);

  const onToggleExpandResultsSection = () => {
    setIsResultsExpanded(prevState => !prevState);
    if (isResultsExpanded) {
      // Then we must be collapsing the results so ensure
      // the results section remains in view.

      const rect = resultsSectionRef.current.getBoundingClientRect();

      // If results section's top is now hidden i.e. above the viewport then:
      if (rect.top <= 0) {
        // Bring the section into view
        locationsRef.current.scrollIntoView();
      }
    }
  };

  const renderLocationsCount = () => {
    const filteredLength = filteredLocations.length;
    const originalLength = locationData.length;
    if (filteredLength !== originalLength) {
      return (
        <>
          <S.FilteredSectionCount>
            {`Showing ${filteredLength} of ${originalLength} locations`}
          </S.FilteredSectionCount>
          <S.SectionCountAdditional>{`${
            originalLength - filteredLength
          } hidden by active filters`}</S.SectionCountAdditional>
        </>
      );
    }
    return locationData.length;
  };

  const renderHeading = () => (
    <S.Heading>
      <div>
        <S.Dates>
          {dateRange.smallestDate}
          <span>{dateRange.smallestDate && " - "}</span>
          <span>{dateRange.largestDate}</span>
        </S.Dates>
      </div>
      <S.SectionTotal>{renderLocationsCount()}</S.SectionTotal>
    </S.Heading>
  );

  const resetAll = () => {
    setCorresAddFilterApplied(false);
    setRegAddFilterApplied(false);
    setSelectedCities([]);
  };

  const renderTags = () => {
    return (
      <S.ResetFiltersButton onClick={resetAll} disabled={!activeFilter}>
        {activeFilter ? "Reset filters" : "No active filters"}
      </S.ResetFiltersButton>
    );
  };

  const isSelected = id => filteredLocations.some(l => l.locationId === id);

  const renderFilterButtons = () => {
    const corresCount = locations.filter(
      location =>
        isSelected(location.locationId) &&
        location.correspondenceAddressRoles &&
        location.correspondenceAddressRoles.length > 0
    ).length;

    const regCount = locations.filter(
      location =>
        isSelected(location.locationId) &&
        location.registeredAddressCompanies &&
        location.registeredAddressCompanies.length > 0
    ).length;

    return (
      <S.FilterButtonsContainer>
        <FilterButton
          index={1}
          disabled={corresCount === 0}
          onClick={() => {
            if (corresCount > 0) {
              setRegAddFilterApplied(false);
              setCorresAddFilterApplied(!corresAddFilterApplied);
            }
          }}
          active={corresAddFilterApplied}
        >
          Director correspondence address
        </FilterButton>
        <FilterButton
          index={2}
          disabled={regCount === 0}
          onClick={() => {
            if (regCount > 0) {
              setCorresAddFilterApplied(false);
              setRegAddFilterApplied(!regAddFilterApplied);
            }
          }}
          active={regAddFilterApplied}
        >
          Company registered address
        </FilterButton>
      </S.FilterButtonsContainer>
    );
  };

  const scrollToLocation = img => {
    if (!isLocationResultsShown) {
      setIsLocationResultsShown(true);
    }
    setSelectedLocation(img);
  };

  const locationsString =
    filteredLocations?.length === 1 ? "location" : "locations";

  const renderLocationTiles = () => (
    <Masonry
      breakpointCols={2}
      className={classNameOverrides.masonryGrid}
      columnClassName={classNameOverrides.masonryColumn}
    >
      {filteredLocations.map(location => (
        <FullLocationCard
          key={`${location.addressFullDisplay.join()} ${location.longitude} ${
            location.latitude
          }`}
          date={location.mostRecentStartDate}
          image={location.locationStreetViewImage}
          address={location.addressFullDisplay}
          originalAddresses={location.originalAddresses}
          correspondenceAddressRoles={location.correspondenceAddressRoles}
          registeredAddressCompanies={location.registeredAddressCompanies}
          riskFlags={location.countryRiskFlags}
        />
      ))}
    </Masonry>
  );

  return (
    <div ref={locationsRef} className="locations-wrapper">
      <S.LocationsTopSectionContainer
        isLocationResultsShown={isLocationResultsShown}
      >
        {renderHeading()}
        <S.LocationsSectionContainer>
          <MapboxComponent
            locations={locations}
            filteredLocations={filteredLocations}
            showClusters={false}
            onMarkerClick={id => scrollToLocation({ id: id[0] })}
            activeFilter={activeFilter}
          />
          <S.FiltersSection>
            {renderTags()}
            {renderFilterButtons()}
            <S.Rule />
            <LocationBar
              locations={locations}
              selectedCities={selectedCities}
              setSelectedCities={setSelectedCities}
              activeFilter={activeFilter}
              filteredLocations={getFilteredLocationsByAddress()}
            />
          </S.FiltersSection>
        </S.LocationsSectionContainer>
        <S.CustomShowResultsButton
          resultsCount={filteredLocations.length + 1}
          customButtonLabel={`${isLocationResultsShown ? "Hide" : "Show"} ${
            filteredLocations.length
          } ${locationsString}`}
          onShowResultsClick={newVal => setIsLocationResultsShown(newVal)}
          resultsString={locationsString}
          isShowingResults={isLocationResultsShown}
        />
      </S.LocationsTopSectionContainer>
      <S.ResultsSection
        isResultsShown={isLocationResultsShown}
        isResultsExpanded={isResultsExpanded}
      >
        <S.MasonrySection
          isResultsShown={isLocationResultsShown}
          isResultsExpanded={isResultsExpanded}
          ref={resultsSectionRef}
        >
          {isLocationResultsShown && renderLocationTiles()}
          {(!filteredLocations || filteredLocations.length === 0) && (
            <S.NoResults>No results</S.NoResults>
          )}
        </S.MasonrySection>
      </S.ResultsSection>
      <S.CustomStickyExpandButton
        isReportRegenerationOpen={isReportRegenerationOpen}
        isResultsExpanded={isResultsExpanded}
        onToggleExpandResultsSection={onToggleExpandResultsSection}
        resultsSectionRef={resultsSectionRef}
        shouldShowButtonCallback={setIsShowingExpandButton}
      />
      {!isLocationResultsShown || !isShowingExpandButton ? (
        <SectionFooter />
      ) : null}
    </div>
  );
};

export default Locations;
