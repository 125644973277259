import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import { grey, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

import StickyExpandButton from "pages/report/StickyExpandButton";

const FlowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const SectionDescription = styled.p`
  display: flex;
  color: ${grey.hover};
  margin: 0;
  padding: 10px 16px;
  font-size: ${fontSize.sm};
`;

const ButtonContainer = styled.span`
  pointer-events: none;
  margin: 0 5px;
`;

const ResultsSection = styled.div`
  max-height: ${props => {
    if (props.isResultsExpanded) {
      return "inherit";
    }

    return "395px";
  }};
  overflow-y: auto;
`;

const SectionBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  width: 100%;
  background-color: ${grey.panel};
`;

const SectionBannerInfo = styled.p`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  margin-bottom: 0;
  margin-left: 15px;
`;

const CustomStickyExpandButton = styled(StickyExpandButton)`
  padding: 10px 16px;
  border-top: 2px solid ${standardColors.white};
`;

const SectionCount = styled.div`
  text-align: end;
  margin-right: 16px;
  color: ${grey.dark};
  font-size: ${fontSize["2xl"]};
`;

const InnerSectionBannerContent = styled.div`
  justify-content: space-between;
  width: 100%;
  display: flex;
  align-items: center;
`;

/** Classname overrides */
const masonryGrid = css`
  display: flex;
  margin-left: -8px; /* gutter size offset */
  width: auto;
  margin-top: 3px;
`;

const masonryColumn = css`
  background-clip: padding-box;
  padding-left: 9px;

  & > div {
    margin-bottom: 10px;
  }

  & > div:last-child {
    margin-bottom: 0px;
  }
`;

const S = {
  SectionDescription,
  FlowContainer,
  ButtonContainer,
  ResultsSection,
  SectionBanner,
  SectionBannerInfo,
  CustomStickyExpandButton,
  SectionCount,
  InnerSectionBannerContent
};

export const classNameOverrides = {
  masonryGrid,
  masonryColumn
};

export default S;
