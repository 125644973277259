import React from "react";
import { MotionProps } from "framer-motion/dist/framer-motion";

import { ButtonKind } from "components/atoms/Button/types";

import S from "./styles";

interface Props {
  animationProps: MotionProps;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  label: string;
}

const BackButton = ({ animationProps, onClick = () => {}, label }: Props) => {
  return (
    <S.BackButtonLabelContainer {...animationProps}>
      <S.BackButton kind={ButtonKind.tertiary} onClick={onClick}>
        <S.BackButtonIconContainer>
          <S.BackButtonChevron />
        </S.BackButtonIconContainer>
      </S.BackButton>
      <S.BackButtonLabel>{label}</S.BackButtonLabel>
    </S.BackButtonLabelContainer>
  );
};

export default BackButton;
