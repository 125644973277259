import React, { FC } from "react";

import type { InsightReport } from "api/insight-reports";

import S from "./styles";

interface Props {
  sections?: InsightReport["sections"];
  activeSectionSlug: string;
  onNext: () => void;
  onPrevious: () => void;
}

const SectionNavigation: FC<Props> = ({
  sections,
  activeSectionSlug,
  onNext,
  onPrevious
}) => {
  const isPreviousDisabled =
    sections === undefined ||
    sections.length === 0 ||
    activeSectionSlug === sections[0].slug;
  const isNextDisabled =
    sections === undefined ||
    sections.length === 0 ||
    activeSectionSlug === sections.slice(-1)[0].slug;

  return (
    <S.SectionNavigationContainer>
      <button type="button" onClick={onPrevious} disabled={isPreviousDisabled}>
        <S.ChevronLeft />
      </button>
      <button type="button" onClick={onNext} disabled={isNextDisabled}>
        <S.ChevronRight />
      </button>
    </S.SectionNavigationContainer>
  );
};

export default SectionNavigation;
