import { styled } from "@linaria/react";

import Button from "components/atoms/Button";
import MediaSourceCardComponent from "components/molecules/MediaSourceCard";

import { grey, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const AdditionalLabel = styled.div`
  font-size: ${fontSize["2xs"]};
  color: ${props => (props.isInspectorOpen ? grey.dark : grey.mid)};
  opacity: ${props => (props.isOnDisplay ? 1 : 0)};
  transition: color 0.2s, opacity 0.1s;
`;

const graphicContainerStyles = {
  "text-align": "center",
  "padding-top": "4px",
  width: "108px"
};

const GraphicContainerWithEntries = styled.div`
  ${graphicContainerStyles}

  color: ${({ color }) => color ?? grey.dark};

  &:hover {
    ${AdditionalLabel} {
      color: ${grey.dark};
    }
  }
`;

const GraphicContainerWithoutEntries = styled.div`
  ${graphicContainerStyles}

  color: ${({ color }) => color ?? grey.mid};

  &:hover {
    ${AdditionalLabel} {
      color: ${grey.mid};
    }
  }
`;

const GraphicContainer = ({
  ariaLabel,
  color,
  isConfirmedEntriesPresent,
  children
}) =>
  isConfirmedEntriesPresent ? (
    <GraphicContainerWithEntries aria-label={ariaLabel} color={color}>
      {children}
    </GraphicContainerWithEntries>
  ) : (
    <GraphicContainerWithoutEntries aria-label={ariaLabel} color={color}>
      {children}
    </GraphicContainerWithoutEntries>
  );

const GraphicHeader = styled.h1`
  margin: 0;
  font-size: ${fontSize.xl};
`;

const GraphicSubtext = styled.div`
  font-size: ${fontSize.sm};
  margin: 0;
  margin-top: 2px;
`;

const SourceContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

const SectionLinkButton = styled(Button)`
  border-bottom: 1px solid ${grey.mid} !important;
  color: ${grey.mid} !important;
  font-size: ${fontSize.sm} !important;
  line-height: 13px;
`;

const SectionLinkText = styled.span`
  white-space: nowrap;
`;

const MediaSourceCard = styled(MediaSourceCardComponent)`
  width: 100%;
  background: ${standardColors.white};
  border-radius: 8px;
  padding: 16px;
  color: ${grey.dark};

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const S = {
  GraphicContainer,
  GraphicHeader,
  GraphicSubtext,
  SourceContainer,
  SectionLinkButton,
  AdditionalLabel,
  SectionLinkText,
  MediaSourceCard
};

export default S;
