import {
  Idam_Contracts_Enums_UsagePeriod,
  IdentityTenantApiService,
  Idam_Contracts_Tenants_UpdateOrganisationRequest,
  Idam_Contracts_Tenants_UpdateOrganisationFeaturesRequest
} from "api/portal";
import { FetchResult } from "api/types";
import { getErrorMessage } from "api/util";

import {
  AssumeRoleResponse,
  Group,
  GroupReportRole,
  Organisation,
  User,
  OrganisationDetails,
  OrganisationUsage,
  OrganisationFeatures
} from "./types";

const mockGetOrganisations = async () => {
  return new Promise<Organisation[]>(resolve => {
    setTimeout(() => {
      resolve([
        {
          organisationId: "1",
          name: "Organisation 1",
          description: "Organisation number one"
        },
        {
          organisationId: "2",
          name: "Organisation 2",
          description: "Organisation number two"
        },
        {
          organisationId: "3",
          name: "Organisation 3",
          description: "Organisation number three"
        }
      ]);
    }, 300);
  });
};

const mockGetUserFromOrganisation = async (orgId: string) => {
  return new Promise<
    {
      userId: string;
      firstName: string;
      lastName: string;
      email: string;
      jobTitle: string;
      organisationId: string;
    }[]
  >(resolve => {
    setTimeout(() => {
      if (orgId === "1") {
        resolve([
          {
            userId: "1",
            firstName: "UserOne",
            lastName: "Org 1",
            jobTitle: "Good job",
            email: "userone@gmail.com",
            organisationId: "1"
          },
          {
            userId: "2",
            firstName: "UserTwo",
            lastName: "Org 1",
            jobTitle: "Good job",
            email: "usertwo@gmail.com",
            organisationId: "1"
          },
          {
            userId: "3",
            firstName: "UserThree",
            lastName: "Org 1",
            jobTitle: "Good job",
            email: "userthree@gmail.com",
            organisationId: "1"
          }
        ]);
      } else if (orgId === "2") {
        resolve([
          {
            userId: "1",
            firstName: "UserOne",
            lastName: "Org 2",
            jobTitle: "Good job",
            email: "userone@gmail.com",
            organisationId: "2"
          },
          {
            userId: "2",
            firstName: "UserTwo",
            lastName: "Org 2",
            jobTitle: "Good job",
            email: "usertwo@gmail.com",
            organisationId: "2"
          },
          {
            userId: "3",
            firstName: "UserThree",
            lastName: "Org 2",
            jobTitle: "Good job",
            email: "userthree@gmail.com",
            organisationId: "2"
          }
        ]);
      }
    }, 1000);
  });
};

const mockGetGroupsFromOrganisation = async (orgId: string) => {
  return new Promise<
    {
      groupId: string;
      name: string;
      description: string;
      users: Object[];
    }[]
  >(resolve => {
    setTimeout(() => {
      if (orgId === "1") {
        resolve([
          {
            groupId: "123",
            name: "Group 1",
            description: "Org 1 group",
            users: [{}, {}]
          },
          {
            groupId: "456",
            name: "Group 2",
            description: "Org 1 group",
            users: [{}]
          },
          {
            groupId: "789",
            name: "Group 3",
            description: "Org 1 group",
            users: []
          }
        ]);
      } else if (orgId === "2") {
        resolve([
          {
            groupId: "123",
            name: "Group 1",
            description: "Org 2 group",
            users: [{}, {}]
          },
          {
            groupId: "456",
            name: "Group 2",
            description: "Org 2 group",
            users: [{}]
          },
          {
            groupId: "789",
            name: "Group 3",
            description: "Org 2 group",
            users: []
          }
        ]);
      }
    }, 2000);
  });
};

const mockSendCopyOfReport = ({
  organisationId,
  userId,
  groupId,
  newGroupAccessPermission,
  notifyOwner
}: {
  organisationId: string;
  userId: string;
  groupId?: string;
  newGroupAccessPermission?: GroupReportRole;
  notifyOwner?: boolean;
}) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        organisationId,
        userId,
        groupId,
        notifyOwner,
        newGroupAccessPermission
      });
    }, 500);
  });
};

export default class Tenant {
  async createOrganisation(
    name: string,
    description?: string,
    users?: string[],
    admins?: string[]
  ): Promise<FetchResult> {
    try {
      // TODO: Backend to modify this endpoint to accept users and admins
      const requestBody = {
        name,
        description,
        users,
        admins
      };

      await IdentityTenantApiService.postTenantsOrganisations({ requestBody });

      return { status: true };
    } catch (e) {
      console.error(e);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async getOrganisations(): Promise<FetchResult<Organisation[]>> {
    try {
      const response = await mockGetOrganisations();

      return {
        status: true,
        response: response.map(org => ({
          organisationId: org.organisationId,
          name: org.name,
          description: org.description
        }))
      };
    } catch (e) {
      console.error(e);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async getUsersFromOrganisation(orgId: string): Promise<FetchResult<User[]>> {
    try {
      const response = await mockGetUserFromOrganisation(orgId);

      return {
        status: true,
        response: response.map(user => ({
          userId: user.userId,
          name: `${user.firstName} ${user.lastName ? user.lastName : ""}`,
          email: user.email,
          jobTitle: user.jobTitle,
          organisationId: user.organisationId
        }))
      };
    } catch (e) {
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async getGroupsFromOrganisation(
    orgId: string
  ): Promise<FetchResult<Group[]>> {
    try {
      const response = await mockGetGroupsFromOrganisation(orgId);

      return {
        status: true,
        response: response.map(group => ({
          groupId: group.groupId,
          title: group.name,
          description: group.description
        }))
      };
    } catch (e) {
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async sendCopyOfReport({
    orgId,
    newOwnerId,
    newGroupId,
    newGroupAccessPermission,
    notifyOwner
  }: {
    orgId: string;
    newOwnerId: string;
    newGroupId?: string;
    newGroupAccessPermission?: GroupReportRole;
    notifyOwner?: boolean;
  }): Promise<FetchResult> {
    try {
      await mockSendCopyOfReport({
        organisationId: orgId,
        userId: newOwnerId,
        groupId: newGroupId,
        newGroupAccessPermission,
        notifyOwner
      });

      return { status: true };
    } catch (e) {
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async getOrganisationUsage(
    organisationId: string
  ): Promise<FetchResult<OrganisationUsage>> {
    try {
      const response =
        await IdentityTenantApiService.getTenantsOrganisationsUsage({
          organisationId
        });

      return {
        status: true,
        response: {
          reportsRunPast30Days: response.reportsRunPast30Days ?? 0,
          reportsRunPast7Days: response.reportsRunPast7Days ?? 0,
          reportsRunPastYear: response.reportsRunPastYear ?? 0,
          reportsRunSinceOrgCreated:
            response.reportsRunSinceOrganisationCreation ?? 0
        }
      };
    } catch (e) {
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async downloadOrganisationUsage(orgId: string): Promise<FetchResult<string>> {
    try {
      const requestBody = {
        organisationId: orgId,
        usagePeriod:
          Idam_Contracts_Enums_UsagePeriod.REPORTS_RUN_SINCE_ORGANISATION_CREATION
      };
      const response =
        await IdentityTenantApiService.getTenantsOrganisationsUsageExport(
          requestBody
        );

      if (response && response.url) {
        return { status: true, response: response.url };
      }

      return { status: false };
    } catch (e) {
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async getOrganisationDetails(
    organisationId: string
  ): Promise<FetchResult<OrganisationDetails>> {
    try {
      const response = await IdentityTenantApiService.getTenantsOrganisations1({
        organisationId
      });

      return {
        status: true,
        response: {
          organisationId: response.organisationId!,
          name: response.name!,
          description: response.description ?? "",
          admins: response.admins ?? 0,
          users: response.users ?? 0,
          groups: response.groups ?? 0,
          enabled: response.state === "Enabled",
          features: {
            xapienInsights: response.features?.xapienInsights ?? false,
            adverseMediaMonitoring:
              response.features?.adverseMediaMonitoring ?? false,
            sanctionMonitoring: response.features?.sanctionMonitoring ?? false,
            mfa: response.features?.mfa ?? false
          }
        }
      };
    } catch (e) {
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async updateOrganisationDetails(
    orgId: string,
    name: string,
    description: string
  ): Promise<FetchResult> {
    try {
      const requestBody: Idam_Contracts_Tenants_UpdateOrganisationRequest = {
        name,
        description
      };

      await IdentityTenantApiService.putTenantsOrganisations({
        organisationId: orgId,
        requestBody
      });

      return { status: true };
    } catch (e) {
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async updateOrganisationFeatures(
    orgId: string,
    features: OrganisationFeatures
  ): Promise<FetchResult> {
    try {
      const requestBody: Idam_Contracts_Tenants_UpdateOrganisationFeaturesRequest =
        features;

      await IdentityTenantApiService.putTenantsOrganisationsFeatures({
        organisationId: orgId,
        requestBody
      });

      return { status: true };
    } catch (e) {
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async assumeRole(orgId: string): Promise<FetchResult<AssumeRoleResponse>> {
    try {
      const response =
        await IdentityTenantApiService.postTenantsOrganisationsAssumeRole({
          organisationId: orgId
        });

      return {
        status: true,
        response: { token: response.idToken!, expires: response.expires! }
      };
    } catch (e) {
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async enableOrganisation(organisationId: string): Promise<FetchResult> {
    try {
      await IdentityTenantApiService.postTenantsOrganisationsEnable({
        organisationId
      });

      return {
        status: true
      };
    } catch (e) {
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async disableOrganisation(organisationId: string): Promise<FetchResult> {
    try {
      await IdentityTenantApiService.postTenantsOrganisationsDisable({
        organisationId
      });

      return {
        status: true
      };
    } catch (e) {
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }
}
