import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import { ReactComponent as RegenerateReportIcon } from "img/icons/menu/reportPage/icon_regenerate_report.svg";

import InfoIconComponent from "components/atoms/InfoIcon";
import { grey, green, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";
import { hexToRgb } from "styles/hexToRgb";
import ButtonNew from "components/atoms/ButtonNew";

import theme from "theme";

const TooltipContent = styled.div`
  text-align: left;
  overflow-y: auto;
  font-size: ${fontSize.sm};
`;

const TooltipHeader = styled.div`
  border-bottom: 1px solid ${grey.rule};
  padding-bottom: 6px;
`;

const TooltipBody = styled.div`
  padding: 8px 0;
`;

const SearchDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InfoIcon = styled(InfoIconComponent)`
  margin-left: 2px;
  display: inline;

  & > div {
    display: inline;
  }
`;

const OldReportPrompt = styled.div`
  background-color: ${props => {
    const { r, g, b } = hexToRgb(props.backgroundColor ?? green.xapienGreen);
    return `rgba(${r}, ${g}, ${b}, 0.10)`;
  }};
  width: 100%;
  min-height: 92px;
  border-radius: 12px;
  padding: 16px;
  margin: 10px 0;
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  display: flex;
  align-items: center;
  gap: 20px;
`;

const PromptText = styled.div`
  max-width: 790px;
`;

const RegenIcon = styled(RegenerateReportIcon)`
  height: 48px;
  min-width: 42px;
  margin-left: 4px;

  path {
    fill: ${props => props.fill ?? green.xapienGreen};
  }
`;

const Link = styled.a`
  color: ${props => props.color ?? green.dark} !important;
  text-decoration: underline !important;
`;

const ReportDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ReportDetails = styled.div`
  color: ${grey.mid};
  font-size: ${fontSize.sm};
  padding-left: 13px;

  span + span {
    border-left: 1px solid ${grey.rule};
    padding-left: 0.625rem;
    margin-left: 0.625rem;
  }
`;

const AccessButton = styled(ButtonNew)`
  background-color: ${theme.primaryColor};
  color: ${standardColors.white};
`;

/* Classname Overrides */
const dataField = css`
  dt {
    margin: 0;
  }

  dd {
    margin-bottom: 8px;
  }
`;

const infoIconTooltipContent = css`
  width: 280px !important;
`;

const S = {
  TooltipBody,
  TooltipContent,
  TooltipHeader,
  SearchDetails,
  InfoIcon,
  OldReportPrompt,
  PromptText,
  RegenIcon,
  Link,
  ReportDetailsContainer,
  ReportDetails,
  AccessButton
};

export const classNameOverrides = {
  dataField,
  infoIconTooltipContent
};

export default S;
