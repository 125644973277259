/**
 * Establish how much of the supplied element is visible in the viewport
 * in pixels.
 * @param {HTMLElement} element the element whose visibility will be calculated.
 * @returns number of pixels of the element that is visible
 */
export const getVisibleElementInPixels = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();
  const elementHeight = element.clientHeight;
  const windowHeight = window.innerHeight;
  const visiblePixelCount = Math.max(
    0,
    rect.top > 0
      ? Math.min(elementHeight, windowHeight - rect.top)
      : Math.min(rect.bottom, windowHeight)
  );

  return visiblePixelCount;
};
