import { styled } from "@linaria/react";
import { motion } from "framer-motion/dist/framer-motion";
import { Spinner as ReactSpinner } from "reactstrap";

import { standardColors, grey } from "styles/colors";
import Button from "components/atoms/Button";

import { fontSize } from "styles/fonts";

const borderColor = props =>
  props.viewerMode
    ? "transparent"
    : props.isEditModeActive
    ? grey.mid42
    : grey.rule;

const EditorContainer = styled.div`
  .remirror-editor-wrapper {
    padding: 0;
    max-height: 500px;
    overflow: auto;
    border-width: 1px;
    border-style: solid;
    border-color: ${borderColor};
    border-radius: 4px;
    color: ${grey.dark};

    &:hover {
      background-color: ${props =>
        props.isEditModeActive || props.disabled
          ? "transparent"
          : "rgba(0, 0, 0, 0.1)"};
    }

    a {
      color: #7963d2;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    transition: background-color 0.3s ease 0s;
  }

  .ProseMirror {
    border: none;
    box-shadow: none;

    &:focus,
    &:active {
      box-shadow: ${props => (props.disabled ? "none" : "inherit")};
    }
  }
`;

const Toolbar = styled.div`
  padding-bottom: 10px;
`;

const ToolbarButton = styled.button`
  outline: none;
  border-radius: 6px;
  background-color: ${props =>
    props.isActive ? "rgba(0, 0, 0, 0.1)" : "transparent"};
  border: none;
  transition: background-color 0.2s;
  margin-right: 2px;

  &:hover,
  &:focus,
  &:active {
    background-color: rgba(0, 0, 0, 0.1);
    outline: none;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 10px;
`;

const SaveButton = styled(Button)`
  color: ${standardColors.white} !important;
  min-height: 0 !important;
  height: 28px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CancelButton = styled(Button)`
  color: ${props => props.color} !important;
  min-height: 0 !important;
  height: 28px;
`;

const ColorPicker = styled.div`
  display: flex;
  padding: 8px;
  flex-wrap: wrap;
  gap: 8px;
  width: 120px;
`;

const ColorOption = styled.button`
  background-color: ${props => props.color};
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: none;
  outline: none;
`;

const UnsavedChanges = styled.div`
  z-index: 100;
  color: ${grey.mid};
  font-size: ${fontSize.sm};
  margin-left: 8px;
`;

const Spinner = styled(ReactSpinner)`
  width: 21px;
  height: 21px;
  border-width: 2px;
`;

const ActionButtons = styled(motion.div)`
  display: flex;
`;

const S = {
  EditorContainer,
  Toolbar,
  ToolbarButton,
  Actions,
  SaveButton,
  CancelButton,
  ColorPicker,
  ColorOption,
  UnsavedChanges,
  Spinner,
  ActionButtons
};

export default S;
