import { ENTITY_TYPE } from "pages/define/utils";

export enum SubjectType {
  Person = "Person",
  Organisation = "Organisation"
}

export enum PersonContextType {
  Person = "person",
  Organisation = "organisation",
  SocialProfile = "socialProfile",
  Location = "location",
  Keywords = "keywords",
  Aliases = "aliases",
  Email = "email",
  WebAddress = "webAddress",
  DateOfBirth = "dateOfBirth"
}

export enum OrganisationContextType {
  WebAddress = "webAddress",
  SocialProfile = "socialProfile",
  Person = "person",
  FreeText = "freeText",
  Location = "location",
  Organisation = "organisation",
  Keywords = "keywords",
  Aliases = "aliases",
  Email = "email"
}

export type ContextType = PersonContextType | OrganisationContextType;

export interface ContextConfig {
  id: string;
  name: string;
  isEnabled: boolean;
  hintText?: string;
  isAvailableInAdvancedMode: boolean;
  type: ContextType;
}

export interface Context {
  type: ContextType;
  value?: string | null;
  id?: string;
}

interface Subject {
  type: SubjectType;
  value: string;
}

export interface EnquiryRequest {
  activeFeatures: Record<string, boolean>;
  subject: Subject;
  contexts: Context[];
  projectReference?: string;
}

export type EnquiryResponse = {
  enquiryId: string;
};

export type EntityType = typeof ENTITY_TYPE[keyof typeof ENTITY_TYPE];
