import { styled } from "@linaria/react";

import { ReactComponent as MenuButtonIconSvg } from "img/icons/menu-trigger-dots.svg";
import Button from "components/atoms/Button";

import { fontSize } from "styles/fonts";
import { grey, blue, purple } from "styles/colors";

const ConfidenceType = styled.span`
  color: ${grey.dark};
  text-transform: capitalize;
`;

const ConfidenceLabel = styled.label`
  text-transform: capitalize;
  color: ${grey.dark};
  cursor: pointer;
`;

const ConfidenceRadioInputContainer = styled.div`
  display: flex;
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  cursor: pointer;
  & + & {
    margin-top: 3px;
  }
`;

const SelectionPrompt = styled.div`
  margin-bottom: 14px;
  color: ${grey.mid};
  font-size: ${fontSize.sm};
`;

const DropdownToggle = styled(Button)`
  min-width: 0 !important;
  min-height: 0 !important;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
  background-color: ${({ className }) => {
    if (className === "hasChangedFromOriginalConfidence") {
      return purple.mid;
    }
    return "transparent";
  }} !important;
  transition: background-color 0.4s;
  box-shadow: none !important;

  &:hover,
  &:focus {
    background-color: ${({ className }) => {
      if (className === "hasChangedFromOriginalConfidence") {
        return purple.dark;
      }
      return grey.light;
    }} !important;

    & > svg > circle {
      fill: ${({ className }) => {
        if (className === "hasChangedFromOriginalConfidence") {
          return grey.panel;
        }
        return grey.dark;
      }};
    }
  }
`;

const TooltipContent = styled.div`
  padding: 14px;
  max-width: 410px;
  font-size: ${fontSize.sm};
`;

const FeatureInfo = styled.div`
  padding: 10px;
  font-size: ${fontSize.sm};
`;

const SupportLink = styled.a`
  color: ${blue.icon} !important;
  text-decoration: underline !important;
`;

const PromptText = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.dark};
`;

const PromptSubtext = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  margin-top: 5px;
`;

const UserSetInfoText = styled.span`
  text-transform: none;
`;

const MenuButtonIcon = styled(MenuButtonIconSvg)`
  height: 18px;

  & > circle {
    fill: ${({ className }) =>
      className === "hasChangedFromOriginalConfidence" ? grey.panel : grey.mid};
  }
`;

const TooltipExplainerText = styled.div`
  color: ${grey.mid};
  font-size: ${fontSize.sm};
`;

const PrimaryActionString = styled.div`
  &:first-letter {
    text-transform: capitalize;
  }
`;

const S = {
  ConfidenceType,
  ConfidenceRadioInputContainer,
  ConfidenceLabel,
  SelectionPrompt,
  DropdownToggle,
  TooltipContent,
  FeatureInfo,
  SupportLink,
  PromptText,
  PromptSubtext,
  UserSetInfoText,
  MenuButtonIcon,
  TooltipExplainerText,
  PrimaryActionString
};

export default S;
