import { styled } from "@linaria/react";
import ButtonNew from "components/atoms/ButtonNew";
import { motion } from "framer-motion/dist/framer-motion";
import { blue } from "styles/colors";

const RequesterEmail = styled.span`
  color: ${blue.xapienLight};
`;

const ModalActions = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const Button = styled(ButtonNew)`
  flex: 1;
`;

const AccessContainer = styled.div`
  border-radius: 0.75rem;
  padding: 1rem 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${blue.xapienDark};
`;

const TitleCardSkeleton = styled.div`
  border-radius: 0.75rem;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background-color: ${blue.xapienDark};
`;

const TitleCardContentSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const ModalInnerContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const S = {
  RequesterEmail,
  ModalActions,
  Button,
  AccessContainer,
  TitleCardSkeleton,
  TitleCardContentSkeleton,
  ModalInnerContent
};

export default S;
