import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import { ReactComponent as TagWithPointer } from "img/tag-with-pointer.svg";

import { ALIGNMENT_OPTIONS } from "components/organisms/RiskSummariesTimeline/utils";
import Button from "components/atoms/Button";
import { grey, green, red, standardColors } from "styles/colors";
import { fontSize, family } from "styles/fonts";
import { hexToRgb } from "styles/hexToRgb";

const EventContainer = styled.div`
  position: relative;
  text-align: ${props => props.alignment};
  display: flex;
  flex-direction: column;
  align-items: ${props =>
    props.alignment === ALIGNMENT_OPTIONS.left ? "flex-end" : "flex-start"};
  margin-right: ${props => {
    return props.alignment === ALIGNMENT_OPTIONS.right ? props.nodeSize / 2 : 0;
  }}px;
  margin-left: ${props =>
    props.alignment === ALIGNMENT_OPTIONS.left ? props.nodeSize / 2 : 0}px;
  text-align: ${props =>
    props.alignment === ALIGNMENT_OPTIONS.left
      ? ALIGNMENT_OPTIONS.right
      : ALIGNMENT_OPTIONS.left};
`;

const DateRange = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  padding-bottom: 6px;
`;

const EventTitle = styled.h2`
  font-size: ${fontSize.lg};
  margin-bottom: 6px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const EventSubtitle = styled.p`
  margin: 0;
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  padding-bottom: 6px;
`;

const EventContent = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  margin-bottom: 6px;

  /* Required for clamping. Right aligned text will otherwise sometimes
  truncate the ellipses */
  padding-right: ${props =>
    props.alignment === ALIGNMENT_OPTIONS.right ? 0 : 12}px;
  margin-right: ${props =>
    props.alignment === ALIGNMENT_OPTIONS.right ? 0 : -12}px;
`;

const DateTag = styled.div`
  margin: 0;
  padding: 3px 0;
  color: #fff;
  font-size: ${fontSize.sm};
  display: flex;
  align-items: center;
  justify-content: ${props =>
    props.alignment === ALIGNMENT_OPTIONS.left ? "flex-end" : "flex-start"};
`;

const DateTagInnerContainer = styled.div`
  position: relative;
`;

const DateString = styled.div`
  position: absolute;
  top: 2px;
  right: ${props => (props.alignment === ALIGNMENT_OPTIONS.right ? 12 : 15)}px;
`;

const DateTagIcon = styled(TagWithPointer)`
  height: 24px;
  width: 83px;
  transform: ${props =>
    props.alignment === ALIGNMENT_OPTIONS.right
      ? "rotate(180deg)"
      : "rotate(0)"};

  path {
    fill: ${({ color }) => color};
  }
`;

const EventNodeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${props =>
    props.alignment === ALIGNMENT_OPTIONS.left ? "row-reverse" : "row"};
`;

const EventNode = styled.div`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background-color: ${props => props.backgroundColor} !important;
  border-radius: 50%;
  border: 1px solid ${props => props.borderColor ?? green.xapienGreen};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

const InnerEventNodeCircle = styled.div`
  height: ${props => Math.floor(props.size * 0.65)}px;
  width: ${props => Math.floor(props.size * 0.65)}px;
  background-color: ${props => props.color ?? green.xapienGreen};
  border-radius: 50%;
`;

const EventLine = styled.div`
  width: 30px;
  border-top: 2px dotted ${props => props.color ?? green.xapienGreen};
`;

const EventContentContainer = styled.div`
  display: flex;
  flex-direction: ${props =>
    props.alignment === ALIGNMENT_OPTIONS.left ? "row-reverse" : "row"};
`;

const PaddingDiv = styled.div`
  padding-left: ${props => props.nodeSize + 30 + 12}px;
`;

const ContentDiv = styled.div`
  padding: 5px 0;
`;

const NoTitleHeader = styled.span`
  font-style: italic;
  padding-right: 1px;
`;

const TimelineFragment = styled.div`
  position: absolute;
  left: calc(50% - 5px);
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Terminus = styled.div`
  height: 1px;
  width: 10px;
  background-color: ${grey.rule};
`;

const Line = styled.div`
  width: 1px;
  height: 150px;
  background-color: ${grey.rule};
`;

const EventNodeOuterContainer = styled.div`
  position: relative;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
`;

const RiskCategoryPill = styled(Button)`
  padding: 0px 8px;
  border: 1px solid ${red.directRisk} !important;
  border-radius: 16px;
  margin-right: 6px;
  font-size: ${fontSize.sm};
  margin-bottom: 6px;
  text-transform: capitalize;
  background-color: ${props =>
    props.isActive ? red.directRisk : "transparent"} !important;
  color: ${props =>
    props.isActive ? standardColors.white : red.directRisk} !important;
  min-height: 21px !important;
  box-shadow: none !important;

  &:hover {
    box-shadow: 0 0 0 4px rgba(239, 114, 115, 0.25) !important;
    cursor: pointer;
  }
`;

const RiskCountButton = styled(Button)`
  background-color: ${grey.faint} !important;
  font-size: ${fontSize.sm};
  color: ${grey.mid} !important;
  padding: 0 10px !important;
  min-height: 21px !important;
  border: 1px solid transparent !important;
  min-width: 10px !important;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px
      ${() => {
        const { r, g, b } = hexToRgb(grey.ghost);
        return `rgba(${r}, ${g}, ${b}, 0.3)`;
      }} !important;
  }
`;

const SourceItemsContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

const SourcesSubtitle = styled.span`
  font-family: ${family.interSemiBold};
`;

const riskPill = css`
  text-transform: capitalize;
`;

const S = {
  EventTitle,
  EventSubtitle,
  EventContainer,
  DateRange,
  EventContent,
  DateTagInnerContainer,
  DateString,
  DateTag,
  DateTagIcon,
  EventNodeContainer,
  EventNode,
  InnerEventNodeCircle,
  EventLine,
  EventContentContainer,
  PaddingDiv,
  ContentDiv,
  NoTitleHeader,
  TimelineFragment,
  Terminus,
  Line,
  EventNodeOuterContainer,
  RiskCategoryPill,
  RiskCountButton,
  SourceItemsContainer,
  SourcesSubtitle,
  riskPill
};

export const classNameOverrides = {
  riskPill
};

export default S;
