import React, {
  useMemo,
  useReducer,
  useCallback,
  useEffect,
  FC,
  ReactNode
} from "react";
import { Auth, Amplify } from "aws-amplify";
import { useNavigate, useSearchParams } from "react-router-dom";

import config from "config";

import { OpenAPI } from "api/portal";
import {
  initialState,
  hubAuthenticationReducer,
  HubAuthenticationContext
} from "./context";

import {
  HubAuthenticationStatus,
  HubAuthenticationAction,
  HubAuthenticationActions
} from "./types";

// aws-amplify lib doesn't give us this :(
interface AmplifyConfig {
  Auth: {
    region: string | undefined;
    userPoolId: string;
    userPoolWebClientId: string;
    oauth: {
      domain: string;
      scope: string[];
      redirectSignIn: string | undefined;
      redirectSignOut: string | undefined;
      responseType: string;
    };
  };
}

interface Props {
  amplifyConfig: AmplifyConfig;
  loginPath: string;
  children: ReactNode;
}

OpenAPI.TOKEN = async () => {
  try {
    const session = await Auth.currentSession();
    // const accessToken = res.getAccessToken();
    const idToken = session.getIdToken();
    const jwt = idToken.getJwtToken();
    return jwt;
  } catch (e) {
    console.error(e);

    return "";
  }
};

export const HubAuthenticationContextProvider: FC<Props> = ({
  children,
  loginPath,
  amplifyConfig
}) => {
  Amplify.configure(amplifyConfig);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [state, reducerDispatch] = useReducer(
    hubAuthenticationReducer,
    initialState
  );

  const dispatch = useCallback((action: HubAuthenticationAction) => {
    reducerDispatch(action);
  }, []);

  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  useEffect(() => {
    if (state.status === HubAuthenticationStatus.unknown) {
      const getHubAuthenticatedUser = async () =>
        Auth.currentAuthenticatedUser();

      getHubAuthenticatedUser()
        .then(session => {
          dispatch({ type: HubAuthenticationActions.authenticated, session });
        })
        .catch(() => {
          dispatch({ type: HubAuthenticationActions.unauthenticated });
        });
    }
  }, [state, dispatch, navigate, loginPath]);

  useEffect(() => {
    if (state.status === HubAuthenticationStatus.verifyingPassword) {
      // Try-catch is also needed here because Amplify will throw an error if
      // login is already pending. Otherwise, it will return a promise.
      try {
        Auth.signIn(
          `${state.email.toLowerCase()}+${config.tenantId}`,
          state.password
        )
          .then(session => {
            dispatch({
              type: HubAuthenticationActions.passwordVerified,
              session
            });
          })
          .catch(({ message: error }) => {
            dispatch({ type: HubAuthenticationActions.unauthenticated, error });
          });
      } catch (e) {
        console.error(e);
      }
    }
  }, [state, dispatch, navigate, searchParams, loginPath]);

  useEffect(() => {
    if (state.status === HubAuthenticationStatus.authenticating) {
      Auth.confirmSignIn(state.session, state.mfaCode, "SOFTWARE_TOKEN_MFA")
        .then(session => {
          dispatch({ type: HubAuthenticationActions.authenticated, session });
        })
        .catch(({ message: error }) => {
          dispatch({ type: HubAuthenticationActions.unauthenticated, error });
        });

      return;
    }

    if (state.status === HubAuthenticationStatus.mfaSetup) {
      Auth.verifyTotpToken(state.session, state.mfaCode)
        .then(async () => {
          await Auth.setPreferredMFA(state.session, "SOFTWARE_TOKEN_MFA");
          dispatch({
            type: HubAuthenticationActions.authenticated,
            session: state.session
          });
        })
        .catch(({ message: error }) => {
          dispatch({ type: HubAuthenticationActions.unauthenticated, error });
        });

      return;
    }

    if (
      state.status === HubAuthenticationStatus.authenticated &&
      window.location.pathname === loginPath
    ) {
      navigate("/hub/organisations");
    }

    if (
      state.status === HubAuthenticationStatus.unauthenticated &&
      window.location.pathname !== loginPath &&
      window.location.pathname !== "/hub/reset-password"
    ) {
      navigate(loginPath);
    }
  }, [state, dispatch, navigate, loginPath]);

  return (
    <HubAuthenticationContext.Provider value={providerValue}>
      {children}
    </HubAuthenticationContext.Provider>
  );
};
