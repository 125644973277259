import React, { useState } from "react";

import { motion } from "framer-motion/dist/framer-motion";

import { ReactComponent as Back } from "img/icons/renders/back-arrow.svg";
import { ReactComponent as Forward } from "img/icons/renders/forward-arrow.svg";
import { ImageSizeToUse } from "util/ImageSizeToUse";
import { usePrintableReportState } from "util/hooks/usePrintableState";
import { usePrintableScrollPosition } from "util/hooks/usePrintableScrollPosition";
import { formatDate, sortBy } from "util/common";
import TruncateLength from "util/TruncateLength";
import { ButtonKind } from "components/atoms/Button/types";
import SourceLink from "components/atoms/SourceLink";
import WithInspector from "components/organisms/WithInspector";
import Assertable from "util/Assertable";
import exactImageAssertionTemplate from "util/Assertable/util/exactImageAssertionTemplate";

import SingleImage from "./SingleImage";
import S, { classNameOverrides } from "./styles";

const ImageBank = ({ images }) => {
  const [activeFilter, setActiveFilter] = useState(1);
  const firstImage = images[0] || {};
  const firstImageElement = firstImage.image || firstImage;
  const [currentImageId, setCurrentImageId] = usePrintableReportState(
    "image-bank-current-image-id",
    firstImageElement.id
  );

  const ROW_HEIGHT = images?.length >= 5 ? 108 : 162;
  const COLUMN_WIDTH = images?.length >= 5 ? 144 : 216;

  const getImages = () => {
    const uniqueImages = images.filter((image, index, arr) => {
      return (
        index === arr.findIndex(element => image.image.id === element.image.id)
      );
    });
    switch (activeFilter) {
      case 1:
        return uniqueImages
          .map(a => ({ ...a, date: new Date(a.image.date) }))
          .sort((a, b) => b.date.getTime() - a.date.getTime());

      case 2:
        return sortBy(uniqueImages, i =>
          i.relevance === undefined ? 0 : i.relevance
        );
      case 3:
        return uniqueImages.sort((a, b) => a.profileScore - b.profileScore);
      default:
        return uniqueImages;
    }
  };

  const labelledFilteredImages = getImages();

  const currentImage = labelledFilteredImages.find(
    i => i.image.id === currentImageId
  );
  const setCurrentImage = image => setCurrentImageId(image.image.id);

  const previousImage = () => {
    const currentImageIndex = labelledFilteredImages.findIndex(
      image => image.image.id === currentImageId
    );
    setCurrentImage(labelledFilteredImages[currentImageIndex - 1]);
  };
  const nextImage = () => {
    const currentImageIndex = labelledFilteredImages.findIndex(
      image => image.image.id === currentImageId
    );
    setCurrentImage(labelledFilteredImages[currentImageIndex + 1]);
  };

  const gridScrollContainerRef = usePrintableScrollPosition(
    "image-bank-scroll-position"
  );

  const getGridStyle = () => {
    let styles;
    if (images?.length > 12) {
      styles = classNameOverrides.columnFirst;
    } else if (images?.length > 4) {
      styles = classNameOverrides.rowFirst;
    } else {
      styles = classNameOverrides.twoByTwo;
    }
    return styles;
  };

  const renderImages = () => (
    <S.GridContainer>
      <S.Grid className={getGridStyle()}>
        {labelledFilteredImages.map(image => (
          <motion.div
            key={image.image.id}
            style={{ width: `${COLUMN_WIDTH}px`, height: `${ROW_HEIGHT}px` }}
            onClick={() => setCurrentImage(image)}
            layout
            transition={{ type: "tween", ease: "easeInOut", duration: 1 }}
          >
            <S.GridImage
              alt="Related photograph"
              image={image.image}
              imageSizeToUse={ImageSizeToUse.Medium}
              lazyLoad={false}
              width="100%"
              height="100%"
            />
          </motion.div>
        ))}
      </S.Grid>
    </S.GridContainer>
  );

  const renderHeading = () => {
    let startYear = "";
    let endYear = "";
    let dateString = "";
    const currentYear = new Date().getFullYear();

    const imageYears = images
      .map(image => {
        return image?.image?.date
          ? new Date(image.image.date).getFullYear()
          : undefined;
      })
      .filter(Boolean);

    imageYears.sort();

    // eslint-disable-next-line prefer-destructuring
    startYear = imageYears[0];
    endYear = imageYears[imageYears.length - 1];

    if (startYear && endYear && startYear !== endYear) {
      dateString = `${startYear} – ${
        endYear === currentYear ? "Present" : endYear
      }`;
    } else if (startYear && endYear && startYear === endYear) {
      if (startYear === currentYear) {
        dateString = `Present`;
      } else {
        dateString = startYear;
      }
    }
    return (
      <S.HeaderContainer>
        <S.Header>{dateString}</S.Header>
        <S.SectionCount>{images?.length}</S.SectionCount>
      </S.HeaderContainer>
    );
  };

  const renderButtons = () => (
    <S.OrderButtonsContainer>
      <S.OrderByLabel>Order by:</S.OrderByLabel>
      <S.OrderButton
        kind={ButtonKind.secondary}
        onClick={() => setActiveFilter(1)}
        active={activeFilter === 1}
        index={1}
      >
        Date
      </S.OrderButton>
      <S.OrderButton
        kind={ButtonKind.secondary}
        onClick={() => {
          setActiveFilter(2);
        }}
        active={activeFilter === 2}
        index={2}
      >
        Relevance
      </S.OrderButton>
      <S.OrderButton
        kind={ButtonKind.secondary}
        onClick={() => {
          setActiveFilter(3);
        }}
        active={activeFilter === 3}
        index={3}
      >
        Group
      </S.OrderButton>
    </S.OrderButtonsContainer>
  );

  const renderNavigationButtons = () => {
    const currentImageIndex = labelledFilteredImages.findIndex(
      image => image.image.id === currentImageId
    );
    const isFirstImage = currentImageIndex === 0;
    const isLastImage = currentImageIndex === labelledFilteredImages.length - 1;

    return (
      <S.NavigationButtonsContainer>
        <S.NavigationButton onClick={isFirstImage ? null : previousImage}>
          <Back alt="Previous" className={isFirstImage ? "disabled" : ""} />
        </S.NavigationButton>
        <S.NavigationButton onClick={isLastImage ? null : nextImage}>
          <Forward alt="Next" className={isLastImage ? "disabled" : ""} />
        </S.NavigationButton>
        <div>{`${currentImageIndex + 1}/${labelledFilteredImages.length}`}</div>
      </S.NavigationButtonsContainer>
    );
  };

  const renderFullImage = () => (
    <S.FullImageCardContainer>
      {renderNavigationButtons()}
      <S.FullImageCard>
        {currentImage.image.originUrl ? (
          <Assertable
            template={exactImageAssertionTemplate(currentImage.image.src)}
          >
            <SourceLink href={currentImage.image.originUrl}>
              <S.FullImage
                alt="Related photograph"
                image={{
                  src: currentImage.src, // taking the src from here because that's where it was coming from before - joseph
                  ...currentImage.image,
                  ...currentImage
                }}
                lazyLoad={false}
                width="auto"
                height="220px"
              />
            </SourceLink>
          </Assertable>
        ) : (
          <S.FullImage
            alt="Related photograph"
            image={{
              src: currentImage.src, // taking the src from here because that's where it was coming from before - joseph
              ...currentImage.image,
              ...currentImage
            }}
            lazyLoad={false}
            width="auto"
            height="220px"
          />
        )}
        <S.ImageDocumentTitle>
          <TruncateLength>
            {currentImage.image.containingDocumentTitle}
          </TruncateLength>
        </S.ImageDocumentTitle>
        <S.ImageAltText>
          <TruncateLength>{currentImage.image.altText}</TruncateLength>
        </S.ImageAltText>
        <S.ImageDate>
          <TruncateLength>
            {currentImage.image.date && formatDate(currentImage.image.date)}
          </TruncateLength>
        </S.ImageDate>
        <S.ImageOriginUrl>
          <SourceLink href={currentImage.image.originUrl}>
            <TruncateLength>{currentImage.image.originUrl}</TruncateLength>
          </SourceLink>
        </S.ImageOriginUrl>
        {!currentImage.image.originUrl &&
        currentImage.image.thirdPartyContent ? (
          <S.ImageThirdParty>
            Image source: third party data provider
          </S.ImageThirdParty>
        ) : (
          false
        )}
        {currentImage.sources && currentImage.sources.length > 1 && (
          <S.ImageSources>
            <WithInspector
              showTopSection={false}
              sources={currentImage.sources}
            >
              {`${currentImage.sources.length} sources (see all)`}
            </WithInspector>
          </S.ImageSources>
        )}
      </S.FullImageCard>
    </S.FullImageCardContainer>
  );

  const renderGalleryView = () => (
    <S.ImageBankContainer>
      {renderHeading()}
      <S.GalleryViewContainer>
        <S.GalleryView>
          {renderButtons()}
          <S.GalleryImages ref={gridScrollContainerRef}>
            {labelledFilteredImages.length > 0 && renderImages()}
          </S.GalleryImages>
        </S.GalleryView>
        {renderFullImage()}
      </S.GalleryViewContainer>
    </S.ImageBankContainer>
  );

  const renderSingleImageView = () => (
    <S.ImageBankContainer>
      <SingleImage
        image={firstImageElement}
        sources={firstImage.sources || []}
      />
    </S.ImageBankContainer>
  );

  return images.length === 1 ? renderSingleImageView() : renderGalleryView();
};

export default React.memo(ImageBank);
