import React, { useEffect } from "react";
import {
    CollectionListActions,
    useCollectionList
} from "util/hooks/useCollectionList";
import CollectionList from "components/organisms/CollectionList";
import CollectionNavigation from "components/molecules/CollectionNavigation";

import { ReportCollectionId } from "api/reports/types";
import PersonaCard from "../PersonaCard";

type PersonaListProps = {
};

const PersonaList: React.FC<PersonaListProps> = ({
                                               }) => {
    const {
        state: { collections },
        dispatch
    } = useCollectionList();

    return (
        <CollectionList
            showTitle
            showTitleCount
            sortable
            CardComponent={PersonaCard}
            emptyCollectionText="No personas"
            toggles={[]}
            navigationComponent={
                <CollectionNavigation
                    items={[
                        {
                            onClick: () => console.log("okay"),
                            isActive: true
                        },
                    ]}
                />
            }
        />
    );
};

export default PersonaList;
