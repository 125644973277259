/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Report } from "../models/Report";
import type { SourceSubsectionRequest } from "../models/SourceSubsectionRequest";
import type { SubmitFeedbackRequest } from "../models/SubmitFeedbackRequest";
import type { SubmitQnaRequest } from "../models/SubmitQnaRequest";
import type { Subsection } from "../models/Subsection";
import type { UpdateElementRequest } from "../models/UpdateElementRequest";
import type { UpdateSectionRequest } from "../models/UpdateSectionRequest";
import type { UpdateSubsectionRequest } from "../models/UpdateSubsectionRequest";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ReportsService {
  /**
   * Get an Insights report
   * An endpoint to retrieve an Insights report that has been generate for the specified report.
   * @returns Report Success
   * @throws ApiError
   */
  public static getReports({
    reportId,
    personaId,
    runId
  }: {
    /**
     * ID of report
     */
    reportId: string;
    personaId: string;
    runId: string | null;
  }): CancelablePromise<Report> {
    if (runId) {
      return __request(OpenAPI, {
        method: "GET",
        url: "/reports/{reportId}/{personaId}?runId={runId}",
        path: {
          reportId: reportId,
          personaId: personaId,
          runId: runId
        },
        errors: {
          400: `Validation`,
          401: `Unauthorized`,
          403: `Forbidden`,
          404: `Report Not Found`,
          409: `Report Failed`,
          423: `Report In Progress`,
          500: `Internal Server Error`
        }
      });
    } else {
      return __request(OpenAPI, {
        method: "GET",
        url: "/reports/{reportId}/{personaId}",
        path: {
          reportId: reportId,
          personaId: personaId
        },
        errors: {
          400: `Validation`,
          401: `Unauthorized`,
          403: `Forbidden`,
          404: `Report Not Found`,
          409: `Report Failed`,
          423: `Report In Progress`,
          500: `Internal Server Error`
        }
      });
    }
  }

  /**
   * Delete an Insights report
   * An endpoint used to delete an Insights report.
   * @returns void
   * @throws ApiError
   */
  public static deleteReports({
    reportId,
    personaId
  }: {
    /**
     * ID of report
     */
    reportId: string;
    personaId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/reports/{reportId}/{personaId}",
      path: {
        reportId: reportId,
        personaId: personaId
      },
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Ask a question
   * An endpoint used to ask a question on a report.
   * @returns Subsection Success
   * @throws ApiError
   */
  public static postReportsQna({
    reportId,
    personaId,
    requestBody
  }: {
    /**
     * ID of report
     */
    reportId: string;
    personaId: string;
    requestBody?: SubmitQnaRequest;
  }): CancelablePromise<Subsection> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/{reportId}/{personaId}/qna",
      path: {
        reportId: reportId,
        personaId: personaId
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Update a section's title
   * An endpoint used to update the title of a report section.
   * @returns void
   * @throws ApiError
   */
  public static putReportsSections({
    reportId,
    personaId,
    sectionId,
    requestBody
  }: {
    reportId: string;
    personaId: string;
    sectionId: string;
    requestBody?: UpdateSectionRequest;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/reports/{reportId}/{personaId}/sections/{sectionId}",
      path: {
        reportId: reportId,
        personaId: personaId,
        sectionId: sectionId
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Delete a report section
   * An endpoint used to delete a section from a report
   * @returns void
   * @throws ApiError
   */
  public static deleteReportsSections({
    reportId,
      personaId,
    sectionId
  }: {
    reportId: string;
    personaId: string;
    sectionId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/reports/{reportId}/{personaId}/sections/{sectionId}",
      path: {
        reportId: reportId,
        personaId: personaId,
        sectionId: sectionId
      },
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Update a subsections's title
   * An endpoint used to update the title of a report subsection.
   * @returns void
   * @throws ApiError
   */
  public static putReportsSectionsSubsections({
    reportId,
    personaId,
    sectionId,
    subsectionId,
    requestBody
  }: {
    reportId: string;
    personaId: string;
    sectionId: string;
    subsectionId: string;
    requestBody?: UpdateSubsectionRequest;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/reports/{reportId}/{personaId}/sections/{sectionId}/subsections/{subsectionId}",
      path: {
        reportId: reportId,
        personaId: personaId,
        sectionId: sectionId,
        subsectionId: subsectionId
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Delete a report subsection
   * An endpoint used to delete a subsection from a report section
   * @returns void
   * @throws ApiError
   */
  public static deleteReportsSectionsSubsections({
    reportId,
    personaId,
    sectionId,
    subsectionId
  }: {
    reportId: string;
    personaId: string;
    sectionId: string;
    subsectionId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/reports/{reportId}/{personaId}/sections/{sectionId}/subsections/{subsectionId}",
      path: {
        reportId: reportId,
        personaId: personaId,
        sectionId: sectionId,
        subsectionId: subsectionId
      },
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Get sourcing for a subsection
   * An endpoint to generate and retrieve the sourcing for a given subsection.
   * @returns Subsection Success
   * @throws ApiError
   */
  public static postReportsSectionsSubsectionsSource({
    reportId,
    personaId,
    sectionId,
    subsectionId,
    requestBody
  }: {
    reportId: string;
    personaId: string;
    sectionId: string;
    subsectionId: string;
    requestBody?: SourceSubsectionRequest;
  }): CancelablePromise<Subsection> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/{reportId}/{personaId}/sections/{sectionId}/subsections/{subsectionId}/source",
      path: {
        reportId: reportId,
        personaId: personaId,
        sectionId: sectionId,
        subsectionId: subsectionId
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Update an element's title
   * An endpoint used to update the title of a report element.
   * @returns void
   * @throws ApiError
   */
  public static putReportsSectionsSubsectionsElements({
    reportId,
    personaId,
    sectionId,
    subsectionId,
    elementId,
    requestBody
  }: {
    reportId: string;
    personaId: string;
    sectionId: string;
    subsectionId: string;
    elementId: string;
    requestBody?: UpdateElementRequest;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/reports/{reportId}/{personaId}/sections/{sectionId}/subsections/{subsectionId}/elements/{elementId}",
      path: {
        reportId: reportId,
        personaId: personaId,
        sectionId: sectionId,
        subsectionId: subsectionId,
        elementId: elementId
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Remove an element
   * An endpoint used to remove an element from a report.
   * @returns void
   * @throws ApiError
   */
  public static deleteReportsSectionsSubsectionsElements({
    reportId,
    personaId,
    sectionId,
    subsectionId,
    elementId
  }: {
    reportId: string;
    personaId: string;
    sectionId: string;
    subsectionId: string;
    elementId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/reports/{reportId}/{personaId}/sections/{sectionId}/subsections/{subsectionId}/elements/{elementId}",
      path: {
        reportId: reportId,
        personaId: personaId,
        sectionId: sectionId,
        subsectionId: subsectionId,
        elementId: elementId
      },
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Submit feedback for a report
   * An endpoint used to submit feedback for an Insights report
   * @returns void
   * @throws ApiError
   */
  public static postReportsFeedback({
    reportId,
    personaId,
    requestBody
  }: {
    reportId: string;
    personaId: string;
    requestBody?: SubmitFeedbackRequest;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/{reportId}/{personaId}/feedback",
      path: {
        reportId: reportId,
        personaId: personaId
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Used to enqueue a report for regeneration.
   * An endpoint to enqueue a xapien insights report to be regenerated.
   * @returns void
   * @throws ApiError
   */
  public static postReportsRegenerate({
    reportId,
    personaId
  }: {
    reportId: string;
    personaId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/{reportId}/{personaId}/regenerate",
      path: {
        reportId: reportId,
        personaId: personaId
      },
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }
}
