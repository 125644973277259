import { styled } from "@linaria/react";

import { AlertCircle, CheckCircle } from "react-feather";

import {
  fontSize,
  family,
  headingLetterSpacing,
  lineHeight
} from "styles/fonts";
import { blue, green, red } from "styles/colors";

const Heading1 = styled.h1`
  display: inline-block;
  font-family: ${family.interDisplay};
  font-size: ${fontSize.xl};
  letter-spacing: ${headingLetterSpacing.xl};
  line-height: ${lineHeight.md};
  margin: 0;
  margin-bottom: 12px;
`;

const Heading2 = styled.h2`
  display: inline-block;
  font-family: ${family.interDisplay};
  font-size: ${fontSize.lg};
  letter-spacing: ${headingLetterSpacing.lg};
  line-height: ${lineHeight.lg};
  margin-bottom: 8px;
`;

const Heading3 = styled.h3`
  font-family: ${family.interDisplay};
  font-size: ${fontSize.md};
  letter-spacing: ${headingLetterSpacing.md};
  line-height: ${lineHeight.xl};
  margin-bottom: 4px;
  color: ${blue.xapienSecondaryLight};
`;

const Heading4 = styled.h4`
  font-family: ${family.interDisplay};
  font-size: ${fontSize.sm};
  letter-spacing: ${headingLetterSpacing.sm};
  margin-bottom: 0.5rem;
`;

const Paragraph = styled.div<{ hasStatusMessage: boolean }>`
  font-size: ${fontSize.sm};
  margin: 0;
  padding-right: 25px;
  margin-bottom: ${({ hasStatusMessage }) => (hasStatusMessage ? "0" : "20px")};
  white-space-collapse: preserve-breaks;
`;

const Division = styled.div`
  font-size: ${fontSize.sm};
  margin: 0;
`;

const TickCircle = styled.img`
  padding-right: 4px;
  height: 38px;
  width: 38px;
`;

const ThreeBalls = styled.img`
  max-height: 38px;
  max-width: 38px;
  position: absolute;
  top: 0;
  left: -40px;
`;

const SourcesFetchedSuccessIcon = styled(CheckCircle)`
  position: absolute;
  top: 2px;
  left: -34px;
  color: ${green.xapienGreen};
`;

const SourcesFetchedErrorIcon = styled(AlertCircle)`
  position: absolute;
  top: 2px;
  left: -34px;
  color: ${red.badgeRed};
`;

const ParagraphContainer = styled.div`
  position: relative;
`;

const ErrorFetchingSourcesMessage = styled.div`
  color: ${red.badgeRed};
  font-size: ${fontSize.xs};
`;

const S = {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Paragraph,
  Division,
  ParagraphContainer,
  ThreeBalls,
  TickCircle,
  SourcesFetchedSuccessIcon,
  SourcesFetchedErrorIcon,
  ErrorFetchingSourcesMessage
};

export default S;
