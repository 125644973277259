import React, { memo } from "react";

import { formatDate } from "util/common";

import { StyledCardText } from "pages/report/cards/StyledCardText";
import SourceLink from "components/atoms/SourceLink";

import { ImageSizeToUse } from "util/ImageSizeToUse";
import S from "./styles";

export const CardHeader = memo(props => {
  const {
    image,
    url,
    heading,
    detectedLanguage,
    publicationDate,
    publicationDateFull,
    provider,
    originalLanguage
  } = props;
  const isTranslated =
    originalLanguage &&
    detectedLanguage &&
    detectedLanguage.toLowerCase() !== originalLanguage.toLowerCase();
  const nonEnglish =
    detectedLanguage && detectedLanguage.toLowerCase() !== "english";
  return (
    <div className="di-card-header">
      <div className="di-card-headings">
        <span>
          <S.TitleContainer>
            <SourceLink
              truncateLink={false}
              href={
                isTranslated
                  ? `https://translate.google.com/translate?u=${encodeURIComponent(
                      url.fullUrl
                    )}`
                  : url.fullUrl
              }
            >
              <StyledCardText>{heading}</StyledCardText>
            </SourceLink>
          </S.TitleContainer>{" "}
          {isTranslated ? (
            <small>({`Translated from ${originalLanguage}`})</small>
          ) : nonEnglish ? (
            <small>{detectedLanguage}</small>
          ) : (
            ""
          )}
        </span>
        <div className="di-card-header-title-box">
          <S.ProviderText>
            <S.SourceLinkIcon />
            <strong>{provider}</strong>
          </S.ProviderText>
        </div>
        <div className="di-card-header-title-box">
          <S.PublicationDate>
            {publicationDateFull
              ? formatDate(publicationDateFull)
              : publicationDate || ""}
          </S.PublicationDate>
        </div>
      </div>
      {image ? (
        <S.SourceImage
          image={image}
          width="64px"
          height="64px"
          lazyLoad={false}
          imageSizeToUse={ImageSizeToUse.Small}
        />
      ) : (
        ""
      )}
    </div>
  );
});
