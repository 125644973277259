import { styled } from "@linaria/react";

import Button from "components/atoms/Button";

import { green, grey, standardColors } from "styles/colors";

const Textarea = styled.textarea`
  width: 100%;
  height: 180px;
  border-radius: 4px;
  border: 1px solid ${grey.rule};
  padding: 1rem;

  ::placeholder {
    color: ${grey.ghost};
  }
`;

const Actions = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: flex-end;
  padding-top: 1rem;
`;

const CloseButton = styled(Button)`
  color: ${green.xapienGreen} !important;
`;

const SubmitButton = styled(Button)`
  color: ${standardColors.white} !important;
`;

const ContextItem = styled.div`
  margin-bottom: 0.5rem;
`;

const ContextItems = styled.div`
  margin-bottom: 1.25rem;
`;

const S = {
  Actions,
  ContextItem,
  ContextItems,
  CloseButton,
  SubmitButton,
  Textarea
};

export default S;
