import { styled } from "@linaria/react";
import { CardVariant } from "components/molecules/Card/types";

import { grey } from "styles/colors";
import { fontSize } from "styles/fonts";

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
`;

const Title = styled.p<{ variant?: CardVariant }>`
  margin: 0;
  font-weight: bold;
  font-size: ${fontSize.xs};
  line-height: 1rem;
  color: ${({ variant }) =>
    variant === CardVariant.dark ? grey.rule : grey.mid};
`;

const Subtitle = styled.p<{ variant: CardVariant }>`
  width: fit-content;
  margin: 0;
  color: ${({ variant }) =>
    variant === CardVariant.dark ? grey.rule : grey.mid};
  font-size: ${fontSize.xs};
  line-height: 1rem;
`;

const Tags = styled.div`
  display: flex;
  gap: 0.375rem;
  flex-wrap: wrap;
`;

const S = {
  Title,
  Subtitle,
  Body,
  Tags
};

export default S;
