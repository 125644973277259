import React, {
  useEffect,
  useState,
  useRef,
  PropsWithChildren,
  KeyboardEventHandler,
  ReactNode,
  FC
} from "react";

// @ts-ignore
import isEqual from "lodash.isequal";

import InspectorTopContent from "components/organisms/WithInspector/InspectorTopContent";
import SourceList from "components/molecules/SourceList";
import { INSPECTOR_DATA_FORMATS } from "util/inspectorDataFormat";

import { InformationSource } from "api/report/report-types";
import type { Placement, Strategy } from "@floating-ui/react";
import { deduplicateSources } from "./util";
import S from "./styles";

// weird type - just adding types to existing behaviour ~joseph
type TopSectionElement = string | ReactNode | { id?: string };
export type FilterAbleSource<T> = T & {
  id?: string;
  sources?: InformationSource[];
  topSectionElement?: TopSectionElement;
};

const separateContentFromSources = <T,>(
  data: FilterAbleSource<T>[]
): { contents: T[]; sources: InformationSource[] } | null => {
  if (!data || !data.length) {
    return null;
  }

  const separatedData = data.reduce(
    (acc: any, curr: any) => {
      if (!curr.sources?.length) {
        return acc;
      }

      if (!acc?.sources) {
        acc.sources = [];
      }
      if (!acc?.contents) {
        acc.contents = new Set();
      }

      if (curr.sources !== null) {
        acc.sources = acc.sources.concat(curr.sources);
      }
      acc.contents.add({
        topSectionElement: curr.topSectionElement,
        id: curr.id ?? curr.topSectionElement
      });

      return acc;
    },
    { contents: new Set(), sources: [] }
  );

  const separatedDataContentsArray = [...separatedData.contents];
  separatedData.contents = separatedDataContentsArray;

  return separatedData;
};

// Length of sources array without duplicates
const adjustLengthForDuplicates = (sourceData: InformationSource[]) => {
  const dedupedData = deduplicateSources(sourceData);
  return dedupedData?.length;
};

type WithInspectorProps = {
  sources?: InformationSource[];
  display?: string;
  highlightPadding?: string;
  highlightBorderRadius?: string;
  popoverAlignment?: Placement;
  popoverPosition?: Strategy;
  popoverDistance?: number;
  popoverOffset?: number;
  popoverTitle?: string;
  popoverSubtext?: string;
  dataFormat?: string;
  filterableSources?: FilterAbleSource<unknown>[];
  topSectionElement?: ReactNode;
  sourceDescriptionText?: string;
  showSourceSection?: boolean;
  showTopSection?: boolean;
  filterPillColor?: string;
  selectedFilterOverride?: any;
  isInspectorOpen?: any;
  renderSourceItems?: (...ps: any[]) => any;
  onFilterSelected?: (f: any) => void;
  pillClassName?: string;
  isSingleSourceOrSingleFilterFilterable?: boolean;
  disabled?: boolean;
  customContent?: any;
  defaultSelectFirstFilter?: boolean;
  className?: string;
  style?: any;
  isOpen?: boolean;
  hidePopover?: boolean;
  withPagination?: boolean;
};

const WithInspector: FC<PropsWithChildren<WithInspectorProps>> = ({
  display = "block",
  highlightPadding = "1px",
  highlightBorderRadius = "4px",
  popoverAlignment = "right",
  popoverPosition = "absolute",
  popoverDistance = 30,
  popoverOffset,
  popoverTitle,
  popoverSubtext,
  dataFormat = INSPECTOR_DATA_FORMATS.default,
  filterableSources = undefined,
  topSectionElement = undefined,
  sources = undefined,
  sourceDescriptionText = undefined,
  children,
  showSourceSection = true,
  showTopSection = true,
  filterPillColor,
  selectedFilterOverride,
  isInspectorOpen = () => {},
  renderSourceItems,
  onFilterSelected = () => {},
  pillClassName,
  isSingleSourceOrSingleFilterFilterable = true,
  disabled = false,
  customContent,
  defaultSelectFirstFilter = false,
  className,
  style,
  isOpen,
  hidePopover: hidePopOver = false,
  withPagination = false
}) => {
  const separatedData = separateContentFromSources(filterableSources || []);

  const [sourceData, setSourceData] = useState(
    separatedData?.sources ?? sources
  );

  const [originalSourcesData, setOriginalSourcesData] = useState(sourceData);

  const [isTooltipOpen, setIsTooltipOpen] = useState(isOpen ?? false);

  const [selectedFilter, setSelectedFilter] = useState<any>();

  const [prevSelectedFilter, setPrevSelectedFilter] = useState<any>();

  const [popoverIgnoreCloseEvents, setPopoverIgnoreCloseEvents] =
    useState(false);

  const exitInspectorRef = useRef<HTMLButtonElement>(null);
  const inspectorButtonRef = useRef<HTMLInputElement>(null);
  const sourceListContainerRef = useRef<HTMLDivElement>(null);

  const originalSourcesLength = adjustLengthForDuplicates(
    originalSourcesData || []
  );

  // In the case the inspector is set to disabled, close the popover
  useEffect(() => {
    if (disabled && isTooltipOpen) {
      setIsTooltipOpen(false);
    }
  }, [disabled, isTooltipOpen]);

  useEffect(() => {
    // Detect when a modal is open. This will require the popover to ignore
    // "close events" on elements inside of itself e.g. clicking on a modal
    // that has been opened inside the Inspector.
    const observer = new MutationObserver(() => {
      setPopoverIgnoreCloseEvents(
        // modal-open class is added to the body when our <Modal />
        // is open.
        document.body.classList.contains("modal-open")
      );
    });

    observer.observe(document.body, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const filterSources = (topSectionElement: any) => {
    if (!filterableSources) {
      return;
    }
    let filteredSources = filterableSources;

    if (topSectionElement !== undefined) {
      filteredSources = filterableSources.filter(sourceObj => {
        const interimTopSectionElement = sourceObj.topSectionElement;
        // We need to match against the _string_ identifier for the particular source.
        // The topSectionElement bound to a source can be in three different forms, so we need to
        // handle these in order to filter correctly:
        // 1. The topSectionElement is a ReactElement. The source object should contain a top level
        // unique identifier.
        // 2. The topSectionElement is an object, in this case, the unique identifier should be found within this object.
        // 3. The topSectionElement is a simple string.
        const sourceObjTopSectionElement =
          sourceObj.id ??
          (interimTopSectionElement &&
          interimTopSectionElement instanceof Object &&
          "id" in interimTopSectionElement
            ? interimTopSectionElement.id
            : interimTopSectionElement) ??
          interimTopSectionElement;
        return sourceObjTopSectionElement === topSectionElement;
      });
    }

    const filteredSeparatedData = separateContentFromSources(filteredSources);
    setSourceData(filteredSeparatedData?.sources);
  };

  // Only when the user switches filters should we then reset the scroll to the top
  if (prevSelectedFilter !== selectedFilter) {
    // Reset scroll position in <SourceList/>
    if (sourceListContainerRef.current) {
      sourceListContainerRef.current.scrollTop = 0;
    }
    setPrevSelectedFilter(selectedFilter);
  }

  // If filterableSources changes e.g. when risk is killed across the report,
  // we want this change to be reflected in the inspector state
  if (
    separatedData?.sources &&
    !isEqual(originalSourcesData, separatedData.sources)
  ) {
    // Here we need to consider the filter that is currently active,
    // this is the reason we call `filterSources`. In this function, it uses the
    // latest source data from props, therefore, we can get the latest prop driven sources
    // while still correctly displaying the _filtered_ set of sources.
    let filter = selectedFilter;
    // We also need to check whether the deleted filter is not the one that is currently selected.
    // If this is so, then we want to fall back on displaying _all_ sources, i.e. by passing undefined into
    // `filterSources`.
    if (
      selectedFilter &&
      !separatedData.contents.some(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        (filter: any) => filter.id === selectedFilter
      )
    ) {
      filter = undefined;
    }
    filterSources(filter);
    setOriginalSourcesData(separatedData.sources);
  }

  // Overrides the internal selected filter state and ensures the sources filter
  // according to the newly selected filter.
  if (selectedFilterOverride && selectedFilter !== selectedFilterOverride) {
    filterSources(selectedFilterOverride);
    setSelectedFilter(selectedFilterOverride);
  }

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setIsTooltipOpen(false);
      }
    };
    window.addEventListener("keydown", handler);

    return () => window.removeEventListener("keydown", handler);
  }, []);

  /** Error checking * */
  if (
    dataFormat === INSPECTOR_DATA_FORMATS.filterableList &&
    filterableSources === undefined
  ) {
    console.error(
      `'${INSPECTOR_DATA_FORMATS.filterableList}' data format requires the 'filterableSources' prop to be assigned.`
    );
    return <>{children}</>;
  }

  if (
    (dataFormat === INSPECTOR_DATA_FORMATS.custom &&
      customContent === undefined) ||
    (dataFormat !== INSPECTOR_DATA_FORMATS.custom && customContent)
  ) {
    if (customContent) {
      console.error(
        `'customContent' prop requires the data format, ${INSPECTOR_DATA_FORMATS.custom}, to be assigned.`
      );
    } else {
      console.error(
        `'${INSPECTOR_DATA_FORMATS.custom}' data format requires the 'customContent' prop to be assigned.`
      );
    }

    return <>{children}</>;
  }

  /** End of error checking * */

  if (disabled) {
    return <>{children}</>;
  }

  const renderSourceSection = () => {
    if (sourceDescriptionText) {
      return <S.SourceDescription>{sourceDescriptionText}</S.SourceDescription>;
    }
    return (
      <SourceList
        sources={sourceData}
        selectedFilter={selectedFilter}
        renderSourceItems={renderSourceItems}
        hideSourceCount={!showTopSection}
        ref={sourceListContainerRef}
        withPagination={withPagination}
      />
    );
  };

  const getSourceCount = () => {
    if (!originalSourcesLength) {
      return "Inspector";
    }

    const filteredSourcesLength = adjustLengthForDuplicates(sourceData || []);

    if (originalSourcesLength === 1) {
      return "1 original source";
    }

    return `${filteredSourcesLength} ${
      originalSourcesLength > filteredSourcesLength
        ? `of ${originalSourcesLength}`
        : ""
    } original sources`;
  };

  const onRequestClose = () => {
    setSelectedFilter(null);
    isInspectorOpen?.(false);
    setIsTooltipOpen(false);
  };

  const onToggleInspector = () => {
    if (!isTooltipOpen) {
      // If the tooltip is going to open then ensure the state
      // of the tooltip (the sources, filters etc) are reset. This
      // mimics the process of re-mounting the component without
      // remounting.

      // Reset to default filter if enabled
      if (defaultSelectFirstFilter && filterableSources?.length === 1) {
        const filter =
          filterableSources[0]?.id ?? filterableSources[0]?.topSectionElement;
        filterSources(filter);
        setSelectedFilter(filter);
      } else {
        // Reset all
        setSelectedFilter(undefined);
        filterSources(undefined);
      }
      isInspectorOpen(true);
      setIsTooltipOpen(true);
    } else {
      onRequestClose();
    }
  };

  const onKeyDownOnExitButton: KeyboardEventHandler<HTMLButtonElement> = e => {
    if (e.key === "Tab" && e.shiftKey) {
      e.preventDefault();
      inspectorButtonRef?.current?.focus();
    } else if (e.key === "Enter") {
      inspectorButtonRef?.current?.focus();
      onRequestClose();
    }
  };

  const onKeyDownOnInspectorButton: KeyboardEventHandler<HTMLElement> = e => {
    if (e.key === "Enter") {
      onToggleInspector();
    } else if (e.key === "Tab" && !e.shiftKey && isTooltipOpen) {
      e.preventDefault();
      exitInspectorRef?.current?.focus();
    }
  };

  return (
    <S.InspectorContainer display={display} className={className} style={style}>
      <S.Popover
        borderRadius={12}
        trigger="click"
        display={display}
        bubbleCloseEvents={!popoverIgnoreCloseEvents}
        interactive
        disableHideOnClip={undefined}
        className={undefined}
        style={undefined}
        hideArrow={hidePopOver}
        content={
          !hidePopOver && (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              onKeyDown={e => {
                if (e.key === "Escape") {
                  inspectorButtonRef?.current?.focus();
                  onRequestClose();
                }
              }}
            >
              <S.TooltipHeader>
                <S.TooltipHeaderTitle role="heading">
                  {popoverTitle ?? getSourceCount()}
                </S.TooltipHeaderTitle>
                <S.RightMenu>
                  <S.ExitButton
                    ref={exitInspectorRef}
                    onClick={onToggleInspector}
                    onKeyDown={onKeyDownOnExitButton}
                  >
                    <S.ExitIcon />
                  </S.ExitButton>
                </S.RightMenu>
              </S.TooltipHeader>
              {popoverSubtext ? (
                <S.Subtext>
                  <hr />
                  <div>{popoverSubtext}</div>
                </S.Subtext>
              ) : null}
              <S.TooltipBody>
                {customContent ? (
                  <div>{customContent}</div>
                ) : (
                  <>
                    {showTopSection && (
                      <InspectorTopContent
                        filterPillColor={filterPillColor}
                        dataFormat={dataFormat}
                        filterItems={separatedData?.contents}
                        fallbackElement={topSectionElement ?? children}
                        filterSources={filterSources}
                        selectedFilter={selectedFilter}
                        onFilterSelected={(filter: any) => {
                          setSelectedFilter(filter);
                          onFilterSelected(filter);
                        }}
                        pillClassName={pillClassName}
                        totalSources={originalSourcesLength}
                        isSingleSourceOrSingleFilterFilterable={
                          isSingleSourceOrSingleFilterFilterable
                        }
                      />
                    )}
                    {showSourceSection && originalSourcesLength > 0 && (
                      <>
                        {showTopSection && <S.Rule />}
                        {renderSourceSection()}
                      </>
                    )}
                  </>
                )}
              </S.TooltipBody>
            </div>
          )
        }
        isOpenOverride={isTooltipOpen as any}
        alignment={popoverAlignment}
        distance={popoverDistance}
        tooltipOffset={popoverOffset}
        onRequestClose={onRequestClose as any}
        position={popoverPosition}
      >
        <S.InspectorInnerContainer
          role="button"
          ref={inspectorButtonRef}
          aria-label="Open inspector"
          onClick={() => {
            onToggleInspector();
          }}
          tabIndex={
            0
          } /* Used for tabbing as this is not a native button element */
          display={display}
          highlightPadding={highlightPadding}
          highlightBorderRadius={highlightBorderRadius}
          onKeyDown={onKeyDownOnInspectorButton}
        >
          {children}
        </S.InspectorInnerContainer>
      </S.Popover>
    </S.InspectorContainer>
  );
};

export default WithInspector;
