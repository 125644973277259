import React, { CSSProperties, ReactNode } from "react";
import Heading from "components/atoms/Heading";

import { X, Zap } from "react-feather";
import S from "./styles";

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  onExitClick?: () => void;
  children: ReactNode;
  icon?: boolean;
  title?: string;
  width?: number;
  style?: CSSProperties;
  className?: string;
  variant?: string;
}

const ModalContainer = ({
  isOpen,
  toggleOpen,
  children,
  onExitClick,
  title,
  width,
  style,
  className,
  icon = false,
  variant = "dark"
}: Props) => {
  return (
    <S.Modal
      centered
      isOpen={isOpen}
      toggle={toggleOpen}
      width={width}
      style={style}
      variant={variant}
      className={className}
    >
      {onExitClick && variant === "dark" && (
        <S.ExitButton onClick={onExitClick}>
          <X />
        </S.ExitButton>
      )}
      {title && variant === "light" && (
        <S.LightHeading level={4}>
          {icon && <Zap />}
          {title}
          {onExitClick && <X onClick={onExitClick} />}
        </S.LightHeading>
      )}
      {title && variant === "dark" && (
        <Heading level={4}>
          {icon && <Zap />} {title}
        </Heading>
      )}
      {variant === "dark" && children}
      {variant === "light" && <S.ModalContent>{children}</S.ModalContent>}
    </S.Modal>
  );
};

export default ModalContainer;
