import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion/dist/framer-motion";

import BrandedModalHeader from "components/molecules/BrandedModalHeader";
import Heading from "components/atoms/Heading";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";

import UserDetails from "./UserDetails";
import ConfirmationStage from "./Confirmation";

import S from "./styles";

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  reportId: string;
}

enum Stage {
  Initial = "initial",
  Form = "form",
  Confirmation = "confirmation"
}

const RequestAccountModal = ({ isOpen, toggleOpen, reportId }: Props) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const [stage, setStage] = useState(Stage.Initial);

  const navigate = useNavigate();

  const toggleModal = () => {
    toggleOpen();
    setStage(Stage.Initial);
    setEmail("");
    setName("");
  };

  const onRequestSent = () => {
    setStage(Stage.Confirmation);
  };

  const renderContent = () => {
    if (stage === Stage.Form) {
      return (
        <UserDetails
          reportId={reportId}
          name={name}
          onNameChange={value => setName(value)}
          email={email}
          onEmailChange={value => setEmail(value)}
          onRequestSent={onRequestSent}
          onBackClick={() => setStage(Stage.Initial)}
        />
      );
    }

    if (stage === Stage.Confirmation) {
      return <ConfirmationStage toggleModal={toggleModal} />;
    }

    return (
      <>
        <Heading level={4}>Request an account</Heading>
        <S.ModalText>
          This will send an email request to grant you access to this
          organisation.
        </S.ModalText>
        <ButtonNew
          text="Go"
          size={ButtonSize.Medium}
          type={ButtonType.Filled}
          onClick={() => setStage(Stage.Form)}
        />
        <S.ExistingAccountButton onClick={() => navigate("/login")}>
          I already have an account
        </S.ExistingAccountButton>
      </>
    );
  };

  return (
    <S.ModalContainer
      isOpen={isOpen}
      toggleOpen={toggleModal}
      width={540}
      onExitClick={toggleModal}
    >
      <BrandedModalHeader
        title={
          <S.ModalTitle>
            Xapien is a fully-automated research platform
          </S.ModalTitle>
        }
      />
      <AnimatePresence exitBeforeEnter initial={false}>
        {renderContent()}
      </AnimatePresence>
    </S.ModalContainer>
  );
};

export default RequestAccountModal;
