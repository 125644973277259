import { styled } from "@linaria/react";

import { fontSize } from "styles/fonts";
import { grey } from "styles/colors";

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  background-color: ${grey.panel};
  height: 54px;
  padding: 16px;
`;

const SectionCount = styled.div`
  color: ${grey.dark};
  font-size: ${fontSize["2xl"]};
`;

const S = {
  SectionHeader,
  SectionCount
};

export default S;
