/* eslint-disable @typescript-eslint/no-unused-vars */
import type { CollectionListState } from "util/hooks/useCollectionList/types";
import type { CollectionSearchState } from "util/hooks/useCollectionSearch/types";
import {
  CollectionStatus,
  COLLECTION_ITEMS_PER_PAGE,
  CollectionInputType
} from "util/hooks/useCollectionList/types";
import { CollectionListView } from "components/molecules/CollectionListControls";
import type { SearchResult } from "api/search";
import {
  Idam_Contracts_Users_GettingStartedRequest,
  IdentityOrganisationApiService,
  IdentityUserApiService,
  Idam_Contracts_Organisations_InviteUsersRequest,
  Idam_Contracts_Enums_OrganisationRole,
  Idam_Contracts_Tenants_OrganisationUser,
  Idam_Contracts_Organisations_UpdateOrganisationUserRequest
} from "api/portal";
import { formatGroupName } from "api/reports/utils";
import { getErrorMessage } from "api/util";
import { FetchResult } from "api/types";

import { Tag, TagId, type User } from "./types";
import {IListableApi} from "../../util/hooks/useCollectionList/provider";

export type { User };

export default class Users implements IListableApi<{ id: string, tags: { name: string }[] }>{
  async addToGroup(userId: string, groupId: string): Promise<FetchResult> {
    try {
      await IdentityOrganisationApiService.putOrganisationsGroupsMembers({
        groupId,
        requestBody: { addUsers: [userId] }
      });
      return { status: true };
    } catch (e) {
      console.error("Error adding user to group", { e, groupId, userId });
      return { status: false, message: getErrorMessage(e) };
    }
  }

  getEmptySearchState(): CollectionSearchState {
    return {
      query: "",
      results: [],
      searchTags: []
    };
  }

  getEmptyCollections(): CollectionListState<any> {
    return {
      collections: [
        {
          id: "users",
          title: "Users",
          limit: COLLECTION_ITEMS_PER_PAGE,
          offset: 0,
          order: "desc",
          view: CollectionListView.grid,
          pollingEnabled: true,
          items: [],
          totalItemCount: 0,
          status: CollectionStatus.stale,
          hidden: false,
          hiddenIfEmpty: false,
          input: { type: CollectionInputType.list }
        }
      ]
    };
  }

  isImportedUser = (user: Idam_Contracts_Tenants_OrganisationUser) => {
    // users that have been imported from the old system but haven't yet setup their portal accounts
    if (!user.firstName) {
      return false;
    }
    if (!user.lastName) {
      return false;
    }
    return `${user.firstName} ${user.lastName}` === "Imported User";
  };

  async search({
    query,
    offset,
    limit,
    users
  }: {
    query: string;
    offset: number;
    limit: number;
    users?: Idam_Contracts_Tenants_OrganisationUser[];
  }): Promise<{ items: User[]; totalItemCount: number }> {
    // TODO - replace with backend implementation

    const allUsersInOrg = users || (await this.getAllUsers());

    function displayName(
      firstName?: string | null,
      lastName?: string | null
    ): string {
      return [firstName, lastName].filter(s => s).join(" ");
    }

    const containsQuery = (value: string | undefined | null): unknown => {
      return value?.toLowerCase()?.includes(query.toLowerCase());
    };
    const filteredUsers = allUsersInOrg.filter(u => {
      if (this.isImportedUser(u)) {
        return false;
      }
      if (containsQuery(u.email)) {
        return true;
      }
      if (containsQuery(displayName(u.firstName, u.lastName))) {
        return true;
      }
      return false;
    });
    const results = filteredUsers.map(
      ({ userId, jobTitle, groups, firstName, lastName, permissions }) => {
        const tags: Tag[] = [];
        groups?.forEach(({ name }) => {
          tags.push({
            id: TagId.GroupMember,
            name: formatGroupName(name) ?? ""
          });
        });
        return {
          id: userId!,
          jobTitle: jobTitle || undefined,
          title: displayName(firstName, lastName),
          tags,
          resource: {
            userId,
            jobTitle,
            groups,
            firstName,
            lastName
          },
          permissions: {
            canView: !!permissions?.canView,
            canEdit: !!permissions?.canEdit,
            canDelete: !!permissions?.canDelete,
            canViewReports: !!permissions?.canViewReports
          }
        };
      }
    );

    const start = Math.max(offset, 0);
    if (start >= results.length) {
      return { items: [], totalItemCount: 0 };
    }
    const end = Math.min(offset + limit, results.length);
    const slicedResults = results.slice(start, end);
    return { items: slicedResults, totalItemCount: slicedResults.length };
  }

  async getAllUsers(): Promise<Idam_Contracts_Tenants_OrganisationUser[]> {
    try {
      return await IdentityOrganisationApiService.getOrganisationsUsers();
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async getSearchSuggestions({
    query,
    users
  }: {
    query: string;
    users?: Idam_Contracts_Tenants_OrganisationUser[];
  }): Promise<SearchResult[]> {
    // TODO - replace with backend implementation - not in portal MVP

    const { items } = await this.search({ query, limit: 20, offset: 0, users });
    return items;
  }

  async invite({
    emails,
    groups,
    userType
  }: {
    emails: string[];
    groups: string[];
    userType: string;
  }): Promise<FetchResult> {
    try {
      const requestBody: Idam_Contracts_Organisations_InviteUsersRequest = {
        emails,
        groups,
        role: userType as Idam_Contracts_Enums_OrganisationRole
      };
      await IdentityOrganisationApiService.postOrganisationsUsersInvite({
        requestBody
      });

      return { status: true };
    } catch (e: unknown) {
      console.error("Error inviting user", e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async validateRegistrationToken({
    token
  }: {
    token: string;
  }): Promise<FetchResult> {
    try {
      const response =
        await IdentityUserApiService.postUsersGettingStartedValidate({
          base64Token: token
        });

      if (response.inviteAccepted === false) {
        return { status: true };
      }
      return { status: false };
    } catch (e: unknown) {
      console.error("Error validating registration token", e);
      return { status: false };
    }
  }

  async register({
    token,
    isMigration,
    firstName,
    lastName,
    jobTitle,
    password
  }: {
    token: string;
    isMigration: boolean;
    firstName: string;
    lastName: string;
    jobTitle?: string;
    password: string;
  }): Promise<FetchResult> {
    try {
      const requestBody: Idam_Contracts_Users_GettingStartedRequest = {
        firstName,
        lastName,
        jobTitle,
        password
      };
      if (isMigration) {
        await IdentityUserApiService.postUsersGettingMigrated({
          base64Token: token,
          requestBody
        });
      } else {
        await IdentityUserApiService.postUsersGettingStarted({
          base64Token: token,
          requestBody
        });
      }

      return {
        status: true
      };
    } catch (e: unknown) {
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async changeRole({
    userId,
    role
  }: {
    userId: string;
    role: Idam_Contracts_Enums_OrganisationRole;
  }): Promise<FetchResult> {
    try {
      await IdentityOrganisationApiService.putOrganisationsUsersRole({
        userId,
        role
      });
      return {
        status: true
      };
    } catch (e) {
      console.error(e);

      return { status: false, message: getErrorMessage(e) };
    }
  }

  async updateUser({
    userId,
    firstName,
    lastName,
    jobTitle
  }: {
    userId: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
  }): Promise<FetchResult> {
    try {
      const requestBody: Idam_Contracts_Organisations_UpdateOrganisationUserRequest =
        {
          firstName,
          lastName,
          jobTitle
        };

      await IdentityOrganisationApiService.putOrganisationsUsers({
        userId,
        requestBody
      });

      return {
        status: true
      };
    } catch (e) {
      console.error(e);

      return { status: false, message: getErrorMessage(e) };
    }
  }

  async deleteUser(userId: string): Promise<FetchResult> {
    try {
      await IdentityOrganisationApiService.deleteOrganisationsUsers({ userId });
      return { status: true };
    } catch (e) {
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async list({
    id,
    limit,
    offset,
    filters
  }: {
    id: string;
    limit: number;
    offset: number;
    filters?: string[];
  }): Promise<{ items: User[]; totalItemCount: number }> {
    const response =
      await IdentityOrganisationApiService.getOrganisationsUsers();

    const items = response
      .filter(u => !this.isImportedUser(u))
      .map(
        ({ userId, firstName, lastName, email, groups, role, permissions }) => {
          const tags: Tag[] = [];

          if (role === Idam_Contracts_Enums_OrganisationRole.ADMIN) {
            tags.push({ id: TagId.UserRole, name: "Admin" });
          }
          groups?.forEach(({ name }) => {
            tags.push({
              id: TagId.GroupMember,
              name: formatGroupName(name) ?? ""
            });
          });
          return {
            id: userId ?? "unknown",
            icon: "user",
            title: `${firstName} ${lastName}`,
            context: email ?? "unknown",
            tags,
            resource: {
              userId,
              firstName,
              lastName,
              email,
              role,
              groups
            },
            permissions: {
              canView: !!permissions?.canView,
              canEdit: !!permissions?.canEdit,
              canDelete: !!permissions?.canDelete,
              canViewReports: !!permissions?.canViewReports
            }
          };
        }
      );

    return {
      items,
      totalItemCount: items.length
    };
  }

  async transferUserReports(
    userId: string,
    newOwnerUserId: string
  ): Promise<FetchResult> {
    try {
      await IdentityOrganisationApiService.postOrganisationsUsersReportsTransfer(
        {
          userId,
          newOwnerUserId
        }
      );

      return { status: true };
    } catch (e) {
      return { status: false, message: getErrorMessage(e) };
    }
  }
}
