import React from "react";
import HubLoginModal from "components/molecules/HubLoginModal";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

const Login = () => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <HubLoginModal slogan="Xapien Hub" isOpen />
      </motion.div>
    </AnimatePresence>
  );
};

export default Login;
