import { styled } from "@linaria/react";
import { grey } from "styles/colors";

const ChartsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RiskOverTimeChart = styled.div`
  min-height: 200px;
  width: ${props => (props.isFullWidth ? 100 : 89)}%;
  z-index: 2;
`;

const Divider = styled.div`
  width: 1px;
  background-color: ${grey.rule};
  height: 130px;
`;

const UndatedRiskChart = styled.div`
  min-height: 200px;
  width: 11%;
  z-index: 1;
  display: ${props => (props.isHidden ? "none" : "block")};
`;

const S = {
  ChartsContainer,
  RiskOverTimeChart,
  Divider,
  UndatedRiskChart
};

export default S;
