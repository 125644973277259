import { styled } from "@linaria/react";

import ReactTooltip from "components/atoms/ReactTooltip";
import ButtonNew from "components/atoms/ButtonNew";
import Heading from "components/atoms/Heading";

import { hexToRgb } from "styles/hexToRgb";
import { blue, grey, purple, standardColors } from "styles/colors";
import { family } from "styles/fonts";
import { zIndex } from "styles/zIndex";

const DropdownButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
`;

const DropdownButtonText = styled.span`
  &:hover {
    color: ${purple.dark};
  }
`;

const Dropdown = styled.div`
  width: 336px;
`;

const DropdownOption = styled.div`
  padding: 8px;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${grey.panel};
  }

  &:hover {
    background-color: ${() => {
      const { r, g, b } = hexToRgb(blue.hover);
      return `rgba(${r}, ${g}, ${b}, 0.05)`;
    }};
  }
`;

const Tooltip = styled(ReactTooltip)`
  display: flex;
`;

const ControlButton = styled.div`
  background: ${grey.panel};
  padding: 6px 12px 6px 8px;
  border-radius: 100px;
  font-family: ${family.interSemiBold};

  &:hover {
    background: ${purple.lighter};

    span {
      color: ${purple.dark};
    }
  }
`;

const ButtonIcon = styled.div<{ isActive: boolean }>`
  background: ${({ isActive }) =>
    isActive ? purple.darker : standardColors.white};
  color: ${({ isActive }) => (isActive ? standardColors.white : purple.darker)};
  border-radius: 50%;
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

const DiscardModalContainer = styled.div`
  z-index: ${zIndex.l7};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
`;

const DiscardModal = styled.div`
  position: relative;
  background: ${standardColors.white};
  padding: 40px 40px 20px 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 5px;

  p {
    font-family: ${family.interLight};
  }
`;

const DiscardModalHeading = styled(Heading)`
  font-family: ${family.interSemiBold};
`;

const DiscardModalActions = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
  padding-top: 10px;
`;

const CancelButton = styled(ButtonNew)`
  background: ${grey.panel};

  &:hover {
    background: ${grey.rule};
    color: ${blue.xapienDark};
  }
`;

const ConfirmButton = styled(ButtonNew)`
  background: ${purple.darker};
  color: ${standardColors.white};

  &:hover {
    background: ${purple.darkest};
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background: ${grey.panel};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;

  svg {
    width: 15px;
    height: 15px;
  }
`;

const DiscardedCover = styled.div`
  z-index: ${zIndex.l7};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
`;

const DiscardedIcon = styled.div`
  background: ${standardColors.white};
  border: 1px solid ${purple.darker};
  color: ${purple.darker};

  border-radius: 50%;
  min-width: 32px;
  min-height: 32px;
  max-width: 32px;
  max-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

const DiscardedCoverHeading = styled(Heading)`
  color: ${purple.darker};
`;

const UndoButton = styled(ButtonNew)`
  background: ${grey.panel};

  &:hover {
    background: ${grey.rule};
    color: ${blue.xapienDark};
  }
`;

const S = {
  CloseButton,
  DropdownButton,
  DropdownButtonText,
  Dropdown,
  DropdownOption,
  Tooltip,
  ControlButton,
  ButtonIcon,
  DiscardModalContainer,
  DiscardModal,
  DiscardModalHeading,
  DiscardModalActions,
  DiscardedCover,
  DiscardedIcon,
  DiscardedCoverHeading,
  CancelButton,
  ConfirmButton,
  UndoButton
};

export default S;
