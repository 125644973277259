import { useMemo } from "react";

import useViewerMode from "util/hooks/useViewerMode";
import Reports from "api/reports";

const useMarkReportRead = () => {
  const { isSharedReportLink } = useViewerMode();
  const reportsApi = useMemo(() => new Reports(), []);

  const markRead = async (reportId: string) => {
    if (isSharedReportLink) return;
    await reportsApi.markRead(reportId);
  };

  const markUnread = async (reportId: string) => {
    await reportsApi.markUnread(reportId);
  };

  return { markRead, markUnread };
};

export default useMarkReportRead;
