import { styled } from "@linaria/react";
import { css } from "@linaria/core";
import { DropdownItem } from "reactstrap";

import { motion } from "framer-motion/dist/framer-motion";

import StickyExpandButton from "pages/report/StickyExpandButton";

import { fontSize, family } from "styles/fonts";
import { grey, green, blue, standardColors } from "styles/colors";
import { hexToRgb } from "styles/hexToRgb";

const RelatedOrganisations = styled.div`
  max-height: ${props => {
    if (props.isResultsExpanded) {
      return "inherit";
    }
    return "600px";
  }};
  overflow-y: auto;
`;

const SectionBanner = styled.div`
  width: 100%;
  background-color: ${grey.panel};
  border-radius: 2px;
  padding: 8px 16px;
`;

const SectionBannerInfo = styled.p`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  margin-bottom: 0;
`;

const CustomStickyExpandButton = styled(StickyExpandButton)`
  padding: 10px 16px;
  border-top: 2px solid ${standardColors.white};
`;

const InnerSectionBannerContent = styled.div`
  justify-content: space-between;
  width: 100%;
  display: flex;
  align-items: center;
`;

const SectionCount = styled.div`
  text-align: end;
  color: ${grey.dark};
  font-size: ${fontSize["2xl"]};
  margin-left: 16px;
`;

const ConfidenceFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
`;

const ConfidenceButton = styled.button`
  height: 36px;
  width: 318px;
  width: 100%;
  flex: 1;
  border: 1px solid
    ${props =>
      props.isSelected ? props.borderColor ?? green.xapienGreen : grey.ghost};
  border-radius: 20px;
  color: ${props => (props.isSelected ? standardColors.white : grey.mid)};
  font-size: ${fontSize.lg};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background-color: ${props =>
    props.isSelected ? props.backgroundColor ?? green.xapienGreen : grey.faint};
  transition: box-shadow 0.2s;
  h2 {
    margin: 0;
  }
  &:hover,
  &:focus {
    outline: none;
    box-shadow: 0 0 0 4px
      ${props => {
        if (props.isSelected) {
          const { r, g, b } = hexToRgb(
            props.backgroundColor ?? green.xapienGreen
          );
          return `rgba(${r}, ${g}, ${b}, 0.33)`;
        }
        return "rgb(210 211 216 / 33%)";
      }};
  }
`;

const Motion = styled(motion.div)`
  flex: 1;
  margin: 0 16px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const NoResults = styled(motion.div)`
  height: ${props => props.height}px;
  width: 100%;
  border-top: 1px solid ${standardColors.white};
  padding: 16px 0 0 16px;
  color: ${grey.mid};
  font-size: ${fontSize.sm};
`;

const ConfidencebucketCount = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.dark} !important;
  padding-right: 4px;
`;

const OptionsDropdownMenuItem = styled(DropdownItem)`
  padding: 8px;
  color: ${grey.dark};
  font-size: ${fontSize.sm} !important;
  font-family: ${family.interRegular};
  font-weight: 200;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover,
  &:focus {
    background-color: ${() => {
      const { r, g, b } = hexToRgb(blue.icon);

      return `rgba(${r}, ${g}, ${b}, 0.15)`;
    }};
  }
  &:disabled {
    opacity: 0.3;
    color: ${standardColors.white};
  }
  &:focus {
    outline: none;
  }
  &:active {
    color: ${grey.dark};
  }
`;

const DisabledOptionsDropdownMenuItem = styled(OptionsDropdownMenuItem)`
  opacity: 0.3;
`;

const CountAndMenu = styled.div`
  display: flex;
  align-items: center;
`;

const BuildingsIconContainer = styled.div`
  padding-top: 10px;
`;

const WhiteTextCentered = styled.div`
  color: ${standardColors.white};
  text-align: center;
`;

const OverviewImageContainer = styled.div`
  border-radius: 3px;
  width: 74px;
  height: 74px;
  background-color: ${props => props.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

/** Classname overrides */
const masonryGrid = css`
  display: flex;
  margin-left: -8px; /* gutter size offset */
  width: auto;
  margin-top: 3px;
`;

const masonryColumn = css`
  background-clip: padding-box;
  padding-left: 10px;

  & > div {
    margin-bottom: 10px;
  }

  & > div:last-child {
    margin-bottom: 0px;
  }
`;

export const classNameOverrides = {
  masonryGrid,
  masonryColumn
};

const TooltipContent = styled.div`
  text-align: left;
  padding: 2px;
  width: ${props => props.width ?? 275}px;
  max-width: 275px;
  max-height: 325px;
  overflow-y: auto;
`;

const S = {
  RelatedOrganisations,
  SectionBanner,
  SectionBannerInfo,
  CustomStickyExpandButton,
  InnerSectionBannerContent,
  SectionCount,
  ConfidenceFilters,
  ConfidenceButton,
  TooltipContent,
  Motion,
  NoResults,
  OptionsDropdownMenuItem,
  DisabledOptionsDropdownMenuItem,
  CountAndMenu,
  ConfidencebucketCount,
  OverviewImageContainer,
  BuildingsIconContainer,
  WhiteTextCentered
};

export default S;
