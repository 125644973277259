import http from "services/http";
import endpoints from "services/endpoints";

export default class Assertions {
  /**
   * @param   {Object} assertion
   * @param   {Object} enquiryId
   * @return  {String} {"AssertionId": "...", "AssertionResult": "..." or null}
   */
  async insertAssertion(assertion, enquiryId = null) {
    const url = endpoints.assertion;
    const res = await http.post(url, {
      Assertion: assertion,
      ReportId: enquiryId
    });
    return res.body;
  }

  /**
   * @param   {String}  testCaseId
   * @param   {String}  userId
   * @return  {Array}   assertions
   */
  async listAssertions(testCaseId = "", userId = "") {
    let url = endpoints.assertion;
    if (testCaseId && userId) {
      url += `?TestCaseId=${testCaseId}&UserId=${userId}`;
    } else if (testCaseId) {
      url += `?TestCaseId=${testCaseId}`;
    } else if (userId) {
      url += `?UserId=${userId}`;
    }
    try {
      return await http.get(url);
    } catch (e) {
      console.error(
        `Error: listAssertions request failed for testCaseId: "${testCaseId}", userId: "${userId}"`,
        e
      );
      return [];
    }
  }

  /**
   * @param   {String} assertionId
   * @return  {Object} assertion
   */
  async getAssertion(assertionId) {
    const url = `${endpoints.assertion}/${assertionId}`;
    try {
      return await http.get(url);
    } catch (e) {
      console.error(
        `Error: getAssertion request failed for "${assertionId}"`,
        e
      );
    }
  }

  /**
   * @param   {String} assertionId
   */
  async deleteAssertion(assertionId) {
    const url = `${endpoints.assertion}/${assertionId}`;
    try {
      return await http.delete(url);
    } catch (e) {
      console.error(
        `Error: deleteAssertion request failed for "${assertionId}"`,
        e
      );
    }
  }

  /**
   * Queries the EnquiryStateTable-{env} for Subject and Context, using them to obtain the testCaseId.
   * For now, assume the environment is QA.
   * @param   {String} enquiryId
   * @return  {Object} testCaseId
   */
  async getTestCaseFromReport(enquiryId) {
    const url = `${endpoints.testcaseFromReport}?ReportId=${enquiryId}`;
    try {
      const res = await http.get(url);
      return res.body.TestCaseId;
    } catch (e) {
      console.error(
        `Error: getTestCaseFromReport request failed for "${enquiryId}"`,
        e
      );
    }
  }

  /**
   * Sends enquiryId to lambda, which retrieves the relevant assertions and validates them, returning their results.
   * @param   {String} enquiryId
   * @return  {Array} [(assertion, assertionResult)]
   */
  async validateReport(enquiryId) {
    const url = `${endpoints.validateReport}?ReportId=${enquiryId}`;
    try {
      const res = await http.post(url, {}).then(res => res);
      return JSON.parse(res.body.ValidationResults);
    } catch (e) {
      console.error(
        `Error: validateReport request failed for "${enquiryId}"`,
        e
      );
    }
  }
}
