import { styled } from "@linaria/react";
import { DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import { standardColors } from "styles/colors";
import { family, fontSize } from "styles/fonts";

import { ReactComponent as ArrowDownSvg } from "img/icons/arrow-down.svg";
import InfoIconComponent from "components/atoms/InfoIcon";

const RowControlPlaceholder = styled.span`
  width: 56px;
`;

const RowControlButton = styled.button`
  border: none;
  background: none;
  padding: 0 16px;

  &:focus,
  &:hover:not([disabled]) {
    path {
      fill: ${standardColors.white};
    }

    circle {
      stroke: ${standardColors.white};
    }
  }

  svg {
    height: 27px;
    width: 24px;
  }

  path {
    transition: fill 0.2s;
    fill: ${standardColors.white};
  }

  circle {
    transition: stroke 0.2s;
    stroke: transparent;
  }

  &:focus {
    outline: none;
  }
`;

const RowContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  padding: 12px 0;
  transition: opacity 0.2s;
  /* Important required here to override Framer motion's opacity animation settings */
  opacity: ${props => (props.disabled ? 0.4 : 1)} !important;
`;

const InputElementsContainer = styled.div<{
  isFocused?: boolean;
  boxShadowColor?: string;
}>`
  display: flex;
  border: 1px solid ${standardColors.white};
  border-radius: 18px;
  transition: box-shadow 0.15s ease-in-out;
  width: 387px;
  justify-content: space-between;
  box-shadow: 0 0 0 4px
    ${props =>
      props.isFocused ? props.boxShadowColor ?? "rgb(40 239 250 / 33%)" : ""};
`;

const TextInput = styled.input`
  border: none;
  height: 36px;
  border-radius: 18px;

  font-family: ${family.interLight};
  box-shadow: none;
  display: flex;
  width: 100%;
  margin: 0;

  padding: 6px 0 6px 13px;

  font-size: ${fontSize.md};
  line-height: 1.5;
  background-color: transparent;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &::placeholder {
    opacity: 0.6;
  }

  &:focus {
    outline: 0;
  }
`;

const OptionsDropdownToggle = styled(DropdownToggle)`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent !important;
  border: none !important;
  border-radius: 18px;
  padding: 6px 8px;
  padding-right: 9px;
  height: 36px;
  font-family: ${family.interLight};
  transition: box-shadow 0.15s ease-in-out;
  color: ${standardColors.white};
  opacity: ${props => (props.disabled ? 0.4 : 1)} !important;

  path: {
    fill: ${standardColors.white};
  }

  &:focus {
    box-shadow: 0 0 0 4px
      ${props => props.boxShadowColor ?? "rgb(40 239 250 / 33%)"} !important;
    background-color: transparent;
    border-color: ${standardColors.white};
    outline: none;
  }

  &:hover,
  &:active {
    border-color: ${standardColors.white} !important;
  }
`;

const OptionsDropdownSelectedLabel = styled.div`
  padding-right: 10px;
`;

const OptionsDropdownMenuItem = styled(DropdownItem)`
  padding: 0 8px;
  color: ${standardColors.white};
  font-family: ${family.interLight};
  height: 28px;

  &:hover,
  &:focus {
    background-color: rgba(40, 239, 250, 0.2);
    color: ${standardColors.white};
  }

  &:disabled {
    opacity: 0.3;
    color: ${standardColors.white};
  }

  &:focus {
    outline: none;
  }

  &:active {
    background-color: rgba(40, 239, 250, 0.2);
  }
`;

const OptionsDropdownMenu = styled(DropdownMenu)`
  --menu-color: ${props => props.menuColor ?? "#4f94b4"};
  min-width: 147px;
  padding: 0;
  transform: none !important;
  top: 44px !important;
  border-color: var(--menu-color);
  border-radius: 6px;
  max-height: 170px;
  background-color: var(--menu-color);
  overflow-y: auto;
  box-shadow: 0 0 7px rgb(0 0 0 / 25%);

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.4);
  }

  &::-webkit-scrollbar-track {
    background-color: var(--menu-color);
    display: initial;
  }
`;

const ArrowDown = styled(ArrowDownSvg)`
  path {
    fill: ${standardColors.white};
  }
`;

const PromptContainer = styled.div`
  padding: 14px;
  font-size: ${fontSize.sm};
  max-width: 168px;
  text-align: left;
`;

const InfoIcon = styled(InfoIconComponent)`
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  margin: 0;
  padding-right: 10px;
  margin-left: 4px;

  svg {
    opacity: 0.7;
    height: 18px;
    width: 18px;
  }

  path {
    fill: ${standardColors.white};
  }
`;

const S = {
  RowControlPlaceholder,
  RowControlButton,
  RowContainer,
  InputElementsContainer,
  TextInput,
  OptionsDropdownToggle,
  OptionsDropdownSelectedLabel,
  OptionsDropdownMenuItem,
  OptionsDropdownMenu,
  ArrowDown,
  PromptContainer,
  InfoIcon
};

export default S;
