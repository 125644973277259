import { styled } from "@linaria/react";

import { green, grey, standardColors } from "styles/colors";
import { family, fontSize } from "styles/fonts";

const ReportBanner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;

  > *:not(:last-child) {
    margin-right: 3px;
  }
`;

const Banner = styled.div`
  color: ${grey.dark};
  background-color: ${grey.faint};
  text-align: left;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 3px;
  font-weight: lighter;
  display: flex;
  margin-bottom: 2px;
  justify-content: left;
  flex: 1;
  font-family: ${family.interLight} !important;
  font-size: ${fontSize.xl};

  @media print {
    display: none;
  }
`;

const ReportEnds = styled.div`
  flex-grow: 1;
`;

const BannerSearch = styled.div<{ backgroundColor: string }>`
  background-color: ${props => props.backgroundColor ?? green.xapienGreen};
  opacity: 0.7;
  text-align: left;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 3px;
  font-size: ${fontSize.xl};
  font-weight: lighter;
  display: flex;
  margin-bottom: 2px;
  justify-content: right;
  background-clip: padding-box;

  &:hover {
    opacity: 1;
  }

  @media print {
    display: none;
  }
`;

const SearchButton = styled.a`
  color: ${standardColors.white};
  text-decoration: none;
`;

const BottomLogo = styled.img`
  display: block;
  margin: 0 auto;
  margin-bottom: 18px;

  @media print {
    display: none;
  }
`;

const CopyrightReportMessage = styled.div`
  display: block;
  text-align: center;
  color: ${grey.dark};
  max-width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  font-size: ${fontSize.sm};

  @media print {
    display: none;
  }
`;

const PrintCopyrightReportMessage = styled.div`
  display: block;
  text-align: center;
  color: ${grey.dark};
  max-width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  font-size: ${fontSize.sm};

  @media screen {
    display: none;
  }
`;

const S = {
  ReportBanner,
  Banner,
  ReportEnds,
  BannerSearch,
  SearchButton,
  BottomLogo,
  CopyrightReportMessage,
  PrintCopyrightReportMessage
};

export default S;
