import React, { FC } from "react";
import ModalContainer from "components/molecules/ModalContainer";

import { ButtonKind } from "components/atoms/Button/types";

import {
  useInsightReport,
  InsightReportActions
} from "util/hooks/useInsightReport";

import { InsightReportStatus } from "util/hooks/useInsightReport/types";
import S from "./styles";

interface Props {
  sectionId: string;
  subSectionId?: string;
  elementId?: string;
  title: string;
  isOpen: boolean;
  onToggle: () => void;
}

const RemoveSectionModal: FC<Props> = ({
  sectionId,
  subSectionId,
  elementId,
  title,
  isOpen,
  onToggle
}) => {
  const { state, dispatch } = useInsightReport();

  const onRemove = () => {
    onToggle();

    if (!subSectionId) {
      // Removing section
      dispatch({
        type: InsightReportActions.removeReportSection,
        sectionId
      });

      return;
    }

    if (elementId) {
      // Removing element
      dispatch({
        type: InsightReportActions.removeReportElement,
        sectionId,
        subSectionId,
        elementId
      });
      return;
    }

    dispatch({
      type: InsightReportActions.removeReportSubSection,
      sectionId,
      subSectionId
    });
  };

  const onCloseModal = () => {
    onToggle();
  };

  return (
    <ModalContainer
      title="Remove"
      isOpen={isOpen}
      variant="light"
      toggleOpen={onCloseModal}
      onExitClick={onCloseModal}
    >
      <p>
        You are able to remove the section titled{" "}
        <strong>&apos;{title}&apos;</strong>
      </p>

      <S.Actions>
        <S.CloseButton
          onClick={onToggle}
          kind={ButtonKind.secondary}
          disabled={
            state.status === InsightReportStatus.removingReportSection ||
            state.status === InsightReportStatus.errorRemovingReportSection
          }
        >
          Cancel
        </S.CloseButton>

        <S.SubmitButton
          onClick={onRemove}
          kind={ButtonKind.secondary}
          disabled={
            state.status === InsightReportStatus.removingReportSection ||
            state.status === InsightReportStatus.errorRemovingReportSection
          }
        >
          {state.status === InsightReportStatus.idle && "Remove"}
          {state.status === InsightReportStatus.removingReportSection &&
            "Removing..."}
          {state.status === InsightReportStatus.errorRemovingReportSection &&
            "Remove"}
        </S.SubmitButton>
      </S.Actions>
    </ModalContainer>
  );
};

export default RemoveSectionModal;
