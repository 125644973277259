import React from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  useSearchParams
} from "react-router-dom";

// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import { ApmRoutes } from "@elastic/apm-rum-react";

import GlobalShell from "pages/shell/GlobalShell";
import PortalShell from "pages/shell/PortalShell";
import HubShell from "pages/shell/HubShell";
import PreparingReportContainer from "pages/report/containers/PreparingReportContainer";
import InviteConfirmationModal from "components/organisms/InviteConfirmationModal";
import Diagnostics from "pages/diagnostics";
import EnquirySummary from "pages/enquirySummary";
import ReportContainer from "pages/report";
import Reports from "./Portal/Reports";
import Users from "./Portal/Users";
import Groups from "./Portal/Groups";
import GettingStarted from "./Portal/GettingStarted";
import Login from "./Portal/Login";
import SharedReportContainer from "./Portal/SharedReportContainer";
import ResetPassword from "./Portal/ResetPassword";
import Search from "./Portal/Search";
import GroupReports from "./Portal/GroupReports";
import UserReports from "./Portal/UserReports";
import HubLogin from "./Hub/Login";
import ManageOrganisation from "./Hub/ManageOrganisation";
import CreateOrganisation from "./Hub/CreateOrganisation";
import HubOrganisationList from "./Hub/OrganisationList";
import HubResetPassword from "./Hub/ResetPassword";
import InsightReport from "./Portal/InsightReport";

declare global {
  interface Window {
    PUBLIC_URL: string;
  }
}
export const routes = {
  signin: "sign-in",
  diagnostics: "diagnostics",
  report: "report",
  preparing: "preparing",
  permalink: "permalink",
  reports: "reports",
  search: "search",
  users: "users",
  groups: "groups",
  gettingStarted: "getting-started",
  share: "share",
  resetPassword: "reset-password",
  login: "login",
  hubLogin: "login",
  hub: "hub",
  createOrg: "create-organisation",
  manageOrg: "manage-organisation",
  hubOrganisationList: "organisations",
  insightReport: "insight-report"
};

const RequestHandler = () => {
  const [searchParams] = useSearchParams();

  if (searchParams.get("mode") === "inviteRequest") {
    return <InviteConfirmationModal />;
  }

  return <Navigate to={routes.search} replace />;
};

const Router = () => {
  return (
    <BrowserRouter basename={window.PUBLIC_URL}>
      <ApmRoutes>
        <Route element={<GlobalShell />}>
          <Route path={routes.hub} element={<HubShell />}>
            <Route
              index
              element={<Navigate to={routes.hubOrganisationList} replace />}
            />
            <Route
              path="*"
              element={<Navigate to={routes.hubOrganisationList} replace />}
            />
            <Route
              path={routes.hubOrganisationList}
              element={<HubOrganisationList />}
            />
            <Route path={routes.hubLogin} element={<HubLogin />} />
            <Route
              path={`${routes.manageOrg}/:orgId`}
              element={<ManageOrganisation />}
            />
            <Route path={routes.createOrg} element={<CreateOrganisation />} />
            <Route path={routes.resetPassword} element={<HubResetPassword />} />
          </Route>
          <Route element={<PortalShell />}>
            <Route index element={<RequestHandler />} />
            <Route path="*" element={<Navigate to={routes.search} replace />} />
            <Route path={routes.insightReport} element={<InsightReport />} />
            <Route path={routes.login} element={<Login />} />
            <Route path={routes.resetPassword} element={<ResetPassword />} />
            <Route path={routes.gettingStarted} element={<GettingStarted />} />
            <Route path={routes.search} element={<Search />} />
            <Route path={routes.reports} element={<Reports />} />
            <Route path={routes.users} element={<Users />} />
            <Route path={routes.groups} element={<Groups />} />
            <Route
              path={`${routes.groups}/:groupId`}
              element={<GroupReports />}
            />
            <Route path={`${routes.users}/:userId`} element={<UserReports />} />
            <Route
              path={`${routes.groups}/:groupId`}
              element={<GroupReports />}
            />
            <Route
              path={`${routes.share}/:enquiryId`}
              element={<SharedReportContainer />}
            />
            <Route
              path={`${routes.diagnostics}/:enquiryId`}
              element={<Diagnostics />}
            />
            <Route path={routes.report}>
              <Route
                path={`${routes.preparing}/:enquiryId`}
                element={<PreparingReportContainer />}
              />
              <Route path=":enquiryId/:personaId" element={<ReportContainer />} />
              <Route
                  path={`:enquiryId`}
                  element={<EnquirySummary />}
              />
            </Route>
          </Route>
        </Route>
      </ApmRoutes>
    </BrowserRouter>
  );
};

export default Router;
