import React, {
  FC,
  SVGProps,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { Switch } from "@mui/material";
import { Spinner } from "reactstrap";

import ModalContainer from "components/molecules/ModalContainer";

import { useUserOrganisation } from "util/hooks/useUserOrganisation";
import Input from "components/atoms/Input";
import { UserOrganisationActions } from "util/hooks/useUserOrganisation/types";
import ErrorBanner from "components/atoms/ErrorBanner";
import { useOrganisationUsage } from "util/hooks/useOrganisationUsage";
import { UsageLoadingStates } from "util/hooks/useOrganisationUsage/types";
import { useAuthentication } from "util/hooks/useAuthentication";

import ReportUsagePanel from "components/molecules/ReportUsagePanel";
import { OrganisationUsage } from "api/organisations/types";
import ModalSection from "components/atoms/ModalSection";
import ModalSectionHeader from "components/atoms/ModalSectionHeader";
import Heading from "components/atoms/Heading";
import useOrganisationPreferences from "util/hooks/useOrganisationPreferences";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import { AuthenticationStatus } from "util/hooks/useAuthentication/types";

import S from "./styles";

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
}

enum UsageTimePeriod {
  ReportsRunPast30Days = "Past 30 days",
  ReportsRunPast7Days = "Past 7 days",
  ReportsRunPastYear = "Past year",
  ReportsRunSinceOrganisationCreation = "Since account opened"
}

const ManageOrganisationModal = ({ isOpen, toggleOpen }: Props) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const {
    state: {
      status: authStatus,
      permissions: { canGetOrganisationUsage, canEditMfa }
    }
  } = useAuthentication();

  const {
    details,
    error: organisationDetailsError,
    saved,
    saving,
    dispatch
  } = useUserOrganisation();

  const {
    downloadUsage,
    usage,
    dismissError: dismissUsageError
  } = useOrganisationUsage();

  const {
    projectRefEnabled,
    inviteUsersEnabled,
    mfaEnabled,
    loaded,
    toggleProjectRefEnabled,
    toggleInviteUsersEnabled,
    toggleMfaEnabled,
    initialisePreferences
  } = useOrganisationPreferences();

  if (!loaded && authStatus === AuthenticationStatus.authenticated) {
    initialisePreferences();
  }

  const [description, setDescription] = useState<string | undefined>();

  useEffect(() => {
    if (details.loaded && saved) {
      toggleOpen();
      dispatch({ type: UserOrganisationActions.resetSavedStatus });
    }
    // @ts-ignore
  }, [details.description, details.loaded, dispatch, saved, toggleOpen]);

  useEffect(() => {
    if (details.loaded) {
      setDescription(details.description ?? "");
    }
    // @ts-ignore
  }, [details.description, details.loaded]);

  const usageOptions: Record<string, number> = useMemo(
    () => ({
      [UsageTimePeriod.ReportsRunPastYear]: (usage as OrganisationUsage)
        .reportsRunPastYear,
      [UsageTimePeriod.ReportsRunPast30Days]: (usage as OrganisationUsage)
        .reportsRunPast30Days,
      [UsageTimePeriod.ReportsRunPast7Days]: (usage as OrganisationUsage)
        .reportsRunPast7Days,
      [UsageTimePeriod.ReportsRunSinceOrganisationCreation]: (
        usage as OrganisationUsage
      ).reportsRunSinceOrgCreated
    }),
    [usage]
  );

  const orgName = (details.loaded && details.name) || "";

  const usageError =
    usage.loadingState === UsageLoadingStates.loadingFailed
      ? usage.error
      : undefined;

  const [selectedUsageOption, setSelectedUsageOption] = useState<string>(
    UsageTimePeriod.ReportsRunPastYear
  );

  const usageNumber =
    usage.loadingState === UsageLoadingStates.loaded
      ? usageOptions[selectedUsageOption]
      : undefined;

  const dismissDetailsError = useCallback(
    () => dispatch({ type: UserOrganisationActions.dismissError }),
    [dispatch]
  );

  const onUsageOptionSelect = useCallback(
    (usageOption: string) => {
      setSelectedUsageOption(usageOption);
    },
    [setSelectedUsageOption]
  );

  const onDownloadUsage = () => {
    setIsDownloading(true);

    downloadUsage(() => setIsDownloading(false));
  };

  const onCloseModal = (save = false) => {
    if (save && details.loaded && details.description !== description) {
      dispatch({
        type: UserOrganisationActions.saveDescription,
        description: description ?? ""
      });
    } else {
      toggleOpen();
      dismissDetailsError();
      // Reset description on close
      setDescription(details.loaded ? details.description : "");
    }
  };

  const onOrgDescriptionChange = (value: string) => {
    setDescription(value);
  };

  const renderOrganisationDetails = () => {
    return (
      <>
        <Input
          inputType="text"
          placeholder="Description (optional)"
          label="Description (optional)"
          onChange={onOrgDescriptionChange}
          value={description === undefined ? "Loading..." : description}
        />
        {canGetOrganisationUsage && organisationDetailsError && (
          <ErrorBanner
            text={organisationDetailsError}
            onClick={dismissDetailsError}
          />
        )}
      </>
    );
  };

  const renderInviteUserPermission = () => {
    return (
      <ModalSection>
        <ModalSectionHeader>
          <Heading level={5}>Users can invite new users</Heading>
          <Switch
            checked={inviteUsersEnabled}
            onChange={toggleInviteUsersEnabled}
          />
        </ModalSectionHeader>
        <S.Text>
          When switched on, all users can invite new users. Admins can always
          invite new users.
        </S.Text>
      </ModalSection>
    );
  };

  const renderMFA = () => {
    return (
      <ModalSection>
        <ModalSectionHeader>
          <Heading level={5}>Multi-factor authentication (MFA)</Heading>
          <S.MfaButton
            isEnabled={mfaEnabled}
            text={mfaEnabled ? "Disable" : "Enable"}
            type={mfaEnabled ? ButtonType.Filled : ButtonType.Outlined}
            size={ButtonSize.Medium}
            onClick={toggleMfaEnabled}
          />
        </ModalSectionHeader>
        <S.Text>
          When enabled, all users will be required to use multi-factor
          authentication.
        </S.Text>
      </ModalSection>
    );
  };

  const renderCustomerSuccessAssistance = () => {
    return (
      <ModalSection>
        <ModalSectionHeader>
          <Heading level={5}>Customer Success assistance</Heading>
          <Switch disabled />
        </ModalSectionHeader>
        <S.Text>
          When switched on, the Xapien Customer Success team have admin access.
        </S.Text>
      </ModalSection>
    );
  };

  const renderProjectReference = () => {
    return (
      <ModalSection>
        <ModalSectionHeader>
          <Heading level={5}>Project references</Heading>
          <Switch
            checked={projectRefEnabled}
            onChange={toggleProjectRefEnabled}
          />
        </ModalSectionHeader>
        <S.Text>
          When switched on, users can choose to enter a project reference for
          each new report.
        </S.Text>
      </ModalSection>
    );
  };

  return (
    <ModalContainer
      title={orgName}
      onExitClick={() => onCloseModal()}
      isOpen={isOpen}
      toggleOpen={() => onCloseModal()}
    >
      <S.ModalContent>
        {renderOrganisationDetails()}
        {renderInviteUserPermission()}
        {renderProjectReference()}
        {renderCustomerSuccessAssistance()}
        {canEditMfa && renderMFA()}
        {canGetOrganisationUsage && (
          <ReportUsagePanel
            usage={usageNumber ?? 0}
            isDownloadingUsage={isDownloading}
            onDownloadUsage={onDownloadUsage}
            usageOptions={usageOptions}
            selectedUsageOption={selectedUsageOption}
            onUsageOptionSelect={onUsageOptionSelect}
          />
        )}
      </S.ModalContent>
      <S.SubmitButton
        text="Done"
        type={ButtonType.Filled}
        size={ButtonSize.Medium}
        onClick={() => onCloseModal(true)}
        disabled={saving}
        IconTrailing={
          saving
            ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
            : undefined
        }
      />
      {usageError && (
        <ErrorBanner text={usageError} onClick={dismissUsageError} />
      )}
    </ModalContainer>
  );
};

export default ManageOrganisationModal;
