import React, { useState, useContext, useLayoutEffect } from "react";
import { inject, observer } from "mobx-react";

import ReportSectionMenu from "pages/report/ReportSectionMenu";
import { scrollToTopOfComponent, formatDate } from "util/common";
import {
  GlobalPrintableStateContext,
  PRINTABLE_STATE_TYPES
} from "util/hooks/usePrintableState";
import XapienLogo from "img/logo.svg";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import useViewerMode from "util/hooks/useViewerMode";
import {
  useVersion,
  significantChangeVersions,
  Version
} from "services/version";
import ExportButton from "components/molecules/ExportButton";
import Datalist from "components/atoms/Datalist";
import { REPORT_TYPES } from "util/reportTypes";
import TruncateLength from "util/TruncateLength";
import { NAME_ONLY_SEARCH_ROUTE } from "util/nameOnlySearchRoute";
import AssertionSummary from "components/atoms/AssertionSummary";
import { AssertionsContext } from "util/hooks/useAssertionsContext";
import { constructSearchUrl } from "util/constructSearchUrl";
import { ENTITY_TYPE } from "pages/define/utils";
import IssueWarningBanner from "components/atoms/IssueWarningBanner";
import ReportAccessButton from "components/organisms/ReportAccessButton";
import { Permission } from "components/organisms/ReportAccessButton/types";
import useUserSettings from "util/hooks/useUserSettings";
import ShareReportButton from "components/molecules/ShareReportButton";
import { ShareReportContextProvider } from "util/hooks/useShareReport/provider";
import { routes } from "pages/Router";

import theme from "theme";

import Reports from "api/reports";
import { usePrintModeEnabled } from "util/hooks/useIsPrintModeEnabled";
import S, { classNameOverrides } from "./styles";

const MAX_SUBJECT_CONTEXT_LENGTH = 40;

const TOP_OFFSET_PIXEL_VALUE = 198;

const EXTRA_OFFSET_FOR_VIEWER_MODE_BANNER = 54;

const getSubjectTypeString = subjectType => {
  switch (subjectType) {
    case REPORT_TYPES.organisation:
      return "Organisation";
    case REPORT_TYPES.person:
      return "Person";
    default:
      return "Unknown entity type";
  }
};

const PrintHeader = props => {
  const {
    generationDate,
    subject,
    contextsAsString,
    reportCreatorEmail,
    generationTime,
    subjectType
  } = props;
  try {
    const printDetailsPadLength = 50;
    const printDetails = [
      ["Search date:", generationDate],
      [`${getSubjectTypeString(subjectType)}:`, subject],
      ["Context:", contextsAsString],
      ["Search generated by:", reportCreatorEmail],
      ["Search duration:", generationTime]
    ].map(detail => [detail[0].padEnd(printDetailsPadLength), detail[1]]);
    return (
      <div className="report-print-header">
        <div className="report-detail-keys">
          {printDetails.map(d => (
            <div key={d[0]}>{d[0]}</div>
          ))}
        </div>
        <div className="report-detail-values">
          {printDetails.map(d => (
            <div key={d[0]}>{d[1]}</div>
          ))}
        </div>
        {theme.reportHeader?.logo ?? (
          <img src={XapienLogo} tabIndex="-1" alt="Xapien logo" />
        )}
      </div>
    );
  } catch (e) {
    const errorMessage = "There was an error building the report print header";
    console.error(errorMessage, e);
    return <div className="report-print-header">{errorMessage}</div>;
  }
};

const RenderHeader = props => {
  const [enableStickyHeader, setEnableStickyHeader] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const diagnosticsMode = useContext(DiagnosticsModeContext).enabled;
  const { isViewerModeEnabled } = useViewerMode();
  const [expandAllSectionsForExport, setExpandAllSectionsForExport] =
    useState(false);

  // Attach sticky behavior at this scrolling point
  const stickyActiveHeight =
    TOP_OFFSET_PIXEL_VALUE +
    (isViewerModeEnabled ? EXTRA_OFFSET_FOR_VIEWER_MODE_BANNER : 0);

  const {
    generationDate,
    generationTime,
    subject,
    contextsAsString,
    report,
    menuItems,
    reportStore,
    enquiryStore,
    defineStore,
    reportCreatorEmail,
    contextValues,
    subjectType,
    contextsWithTypes
  } = props;
  const enquiryId = enquiryStore.currentEnquiryId;
  const activeFeatures = (report && report.activeFeatures) || [];
  const { nameOnlySearch } = defineStore;
  const {
    reportMeta: { owner, permissions, imageUrl, project }
  } = reportStore;
  const {
    state: {
      userDetails: { userId }
    }
  } = useUserSettings();

  const getReportPermission = () => {
    if (owner.userId === userId) {
      return Permission.Owner;
    }
    if (permissions.canEdit) {
      return Permission.Editor;
    }
    return Permission.Viewer;
  };

  const scrollToLink = (activeLinkTitle, navMenuItems) => {
    navMenuItems
      .filter(i => !!i)
      .forEach(item => {
        if (item.title === activeLinkTitle) {
          scrollToTopOfComponent(item.linkRef, true);
        }
      });
  };

  const version = useVersion();

  const printModeEnabled = usePrintModeEnabled();

  const renderReportDetails = () => {
    return (
      <S.ReportDetailsContainer>
        <S.ReportDetails>
          <span>
            <TruncateLength
              position="bottom"
              length={MAX_SUBJECT_CONTEXT_LENGTH}
              text={`${getSubjectTypeString(subjectType)}: ${subject}`}
            />
          </span>
          <span>
            <TruncateLength
              position="bottom"
              length={MAX_SUBJECT_CONTEXT_LENGTH}
              text={`Context: ${decodeURI(contextsAsString)}`}
            />
          </span>
          <span>{generationDate} </span>
          {generationTime ? <span>{generationTime} </span> : ""}
          <S.InfoIcon
            interactive
            infoContent={
              <S.TooltipContent>
                <S.TooltipHeader>Search details</S.TooltipHeader>
                <S.TooltipBody>
                  <S.SearchDetails>
                    <Datalist
                      shouldDataValuesWrap
                      dataFieldClassName={classNameOverrides.dataField}
                      fieldList={[
                        { title: "Search date", value: generationDate }
                      ]}
                    />
                    <Datalist
                      shouldDataValuesWrap
                      dataFieldClassName={classNameOverrides.dataField}
                      fieldList={[
                        { title: "Search duration", value: generationTime }
                      ]}
                    />
                  </S.SearchDetails>
                  <Datalist
                    shouldDataValuesWrap
                    dataFieldClassName={classNameOverrides.dataField}
                    fieldList={[
                      {
                        title: "Search generated by",
                        value: reportCreatorEmail
                      }
                    ]}
                  />
                  <Datalist
                    shouldDataValuesWrap
                    dataFieldClassName={classNameOverrides.dataField}
                    fieldList={[
                      {
                        title: "Project reference",
                        value: project?.reference
                      }
                    ]}
                  />
                </S.TooltipBody>
                <S.TooltipHeader>Search terms</S.TooltipHeader>
                <S.TooltipBody>
                  <Datalist
                    shouldDataValuesWrap
                    dataFieldClassName={classNameOverrides.dataField}
                    fieldList={[
                      {
                        title: getSubjectTypeString(subjectType),
                        value: subject
                      },
                      {
                        title: "Context",
                        value: (
                          <div>
                            {contextValues.map(context => (
                              <div key={context}>{decodeURI(context)}</div>
                            ))}
                          </div>
                        )
                      }
                    ]}
                  />
                </S.TooltipBody>
              </S.TooltipContent>
            }
            tooltipContentClassName={classNameOverrides.infoIconTooltipContent}
            tooltipAlignment="bottom"
          />
          {diagnosticsMode ? (
            <>
              Generation Version: {version ? version.display : "pre-1.8"}
              <div className="feature-switches">
                {activeFeatures.map(f => (
                  <div key={f}>{f}</div>
                ))}
              </div>
            </>
          ) : (
            false
          )}
        </S.ReportDetails>
        {!printModeEnabled && (
          <ReportAccessButton
            permission={getReportPermission()}
            owner={owner?.email}
            reportId={enquiryId}
          />
        )}
      </S.ReportDetailsContainer>
    );
  };

  const enableSticky = (currentPosition, stickyStartPosition) => {
    return currentPosition > stickyStartPosition;
  };
  const currentScrollPosition = () => {
    const target = document.body;
    const position = target.getBoundingClientRect();
    return parseInt(position.top, 10) * -1;
  };
  const throttle = (callback, limit, currentScrollPos = null) => {
    if (currentScrollPos < stickyActiveHeight) {
      return (...args) => callback(...args);
    }

    let wait = false;
    return (...args) => {
      if (!wait) {
        callback(...args);
        wait = true;
        setTimeout(() => {
          wait = false;
        }, limit);
      }
    };
  };
  useLayoutEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = currentScrollPosition();
      if (!enableStickyHeader && currentScrollPos > stickyActiveHeight) {
        setEnableStickyHeader(
          enableSticky(currentScrollPosition(), stickyActiveHeight)
        );
      } else if (enableStickyHeader && currentScrollPos < stickyActiveHeight) {
        setEnableStickyHeader(
          enableSticky(
            currentScrollPosition(),
            stickyActiveHeight,
            currentScrollPos
          )
        );
      }
    };
    window.addEventListener("scroll", throttle(handleScroll, 50));

    return () =>
      window.removeEventListener("scroll", throttle(handleScroll, 50));
  });

  /** export button - START */
  const exportToPdf = (reportEnquiryId, state, shareToken, stateId) => {
    return reportStore.exportToPdf(reportEnquiryId, state, shareToken, stateId);
  };

  const printState = useContext(GlobalPrintableStateContext).state;
  const [downloadReady, setDownloadReady] = useState(undefined);
  const [exporting, setExporting] = useState(reportStore.exporting);

  const reportsApi = new Reports();

  const print = async e => {
    const shareTokenPromise = reportsApi.generateShareToken(enquiryId, true);

    // If the user has selected to expand all sections by default, set all section expansion states
    const processedPrintState = new Map();
    if (expandAllSectionsForExport) {
      printState.forEach((value, key) => {
        processedPrintState.set(key, {
          value:
            value.type === PRINTABLE_STATE_TYPES.sectionExpand
              ? true
              : value.value,
          type: value.type
        });
      });
    } else {
      printState.forEach((value, key) => {
        processedPrintState.set(key, value);
      });
    }

    const stateId = await reportsApi.setPDFMetadata(
      enquiryId,
      processedPrintState
    );
    const shareToken = (await shareTokenPromise).response;

    if (e && e.altKey) {
      // use alt key to debug report printable state
      const url = `../share/${enquiryId}?token=${shareToken.token}&stateId=${stateId}&isForPDFExport=true`;
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.target = "_blank";
      tempLink.click();
    } else if (downloadReady) {
      setTimeout(() => setDownloadReady(undefined), 1000);
      return true;
    } else {
      setExporting(true);
      try {
        const urlPromise = exportToPdf(
          enquiryId,
          Object.fromEntries(printState),
          shareToken.token,
          stateId
        );

        const url = await urlPromise;
        if (url && url.length) {
          const { subject: reportSubject } = report;
          const fileName = `Xapien-report-${reportSubject}.pdf`;
          const download = { url, fileName };
          setDownloadReady(download);
        }
      } finally {
        setExporting(false);
      }
    }
    return null;
  };

  const downloadPdf = () => {
    window.open(downloadReady.url, "#", "noopener,noreferrer");
  };
  /** export button - END */

  const renderTopSection = (instance, isMenuHidden = false) => {
    return (
      <div className="top-section">
        <div className="left">
          <div className="report-title font__weight--light">
            <TruncateLength>
              {report.fullName || report.subject || "Report"}
            </TruncateLength>
          </div>
        </div>
        <div className="right">
          <a
            href={nameOnlySearch ? NAME_ONLY_SEARCH_ROUTE : "/"}
            className="report-header__logo"
            target="_blank"
            rel="noopener noreferrer"
          >
            {theme.reportHeader?.logo ?? (
              <img
                src={XapienLogo}
                className="report-header__logo"
                alt="Xapien logo"
              />
            )}
          </a>
          {!printModeEnabled && (
            <ul className="report-menu-items">
              {report && !isViewerModeEnabled ? (
                <ShareReportContextProvider reportId={enquiryId}>
                  <ShareReportButton
                    authorId={owner.userId}
                    authorName={`${owner.firstName} ${owner.lastName}`}
                    authorEmail={owner.email}
                    reportId={enquiryId}
                    reportSubject={subject}
                    reportContext={contextsAsString}
                    reportImageSrc={imageUrl}
                    permissions={permissions}
                  />
                </ShareReportContextProvider>
              ) : null}
              {report && !isViewerModeEnabled ? (
                <ExportButton
                  downloadReady={downloadReady}
                  exporting={exporting}
                  print={print}
                  instance={instance}
                  downloadPdf={downloadPdf}
                  expandAllSectionsForExport={expandAllSectionsForExport}
                  onExpandAllSectionsForExport={setExpandAllSectionsForExport}
                />
              ) : null}
              <li className="nav-item">
                <div>
                  <ReportSectionMenu
                    menuItems={menuItems}
                    scrollToLink={scrollToLink}
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                    isMenuHidden={isMenuHidden}
                  />
                </div>
              </li>
            </ul>
          )}
        </div>
      </div>
    );
  };

  const { assertions } = useContext(AssertionsContext);
  const renderAssertionSummary = () => {
    return (
      <div className="assertion-summary">
        <AssertionSummary assertions={assertions} />
      </div>
    );
  };

  const renderSystemIssueWarnings = () => {
    // Backwards compat check: backend will now filter what system issue messages are fed to the
    // frontend. The issue is that older generated reports won't have their system issues filtered, so all system issues messages
    // will be present in the JSON for the frontend to display – we don't want this, we only want to display
    // the issues on reports that _have_ had their system issues filtered i.e. after this version
    if (!version.isAfterOrEqualTo(new Version(2, 3, 1))) {
      return null;
    }

    return report?.searchErrorInfo?.map(
      ({ impactOfError, diagnosticsOnly }) => (
        <IssueWarningBanner
          heading="Missing data warning"
          message={impactOfError}
          diagnosticsOnly={diagnosticsOnly}
        />
      )
    );
  };

  const generationVersion = useVersion();
  const renderOldReportBanner = () => {
    if (
      significantChangeVersions.every(v =>
        generationVersion.isAfterOrEqualTo(v)
      )
    ) {
      return null;
    }

    let searchUrl = "";

    if (contextsWithTypes?.length) {
      searchUrl = constructSearchUrl({
        subject: report.reportMetadata?.subject,
        contextList: contextsWithTypes?.map(context => {
          return {
            value: context.item,
            type:
              (context.type?.slice(0, 1).toLowerCase() ?? "") +
              (context.type?.slice(1) ?? "")
          };
        }),
        subjectType,
        nameOnlySearch,
        autorun: false
      });
    }

    const emailSubject = encodeURI(
      `Updating report - ${window.location.pathname.split("/")[2]}`
    );
    const emailBody = encodeURI(`Dear support, \n\n`);

    const getReportGenTime = () => {
      if (
        report.reportMetadata?.subjectType?.toLowerCase() ===
        ENTITY_TYPE.Organisation?.toLowerCase()
      ) {
        return "10-20";
      }
      return "5-10";
    };

    return (
      <S.OldReportPrompt backgroundColor={theme.primaryColor}>
        <S.RegenIcon fill={theme.primaryColor} />
        <S.PromptText>
          <strong>
            This report was generated using an older version of Xapien.
          </strong>
          <br />
          Some things may not appear or work as expected. To solve this, simply{" "}
          <S.Link
            color={theme.darkPrimaryColor}
            href={`/${routes.search}${searchUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            re-run the report
          </S.Link>{" "}
          (this will take {getReportGenTime()} minutes and will not use any
          credits). Any questions - just email the{" "}
          <S.Link
            color={theme.darkPrimaryColor}
            href={`mailto:support@xapien.com?subject=${emailSubject}&body=${emailBody}`}
            rel="noopener noreferrer"
          >
            customer success team
          </S.Link>
          , who will be happy to help you.
        </S.PromptText>
      </S.OldReportPrompt>
    );
  };

  return (
    <div className="report-header">
      <div className="top-sticky-container">
        <div
          className={`report-header-top-sticky ${
            enableStickyHeader ? "report-header-top-sticky--active" : ""
          } `}
        >
          <div className="header-top-information">{renderTopSection(0)}</div>
        </div>
      </div>
      <div
        className={`header-top-information ${
          enableStickyHeader ? "hide-menu" : ""
        }`}
      >
        {renderTopSection(1, enableStickyHeader)}
        {renderReportDetails(enableSticky)}
        {renderAssertionSummary()}
        {renderSystemIssueWarnings()}
        {renderOldReportBanner()}
      </div>
    </div>
  );
};

const ReportHeader = props => {
  try {
    const report = props.report ? props.report : props.reportStore.report;

    if (!report) {
      return <div />;
    }

    const {
      reportStore,
      enquiryStore,
      menuItems,
      defineStore,
      contextsWithTypes,
      subjectType
    } = props;
    const generationDate = formatDate(report.preparedAtUtc);
    const subject = report && report.subject;

    // Todo: Cleanup/Remove old format after successful testing
    // const contextLegacyRegExp = new RegExp("\\((.*)\\)");
    const contextLegacyRegExp = /\((.*)\)/;

    // Backwards compat for contexts that are an array of strings vs. objects
    const contextValues = contextsWithTypes?.map(context => {
      if (typeof context === "object" && context !== null) {
        return context.item;
      }
      return context;
    });

    const contextsAsString =
      report &&
      (contextValues?.join(", ") ||
        (report.preparedBy && contextLegacyRegExp.exec(report.preparedBy)[1]));
    const generationTime = report.preparationTime
      ? `${report.preparationTime.minutes} min ${report.preparationTime.seconds} sec`
      : false;
    const reportCreatorEmail = report?.reportMetadata?.userEmail;

    return (
      <>
        <PrintHeader
          {...{
            report,
            generationDate,
            subject,
            contextsAsString,
            reportCreatorEmail,
            generationTime,
            subjectType
          }}
        />
        <RenderHeader
          {...{
            generationDate,
            subject,
            contextsAsString,
            reportCreatorEmail,
            report,
            reportStore,
            enquiryStore,
            generationTime,
            menuItems,
            defineStore,
            contextValues,
            contextsWithTypes,
            subjectType
          }}
        />
      </>
    );
  } catch (e) {
    console.error("There was an error showing the report header", e);
    return <div className="report-header" />;
  }
};

export default inject(
  "reportStore",
  "enquiryStore",
  "sharedUserInterfaceStore",
  "defineStore"
)(observer(ReportHeader));
