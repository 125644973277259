import React, { useState } from "react";

import Lottie from "lottie-react";

import {
  InsightReportActions,
  useInsightReport
} from "util/hooks/useInsightReport";

import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";

import invitedToThePreviewImage from "img/xi-invited-to-the-preview.jpg";
import notPerfectImage from "img/xi-not-perfect.jpg";
import hearFromYouImage from "img/xi-hear-from-you.jpg";

import confettiAnimation from "./confetti.json";

import S from "./styles";

const NextButton = ({
  onNext,
  hasNext,
  onFinish
}: {
  onNext: () => void;
  hasNext: boolean;
  onFinish: () => void;
}) => {
  const handleOnClick = () => {
    if (!hasNext) {
      onFinish();
    } else {
      onNext();
    }
  };

  return (
    <S.NextButtonContainer>
      <S.NextButton
        type={ButtonType.Filled}
        size={ButtonSize.Large}
        onClick={handleOnClick}
        text={hasNext ? "Next" : "Let's go"}
      />
    </S.NextButtonContainer>
  );
};

const WelcomeDisclaimer = () => {
  const { dispatch } = useInsightReport();
  const [isFinished, setIsFinished] = useState(false);

  const onFinish = () => {
    setIsFinished(true);

    setTimeout(() => {
      dispatch({ type: InsightReportActions.hideWelcomeDisclaimer });
    }, 1000);
  };

  const slides = [
    {
      slug: "invitedToThePreview",
      image: invitedToThePreviewImage,
      title: "You're invited to the preview",
      description:
        // eslint-disable-next-line max-len
        "Xapien Insights uses advanced technology and generative AI to summarise what we learned about your subject, quickly identify potential risks and answer questions"
    },
    {
      slug: "notPerfectYet",
      image: notPerfectImage,
      title: "It is not perfect yet",
      description:
        // eslint-disable-next-line max-len
        "It only contains web and media content. Information may be misleading or inaccurately phrased. We encourage you to check the sourcing of anything that looks important so you can validate it."
    },
    {
      slug: "hearFromYou",
      image: hearFromYouImage,
      title: "We’d love to hear from you!",
      description:
        // eslint-disable-next-line max-len
        "We offer this as an experimental preview and can’t wait to get your feedback. Please use the hover menus to share your thoughts on what we’ve made."
    }
  ];

  return (
    <S.Container>
      <S.Modal>
        {isFinished && (
          <S.FinishedAnimation>
            <Lottie animationData={confettiAnimation} />
          </S.FinishedAnimation>
        )}
        <S.Carousel
          showThumbs={false}
          showStatus={false}
          renderArrowNext={(onClick: () => void, hasNext: boolean) => (
            <NextButton
              onFinish={onFinish}
              onNext={onClick}
              hasNext={hasNext}
            />
          )}
        >
          {slides.map(({ slug, image, title, description }) => (
            <div key={`WelcomeDisclaimerSlide-${slug}`}>
              <S.ModalImage>
                <img src={image} alt={title} />
              </S.ModalImage>

              <S.ModalContent>
                <S.Heading level={3}>{title}</S.Heading>

                <S.Description>{description}</S.Description>
              </S.ModalContent>
            </div>
          ))}
        </S.Carousel>
      </S.Modal>
    </S.Container>
  );
};

export default WelcomeDisclaimer;
