import React from "react";

import { ButtonKind } from "components/atoms/Button/types";

import S, { classNameOverrides } from "./styles";

const Modal = ({
  headerString,
  body,
  isOpen,
  toggleModal,
  secondaryActionString,
  primaryActionString,
  onSecondaryActionClick,
  onPrimaryActionClick,
  footerTertiaryNode
}) => {
  return (
    <S.Modal
      isOpen={isOpen}
      toggle={toggleModal}
      contentClassName={classNameOverrides.modalContent}
      centered
    >
      <S.ModalHeader
        toggle={toggleModal}
        close={<S.CloseModalButton onClick={toggleModal} />}
      >
        {headerString}
      </S.ModalHeader>
      <S.ModalBody>{body}</S.ModalBody>
      <S.ModalFooter isTertiaryNodePresent={footerTertiaryNode !== undefined}>
        {footerTertiaryNode}
        <div>
          <S.SecondaryButton
            kind={ButtonKind.secondary}
            onClick={onSecondaryActionClick}
          >
            {secondaryActionString}
          </S.SecondaryButton>
          <S.PrimaryButton onClick={onPrimaryActionClick}>
            {primaryActionString}
          </S.PrimaryButton>
        </div>
      </S.ModalFooter>
    </S.Modal>
  );
};

export default Modal;
