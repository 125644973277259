import type { Report } from "api/reports";
import type { User } from "api/users";
import type { Group } from "api/groups";
import type { Organisation } from "api/hub/organisations";
import { SearchResult } from "api/search";

import type { CollectionListView } from "components/molecules/CollectionListControls";

export enum CollectionType {
  report = "report",
  group = "group",
  user = "user"
}

export const COLLECTION_ITEMS_PER_PAGE = 16;

export enum CollectionStatus {
  stale = "stale",
  loading = "loading",
  complete = "complete"
}

export enum CollectionInputType {
  list = "list",
  search = "search"
}
export interface SearchCollectionInput {
  type: CollectionInputType.search;
  query: string;
  searchTags: SearchResult[];
  filterByUserId?: string;
  filterByGroupId?: string;
  filterAllAccessible?: boolean;
}
export interface ListCollectionInput {
  type: CollectionInputType.list;
  filterByRead?: boolean;
  filterByUnread?: boolean;
  excludeGroupSharedReports?: boolean;
  excludeUserSharedReports?: boolean;
  excludeAllAccessibleReports?: boolean;
  filterByUserId?: string;
  filterByGroupId?: string;
}
export type CollectionInput = ListCollectionInput | SearchCollectionInput;

// export type CollectionIndividualItem = Report | User | Group | Organisation;

export interface CollectionItem<T extends {id: string}> {
  id: string;
  title?: string;
  items: T[];
  totalItemCount: number;
  limit: number;
  order: string;
  view: CollectionListView;
  offset: number;
  status: CollectionStatus;
  hidden: boolean;
  hiddenIfEmpty: boolean;
  /** The input info required to get this collection - and required when paging through */
  input: CollectionInput;
  pollingEnabled: boolean;
  filters?: string[];
}

export enum CollectionListActions {
  addCollection = "addCollection",
  addCollectionItem = "addCollectionItem",
  deleteCollectionItem = "deleteCollectionItem",
  disablePolling = "disablePolling",
  enablePolling = "enablePolling",
  hideCollection = "hideCollection",
  moveCollectionItem = "moveCollectionItem",
  refreshCollectionItems = "refreshCollectionItems",
  removeCollection = "removeCollection",
  showCollection = "showCollection",
  updateCollectionFilters = "updateCollectionFilters",
  updateCollectionInput = "updateCollectionInput",
  updateCollectionItem = "updateCollectionItem",
  updateCollectionItems = "updateCollectionItems",
  updateCollectionOrder = "updateCollectionOrder",
  updateCollectionPage = "updateCollectionPage",
  updateCollectionStatus = "updateCollectionStatus"
}

interface EnablePollingAction {
  type: CollectionListActions.enablePolling;
  id: string;
}

interface DisablePollingAction {
  type: CollectionListActions.disablePolling;
  id: string;
}

interface UpdateCollectionFilters {
  type: CollectionListActions.updateCollectionFilters;
  id: string;
  filters: string[];
}

interface UpdateCollectionInput {
  type: CollectionListActions.updateCollectionInput;
  id: string;
  input: CollectionInput;
}

interface UpdateCollectionItemAction<T extends {id: string}> {
  type: CollectionListActions.updateCollectionItem;
  id: string;
  item: Partial<T>;
}

interface AddCollectionItemAction<T extends {id: string}> {
  type: CollectionListActions.addCollectionItem;
  id: string;
  item: T;
}

interface DeleteCollectionItemAction {
  type: CollectionListActions.deleteCollectionItem;
  itemId: string;
}

interface UpdateCollectionStatusAction {
  type: CollectionListActions.updateCollectionStatus;
  id: string;
  status: CollectionStatus;
}

interface UpdateCollectionPageAction {
  type: CollectionListActions.updateCollectionPage;
  id: string;
}

interface UpdateCollectionOrderAction {
  type: CollectionListActions.updateCollectionOrder;
  id: string;
  order: string;
}

interface UpdateCollectionItemsAction<T extends {id: string}> {
  type: CollectionListActions.updateCollectionItems;
  id: string;
  items: T[];
  offset: number;
  totalItemCount: number;
}

interface AddCollectionAction<T extends {id: string}> {
  type: CollectionListActions.addCollection;
  id: string;
  title?: string;
  items: T[];
  totalItemCount: number;
  limit: number;
  order: string;
  offset: number;
  status: CollectionStatus;
  input: CollectionInput;
  hiddenIfEmpty: boolean;
  view: CollectionListView;
  pollingEnabled: boolean;
}

interface RemoveCollectionAction {
  type: CollectionListActions.removeCollection;
  id: string;
}

interface MoveCollectionItemAction {
  type: CollectionListActions.moveCollectionItem;
  itemId: string;
  fromCollectionId: string;
  toCollectionId: string;
}

interface HideCollectionAction {
  type: CollectionListActions.hideCollection;
  id: string;
}

interface ShowCollectionAction {
  id: string;
  type: CollectionListActions.showCollection;
}

interface RefreshCollectionItemsAction<T extends {id: string}> {
  type: CollectionListActions.refreshCollectionItems;
  id: string;
  items: T[];
  totalItemCount: number;
}

export type CollectionListAction =
  | AddCollectionAction<any>
  | AddCollectionItemAction<any>
  | DeleteCollectionItemAction
  | DisablePollingAction
  | EnablePollingAction
  | HideCollectionAction
  | MoveCollectionItemAction
  | RefreshCollectionItemsAction<any>
  | RemoveCollectionAction
  | ShowCollectionAction
  | UpdateCollectionFilters
  | UpdateCollectionInput
  | UpdateCollectionItemsAction<any>
  | UpdateCollectionItemAction<any>
  | UpdateCollectionOrderAction
  | UpdateCollectionPageAction
  | UpdateCollectionStatusAction;

export interface CollectionListState<T extends {id: string}> {
  collections: CollectionItem<T>[];
}
