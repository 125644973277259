import { styled } from "@linaria/react";
import { standardColors, grey, green, blue } from "styles/colors";
import { fontSize } from "styles/fonts";

import { hexToRgb } from "styles/hexToRgb";

import BaseHeading from "components/atoms/Heading";

import { ReactComponent as XapienLogo } from "img/icons/xapien-logo-icon.svg";

const LogoOuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(
    ${() => {
      const { r, g, b } = hexToRgb(green.xapienGreen);
      return `${r}, ${g}, ${b}`;
    }},
    0.15
  );
  width: 80px;
  height: 80px;
  margin-bottom: 4px;
`;

const LogoInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${green.xapienGreen};
  width: 48px;
  height: 48px;
`;

const Logo = styled(XapienLogo)`
  width: 24px;
  height: 24px;

  path {
    fill: ${standardColors.white};
  }
`;

const Heading = styled(BaseHeading)`
  color: ${standardColors.black};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 475px;
  margin: auto;
  padding: 96px 0;
  gap: 8px;
`;

const Paragraph = styled.p`
  text-align: center;
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  margin-bottom: 8px;

  a {
    color: ${blue.icon};
  }
`;

const Recommendation = styled.div`
  background: ${standardColors.white};
  padding: 12px;
  border-radius: 8px;
  text-align: center;
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  margin-bottom: 8px;
`;

const S = {
  Container,
  Paragraph,
  Recommendation,
  Heading,
  Logo,
  LogoOuterContainer,
  LogoInnerContainer
};

export default S;
