import React, { Dispatch, Fragment, ReactNode, SetStateAction } from "react";

import { SUBJECT_CONTEXTS } from "pages/define/utils";

import { Context, EntityType } from "api/enquiry/types";
import { ValidationResponse, ValidationResponseReason } from "api/define/types";
import { ButtonKind } from "components/atoms/Button/types";

import { correctContextTypesForInputs } from "./utils";
import { CorrectedType, InputToValidationStages } from "./types";

import S from "./styles";

export const renderInvalidWebsiteInputInPersonSearch = (
  inputIndicesWithSuggestedTypes: CorrectedType[],
  setInputIndicesWithSuggestedTypes: Dispatch<SetStateAction<CorrectedType[]>>,
  onGoClick: (
    runSearch: boolean,
    modifiedSearchInputs: { contexts?: Record<string, Context> },
    typeToInputValidationStage: InputToValidationStages
  ) => void,
  contexts: Record<string, Context>,
  subjectType: EntityType,
  validateOnly: boolean
) => {
  // Filter for the next step in the validation
  const filteredInputIndicies = inputIndicesWithSuggestedTypes.filter(
    input =>
      input.suggestedType !== SUBJECT_CONTEXTS?.[subjectType]?.webAddress?.type
  );

  const nextValidationStage = filteredInputIndicies?.length
    ? InputToValidationStages.inProgress
    : InputToValidationStages.complete;

  let matchString = "a URL";
  if (
    (inputIndicesWithSuggestedTypes?.length ?? 0) -
      (filteredInputIndicies?.length ?? 0) >
    1
  ) {
    matchString = "URLs";
  }

  return (
    <S.SearchAssistNotification
      notificationType="prompt"
      notificationString={
        <div>
          <S.ErrorNotificationHeadingLine>
            It looks like you have entered {matchString} as context.
          </S.ErrorNotificationHeadingLine>
          <S.ErrorNotificationDetails>
            Is that correct? <br /> (If your context is an organisation, try
            putting the organisation&apos;s name instead)
          </S.ErrorNotificationDetails>
        </div>
      }
      notificationActions={[
        {
          name: "No",
          onClick: () => {
            setInputIndicesWithSuggestedTypes([]);
          },
          kind: ButtonKind.secondary
        },
        {
          name: "Yes",
          onClick: () => {
            setInputIndicesWithSuggestedTypes(filteredInputIndicies);
            onGoClick(
              validateOnly,
              {
                contexts
              },
              nextValidationStage
            );
          },
          kind: ButtonKind.primary
        }
      ]}
      isCloseable
      onCloseNotificationClick={() => {
        setInputIndicesWithSuggestedTypes([]);
      }}
    />
  );
};

export const renderInvalidWebsiteInputNotification = (
  inputIndicesWithSuggestedTypes: CorrectedType[],
  setInputIndicesWithSuggestedTypes: Dispatch<SetStateAction<CorrectedType[]>>,
  onGoClick: (
    runSearch: boolean,
    modifiedSearchInputs: { contexts?: Record<string, Context> },
    typeToInputValidationStage: InputToValidationStages
  ) => void,
  contexts: Record<string, Context>,
  setContexts: Dispatch<SetStateAction<Record<string, Context>>>,
  subjectType: EntityType,
  validateOnly: boolean
) => {
  let matchString = "a URL";
  if (inputIndicesWithSuggestedTypes?.length > 1) {
    matchString = "URLs";
  }

  const onConfirmClick = () => {
    const correctedContextTypes = correctContextTypesForInputs(
      contexts,
      inputIndicesWithSuggestedTypes.filter(
        input =>
          input.suggestedType ===
          SUBJECT_CONTEXTS?.[subjectType]?.webAddress?.type
      ),
      SUBJECT_CONTEXTS?.[subjectType]?.webAddress
    );
    setContexts(correctedContextTypes);
    setInputIndicesWithSuggestedTypes([]);
    onGoClick(
      validateOnly,
      {
        contexts: correctedContextTypes
      },
      InputToValidationStages.complete
    );
  };

  const onCancelClick = () => {
    setInputIndicesWithSuggestedTypes([]);
    onGoClick(
      validateOnly,
      {
        contexts
      },
      InputToValidationStages.complete
    );
  };

  return (
    <S.SearchAssistNotification
      notificationType="prompt"
      notificationString={
        <div>
          <S.ErrorNotificationHeadingLine>
            It looks like you have entered {matchString} as context.
          </S.ErrorNotificationHeadingLine>
          <S.ErrorNotificationDetails>
            Is that correct?
          </S.ErrorNotificationDetails>
        </div>
      }
      notificationActions={[
        {
          name: "No",
          onClick: onCancelClick,
          kind: ButtonKind.secondary
        },
        {
          name: "Yes",
          onClick: onConfirmClick,
          kind: ButtonKind.primary
        }
      ]}
      isCloseable
      onCloseNotificationClick={() => {
        setInputIndicesWithSuggestedTypes([]);
      }}
    />
  );
};

export const renderInvalidLinkedinInputNotification = (
  inputIndicesWithSuggestedTypes: CorrectedType[],
  setInputIndicesWithSuggestedTypes: Dispatch<SetStateAction<CorrectedType[]>>,
  onGoClick: (
    runSearch: boolean,
    modifiedSearchInputs: { contexts?: Record<string, Context> },
    typeToInputValidationStage: InputToValidationStages
  ) => void,
  contexts: Record<string, Context>,
  setContexts: Dispatch<SetStateAction<Record<string, Context>>>,
  subjectType: EntityType,
  validateOnly: boolean
) => {
  // Filter for the next step in the validation
  const filteredInputIndicies = inputIndicesWithSuggestedTypes.filter(
    input =>
      input.suggestedType !==
      SUBJECT_CONTEXTS?.[subjectType]?.socialProfile?.type
  );

  const nextValidationStage = filteredInputIndicies?.length
    ? InputToValidationStages.inProgress
    : InputToValidationStages.complete;

  let matchString = "a LinkedIn profile";
  if (
    (inputIndicesWithSuggestedTypes?.length ?? 0) -
      (filteredInputIndicies?.length ?? 0) >
    1
  ) {
    matchString = "LinkedIn profiles";
  }

  const onConfirmClick = () => {
    const correctedContextTypes = correctContextTypesForInputs(
      contexts,
      inputIndicesWithSuggestedTypes.filter(
        input =>
          input.suggestedType ===
          SUBJECT_CONTEXTS?.[subjectType]?.socialProfile?.type
      ),
      SUBJECT_CONTEXTS?.[subjectType]?.socialProfile
    );
    setContexts(correctedContextTypes);
    setInputIndicesWithSuggestedTypes(filteredInputIndicies);
    onGoClick(
      validateOnly,
      {
        contexts: correctedContextTypes
      },
      nextValidationStage
    );
  };

  const onCancelClick = () => {
    setInputIndicesWithSuggestedTypes(filteredInputIndicies);
    onGoClick(
      validateOnly,
      {
        contexts
      },
      nextValidationStage
    );
  };

  return (
    <S.SearchAssistNotification
      notificationType="prompt"
      notificationString={
        <div>
          <S.ErrorNotificationHeadingLine>
            It looks like you have entered {matchString} as context.
          </S.ErrorNotificationHeadingLine>
          <S.ErrorNotificationDetails>
            Is that correct?
          </S.ErrorNotificationDetails>
        </div>
      }
      notificationActions={[
        {
          name: "No",
          onClick: onCancelClick,
          kind: ButtonKind.secondary
        },
        {
          name: "Yes",
          onClick: onConfirmClick,
          kind: ButtonKind.primary
        }
      ]}
      isCloseable
      onCloseNotificationClick={() => {
        setInputIndicesWithSuggestedTypes(filteredInputIndicies);
      }}
    />
  );
};

export const renderSearchErroredNotification = (
  resetSearchResultsData: () => void,
  onGoClick: () => void
) => {
  const onConfirmClick = () => {
    resetSearchResultsData();
    onGoClick();
  };

  const onCancelClick = () => {
    resetSearchResultsData();
  };

  return (
    <S.SearchAssistNotification
      notificationType="info"
      notificationString={
        <div>
          <S.ErrorNotificationHeadingLine>
            There was an error running your report.
          </S.ErrorNotificationHeadingLine>
          <S.ErrorNotificationDetails>
            Please try again.
          </S.ErrorNotificationDetails>
        </div>
      }
      notificationActions={[
        {
          name: "Cancel",
          onClick: onCancelClick,
          kind: ButtonKind.secondary
        },
        {
          name: "Go",
          onClick: onConfirmClick,
          kind: ButtonKind.primary
        }
      ]}
      isCloseable
      onCloseNotificationClick={() => {
        resetSearchResultsData();
      }}
    />
  );
};

export const renderInsufficientDataSearchErrorNotification = (
  resetSearchResultsData: () => void,
  contexts: Record<string, Context>,
  subjectName: string,
  validationResult: ValidationResponse
) => {
  const isRightToBeForgotten =
    validationResult.reasonCode === ValidationResponseReason.RightToForget;

  const onConfirmClick = () => {
    resetSearchResultsData();
    const contextsAsString = Object.values(contexts)
      .map(y => y.value)
      .join(", ");
    const body = encodeURI(
      `Dear Support,\n\n I am unable to run a search on my subject ${subjectName} with context ${contextsAsString}.`
    );
    window.location.href = `mailto:support@xapien.com?subject=Unable to run search&body=${body}`;
  };

  return (
    <S.SearchAssistNotification
      notificationType="prompt"
      notificationString={
        <div>
          <S.ErrorNotificationHeadingLine>
            We’ve tried our best but cannot generate a report.
          </S.ErrorNotificationHeadingLine>
          <S.ErrorNotificationDetails>
            {isRightToBeForgotten
              ? "It is not possible to run a Xapien report on this subject - please contact Customer Support for more information"
              : "There may be a number of reasons for this. However, we can help you get this report running."}
          </S.ErrorNotificationDetails>
        </div>
      }
      notificationActions={[
        {
          name: "Get in touch…",
          onClick: onConfirmClick,
          kind: ButtonKind.primary
        }
      ]}
      isCloseable
      onCloseNotificationClick={() => {
        resetSearchResultsData();
      }}
    />
  );
};

export const renderSearchConfirmationNotification = (
  resetSearchResultsData: () => void,
  isAcceptedSuggestionsNotificationOpen: boolean,
  onSearchConfirmed: () => void,
  onSearchAssistConfirmed: () => void
) => {
  return (
    <S.SearchAssistNotification
      notificationString={
        <S.NotificationGuidance>
          Suggestion{" "}
          {isAcceptedSuggestionsNotificationOpen ? "accepted" : "rejected"}.
          Click Go to run report.
        </S.NotificationGuidance>
      }
      notificationType="prompt"
      isCloseable
      onCloseNotificationClick={() => {
        resetSearchResultsData();
      }}
      notificationActions={[
        {
          name: "Cancel",
          onClick: () => {
            resetSearchResultsData();
          },
          kind: ButtonKind.secondary
        },
        {
          name: "Go",
          onClick: () => {
            resetSearchResultsData();
            if (isAcceptedSuggestionsNotificationOpen) {
              return onSearchAssistConfirmed();
            }

            return onSearchConfirmed();
          },
          kind: ButtonKind.primary
        }
      ]}
    />
  );
};

const buildSuggestionNotificationString = (suggestionTerms: string[]) => {
  const htmlStringToDisplay: ReactNode[] = [];

  suggestionTerms.forEach((suggestion, index, collection) => {
    if (collection.length === 1) {
      htmlStringToDisplay.push(<b key={suggestion}>{suggestion}</b>);
    } else if (index === collection.length - 1) {
      htmlStringToDisplay.push(
        <Fragment key={suggestion}>
          {" "}
          and <b>{suggestion}</b>
        </Fragment>
      );
    } else {
      htmlStringToDisplay.push(
        <Fragment key={suggestion}>
          {index !== 0 ? "," : null} <b>{suggestion}</b>
        </Fragment>
      );
    }
  });

  return (
    <S.NotificationGuidance>
      Did you mean {htmlStringToDisplay}?
    </S.NotificationGuidance>
  );
};

export const renderSearchSuggestionNotification = (
  shouldContextsBeCorrected: boolean,
  proposedContexts: Record<string, Context> | undefined,
  proposedSubjectName: string | undefined,
  contexts: Record<string, Context>,
  resetSearchResultsData: () => void,
  setIsRejectedSuggestionsNotificationOpen: Dispatch<SetStateAction<boolean>>,
  setIsAcceptedSuggestionsNotificationOpen: Dispatch<SetStateAction<boolean>>,
  setSubjectName: Dispatch<SetStateAction<string>>,
  setContexts: Dispatch<SetStateAction<Record<string, Context>>>
) => {
  let notificationString: ReactNode = "";
  let proposedContextsToDisplay: Context[] = [];
  let proposedContextsToSet: Record<string, Context> = {};
  const suggestionTerms = [];

  if (shouldContextsBeCorrected && proposedContexts) {
    proposedContextsToDisplay = Object.values(proposedContexts);
    // Use original context values if they are missing, so we don't mistakenly set the form field value to nothing.
    proposedContextsToSet = Object.keys(proposedContexts).reduce(
      (acc: Record<string, Context>, contextKey) => {
        const contextObj = proposedContexts[contextKey];
        if (!contextObj.value) {
          acc[contextKey] = contexts[contextKey];
        } else {
          acc[contextKey] = contextObj;
        }
        return acc;
      },
      {}
    );
  }

  if (proposedSubjectName) {
    suggestionTerms.push(proposedSubjectName);
  }

  proposedContextsToDisplay.reduce((acc, ctx) => {
    if (ctx.value) {
      acc.push(ctx.value);
    }
    return acc;
  }, suggestionTerms);

  notificationString = (
    <S.NotificationGuidance>
      {buildSuggestionNotificationString(suggestionTerms)}
    </S.NotificationGuidance>
  );

  return (
    <S.SearchAssistNotification
      notificationString={notificationString}
      notificationType="prompt"
      isCloseable
      onCloseNotificationClick={() => {
        resetSearchResultsData();
      }}
      notificationActions={[
        {
          name: "Ignore suggestion...",
          onClick: () => {
            setIsRejectedSuggestionsNotificationOpen(true);
          },
          kind: ButtonKind.secondary
        },
        {
          name: "Accept suggestion...",
          onClick: () => {
            setIsAcceptedSuggestionsNotificationOpen(true);
            if (proposedSubjectName) {
              setSubjectName(proposedSubjectName);
            }
            if (shouldContextsBeCorrected) {
              setContexts(proposedContextsToSet);
            }
          },
          kind: ButtonKind.primary
        }
      ]}
    />
  );
};
