import React, { ReactNode } from "react";

import { motion } from "framer-motion/dist/framer-motion";

import { CardVariant } from "components/molecules/Card/types";

import S from "./styles";

interface Props {
  control?: ReactNode;
  children: ReactNode;
  variant?: CardVariant;
  loading?: boolean;
  onClick?: () => void;
  className?: string;
}

const ListCard = ({
  control,
  children,
  variant = CardVariant.default,
  onClick = () => {},
  loading,
  className
}: Props) => {
  return loading ? (
    <S.Skeleton>
      <S.ListCardContent>{children}</S.ListCardContent>
    </S.Skeleton>
  ) : (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      style={{ width: "100%" }}
    >
      <S.ListCard
        variant={variant}
        onClick={onClick}
        onKeyDown={e => (e.key === "Enter" ? onClick() : undefined)}
        tabIndex={0}
        className={className}
      >
        <S.ListCardContent>{children}</S.ListCardContent>
        {control && <S.ControlContainer>{control}</S.ControlContainer>}
      </S.ListCard>
    </motion.div>
  );
};

export default ListCard;
