import React from "react";

import List from "pages/report/sections/InsightCentre/List";

import { classNameOverrides } from "./styles";

const CategorisedSection = ({ data = [], title, initialItemsShownCount }) => {
  return (
    <div>
      <List
        list={data}
        title={title}
        titleClassName={classNameOverrides.title}
        className={classNameOverrides.list}
        initialItemsShownCount={initialItemsShownCount}
      />
    </div>
  );
};

export default React.memo(CategorisedSection);
