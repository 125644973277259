import React, { CSSProperties } from "react";

import S from "./styles";

interface Props {
  style?: CSSProperties;
  className?: string;
}

const SectionFooter = ({ style, className }: Props) => {
  return <S.Footer style={style} className={className} />;
};

export default SectionFooter;
