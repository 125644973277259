import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import {
  ModalFooter as ReactStrapModalFooter,
  ModalHeader as ReactStrapModalHeader,
  ModalBody as ReactStrapModalBody,
  Modal as ReactStrapModal
} from "reactstrap";

import CommonButton from "components/atoms/Button";
import { ReactComponent as CrossInCircle } from "img/icons/cross-in-circle.svg";
import { standardColors, grey } from "styles/colors";
import { fontSize } from "styles/fonts";

import theme from "theme";

const MODAL_MAX_WIDTH = 426;

const Modal = styled(ReactStrapModal)`
  max-width: ${MODAL_MAX_WIDTH}px;
`;

const CloseModalButton = styled(CrossInCircle)`
  height: 21px;
  width: 21px;

  &:hover {
    cursor: pointer;
  }

  circle {
    stroke: transparent;
  }

  path {
    fill: ${standardColors.white};
  }
`;

const ModalHeader = styled(ReactStrapModalHeader)`
  height: 32px;
  border-bottom: none;
  padding: 0 16px;
  background-color: ${theme.primaryColor};
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  display: flex;
  align-items: center;

  & > h5 {
    max-width: ${MODAL_MAX_WIDTH}px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: ${fontSize.md};
  }
`;

const ModalBody = styled(ReactStrapModalBody)`
  color: ${grey.mid};
  font-size: ${fontSize.sm};
  padding: 16px 15px 0 15px;
`;

const ModalFooter = styled(ReactStrapModalFooter)`
  border-top: none;
  padding: 16px 15px 16px 15px;
  display: flex;
  justify-content: ${props =>
    props.isTertiaryNodePresent ? "space-between" : "flex-end"};
`;

const PrimaryButton = styled(CommonButton)`
  color: ${props => props.color ?? standardColors.white} !important;
  font-size: ${fontSize.sm};
  min-height: 28px !important;
`;

const SecondaryButton = styled(CommonButton)`
  color: ${theme.primaryColor} !important;
  min-height: 28px !important;
  font-size: ${fontSize.sm};
  margin-right: 8px;

  border-width: 1px;

  &:active {
    border-width: 1px !important;
  }
`;

const modalContent = css`
  border-radius: 11px;
`;

const S = {
  Modal,
  CloseModalButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  PrimaryButton,
  SecondaryButton
};

export const classNameOverrides = {
  modalContent
};

export default S;
