import React, { FC } from "react";
import { Skeleton as MuiSkeleton } from "@mui/material";
import type { SkeletonProps } from "@mui/material";

const Skeleton: FC<SkeletonProps> = props => {
  return (
    <MuiSkeleton {...props} sx={{ backgroundColor: "rgba(255,255,255,0.2)" }} />
  );
};

export default Skeleton;
