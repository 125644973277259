import React, { Fragment, useState, useEffect } from "react";
import type { ContentNode as ContentNodeType } from "api/insight-reports";

import { useInsightReport } from "util/hooks/useInsightReport";
import { InsightReportStatus } from "util/hooks/useInsightReport/types";
import threeBallGif from "img/3-slow-balls.gif";
import GeneratingText from "components/atoms/GeneratingText";
import TextNode from "components/organisms/InsightReport/TextNode";
import WithContextMenu from "components/organisms/InsightReport/WithContextMenu";

import {
  renderAsRichText,
  renderAsPlainText,
  parseListString
} from "components/organisms/InsightReport/utils";

import S from "./styles";

const ContentNode = ({
  id,
  nodeType,
  content,
  value,
  supportingSentences,
  subject,
  section,
  subSection,
  parentIds = [],
  isParentHeading = false,
  isSourced,
  isUnknown
}: ContentNodeType & {
  subject?: string;
  section?: string;
  parentIds?: string[];
  isParentHeading?: boolean;
}) => {
  const [isLoadingSources, setIsLoadingSources] = useState(false);
  const [hasErrorFetchingSources, setHasErrorFetchingSources] = useState(false);
  const { state } = useInsightReport();

  useEffect(() => {
    if (
      state.fetchingSourceNodeId === id &&
      state.status === InsightReportStatus.fetchingSources
    ) {
      setIsLoadingSources(true);
      setHasErrorFetchingSources(false);
    } else {
      setIsLoadingSources(false);
    }
  }, [state, id]);

  useEffect(() => {
    if (
      state.status === InsightReportStatus.errorFetchingSources &&
      state.fetchingSourceNodeId === id
    ) {
      setHasErrorFetchingSources(true);
    } else {
      setHasErrorFetchingSources(false);
    }
  }, [state, id]);

  const renderSubContent = (isHeading?: boolean) =>
    value ||
    content?.map((subContent, index) => (
      <Fragment key={`SubContent-${nodeType}-${index}`}>
        <ContentNode
          parentIds={[...parentIds, id]}
          subject={subject}
          section={section}
          subSection={subSection}
          isSourced={isSourced}
          isParentHeading={
            isHeading !== undefined ? isHeading : isParentHeading
          }
          {...subContent}
        />
      </Fragment>
    ));

  const richTextContent = content
    ? content.map(subContent => renderAsRichText(subContent)).join("")
    : value ?? "";

  const plainTextContent = content
    ? content.map(subContent => renderAsPlainText(subContent)).join("")
    : value ?? "";

  switch (nodeType) {
    case "h1": {
      return <S.Heading1>{renderSubContent(true)}</S.Heading1>;
    }
    case "h2": {
      return <S.Heading2>{renderSubContent(true)}</S.Heading2>;
    }
    case "h3": {
      return <S.Heading3>{renderSubContent(true)}</S.Heading3>;
    }
    case "h4": {
      return <S.Heading4>{renderSubContent(true)}</S.Heading4>;
    }
    case "div": {
      return (
        <WithContextMenu
          id={id}
          subject={subject}
          richText={richTextContent}
          plainText={plainTextContent}
          sectionTitle={section}
        >
          <S.Division>{renderSubContent()}</S.Division>
        </WithContextMenu>
      );
    }
    case "p": {
      return (
        <S.ParagraphContainer>
          {isSourced && state.fetchedSourcesNodeIds.includes(id) && (
            <S.SourcesFetchedSuccessIcon />
          )}
          {hasErrorFetchingSources && <S.SourcesFetchedErrorIcon />}
          {isLoadingSources && <S.ThreeBalls src={threeBallGif} />}
          <S.Paragraph
            hasStatusMessage={isLoadingSources || hasErrorFetchingSources}
          >
            {renderSubContent()}
          </S.Paragraph>
          {hasErrorFetchingSources && (
            <S.ErrorFetchingSourcesMessage>
              Oops! There’s been a hiccup in generating sourcing. Please try
              again later.
            </S.ErrorFetchingSourcesMessage>
          )}
          {isLoadingSources && (
            <GeneratingText>Generating sourcing</GeneratingText>
          )}
        </S.ParagraphContainer>
      );
    }
    case "text": {
      return (
        <TextNode
          id={id}
          showSourcing={!isParentHeading && !isUnknown}
          parentIds={parentIds}
          supportingSentences={supportingSentences}
        >
          {parseListString(value)}
        </TextNode>
      );
    }
    default: {
      return value;
    }
  }
};

export default ContentNode;
