import React from "react";

import { useNavigate } from "react-router-dom";

import { CollectionListView } from "components/molecules/CollectionListControls";
import { CardVariant } from "components/molecules/Card/types";

import CardSkeleton from "components/atoms/CardSkeleton";
import UserCardMenu from "components/organisms/UserCardMenu";
import { UserActionsProvider } from "util/hooks/useUserActions";
import { formatCardTags } from "util/formatCardTags";
import Avatar from "components/atoms/Avatar";
import { AvatarSize } from "components/atoms/Avatar/types";
import { getInitials } from "util/getInitials";

import { collectionCardComponents } from "util/collectionCardComponents";

import type { User } from "api/users";

import { classNameOverrides } from "./styles";

interface Props {
  item?: User;
  view: CollectionListView;
  loading?: boolean;
  variant: CardVariant;
}

const UserCard = ({ item, view, loading, variant }: Props) => {
  const { C } = collectionCardComponents[view];
  const navigate = useNavigate();

  if (loading) {
    return <CardSkeleton view={view} />;
  }

  if (!item) {
    return null;
  }

  const userTags = formatCardTags(item.tags);
  const canClickThrough = item.permissions.canViewReports;

  return (
    <C.Card
      onClick={
        canClickThrough
          ? () => {
              navigate({
                pathname: `/users/${item.id}`,
                search: `?name=${item.title}&description=${item.jobTitle ?? ""}`
              });
            }
          : undefined
      }
      variant={variant}
      control={
        <UserActionsProvider>
          <UserCardMenu user={item} />
        </UserActionsProvider>
      }
      className={
        canClickThrough
          ? classNameOverrides.clickableCard
          : classNameOverrides.card
      }
    >
      <C.CardHeader
        variant={variant}
        title={item.title}
        subtitle={item.jobTitle}
        icon={
          <Avatar avatarSize={AvatarSize.Large} hasBorder>
            {getInitials(item.title)}
          </Avatar>
        }
      />
      <C.CardBody variant={variant} tags={userTags} />
    </C.Card>
  );
};

export default UserCard;
