import React, {
  useMemo,
  useCallback,
  useReducer,
  useEffect,
  FC,
  ReactNode
} from "react";

import {useLocation, useSearchParams} from "react-router-dom";
import { useEnquiryId } from "util/hooks/useEnquiryId";

import InsightReportsApi, { GetReportErrorCodes } from "api/insight-reports";

import type { InsightReportAction } from "./types";
import { InsightReportStatus, InsightReportActions } from "./types";

import {
  InsightReportContext,
  insightReportReducer,
  initialState,
  removeSectionFromReportBySectionId,
  removeSubSectionFromReportBySubSectionId,
  removeElementFromReportByElementId
} from "./context";
import {usePersonaId} from "../usePersonaId";

interface Props {
  children: ReactNode;
}

export const InsightReportProvider: FC<Props> = ({ children }) => {
  const enquiryId = useEnquiryId();
  const personaId = usePersonaId();
  const [searchParams] = useSearchParams();
  const runId = searchParams.get("runId");
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  // @ts-ignore TODO: Fix this after merging this :)
  const [state, reducerDispatch] = useReducer(
    insightReportReducer,
    initialState
  );

  // @ts-ignore TODO: Fix this after merging this :)
  const dispatch = useCallback((action: InsightReportAction) => {
    // @ts-ignore TODO: Fix this after merging this :)
    reducerDispatch(action);
  }, []);

  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  useEffect(() => {
    if (
      state.status === InsightReportStatus.idle &&
      state.report === undefined
    ) {
      dispatch({ type: InsightReportActions.fetchReport });
    }

    if (state.status === InsightReportStatus.regeneratingReport) {
      const api = new InsightReportsApi();

      api
        .regenerateReport({ reportId: enquiryId, personaId: personaId })
        .then(() => {
          dispatch({ type: InsightReportActions.fetchReport });
        })
        .catch(() =>
          dispatch({ type: InsightReportActions.errorFetchingReport })
        );
    }

    if (
      state.status === InsightReportStatus.fetching &&
      state.report === undefined
    ) {
      const api = new InsightReportsApi();

      let apiCall = () => api.getReport({ id: enquiryId, personaId: personaId, runId: runId });

      if (params.has("token")) {
        apiCall = () =>
          api.getReportWithShareToken({
            id: enquiryId,
            shareToken: params.get("token")!
          });
      }

      apiCall()
        .then(({ response: rawReport, message }) => {
          if (rawReport) {
            dispatch({
              type: InsightReportActions.updateReport,
              rawReport,
              reportId: enquiryId
            });
          } else {
            dispatch({
              type: InsightReportActions.errorFetchingReport,
              errorMessage: message
            });
          }
        })
        .catch(e => {
          console.error(e);
          dispatch({ type: InsightReportActions.errorFetchingReport });
        });
    }

    if (
      state.status === InsightReportStatus.errorFetchingReport &&
      state.errorMessage === GetReportErrorCodes.IN_PROGRESS_ERROR
    ) {
      setTimeout(() => {
        dispatch({ type: InsightReportActions.fetchReport });
      }, 10000);
    }

    if (
      state.status === InsightReportStatus.removingReportSection &&
      state.sectionIdToRemove
    ) {
      const api = new InsightReportsApi();

      api
        .removeSection({
          reportId: enquiryId,
          personaId: personaId,
          sectionId: state.sectionIdToRemove
        })
        .then(response => {
          if (response.status) {
            if (!state.report || !state.rawReport || !state.sectionIdToRemove)
              return;

            const rawReport = removeSectionFromReportBySectionId(
              state.rawReport,
              state.sectionIdToRemove
            );

            dispatch({
              type: InsightReportActions.updateReport,
              reportId: enquiryId,
              rawReport
            });
          } else {
            dispatch({ type: InsightReportActions.errorRemovingReportSection });
          }
        });
    }

    if (
      state.status === InsightReportStatus.removingReportSubSection &&
      state.sectionIdToRemove &&
      state.subsectionIdToRemove
    ) {
      const api = new InsightReportsApi();

      api
        .removeSubSection({
          reportId: enquiryId,
          personaId: personaId,
          sectionId: state.sectionIdToRemove,
          subsectionId: state.subsectionIdToRemove
        })
        .then(response => {
          if (response.status) {
            if (
              !state.report ||
              !state.rawReport ||
              !state.sectionIdToRemove ||
              !state.subsectionIdToRemove
            )
              return;

            const rawReport = removeSubSectionFromReportBySubSectionId(
              state.rawReport,
              state.subsectionIdToRemove
            );

            dispatch({
              type: InsightReportActions.updateReport,
              reportId: enquiryId,
              rawReport
            });
          } else {
            dispatch({ type: InsightReportActions.errorRemovingReportSection });
          }
        });
    }

    if (
      state.status === InsightReportStatus.removingReportElement &&
      state.sectionIdToRemove &&
      state.subsectionIdToRemove &&
      state.elementIdToRemove
    ) {
      const api = new InsightReportsApi();

      api
        .removeElement({
          reportId: enquiryId,
          personaId: personaId,
          sectionId: state.sectionIdToRemove,
          subsectionId: state.subsectionIdToRemove,
          elementId: state.elementIdToRemove
        })
        .then(response => {
          if (response.status) {
            if (
              !state.report ||
              !state.rawReport ||
              !state.sectionIdToRemove ||
              !state.subsectionIdToRemove ||
              !state.elementIdToRemove
            )
              return;

            const rawReport = removeElementFromReportByElementId(
              state.rawReport,
              state.elementIdToRemove
            );

            dispatch({
              type: InsightReportActions.updateReport,

              reportId: enquiryId,
              rawReport
            });
          } else {
            dispatch({ type: InsightReportActions.errorRemovingReportSection });
          }
        });
    }
  }, [state, dispatch, enquiryId, params]);

  return (
    <InsightReportContext.Provider value={providerValue}>
      {children}
    </InsightReportContext.Provider>
  );
};
