import React, { FC, useEffect } from "react";

import { InformationSource } from "api/report/report-types";
import { Fragment, Subsection } from "api/insight-reports/types";

import GeneratingText from "components/atoms/GeneratingText";
import WithInspector from "components/organisms/WithInspector";

import { useInsightsSources } from "util/hooks/useInsightsSources";

import { reportStore } from "state/Store";
import S from "./styles";

type SourcedAnswerFragmentProps = {
  fragment: Fragment;
};

const SourcedAnswerFragment: FC<SourcedAnswerFragmentProps> = ({
  fragment
}) => {
  const mapSource = (sourceId: string) => {
    return reportStore.webAndMediaData.find(data => data.sourceId === sourceId);
  };

  const sources = fragment.supportingSourceSentences
    .flatMap(({ text, wamSourceIds }) => {
      const sourcesFromMap = wamSourceIds
        .map(sourceId => {
          const source = mapSource(sourceId);

          if (!source) return undefined;

          const parseSingleDigitDateString = (value: number): string =>
            value < 10 ? `0${value}` : value.toString();

          const publicationDateFull =
            source.datePublished &&
            source.datePublished.day &&
            source.datePublished.month &&
            source.datePublished.year
              ? new Date(
                  `${source.datePublished.year}-${parseSingleDigitDateString(
                    source.datePublished.month
                  )}-${parseSingleDigitDateString(source.datePublished.day)}`
                ).toISOString()
              : "";

          return {
            ...source,
            heading: source.title || source.heading,
            provider: source.publisher || source.provider,
            publicationDateFull,
            readingTime: undefined,
            subHeading: undefined,
            tags: [],
            text
          };
        })
        .filter(Boolean);

      return sourcesFromMap;
    })
    .filter(Boolean) as InformationSource[];

  if (sources.length === 0) {
    return <>{fragment.text}</>;
  }

  return (
    <>
      <WithInspector
        display="inline"
        sources={sources}
        showTopSection={false}
        popoverTitle={`${sources.length} Strongest sources`}
        withPagination
        renderSourceItems={(
          _sourcesArr,
          _,
          sourceContainerRef,
          page,
          paginationComponent
        ) => {
          return (
            <S.SourceItemsContainer ref={sourceContainerRef}>
              {sources[page] && (
                <S.MediaSourceCard
                  {...sources[page]}
                  // TODO: MediaSourceCard needs types
                  // @ts-ignore
                  show
                  key={`${sources[page].url}_${page}`}
                  activeRiskDirectionality="direct"
                  filterOutIndirectTags
                  paginationComponent={paginationComponent}
                />
              )}
            </S.SourceItemsContainer>
          );
        }}
      >
        {fragment.text}
      </WithInspector>
      {fragment.text.trimEnd().endsWith(".") ? " " : ""}
      {/* ^sorts out a spacing issue */}
    </>
  );
};

type InsightsAnswerProps = {
  answer: Subsection;
  isFetchingSources: boolean;
  onFetchSources: (v: boolean) => void;
};

const InsightsAnswer: FC<InsightsAnswerProps> = ({
  answer,
  isFetchingSources,
  onFetchSources
}) => {
  const { sourcedFragments, fetchSources, error } = useInsightsSources({
    answer
  });

  useEffect(() => {
    if (error) {
      onFetchSources(false);
    }
  }, [error, onFetchSources]);

  useEffect(() => {
    if (sourcedFragments && sourcedFragments.length) {
      onFetchSources(false);
    }
  }, [sourcedFragments, onFetchSources]);

  const onInspectorOpen = () => {
    onFetchSources(true);
    fetchSources();
  };

  if (sourcedFragments && sourcedFragments.length) {
    return (
      <div>
        {sourcedFragments.map((frag, index) => (
          <SourcedAnswerFragment key={index} fragment={frag} />
        ))}
      </div>
    );
  }

  if (isFetchingSources && !error) {
    return (
      <S.FetchingSourcesContainer>
        <WithInspector display="inline" hidePopover>
          {answer.elements?.[0].text}
        </WithInspector>
        <GeneratingText>Generating sourcing</GeneratingText>
      </S.FetchingSourcesContainer>
    );
  }

  return (
    <div>
      <WithInspector
        display="inline"
        isInspectorOpen={(open: boolean) => open && onInspectorOpen()}
      >
        {answer.elements?.[0].text}
      </WithInspector>

      {error && (
        <S.ErrorFetchingSourcesMessage>
          Oops! There’s been a hiccup in generating sourcing. Please try again
          later.
        </S.ErrorFetchingSourcesMessage>
      )}
    </div>
  );
};

export default InsightsAnswer;
