import { styled } from "@linaria/react";

import Button from "components/atoms/Button";

import { fontSize } from "styles/fonts";
import { green, grey, standardColors } from "styles/colors";

const SelectablePill = styled(Button as any)<{ filterPillColor: string }>`
  text-transform: capitalize;
  padding: 0px 8px;
  border: 1px solid ${props => props.filterPillColor ?? green.xapienGreen} !important;
  border-color: rgba(31, 204, 192, 0.5);
  border-radius: 11px !important;
  font-size: ${fontSize.sm};
  color: ${props => {
    if (props.isFilterSelected) {
      return standardColors.white;
    }
    return props.filterPillColor ?? grey.dark;
  }} !important;
  background-color: ${props => {
    if (props.isFilterSelected) {
      return props.filterPillColor ?? green.xapienGreen;
    }
    return "transparent";
  }} !important;
  cursor: pointer;
  transition: box-shadow 0.2s;
  max-width: 270px;
  min-height: 21px !important;
  min-width: 0 !important;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px
      ${props =>
        props.filterPillColor
          ? "rgba(239, 114, 115, 0.25)"
          : "rgba(31, 204, 192, 0.25)"} !important;
  }
`;

const SelectablePillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 102px;
  overflow-y: auto;
  padding: 8px;
  cursor: pointer;
`;

const DefaultContainer = styled.div`
  padding: 8px 8px 0 8px;
  font-size: ${fontSize.sm};
  text-align: left;
  margin-bottom: 12px;
  max-height: 100px;
  overflow: auto;
`;

const Selectable = styled.span<{ isFilterSelected: string }>`
  cursor: pointer;

  background-color: ${props =>
    props.isFilterSelected ? "rgba(31, 204, 192, 0.2)" : "transparent"};

  box-shadow: ${props =>
    props.isFilterSelected ? "0 0 1px 0 rgba(31, 204, 192, 0.2)" : "none"};

  &:hover {
    background-color: rgba(31, 204, 192, 0.2);
    box-shadow: 0 0 1px 0 rgba(31, 204, 192, 0.2);
  }
`;

const SelectableItemContainer = styled.div`
  margin-bottom: 10px;
`;

const S = {
  SelectablePill,
  SelectablePillsContainer,
  DefaultContainer,
  Selectable,
  SelectableItemContainer
};

export default S;
