import dayjs from "dayjs";

export const showInsightReport = (
  reportPreparedAtDate: string,
  isPrintMode: string
) => {
  if (isPrintMode) {
    return false;
  }

  const newInsightReportDate = dayjs("2023-09-20");
  const reportDate = dayjs(reportPreparedAtDate);

  return reportDate.isAfter(newInsightReportDate);
};
