import React, { CSSProperties, Fragment, ReactNode, useState } from "react";
import ReactDom from "react-dom";

import Checkbox from "components/atoms/Checkbox";
import TruncateLength from "util/TruncateLength";
import Popover from "components/atoms/Popover";
import ConfirmationForm from "components/molecules/ConfirmationForm";

import S from "./styles";

const HIDE_LINKS_WARNING = "hide-links-warning";

interface Props {
  href: string;
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  popoverClassName?: string;
  ariaLabel?: string;
  disabled?: boolean;
  truncateLink?: boolean;
  renderPopoverWithPortal?: boolean;
  popoverPortalAnchorElement?: Element | DocumentFragment;
}

const SourceLink = ({
  href,
  children,
  className,
  style,
  popoverClassName,
  ariaLabel,
  disabled,
  truncateLink = true,
  renderPopoverWithPortal = false,
  popoverPortalAnchorElement
}: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isDismissChecked, setIsDismissChecked] = useState(false);

  const multipleUrls = Array.isArray(href);

  const togglePopover = () => {
    // If we're closing the popover then reset
    // the checkbox state.
    if (isPopoverOpen) {
      setIsDismissChecked(false);
    }

    setIsPopoverOpen(prevState => !prevState);
  };

  const onConfirmClick = () => {
    if (isDismissChecked) {
      window.localStorage.setItem(HIDE_LINKS_WARNING, "true");
    }

    if (multipleUrls) {
      href.forEach(url => {
        window.open(url, "_blank", "noopener,noreferrer");
      });
    } else {
      window.open(href, "_blank", "noopener,noreferrer");
    }

    togglePopover();
  };

  let linkComponent = (
    <S.Link
      disabled={disabled}
      className={className}
      style={style}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={ariaLabel}
      onClick={e => {
        const linkDirectlyToSource =
          window.localStorage.getItem(HIDE_LINKS_WARNING) === "true";

        if (linkDirectlyToSource) {
          if (multipleUrls) {
            e.preventDefault();
            href.forEach(url => {
              window.open(url, "_blank", "noopener,noreferrer");
            });
          }
          return;
        }

        e.preventDefault();
        togglePopover();
      }}
    >
      {children}
    </S.Link>
  );

  if (truncateLink) {
    linkComponent = <TruncateLength>{linkComponent}</TruncateLength>;
  }

  const renderUrls = () => {
    if (multipleUrls) {
      return (
        <S.UrlsContainer>
          {href.map(url => (
            <S.UrlItem key={url}>{url}</S.UrlItem>
          ))}
        </S.UrlsContainer>
      );
    }

    return href;
  };

  const popover = (
    <Popover
      className={popoverClassName}
      alignment="left"
      position="fixed"
      trigger="click"
      isOpenOverride={isPopoverOpen}
      onRequestClose={() => setIsPopoverOpen(false)}
      content={
        // @ts-ignore TODO convert to ts
        <ConfirmationForm
          header={`Open ${multipleUrls ? "links" : "link"}`}
          body={
            <>
              You are about to open{multipleUrls ? ": " : " "}
              <b>{renderUrls()}</b>
              .<br />
              <S.ConfirmationString>Are you sure?</S.ConfirmationString>
            </>
          }
          footerTertiaryNode={
            <Checkbox
              checked={isDismissChecked}
              onChange={() => setIsDismissChecked(prevState => !prevState)}
              label="Don't ask me again"
            />
          }
          secondaryActionString="Cancel"
          primaryActionString="Confirm"
          togglePopover={togglePopover}
          onPrimaryActionClick={onConfirmClick}
        />
      }
      disableHideOnClip
    >
      {renderPopoverWithPortal ? null : linkComponent}
    </Popover>
  );

  if (renderPopoverWithPortal && popoverPortalAnchorElement) {
    return (
      <>
        {ReactDom.createPortal(popover, popoverPortalAnchorElement)}
        {linkComponent}
      </>
    );
  }

  return popover;
};

export default SourceLink;
