import React, { useContext, memo } from "react";

import AssessmentSelectionControl from "components/molecules/AssessmentSelectionControl";
import { AssessmentOptions } from "pages/report/AssessmentOptions";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import {
  UserVerificationContext,
  useUserVerification
} from "util/hooks/useUserVerification";

import { EntityTypeContext } from "util/context/EntityTypeContext";
import { ViewFDocButton } from "components/organisms/ViewFDocButton";
import { useEnquiryId } from "util/hooks/useEnquiryId";
import StackOpenActive from "./Stack (open) active.svg";
import StackClosedActive from "./Stack (closed) active.svg";
import Translate from "./Translate-active.svg";

export const CardFooter = memo(props => {
  const {
    showAssessment,
    sourceId,
    expand,
    contract,
    stackCount,
    setEmphasis,
    goldStandardAssessment,
    userAssessment,
    originalLanguage,
    translatedToLanguage,
    setShowTranslated,
    showTranslated,
    isInSnapshot,
    isDisregarded,
    paginationComponent,
    sourceUrl
  } = props;
  const subjectType = useContext(EntityTypeContext).toLowerCase();

  const textsByValue = {
    organisation: [
      {
        key: AssessmentOptions.NoUserAssessment,
        value: "Wrong organisation?"
      },
      {
        key: AssessmentOptions.RightPerson,
        value: "Correct organisation"
      },
      {
        key: AssessmentOptions.WrongPerson,
        value: "Wrong organisation"
      }
    ],
    person: [
      {
        key: AssessmentOptions.NoUserAssessment,
        value: "Wrong person?"
      },
      {
        key: AssessmentOptions.RightPerson,
        value: "Correct person"
      },
      {
        key: AssessmentOptions.WrongPerson,
        value: "Wrong person"
      }
    ]
  };

  const toggleExpand = () => {
    if (expand) {
      setEmphasis(true);
      expand();
    } else if (contract) {
      setEmphasis(false);
      contract();
    }
  };

  const diagnosticsModeEnabled = useContext(DiagnosticsModeContext).enabled;
  const [assessment, setAssessment] = useUserVerification(sourceId);
  const [, , , setAssessmentCount] = useContext(UserVerificationContext);
  const enquiryId = useEnquiryId();

  const hasAssessmentBeenSelected =
    assessment && assessment !== AssessmentOptions.NoUserAssessment;

  const onUserAssessment = value => {
    if (value !== AssessmentOptions.NoUserAssessment) {
      if (!hasAssessmentBeenSelected) {
        setAssessmentCount(prev => prev + 1);
      }
    } else if (value === AssessmentOptions.NoUserAssessment) {
      if (hasAssessmentBeenSelected) {
        setAssessmentCount(prev => prev - 1);
      }
    }
    setAssessment(value);
  };

  const mouseEnterStackExpander = () => setEmphasis(true);
  const mouseLeaveStackExpander = () => setEmphasis(false);

  const showTranslateControl =
    translatedToLanguage &&
    originalLanguage &&
    translatedToLanguage.toLowerCase() !== originalLanguage.toLowerCase();

  const getStackImageAlt = () => {
    if (expand) {
      return "Two squares stacked on top of each other and overlapping";
    }

    if (contract) {
      return "Square with an arrow pointing into it";
    }

    return "";
  };

  const getStackImageSrc = () => {
    if (expand) {
      return StackClosedActive;
    }

    if (contract) {
      return StackOpenActive;
    }

    return "";
  };

  return (
    <div className={`di-card-footer ${isInSnapshot && "media-card-footer"}`}>
      <div className="di-card-footer-start">
        {showAssessment ? (
          <AssessmentSelectionControl
            isDisregarded={isDisregarded}
            onUserAssessment={onUserAssessment}
            userAssessment={
              assessment === undefined ? userAssessment : assessment
            }
            textsByValue={textsByValue[subjectType]}
            {...{ goldStandardAssessment, diagnosticsModeEnabled }}
          />
        ) : (
          <div />
        )}
      </div>

      <div className="di-card-translate-control-container">
        {showTranslateControl ? (
          <div className="di-card-translate-control">
            <button
              type="button"
              className={`di-card-language${
                showTranslated ? " di-card-language-active" : ""
              }`}
              onClick={() => setShowTranslated(true)}
              onKeyUp={() => setShowTranslated(true)}
            >
              {translatedToLanguage}
            </button>
            <button
              type="button"
              className="di-card-translate-toggle-button"
              onClick={() => setShowTranslated(!showTranslated)}
            >
              <img
                src={Translate}
                alt="Two arrows in a circle pointing in opposite directions"
              />
            </button>
            <button
              type="button"
              className={`di-card-language${
                !showTranslated ? " di-card-language-active" : ""
              }`}
              onClick={() => setShowTranslated(false)}
            >
              {originalLanguage}
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      <ViewFDocButton sourceUrl={sourceUrl} enquiryId={enquiryId} />
      {/* Ensure we can always collapse if we need to */}
      {(stackCount && stackCount > 1) || contract ? (
        <div className="di-card-footer-end">
          <div className="di-card-stack-expander">
            {stackCount}
            <button
              type="button"
              onMouseEnter={mouseEnterStackExpander}
              onMouseLeave={mouseLeaveStackExpander}
              onClick={toggleExpand}
              className="di-card-stack-expander-image-button"
            >
              <img
                height={30}
                src={getStackImageSrc()}
                alt={getStackImageAlt()}
              />
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
      {paginationComponent && (
        <div className="di-card-footer-end">{paginationComponent}</div>
      )}
    </div>
  );
});
