import { styled } from "@linaria/react";

import { grey } from "styles/colors";
import { fontSize, family } from "styles/fonts";

import AccordionComponent from "components/molecules/Accordion";
import { ReactComponent as PlaceholderImage } from "img/icons/profile-placeholder.svg";
import { DiImage } from "components/molecules/DiImage";

const CardContainer = styled.div`
  margin: 0;
  padding: 3px;
  cursor: default;
  display: flex;
  flex-direction: column;
  flex: 0 0 25%;
  max-width: 25%;
  &:first-child {
    padding-left: 0;
  }
  &:nth-child(4n + 0) {
    padding-right: 0;
  }
  &:nth-child(4n + 1) {
    padding-left: 0;
  }
`;

const CardInnerContainer = styled.div`
  width: 100%;
  max-width: none;
  padding: 16px;
  background-color: ${grey.panel};
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  height: 100%;
  min-width: 11.2rem;
`;

const CardContentContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const CardContent = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CardTopSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  height: 82px;
`;

const CardImageSection = styled.div`
  display: flex;
  position: relative;
  gap: 6px;
`;

const ImageContainer = styled.div`
  width: 95px;
  height: 90px;
`;

const Image = styled(DiImage)`
  height: 74px;
  margin-bottom: 8px;
  width: 74px;
  object-fit: cover;
  border-radius: 3px;
`;

const SocialMediaIcons = styled.div`
  display: flex;
  a {
    line-height: 1;
  }
`;

const RightSideContainer = styled.div`
  display: flex;
`;

const CardSummary = styled.div`
  color: ${grey.mid};
`;

const NameField = styled.div`
  margin-bottom: 4px;
  font-size: ${fontSize.lg};
  font-family: ${family.interDisplay};
  color: ${grey.dark};
`;

const DataFieldValue = styled.div`
  font-size: ${fontSize.sm};
  padding-bottom: 6px;
  font-family: ${family.interRegular};
  color: ${grey.dark};
`;

const DescriptorValue = styled.div`
  border-top: 1px solid ${grey.rule};
  padding-top: 6px;
  margin-top: 6px;
`;

const RoleDuration = styled.span`
  color: ${grey.mid};
`;

const FieldLabel = styled.span`
  font-family: ${family.interLight};
  color: ${grey.mid};
`;

const Accordion = styled(AccordionComponent)`
  margin-top: 10px;
  border-top: 1px solid ${grey.rule};
  padding: 3px 0;
`;

const AdditionalFieldContainer = styled.div`
  padding-top: 10px;
`;

const PlaceholderIcon = styled(PlaceholderImage)`
  height: 74px;
  margin-bottom: 8px;
  width: 74px;
  object-fit: cover;
  border-radius: 3px;
`;

const SummaryFields = styled.div`
  min-height: 30px;
`;

const S = {
  CardContainer,
  CardInnerContainer,
  CardContentContainer,
  CardContent,
  CardTopSection,
  CardImageSection,
  ImageContainer,
  Image,
  SocialMediaIcons,
  RightSideContainer,
  CardSummary,
  NameField,
  DataFieldValue,
  RoleDuration,
  FieldLabel,
  Accordion,
  AdditionalFieldContainer,
  DescriptorValue,
  PlaceholderIcon,
  SummaryFields
};

export default S;
