import React, {
  useMemo,
  useReducer,
  useCallback,
  useEffect,
  FC,
  ReactNode
} from "react";
import { Auth, Amplify } from "aws-amplify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

import config from "config";

import { OpenAPI as InsightsOpenAPI } from "api/insights";
import { ApiError, OpenAPI as PortalOpenAPI } from "api/portal";
import UserApi from "api/user";
import { UserPermissionsResultType } from "api/user/types";
import useHubAdminRole from "util/hooks/useHubAdminRole";
import { HUB_ASSUMED_ROLE_TOKEN_KEY } from "util/hooks/useHubAdminRole/provider";
import { routes } from "pages/Router";

import {
  initialState,
  authenticationReducer,
  AuthenticationContext
} from "./context";

import {
  AuthenticationStatus,
  AuthenticationAction,
  AuthenticationActions
} from "./types";

// aws-amplify lib doesn't give us this :(
interface AmplifyConfig {
  Auth: {
    region: string | undefined;
    userPoolId: string;
    userPoolWebClientId: string;
    oauth: {
      domain: string;
      scope: string[];
      redirectSignIn: string | undefined;
      redirectSignOut: string | undefined;
      responseType: string;
    };
  };
}

interface Props {
  amplifyConfig: AmplifyConfig;
  loginPath: string;
  children: ReactNode;
}

const apiToken = async () => {
  try {
    const session = await Auth.currentSession();
    // const accessToken = res.getAccessToken();
    const idToken = session.getIdToken();
    const jwt = idToken.getJwtToken();
    return jwt;
  } catch (e) {
    console.error(e);

    return "";
  }
};

PortalOpenAPI.TOKEN = apiToken;
InsightsOpenAPI.TOKEN = apiToken;

export const AuthenticationContextProvider: FC<Props> = ({
  children,
  loginPath,
  amplifyConfig
}) => {
  const isBlacklistedPath =
    window.location.pathname !== "/getting-started" &&
    window.location.pathname !== "/reset-password" &&
    !window.location.pathname.includes("/share");

  const location = useLocation();

  const { setIsAuthenticatedAsHubAdmin, isAuthenticatedAsHubAdmin } =
    useHubAdminRole();

  Amplify.configure(amplifyConfig);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [state, reducerDispatch] = useReducer(
    authenticationReducer,
    initialState
  );

  const dispatch = useCallback((action: AuthenticationAction) => {
    reducerDispatch(action);
  }, []);

  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  const getHubAdminAuthenticationStatus = useCallback(() => {
    const hubAuthed = sessionStorage.getItem(HUB_ASSUMED_ROLE_TOKEN_KEY);

    if (hubAuthed) {
      const authObject = JSON.parse(hubAuthed);
      // If the token is expired
      if (
        dayjs(new Date()).add(authObject.expires, "s").toDate() < new Date()
      ) {
        sessionStorage.removeItem(HUB_ASSUMED_ROLE_TOKEN_KEY);
        navigate(routes.hub);
        return false;
      }
      // Override OpenAPI token config
      InsightsOpenAPI.TOKEN = authObject.token;
      PortalOpenAPI.TOKEN = authObject.token;
      return true;
    }

    return false;
  }, [navigate]);

  useEffect(() => {
    if (state.status === AuthenticationStatus.authenticated) {
      const api = new UserApi();

      const getPermissions = async () => {
        try {
          const permissionsResult = await api.getUserPermissions();
          if (permissionsResult.type === UserPermissionsResultType.Success) {
            dispatch({
              type: AuthenticationActions.updatePermissions,
              permissions: permissionsResult.permissions
            });
          } else {
            const { message } = permissionsResult;
            console.error("Error loading user permissions", { message });
            await Auth.signOut();
            dispatch({
              type: AuthenticationActions.unauthenticated,
              error: message
            });
          }
        } catch (e: unknown) {
          let message: string | undefined;
          if (e instanceof ApiError && e.body && e.body.message) {
            message = e.body.message;
          }
          try {
            await Auth.signOut();
          } catch (e2) {
            console.error("Fallback sign-out error", { e, e2 });
          }
          dispatch({
            type: AuthenticationActions.unauthenticated,
            error: message
          });
        }
      };

      getPermissions();
    }
  }, [state.status, dispatch]);

  useEffect(() => {
    if (state.status === AuthenticationStatus.unknown) {
      if (getHubAdminAuthenticationStatus()) {
        if (!isAuthenticatedAsHubAdmin) {
          setIsAuthenticatedAsHubAdmin(true);
        }
        dispatch({ type: AuthenticationActions.authenticated });
        return;
      }

      const getAuthenticatedUser = async () => Auth.currentAuthenticatedUser();

      getAuthenticatedUser()
        .then(session => {
          const mfaRequired =
            session?.signInUserSession?.idToken.payload.mfaRequired;
          if (
            mfaRequired === "true" &&
            (session.preferredMFA !== "SOFTWARE_TOKEN_MFA" ||
              session.challengeName === "MFA_SETUP")
          ) {
            dispatch({ type: AuthenticationActions.unauthenticated });

            if (location.pathname !== loginPath) {
              navigate(loginPath);
            }
          } else {
            dispatch({ type: AuthenticationActions.authenticated, session });
          }
        })
        .catch(() => {
          if (isBlacklistedPath && location.pathname !== loginPath) {
            navigate(loginPath, {
              state: { redirect: `${location.pathname}${location.search}` }
            });
          }
          // no need for error message - probably just not yet authenticated
          dispatch({ type: AuthenticationActions.unauthenticated });
        });
    }
  }, [
    state,
    dispatch,
    navigate,
    isBlacklistedPath,
    location.pathname,
    location.search,
    loginPath,
    setIsAuthenticatedAsHubAdmin,
    isAuthenticatedAsHubAdmin,
    getHubAdminAuthenticationStatus
  ]);

  useEffect(() => {
    if (state.status === AuthenticationStatus.verifyingPassword) {
      // Try-catch is also needed here because Amplify will throw an error if
      // login is already pending. Otherwise, it will return a promise.
      try {
        Auth.signIn(
          `${state.email.toLowerCase()}+${config.tenantId}`,
          state.password
        )
          .then(session => {
            const mfaRequired =
              session.signInUserSession?.idToken.payload.mfaRequired === "true";
            if (mfaRequired || session.challengeName === "SOFTWARE_TOKEN_MFA") {
              dispatch({
                type: AuthenticationActions.passwordVerified,
                session
              });
            } else {
              dispatch({
                type: AuthenticationActions.authenticated,
                session: state.session
              });
            }
          })
          .then(() => {
            dispatch({
              type: AuthenticationActions.resetState
            });
          })
          .catch(({ message: error }) => {
            dispatch({ type: AuthenticationActions.unauthenticated, error });
          });
      } catch (e) {
        console.error(e);
      }
    }
  }, [state, dispatch, navigate, searchParams, loginPath]);

  useEffect(() => {
    if (state.status === AuthenticationStatus.authenticating) {
      Auth.confirmSignIn(state.session, state.mfaCode, "SOFTWARE_TOKEN_MFA")
        .then(session => {
          dispatch({ type: AuthenticationActions.authenticated, session });
        })
        .catch(({ message: error }) => {
          dispatch({ type: AuthenticationActions.unauthenticated, error });
        });

      return;
    }

    if (state.status === AuthenticationStatus.mfaSetup) {
      Auth.verifyTotpToken(state.session, state.mfaCode)
        .then(async () => {
          await Auth.setPreferredMFA(state.session, "SOFTWARE_TOKEN_MFA");

          dispatch({
            type: AuthenticationActions.authenticated,
            session: state.session
          });
        })
        .catch(({ message: error }) => {
          dispatch({ type: AuthenticationActions.unauthenticated, error });
        });

      return;
    }

    if (state.status === AuthenticationStatus.authenticated) {
      if (window.location.pathname === loginPath) {
        if (location.state?.redirect) {
          navigate(location.state.redirect);
        } else {
          navigate("/search");
        }
      }
    }

    if (
      state.status === AuthenticationStatus.unauthenticated &&
      window.location.pathname !== loginPath &&
      isBlacklistedPath
    ) {
      if (!getHubAdminAuthenticationStatus()) {
        navigate(loginPath);
      }
    }
  }, [
    state,
    dispatch,
    navigate,
    searchParams,
    isBlacklistedPath,
    location.state?.redirect,
    loginPath,
    isAuthenticatedAsHubAdmin,
    getHubAdminAuthenticationStatus
  ]);

  return (
    <AuthenticationContext.Provider value={providerValue}>
      {children}
    </AuthenticationContext.Provider>
  );
};
