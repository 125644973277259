import { getSecondTierOfRiskHierarchy } from "util/getSecondTierOfRiskHierarchy";

/**
 * Ascertain which sources are affected by the newly removedRiskHierarchy.
 * @param {string} sourceId if defined then removedRiskHierarchy will be removed only from this source.
 * @param {string[]} removedRiskHierarchy the hierarchy the user has requested to remove.
 * @param {object[]} sources original web and media sources
 * @param {string[]} currentUserRemovedRiskCategories existing user removed categories
 * @returns updated collection of removed risk hierarchies, containing removedRiskHierarchy
 */
export const getUserRemovedRiskCategories = ({
  sourceId,
  removedRiskHierarchy,
  currentUserRemovedRiskCategories,
  webAndMediaData,
  riskData
}) => {
  const result = {
    webAndMediaData: [...webAndMediaData],
    riskData: { ...riskData },
    removedRiskCategories: { ...currentUserRemovedRiskCategories }
  };

  const addSourceToResults = sourceId => {
    if (result.removedRiskCategories[sourceId]) {
      const hierarchyAlreadyAddedForSource = result.removedRiskCategories[
        sourceId
      ].riskHierarchies.some(hierarchy => {
        return (
          getSecondTierOfRiskHierarchy(hierarchy) ===
          getSecondTierOfRiskHierarchy(removedRiskHierarchy)
        );
      });

      if (!hierarchyAlreadyAddedForSource) {
        result.removedRiskCategories[sourceId].riskHierarchies.push(
          removedRiskHierarchy
        );
      }
    } else {
      result.removedRiskCategories[sourceId] = {
        riskHierarchies: [removedRiskHierarchy]
      };
    }
  };

  const reduceTagsForSource = source => {
    let sourceContainsHierarchy = false;
    const reducedTags = source.tags.reduce((acc, tag) => {
      const isRiskTag = tag.riskHierarchies.length > 0;

      if (!isRiskTag) {
        acc.push(tag);
        return acc;
      }

      let hierarchyIndex;
      while (
        (hierarchyIndex = tag.riskHierarchies.findIndex(hierarchy => {
          return (
            getSecondTierOfRiskHierarchy(hierarchy) ===
            getSecondTierOfRiskHierarchy(removedRiskHierarchy)
          );
        })) >= 0
      ) {
        sourceContainsHierarchy = true;
        tag.riskHierarchies = [
          ...tag.riskHierarchies.slice(0, hierarchyIndex),
          ...tag.riskHierarchies.slice(hierarchyIndex + 1)
        ];
      }

      // If there is still hierarchies left in the tag, then keep the tag
      if (tag.riskHierarchies.length > 0) {
        acc.push(tag);
      }

      return acc;
    }, []);

    return { reducedTags, sourceContainsHierarchy };
  };

  // If there's a sourceId, then we only want to remove the risk category
  // from this one source.
  if (sourceId) {
    addSourceToResults(sourceId);

    // First, remove the hierarchy from the specific source.
    const sourceObj = result.webAndMediaData.find(d => d.sourceId === sourceId);
    sourceObj.tags = reduceTagsForSource(sourceObj).reducedTags;

    // Now, remove the source from the corresponding hierarchy store
    // in the risk data for the Details section of the report.
    Object.keys(result.riskData).forEach(tier0Risk => {
      Object.keys(result.riskData[tier0Risk]).forEach(directionalityBucket => {
        const catContainingSourceIndex = result.riskData[tier0Risk][
          directionalityBucket
        ].findIndex(tier2 => {
          return removedRiskHierarchy[1] === tier2.riskTier2Cat;
        });

        if (catContainingSourceIndex >= 0) {
          const referenceIds =
            result.riskData[tier0Risk][directionalityBucket][
              catContainingSourceIndex
            ].referenceIds ?? [];
          const sourceIndexToRemove = referenceIds.findIndex(source => {
            return source === sourceId;
          });

          if (sourceIndexToRemove >= 0) {
            result.riskData[tier0Risk][directionalityBucket][
              catContainingSourceIndex
            ].referenceIds = [
              ...referenceIds.slice(0, sourceIndexToRemove),
              ...referenceIds.slice(sourceIndexToRemove + 1)
            ];
          }
        }
      });
    });

    //
  } else {
    // If we lack a sourceId then remove the category from all sources.
    // Loop through all articles and find those that have the supplied hierarchy.
    result.webAndMediaData.forEach(source => {
      // Remove the hierarchy, and if necessary tags, from each source
      const { reducedTags, sourceContainsHierarchy } =
        reduceTagsForSource(source);
      source.tags = reducedTags;

      if (sourceContainsHierarchy) {
        addSourceToResults(source.sourceId);
      }
    });

    // Now, remove the second tier hierarchy
    // in the risk data for the Details section of the report.
    Object.keys(result.riskData).forEach(tier0Risk => {
      Object.keys(result.riskData[tier0Risk]).forEach(directionalityBucket => {
        const indexToRemove = result.riskData[tier0Risk][
          directionalityBucket
        ].findIndex(tier2 => {
          return removedRiskHierarchy[1] === tier2.riskTier2Cat;
        });

        if (indexToRemove >= 0) {
          result.riskData[tier0Risk][directionalityBucket] = [
            ...result.riskData[tier0Risk][directionalityBucket].slice(
              0,
              indexToRemove
            ),
            ...result.riskData[tier0Risk][directionalityBucket].slice(
              indexToRemove + 1
            )
          ];
        }
      });
    });
  }

  return result;
};
