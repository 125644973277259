import React, { FC, SVGProps, useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { Portal, Snackbar } from "@mui/material";

import { Trash2, Link, Copy } from "react-feather";

import { ButtonType, ButtonSize } from "components/atoms/ButtonNew/types";
import useShareReport from "util/hooks/useShareReport";
import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";
import type { Report } from "api/reports";
import Heading from "components/atoms/Heading";
import ErrorBanner from "components/atoms/ErrorBanner";

import S from "./styles";

interface Props {
  permissions: Report["permissions"];
}

const CreateLinkControl: FC<Props> = ({ permissions }) => {
  const [copySuccessBarOpen, setCopySuccessBarOpen] = useState(false);

  const {
    generatedLink,
    generateShareLink,
    getShareLink,
    toggleDeleteShareLinkModal,
    toggleShareModal
  } = useShareReport();
  const [{ fetching, error, errorMessage }, dispatch] = useFetchReducer();

  const hasGeneratedLink = generatedLink && generatedLink.length;

  const handleCopySuccessBarClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setCopySuccessBarOpen(false);
  };

  useEffect(() => {
    const getGeneratedShareLink = async () => {
      dispatch({ type: RequestActions.SendRequest });
      await getShareLink();
      dispatch({ type: RequestActions.SetSuccess });
    };
    getGeneratedShareLink();
  }, [dispatch, getShareLink]);

  const onCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(generatedLink ?? "");
      setCopySuccessBarOpen(true);
    } catch (e) {
      console.error("Failed to copy link:", e);
    }
  };

  const onGenerateLink = async () => {
    dispatch({ type: RequestActions.SendRequest });

    const { status, message } = await generateShareLink();

    if (status) {
      dispatch({ type: RequestActions.SetSuccess });
    } else {
      dispatch({ type: RequestActions.SetError, errorMessage: message });
    }
  };

  const onDeleteLink = () => {
    toggleDeleteShareLinkModal();
    toggleShareModal();
  };

  return (
    <>
      <S.ShareWithOthersContainer>
        <S.LinkControls>
          <Heading level={6}>Share with a link</Heading>
          {hasGeneratedLink ? (
            <S.LinkButton
              text="Delete secure link"
              type={ButtonType.Text}
              size={ButtonSize.Medium}
              IconLeading={Trash2}
              onClick={onDeleteLink}
              disabled={!permissions.canDeleteShareLink}
            />
          ) : (
            <S.LinkButton
              text="Generate secure link"
              type={ButtonType.Text}
              size={ButtonSize.Medium}
              IconLeading={
                fetching
                  ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
                  : Link
              }
              onClick={onGenerateLink}
              disabled={fetching || !permissions.canGenerateShareLink}
            />
          )}
        </S.LinkControls>
        <AnimatePresence>
          {hasGeneratedLink && (
            <motion.div
              initial={{ height: 0 }}
              animate={{
                height: "auto"
              }}
              exit={{ height: 0 }}
              style={{ overflow: "hidden" }}
              transition={{ duration: 0.3 }}
            >
              <S.GeneratedLinkContainer>
                <S.GeneratedLink>{generatedLink}</S.GeneratedLink>
                <S.LinkButton
                  text="Copy to clipboard"
                  type={ButtonType.Text}
                  size={ButtonSize.Medium}
                  IconLeading={Copy}
                  onClick={onCopyLink}
                />
              </S.GeneratedLinkContainer>
            </motion.div>
          )}
        </AnimatePresence>
      </S.ShareWithOthersContainer>
      {error && (
        <ErrorBanner
          text={
            errorMessage ||
            "A link could not be created. Try again in a moment."
          }
        />
      )}
      <Portal>
        <Snackbar
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={copySuccessBarOpen}
          onClose={handleCopySuccessBarClose}
          message="Share link copied to clipboard"
        />
      </Portal>
    </>
  );
};

export default CreateLinkControl;
