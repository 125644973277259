import http from "services/http";
import endpoints from "services/endpoints";
import {
  EnquiryService,
  Idam_Contracts_Enums_ReportContextType,
  Idam_Contracts_Enums_ReportSubjectType,
  PortalMockAPI_Contracts_Enquiries_StartEnquiryRequest
} from "api/portal";

import { EnquiryRequest, EnquiryResponse } from "./types";

export default class Enquiry {
  inProgress: boolean;

  constructor(inProgress: boolean) {
    this.inProgress = inProgress;
  }

  async create({
    subject,
    contexts,
    activeFeatures,
    projectReference
  }: EnquiryRequest): Promise<EnquiryResponse | null> {
    try {
      const requestBody: PortalMockAPI_Contracts_Enquiries_StartEnquiryRequest =
        {
          subject: {
            type: subject.type as unknown as Idam_Contracts_Enums_ReportSubjectType,
            value: subject.value
          },
          contexts: contexts.map(context => ({
            type: context.type as unknown as Idam_Contracts_Enums_ReportContextType,
            value: context.value
          })),
          activeFeatures,
          projectReference
        };

      const enquiryIdResponse = await EnquiryService.postEnquiriesStartEnquiry({
        requestBody
      });

      return {
        enquiryId: enquiryIdResponse.enquiryId ?? ""
      };
    } catch (e) {
      console.error(e);
    }
    return null;
  }

  status(enquiryId: string) {
    return new Promise((resolve, reject) => {
      this.inProgress = true;
      http.post(`${endpoints.enquiryStatus}`, { EnquiryId: enquiryId }).then(
        s => {
          this.inProgress = false;
          resolve(s);
        },
        () => {
          this.inProgress = false;
          reject();
        }
      );
    });
  }
}
