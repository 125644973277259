import { styled } from "@linaria/react";

import ButtonNew from "components/atoms/ButtonNew";

import { grey, purple, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

import { ASK_XAPIEN_SECTION_SLUG } from "components/organisms/InsightReport/types";

const Menu = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding-top: 0.6rem;
  border-right: 1px solid ${grey.rule};
`;

const MenuItem = styled.li<{ active?: boolean }>`
  font-size: ${fontSize.sm};
  background-color: ${({ active }) => (active ? purple.light : "transparent")};
  color: ${({ active }) => (active ? purple.darker : grey.darkish)};
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  transition: all 0.3s ease;

  svg {
    width: 16px;
    height: 16px;
    color: ${({ active }) => (active ? purple.darker : grey.dark)};
  }

  &:hover {
    color: ${({ active }) => (active ? purple.darker : grey.dark)};
    background-color: ${({ active }) =>
      active ? purple.light : grey.faintish};
  }
`;

const MenuItemIndicator = styled.div<{ active?: boolean }>`
  width: 8px;
  border-radius: 4px 0px 0px 4px;
  background: ${({ active }) => (active ? purple.darker : "transparent")};
`;

const MenuItemContent = styled.div`
  padding: 11px 10px 11px 20px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const AskXapienContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  padding: 34px 32px;
`;

const AskXapienButton = styled(ButtonNew)<{ selected: boolean }>`
  background: ${purple.darker};
  color: ${standardColors.white};

  &:disabled {
    background: ${purple.darker};
    color: #fff;
    opacity: 0.5;
  }

  &:hover,
  &:hover:focus,
  &:hover:active {
    background: ${purple.light};
    color: ${purple.darker};
    box-shadow: 0px 2px 4px 0px #00000040;

    path {
      stroke: ${purple.darker} !important;
    }
  }

  &:focus,
  &:active {
    background: ${purple.darker};
    color: ${standardColors.white};

    path {
      stroke: ${standardColors.white} !important;
    }
  }

  path {
    stroke: ${standardColors.white} !important;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  position: relative;
`;

const ContentSection = styled.div<{ activeSectionSlug: string }>`
  padding: ${({ activeSectionSlug }) =>
    activeSectionSlug === ASK_XAPIEN_SECTION_SLUG
      ? "24px 44px 0 44px"
      : "24px 24px 24px 44px"};
  -webkit-transform: translate3d(0, 0, 0);
  height: ${({ activeSectionSlug }) =>
    activeSectionSlug === ASK_XAPIEN_SECTION_SLUG ? "100%" : "604px"};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${({ activeSectionSlug }) =>
    activeSectionSlug === ASK_XAPIEN_SECTION_SLUG ? "0" : "95px"};
  max-height: ${({ activeSectionSlug }) =>
    activeSectionSlug === ASK_XAPIEN_SECTION_SLUG ? "750px" : "none"};
`;

const SectionNavigationBlur = styled.div`
  position: absolute;
  pointer-events: none;
  z-index: 1;
  bottom: 106px;
  left: 0;
  width: calc(100% - 15px);
  height: 50px;
  background: linear-gradient(
    0deg,
    rgba(242, 244, 245, 1) 25%,
    rgba(255, 255, 255, 0) 100%
  );
`;

const S = {
  Menu,
  MenuItem,
  MenuItemContent,
  MenuItemIndicator,
  AskXapienContainer,
  AskXapienButton,
  ContentContainer,
  ContentSection,
  SectionNavigationBlur
};

export default S;
