import { styled } from "@linaria/react";

import { ReactComponent as LogoIcon } from "img/insights-icon.svg";
import { grey, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const Panel = styled.div`
  position: relative;
  width: 100%;
  background-color: ${grey.panel};
  page-break-inside: avoid;
  min-height: 30px;
  color: ${grey.dark};
`;

const Title = styled.div`
  align-items: center;
  display: flex;
  gap: 0.75rem;
  padding: 6px 10px;
  font-size: ${fontSize.sm};
  span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

const Container = styled.div`
  min-height: 600px;
  display: flex;
`;

const Divider = styled.hr`
  margin: 0;
  background-color: ${grey.panel};
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background: ${standardColors.white};
  border-radius: 50%;

  svg {
    width: 13px;
    height: 16px;
  }
`;

const S = {
  Panel,
  Container,
  Divider,
  LogoContainer,
  LogoIcon,
  Title
};

export default S;
