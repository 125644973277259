/* eslint-disable max-len */
import { styled } from "@linaria/react";

import { hexToRgb } from "styles/hexToRgb";

import MediaSourceCardComponent from "components/molecules/MediaSourceCard";

import { family } from "styles/fonts";
import { grey, green, standardColors } from "styles/colors";

// @ts-ignore
const MediaSourceCard = styled(MediaSourceCardComponent)`
  width: 100%;
  background: ${standardColors.white};
  padding: 16px;
  color: ${grey.dark};
  margin-bottom: 0;
  border-radius: 0 0 12px 12px;

  .di-card-header {
    height: 102px;
    margin-bottom: 0;

    img {
      margin-top: 4px;
    }
  }

  .di-card-headings {
    margin-top: 0;
  }

  .di-source-card {
    padding: 0;
    margin: 0;
  }

  .di-card-footer {
    padding: 8px;
    border-top: 1px solid ${grey.rule};
  }

  .di-card-main-text {
    height: 136px;
    overflow: auto;
    padding-bottom: 0;

    hr {
      display: none;
    }
  }

  .di-card-text {
    position: relative;
    border-radius: 12px;
    padding: 8px;
    padding-left: 37px;
    background: rgba(
      ${
        // @ts-ignore this thinks it needs a StaticPlaceholder rather than a string
        () => {
          const { r, g, b } = hexToRgb(green.xapienGreen);
          return `${r}, ${g}, ${b}`;
        }
      },
      0.15
    );

    &:after {
      content: "";
      background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="34" fill="none"><path fill="%23007C89" d="M2.386 15v-3.41c0-.927.19-1.86.569-2.797a12.15 12.15 0 0 1 1.434-2.6c.578-.795 1.16-1.44 1.747-1.932L9.375 5.91a11.658 11.658 0 0 0-1.15 2.585c-.266.9-.394 1.932-.384 3.097V15H2.386Zm7.103 0v-3.41c0-.927.189-1.86.568-2.797.379-.938.857-1.804 1.434-2.6.578-.795 1.16-1.44 1.748-1.932l3.238 1.648a11.654 11.654 0 0 0-1.15 2.585c-.265.9-.393 1.932-.384 3.097V15H9.49Z"/></svg>');
      background-repeat: no-repeat;
      position: absolute;
      top: 9px;
      left: 10px;
      color: ${green.mid};
      font-size: 40px;
      font-family: ${family.interDisplay};
      line-height: 1;
      height: 34px;
      width: 18px;
    }
  }
`;

const SourceContainer = styled.div`
  height: 100%;
  background: ${standardColors.white};
  overflow: auto;
  border-radius: 0 0 12px 12px;
`;

const S = {
  MediaSourceCard,
  SourceContainer
};

export default S;
