import React, { Fragment } from "react";
import { Collapse } from "reactstrap";

import arrow from "img/icons/renders/arrow.svg";
import { usePrintableReportState } from "util/hooks/usePrintableState";

import ListItem from "./ListItem";

const SingleRole = ({ groupData }) => {
  const item = {
    text: groupData.text,
    subText: groupData.group[0].text,
    sources: groupData.group[0].sources,
    firstDate: groupData.group[0].firstDate,
    lastDate: groupData.group[0].lastDate
  };
  return <ListItem item={item} />;
};

const Group = ({
  groupData,
  groupLabel = "roles",
  textIsWrappedWithSourcing = false
}) => {
  const [isExpanded, setExpanded] = usePrintableReportState(
    `group-${groupData.text}-expanded`,
    false
  );
  const toggleExpanded = () => setExpanded(!isExpanded);

  return groupData.group.length === 1 ? (
    <SingleRole groupData={groupData} />
  ) : (
    <>
      <li>
        <div
          className="d-flex align-items-baseline"
          onClick={toggleExpanded}
          onKeyDown={toggleExpanded}
        >
          <div className="d-flex">
            <img
              src={arrow}
              alt="Collapse"
              className={`toggle-icon__extra-information ${
                isExpanded ? "toggle-icon__extra-information--expanded" : ""
              }`}
            />
          </div>
          <div>
            <span>{`${groupData.text}`}</span>
            <span className="experimental-section__group-label">{` (${groupData.group.length} ${groupLabel})`}</span>
          </div>
        </div>
      </li>
      <Collapse isOpen={isExpanded} className="list-group-contents">
        {groupData.group.map((item, index) => (
          <ListItem
            className="ml-3 font__weight--super-light" // want alignment so that bullet is aligned "inside" text of parent
            key={`GroupListItem-${item.role}-${index}`}
            item={item}
            textIsWrappedWithSourcing={textIsWrappedWithSourcing}
          />
        ))}
      </Collapse>
    </>
  );
};

export default React.memo(Group);
