import { styled } from "@linaria/react";

import { fontSize } from "styles/fonts";
import { grey } from "styles/colors";

const ButtonDiv = styled.div`
  width: 100%;
  background-color: ${grey.panel};
  padding: 8px 3px;
  margin-top: 8px;
  color: ${grey.dark};
  line-height: 1;
  cursor: pointer;
  font-size: ${fontSize.sm};
  text-align: left;

  &:hover {
    background-color: ${grey.light};
  }
`;

const S = {
  ButtonDiv
};

export default S;
