import React from "react";
import DropdownButton from "components/molecules/DropdownButton";
import { dJToolTipText } from "themes/projectdx/ThemedScreening/util";
import { CONFIDENCE_CATEGORIES } from "util/confidenceCategories";

import ReactTooltip from "components/atoms/ReactTooltip";
import ConfidenceToolTipText from "components/atoms/ConfidenceTooltipText";
import S from "./styles";

interface DropDownMenuProps {
  label: number | string;
  onClickHandler: () => void;
}

interface Props {
  isPdx: boolean;
  inputtedHoveredState: CONFIDENCE_CATEGORIES;
  dropDownButtonText: string;
  dropDownMenuItem1: DropDownMenuProps;
  dropDownMenuItem2: DropDownMenuProps;
  dropDownMenuItem3: DropDownMenuProps;
  dropDownMenuItem4?: DropDownMenuProps;
}

const ConfidenceDropDownMenu = ({
  isPdx = false,
  inputtedHoveredState,
  dropDownButtonText,
  dropDownMenuItem1,
  dropDownMenuItem2,
  dropDownMenuItem3,
  dropDownMenuItem4
}: Props) => {
  const [hoverState, setHoverState] =
    React.useState<CONFIDENCE_CATEGORIES | null>(null);

  return (
    <ReactTooltip
      position="left"
      delay={1000}
      tooltip={
        <ConfidenceToolTipText
          selectedConfidenceFilter={hoverState ?? inputtedHoveredState}
        />
      }
    >
      <DropdownButton label={dropDownButtonText}>
        <S.OptionsDropdownMenuItem
          onClick={() => {
            dropDownMenuItem1.onClickHandler();
          }}
          onMouseEnter={() => setHoverState(CONFIDENCE_CATEGORIES.confirmed)}
          onMouseLeave={() => setHoverState(null)}
        >
          {isPdx ? dJToolTipText.confimed.title : "Confirmed"}
          <S.ConfidencebucketCount>
            {dropDownMenuItem1.label}
          </S.ConfidencebucketCount>
        </S.OptionsDropdownMenuItem>
        <S.OptionsDropdownMenuItem
          onClick={() => {
            dropDownMenuItem2.onClickHandler();
          }}
          onMouseEnter={() => setHoverState(CONFIDENCE_CATEGORIES.unconfirmed)}
          onMouseLeave={() => setHoverState(null)}
        >
          {isPdx ? dJToolTipText.unconfirmed.title : "Unconfirmed"}
          <S.ConfidencebucketCount>
            {dropDownMenuItem2.label}
          </S.ConfidencebucketCount>
        </S.OptionsDropdownMenuItem>
        <S.DisabledOptionsDropdownMenuItem
          onClick={() => {
            // dropDownMenuItem3.onClickHandler();
          }}
          onMouseEnter={() => setHoverState(CONFIDENCE_CATEGORIES.discarded)}
          onMouseLeave={() => setHoverState(null)}
        >
          {isPdx ? dJToolTipText.discarded.title : "Discarded"}
          <S.ConfidencebucketCount>
            {dropDownMenuItem3.label}
          </S.ConfidencebucketCount>
        </S.DisabledOptionsDropdownMenuItem>
        {isPdx && (
          <S.DisabledOptionsDropdownMenuItem
            onClick={() => {
              // dropDownMenuItem4.onClickHandler();
            }}
            onMouseEnter={() =>
              setHoverState(CONFIDENCE_CATEGORIES.userDiscarded)
            }
            onMouseLeave={() => setHoverState(null)}
          >
            {dJToolTipText.userDiscarded.title}
            <S.ConfidencebucketCount>
              {dropDownMenuItem4 && dropDownMenuItem4.label}
            </S.ConfidencebucketCount>
          </S.DisabledOptionsDropdownMenuItem>
        )}
      </DropdownButton>
    </ReactTooltip>
  );
};

export default ConfidenceDropDownMenu;
