import { styled } from "@linaria/react";
import { css } from "@linaria/core";
import { motion } from "framer-motion/dist/framer-motion";

import Notification from "components/atoms/Notification";

import { standardColors, blue } from "styles/colors";
import { fontSize } from "styles/fonts";
import Button from "components/atoms/Button";

const ViewContainer = styled.main`
  padding-left: 8vw;
  padding-right: 8vw;
  flex-grow: 1;
`;

const ViewInnerContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PaddingElement = styled.div<{ extraPadding?: string }>`
  height: ${props => (props.extraPadding ? "10%" : "5%")};
`;

const StageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: flex-start;
  flex: 0.93;
`;

const PopoverAnchor = styled.div`
  position: absolute;
  right: 10%;
  top: 17%;
`;

const VersionNumber = styled.p`
  color: ${standardColors.white};
`;

const AdvancedSearchOption = styled.div`
  padding-bottom: 30px;
  p {
    color: ${standardColors.white};
    margin: 0;
    display: inline;
  }

  button {
    display: inline;
  }
`;

const StatusNotification = styled(Notification)`
  width: 1000px;
  font-size: ${fontSize.md};
  margin-top: 0;
  padding-bottom: 25px !important;
  padding-right: 34px;
  padding-left: 34px;
  background-color: #355672;
`;

const NotificationOkButton = styled(Button)<{
  backgroundColor?: string;
  boxShadowColor: string;
  color: string;
}>`
  background-color: ${props => {
    return props.backgroundColor ?? blue.xapienLight;
  }} !important;
  color: ${props => {
    return props.color;
  }} !important;
  font-size: ${fontSize.md} !important;
  margin-top: 16px;
  margin-bottom: 4px;
  min-width: 0 !important;
  min-height: 0 !important;
  width: 60px;
  height: 28px;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px ${props => props.boxShadowColor} !important;
  }
`;

const NotificationText = styled.div`
  max-height: 145px;
  overflow-y: auto;
  white-space: pre-wrap;
`;

const NotificationContainer = styled(motion.div)`
  position: absolute;
  bottom: 5%;
  z-index: 1;
`;

const AdvancedSearchTogglePaddingElement = styled.div`
  height: 54px;
  width: 1px;
`;

const PoweredBy = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 1.5rem;
`;

/** ClassNameOverrides */

const notificationDescriptionClass = css`
  padding-top: 0;
  margin-top: 10px;
`;

export const classNameOverrides = {
  notificationDescriptionClass
};

const S = {
  ViewContainer,
  ViewInnerContainer,
  PaddingElement,
  StageContainer,
  PopoverAnchor,
  VersionNumber,
  AdvancedSearchOption,
  StatusNotification,
  NotificationOkButton,
  NotificationText,
  NotificationContainer,
  AdvancedSearchTogglePaddingElement,
  PoweredBy
};

export default S;
