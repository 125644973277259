import { styled } from "@linaria/react";
import { Spinner } from "reactstrap";

import { ReactComponent as DownloadReadySvg } from "img/icons/menu/reportPage/icon_download_ready.svg";
import { ReactComponent as DownloadSvg } from "img/icons/menu/reportPage/icon_download_pdf.svg";

import { fontSize } from "styles/fonts";
import { green, grey, standardColors } from "styles/colors";

const ExportButton = styled.span<{
  downloadReady?: boolean;
  backgroundColor?: string;
}>`
  width: auto;
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
  font-size: ${fontSize.sm};
  height: 28px;
  cursor: pointer;
  border-radius: 3px;
  justify-content: center;
  display: flex;
  align-items: center;
  border: 1px solid ${grey.rule};
  background-color: ${({ downloadReady, backgroundColor }) =>
    downloadReady ? backgroundColor ?? green.xapienGreen : "transparent"};
  svg {
    path {
      fill: ${({ downloadReady }) =>
        downloadReady ? standardColors.white : grey.dark};
    }
  }
`;

const ExportSpinner = styled(Spinner)<{ color?: string }>`
  color: ${({ color }) => color ?? green.xapienGreen};
  width: 21px;
  height: 21px;
`;

const DownloadIconStyles = {
  width: "28px",
  height: "23px"
};

const DownloadReady = styled(DownloadReadySvg)<{ backgroundColor: string }>`
  ${DownloadIconStyles};
  rect {
    fill: ${({ backgroundColor }) => backgroundColor};
  }
`;

const Download = styled(DownloadSvg)`
  ${DownloadIconStyles};
`;

const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const ExpandOptionLabel = styled.div`
  margin-left: 8px;
  padding-bottom: 2px;
`;

const ExportButtonContainer = styled.div`
  height: 100%;
  width: 28px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Question = styled.div`
  margin-top: 8px;
`;

const S = {
  ExportButton,
  ExportSpinner,
  DownloadReady,
  Download,
  OptionsContainer,
  ExpandOptionLabel,
  ExportButtonContainer,
  Question
};

export default S;
