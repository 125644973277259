import { styled } from "@linaria/react";

const FDocCodeBlock = styled.pre`
  text-wrap: wrap;
  max-height: 70vh;
  padding: 1rem;
  overflow-y: scroll;
  background: #2e3033;
  color: white;
  font-family: courier;
`;

const FDocJSONLink = styled.a`
  color: white;
`;

export const S = { FDocCodeBlock, FDocJSONLink };
