import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import { grey, purple, standardColors } from "styles/colors";
import { fontSize, family } from "styles/fonts";

import ShowResultsButton from "pages/report/ShowResultsButton";
import { ReactComponent as InformationIconSvg } from "img/icons/information-icon.svg";
import { DiImage } from "components/molecules/DiImage";
import StickyExpandButton from "pages/report/StickyExpandButton";
import ReactTooltip from "components/atoms/ReactTooltip";
import InfoIconComponent from "components/atoms/InfoIcon";

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${grey.rule};
  background-color: ${grey.panel};
  padding-bottom: 16px;
  align-items: center;
  height: 36px;
`;

const LocationsSectionContainer = styled.div`
  display: flex;
  padding: 16px 0;
  background-color: ${grey.panel};
`;

const ResultsSection = styled.div`
  visibility: ${props => (props.isResultsShown ? "visible" : "hidden")};
  max-height: ${props => (props.isResultsShown ? "fit-content" : "0")};
`;

const MasonrySection = styled.div`
  max-height: ${props => {
    if (props.isResultsExpanded) {
      return "inherit";
    }

    if (props.isResultsShown) {
      return "700px";
    }

    return 0;
  }};
  overflow-y: auto;
`;

const SectionTotal = styled.h1`
  margin: 0;
`;

const FilteredSectionCount = styled.div`
  font-size: ${fontSize.lg};
`;

const SectionCountAdditional = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  text-align: right;
  padding-top: 2px;
`;

const Dates = styled.span`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

const FilterButtonsContainer = styled.div`
  display: flex;
  padding-left: 32px;
  padding-top: 8px;
  padding-bottom: 10px;
`;

const FilterByLabel = styled.span`
  margin-right: 4px;
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

const FiltersSection = styled.div`
  flex: 1;
`;

const ResetFiltersButton = styled.button`
  color: ${grey.mid};
  border: none;
  background: none;
  margin-left: 32px;
  padding: 0;
  border-bottom: 1px solid;
  border-color: transparent;
  font-size: ${fontSize.sm};

  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
    border-color: ${grey.mid};

    &:focus {
      border-color: ${grey.mid};
    }
  }

  &:focus {
    outline: none;
    color: ${grey.mid};
  }
`;

const LocationNoStreetview = styled.div`
  color: ${grey.mid};
  font-size: ${fontSize.sm};
  margin: 8px 0;
`;

const FilterButtonContainer = styled.div`
  margin-right: 8px;
`;

const OrderByLabel = styled.span`
  margin-right: 16px;
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

const NoResults = styled.div`
  color: ${grey.mid};
`;

const LocationCard = styled.div`
  background-color: ${grey.panel};
  border-radius: 3px;
`;

const LocationCardContent = styled.div`
  display: flex;
  padding-top: 15px;
`;

const CustomShowResultsButton = styled(ShowResultsButton)`
  background-color: ${grey.panel};
`;

const MissingStreetViewImageContainer = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${grey.mid};
  font-size: ${fontSize.sm};
  height: 97px;
  background: ${grey.light};
  border-radius: 3px;
  padding-top: 15px;

  svg {
    width: 34px;
    height: 40px;
  }
`;

const NoStreetviewInfo = styled.p`
  font-size: ${fontSize.sm};
  margin: 13px 0;
`;

const ShowAllButton = styled.button`
  color: ${grey.mid};
  border: none;
  background: none;
  padding: 0;
  border-bottom: 1px solid;
  border-color: transparent;
  font-size: ${fontSize.sm};
  padding-top: 10px;

  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
    border-color: ${grey.mid};

    &:focus {
      border-color: ${grey.dark};
    }
  }

  &:focus {
    outline: none;
    color: ${grey.dark};
  }
`;

const InformationIcon = styled(InformationIconSvg)`
  height: 15px;
  margin-left: 1px;
  &:hover {
    cursor: pointer;
    path {
      fill: ${purple.mid};
    }
  }
`;

const RoleYear = styled.span`
  font-family: ${family.interLight};
  color: ${grey.mid};
  padding-left: 3px;
`;

const LocationOrgName = styled.div`
  max-width: ${props => (props.isDatePresent ? "217px" : "255px")};
`;

const RoleLinkButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  font-size: ${fontSize.sm};
  color: ${grey.dark};

  &:hover {
    color: ${purple.mid};
  }

  &:focus {
    outline: none;
    color: ${purple.mid};
  }
`;

const LocationRoleItem = styled.div`
  display: flex;
  align-items: center;
`;

const LocationRolesList = styled.div`
  max-height: 243px;
  overflow: auto;

  & > div {
    max-width: 274px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const RoleSectionTitle = styled.div`
  color: ${grey.mid};
`;

const LocationRolesWrapper = styled.div`
  border-bottom: 1px solid ${grey.rule};
  padding: 10px 0;

  &:last-child {
    border-bottom: none;
  }

  &:first-child {
    padding-top: 0;
  }
`;

const AddressTooltip = styled.div`
  text-align: left;
`;

const AddressDate = styled.div`
  padding-bottom: 8px;
`;

const TooltipContainer = styled.div`
  background: ${standardColors.white};
  text-align: left;
  border-radius: 4px;
  font-size: ${fontSize.sm};
  -webkit-column-break-inside: avoid;
`;

const Image = styled(DiImage)`
  width: 100%;
  object-fit: cover;
  height: 258px;
  border-radius: 3px;
`;

const Rule = styled.div`
  border-bottom: 1px solid ${grey.rule};
  position: relative;
  left: 26px;
  width: 95%;
  margin-bottom: 11px;
  margin-top: 3px;
`;

const LocationsTopSectionContainer = styled.div`
  padding: 16px;
  background-color: ${grey.panel};
  margin-bottom: ${props => (props.isLocationResultsShown ? 2 : 0)}px;
`;

const CustomStickyExpandButton = styled(StickyExpandButton)`
  padding-left: 16px;
  padding-right: 16px;
  border-top: 2px solid ${standardColors.white};
`;

const Tooltip = styled(ReactTooltip)`
  margin-top: -8px;
  height: 15px;
`;

const TooltipHeader = styled.div`
  border-bottom: 1px solid ${grey.rule};
  padding-bottom: 6px;
`;

const TooltipBody = styled.div`
  padding-top: 6px;
`;

const InfoIcon = styled(InfoIconComponent)`
  margin-bottom: 3px;
`;

const LocationRiskFlagsContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  padding: 4px 0 3px 0;
  width: 160px;
  gap: 4px;
`;

/* ClassNameOverrides */

const masonryGrid = css`
  display: flex;
  margin-left: -10px; /* gutter size offset */
  width: auto;
`;

const masonryColumn = css`
  background-clip: padding-box;
  padding-left: 10px;

  & > div {
    margin-bottom: 10px;
    padding: 16px;
  }

  & > div:last-child {
    margin-bottom: 0px;
  }
`;

const infoIconTooltipContent = css`
  width: auto;
`;

const S = {
  Heading,
  LocationsSectionContainer,
  ResultsSection,
  MasonrySection,
  SectionTotal,
  FilteredSectionCount,
  SectionCountAdditional,
  Dates,
  FilterButtonsContainer,
  FilterByLabel,
  FiltersSection,
  ResetFiltersButton,
  LocationNoStreetview,
  FilterButtonContainer,
  OrderByLabel,
  NoResults,
  LocationCard,
  LocationCardContent,
  CustomShowResultsButton,
  MissingStreetViewImageContainer,
  NoStreetviewInfo,
  ShowAllButton,
  InformationIcon,
  RoleYear,
  LocationOrgName,
  RoleLinkButton,
  LocationRoleItem,
  LocationRolesList,
  RoleSectionTitle,
  LocationRolesWrapper,
  AddressTooltip,
  AddressDate,
  TooltipContainer,
  Image,
  Rule,
  LocationsTopSectionContainer,
  CustomStickyExpandButton,
  Tooltip,
  TooltipHeader,
  TooltipBody,
  InfoIcon,
  LocationRiskFlagsContainer
};

export const classNameOverrides = {
  masonryGrid,
  masonryColumn,
  infoIconTooltipContent
};

export default S;
