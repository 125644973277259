import { styled } from "@linaria/react";

import CommonButton from "components/atoms/Button";
import Popover from "components/atoms/Popover";

import { standardColors, grey } from "styles/colors";
import { fontSize, family } from "styles/fonts";
import { hexToRgb } from "styles/hexToRgb";

// @ts-ignore
import theme from "theme";

const DropdownHeader = styled.div<{ backgroundColor: string }>`
  height: 32px;
  background-color: ${props => props.backgroundColor};
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  color: ${standardColors.white};
  padding: 0 16px;
  display: flex;
  align-items: center;
  border-bottom: none;
  font-size: ${fontSize.md};
  font-family: ${family.interSemiBold};
`;

const DropdownFooter = styled.div`
  border-top: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 8px 16px 16px 16px;
`;

const FooterInfoText = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  padding-bottom: 2px;
`;

const DropdownBody = styled.div`
  padding: 16px;
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  white-space: normal;
`;

const ConfirmButton = styled(CommonButton)<{ color: string }>`
  color: ${props => props.color} !important;
  font-size: ${fontSize.sm};
  min-height: 28px !important;
  border-color: ${props => props.color} !important;
  border-width: 1px;

  &:active {
    border-width: 1px !important;
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px
      ${props => {
        const { r, g, b } = hexToRgb(props.color);
        return `rgba(${r}, ${g}, ${b}, 0.33)`;
      }} !important;
  }
`;

const CancelButton = styled(CommonButton)<{
  color?: string;
  backgroundColor?: string;
}>`
  color: ${props => props.color ?? standardColors.white} !important;
  min-height: 28px !important;
  font-size: ${fontSize.sm};
  background-color: ${props =>
    props.backgroundColor ?? theme.primaryColor} !important;
  margin-right: 8px;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px
      ${props => {
        const { r, g, b } = hexToRgb(props.backgroundColor);
        return `rgba(${r}, ${g}, ${b}, 0.33)`;
      }} !important;
  }
`;

const DropdownContainer = styled.div`
  width: 426px;
`;

const DropdownSubtext = styled.div<{ backgroundColor?: string }>`
  background-color: ${props => {
    const { r, g, b } = hexToRgb(props.backgroundColor);
    return `rgba(${r}, ${g}, ${b}, 0.07)`;
  }};
  padding: 12px 14px;
  color: ${grey.mid};
  font-size: ${fontSize.sm};
`;

const Tooltip = styled(Popover)`
  display: inline-block;
  z-index: 100;
`;

const TooltipContent = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  padding: 14px;
`;

const S = {
  DropdownHeader,
  DropdownFooter,
  FooterInfoText,
  DropdownBody,
  ConfirmButton,
  CancelButton,
  DropdownContainer,
  DropdownSubtext,
  Tooltip,
  TooltipContent
};

export default S;
