import http from "services/http";
import endpoints from "services/endpoints";

class Notes {
  async getUserNotes(enquiryId) {
    const data = {
      EnquiryId: enquiryId
    };

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("isForPDFExport") === "true") {
      data.Token = urlParams.get("token");
      data.IsForPdfExport = true;
    }

    try {
      const response = await http.post(endpoints.getUserNotes, data);

      return response;
    } catch (error) {
      console.error("Error retrieving notes: ", error);
      return { hasErrored: true, error };
    }
  }

  async setUserNotes(enquiryId, userNotes) {
    try {
      const response = await http.post(endpoints.setUserNotes, {
        EnquiryId: enquiryId,
        ReportNotes: userNotes
      });

      return response;
    } catch (error) {
      console.error("Error setting notes: ", error);
      return { hasErrored: true, error };
    }
  }
}

export default Notes;
