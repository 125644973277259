import React, { useState, useEffect } from "react";
import { DropdownItem } from "reactstrap";
import Toggle from "react-toggle";
import { SortFilterPill } from "./SortFilterPill";
import SortFilterCategories from "../../../../components/molecules/SortFilterCategories";

// when adding more pills should extract a few bits from here for reuse
export const RiskSortFilterPill = props => {
  const {
    setRiskCategoriesToUse,
    riskCategoriesToUseSet,
    riskCategories,
    showBroaderRisk,
    setShowBroaderRisk,
    showBroaderRiskDefault,
    isOnlyRisk,
    showOnlyRisk,
    setShowOnlyRisk
  } = props;

  const [modifiedRiskCategories, setModifiedRiskCategories] = useState(
    new Map()
  );

  useEffect(() => {
    const modifiedCategories = new Map();
    riskCategories.forEach((category, key) => {
      // Split up the hierarchy string into its tiers
      const splitCategories = key.split("/");

      // Extract the last item in the hierarchy. We'll display this string
      // in the filter menu.
      const lastCatInHierarchy = splitCategories[splitCategories.length - 1];
      modifiedCategories.set(key, {
        ...category,
        display: lastCatInHierarchy
      });
    });

    setModifiedRiskCategories(modifiedCategories);
  }, [riskCategories]);

  if (
    [...(riskCategories.keys() || [])].filter(cat => cat != null).length === 0
  ) {
    return "";
  }

  const pressShowBroaderRiskToggle = () => {
    setShowBroaderRisk(!showBroaderRisk);
  };
  const pressShowRiskToggle = () => setShowOnlyRisk(!showOnlyRisk);

  const title = "Risk";
  const active =
    (riskCategoriesToUseSet && riskCategoriesToUseSet.size) ||
    (showOnlyRisk && !isOnlyRisk);
  const canReset =
    (riskCategoriesToUseSet && riskCategoriesToUseSet.size) ||
    showBroaderRisk !== showBroaderRiskDefault ||
    showOnlyRisk;
  const reset = () => {
    setRiskCategoriesToUse(new Set());
    setShowBroaderRisk(showBroaderRiskDefault);
    setShowOnlyRisk(false);
  };

  try {
    return (
      <SortFilterPill {...{ reset, title, active, canReset }}>
        <div className="sort-filter-dropdown-menu-header">
          <div>In this report:</div>{" "}
          <div>
            {
              [...(riskCategories.keys() || [])].filter(cat => cat != null)
                .length
            }{" "}
            risk categories
          </div>
        </div>
        <DropdownItem divider />
        {!isOnlyRisk ? (
          <div className="toggle-filter">
            <div className="toggle-filter-label">Show only risk</div>
            <Toggle
              className="toggle-filter-inner"
              checked={showOnlyRisk}
              icons={false}
              onChange={pressShowRiskToggle}
              value="yes"
            />
          </div>
        ) : (
          ""
        )}
        <div className="toggle-filter">
          <div className="toggle-filter-label">Direct risk only</div>
          <Toggle
            className="toggle-filter-inner"
            checked={!showBroaderRisk}
            icons={false}
            onChange={pressShowBroaderRiskToggle}
            value="yes"
          />
        </div>
        <DropdownItem divider />
        <SortFilterCategories
          categories={modifiedRiskCategories}
          categoriesToUse={riskCategoriesToUseSet}
          setCategoriesToUse={setRiskCategoriesToUse}
          titleCase
        />
      </SortFilterPill>
    );
  } catch (e) {
    console.error("Error rendering menu", e);
    return <div>Could not display this menu due to an error</div>;
  }
};
