import type { InsightReport } from "api/insight-reports/types";

import type {
  Report as RawReport,
  Subsection as RawSubsection
} from "api/insights";

export enum InsightReportActions {
  fetchReport = "fetchReport",
  errorFetchingReport = "errorFetchingReport",
  errorFetchingSources = "errorFetchingSources",
  errorRemovingReportSection = "errorRemovingReportSection",
  hideWelcomeDisclaimer = "hideWelcomeDisclaimer",
  regenerateReport = "regenerateReport",
  removeReport = "removeReport",
  removeReportElement = "removeReportElement",
  removeReportSection = "removeReportSection",
  removeReportSubSection = "removeReportSubSection",
  showPreviousSectionSlug = "showPreviousSectionSlug",
  showWelcomeDisclaimer = "showWelcomeDisclaimer",
  updateReport = "updateReport",
  updateActiveSectionSlug = "updateActiveSectionSlug",
  updateReportSubSection = "updateReportSubSection",
  updatingFetchingSources = "updatingFetchingSources"
}

export enum InsightReportStatus {
  errorFetchingReport = "errorFetchingReport",
  errorFetchingSources = "errorFetchingSources",
  errorRemovingReportSection = "errorRemovingReportSection",
  fetching = "fetching",
  fetchingSources = "fetchingSources",
  idle = "idle",
  regeneratingReport = "regeneratingReport",
  removed = "removed",
  removingReportElement = "removingReportElement",
  removingReportSection = "removingReportSection",
  removingReportSubSection = "removingReportSubSection"
}

interface FetchReportAction {
  type: InsightReportActions.fetchReport;
}

interface ErrorFetchingReportAction {
  type: InsightReportActions.errorFetchingReport;
  errorMessage?: string;
}

interface ErrorFetchingSourcesAction {
  type: InsightReportActions.errorFetchingSources;
}

interface ErrorRemovingReportSectionAction {
  type: InsightReportActions.errorRemovingReportSection;
}

interface HideWelcomeDisclaimerAction {
  type: InsightReportActions.hideWelcomeDisclaimer;
}

interface RegenerateReportAction {
  type: InsightReportActions.regenerateReport;
}

interface RemoveReportAction {
  type: InsightReportActions.removeReport;
}

interface RemoveReportElementAction {
  type: InsightReportActions.removeReportElement;
  elementId: string;
  subSectionId: string;
  sectionId: string;
}

interface RemoveReportSectionAction {
  type: InsightReportActions.removeReportSection;
  sectionId: string;
}

interface RemoveReportSubSectionAction {
  type: InsightReportActions.removeReportSubSection;
  subSectionId: string;
  sectionId: string;
}

interface ShowPreviousSectionSlugAction {
  type: InsightReportActions.showPreviousSectionSlug;
}

interface ShowWelcomeDisclaimerAction {
  type: InsightReportActions.showWelcomeDisclaimer;
}

interface UpdateReportAction {
  type: InsightReportActions.updateReport;
  rawReport: RawReport;
  reportId: string;
}

interface UpdateReportSubSectionAction {
  type: InsightReportActions.updateReportSubSection;
  sectionId: string;
  subsectionId: string;
  subSection: RawSubsection;
}

interface UpdateActiveSectionSlugAction {
  type: InsightReportActions.updateActiveSectionSlug;
  slug: string;
}

interface UpdatingFetchingSourcesAction {
  type: InsightReportActions.updatingFetchingSources;
  nodeId: string;
}

export type InsightReportAction =
  | ErrorFetchingReportAction
  | ErrorRemovingReportSectionAction
  | ErrorFetchingSourcesAction
  | FetchReportAction
  | HideWelcomeDisclaimerAction
  | RegenerateReportAction
  | RemoveReportAction
  | RemoveReportElementAction
  | RemoveReportSectionAction
  | RemoveReportSubSectionAction
  | ShowPreviousSectionSlugAction
  | ShowWelcomeDisclaimerAction
  | UpdateActiveSectionSlugAction
  | UpdateReportAction
  | UpdateReportSubSectionAction
  | UpdatingFetchingSourcesAction;

export interface InsightReportState {
  activeSectionSlug: string;
  fetchedSourcesNodeIds: string[];
  fetchingSourceNodeId?: string;
  elementIdToRemove?: string;
  errorMessage?: string;
  isShowingWelcomeDisclaimer: boolean;
  previousSectionSlug?: string;
  rawReport?: RawReport;
  report?: InsightReport;
  sectionIdToRemove?: string;
  status: InsightReportStatus;
  subsectionIdToRemove?: string;
}
