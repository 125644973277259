import React, { useEffect, useRef, useState } from "react";
import { InputAdornment, OutlinedInput } from "@mui/material";
import { AnimatePresence } from "framer-motion/dist/framer-motion";

import { Message, MessageType } from "api/insight-reports/types";
import { useSubjectName } from "util/hooks/useSubjectName";
import Popover from "components/atoms/Popover";
import TruncateLength from "util/TruncateLength";

import {
  useInsightReport,
  InsightReportActions
} from "util/hooks/useInsightReport";

import useInsightsChat from "./useInsightsChat";
import InsightsMessage from "./InsightsMessage";

import S from "./styles";

const MAX_SUGGESTIONS = 4;

const InsightsChat = () => {
  const {
    messages,
    askQuestion,
    loadingAnswer,
    loading,
    error,
    suggestedQuestions
  } = useInsightsChat();
  const { dispatch } = useInsightReport();

  const subjectName = useSubjectName();

  const [question, setQuestion] = useState("");
  const [currentChatSectionHeight, setCurrentChatSectionHeight] = useState(0);
  const [isInputFocussed, setIsInputFocused] = useState(false);

  const chatSectionRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (chatSectionRef.current) {
      const newScrollHeight = chatSectionRef.current.scrollHeight;
      if (currentChatSectionHeight !== newScrollHeight) {
        chatSectionRef.current.scroll({
          top: newScrollHeight
        });
        setCurrentChatSectionHeight(newScrollHeight);
      }
    }
  }, [
    chatSectionRef.current?.scrollHeight,
    currentChatSectionHeight,
    messages
  ]);

  const onAskQuestion = () => {
    if (!question.length || loadingAnswer) {
      return;
    }
    askQuestion(question);
    setQuestion("");
  };

  const onCloseChat = () => {
    dispatch({ type: InsightReportActions.showPreviousSectionSlug });
  };

  const showSuggestions =
    messages.length === 0 &&
    isInputFocussed &&
    suggestedQuestions &&
    suggestedQuestions.length > 0;

  return (
    <S.InsightsChat>
      <S.HeaderContainer>
        <S.CloseButton onClick={onCloseChat}>
          <S.CloseButtonIcon />
        </S.CloseButton>
        <S.Heading>Ask Xapien</S.Heading>
        {error && (
          <Popover
            maxWidth="300px"
            content={
              <S.TooltipContent>
                There was an issue retrieving existing questions and answers.
              </S.TooltipContent>
            }
          >
            <S.Alert />
          </Popover>
        )}
      </S.HeaderContainer>
      <S.ChatAndInputSection>
        {loading ? (
          <S.ChatSection>
            <InsightsMessage loading />
            <InsightsMessage loading />
          </S.ChatSection>
        ) : (
          <>
            {!messages ||
              (messages.length === 0 && (
                <S.ChatDescription>
                  Ask Xapien {subjectName ? `about ${subjectName}` : ""} or use
                  one of the prompts to get you started...
                </S.ChatDescription>
              ))}
            <S.ChatSection ref={chatSectionRef}>
              {messages.map((message, index) => (
                <InsightsMessage key={index} message={message} />
              ))}
              {loadingAnswer && (
                <InsightsMessage
                  fetchingMessage
                  message={{ type: MessageType.Answer } as Message}
                />
              )}
            </S.ChatSection>
          </>
        )}
        <AnimatePresence>
          {showSuggestions && (
            <S.SuggestedQuestions
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              {suggestedQuestions
                ?.slice(0, MAX_SUGGESTIONS)
                ?.map(suggestion => (
                  <S.Suggestion
                    key={suggestion}
                    onClick={() => setQuestion(suggestion)}
                  >
                    <TruncateLength>{suggestion}</TruncateLength>
                  </S.Suggestion>
                ))}
            </S.SuggestedQuestions>
          )}
        </AnimatePresence>
        <S.AskInputContainer>
          <OutlinedInput
            placeholder={
              loadingAnswer
                ? "Working..."
                : `Ask Xapien a question about ${subjectName}...`
            }
            endAdornment={
              <InputAdornment position="end">
                <S.SendButton
                  aria-label="Send question"
                  onClick={() => onAskQuestion()}
                  disabled={loadingAnswer || loading}
                >
                  <S.Send />
                </S.SendButton>
              </InputAdornment>
            }
            onKeyDown={e => {
              if (e.key === "Enter") {
                onAskQuestion();
                inputRef.current?.blur();
              }
            }}
            onChange={e => setQuestion(e.target.value)}
            value={question}
            disabled={loadingAnswer || loading}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
            inputRef={inputRef}
          />
        </S.AskInputContainer>
      </S.ChatAndInputSection>
    </S.InsightsChat>
  );
};

export default InsightsChat;
