import React, { ReactNode, useState } from "react";
import businessAssociatePlaceholderImageIcon from "img/icons/profile-placeholder.svg";
import NewReportButton from "pages/report/NewReportButton";
import { toTitleCase } from "util/common";
import { TruncateLength } from "util/TruncateLength";
import { getAgeFromDob } from "util/getAgeFromDob";
import { ENTITY_TYPE, SUBJECT_CONTEXTS } from "pages/define/utils";
import PaginatedList from "components/molecules/PaginatedList";
import SocialMediaIcons from "components/molecules/SocialMediaIcons";
import {
  BestAvailability,
  OnlineProfile,
  PartialDate
} from "api/report/report-types";

import S from "./styles";

const DESCRIPTORS_MAX_LENGTH = 150;

interface Connection {
  text: string;
  url?: string;
}

interface DateRange {
  startDate?: PartialDate;
  endDate?: PartialDate;
}

interface Role extends DateRange {
  roleTitle: string;
}

interface Card {
  name: string;
  connectionList?: Connection[];
  contextToUse: string;
  nameToSendToNewReport: string;
  image?: string;
  dateOfBirth?: PartialDate;
  pscDates?: boolean;
  uboDates?: boolean;
  roles?: Role[];
  directorDates?: DateRange;
  onlineProfiles?: OnlineProfile[];
  addresses?: string[][];
  descriptors?: string;
  linkedInUrl?: string;
}

interface Props {
  cardData: Card;
  nameOnlySearch?: boolean;
  cardIndex?: number;
}

const BusinessAssociatesCard = ({
  cardData,
  nameOnlySearch,
  cardIndex
}: Props) => {
  const {
    name,
    connectionList = [],
    contextToUse,
    nameToSendToNewReport,
    image,
    dateOfBirth,
    pscDates,
    uboDates,
    roles = [],
    directorDates,
    onlineProfiles,
    addresses = [],
    descriptors,
    linkedInUrl
  } = cardData;

  const [imageErrored, setImageErrored] = useState(false);

  const connectionsLabel = connectionList.length > 1 ? "overlaps" : "overlap";

  const getDuration = ({
    startDate,
    endDate,
    isKnownToBeOngoing
  }: {
    startDate?: PartialDate;
    endDate?: PartialDate;
    isKnownToBeOngoing?: boolean;
  }) => {
    let durationString = ``;

    if (startDate && startDate?.year && endDate && endDate?.year) {
      durationString = `${startDate?.monthShort ?? ""} ${startDate.year} - ${
        endDate?.monthShort ?? ""
      } ${endDate.year}`;
    } else if (startDate && startDate.year) {
      durationString = `${startDate?.monthShort ?? ""} ${startDate.year}${
        isKnownToBeOngoing ? " - Present" : ""
      }`;
    } else if (endDate && endDate?.year) {
      durationString = `- ${endDate?.monthShort ?? ""} ${endDate.year}`;
    } else if (isKnownToBeOngoing) {
      durationString = "Present";
    } else {
      return null;
    }

    return <S.RoleDuration>({durationString})</S.RoleDuration>;
  };

  const getRole = () => {
    let roleString: ReactNode = "";
    if (directorDates) {
      roleString = (
        <span>
          Director{" "}
          {getDuration({
            startDate: directorDates.startDate,
            endDate: directorDates.endDate
          })}
        </span>
      );
    } else if (roles[0]) {
      roleString = (
        <span>
          {roles[0].roleTitle}{" "}
          {getDuration({
            startDate: roles[0].startDate,
            endDate: roles[0].endDate
          })}
        </span>
      );
    }

    return roleString;
  };

  let ageToDisplay;

  if (dateOfBirth && dateOfBirth.year && dateOfBirth.month && dateOfBirth.day) {
    ageToDisplay = getAgeFromDob(
      new Date(dateOfBirth.year, dateOfBirth.month - 1, dateOfBirth.day)
    );
  }

  let profileData = onlineProfiles?.map(profile => ({
    data: profile
  }));

  // Business Associates don't make use of onlineProfiles. Instead
  // they very rarely have a dedicated linkedInUrl field.
  if (linkedInUrl && linkedInUrl.length > 0) {
    profileData = [
      {
        data: {
          link: linkedInUrl,
          profileType: "linkedin",
          bestAvailability: BestAvailability.Available,
          isUncertain: null,
          label: null,
          sourceIds: null,
          sources: null,
          totalSourceCount: null
        }
      },
      ...(profileData ?? [])
    ];
  }

  const renderImage = () => {
    return image && !imageErrored ? (
      <S.Image
        image={image}
        alt="Business associate"
        onError={() => setImageErrored(true)}
        fallbackImage={businessAssociatePlaceholderImageIcon}
        width="74px"
        height="74px"
      />
    ) : (
      <S.PlaceholderIcon />
    );
  };

  return (
    <S.CardContainer>
      <S.CardInnerContainer>
        <S.CardContentContainer>
          <S.CardContent>
            <div>
              <S.CardTopSection>
                <S.CardImageSection>
                  {renderImage()}
                  <S.SocialMediaIcons>
                    {/* @ts-ignore  TODO: convert to ts */}
                    <SocialMediaIcons
                      showLabels={false}
                      profileData={profileData}
                    />
                  </S.SocialMediaIcons>
                </S.CardImageSection>
                <S.RightSideContainer>
                  <span>
                    {/* @ts-ignore  TODO: convert to ts */}
                    <NewReportButton
                      subject={nameToSendToNewReport}
                      contextList={
                        connectionList.length
                          ? connectionList.map(connection => ({
                              value: connection.text,
                              type: SUBJECT_CONTEXTS?.person?.organisation?.type
                            }))
                          : [
                              {
                                value: contextToUse,
                                type: SUBJECT_CONTEXTS?.person?.organisation
                                  ?.type
                              }
                            ]
                      }
                      name={
                        name && name === name.toUpperCase()
                          ? toTitleCase(name)
                          : name
                      }
                      image={renderImage()}
                      nameOnlySearch={nameOnlySearch}
                      subjectType={ENTITY_TYPE?.Person}
                    />
                  </span>
                </S.RightSideContainer>
              </S.CardTopSection>
              <S.CardSummary>
                <S.NameField>
                  <TruncateLength>
                    {name && name === name.toUpperCase()
                      ? toTitleCase(name)
                      : name}{" "}
                  </TruncateLength>
                </S.NameField>
                <S.SummaryFields>
                  {ageToDisplay && (
                    <S.DataFieldValue>{ageToDisplay} yrs</S.DataFieldValue>
                  )}
                  {roles.length ? (
                    <S.DataFieldValue>{getRole()}</S.DataFieldValue>
                  ) : null}
                  {uboDates && (
                    <S.DataFieldValue>
                      Ultimate beneficial owner
                    </S.DataFieldValue>
                  )}
                  {pscDates && (
                    <S.DataFieldValue>
                      Person of significant control
                    </S.DataFieldValue>
                  )}
                  {descriptors && (
                    <S.DataFieldValue>
                      <S.DescriptorValue>
                        {descriptors.length > DESCRIPTORS_MAX_LENGTH
                          ? `${descriptors.substring(
                              0,
                              DESCRIPTORS_MAX_LENGTH
                            )}...`
                          : descriptors}
                      </S.DescriptorValue>
                    </S.DataFieldValue>
                  )}
                </S.SummaryFields>
              </S.CardSummary>
            </div>
            {addresses && addresses.length ? (
              // @ts-ignore TODO
              <S.Accordion
                // set to false to not show - but I am leaving it here because I believe it will be asked for SEE CORE-108
                dataToDisplay={
                  <S.AdditionalFieldContainer>
                    <S.FieldLabel>
                      Director&apos;s correspondence address
                    </S.FieldLabel>
                    {addresses[0].map(line => (
                      <div key={line}>
                        <TruncateLength>{line}</TruncateLength>
                      </div>
                    ))}
                  </S.AdditionalFieldContainer>
                }
                id={`${name}-${cardIndex}-addresses`}
                title="Officership details"
                type="custom"
              />
            ) : null}
            {connectionList && connectionList.length ? (
              <PaginatedList
                dataToDisplay={connectionList}
                title={connectionList.length}
                subTitle={connectionsLabel}
                printKey={name.split(" ").join("-")}
                id={`${name}-${cardIndex}`}
              />
            ) : null}
          </S.CardContent>
        </S.CardContentContainer>
      </S.CardInnerContainer>
    </S.CardContainer>
  );
};

export default BusinessAssociatesCard;
