import React from "react";

import S from "./styles";

interface Props {
  error?: { name: string; message: string; stack: string };
}

class GlobalErrorScreen extends React.Component<Props> {
  buildBody() {
    try {
      const body = encodeURI(
        `Dear Support,\n\nI'm experiencing trouble accessing the platform, here is the diagnostic information:\n\n${this.buildDiagnosticData()}`
      );
      return body;
    } catch (e) {
      console.error(e);
      return encodeURI(
        "Dear Support,\n\nI'm experiencing trouble accessing the platform. Error code: 68"
      );
    }
  }

  buildDiagnosticData() {
    const { error } = this.props;
    try {
      if (error) {
        const { name, message, stack } = error;
        return [name, message, stack].join("\n");
      }
      return this.props ? JSON.stringify(this.props) : "Error code 67";
    } catch (e2) {
      console.error(e2);
      return "Error code 66";
    }
  }

  render() {
    const subject = encodeURI("Xapien Error Diagnostics");
    const body = this.buildBody();
    const mailToLink = `mailto:support@xapien.com?subject=${subject}&body=${body}`;
    return (
      <S.ErrorContainer>
        Something has gone very wrong.&nbsp;
        <a rel="noopener noreferrer" href={mailToLink}>
          Click here
        </a>
        &nbsp;to send an email with diagnostic information to Xapien support.
      </S.ErrorContainer>
    );
  }
}

export default GlobalErrorScreen;
