import React, { FC, SVGProps, useEffect, useState } from "react";

import { standardColors } from "styles/colors";
import { ChevronDown } from "react-feather";
import Popover from "components/atoms/Popover";
import MenuItem from "components/molecules/Menu/MenuItem";
import { Spinner } from "reactstrap";

import type { User } from "api/users";

import UsersApi from "api/users";

import ModalContainer from "components/molecules/ModalContainer";
import Heading from "components/atoms/Heading";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import ButtonNew from "components/atoms/ButtonNew";
import ModalSection from "components/atoms/ModalSection";
import ErrorBanner from "components/atoms/ErrorBanner";
import ModalSectionHeader from "components/atoms/ModalSectionHeader";

import UserPersonalDetails, {
  DetailsFormError,
  DetailsFormFields
} from "components/organisms/UserPersonalDetails";
import {
  CollectionListActions,
  useCollectionList
} from "util/hooks/useCollectionList";

import { Idam_Contracts_Enums_OrganisationRole } from "api/portal";
import S from "./styles";

interface Props {
  user: User["resource"];
  isOpen: boolean;
  toggleOpen: () => void;
}

const roleDropdownOptions = [
  {
    id: Idam_Contracts_Enums_OrganisationRole.USER,
    label: Idam_Contracts_Enums_OrganisationRole.USER
  },
  {
    id: Idam_Contracts_Enums_OrganisationRole.ADMIN,
    label: Idam_Contracts_Enums_OrganisationRole.ADMIN
  }
];

const UserSettingsModal = ({ isOpen, user, toggleOpen }: Props) => {
  const { dispatch: listDispatch } = useCollectionList();
  const [formErrors, setFormErrors] = useState<DetailsFormError[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const { userId, email, firstName, lastName, jobTitle, role } = user;

  const [userFirstName, setUserFirstName] = useState(firstName ?? "");
  const [userLastName, setUserLastName] = useState(lastName ?? "");
  const [userJobTitle, setUserJobTitle] = useState(jobTitle ?? "");
  const [userRole, setUserRole] =
    useState<Idam_Contracts_Enums_OrganisationRole>(
      role ?? Idam_Contracts_Enums_OrganisationRole.USER
    );

  useEffect(() => {
    setUserRole(role ?? Idam_Contracts_Enums_OrganisationRole.USER);
    setUserFirstName(firstName ?? "");
    setUserLastName(lastName ?? "");
    setUserJobTitle(jobTitle ?? "");
  }, [firstName, lastName, jobTitle, role]);

  const validateFormInputs = () => {
    const errors: DetailsFormError[] = [];

    if (!userFirstName) {
      errors.push({ type: DetailsFormFields.FirstName });
    }

    if (!userLastName) {
      errors.push({ type: DetailsFormFields.LastName });
    }
    return errors;
  };

  const onRoleOptionChange = (newRole: {
    id: Idam_Contracts_Enums_OrganisationRole;
    label: Idam_Contracts_Enums_OrganisationRole;
  }) => {
    if (!userId) {
      return;
    }
    const previousRole = userRole;
    setUserRole(newRole.id);

    setIsLoading(true);
    const api = new UsersApi();

    api
      .changeRole({ userId, role: newRole.id })
      .then(result => {
        if (!result.status) {
          setUserRole(previousRole);
        }

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setUserRole(previousRole);
      });
  };

  const inputsChanged =
    firstName !== userFirstName ||
    lastName !== userLastName ||
    (jobTitle ?? "") !== userJobTitle ||
    role !== userRole;

  const onSaveUserDetails = async () => {
    if (isLoading) {
      return;
    }

    // Reset form errors on resave/retry
    setFormErrors([]);
    setError(undefined);

    if (!userId) {
      return;
    }

    if (!inputsChanged) {
      toggleOpen();
      return;
    }

    const errors = validateFormInputs();

    if (errors.length !== 0) {
      setFormErrors(errors);
    } else {
      setIsLoading(true);
      const api = new UsersApi();

      api
        .updateUser({
          userId,
          firstName: userFirstName,
          lastName: userLastName,
          jobTitle: userJobTitle
        })
        .then(result => {
          setIsLoading(false);
          if (result.status) {
            listDispatch({
              type: CollectionListActions.updateCollectionItem,
              id: "users",
              item: {
                id: userId,
                title: `${userFirstName} ${userLastName}`,
                context: email ?? "",
                resource: {
                  userId,
                  firstName: userFirstName,
                  lastName: userLastName,
                  email,
                  role: userRole,
                  groups: user.groups
                }
              }
            });
            toggleOpen();
          } else {
            setError(result.message);
          }
        })
        .catch(e => {
          console.error(e);
          setIsLoading(false);
          setError("An unexpected error occured.");
        });
    }
  };

  const renderRoleDropdown = () => {
    const dropdownMenu = (
      <S.Menu>
        {roleDropdownOptions.map(option => {
          return (
            <MenuItem
              key={option.id}
              onMenuItemClick={() => {
                onRoleOptionChange(option);
                setIsDropdownOpen(false);
              }}
              text={option.label}
              disabled={option.id === userRole}
            />
          );
        })}
      </S.Menu>
    );

    return (
      <Popover
        // @ts-ignore
        isOpenOverride={isDropdownOpen}
        // @ts-ignore
        onRequestClose={() => setIsDropdownOpen(false)}
        alignment="bottom-end"
        hideArrow
        trigger="click"
        content={dropdownMenu}
        disableHideOnClip={undefined}
        className={undefined}
        style={undefined}
      >
        <S.OptionsButton
          type="button"
          onClick={() => setIsDropdownOpen(prev => !prev)}
        >
          {userRole}{" "}
          <ChevronDown
            size={16}
            color={standardColors.white}
            style={{ marginBottom: 4 }}
          />
        </S.OptionsButton>
      </Popover>
    );
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      toggleOpen={onSaveUserDetails}
      onExitClick={toggleOpen}
      title="User settings"
    >
      <S.ModalContent>
        <ModalSection>
          <Heading level={5}>Personal details</Heading>

          {error && (
            <ErrorBanner text={error} onClick={() => setError(undefined)} />
          )}

          <UserPersonalDetails
            {...{
              userFirstName,
              userLastName,
              userJobTitle,
              userEmail: email ?? "",
              formErrors
            }}
            onUserFirstNameChange={value => setUserFirstName(value)}
            onUserLastNameChange={value => setUserLastName(value)}
            onUserJobTitleChange={value => setUserJobTitle(value)}
          />
        </ModalSection>

        <ModalSection>
          <ModalSectionHeader>
            <Heading level={6}>Account type</Heading>

            {renderRoleDropdown()}
          </ModalSectionHeader>
        </ModalSection>
      </S.ModalContent>
      <ButtonNew
        text="Done"
        type={ButtonType.Filled}
        size={ButtonSize.Medium}
        onClick={onSaveUserDetails}
        disabled={isLoading}
        IconTrailing={
          isLoading
            ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
            : undefined
        }
      />
    </ModalContainer>
  );
};

export default UserSettingsModal;
