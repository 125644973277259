import React, { Fragment } from "react";

import WithInspector from "components/organisms/WithInspector";
import { formatDate } from "util/common";

import S from "./styles";

const ListItem = ({
  item,
  withoutBulletPoint,
  subTextWithoutSourcing,
  textIsWrappedWithSourcing
}) => {
  const customClasses = item.classes || {};
  const renderModifers = modifiers => {
    if (!modifiers || modifiers.length === 0) {
      return null;
    }
    const modifierArr = modifiers.map((m, i) => {
      return (
        <Fragment key={`ListItemModifier-${m.modifierText}-${i}`}>
          <WithInspector
            sources={m.source}
            topSectionElement={m.modifierText}
            display="inline"
            highlightPadding="1px"
          >
            {`${m.modifierText}`}
            <S.ReferenceSuperscript>{` [${m.source.length}] `}</S.ReferenceSuperscript>
            {i !== modifiers.length - 1 && " "}
          </WithInspector>
        </Fragment>
      );
    });

    return <div className="subtext">{modifierArr}</div>;
  };
  const renderAdditionalInfo = addInfo => {
    if (!addInfo || addInfo.length === 0) {
      return null;
    }
    return (
      <div className="subtext">
        {addInfo.map((a, i) => (
          <Fragment key={`ListItemAdditionalInfo-${a.text}-${i}`}>
            <WithInspector
              sources={a.sources}
              topSectionElement={a.text}
              display="inline"
              highlightPadding="1px"
            >
              {`${a.text}`}
              <S.ReferenceSuperscript>{` [${a.sources.length}] `}</S.ReferenceSuperscript>
            </WithInspector>
            {i !== addInfo.length - 1 && ", "}
            {a.modifiers.length > 0 && (
              <>
                <span>(</span>
                {renderModifers(a.modifiers)}
                <span>)</span>
              </>
            )}
          </Fragment>
        ))}
      </div>
    );
  };

  const renderDates = () => {
    const renderDateString = () => {
      if (item.firstDate && item.lastDate) {
        const formattedFirstDate = formatDate(item.firstDate);
        const formattedLastDate = formatDate(item.lastDate);
        if (formattedFirstDate !== formattedLastDate) {
          return `${
            item.isDateUncertain ? "~" : ""
          }(${formattedFirstDate} - ${formattedLastDate})`;
        }
      }
      if (item.firstDate) {
        return `${item.isDateUncertain ? "~" : ""}(${item.firstDate.year})`;
      }
      if (item.lastDate) {
        return `${item.isDateUncertain ? "~" : ""}(${item.lastDate.year})`;
      }
      if (item.dateDurations && item.dateDurations.length > 0) {
        return `${item.isDateUncertain ? "~" : ""}(${item.dateDurations.join(
          ", "
        )})`;
      }

      return "";
    };
    if (item.firstDate || item.lastDate || item.dateDurations) {
      const dateString = renderDateString();
      if (!dateString) return null;
      return (
        <span className="font__color--regular font__size--tiny font__weight--super-light">
          {`${dateString} `}
        </span>
      );
    }
    return null;
  };

  const renderMainText = () => {
    const body = (
      <>
        {item.text} {!item.subText && renderDates()}
      </>
    );

    if (!textIsWrappedWithSourcing) {
      return (
        <WithInspector
          sources={item.sources}
          topSectionElement={body}
          display="inline"
          highlightPadding="1px"
        >
          {body}
          {item.sources?.length > 0 && !item.hideSourcing && (
            <S.ReferenceSuperscript>{` [${item.sources.length}] `}</S.ReferenceSuperscript>
          )}
        </WithInspector>
      );
    }
    return body;
  };

  return (
    <li className={customClasses.listItemClassName}>
      <div className="d-flex">
        {!withoutBulletPoint && (
          <div>
            <span className="list-bullet" />
          </div>
        )}
        <div>
          {item.title && <div>{item.title}</div>}
          {renderMainText()}
          {item.secondaryInfo && (
            <div className="experimental-section__secondary-info">
              ({item.secondaryInfo}
              {") "}
            </div>
          )}
          {item.subText &&
            (item.sources?.length > 0 && !subTextWithoutSourcing ? (
              <WithInspector
                sources={item.sources}
                topSectionElement={
                  <div className="subtext">
                    {item.subText} {renderDates()}
                  </div>
                }
                display="inline"
                highlightPadding="1px"
              >
                <div className="subtext">
                  {item.subText} {renderDates()}
                </div>
                <S.ReferenceSuperscript>{` [${item.sources.length}] `}</S.ReferenceSuperscript>
              </WithInspector>
            ) : (
              <>
                <div className="subtext">{item.subText} </div> {renderDates()}
              </>
            ))}
          {renderAdditionalInfo(item.additionalInfo)}
          {renderModifers(item.modifiers)}
        </div>
      </div>
    </li>
  );
};

export default ListItem;
