import React, { useImperativeHandle, useState } from "react";

import { AnimatePresence } from "framer-motion/dist/framer-motion";

import S from "./styles";

export const DIRECTION = {
  forward: 1,
  backward: -1
};

const variants = {
  enter: {
    x: 0
  },
  exit: {
    x: 600
  }
};

const Pages = React.forwardRef(({ pages, onPageChange = () => {} }, ref) => {
  const [pageVisibilities, setPageVisibilities] = useState(
    pages?.map((_, index) => (index === 0 ? true : false))
  );

  const setPage = direction => {
    // Get current active page
    let page;
    for (let i = pageVisibilities.length - 1; i >= 0; i--) {
      if (pageVisibilities[i]) {
        page = i;
        break;
      }
    }

    // No action if you are on the last or first page
    if (
      (page === pageVisibilities?.length - 1 &&
        direction === DIRECTION.forward) ||
      (page === 0 && direction === DIRECTION.backward)
    ) {
      return;
    }

    if (direction === DIRECTION.forward) {
      const nextPage = page + 1;
      setPageVisibilities(prevPageVisibilities => [
        ...prevPageVisibilities.slice(0, nextPage),
        !prevPageVisibilities[nextPage],
        ...prevPageVisibilities.slice(nextPage + 1)
      ]);
      onPageChange(nextPage);
    } else {
      const prevPage = page - 1;
      setPageVisibilities(prevPageVisibilities => [
        ...prevPageVisibilities.slice(0, page),
        !prevPageVisibilities[page],
        ...prevPageVisibilities.slice(page + 1)
      ]);
      onPageChange(prevPage);
    }
  };

  // Expose `setPage` method to parent
  useImperativeHandle(ref, () => ({
    switchPageByDirection(direction) {
      setPage(direction);
    }
  }));

  return (
    <S.PagesContainer>
      {pages?.map((page, index) => {
        return (
          <AnimatePresence initial={false} key={index}>
            {pageVisibilities[index] && (
              <S.InteractiveWrapper
                variants={variants}
                transition={{
                  x: { type: "spring", duration: 0.8, bounce: 0, delay: 0.2 }
                }}
                initial="exit"
                animate="enter"
                exit="exit"
              >
                <S.InteractiveWrapperContent
                  isPagePreviousPage={pageVisibilities[index + 1]}
                >
                  {page}
                </S.InteractiveWrapperContent>
              </S.InteractiveWrapper>
            )}
          </AnimatePresence>
        );
      })}
    </S.PagesContainer>
  );
});

export default Pages;
