export enum RegistrationActions {
  updateField = "updateField",
  updateTermsAccepted = "updateTermsAccepted",
  fieldsSubmitted = "fieldsSubmitted",
  registrationSubmitted = "registrationSubmitted",
  editFields = "editFields",
  registrationFailed = "registrationFailed",
  dismissError = "dismissError",
  tokenExpired = "tokenExpired"
}

export enum RegistrationStatus {
  unregistered = "unregistered",
  fieldsSubmitted = "fieldsSubmitted",
  registrationSubmitted = "registrationSubmitted",
  tokenExpired = "tokenExpired"
}

export interface RegistrationState {
  token: string;
  email: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  password: string;
  confirmationPassword: string;
  status: RegistrationStatus;
  termsAccepted: boolean;
  tokenExpiry: string;
  error?: string;
  isMigration: boolean;
}

interface UpdateFieldAction {
  type: RegistrationActions.updateField;
  field: string;
  value: string;
}

interface TokenExpiredAction {
  type: RegistrationActions.tokenExpired;
}

interface UpdateTermsAcceptedAction {
  type: RegistrationActions.updateTermsAccepted;
  value: boolean;
}

interface RegistrationSubmittedAction {
  type: RegistrationActions.registrationSubmitted;
}

interface FieldsSubmittedAction {
  type: RegistrationActions.fieldsSubmitted;
}

interface EditFieldsAction {
  type: RegistrationActions.editFields;
}

interface RegistrationFailedAction {
  type: RegistrationActions.registrationFailed;
  error: string;
}

interface DismissErrorAction {
  type: RegistrationActions.dismissError;
}

export type RegistrationAction =
  | UpdateFieldAction
  | UpdateTermsAcceptedAction
  | RegistrationSubmittedAction
  | FieldsSubmittedAction
  | EditFieldsAction
  | RegistrationFailedAction
  | DismissErrorAction
  | TokenExpiredAction;
