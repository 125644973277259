import React, {
  forwardRef,
  useState,
  useLayoutEffect,
  ReactNode,
  SetStateAction,
  Dispatch,
  Ref
} from "react";
import { Collapse } from "reactstrap";
import ErrorBoundary from "util/ErrorBoundary";

import { usePrintableReportState } from "util/hooks/usePrintableState";
import { scrollToTopOfComponent } from "util/common";

import SectionErrorContents from "./Error";
import { SectionHeader } from "./SectionHeader";

type SectionProps = {
  children: ReactNode;
  defaultExpanded?: boolean;
  potentialSectionCount?: number;
  sectionCount?: number;
  isSubjectSection?: boolean;
  title: string;
  className?: string;
  showHeader?: boolean;
  isContractable?: boolean;
  shouldUnmountOnCollapse?: boolean;
};

type requireBothOrNeitherExpandedProps =
  | {
      isExpanded: boolean;
      setExpanded: Dispatch<SetStateAction<boolean>>;
    }
  | { isExpanded?: never; setExpanded?: never };

export const Section = forwardRef(
  (
    props: SectionProps & requireBothOrNeitherExpandedProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const {
      children,
      defaultExpanded,
      potentialSectionCount,
      sectionCount,
      isSubjectSection,
      title,
      isExpanded: isExpandedProp,
      setExpanded: setExpandedProp,
      className,
      showHeader = true,
      isContractable = true,
      shouldUnmountOnCollapse = false
    } = props;
    const key = title;

    // allowing component to control own expand/contract state if none is passed in
    const useInternalExpandedState =
      isExpandedProp === undefined && setExpandedProp === undefined;
    const [isExpandedInternalState, setExpandedInternalState] =
      usePrintableReportState(
        `section-${key}-expanded-internal`,
        defaultExpanded
      );
    const isExpanded = useInternalExpandedState
      ? isExpandedInternalState
      : isExpandedProp;
    const setExpanded = useInternalExpandedState
      ? setExpandedInternalState
      : setExpandedProp;

    const toggleExpanded = () => {
      scrollToTopOfComponent(ref);
      setExpanded(!isExpanded);
    };

    const [, setDisplayScrollToBottom] = useState(false);
    const [, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);

    // scroll to next section functionality
    useLayoutEffect(() => {
      const container = window;
      const intervalInternal = 200;
      let throttleTimeout: NodeJS.Timeout | undefined;

      const isScrolledIntoView = (el: Element) => {
        const rect = el.getBoundingClientRect();
        return (
          rect.top < window.innerHeight && rect.bottom >= window.innerHeight
        );
      };

      if (container) {
        const callback = () => {
          throttleTimeout = undefined;
          if (ref && "current" in ref && ref.current !== null) {
            setDisplayScrollToBottom(isScrolledIntoView(ref.current));
          }
        };
        const handleScroll = () => {
          if (throttleTimeout === undefined) {
            throttleTimeout = setTimeout(callback, intervalInternal);
          }
        };
        container.addEventListener("scroll", handleScroll);

        return () => {
          if (throttleTimeout) {
            window.clearTimeout(throttleTimeout);
          }
          container.removeEventListener("scroll", handleScroll);
        };
      }
      return undefined;
    });

    const sectionContents = () => (
      <ErrorBoundary
        FallbackComponent={SectionErrorContents}
        onError={(e: any) => console.error("Error rendering section", e, title)}
      >
        {children}
      </ErrorBoundary>
    );
    return (
      <div
        className={`report-section ${
          isSubjectSection ? "subject-section" : ""
        }`}
        ref={ref}
      >
        {showHeader && (
          <SectionHeader
            {...{
              toggleExpanded,
              title,
              sectionCount,
              isSubjectSection,
              potentialSectionCount,
              isExpanded,
              isContractable
            }}
          />
        )}
        <Collapse isOpen={isExpanded} {...{ className }}>
          {shouldUnmountOnCollapse && !isExpanded ? null : sectionContents()}
        </Collapse>
      </div>
    );
  }
);
