import { styled } from "@linaria/react";
import { grey } from "styles/colors";
import { fontSize } from "styles/fonts";

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Question = styled.p`
  margin: 0;
  font-size: ${fontSize.sm};
  font-style: normal;
  font-weight: 600;
  line-height: 1.625rem;
  color: ${grey.dark};
`;

const QuestionDate = styled.p`
  margin: 0;
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

const S = {
  QuestionContainer,
  Question,
  QuestionDate
};

export default S;
