import React from "react";

import DiagnosticOverview from "pages/diagnostics/DiagnosticsOverview";
import withMountTransition from "pages/define/withMountTransition";
import Loading from "components/atoms/Loading";
import { REPORT_TYPES } from "util/reportTypes";

import S from "./styles";

const PreparingReport = ({ enquiryId, messages }) => {
  // HACK HACK HACK
  // Extract the first status message as this message contains the
  // Subject along with its type (Person/Organisation).
  const firstMessage = messages && messages[0];
  let reportEntityType;

  if (firstMessage) {
    // Looking for the string "Subject: (Person)" for example
    const entityTypeRegex = new RegExp(
      `Subject: \\((${REPORT_TYPES.person}|${REPORT_TYPES.organisation})\\)`
    );

    // Extract the entity type from the message
    const regexOutput = firstMessage.match(entityTypeRegex);

    // If we fail to find a type then just fallback to Person.
    reportEntityType = regexOutput ? regexOutput[1] : REPORT_TYPES.person;
  }

  return (
    <S.Container>
      <S.InnerContainer>
        <div style={{ maxHeight: "15%" }} />
        <S.DetailsContainer>
          <Loading />
          <S.Heading className="large" style={{ marginTop: 0 }}>
            Generating your report...
          </S.Heading>
          {firstMessage && (
            <S.DetailText>
              Your report is being prepared and is expected to take
              approximately{" "}
              {reportEntityType === REPORT_TYPES.organisation
                ? "20-30"
                : "5-10"}{" "}
              minutes to complete
            </S.DetailText>
          )}
          <DiagnosticOverview enquiryId={enquiryId} />
        </S.DetailsContainer>
      </S.InnerContainer>
    </S.Container>
  );
};

export default withMountTransition(PreparingReport);
