import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { routes } from "pages/Router";
import useUserSettings from "util/hooks/useUserSettings";
import { useAuthentication } from "util/hooks/useAuthentication";
import MySettingsModal from "components/organisms/MySettingsModal";
import AutoShareModal from "components/organisms/AutoShareModal";
import { AuthenticationStatus } from "util/hooks/useAuthentication/types";
import ManageOrganisationModal from "components/organisms/ManageOrganisationModal";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import InviteUserModal from "components/molecules/InviteUserModal";
import ContactAdminModal from "components/organisms/ContactAdminModal";
import { usePrintModeEnabled } from "util/hooks/useIsPrintModeEnabled";

import theme from "theme";
import ProfilePopover from "./ProfilePopover";

import S from "./styles";

const GlobalNavigationBar = () => {
  const printModeEnabled = usePrintModeEnabled();
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const {
    state: { isSettingsModalOpen, isAutoshareModalOpen, isInviteUserModalOpen },
    toggleSettingsModal,
    toggleAutoshareModal,
    toggleInviteUserModal
  } = useUserSettings();
  const {
    state: {
      status,
      permissions: { canListGroups, canListReports, canStartReports }
    }
  } = useAuthentication();

  const [isManageOrganisationModalOpen, setIsManageOrganisationModalOpen] =
    useState(false);
  const [isContactAdminModalOpen, setIsContactAdminModalOpen] = useState(false);
  const toggleManageOrganisationModal = useCallback(
    () => setIsManageOrganisationModalOpen(!isManageOrganisationModalOpen),
    [isManageOrganisationModalOpen, setIsManageOrganisationModalOpen]
  );
  const toggleContactAdminModal = () => {
    setIsContactAdminModalOpen(prev => !prev);
  };

  const navigate = useNavigate();

  // Dismiss the profile menu when any modal is invoked from it
  if (
    (isSettingsModalOpen || isManageOrganisationModalOpen) &&
    isProfileMenuOpen
  ) {
    setIsProfileMenuOpen(false);
  }

  const location = useLocation();

  if (printModeEnabled) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  if (status !== AuthenticationStatus.authenticated) {
    return null;
  }

  return (
    <S.GlobalNavigationBar>
      <>
        <S.NavItemContainer>
          <S.LogoNavLink to="/">
            {theme.navigation?.logo ?? <S.Logo />}
          </S.LogoNavLink>
        </S.NavItemContainer>
        <S.NavItems>
          {canStartReports && (
            <S.NewReport
              onClick={() => navigate(`/${routes.search}`)}
              size={ButtonSize.Medium}
              type={ButtonType.Filled}
              text="New report"
            />
          )}

          {canListReports && (
            <S.NavLink
              selected={location.pathname === `/${routes.reports}`}
              to={`/${routes.reports}`}
            >
              Reports
            </S.NavLink>
          )}

          {canListGroups && (
            <S.NavLink
              selected={location.pathname === `/${routes.groups}`}
              to={`/${routes.groups}`}
            >
              Groups
            </S.NavLink>
          )}
        </S.NavItems>
        <S.NavItemContainer>
          <ProfilePopover
            isManageOrganisationModalOpen={isManageOrganisationModalOpen}
            setIsContactAdminModalOpen={setIsContactAdminModalOpen}
            setIsManageOrganisationModalOpen={setIsManageOrganisationModalOpen}
          />
        </S.NavItemContainer>
        {isSettingsModalOpen && (
          <MySettingsModal
            isOpen={isSettingsModalOpen}
            toggleOpen={toggleSettingsModal}
          />
        )}
        {isAutoshareModalOpen && (
          <AutoShareModal
            isOpen={isAutoshareModalOpen}
            toggleOpen={toggleAutoshareModal}
          />
        )}
        {isManageOrganisationModalOpen && (
          <ManageOrganisationModal
            isOpen={isManageOrganisationModalOpen}
            toggleOpen={toggleManageOrganisationModal}
          />
        )}
        {isInviteUserModalOpen && (
          <InviteUserModal
            isOpen={isInviteUserModalOpen}
            onToggleOpen={toggleInviteUserModal}
          />
        )}
        {isContactAdminModalOpen && (
          <ContactAdminModal
            isOpen={isContactAdminModalOpen}
            toggleOpen={toggleContactAdminModal}
          />
        )}
      </>
    </S.GlobalNavigationBar>
  );
};

export default GlobalNavigationBar;
