import React, { ReactElement, useEffect, Fragment, useRef } from "react";
import { ReactComponent as AskXapienIcon } from "img/icons/ask-xapien.svg";

import {
  useInsightReport,
  InsightReportActions
} from "util/hooks/useInsightReport";

import { useSubjectName } from "util/hooks/useSubjectName";

import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

import InsightsChat from "components/organisms/InsightsChat";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";

import SectionNavigation from "components/organisms/InsightReport/SectionNavigation";
import ContentNode from "components/organisms/InsightReport/ContentNode";

import { ASK_XAPIEN_SECTION_SLUG } from "components/organisms/InsightReport/types";

import S from "./styles";
import Icon from "./icons";

// TODO: The backend should be returning what icon to use for the section. Remove this when it does
const SectionIcon = ({ section }: { section: string }) => {
  const sectionIcons: Record<string, ReactElement> = {
    biographical: <Icon.Biographical />,
    "biographical details": <Icon.Biographical />,
    "business interests": <Icon.BusinessInterests />,
    career: <Icon.BusinessInterests />,
    "character and achievements": <Icon.CharacterAchievements />,
    controversy: <Icon.Controversy />,
    "crime and controversy": <Icon.CrimeControversy />,
    esg: <Icon.ESG />,
    "executive summary": <Icon.Overview />,
    "family, friends, and associates": <Icon.FamilyFriendsAssociates />,
    finances: <Icon.Wealth />,
    "global reach": <Icon.GlobalReach />,
    "key details": <Icon.KeyDetails />,
    "key people": <Icon.KeyPeople />,
    "legal regulatory and compliance": <Icon.LegalRegulatoryCompliance />,
    "legal, regulatory, and compliance": <Icon.LegalRegulatoryCompliance />,
    locations: <Icon.GlobalReach />,
    ownership: <Icon.Ownership />,
    "ownership and group structure": <Icon.Ownership />,
    philanthropy: <Icon.Wealth />,
    "political exposure": <Icon.PoliticalExposure />,
    "sanctions exposure": <Icon.SanctionsExposure />,
    wealth: <Icon.Wealth />
  };

  const icon = sectionIcons[section.toLowerCase()];

  return icon ?? <Icon.Unknown />;
};

const Report = () => {
  const { state, dispatch } = useInsightReport();
  const subjectName = useSubjectName();

  const { report, activeSectionSlug } = state;

  const contentSectionRef = useRef<HTMLDivElement>(null);

  const activeSection = report
    ? report.sections.find(({ slug }) => slug === activeSectionSlug)
    : undefined;

  useEffect(() => {
    contentSectionRef.current?.scroll({
      top: 0,
      behavior: "smooth"
    });
  }, [activeSectionSlug]);

  const onShowAskXapien = () => {
    dispatch({
      type: InsightReportActions.updateActiveSectionSlug,
      slug: ASK_XAPIEN_SECTION_SLUG
    });
  };

  const updateActiveSectionSlug = (slug: string) => {
    dispatch({
      type: InsightReportActions.updateActiveSectionSlug,
      slug
    });
  };

  const onNextSection = () => {
    if (!report) return;

    const activeSlugIndex = report.sections.findIndex(
      section => section.slug === activeSectionSlug
    );
    const nextSlugIndex = activeSlugIndex + 1;

    if (report.sections.length === nextSlugIndex) return;

    updateActiveSectionSlug(report.sections[nextSlugIndex].slug);
  };

  const onPreviousSection = () => {
    if (!report) return;

    const activeSlugIndex = report.sections.findIndex(
      section => section.slug === activeSectionSlug
    );
    const previousSlugIndex = activeSlugIndex - 1;

    if (previousSlugIndex < 0) return;

    updateActiveSectionSlug(report.sections[previousSlugIndex].slug);
  };

  return (
    <>
      <S.Menu>
        {report &&
          report.sections.map(({ slug, title }) => (
            <S.MenuItem
              key={`MenuItem-${slug}`}
              active={slug === activeSectionSlug}
              onClick={() => updateActiveSectionSlug(slug)}
            >
              <S.MenuItemContent>
                <SectionIcon section={slug} />
                <span title={title}>{title}</span>
              </S.MenuItemContent>
              <S.MenuItemIndicator
                className="MenuItemIndicator"
                active={slug === activeSectionSlug}
              />
            </S.MenuItem>
          ))}
        <S.AskXapienContainer>
          <S.AskXapienButton
            selected={activeSectionSlug === ASK_XAPIEN_SECTION_SLUG}
            onClick={onShowAskXapien}
            IconLeading={AskXapienIcon}
            type={ButtonType.Filled}
            size={ButtonSize.Large}
            text="Ask Xapien"
          />
        </S.AskXapienContainer>
      </S.Menu>

      <S.ContentContainer>
        <S.ContentSection
          activeSectionSlug={activeSectionSlug}
          ref={contentSectionRef}
        >
          <AnimatePresence exitBeforeEnter>
            {activeSectionSlug === ASK_XAPIEN_SECTION_SLUG ? (
              <InsightsChat />
            ) : (
              activeSection && (
                <motion.div
                  key={`${activeSection.slug}-container`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {activeSection.content.map((content, index) => (
                    <Fragment
                      key={`${activeSection.slug}-${content.nodeType}-${index}`}
                    >
                      <ContentNode
                        subject={subjectName}
                        section={activeSection.title}
                        {...content}
                      />
                    </Fragment>
                  ))}
                </motion.div>
              )
            )}
          </AnimatePresence>
        </S.ContentSection>
        {activeSectionSlug !== ASK_XAPIEN_SECTION_SLUG && (
          <>
            <S.SectionNavigationBlur />
            <SectionNavigation
              sections={report?.sections}
              activeSectionSlug={activeSectionSlug}
              onNext={onNextSection}
              onPrevious={onPreviousSection}
            />
          </>
        )}
      </S.ContentContainer>
    </>
  );
};
export default Report;
