import type {
  Report as RawReport,
  Subsection as RawSubsection
} from "api/insights";

import {
  DivContentNode,
  H1ContentNode,
  H2ContentNode,
  H3ContentNode,
  InsightReport,
  InsightReportSection,
  TextContentNode,
  PContentNode,
  ContentNode
} from "api/insight-reports/types";

const getSummaryContent = (summary: RawSubsection) => {
  const summaryContent = summary.elements.flatMap(element => {
    const titleContentNode = element.title
      ? ({
          id: element.id,
          nodeType: "h2",
          value: "",
          content: [{ id: element.id, value: element.title, nodeType: "text" }]
        } as H2ContentNode)
      : undefined;

    const textContentNodes: TextContentNode[] = element.cited_fragments.map(
      fragment => {
        const supportingSentences =
          fragment.supporting_source_sentences.length > 0
            ? fragment.supporting_source_sentences.map(
                ({ title, text, wam_source_ids: wamSourceIds }) => ({
                  title,
                  text,
                  wamSourceIds
                })
              )
            : [];

        return {
          id: element.id,
          nodeType: "text",
          value: fragment.text,
          supportingSentences
        } as TextContentNode;
      }
    );

    const paragraphContentNode: PContentNode = {
      id: element.id,
      nodeType: "p",
      content: textContentNodes
    } as PContentNode;

    if (titleContentNode) {
      return {
        id: element.id,
        nodeType: "div",
        value: "",
        content: [titleContentNode, paragraphContentNode] as ContentNode[]
      } as DivContentNode;
    }
    return paragraphContentNode;
  });

  const summaryTitleContent = {
    id: summary.id,
    nodeType: "div",
    value: "",
    content: [
      {
        id: summary.id,
        nodeType: "h1",
        value: "",
        content: [
          {
            id: summary.id,
            value: summary.title,
            nodeType: "text"
          } as TextContentNode
        ]
      } as H1ContentNode,
      ...summaryContent
    ]
  } as DivContentNode;

  return [summaryTitleContent];
};

export const convertRawReport = (
  reportId: string,
  rawReport: RawReport
): InsightReport => {
  const { executive_summary: summary, sections } = rawReport;

  const reportSections = Object.entries(sections)
    .map(([_index, { ordinal, id, title, subsections }]) => {
      const content = subsections.flatMap(section => {
        const titleSectionNode = section.title
          ? ({
              id: section.id,
              nodeType: "h2",
              value: "",
              content: [
                {
                  id: section.id,
                  value: section.title,
                  nodeType: "text"
                } as TextContentNode
              ]
            } as H2ContentNode)
          : undefined;

        const sectionContentNodes = section.elements.flatMap(element => {
          const titleElementNode = element.title
            ? ({
                id: element.id,
                nodeType: "h3",
                value: "",
                content: [
                  {
                    id: element.id,
                    value: element.title,
                    nodeType: "text"
                  } as TextContentNode
                ]
              } as H3ContentNode)
            : undefined;

          const textElementNodes: TextContentNode[] =
            element.cited_fragments.length > 0
              ? element.cited_fragments.map(fragment => {
                  const supportingSentences =
                    fragment.supporting_source_sentences.length > 0
                      ? fragment.supporting_source_sentences.map(
                          ({
                            title: supportingSourceSentenceTitle,
                            text,
                            wam_source_ids: wamSourceIds
                          }) => ({
                            title: supportingSourceSentenceTitle,
                            text,
                            wamSourceIds
                          })
                        )
                      : [];
                  return {
                    id: element.id,
                    nodeType: "text",
                    value: fragment.text,
                    isUnknown: !!section.is_unknown,
                    supportingSentences
                  } as TextContentNode;
                })
              : [
                  {
                    id: element.id,
                    nodeType: "text",
                    value: element.text,
                    isUnknown: !!section.is_unknown
                  } as TextContentNode
                ];

          const paragraphContentNode: PContentNode = {
            id: element.id,
            nodeType: "p",
            isSourced: !!section.is_sourced,
            content: textElementNodes
          };

          if (titleElementNode) {
            return {
              id: element.id,
              nodeType: "div",
              value: "",
              content: [titleElementNode, paragraphContentNode] as ContentNode[]
            } as DivContentNode;
          }
          return paragraphContentNode;
        });

        const divContentNode = {
          id: section.id,
          nodeType: "div",
          content: [titleSectionNode, ...sectionContentNodes].filter(
            Boolean
          ) as ContentNode[]
        } as DivContentNode;

        return [divContentNode];
      });

      const titleContentNode = {
        id,
        nodeType: "div",
        value: "",
        content: [
          {
            id,
            nodeType: "h1",
            title,
            value: "",
            content: [{ id, value: title, nodeType: "text" } as TextContentNode]
          } as H1ContentNode,
          ...content
        ]
      } as DivContentNode;

      const section = {
        slug: title ?? "Unknown",
        ordinal,
        id,
        title,
        content: [titleContentNode]
      };

      return section;
    })
    .reduce(
      (
        acc: (InsightReportSection & { ordinal: number })[],
        curr: InsightReportSection & { ordinal: number }
      ) => {
        const index = acc.findIndex(item => curr.ordinal < item.ordinal);
        if (index !== -1) {
          acc.splice(index, 0, curr);
        } else {
          acc.push(curr);
        }
        return acc;
      },
      []
    )
    .map(section => {
      const { ordinal: _, ...sectionWithoutOrdinal } = section;

      return sectionWithoutOrdinal;
    });

  const summarySection = {
    id: summary.id,
    title: summary.title,
    slug: summary.title,
    content: getSummaryContent(summary)
  };

  const allSections = ([summarySection] as any[]).concat(
    reportSections
  ) as InsightReportSection[];

  const report: InsightReport = {
    id: reportId,
    subject: "unknown", // TODO: Pull this from main report?
    sections: allSections
  };

  return report;
};
