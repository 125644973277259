import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Heading from "components/atoms/Heading";
import Input from "components/atoms/Input";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import CreateOrgModal from "components/organisms/CreateOrgModal";
import PageTitle from "components/atoms/PageTitle";
import { routes } from "pages/Router";

import S from "./styles";

const ORG_NAME_LIMIT = 30;

const CreateOrganisation = () => {
  const [isCreateOrgModalOpen, setIsCreateOrgModalOpen] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [orgDescription, setOrgDescription] = useState("");
  const [users, setUsers] = useState("");
  const [admins, setAdmins] = useState("");

  const navigate = useNavigate();

  const onOrgNameChange = (value: string) => {
    if (value.length <= ORG_NAME_LIMIT) {
      setOrgName(value);
    }
  };

  const onOrgDescriptionChange = (value: string) => {
    setOrgDescription(value);
  };

  const onAdminsChange = (value: string) => {
    setAdmins(value);
  };

  const onUsersChange = (value: string) => {
    setUsers(value);
  };

  const requiredFieldsFilled =
    orgName &&
    orgName.length &&
    ((users && users.length) || (admins && admins.length));

  return (
    <>
      <S.CreateOrganisation>
        <PageTitle title="Create organisation" />
        <S.CreateOrganisationInputs>
          <S.Section>
            <S.InputGroup>
              <Heading level={6}>Enter the organisation name</Heading>
              <Input
                placeholder="Enter name"
                onChange={onOrgNameChange}
                value={orgName}
                autoComplete="off"
              />
            </S.InputGroup>
            <S.InputGroup>
              <Heading level={6}>Enter a description (optional)</Heading>
              <Input
                placeholder="Enter description"
                onChange={onOrgDescriptionChange}
                value={orgDescription}
                autoComplete="off"
              />
            </S.InputGroup>
          </S.Section>
          <S.Rule />
          <S.Section>
            <S.InputGroup>
              <div>
                <Heading level={6}>Invite admins</Heading>
                <S.InputExplainer>
                  Admins can manage the organisation, view usage, promote users
                  to administrators, delete users and manage all groups.
                </S.InputExplainer>
              </div>
              <Input
                placeholder="Add emails, comma separated"
                onChange={onAdminsChange}
                value={admins}
                autoComplete="off"
              />
            </S.InputGroup>
            <S.InputGroup>
              <div>
                <Heading level={6}>Invite users</Heading>
                <S.InputExplainer>
                  Users can run and share reports, create groups and invite new
                  users.
                </S.InputExplainer>
              </div>
              <Input
                placeholder="Add emails, comma separated"
                onChange={onUsersChange}
                value={users}
                autoComplete="off"
              />
            </S.InputGroup>
          </S.Section>
        </S.CreateOrganisationInputs>
        <S.Controls>
          <S.Button
            text="Cancel"
            size={ButtonSize.Medium}
            type={ButtonType.Outlined}
            onClick={() => navigate(`${routes.hub}`)}
          />
          <S.Button
            text="Create organisation"
            size={ButtonSize.Medium}
            type={ButtonType.Filled}
            disabled={!requiredFieldsFilled}
            onClick={() =>
              requiredFieldsFilled && setIsCreateOrgModalOpen(true)
            }
          />
        </S.Controls>
      </S.CreateOrganisation>
      <CreateOrgModal
        isOpen={isCreateOrgModalOpen}
        toggleOpen={() => setIsCreateOrgModalOpen(prev => !prev)}
        orgName={orgName}
        orgDescription={orgDescription}
        admins={admins}
        users={users}
      />
    </>
  );
};

export default CreateOrganisation;
