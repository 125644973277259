import React, { forwardRef, Fragment, useRef, useState } from "react";

import { getDurationStringFromDates } from "util/getDurationStringFromDates";
import {
  PRINTABLE_STATE_TYPES,
  usePrintableReportState
} from "util/hooks/usePrintableState";
import SectionFooter from "components/atoms/SectionFooter";

import { ALIGNMENT_OPTIONS } from "./utils";
import S from "./styles";

const RiskSummariesTimeline = forwardRef(
  ({ events = [], isReportRegenerationOpen }, riskSummariesSectionRef) => {
    const [isResultsExpanded, setIsResultsExpanded] = usePrintableReportState(
      "risk-events-expanded-fully",
      false,
      PRINTABLE_STATE_TYPES.sectionExpand
    );
    const [isShowingExpandButton, setIsShowingExpandButton] = useState();

    const contentRef = useRef(null);

    const onToggleExpandResultsSection = () => {
      setIsResultsExpanded(prevState => !prevState);
      if (isResultsExpanded) {
        // Then we must be collapsing the results so ensure
        // the results section remains in view.

        const rect = contentRef.current.getBoundingClientRect();

        // If results section's top is now hidden i.e. above the viewport then:
        if (rect.top <= 0) {
          // Bring the section into view
          riskSummariesSectionRef.current.scrollIntoView();
        }
      }
    };

    const renderEvent = ({
      eventData,
      isOnTheRight,
      isOnlyOneEvent = false
    }) => {
      return (
        <S.TimelineEvent
          startDate={eventData.startDate}
          endDate={eventData.endDate}
          content={eventData.content}
          title={eventData.title}
          subtitle={eventData.subtitle}
          riskCategories={eventData.riskCategories}
          sources={eventData.sources}
          alignment={
            isOnTheRight ? ALIGNMENT_OPTIONS.left : ALIGNMENT_OPTIONS.right
          }
          displayOwnTimelineFragment={isOnlyOneEvent}
        />
      );
    };

    const transformedevents = events
      .map(summaryEvent => {
        return {
          startDate: summaryEvent.startDate,
          endDate: summaryEvent.endDate,
          content: summaryEvent.summary,
          subtitle: getDurationStringFromDates({
            startDate: summaryEvent.startDate,
            endDate: summaryEvent.endDate
          }),
          riskCategories: summaryEvent.granularRiskCategories,
          sources: summaryEvent.riskSources,
          title: summaryEvent.title
        };
      })
      .sort((a, b) => {
        const aStartDate = new Date(
          a?.startDate?.year,
          a?.startDate?.month ?? 0 - 1,
          a?.startDate?.day
        );

        const bStartDate = new Date(
          b?.startDate?.year,
          b?.startDate?.month ?? 0 - 1,
          b?.startDate?.day
        );

        if (aStartDate.getTime() > bStartDate.getTime()) {
          return -1;
        }

        return 1;
      });

    return (
      <>
        <S.SectionBanner>
          <S.SectionBannerInfo>
            <span>
              Experimental preview: machine-generated event summaries relating
              to your subject&apos;s wider brand using Xapien&apos;s{" "}
              <S.Fluenci>Fluenci&trade;</S.Fluenci> technology.
            </span>
            <S.SectionCount>{transformedevents.length}</S.SectionCount>
          </S.SectionBannerInfo>
        </S.SectionBanner>
        <S.SectionContent
          ref={contentRef}
          isResultsExpanded={isResultsExpanded}
        >
          <S.TimelineContainer isOnlyEvent={transformedevents.length === 1}>
            {transformedevents.length === 1 ? (
              renderEvent({
                eventData: transformedevents[0],
                isOnTheRight: false,
                isOnlyOneEvent: true
              })
            ) : (
              <>
                <S.LineContainer>
                  <S.LineTopCover />
                  <S.Line />
                  <S.LineTerminus />
                </S.LineContainer>
                <ul>
                  {transformedevents.map((eventData, index) => {
                    const isOnTheRight = index % 2 === 1;

                    return (
                      <S.ListItem key={eventData.title ?? eventData.content}>
                        {renderEvent({ eventData, isOnTheRight })}
                      </S.ListItem>
                    );
                  })}
                  <S.ClearingElement />
                </ul>
              </>
            )}
          </S.TimelineContainer>
        </S.SectionContent>
        {!isShowingExpandButton && <SectionFooter />}
        <S.CustomStickyExpandButton
          isReportRegenerationOpen={isReportRegenerationOpen}
          isResultsExpanded={isResultsExpanded}
          onToggleExpandResultsSection={onToggleExpandResultsSection}
          resultsSectionRef={contentRef}
          shouldShowButtonCallback={setIsShowingExpandButton}
        />
      </>
    );
  }
);

export default RiskSummariesTimeline;
