import React, { useState, Fragment, FC, MouseEvent } from "react";
import { NavItem } from "reactstrap";
// TODO: Replace react-toggle with something with types
// @ts-ignore
import Toggle from "react-toggle";

import ReactTooltip from "components/atoms/ReactTooltip";

import Modal from "components/molecules/Modal";

// @ts-ignore
import theme from "theme";

import S from "./styles";

interface Props {
  downloadReady: boolean;
  exporting: boolean;
  print: (e: MouseEvent) => void;
  instance: string;
  downloadPdf: () => void;
  expandAllSectionsForExport: () => void;
  onExpandAllSectionsForExport: (v: (v: boolean) => boolean) => void;
}

const ExportButton: FC<Props> = ({
  downloadReady,
  exporting,
  print,
  instance,
  downloadPdf,
  expandAllSectionsForExport,
  onExpandAllSectionsForExport
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const renderExport = () => {
    return (
      <ReactTooltip tooltip="Export as PDF">
        <S.ExportButton id={`ExportButton${instance}`}>
          <S.Download />
        </S.ExportButton>
      </ReactTooltip>
    );
  };

  const renderExporting = () => {
    if (exporting) {
      return (
        <ReactTooltip tooltip="PDF export in progress">
          <S.ExportButton>
            <S.ExportSpinner
              id={`ExportSpinner${instance}`}
              size="m"
              color={theme.primaryColor}
            />
          </S.ExportButton>
        </ReactTooltip>
      );
    }
    return null;
  };
  const renderExportReady = () => {
    if (downloadReady) {
      return (
        <ReactTooltip tooltip="PDF export complete, click button to download">
          <S.ExportButton
            downloadReady={downloadReady}
            id={`ExportButton${instance}`}
            backgroundColor={theme.primaryColor}
          >
            <S.DownloadReady backgroundColor={theme.primaryColor} />
          </S.ExportButton>
        </ReactTooltip>
      );
    }
    return null;
  };

  const toggleModal = () => {
    setIsModalOpen(prev => !prev);
  };

  return (
    <>
      <NavItem
        role="menuitem"
        onClick={() => {
          if (!exporting && !downloadReady) {
            toggleModal();
          }
          if (downloadReady) {
            downloadPdf();
          }
        }}
      >
        <S.ExportButtonContainer>
          {renderExportReady() || renderExporting() || renderExport()}
        </S.ExportButtonContainer>
      </NavItem>
      <Modal
        headerString="Export"
        body={
          <>
            <div>
              The report will be exported with the sections expanded and/or
              collapsed as you see them on the screen now. This does not
              currently include the Xapien Insight section.
            </div>
            <S.Question>
              Would you like to expand all sections before exporting?
            </S.Question>
            <S.OptionsContainer>
              <Toggle
                checked={expandAllSectionsForExport}
                onChange={() =>
                  onExpandAllSectionsForExport((prev: boolean) => !prev)
                }
                icons={false}
              />
              <S.ExpandOptionLabel>Expand all sections</S.ExpandOptionLabel>
            </S.OptionsContainer>
          </>
        }
        secondaryActionString="Cancel"
        primaryActionString="Export"
        onSecondaryActionClick={toggleModal}
        onPrimaryActionClick={(e: MouseEvent) => {
          print(e);
          toggleModal();
        }}
        toggleModal={toggleModal}
        isOpen={isModalOpen}
        footerTertiaryNode={undefined}
      />
    </>
  );
};

export default ExportButton;
