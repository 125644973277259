export interface SupportingSentence {
  wamSourceIds: string[];
  title?: string;
  text: string;
}

interface BaseContentNode {
  id: string;
  nodeType: string;
  supportingSentences?: SupportingSentence[];
  value?: string;
  content?: ContentNode[];
  subSection?: string;
  isSourced?: boolean;
  isUnknown?: boolean;
}

export interface H1ContentNode extends BaseContentNode {
  nodeType: "h1";
  content: ContentNode[];
}

export interface H2ContentNode extends BaseContentNode {
  nodeType: "h2";
  content: ContentNode[];
}

export interface H3ContentNode extends BaseContentNode {
  nodeType: "h3";
  content: ContentNode[];
}

export interface H4ContentNode extends BaseContentNode {
  nodeType: "h4";
  content: ContentNode[];
}

export interface PContentNode extends BaseContentNode {
  nodeType: "p";
  content: TextContentNode[];
  isSourced: boolean;
}

export interface DivContentNode extends BaseContentNode {
  nodeType: "div";
  content: ContentNode[];
}

export interface TextContentNode extends BaseContentNode {
  nodeType: "text";
  value: string;
  isUnknown: boolean;
}

export type ContentNode =
  | H1ContentNode
  | H2ContentNode
  | H3ContentNode
  | H4ContentNode
  | PContentNode
  | DivContentNode
  | TextContentNode;

export interface InsightReportSection {
  id: string;
  title?: string;
  slug: string;
  content: ContentNode[];
}

export interface InsightReport {
  id: string;
  subject: string;
  sections: InsightReportSection[];
}

export enum MessageType {
  Question = "question",
  Answer = "answer",
  Error = "error"
}

type User = {
  id: string;
  firstName: string;
  lastName: string;
};

export interface Fragment {
  text: string;
  supportingSourceSentences: {
    text: string;
    title?: string;
    date: string;
    wamSourceIds: string[];
  }[];
}

interface Element {
  id: string;
  title?: string;
  text: string;
  citedFragments: Fragment[];
}

export interface Subsection {
  id: string;
  title?: string;
  isSourced?: boolean;
  elements: Element[];
}

export interface Question extends Subsection {
  timestamp: string;
  user: User;
}

export interface Error {
  text: string;
}

export interface QuestionAndAnswer {
  question: Question;
  answer: Subsection;
}

export type Message = (Question | Subsection | Error) & {
  type: MessageType;
};
