import { styled } from "@linaria/react";

import { grey, purple, standardColors } from "styles/colors";

import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon
} from "react-feather";

const ChevronLeft = styled(ChevronLeftIcon)`
  margin-right: 2px;
`;

const ChevronRight = styled(ChevronRightIcon)`
  margin-left: 2px;
`;

const SectionNavigationContainer = styled.div`
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  gap: 1rem;
  border-top: 1px solid ${grey.rule};
  margin-top: 1rem;
  padding: 44px 32px;
  background-color: ${grey.panel};

  button {
    border: 0;
    background: ${standardColors.white};
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    padding: 0;
    transition: all 0.2s ease;
    color: ${purple.darker};

    &:focus {
      outline: none;
    }

    &:disabled {
      cursor: initial;
      color: ${grey.ghost};
    }
  }
`;

const S = {
  SectionNavigationContainer,
  ChevronLeft,
  ChevronRight
};

export default S;
