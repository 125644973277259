import { styled } from "@linaria/react";

import Button from "components/atoms/Button";

import { fontSize } from "styles/fonts";
import { grey, standardColors } from "styles/colors";
import { hexToRgb } from "styles/hexToRgb";

import ReactTooltip from "components/atoms/ReactTooltip";

// @ts-ignore
const RiskPill = styled(Button)<{
  width?: string;
  isCountZero: boolean;
  color: string;
  isActive?: boolean;
  fillColor: string;
  isClickable?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${props => `${props.width}px` ?? "auto"};
  padding-left: 8px !important;
  padding-right: 8px !important;
  position: relative;
  border: 1px solid ${props => (props.isCountZero ? grey.ghost : props.color)} !important;
  border-radius: 16px;
  gap: 2px;
  min-height: 22px !important;
  min-width: 0 !important;
  height: 22px;
  transition: box-shadow 0.2s;
  font-size: ${fontSize.sm};
  text-transform: capitalize;
  background-color: ${props =>
    props.isActive ? props.fillColor : "transparent"} !important;
  color: ${props => {
    if (props.isCountZero) {
      return grey.ghost;
    }
    if (props.isActive) {
      return standardColors.white;
    }
    return props.color;
  }} !important;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px
      rgba(
        ${props => {
          if (!props.isCountZero && props.isClickable) {
            const { r, g, b } = hexToRgb(props.color);
            return `${r}, ${g}, ${b}`;
          }
          return "";
        }},
        0.25
      ) !important;
    cursor: ${props =>
      props.isCountZero || !props.isClickable ? "default" : "pointer"};
  }
`;

const KillButton = styled.div<{ isActive: boolean }>`
  border: none;
  background: none;
  height: 26px;
  display: flex;
  align-items: center;
  padding-right: 0;
  padding-inline: 6px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  svg {
    height: 16px;
    width: 16px;
  }

  path {
    transition: fill 0.2s;
    fill: ${props =>
      props.isActive ? standardColors.white : (props.color as string)};
    opacity: 0.6;
  }

  circle {
    transition: fill 0.2s;
    stroke: transparent;
  }

  &:hover:not(:disabled) {
    path {
      opacity: 1;
      fill: ${props =>
        props.isActive ? (props.color as string) : standardColors.white};
    }

    circle {
      fill: ${props =>
        props.isActive ? standardColors.white : (props.color as string)};
    }
  }
`;

const KillButtonDisabled = styled(KillButton)`
  cursor: not-allowed;
  opacity: 0.5;
`;

const VerticalSeparator = styled.span`
  padding-bottom: 2px;
`;

const PillLabel = styled.div`
  display: flex;
  align-items: center;
`;

const RightPart = styled.div<{ canKill: boolean }>`
  position: relative;
  height: 22px;
  min-width: ${props => (props.canKill ? 16 : 0)}px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
`;

const CountDiv = styled.div<{ isKillButtonVisible: boolean }>`
  position: relative;

  span {
    opacity: ${props => (props.isKillButtonVisible ? 0 : 1)};
    transition: opacity 0.2s;
  }
`;

const KillDiv = styled.div<{ isKillButtonVisible: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  top: -3px;
  opacity: ${props => (props.isKillButtonVisible ? 1 : 0)};
  transition: opacity 0.2s;
  width: 100%;
  justify-content: center;
`;

const RemovalDisclaimer = styled.div`
  padding-top: 6px;
`;

const Tooltip = styled(ReactTooltip)`
  display: flex;
`;

const S = {
  RiskPill,
  KillButton,
  KillButtonDisabled,
  VerticalSeparator,
  PillLabel,
  RightPart,
  KillDiv,
  CountDiv,
  RemovalDisclaimer,
  Tooltip
};

export default S;
