import React, { useRef, Fragment, useState, useEffect } from "react";

import { ButtonKind } from "components/atoms/Button/types";
import SourceCard from "components/molecules/SourceList/SourceCard";
import { ALIGNMENT_OPTIONS } from "components/organisms/RiskSummariesTimeline/utils";
import WithInspector from "components/organisms/WithInspector";
import { INSPECTOR_DATA_FORMATS } from "util/inspectorDataFormat";
import { combineSourcesWithRiskCategories } from "components/organisms/WithInspector/util";

import { grey, red } from "styles/colors";

import theme from "theme";

import S, { classNameOverrides } from "./styles";

const CATEGORY_MAX_DEFAULT_SHOWN = 3;

const Event = ({
  content,
  title,
  subtitle,
  riskCategories = [],
  sources,
  startDate,
  alignment = ALIGNMENT_OPTIONS.left,
  nodeBackgroundColor = grey.panel,
  displayOwnTimelineFragment,
  className,
  style
}) => {
  const [isCategoriesExpanded, setIsCategoriesExpanded] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [isInspectorActive, setIsInspectorActive] = useState();

  const expandButtonRef = useRef();

  // Add ids to sources
  sources?.forEach((source, index) => {
    // eslint-disable-next-line no-param-reassign
    source.id = `${riskCategories.join("")}_${index}`;
  });

  // Pre-process - couple risk category with sources
  const withInspectorData = combineSourcesWithRiskCategories(sources, false);

  // Reset active filter pill when the inspector is closed
  useEffect(() => {
    if (!isInspectorActive) {
      setSelectedCategory(undefined);
    }
  }, [isInspectorActive]);

  const getEventNodeSize = (articles = []) => {
    if (articles.length > 10) {
      return 29;
    }

    if (articles.length > 5) {
      return 21;
    }

    if (articles.length > 1) {
      return 15;
    }

    return 5;
  };

  const getEventStartDate = () => {
    if (!startDate) {
      return "Unknown";
    }

    return `${startDate?.monthShort ?? ""} ${startDate?.year ?? ""}`;
  };

  const getEventRiskCategories = () => {
    const riskCategoryElements = riskCategories.map(cat => (
      <S.RiskCategoryPill
        key={cat}
        isActive={selectedCategory === cat}
        disabled={isInspectorActive}
        onClick={() => {
          if (selectedCategory === cat) {
            setSelectedCategory(undefined);
          }
          setSelectedCategory(cat);
        }}
        ariaLabel={`Risk filter: ${cat}`}
      >
        {cat}
      </S.RiskCategoryPill>
    ));

    return (
      <>
        {riskCategoryElements.slice(
          0,
          isCategoriesExpanded
            ? riskCategoryElements.length
            : CATEGORY_MAX_DEFAULT_SHOWN
        )}
        {riskCategories.length > CATEGORY_MAX_DEFAULT_SHOWN ? (
          <S.RiskCountButton
            ref={expandButtonRef}
            onClick={e => {
              e.stopPropagation();
              setIsCategoriesExpanded(prevState => !prevState);
              expandButtonRef.current.blur();
            }}
            kind={ButtonKind.secondary}
          >
            {isCategoriesExpanded
              ? "Show less"
              : `+${riskCategories.length - CATEGORY_MAX_DEFAULT_SHOWN}`}
          </S.RiskCountButton>
        ) : null}
      </>
    );
  };

  const sourcesString = sources.length === 1 ? "source" : "sources";

  const getEventContent = (aligmentOverride = alignment) => {
    return (
      <>
        <S.EventTitle>
          {title ?? <S.NoTitleHeader>No title</S.NoTitleHeader>}
        </S.EventTitle>
        <S.EventSubtitle>
          <S.SourcesSubtitle>
            Summary of {sources?.length} {sourcesString}:
          </S.SourcesSubtitle>{" "}
          {subtitle}
        </S.EventSubtitle>
        <S.EventContent alignment={aligmentOverride}>{content}</S.EventContent>
      </>
    );
  };

  const nodeSize = getEventNodeSize(sources);

  const renderEventNode = () => {
    return (
      <S.EventNodeOuterContainer size={nodeSize}>
        {displayOwnTimelineFragment && (
          <S.TimelineFragment>
            <S.Line />
            <S.Terminus />
          </S.TimelineFragment>
        )}
        <S.EventNode
          size={nodeSize}
          backgroundColor={nodeBackgroundColor}
          borderColor={theme.primaryColor}
        >
          <S.InnerEventNodeCircle size={nodeSize} color={theme.primaryColor} />
        </S.EventNode>
      </S.EventNodeOuterContainer>
    );
  };

  return (
    <S.EventContainer
      className={className}
      style={style}
      alignment={alignment}
      nodeSize={nodeSize}
    >
      <div>
        <S.EventNodeContainer alignment={alignment}>
          {renderEventNode()}
          <S.EventLine color={theme.primaryColor} />
          <S.DateTag alignment={alignment}>
            <S.DateTagInnerContainer>
              <S.DateTagIcon alignment={alignment} color={theme.primaryColor} />
              <S.DateString alignment={alignment}>
                {getEventStartDate()}
              </S.DateString>
            </S.DateTagInnerContainer>
          </S.DateTag>
        </S.EventNodeContainer>
      </div>
      <S.EventContentContainer alignment={alignment}>
        <S.PaddingDiv nodeSize={nodeSize} />
        <S.ContentDiv>
          <WithInspector
            dataFormat={INSPECTOR_DATA_FORMATS.filterableList}
            filterableSources={withInspectorData}
            filterPillColor={red.directRisk}
            pillClassName={classNameOverrides.riskPill}
            selectedFilterOverride={selectedCategory}
            isInspectorOpen={state => setIsInspectorActive(state)}
            onFilterSelected={setSelectedCategory}
            popoverPosition="fixed"
            renderSourceItems={(
              sourcesArr,
              selectedFilter,
              sourceContainerRef
            ) => {
              return (
                <S.SourceItemsContainer ref={sourceContainerRef}>
                  {sourcesArr?.map(source => (
                    <SourceCard
                      key={source.url}
                      source={source}
                      selectedFilter={selectedFilter}
                    />
                  ))}
                </S.SourceItemsContainer>
              );
            }}
            isSingleSourceOrSingleFilterFilterable
          >
            {getEventContent()}
            {getEventRiskCategories()}
          </WithInspector>
        </S.ContentDiv>
      </S.EventContentContainer>
    </S.EventContainer>
  );
};

export default Event;
