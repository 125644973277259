import React, { useContext, Fragment, useState } from "react";
import Masonry from "react-masonry-css";

import WithInspector from "components/organisms/WithInspector";
import { ReactComponent as NLP } from "img/icons/renders/nlp.svg";
import { sortBy, formatDate } from "util/common";
import TruncateLength from "util/TruncateLength";
import { REPORT_TYPES } from "util/reportTypes";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import { emboldenKeyWords } from "util/emboldenKeyWords";
import { stage, devel } from "services/stage";
import SectionFooter from "components/atoms/SectionFooter";

import ExpandButton from "./ExpandButton";
import List from "./List";
import CategorisedSection from "./CategorisedSection";

import S, { classNameOverrides } from "./styles";

const INITIAL_DESCRIPTIONS_LIMIT = 20;

const FIELD_LABEL_MAP = {
  PKA: "Previously known as"
};

const breakpointColumnsObj = {
  default: 2,
  700: 1
};

const assetSecondaryInfo = asset => {
  const assetInfo = [];

  if (asset.purchasedFor) {
    assetInfo.push(`purchased for ${asset.purchasedFor}`);
  }
  if (asset.valuedAt) {
    assetInfo.push(`was valued:${asset.valuedAt}`);
  }
  if (asset.soldFor) {
    assetInfo.push(`purchased for ${asset.soldFor}`);
  }
  if (asset.soldDate) {
    assetInfo.push(asset.soldDate);
  }
  return assetInfo.join(",");
};

const formatBirthDeathData = ({ phrase, dates = [] }) => ({
  text: phrase,
  groupLabel: dates.length === 1 ? "date" : "dates",
  group: dates.map(({ date, modifiers, sources }) => {
    const dateString = date ? formatDate(date) : "No date";
    return {
      text: dateString,
      sources,
      modifiers: modifiers.filter(m => m.modifierText)
    };
  })
});

const getAffiliationsData = data => {
  return {
    text: (
      <WithInspector
        sources={data.sources}
        topSectionElement={
          <>
            {data.target}:{" "}
            <span className="experimental-section__relation-type">
              {data.text}
            </span>
          </>
        }
        display="inline-block"
        highlightPadding="1px"
      >
        <S.Truncate text={data.target} length={120} />:{" "}
        <span className="experimental-section__relation-type">{data.text}</span>
      </WithInspector>
    ),
    sources: data.sources,
    secondaryInfo: data.value,
    firstDate: data.date
  };
};

const getQuotationsData = (quotes, title) => {
  return {
    title,
    footerText: (
      <span>
        <S.QuoteSuperscript>*</S.QuoteSuperscript>
        Dates indicate when the quote was encountered.
      </span>
    ),
    initialItemsShownCount: 3,
    dataToDisplay: quotes?.length
      ? quotes.map(quote => ({
          text: (
            <S.QuoteContainer>
              <S.Quote>{quote.quoteText}</S.Quote>
            </S.QuoteContainer>
          ),
          sources: quote.sources,
          classes: {
            listItemClassName: classNameOverrides.quoteListItem
          },
          firstDate: quote.date,
          // Dates for quotes from Wikipedia articles can be extremely
          // far from the date of the original source. In this situation
          // we mark the date as 'uncertain'.
          isDateUncertain: quote.sources.some(
            source =>
              source.publisher === "Wikipedia" &&
              source?.dateInformation?.date?.year === quote?.date?.year
          )
        }))
      : []
  };
};

const getAdditionalDescriptionsData = descriptors => {
  return {
    title: "Additional descriptions",
    initialItemsShownCount: 3,
    dataToDisplay: descriptors?.length
      ? descriptors.map(descriptor => {
          const emboldenedSentence = descriptor?.descriptorHighlights
            ? emboldenKeyWords(
                descriptor.descriptorSentence,
                descriptor.descriptorHighlights
              )
            : descriptor.descriptorSentence;

          return {
            text: emboldenedSentence,
            sources: descriptor.sources,
            classes: {
              listItemClassName: classNameOverrides.quoteListItem
            }
          };
        })
      : []
  };
};

const InsightCentre = ({ experimentalData, reportType }) => {
  const isDiagnosticsModeEnabled = useContext(DiagnosticsModeContext).enabled;
  const [isDescriptionsExpanded, setIsDescriptionsExpanded] = useState();
  const standardFormatSections = [];

  const onExpandClick = () => {
    setIsDescriptionsExpanded(prevState => !prevState);
  };

  const renderSanctions = () => {
    return (
      <div className="list">
        <div className="heading">Sanctions</div>
        {experimentalData?.sanctions?.length ? (
          experimentalData.sanctions.map(d => {
            const data = d?.sanctions?.map(sanction => {
              const reportedDate = sanction?.sourceDate?.year;

              return {
                text: (
                  <>
                    {sanction.sentence}{" "}
                    <S.Dates>
                      {reportedDate ? `(Reported ${reportedDate})` : null}
                    </S.Dates>
                  </>
                ),
                classes: {
                  listItemClassName: classNameOverrides.listItem
                },
                sources: sanction.sources
              };
            });

            return (
              <CategorisedSection
                key={d.sanctioningBodyText}
                data={data}
                title={
                  d.sanctioningBodyText?.toLowerCase() === "unknown"
                    ? "Unknown sanctioning body"
                    : d.sanctioningBodyText
                }
                initialItemsShownCount={3}
              />
            );
          })
        ) : (
          <S.NoData>None identified</S.NoData>
        )}
      </div>
    );
  };

  const getOrgReportSections = () => {
    standardFormatSections.push({
      title: "People and roles",
      dataToDisplay: experimentalData?.orgRoles?.length
        ? experimentalData.orgRoles.map(r => ({
            ...r,
            text: r.role,
            group: r.people.map(person => ({
              ...person,
              text: person.person
            })),
            groupLabel: r.people.length === 1 ? "person" : "people"
          }))
        : []
    });

    standardFormatSections.push(
      getQuotationsData(
        experimentalData.orgQuotes,
        "Quotes about the organisation"
      )
    );

    standardFormatSections.push(
      getAdditionalDescriptionsData(
        experimentalData.sortedOrgDescriptiveSentences
      )
    );

    const renderOrgRelations = () => {
      const ordering = {
        parent: 1,
        subsidiary: 2,
        unknown: 3
      };

      const orderedOrgRelations = experimentalData?.orgRelationsByType?.sort(
        (a, b) => {
          return ordering[a.relationship] - ordering[b.relationship];
        }
      );

      return (
        <div className="list">
          <div className="heading">Organisation relationships</div>
          {orderedOrgRelations?.length ? (
            orderedOrgRelations.map(d => {
              const data = d.orgRelations.map(orgName => ({
                text: orgName.relatedOrgName,
                secondaryInfo: orgName.value,
                firstDate: orgName.date,
                sources: orgName.sources
              }));
              return (
                <CategorisedSection
                  key={d.relationship}
                  data={data}
                  title={d.relationship}
                />
              );
            })
          ) : (
            <S.NoData>None identified</S.NoData>
          )}
        </div>
      );
    };

    const renderOrgStateOwnerships = () => {
      return (
        <div className="list">
          <div className="heading">State ownerships</div>
          {experimentalData?.orgStateOwnerships?.length ? (
            experimentalData.orgStateOwnerships.map(d => {
              const data = d.orgStateOwnerships?.map(ownership => {
                const reportedDate = ownership?.sourceDate?.year;

                return {
                  text: (
                    <>
                      {ownership.sentence}{" "}
                      <S.Dates>
                        {reportedDate ? `(Reported ${reportedDate})` : null}
                      </S.Dates>
                    </>
                  ),
                  classes: {
                    listItemClassName: classNameOverrides.listItem
                  },
                  sources: ownership.sources
                };
              });
              return (
                <CategorisedSection
                  key={d.stateOwnerText}
                  data={data}
                  title={
                    d.stateOwnerText?.toLowerCase() === "unknown"
                      ? "Unknown state owner"
                      : d.stateOwnerText
                  }
                  initialItemsShownCount={3}
                />
              );
            })
          ) : (
            <S.NoData>None identified</S.NoData>
          )}
        </div>
      );
    };

    const renderOrgAlternativeNames = () => {
      return (
        <div className="list">
          <div className="heading">Alternative names</div>
          {experimentalData?.orgAlternativeNamesByType?.length ? (
            experimentalData?.orgAlternativeNamesByType?.map(d => {
              const data = d.orgAlternativeNames.map(orgName => ({
                text: orgName.alternativeOrgName,
                secondaryInfo: orgName.alternativeNameLinkType,
                firstDate: orgName.date,
                sources: orgName.sources
              }));
              return (
                <CategorisedSection
                  key={d.alternativeNameType}
                  data={data}
                  title={
                    FIELD_LABEL_MAP[d.alternativeNameType] ??
                    d.alternativeNameType
                  }
                />
              );
            })
          ) : (
            <S.NoData>None identified</S.NoData>
          )}
        </div>
      );
    };

    standardFormatSections.push(renderOrgRelations);
    if (stage === devel || isDiagnosticsModeEnabled) {
      standardFormatSections.push(renderSanctions);
    }

    if (stage === devel || isDiagnosticsModeEnabled) {
      standardFormatSections.push(renderOrgStateOwnerships);
    }

    standardFormatSections.push({
      title: "Also known as",
      dataToDisplay: experimentalData?.akas?.length
        ? experimentalData.akas.map(r => ({
            ...r,
            text: r.name,
            sources: r.source,
            modifiers:
              r.type?.filter(
                type =>
                  type.modifierText !== "Unknown" &&
                  type.modifierText !== "Known as"
              ) || []
          }))
        : []
    });

    standardFormatSections.push({
      title: "Affiliations",
      dataToDisplay: experimentalData?.orgAffiliations?.length
        ? experimentalData.orgAffiliations.map(r =>
            getAffiliationsData({
              target: r.target,
              date: r.date,
              sources: r.sources,
              value: r.value,
              text: r.affiliationType
            })
          )
        : [],
      textIsWrappedWithSourcing: true
    });

    standardFormatSections.push(renderOrgAlternativeNames);
  };

  const getPersonReportSections = () => {
    standardFormatSections.push({
      title: "Specific roles held",
      dataToDisplay: experimentalData?.jobRoles?.length
        ? experimentalData.jobRoles.map(r => ({
            ...r,
            text: r.org,
            group: r.roles.map(role => ({ ...role, text: role.role }))
          }))
        : []
    });

    standardFormatSections.push({
      title: "Generic roles held",
      dataToDisplay: experimentalData?.jobRolesNoOrgs?.length
        ? experimentalData.jobRolesNoOrgs.map(r => ({
            ...r,
            text: r.role
          }))
        : []
    });

    standardFormatSections.push({
      title: "Also known as",
      dataToDisplay: experimentalData?.akas?.length
        ? experimentalData.akas.map(r => ({
            ...r,
            text: r.name,
            sources: r.source,
            modifiers:
              r.type?.filter(
                type =>
                  type.modifierText !== "Unknown" &&
                  type.modifierText !== "Known as"
              ) || []
          }))
        : []
    });

    standardFormatSections.push({
      title: "Family members",
      dataToDisplay: experimentalData?.familyRoles?.length
        ? experimentalData.familyRoles.map(r => ({
            ...r,
            text: r.person,
            modifiers: [
              {
                modifierText: r.role,
                source: r.sources
              },
              ...r.modifiers
            ]
          }))
        : []
    });

    standardFormatSections.push({
      title: "Acquaintances",
      dataToDisplay: experimentalData?.acquaintanceRoles?.length
        ? experimentalData.acquaintanceRoles.map(acquaintanceRole => ({
            ...acquaintanceRole,
            text: acquaintanceRole.person,
            additionalInfo: acquaintanceRole.roles
              ? acquaintanceRole.roles.map(role => ({
                  ...role,
                  text: role.role
                }))
              : []
          }))
        : []
    });

    const renderDescriptions = () => {
      const descriptionData = experimentalData?.describedAs;

      const expandable = descriptionData?.length > INITIAL_DESCRIPTIONS_LIMIT;
      const numberToDisplay = isDescriptionsExpanded
        ? descriptionData?.length
        : Math.min(descriptionData?.length, INITIAL_DESCRIPTIONS_LIMIT);
      const renderDescriptionText = text => {
        let formattedText = text;
        // Backwards compatibility check for older reports that would have
        // had their descriptions pre-wrapped in quotaton marks.
        if (text[0] === '"' && text[text.length - 1] === '"') {
          // Remove quotation marks to prevent interference with truncation.
          formattedText = text.slice(1, text.length - 1);
        }

        return (
          <q>
            <S.Truncate text={formattedText} length={115} />
          </q>
        );
      };

      const descriptorArr = descriptionData
        ?.slice(0, numberToDisplay)
        .map(d => {
          const sources = d.source ?? d.sources;
          return (
            <WithInspector
              key={`WithInspector-${d.description}`}
              sources={sources}
              topSectionElement={
                d.description && renderDescriptionText(d.description)
              }
              display="inline-block"
              highlightPadding="1px"
            >
              {d.description && renderDescriptionText(d.description)}{" "}
              <sup className="reference">{`[${sources.length}]`}</sup>
            </WithInspector>
          );
        });

      return (
        <div className="list">
          <div className="heading">Descriptions</div>
          {descriptorArr?.length ? (
            <>
              <div className="descriptor">{descriptorArr}</div>
              <ExpandButton
                data={descriptionData}
                isSectionExpandable={expandable}
                isSectionExpanded={isDescriptionsExpanded}
                onClick={onExpandClick}
              />
            </>
          ) : (
            <S.NoData>None identified</S.NoData>
          )}
        </div>
      );
    };

    standardFormatSections.push(renderDescriptions);

    if (stage === devel || isDiagnosticsModeEnabled) {
      standardFormatSections.push(renderSanctions);
    }

    if (experimentalData.wealth) {
      const wealthDescriptors = experimentalData.wealth?.wealthDescriptors
        ? experimentalData.wealth.wealthDescriptors.map(r => ({
            ...r,
            text: r.description,
            sources: r.sources
          }))
        : [];
      const wealthValues = experimentalData.wealth?.wealthValues
        ? sortBy(experimentalData.wealth.wealthValues, v =>
            v.year === undefined ? 0 : v.year
          ).map(r => ({
            ...r,
            text: r.value,
            firstDate: r.year ? r : { year: "undated" },
            sources: r.sources
          }))
        : [];
      standardFormatSections.push({
        title: "Wealth",
        dataToDisplay: [...wealthValues, ...wealthDescriptors]
      });
    }

    standardFormatSections.push({
      title: "Assets",
      dataToDisplay: experimentalData?.assets?.length
        ? experimentalData.assets.map(r => ({
            ...r,
            text: <TruncateLength text={r.assetName} length={115} />,
            firstDate: r.firstPurchaseDate,
            lastDate: r.lastPurchaseDate,
            secondaryInfo: assetSecondaryInfo(r)
          }))
        : []
    });

    if (isDiagnosticsModeEnabled) {
      standardFormatSections.push({
        title: "Deceased information",
        dataToDisplay: experimentalData?.deceasedPatterns?.length
          ? experimentalData.deceasedPatterns.map(formatBirthDeathData)
          : []
      });

      standardFormatSections.push({
        title: "Birth information",
        dataToDisplay: experimentalData?.bornPatterns?.length
          ? experimentalData.bornPatterns.map(formatBirthDeathData)
          : []
      });
    } else if (
      experimentalData.deceasedPattern &&
      experimentalData.deceasedPattern.deceasedFlag
    ) {
      standardFormatSections.push({
        title: "Deceased",
        dataToDisplay: [
          {
            text: "Sources indicate the subject is deceased",
            sources: experimentalData.deceasedPattern.sources
          }
        ]
      });
    }

    standardFormatSections.push({
      title: "Affiliations",
      dataToDisplay: experimentalData?.affiliations?.length
        ? experimentalData.affiliations.map(r =>
            getAffiliationsData({
              target: r.orgName,
              date: r.date,
              sources: r.sources,
              value: r.value,
              text: r.affiliationType
            })
          )
        : [],
      textIsWrappedWithSourcing: true
    });

    standardFormatSections.push(
      getQuotationsData(experimentalData.quotes, "Quotes")
    );

    standardFormatSections.push({
      title: "Nationalities",
      dataToDisplay: experimentalData?.nationalities?.length
        ? experimentalData.nationalities.map(nationality => ({
            text: nationality.presentableText,
            sources: nationality.sources
          }))
        : []
    });

    standardFormatSections.push({
      title: "Residence",
      dataToDisplay: experimentalData?.residencies?.length
        ? experimentalData.residencies.map(r => ({
            text: r.presentableText,
            sources: r.sources
          }))
        : []
    });
  };

  const renderContent = () => (
    <div className="experimental-section">
      <S.Info>
        <NLP className="nlp" />
        This prototype section extracts data from text using Natural Language
        Processing.
      </S.Info>
      <S.Content>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className={classNameOverrides.masonryGrid}
          columnClassName={classNameOverrides.masonryColumn}
        >
          {standardFormatSections.map((section, i) => {
            if (section instanceof Function) {
              return (
                <Fragment key={`StandardFormatSection-${i}`}>
                  {section()}
                </Fragment>
              );
            }

            return (
              <List
                key={`List-${section.title}-${i}`}
                title={section.title}
                footerText={section.footerText}
                list={section.dataToDisplay}
                initialItemsShownCount={section.initialItemsShownCount}
                subTextWithoutSourcing={section.subTextWithoutSourcing}
                withoutBulletPoint={section.withoutBulletPoint}
                textIsWrappedWithSourcing={section.textIsWrappedWithSourcing}
                className={section.listClassName}
              />
            );
          })}
        </Masonry>
      </S.Content>
      <SectionFooter />
    </div>
  );

  if (reportType === REPORT_TYPES.person) {
    getPersonReportSections();
  } else if (reportType === REPORT_TYPES.organisation) {
    getOrgReportSections();
  }

  return renderContent();
};

export default InsightCentre;
