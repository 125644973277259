/* eslint-disable max-len */
import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";

import Api from "api/insight-reports";
import { Message, MessageType } from "api/insight-reports/types";
import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";
import { useSubjectName } from "util/hooks/useSubjectName";
import { useEnquiryId } from "util/hooks/useEnquiryId";
import useUserSettings from "util/hooks/useUserSettings";
import {
  useReportSubjectEntityType,
  EntityType
} from "util/hooks/useReportSubjectEntityType";
import { useLocation } from "react-router-dom";
import {usePersonaId} from "../../../util/hooks/usePersonaId";

const personSuggestedQuestions = [
  "How are they perceived by the public?",
  "What is their vision?",
  "Tell me about their views on climate change",
  "Do they have any links to Putin?"
];

const companySuggestedQuestions = [
  "How are they perceived by the public?",
  "What is their vision?",
  "Do they have any links to Putin?",
  "What are their founders most known for?"
];

const getSuggestedQuestions = (subjectType: EntityType) => {
  switch (subjectType) {
    case EntityType.Organisation: {
      return companySuggestedQuestions;
    }
    case EntityType.Person: {
      return personSuggestedQuestions;
    }
    default:
      return [];
  }
};

const useInsightsChat = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  // const [suggestedQuestions, setSuggestedQuestions] = useState<
  //   string[] | undefined
  // >([]);
  //
  const [{ fetching: loading, error, errorMessage }, dispatch] =
    useFetchReducer();

  const [{ fetching: loadingAnswer }, answerDispatch] = useFetchReducer();

  const subjectName = useSubjectName();
  const enquiryId = useEnquiryId();
  const personaId = usePersonaId();
  const subjectType = useReportSubjectEntityType();

  const suggestedQuestions = getSuggestedQuestions(subjectType);

  const {
    state: {
      userDetails: { userId, firstName, lastName }
    }
  } = useUserSettings();

  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const InsightsApi = useMemo(() => new Api(), []);

  useEffect(() => {
    // Fetch exisiting messages
    dispatch({ type: RequestActions.SendRequest });
    InsightsApi.getQuestionsAndAnswers({ enquiryId }).then(response => {
      const { status, response: qnas } = response;

      if (!status) {
        console.error(
          `Error fetching saved questions and answers: ${response.message}`
        );
        dispatch({
          type: RequestActions.SetError,
          errorMessage: response.message
        });
        return;
      }

      const flattenedMessages = qnas!.flatMap(qna => {
        return [
          { ...qna.question, type: MessageType.Question },
          { ...qna.answer, type: MessageType.Answer }
        ];
      });
      setMessages(flattenedMessages);
      dispatch({ type: RequestActions.SetSuccess });
    });
  }, [InsightsApi, dispatch, enquiryId]);

  // TODO: When we have an endpoint, put this back.
  // useEffect(() => {
  // InsightsApi.getSuggestedQuestions().then(({ status, response }) => {
  //   if (status) {
  //     setSuggestedQuestions(response);
  //   }
  // });
  // }, [InsightsApi]);

  const addErrorMessageToList = (message?: string) => {
    console.error(`Error answering question. ${message}`);
    setMessages(prev => [
      ...prev,
      {
        text: "There was an error answering your question. Try again or contact Customer Success to help you.",
        type: MessageType.Error
      }
    ]);
    answerDispatch({ type: RequestActions.SetError });
  };

  const askQuestion = async (question: string) => {
    setMessages(prev => [
      ...prev,
      {
        id: "",
        title: "",
        timestamp: dayjs(new Date()).toISOString(),
        elements: [
          {
            id: "",
            title: "",
            text: question.slice(0, 1).toUpperCase() + question.slice(1)
          }
        ],
        text: "",
        user: {
          id: userId,
          firstName,
          lastName
        },
        type: MessageType.Question
      }
    ]);

    if (subjectName) {
      answerDispatch({ type: RequestActions.SendRequest });

      const { status, response, message } =
        await InsightsApi.askSourcedQuestion({
          enquiryId,
          personaId,
          question,
          subjectName,
          shareToken: params.get("token")
        });

      if (!status) {
        addErrorMessageToList(message);
        return;
      }

      if (response) {
        setMessages(prev => [
          ...prev,
          { ...response, type: MessageType.Answer }
        ]);
      }
      answerDispatch({ type: RequestActions.SetSuccess });
    } else {
      addErrorMessageToList(`subjectName is undefined.`);
    }
  };

  return {
    askQuestion,
    messages,
    loading,
    error,
    errorMessage,
    loadingAnswer,
    suggestedQuestions
  };
};

export default useInsightsChat;
