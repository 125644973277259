import React, { useState, Fragment } from "react";
import ReactTooltip from "components/atoms/ReactTooltip";
import { ButtonKind } from "components/atoms/Button/types";
import Modal from "components/molecules/Modal";
import { constructSearchUrl } from "util/constructSearchUrl";
import useViewerMode from "util/hooks/useViewerMode";

import theme from "theme";

import S from "./styles";

const NewReportButton = ({
  subject,
  contextList,
  name,
  image,
  nameOnlySearch,
  subjectType,
  contextType,
  placeholderIcon = <S.PersonPlaceholderIcon />,
  isDisabled = false
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const searchUrl = constructSearchUrl({
    subject,
    contextList,
    subjectType,
    contextType,
    nameOnlySearch
  });
  const { isViewerModeEnabled } = useViewerMode();

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const renderTooltipContent = () => {
    if (isDisabled) {
      return "Coming soon";
    }

    if (isViewerModeEnabled) {
      return "You are viewing this report in Viewer mode.";
    }
    return (
      <>
        Run new report on <div>{name}</div>
      </>
    );
  };

  return (
    <>
      <S.ButtonContainer>
        <ReactTooltip
          layoutPosition="fixed"
          tooltip={renderTooltipContent()}
          position="bottom"
          maxWidth="200px"
        >
          <S.Button
            kind={ButtonKind.primary}
            disabled={isViewerModeEnabled || isDisabled}
            onClick={e => {
              e.stopPropagation();
              setIsModalOpen(true);
            }}
            ariaLabel={`New report on ${name}`}
          >
            {theme.button?.logo ?? <S.LogoIcon alt="Generate new report" />}
            <S.ButtonText>New...</S.ButtonText>
          </S.Button>
        </ReactTooltip>
      </S.ButtonContainer>
      <Modal
        headerString="Run new report"
        body={
          <S.ModalBody>
            <S.ModalBodyText>
              {`You are about to run a new ${
                theme.brand ? "" : "Xapien"
              } report on`}
              <br /> <S.Span>{name}</S.Span>.
            </S.ModalBodyText>
            {image ?? placeholderIcon}
          </S.ModalBody>
        }
        isOpen={isModalOpen}
        secondaryActionString="Cancel"
        primaryActionString="Go"
        onPrimaryActionClick={() => {
          window.open(`/search${searchUrl}`, "_blank", "noopener,noreferrer");
          toggleModal();
        }}
        onSecondaryActionClick={toggleModal}
        toggleModal={toggleModal}
      />
    </>
  );
};

export default NewReportButton;
