import { styled } from "@linaria/react";
import { DropdownItem } from "reactstrap";
import { fontSize, family } from "styles/fonts";
import { grey, blue, standardColors } from "styles/colors";
import { hexToRgb } from "styles/hexToRgb";
import StickyExpandButton from "pages/report/StickyExpandButton";
import { ReactComponent as ChevronSvg } from "img/right-chevron.svg";
import { ReactComponent as CrossInCircle } from "img/icons/cross-in-circle.svg";

type ResultsSectionProps = {
  isResultsExpanded: boolean;
  isResultsShown: boolean;
};

const SectionHeader = styled.div`
  border-bottom: 2px solid ${standardColors.white};
  padding: 0 16px 16px 16px;
  margin: 0 -17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FilterHeader = styled.div`
  color: ${grey.mid};
  font-size: ${fontSize.sm};
  padding-right: 15px;
`;

const ResultsSection = styled.div<ResultsSectionProps>`
  max-height: ${({ isResultsExpanded, isResultsShown }) => {
    if (isResultsExpanded) {
      return "inherit";
    }

    if (isResultsShown) {
      return "600px";
    }

    return 0;
  }};
  overflow-y: auto;
  background-color: ${grey.panel};

  .professional-information {
    border-bottom: 1px solid ${grey.rule};
    margin-right: 16px;
  }

  .professional-information:last-child {
    border-bottom: 1px solid transparent;
  }
`;

const ListTitle = styled.span`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

const SubSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
`;

const SubSectionTitle = styled.div`
  font-size: ${fontSize.sm};
  font-family: ${family.interDisplay};
`;

const FilterBarsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  padding-top: 8px;

  span {
    font-size: ${fontSize.sm};
  }
`;

const MetaInformation = styled.div`
  display: flex;
  align-items: center;
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

const AverageTenureLabel = styled.span`
  color: ${grey.mid};
  font-size: ${fontSize.xs};
  padding-left: 5px;
`;

const ActiveFiltersContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ActiveFilterPill = styled.div<{ backgroundColor: string }>`
  font-size: ${fontSize.sm};
  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: 0.6;
  border-radius: 17px;
  color: ${standardColors.white};
  padding: 1px 7px 1px 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
`;

const ActiveFilterPillLabel = styled.span`
  padding-right: 5px;
  font-size: ${fontSize.sm};
`;

const ActiveFiltersLabel = styled.span`
  color: ${grey.mid};
  font-size: ${fontSize.sm};
`;

const CrossIcon = styled(CrossInCircle)`
  height: 20px;
  width: 20px;

  circle {
    stroke: transparent;
  }

  &:hover {
    cursor: pointer;
  }
`;

const CurrentRolesBarContainer = styled.div`
  margin-bottom: 20px;
`;

const EmploymentDetailsContainer = styled.div<{ isLeftPanelEmpty: boolean }>`
  /* When there's no data in the left panel the Show detail button
     can get too close to the right hand panel. We're just adding
     a bit of breathing room here.
  */
  margin-bottom: ${({ isLeftPanelEmpty }) => (isLeftPanelEmpty ? "6px" : 0)};
`;

const CustomStickyExpandButton = styled(StickyExpandButton)`
  background-color: ${grey.panel};
`;

const Chevron = styled(ChevronSvg)`
  width: 6px;
  transform: rotate(90deg);

  path {
    fill: ${grey.dark};
  }
`;

const ConfidencebucketCount = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.dark} !important;
  padding-right: 6px;
`;

const OptionsDropdownMenuItem = styled(DropdownItem)`
  padding: 8px;
  color: ${grey.dark};
  font-size: ${fontSize.sm} !important;
  font-family: ${family.interLight};
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover,
  &:focus {
    background-color: ${() => {
      const { r, g, b } = hexToRgb(blue.icon);

      return `rgba(${r}, ${g}, ${b}, 0.15)`;
    }};
  }
  &:disabled {
    opacity: 0.3;
    color: ${standardColors.white};
  }
  &:focus {
    outline: none;
  }
  &:active {
    color: ${grey.dark};
  }
`;

const DisabledOptionsDropdownMenuItem = styled(OptionsDropdownMenuItem)`
  opacity: 0.3;
`;

const NoneIdentifiedText = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.ghost};
  font-style: italic;
`;

const TooltipContent = styled.div<{ width?: number }>`
  text-align: left;
  padding: 2px;
  width: ${({ width }) => width ?? 275}px;
  max-width: 275px;
  max-height: 325px;
  overflow-y: auto;
`;

const S = {
  SectionHeader,
  ResultsSection,
  ListTitle,
  FilterHeader,
  SubSectionHeader,
  SubSectionTitle,
  MetaInformation,
  FilterBarsHeader,
  AverageTenureLabel,
  ActiveFiltersContainer,
  ActiveFilterPill,
  ActiveFilterPillLabel,
  ActiveFiltersLabel,
  CrossIcon,
  CurrentRolesBarContainer,
  EmploymentDetailsContainer,
  CustomStickyExpandButton,
  Chevron,
  OptionsDropdownMenuItem,
  DisabledOptionsDropdownMenuItem,
  ConfidencebucketCount,
  TooltipContent,
  NoneIdentifiedText
};

export default S;
