import React from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import useUserSettings from "util/hooks/useUserSettings";
import { Question } from "api/insight-reports/types";

import S from "./styles";

dayjs.extend(customParseFormat);

interface Props {
  question: Question;
}

const InsightsQuestion = ({ question }: Props) => {
  const { user } = question;

  const timestamp = dayjs(question.timestamp)
    .local()
    .format("HH:mm DD/MM/YYYY");

  const {
    state: { userDetails }
  } = useUserSettings();

  const questionAuthorString =
    user.id === userDetails.userId
      ? "You"
      : `${user.firstName} ${user.lastName}`;

  return (
    <S.QuestionContainer>
      <S.Question>{question.elements?.[0].text}</S.Question>
      <S.QuestionDate>
        {questionAuthorString} asked Xapien this question at {timestamp}.
      </S.QuestionDate>
    </S.QuestionContainer>
  );
};

export default InsightsQuestion;
