import type { CognitoUser } from "@aws-amplify/auth";

export const MFA_CODE_LENGTH = 6;

export enum HubAuthenticationActions {
  updateEmail = "updateEmail",
  updatePassword = "updatePassword",
  updateMfaCode = "updateMfaCode",
  authenticate = "authenticate",
  authenticated = "authenticated",
  verifyPassword = "verifyPassword",
  passwordVerified = "passwordVerified",
  unauthenticated = "unauthenticated",
  dismissError = "dismissError",
  mfaSetup = "mfaSetup"
}

export enum HubAuthenticationStatus {
  unknown = "unknown",
  unauthenticated = "unauthenticated",
  authenticating = "authenticating",
  authenticated = "authenticated",
  verifyingPassword = "verifyingPassword",
  passwordVerified = "passwordVerified",
  mfaSetup = "mfaSetup"
}

export interface HubAuthenticationState {
  email: string;
  password: string;
  status: HubAuthenticationStatus;
  user?: CognitoUser;
  session?: any;
  error?: string;
  mfaCode: string;
}

interface HubUpdateMfaCodeAction {
  type: HubAuthenticationActions.updateMfaCode;
  mfaCode: string;
}

interface HubVerifyPasswordAction {
  type: HubAuthenticationActions.verifyPassword;
}

interface HubPasswordVerifiedAction {
  type: HubAuthenticationActions.passwordVerified;
  session: any;
}

interface HubUpdateEmailAction {
  type: HubAuthenticationActions.updateEmail;
  email: string;
}

interface HubUpdatePasswordAction {
  type: HubAuthenticationActions.updatePassword;
  password: string;
}

interface HubAuthenticateAction {
  type: HubAuthenticationActions.authenticate;
}

interface HubAuthenticatedAction {
  type: HubAuthenticationActions.authenticated;
  session: any;
}

interface HubUnauthenticatedAction {
  type: HubAuthenticationActions.unauthenticated;
  error?: string;
}

interface HubDismissErrorAction {
  type: HubAuthenticationActions.dismissError;
}

interface HubMfaSetupAction {
  type: HubAuthenticationActions.mfaSetup;
}

export type HubAuthenticationAction =
  | HubUpdateEmailAction
  | HubUpdatePasswordAction
  | HubAuthenticateAction
  | HubAuthenticatedAction
  | HubUnauthenticatedAction
  | HubDismissErrorAction
  | HubVerifyPasswordAction
  | HubPasswordVerifiedAction
  | HubUpdateMfaCodeAction
  | HubMfaSetupAction;
