import React, { useEffect, useState } from "react";

import { AnimatePresence } from "framer-motion/dist/framer-motion";
import { Auth } from "aws-amplify";
import { Lock } from "react-feather";

import Input from "components/atoms/Input";
import ErrorBanner from "components/atoms/ErrorBanner";

import config from "config";
import S from "./styles";

export enum DetailsFormFields {
  FirstName = "firstName",
  LastName = "lastName"
}

export interface DetailsFormError {
  type: DetailsFormFields;
}

interface Props {
  userFirstName: string;
  userLastName: string;
  userJobTitle: string;
  userEmail: string;
  onUserFirstNameChange: (value: string) => void;
  onUserLastNameChange: (value: string) => void;
  onUserJobTitleChange: (value: string) => void;
  formErrors: DetailsFormError[];
}

const UserPersonalDetails = ({
  userFirstName,
  userLastName,
  userJobTitle,
  userEmail,
  onUserFirstNameChange,
  onUserLastNameChange,
  onUserJobTitleChange,
  formErrors
}: Props) => {
  const [passwordResetSent, setPasswordResetSent] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (passwordResetSent) {
      setTimeout(() => setPasswordResetSent(false), 3000);
    }
  }, [passwordResetSent]);

  const onPasswordReset = async () => {
    if (!passwordResetSent) {
      try {
        await Auth.forgotPassword(`${userEmail.toLowerCase()}+${config.tenantId}`);
      } catch (e: any) {
        const message = `Error resetting password, please contact support.${
          e && e.message && typeof e.message === "string"
            ? ` (${e.message})`
            : ""
        }`;
        console.error(message, e);
        setError(message);
        return;
      }
      setPasswordResetSent(true);
    }
  };

  return (
    <S.Form onSubmit={e => e.preventDefault()} noValidate>
      <Input
        label="First name"
        onChange={onUserFirstNameChange}
        placeholder="first name"
        value={userFirstName}
      />
      {formErrors.some(e => e.type === DetailsFormFields.FirstName) && (
        <ErrorBanner text="First name is required." />
      )}
      <Input
        label="Last name"
        onChange={onUserLastNameChange}
        placeholder="last name"
        value={userLastName}
      />
      {formErrors.some(e => e.type === DetailsFormFields.LastName) && (
        <ErrorBanner text="Last name is required." />
      )}
      <S.FieldContainer>
        <S.FieldLabel>Email</S.FieldLabel>
        <S.Field>
          {userEmail} <Lock size={16} />
        </S.Field>
      </S.FieldContainer>
      <Input
        label="Job title (optional)"
        onChange={onUserJobTitleChange}
        placeholder="job title"
        value={userJobTitle}
      />
      {error ? (
        <ErrorBanner text={error} onClick={() => setError(undefined)} />
      ) : undefined}
      <S.FieldContainer>
        <S.FieldLabel>Password</S.FieldLabel>
        <S.Field>
          ••••••••••••{" "}
          <S.ResetPassword onClick={onPasswordReset}>
            Reset password
          </S.ResetPassword>
        </S.Field>
      </S.FieldContainer>
      <AnimatePresence>
        {passwordResetSent ? (
          <S.ResetPasswordSentText
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.6 }}
          >
            A password reset link has been sent to {userEmail}.
          </S.ResetPasswordSentText>
        ) : (
          <S.ResetPasswordText
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.6 }}
          >
            Clicking Reset password will send a password reset link to{" "}
            {userEmail}.
          </S.ResetPasswordText>
        )}
      </AnimatePresence>
    </S.Form>
  );
};

export default UserPersonalDetails;
