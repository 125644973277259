import React, { FC, FormEvent, MouseEventHandler, useState } from "react";
import Input from "components/atoms/Input";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import ErrorBanner from "components/atoms/ErrorBanner";
import Api from "api/user";
import config from "config";
import { isPDX } from "static-config";

import {
  useAuthentication,
  AuthenticationActions
} from "util/hooks/useAuthentication";
import { AuthenticationStatus } from "util/hooks/useAuthentication/types";

import S from "./styles";
import LoginHeader from "../LoginHeader";

interface Props {
  slogan?: string;
  onForgottenPasswordClick: () => void;
  onSetMigrationFlow: (id: string) => void;
}

const LoginForm: FC<Props> = ({
  slogan,
  onForgottenPasswordClick,
  onSetMigrationFlow
}) => {
  const { state, dispatch } = useAuthentication();
  const [loading, setLoading] = useState(false);

  const UserApi = new Api();

  const onEmailChange = (email: string) =>
    dispatch({ type: AuthenticationActions.updateEmail, email });

  const onPasswordChange = (password: string) =>
    dispatch({ type: AuthenticationActions.updatePassword, password });

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const filledFields =
      state.email &&
      state.email.trim().length &&
      state.password &&
      state.password.trim().length;

    if (!filledFields) {
      setLoading(false);

      dispatch({
        type: AuthenticationActions.unauthenticated,
        error: "Please enter your email address and password."
      });
      return;
    }

    const { status, response } = await UserApi.getMigrationPending(
      state.email,
      config.tenantId
    );

    setLoading(true);
    // Exit early into the migration modal if the user is in a pending migration state
    if (status && response?.isPending && response?.requestId) {
      onSetMigrationFlow(response.requestId);
      setLoading(false);
      return;
    }

    dispatch({ type: AuthenticationActions.verifyPassword });
    setLoading(false);
  };

  const onDismissError: MouseEventHandler<HTMLButtonElement> = () => {
    dispatch({ type: AuthenticationActions.dismissError });
  };

  return (
    <form onSubmit={onSubmit}>
      <S.Container>
        {!isPDX && <LoginHeader slogan={slogan} />}
        <S.Inputs>
          {state.error && (
            <ErrorBanner text={state.error} onClick={onDismissError} />
          )}

          <Input
            inputType="email"
            placeholder="Email"
            onChange={onEmailChange}
            value={state.email}
            autoComplete="email"
          />
          <Input
            inputType="password"
            placeholder="Password"
            onChange={onPasswordChange}
            value={state.password}
            autoComplete="current-password"
          />
        </S.Inputs>
        <S.Link onClick={onForgottenPasswordClick}>
          Forgotten your password?
        </S.Link>

        <S.SignInButton
          onClick={onSubmit}
          type={ButtonType.Filled}
          size={ButtonSize.Medium}
          disabled={state.status === AuthenticationStatus.authenticating}
          text={
            loading || state.status === AuthenticationStatus.authenticating
              ? "Signing in"
              : "Go"
          }
        />
      </S.Container>
    </form>
  );
};

export default LoginForm;
