import React, { FC, SVGProps, useState } from "react";

import { Send } from "react-feather";
import { Spinner } from "reactstrap";

import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import ButtonNew from "components/atoms/ButtonNew";
import Api from "api/user";
import ErrorBanner from "components/atoms/ErrorBanner";

// @ts-ignore
import theme from "theme";

import S from "./styles";

interface Props {
  migrationId: string;
  onReturnToLogin: () => void;
}

const Migration = ({ migrationId, onReturnToLogin }: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const UserApi = new Api();

  const onSendEmail = async () => {
    setLoading(true);
    const { status, message } = await UserApi.sendMigrationEmail(migrationId);

    if (!status) {
      setError(
        message ||
          "There was an issue sending the email. Try again in a moment."
      );
      return;
    }

    setLoading(false);
    onReturnToLogin();
  };

  return (
    <S.Container>
      {theme.navigation?.logo ?? <S.XapienLogo />}
      <S.Title level={5}>
        {theme.slogan ?? "Xapien is a fully-automated research platform"}
      </S.Title>
      <S.Subtitle level={4}>Our new platform is now live</S.Subtitle>
      <p style={{ textAlign: "center" }}>
        In order to migrate you to the new platform, we will need to validate
        your email and refresh your password. We will send you an email to get
        started.
      </p>
      <ButtonNew
        text="Send email"
        type={ButtonType.Filled}
        size={ButtonSize.Medium}
        IconTrailing={
          loading ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>) : Send
        }
        onClick={onSendEmail}
      />
      {error && (
        <ErrorBanner text={error} onClick={() => setError(undefined)} />
      )}
    </S.Container>
  );
};

export default Migration;
