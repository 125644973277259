import React, { ReactNode, useMemo, useCallback } from "react";

import Users from "api/users";
import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";
import UserReports from "api/userReports";
import Reports from "api/reports";

import { UserActionsContext } from "./context";

const UserActionsProvider = ({ children }: { children: ReactNode }) => {
  const [{ fetching, error, success, errorMessage }, fetchDispatch] =
    useFetchReducer();
  const UsersApi = useMemo(() => new Users(), []);
  const UserReportsApi = useMemo(() => new UserReports(), []);
  const ReportsApi = useMemo(() => new Reports(), []);

  const deleteUser = useCallback(
    async ({
      userId,
      transferUserId,
      dataLossAcknowledged
    }: {
      userId: string;
      transferUserId?: string;
      dataLossAcknowledged: boolean;
    }): Promise<boolean> => {
      fetchDispatch({ type: RequestActions.SendRequest });

      if (transferUserId) {
        const { status, message } = await UsersApi.transferUserReports(
          userId,
          transferUserId
        );

        if (!status) {
          fetchDispatch({
            type: RequestActions.SetError,
            errorMessage: message
          });
          return false;
        }
      }

      if (dataLossAcknowledged) {
        const { items: reports } = await UserReportsApi.list({
          limit: 1000,
          offset: 0,
          userId,
          filterByUserId: userId
        });

        const responses = await Promise.all(
          reports.map(async ({ id }) => {
            const { status } = await ReportsApi.deleteReport(id);

            return status;
          })
        );

        if (responses.includes(false)) {
          fetchDispatch({
            type: RequestActions.SetError,
            errorMessage: "Could not delete all reports, please try again"
          });

          return false;
        }
      }

      const { status, message } = await UsersApi.deleteUser(userId);

      if (!status) {
        fetchDispatch({ type: RequestActions.SetError, errorMessage: message });
        return false;
      }

      fetchDispatch({ type: RequestActions.SetSuccess });
      return true;
    },
    [UsersApi, ReportsApi, UserReportsApi, fetchDispatch]
  );

  const addToGroup = useCallback(
    async (userId: string, groupId: string): Promise<boolean> => {
      fetchDispatch({ type: RequestActions.SendRequest });

      const { status, message } = await UsersApi.addToGroup(userId, groupId);

      if (!status) {
        fetchDispatch({ type: RequestActions.SetError, errorMessage: message });
        return false;
      }

      fetchDispatch({ type: RequestActions.SetSuccess });
      return true;
    },
    [UsersApi, fetchDispatch]
  );

  const resetError = useCallback(() => {
    fetchDispatch({ type: RequestActions.ResetError });
  }, [fetchDispatch]);

  const resetAll = useCallback(() => {
    fetchDispatch({ type: RequestActions.Reset });
  }, [fetchDispatch]);

  const providerValue = useMemo(
    () => ({
      deleteUser,
      addToGroup,
      resetError,
      resetAll,
      fetching,
      error,
      errorMessage,
      success
    }),
    [
      deleteUser,
      addToGroup,
      resetError,
      resetAll,
      fetching,
      error,
      errorMessage,
      success
    ]
  );

  return (
    <UserActionsContext.Provider value={providerValue}>
      {children}
    </UserActionsContext.Provider>
  );
};

export { UserActionsProvider };
