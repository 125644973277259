import { styled } from "@linaria/react";
import { motion } from "framer-motion/dist/framer-motion";

import ButtonNew from "components/atoms/ButtonNew";

// @ts-ignore
import theme from "theme";

import { standardColors, blue } from "styles/colors";
import { fontSize } from "styles/fonts";

const AccessButton = styled(ButtonNew)`
  background-color: ${theme.primaryColor};
  color: ${standardColors.white};
  text-transform: capitalize;
`;

const ModalTitle = styled.h1`
  font-weight: 400;
  font-size: ${fontSize.xl};
  line-height: 2rem;
  height: 2rem;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
`;

const Text = styled(motion.p)`
  text-align: center;
  margin: 0;
`;

const ConfirmationText = styled(motion.p)`
  text-align: center;
  margin: -32px 0px 8px;
`;

const Email = styled.span`
  color: ${blue.xapienLight};
`;

const S = {
  AccessButton,
  ModalTitle,
  ModalContent,
  ConfirmationText,
  Text,
  Email
};

export default S;
