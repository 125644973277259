import { styled } from "@linaria/react";
import ButtonNew from "components/atoms/ButtonNew";
import ReportUsagePanel from "components/molecules/ReportUsagePanel";
import TitleCard from "components/molecules/TitleCard";
import { blue, grey, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";
import { ReactComponent as Building } from "img/building.svg";

const ManageOrganisation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  /* 85px = height of nav bar */
  height: calc(100vh - 85px);
  justify-content: space-between;
`;

const ManageOrganisationInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const CustomTitleCard = styled(TitleCard)`
  background-color: ${blue.portalBlue};

  h5 + div {
    color: ${standardColors.white};
  }
`;

const OrgDetails = styled.p`
  margin: 0;
  color: ${grey.rule};
  margin-left: 3.75rem;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const UsagePanel = styled(ReportUsagePanel)`
  background-color: ${blue.portalBlue};
  margin-top: 0.75rem;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  width: 100%;
`;

const Button = styled(ButtonNew)`
  min-width: 12.5rem;
`;

const LeftControls = styled.div`
  display: flex;
  gap: 1.25rem;
`;

const ErrorFallback = styled.div`
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 12.5rem;
  color: ${grey.ghost};
  font-size: ${fontSize.xl};
  gap: 1rem;
`;

const BuildingIcon = styled(Building)`
  transform: scale(0.875);
`;

const FeatureSection = styled.div`
  background-color: ${blue.portalBlue};
  border-radius: 0.75rem;
  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FeatureSectionItem = styled.div`
  display: flex;
`;

const FeatureSectionDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const FeatureSectionAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FeatureSectionDescription = styled.div`
  color: ${grey.ghost};
`;

const S = {
  FeatureSection,
  FeatureSectionItem,
  FeatureSectionDetails,
  FeatureSectionAction,
  FeatureSectionDescription,
  ManageOrganisation,
  ManageOrganisationInnerContainer,
  CustomTitleCard,
  OrgDetails,
  InputGroup,
  UsagePanel,
  Controls,
  Button,
  LeftControls,
  ErrorFallback,
  BuildingIcon
};

export default S;
