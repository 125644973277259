import { observable, action, runInAction, makeObservable } from "mobx";
import EnquirySummaryAPI from "../api/enquirySummary/EnquirySummaryAPI";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

function msToTime(duration) {
  var seconds = parseInt((duration / 1000) % 60),
    minutes = parseInt((duration / (1000 * 60)) % 60),
    hours = parseInt((duration / (1000 * 60 * 60)) % 24);

  hours = hours > 0 ? (hours < 10 ? "0" + hours : hours) : "";
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return hours + (hours.length > 0 ? ":" : "") + minutes + ":" + seconds;
}

class EnquirySummaryStore {
  constructor(sharedUserInterface, enquirySummaryApi) {
    this.sharedUserInterface = sharedUserInterface;
    this.enquirySummaryApi =
      enquirySummaryApi ||
      new EnquirySummaryAPI(state =>
        runInAction(() => (this.sharedUserInterface.apiCallInProgress = state))
      );
    this.enquirySummary = observable.object({});
    this.personaSummaries = observable.array([]);
    this.loaded = false;
    this.notFound = false;
    this.enquiryId = null;

    this._refresh = this._refresh.bind(this);
    this.waitingOnResponse = false;

    this.enquiryId = null;
    this.enquirySummary = null;
    this.personaSummaries = [];

    makeObservable(this, {
      enquirySummary: observable,
      personaSummaries: observable,
      loaded: observable,
      enquiryId: observable
    });
  }

  reset() {
    this.enquiryId = null;
    this.enquirySummary = null;
    this.personaSummaries = [];
    this.loaded = false;
    this.notFound = false;
  }

  overview(enquiryId, runId) {
    this.waitingOnResponse = true;

    return new Promise((resolve, reject) => {
      const timeoutMillis = 30000;
      const id = setTimeout(() => {
        console.error(
          `Did not receive enquiry summary update within ${
            timeoutMillis / 1000
          } seconds, will allow another request to be made`
        );
        runInAction(() => {
          this.waitingOnResponse = false;
        });
        reject();
      }, timeoutMillis);
      this.enquirySummaryApi.enquirySummary(enquiryId, runId).then(
        enquirySummary => {
          try {
            const personaSummaries = enquirySummary.persona_summaries;
            clearTimeout(id);
            runInAction(() => {
              this.waitingOnResponse = false;
              this.loaded = true;
              this.enquirySummary = enquirySummary;
              this.personaSummaries = personaSummaries;
            });
            resolve();
          } catch (e) {
            console.log(`Error during enquiry summary store ${e}`);
            this.notFound = true;
          }
        },
        e => {
          runInAction(() => {
            this.waitingOnResponse = false;
          });
          reject(e);
          this.notFound = true;
        }
      );
    });
  }

  _refresh() {
    if (this.enquirySummary !== null) {
      this.overview(this.enquiryId, false);
    }
  }
}

export default EnquirySummaryStore;
