import { styled } from "@linaria/react";

import { MoreVertical } from "react-feather";

import { green, red } from "styles/colors";

const ContextMenuContainer = styled.div<{ isRemovingSection: boolean }>`
  position: relative;
  border-radius: 4px;
  padding: 0;
  transition: opacity 0.2s ease;
  outline-offset: 3px;

  outline: ${({ isRemovingSection }) =>
    isRemovingSection ? `3px solid ${red.badgeRed}` : "none"};

  &:hover {
    > [class*="ContextMenuControl"] {
      opacity: 1;
    }
  }

  &:hover:has(> [class*="ContextMenuControl"]:hover) {
    outline: 3px solid ${green.xapienGreen};

    > [class*="ContextMenuControl"] {
      opacity: 1;
    }
  }

  &:hover:has([class*="ContextMenuContainer"]:hover) {
    > [class*="ContextMenuControl"] {
      opacity: 0;
    }
  }
`;

const ContextMenuControl = styled.div`
  position: absolute;
  right: 0.2rem;
  top: 0.2rem;
  opacity: 0;
  transition: all 0.2s ease;
  cursor: pointer;
`;

const MenuControl = styled(MoreVertical)`
  cursor: pointer;
  height: 18px;

  &:hover {
    color: ${green.xapienGreen};
  }
`;

const S = {
  MenuControl,
  ContextMenuContainer,
  ContextMenuControl
};

export default S;
