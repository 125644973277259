import React, {
  FormEvent,
  useEffect,
  useState,
  MouseEventHandler
} from "react";
import PinInput from "components/molecules/PinInput";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import {
  useAuthentication,
  AuthenticationActions
} from "util/hooks/useAuthentication";

import { MFA_CODE_LENGTH } from "util/hooks/useAuthentication/types";

import MfaSetup from "./MfaSetup";
import S from "./styles";

const MfaForm = () => {
  const [showSetup, setShowSetup] = useState(false);
  const [mfaValidationError, setMfaValidationError] = useState(false);
  const { state, dispatch } = useAuthentication();
  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (state.mfaCode.replace(/ /g, "").length !== MFA_CODE_LENGTH) {
      setMfaValidationError(true);

      return;
    }

    if (
      !state.session?.challengeName ||
      state.session?.challengeName === "MFA_SETUP"
    ) {
      dispatch({ type: AuthenticationActions.mfaSetup });
    } else {
      dispatch({ type: AuthenticationActions.authenticate });
    }
  };

  const onChange = (value: string) => {
    dispatch({ type: AuthenticationActions.updateMfaCode, mfaCode: value });
  };

  const onDismissMfaValidationError = () => {
    setMfaValidationError(false);
  };

  const onSetupComplete: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    setShowSetup(false);
  };

  useEffect(() => {
    if (
      !state.session?.challengeName ||
      state.session?.challengeName === "MFA_SETUP"
    ) {
      setShowSetup(true);
    } else {
      setShowSetup(false);
    }
  }, [state.session]);

  return (
    <form onSubmit={onSubmit}>
      <S.Container>
        <S.XapienLogo />
        <S.Title level={5}>Xapien</S.Title>

        {showSetup ? (
          <MfaSetup onComplete={onSetupComplete} />
        ) : (
          <>
            <S.Subtitle level={4}>
              Enter your six-digit verification code
            </S.Subtitle>

            <S.Description>
              This code is generated by the app you used when setting up
              multi-factor authentication.
            </S.Description>

            <S.Inputs>
              <PinInput
                length={MFA_CODE_LENGTH}
                onChange={onChange}
                value={state.mfaCode}
              />
            </S.Inputs>

            {mfaValidationError && (
              <S.ErrorBanner
                text="Please enter a valid 6 digit code"
                onClick={onDismissMfaValidationError}
              />
            )}

            <ButtonNew
              type={ButtonType.Filled}
              size={ButtonSize.Medium}
              text="Go"
            />
          </>
        )}
      </S.Container>
    </form>
  );
};

export default MfaForm;
