import { styled } from "@linaria/react";

import { fontSize } from "styles/fonts";
import { grey } from "styles/colors";

const ContentContainer = styled.div<{ maxWidth: string }>`
  max-width: ${props => props.maxWidth};
  padding: 14px;
  font-size: ${fontSize.sm};
  color: ${grey.dark};
`;

const S = {
  ContentContainer
};

export default S;
