import { stageUniqueName } from "static-config";

export const devel = "devel";
export const qa = "qa";
export const diDemo = "di-demo";
export const research = "research";

export const getStage = hostName =>
  hostName.startsWith("localhost") ? research : stageUniqueName ?? research;

export const stage = getStage(window.location.host);

export const internalStages = new Set([research, devel, qa]);
