import { styled } from "@linaria/react";

import { grey, blue, green, standardColors } from "styles/colors";

import { ReactComponent as PlaceholderImage } from "img/icons/profile-placeholder.svg";
import { DiImage } from "components/molecules/DiImage";
import WithInspector from "components/organisms/WithInspector";
import SocialMediaIcons from "components/molecules/SocialMediaIcons";

const MINIMUM_CARD_HEIGHT = "161px";
const MAX_CARD_HEIGHT = "290px";

const CardInnerContainer = styled.div`
  width: 100%;
  max-width: none;
  padding: 16px;
  background-color: ${props => props.backgroundColor ?? grey.panel};
  border-radius: 3px;
  display: flex;
  align-items: flex-start;
  height: 100%;
  min-width: 11.2rem;
  min-height: ${props =>
    props.minHeight !== undefined ? props.minHeight : MINIMUM_CARD_HEIGHT};
  height: ${props =>
    props.shouldCardBeUniformHeight ? MAX_CARD_HEIGHT : "auto"};
  cursor: ${props => (props.isHoverable ? "pointer" : "initial")};
`;

const CardContentContainer = styled.div`
  display: flex;
  width: 100%;
`;

const CardImageSection = styled.div`
  display: flex;
  margin-bottom: 9px;
`;

const ImageContainer = styled.div`
  display: relative;
  width: 74px;
  height: 74px;
`;

const Image = styled(DiImage)`
  height: 74px;
  margin-bottom: 8px;
  width: 74px;
  object-fit: cover;
  border-radius: 3px;
`;

const Container = styled.div`
  margin-right: 12px;
`;

const PlaceholderIcon = styled(PlaceholderImage)`
  height: 74px;
  margin-bottom: 8px;
  width: 74px;
  object-fit: cover;
  border-radius: 3px;
`;

const CardContainer = styled.div`
  margin: 0;
  cursor: default;
  break-inside: avoid;
  visibility: ${props => (props.showCard ? "visible" : "hidden")};

  ${CardInnerContainer} {
    border-top: 3px solid
      ${props =>
        props.isKnownToBeInactive
          ? grey.ghost
          : props.borderColor ?? green.xapienGreen};
  }

  ${PlaceholderIcon} {
    opacity: ${props => (props.isKnownToBeInactive ? "0.6" : "1")};
  }

  ${Image} {
    filter: ${props => (props.isKnownToBeInactive ? "grayscale(1)" : "none")};
    opacity: ${props => (props.isKnownToBeInactive ? "0.8" : "1")};
  }
`;

const StyledWithInspector = styled(WithInspector)`
  margin-top: 8px;
`;

const InspectorSocialMediaIcons = styled(SocialMediaIcons)`
  a {
    color: ${props =>
      props.isSelected ? standardColors.white : grey.dark} !important;
  }

  path {
    fill: ${props =>
      props.isSelected ? standardColors.white : blue.icon} !important;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const S = {
  CardContainer,
  CardInnerContainer,
  CardContentContainer,
  CardImageSection,
  ImageContainer,
  Image,
  Container,
  PlaceholderIcon,
  StyledWithInspector,
  InspectorSocialMediaIcons,
  CardContent
};

export default S;
