import { createContext, useContext } from "react";

export const EnquiryIdContext = createContext<string | undefined>(undefined);
export const useEnquiryId = (): string => {
  const id = useContext(EnquiryIdContext);
  if (id && id.length) {
    return id;
  }
  throw new Error("No Enquiry ID");
};
