import React from "react";
import S, {classNameOverrides} from "../../../components/organisms/ReportCard/styles";
import Avatar from "../../../components/atoms/Avatar";
import {AvatarSize} from "../../../components/atoms/Avatar/types";
import {Person} from "@mui/icons-material";
import Card from "components/molecules/Card";
import CardHeader from "components/molecules/CardHeader";
import CardFooter from "components/molecules/CardFooter";
import PersonaCardMenu from "../PersonaCardMenu";
import {PersonaSummary} from "../../../api/enquirySummary/EnquirySummaryAPI";
import {CardVariant} from "../../../components/molecules/Card/types";
import {useParams, useSearchParams} from "react-router-dom";
import ReactTooltip from "components/atoms/ReactTooltip";

interface Props {
    item: PersonaSummary
}

const PersonaCard = ({item}: Props) => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const runId = searchParams.get("runId");
    const { enquiryId } = params;
    const persona = item;
    
    if (!persona) {
        return (
            <Card
                variant={CardVariant.default}
                className={classNameOverrides.failedCard}
            >
                <CardHeader
                    variant={CardVariant.default}
                    icon={
                        <S.AvatarIconContainer>
                            <Avatar
                                avatarSize={AvatarSize.Large}
                                hasBorder
                                background={
                                    undefined
                                }
                            >
                                <S.LoadingBar />
                            </Avatar>
                        </S.AvatarIconContainer>
                    }
                />
            </Card>
        );
    }
    const personaReportURL = runId ? `/report/${enquiryId}/${persona.persona_id}?runId=${runId}` : `/report/${enquiryId}/${persona.persona_id}`;


    const isConfirmed = persona.subject_score_percentage == 100;
    const isRejected = persona.subject_score_percentage == 0;


    let className = isConfirmed ? classNameOverrides.card : isRejected ? classNameOverrides.failedCard : classNameOverrides.cardUpdating;

    const warnings_to_show = persona.resolution_accuracy_warnings.filter(
        (warning) => warning.certainty !== "COULD_BE"
    );
    
    let warningsList = [];
    if (warnings_to_show.length > 0) {
        className = classNameOverrides.resolutionWarningCard;
        warningsList.push((
            <div>
                {
                    warnings_to_show.length > 1 ?
                        `${warnings_to_show.length} Resolution Accuracy Warnings!` : "Resolution Accuracy Warning!"
                    
                }
            </div>
        ));
        // Map through each warning and generate a CardFooter for each one
        warnings_to_show.forEach((warning, index) => {
            let message = `[Unlocalised] Category: ${warning.category} Certainty: ${warning.certainty}`;
            let hoverText = '[Unlocalised] Category: ${warning.category} Certainty: ${warning.certainty}';

            // Switch based on category and certainty
            if (warning.category === 'GENERAL_OVERLINK' && warning.certainty === 'ALMOST_CERTAINLY') {
                message = 'It is highly likely that there are mistakes in this report, please review all information carefully. This could be due to conflicting information found in the sources that Xapien processed.';
                hoverText = 'It is highly likely that there are mistakes in this report, please review all information carefully. This could be due to conflicting information found in the sources that Xapien processed.';
            } else if (warning.category === 'GENERAL_OVERLINK' && warning.certainty === 'PROBABLY') {
                message = 'It is likely that there are mistakes in this report, please review all information carefully. This could be due to conflicting information found in the sources that Xapien processed.';
                hoverText = 'It is likely that there are mistakes in this report, please review all information carefully. This could be due to conflicting information found in the sources that Xapien processed.';
            } else if (warning.category === 'GENERAL_OVERLINK' && warning.certainty === 'COULD_BE') {
                message = 'It is possible that there are some mistakes in this report, please review all information carefully. This could be due to conflicting information found in the sources that Xapien processed.';
                hoverText = 'It is possible that there are some mistakes in this report, please review all information carefully. This could be due to conflicting information found in the sources that Xapien processed.';
            } else if (warning.category === 'FAMILY_OVERLINK' && warning.certainty === 'ALMOST_CERTAINLY') {
                message = 'It is highly likely that there are mistakes in this report, please review all information carefully. This is due to the presence of family members with similar names.';
                hoverText = 'It is highly likely that there are mistakes in this report, please review all information carefully. This is due to the presence of family members with similar names.';
            } else if (warning.category === 'FAMILY_OVERLINK' && warning.certainty === 'PROBABLY') {
                message = 'It is likely that there are mistakes in this report, please review all information carefully. This is due to the presence of family members with similar names.';
                hoverText = 'It is likely that there are mistakes in this report, please review all information carefully. This is due to the presence of family members with similar names.';
            } else if (warning.category === 'FAMILY_OVERLINK' && warning.certainty === 'COULD_BE') {
                message = 'It is possible that there are some mistakes in this report, please review all information carefully. This is due to the presence of family members with similar names.';
                hoverText = 'It is possible that there are some mistakes in this report, please review all information carefully. This is due to the presence of family members with similar names.';
            }
            let accuracyWarning = (
                <ReactTooltip tooltip={hoverText} delay={0}>
                    <div>
                        {message}
                    </div>
                </ReactTooltip>
            );

            // Add the accuracyWarning to the warningsList
            warningsList.push(accuracyWarning);
        });
        
    }
    
    return (
        <Card
            onClick={() => window.open(personaReportURL)}
            variant={CardVariant.default}
            className={className}
            control={
                <PersonaCardMenu/>
            }
        >
            <CardHeader
                variant={CardVariant.default}
                subtitle={persona?.summary}
                overview={persona?.name}
                icon={
                    <S.AvatarIconContainer>
                        <Avatar
                            avatarSize={AvatarSize.Large}
                            hasBorder
                            background={
                                undefined
                            }
                        >
                            <Person fontSize="large" />
                        </Avatar>
                    </S.AvatarIconContainer>
                }
            />
            {warningsList}
            <CardFooter
                variant={CardVariant.default} persona={persona}
            >
            </CardFooter>
        </Card>
    );
};

export default PersonaCard;
