import React from "react";
import { Link } from "react-router-dom";

import S from "./styles";

export class ErrorOldReport extends React.Component {
  render() {
    const { subject, contexts } = this.props;
    const mailSubject = encodeURI("Xapien Error Diagnostics");
    const body = this.buildBody();
    const mailToLink = `mailto:support@xapien.com?subject=${mailSubject}&body=${body}`;
    const search = encodeURI(`?subject=${subject}&context=${contexts}`);

    return (
      <S.ErrorContainer>
        This report is no longer available as it contains some old features.
        Please
        <Link
          to={{ pathname: "/define", search }}
          target="_blank"
          rel="noreferrer noopener"
        >
          {" "}
          click here{" "}
        </Link>
        to generate a fresh report, your account will not be charged for this
        report.
        <br />
        <br />
        If you need any further help or support, then just
        <a href={mailToLink} rel="noopener noreferrer">
          {" "}
          click here{" "}
        </a>
        to email us - we will automatically get all the info we need about your
        old report to best assist your enquiry.
        <br />
        <br />
        Thanks - the DI support team.
      </S.ErrorContainer>
    );
  }

  buildVersionData() {
    const { currentVersion, reportVersion } = this.props;
    let message = "";
    if (currentVersion) {
      message = `Current version is ${currentVersion}.`;
    }
    if (reportVersion) {
      message = `${message} Report version is ${reportVersion}.`;
    }
    return message;
  }

  buildBody() {
    try {
      const body = encodeURI(
        "Dear Support,\n\nI'm experiencing trouble accessing the platform, here is the diagnostic information:\n\n" +
          this.buildVersionData() +
          "\n\n" +
          this.buildDiagnosticData()
      );
      return body;
    } catch (e) {
      console.error(e);
      return encodeURI(
        "Dear Support,\n\nI'm experiencing trouble accessing the platform. Error code: 68"
      );
    }
  }

  buildDiagnosticData() {
    const { error } = this.props;
    try {
      if (error) {
        return [error.name, error.message, error.stack].join("\n");
      } else {
        return this.props || "Error code 67";
      }
    } catch (e2) {
      console.error(e2);
      return "Error code 66";
    }
  }
}
