import React from "react";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { Plus } from "react-feather";
import { useNavigate } from "react-router-dom";

import { CollectionListContextProvider } from "util/hooks/useCollectionList";
import CollectionList from "components/organisms/CollectionList";
import OrganisationCard from "components/organisms/OrganisationCard";
import { CardVariant } from "components/molecules/Card/types";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import Heading from "components/atoms/Heading";
import { routes } from "pages/Router";

import Api from "api/hub/organisations";

import S from "./styles";

const OrganisationList = () => {
  const navigate = useNavigate();

  return (
    <CollectionListContextProvider ConstructApi={Api}>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <S.ViewContainer>
            <S.ViewInnerContainer>
              <CollectionList
                CardComponent={OrganisationCard}
                cardVariant={CardVariant.dark}
                emptyCollectionText="No organisations"
                navigationComponent={
                  <Heading level={6}>
                    Click on an organisation to manage
                  </Heading>
                }
                createItemComponent={
                  <ButtonNew
                    text="Create new"
                    type={ButtonType.Outlined}
                    size={ButtonSize.Small}
                    IconLeading={Plus}
                    onClick={() =>
                      navigate(`/${routes.hub}/${routes.createOrg}`)
                    }
                  />
                }
              />
            </S.ViewInnerContainer>
          </S.ViewContainer>
        </motion.div>
      </AnimatePresence>
    </CollectionListContextProvider>
  );
};

export default OrganisationList;
