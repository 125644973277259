import React, { useReducer, useCallback, useMemo, FC, ReactNode } from "react";
import type ReportsApi from "api/reports";
import type UsersApi from "api/users";
import type GroupsApi from "api/groups";
import type UserReportsApi from "api/userReports";

import { collectionSearchReducer, CollectionSearchContext } from "./context";
import type { CollectionSearchAction } from "./types";

interface Props {
  Api:
    | typeof ReportsApi
    | typeof UsersApi
    | typeof GroupsApi
    | typeof UserReportsApi;
  children: ReactNode;
}

export const CollectionSearchContextProvider: FC<Props> = ({
  Api,
  children
}) => {
  const api = useMemo(() => new Api(), [Api]);
  const initialState = api.getEmptySearchState();
  const [state, reducerDispatch] = useReducer(
    collectionSearchReducer,
    initialState
  );

  const dispatch = useCallback((action: CollectionSearchAction) => {
    reducerDispatch(action);
  }, []);

  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <CollectionSearchContext.Provider value={providerValue}>
      {children}
    </CollectionSearchContext.Provider>
  );
};
