import React, { FC, useEffect, useState, ReactNode, useCallback } from "react";
import { useLocation } from "react-router-dom";

import { InformationSource } from "api/report/report-types";
import InsightReportsApi from "api/insight-reports";
import WithInspector from "components/organisms/WithInspector";

import {
  useInsightReport,
  InsightReportActions
} from "util/hooks/useInsightReport";

import { useEnquiryId } from "util/hooks/useEnquiryId";
import { useSubjectName } from "util/hooks/useSubjectName";

import { reportStore } from "state/Store";

import type { SupportingSentence } from "api/insight-reports/types";

import { InsightReportStatus } from "util/hooks/useInsightReport/types";
import S from "./styles";
import {usePersonaId} from "../../../../util/hooks/usePersonaId";

interface Props {
  id: string;
  supportingSentences?: SupportingSentence[];
  children: ReactNode;
  parentIds: string[];
  showSourcing: boolean;
}

const TextNode: FC<Props> = ({
  id,
  supportingSentences,
  children,
  parentIds,
  showSourcing
}) => {
  const { dispatch, state } = useInsightReport();
  const reportId = useEnquiryId();
  const personaId = usePersonaId();
  const subjectName = useSubjectName();
  const [sources, setSources] = useState<InformationSource[]>([]);
  const { search } = useLocation();

  const mapSource = (sourceId: string) => {
    return reportStore.webAndMediaData.find(data => data.sourceId === sourceId);
  };

  const loadSources = useCallback(() => {
    if (!supportingSentences) return;

    const sourcesFromSentences = supportingSentences.flatMap(
      ({ text, wamSourceIds }) => {
        const sourcesFromMap = wamSourceIds
          .map(sourceId => {
            const source = mapSource(sourceId);

            if (!source) return undefined;

            return {
              ...source,
              readingTime: undefined,
              subHeading: undefined,
              tags: [],
              text
            };
          })
          .filter(Boolean);

        return sourcesFromMap;
      }
    );

    if (sourcesFromSentences.length) {
      setSources(sourcesFromSentences);
    } else {
      dispatch({ type: InsightReportActions.errorFetchingSources });
      setSources([]);
    }
  }, [supportingSentences, dispatch]);

  useEffect(() => {
    if (supportingSentences && supportingSentences.length > 0) {
      loadSources();
    }
  }, [supportingSentences, loadSources]);

  const onInspectorOpen = (open: boolean) => {
    if (!open) return;

    if (!supportingSentences || supportingSentences.length === 0) {
      const [sectionId, subsectionId] = parentIds;
      const api = new InsightReportsApi();

      dispatch({
        type: InsightReportActions.updatingFetchingSources,
        nodeId: id
      });

      const params = new URLSearchParams(search);

      api
        .getSources({
          reportId,
          personaId,
          sectionId,
          subsectionId,
          subjectName: subjectName ?? "",
          shareToken: params.get("token")
        })
        .then(result => {
          if (!result.status) {
            dispatch({ type: InsightReportActions.errorFetchingSources });
            return;
          }

          const { response: subSection } = result;

          if (!subSection) return;

          dispatch({
            type: InsightReportActions.updateReportSubSection,
            sectionId,
            subsectionId,
            subSection
          });
        })
        .catch(() => {
          dispatch({ type: InsightReportActions.errorFetchingSources });
        });
    } else {
      loadSources();
    }
  };

  if (!showSourcing) return <>{children}</>;

  if (state.status === InsightReportStatus.fetchingSources)
    return (
      <WithInspector display="inline" hidePopover>
        {children}
      </WithInspector>
    );

  return (
    <WithInspector
      key={`WithInspector-${id}-${sources.length}`}
      display="inline"
      sources={sources}
      popoverTitle={`${sources.length} Strongest sources`}
      showTopSection={false}
      isInspectorOpen={onInspectorOpen}
      withPagination
      renderSourceItems={(
        _sources,
        _filter,
        ref,
        page,
        paginationComponent
      ) => {
        return (
          <S.SourceContainer ref={ref}>
            {sources[page] && (
              <S.MediaSourceCard
                {...sources[page]}
                // TODO: MediaSourceCard needs types
                // @ts-ignore
                show
                showAssessment
                key={`${sources[page].url}_${page}`}
                activeRiskDirectionality="direct"
                filterOutIndirectTags
                paginationComponent={paginationComponent}
              />
            )}
          </S.SourceContainer>
        );
      }}
    >
      {children}
    </WithInspector>
  );
};

export default TextNode;
