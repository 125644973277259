import { styled } from "@linaria/react";

import { ReactComponent as Logo } from "img/icons/xapien-logo-icon.svg";

import Heading from "components/atoms/Heading";

import { fontSize } from "styles/fonts";
import { grey, orange, blue, standardColors } from "styles/colors";

const XapienLogo = styled(Logo)`
  width: 48px;
  height: 48px;
  path: {
    fill: ${blue.xapienLight};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
`;

const Warning = styled.p`
  text-align: center;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;

  input {
    width: 100%;
  }
`;

const Subtitle = styled.p`
  color: ${grey.ghost};
  font-size: ${fontSize.sm};
  margin: 0;
`;

const Title = styled(Heading)`
  color: ${standardColors.white};
`;

const PasswordInvalid = styled.div`
  width: 100%;
  color: ${orange.amber};
`;

const PasswordValid = styled.div`
  width: 100%;
  color: ${blue.blueDiamond};
`;

const Link = styled.a`
  color: ${blue.blueDiamond};
`;

const Explainer = styled(Subtitle)`
  text-align: center;
`;

const S = {
  Container,
  Description,
  Title,
  Inputs,
  XapienLogo,
  PasswordInvalid,
  PasswordValid,
  Subtitle,
  Link,
  Warning,
  Explainer
};

export default S;
