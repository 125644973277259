import { styled } from "@linaria/react";

import { motion } from "framer-motion/dist/framer-motion";

import { standardColors, purple, yellow } from "styles/colors";
import { fontSize, family } from "styles/fonts";

import ButtonNew from "components/atoms/ButtonNew";

const PrimaryAction = styled(ButtonNew)`
  color: ${purple.dark};
`;

const SecondaryAction = styled(ButtonNew)`
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    background: transparent !important;
  }
`;

const Footer = styled(motion.div)`
  position: fixed;
  bottom: 28px;
  margin-left: -540px;
  max-width: 478px;
  z-index: 8;
  color: ${standardColors.white};
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px #00000040;
  background: linear-gradient(
      243.15deg,
      rgba(101, 46, 160, 0.97) 11.6%,
      rgba(50, 12, 90, 0.97) 106.68%
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));

  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.div`
  font-size: ${fontSize.lg};
  font-family: ${family.interSemiBold};
`;

const Body = styled.div`
  font-family: ${family.interLight};
  font-size: ${fontSize.sm};

  span {
    color: ${yellow.slime};
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 8px;
`;

const TitleMetaInfo = styled.div`
  font-family: ${family.interLight};
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
`;

const S = {
  IconContainer,
  PrimaryAction,
  SecondaryAction,
  Footer,
  Title,
  Body,
  Actions,
  TitleMetaInfo
};

export default S;
