import { HelpCircle as Unknown } from "react-feather";

import { ReactComponent as Biographical } from "img/insights-icons/biographical.svg";
import { ReactComponent as BusinessInterests } from "img/insights-icons/business-interests.svg";
import { ReactComponent as CharacterAchievements } from "img/insights-icons/character-achievements.svg";
import { ReactComponent as Controversy } from "img/insights-icons/controversy.svg";
import { ReactComponent as CrimeControversy } from "img/insights-icons/crime-controversy.svg";
import { ReactComponent as ESG } from "img/insights-icons/esg.svg";
import { ReactComponent as FamilyFriendsAssociates } from "img/insights-icons/family-friends-associates.svg";
import { ReactComponent as GlobalReach } from "img/insights-icons/global-reach.svg";
import { ReactComponent as KeyDetails } from "img/insights-icons/key-details.svg";
import { ReactComponent as KeyPeople } from "img/insights-icons/key-people.svg";
import { ReactComponent as LegalRegulatoryCompliance } from "img/insights-icons/legal-regulatory-compliance.svg";
import { ReactComponent as Overview } from "img/insights-icons/overview.svg";
import { ReactComponent as Ownership } from "img/insights-icons/ownership.svg";
import { ReactComponent as Philanthropy } from "img/insights-icons/philanthropy.svg";
import { ReactComponent as PoliticalExposure } from "img/insights-icons/political-exposure.svg";
import { ReactComponent as SanctionsExposure } from "img/insights-icons/sanctions-exposure.svg";
import { ReactComponent as Wealth } from "img/insights-icons/wealth.svg";

const Icon = {
  Biographical,
  BusinessInterests,
  CharacterAchievements,
  Controversy,
  CrimeControversy,
  ESG,
  FamilyFriendsAssociates,
  GlobalReach,
  KeyDetails,
  KeyPeople,
  LegalRegulatoryCompliance,
  Overview,
  Ownership,
  Philanthropy,
  PoliticalExposure,
  SanctionsExposure,
  Wealth,
  Unknown
};

export default Icon;
