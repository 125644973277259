import { styled } from "@linaria/react";

import Heading from "components/atoms/Heading";

import { ReactComponent as Logo } from "img/icons/xapien-logo-icon.svg";

// @ts-ignore
import theme from "theme";

import { blue, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const XapienLogo = styled(Logo)`
  width: 48px;
  height: 48px;
  path: {
    fill: ${blue.xapienLight};
  }
`;

const Title = styled(Heading)`
  color: ${theme.alternativePrimaryColor ?? blue.blueDiamond};
`;

const DefaultTitle = styled(Heading)`
  color: ${theme.alternativePrimaryColor ?? blue.blueDiamond};
`;

const Highlight = styled.span`
  color: ${standardColors.white};
`;

const Subtitle = styled(Heading)`
  color: ${standardColors.white};
  font-size: ${fontSize.lg};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  gap: 48px;
`;

const S = {
  Title,
  XapienLogo,
  Highlight,
  DefaultTitle,
  Container,
  Subtitle
};

export default S;
