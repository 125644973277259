import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { withParams } from "util/routerHocs";
import Overview from "pages/enquirySummary/Overview";
import {useParams, useSearchParams} from "react-router-dom";
import type EnquirySummaryStore from "../../../state/EnquirySummaryStore";

interface Props {
  enquirySummaryStore: EnquirySummaryStore
}

const PersonasOverview = ({ enquirySummaryStore }: Props) => {
  
  const params = useParams();
  const { enquiryId } = params;


  const [searchParams] = useSearchParams();
  const runId = searchParams.get("runId");

  useEffect(() => {
    enquirySummaryStore.overview(enquiryId,runId).catch(console.error);
  }, [enquiryId, enquirySummaryStore]);

  const diagProps = enquirySummaryStore;

  if (diagProps.loaded) {
    if (diagProps.notFound) {
      return <h2>Enquiry not found</h2>;
    }
    return (
        <div style={{ flex: 1, width: "100%" }}>
          <Overview personaSummaries={diagProps.personaSummaries}/>
        </div>
    );
  }

  return <div />;
};

export default inject("enquirySummaryStore")(observer(PersonasOverview));
