import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import { DiImage } from "components/molecules/DiImage";
import Button from "components/atoms/Button";

import { CardText } from "pages/report/cards/CardText";

import { fontSize, family } from "styles/fonts";
import { grey, blue, green, red, standardColors } from "styles/colors";
import { hexToRgb } from "styles/hexToRgb";
import SourceLinkComponent from "components/atoms/SourceLink";

const hr = "1px solid rgba(0, 124, 137, 0.30)";

const CardContainer = styled.div`
  background: ${standardColors.white};
  border-radius: 4px;
  padding: 16px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const CardInnerContainer = styled.div`
  text-align: left;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SourceDescription = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  border-top: ${hr};
  padding-top: 14px;
  margin-top: 8px;
  font-size: ${fontSize.sm};
  cursor: initial;
`;

const RiskCategories = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 6px;
  flex-flow: wrap;
`;

const CardFooter = styled.div`
  display: flex;
  align-items: center;
  border-top: ${hr};
  padding-top: 10px;
  margin-top: 8px;
`;

const SourceTitle = styled.div`
  font-size: ${fontSize.md};
  font-family: ${family.interSemiBold};
  color: ${grey.dark};
  line-height: 1.38;
  margin-right: 4px;
`;

const SourceImage = styled(DiImage)`
  object-fit: cover;
  height: 60px;
  width: 60px;
  border-radius: 8px;
`;

const SourceLink = styled(SourceLinkComponent)`
  font-size: ${fontSize.sm};
  color: ${green.mid} !important;
  cursor: pointer;

  &:hover {
    color: ${blue.icon} !important;
  }
`;

const RiskCountButton = styled(Button)`
  background-color: ${grey.faint} !important;
  font-size: ${fontSize.sm};
  color: ${grey.mid} !important;
  padding: 0 10px !important;
  min-height: 21px !important;
  border: 1px solid transparent !important;
  min-width: 10px !important;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px
      ${() => {
        const { r, g, b } = hexToRgb(grey.ghost);
        return `rgba(${r}, ${g}, ${b}, 0.3)`;
      }} !important;
  }
`;

const RiskCategoryPill = styled(Button)<{ isActive?: boolean }>`
  padding: 0px 8px;
  border: 1px solid ${red.directRisk} !important;
  border-radius: 16px;
  margin-right: 6px;
  font-size: ${fontSize.sm};
  margin-bottom: 6px;
  text-transform: capitalize;
  background-color: ${props =>
    props.isActive ? red.directRisk : "transparent"} !important;
  color: ${props =>
    props.isActive ? standardColors.white : red.directRisk} !important;
  min-height: 22px !important;
  box-shadow: none !important;
  min-width: 0 !important;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px rgba(239, 114, 115, 0.25) !important;
    cursor: pointer;
  }
`;

const CardTextContainer = styled.div`
  padding-top: 14px;
  margin-top: 8px;
  max-height: 142px;
  border-top: ${hr};
  overflow: auto;
`;

const CardSourceText = styled(CardText)`
  font-size: ${fontSize.sm};
`;

const NoTitleHeader = styled.span`
  font-style: italic;
  padding-right: 1px;
`;

const Snippet = styled.div`
  font-size: ${fontSize.sm};
  margin-bottom: 8px;
  cursor: initial;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SourceDescriptionContainer = styled.div`
  cursor: pointer;
`;

const NoSnippet = styled.span`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  font-style: italic;
`;

const SourceTitleLink = styled(SourceLinkComponent)`
  white-space: pre-wrap;
`;

const TruncatedSourceTitleLink = styled(SourceLinkComponent)`
  white-space: pre-wrap;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const SourceUrlPlaceholder = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

/* Classname overrides */

const boldenRisk = css`
  font-size: ${fontSize.sm};
  color: ${red.directRisk};
  font-family: ${family.interSemiBold};
`;

const sourceLink = css`
  width: 94%;
`;

const sourceDate = css`
  margin-left: 0;
`;

const S = {
  CardContainer,
  CardInnerContainer,
  SourceDescription,
  RiskCategories,
  CardFooter,
  CardHeader,
  SourceTitle,
  SourceImage,
  SourceLink,
  RiskCountButton,
  RiskCategoryPill,
  CardTextContainer,
  CardSourceText,
  NoTitleHeader,
  Snippet,
  SourceDescriptionContainer,
  NoSnippet,
  SourceTitleLink,
  SourceUrlPlaceholder,
  TruncatedSourceTitleLink
};

export default S;

export const classNameOverrides = {
  boldenRisk,
  sourceLink,
  sourceDate
};
