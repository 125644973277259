import React, { FC, ReactNode, useState, ChangeEvent } from "react";
import ModalContainer from "components/molecules/ModalContainer";
import { useSubjectName } from "util/hooks/useSubjectName";
import { useEnquiryId } from "util/hooks/useEnquiryId";
import { usePersonaId } from "util/hooks/usePersonaId";

import { ButtonKind } from "components/atoms/Button/types";
import ErrorBanner from "components/atoms/ErrorBanner";

import InsightReportsApi from "api/insight-reports";

import S from "./styles";

interface ContextItem {
  key: string;
  value?: ReactNode;
}

interface Props {
  isOpen: boolean;
  onToggle: () => void;
  context?: ContextItem[];
}

enum SubmissionStatus {
  idle = "idle",
  submitting = "submitting",
  submitted = "submitted"
}

const ProvideFeedbackModal: FC<Props> = ({ isOpen, onToggle, context }) => {
  const enquiryId = useEnquiryId();
  const personaId = usePersonaId();
  const subjectName = useSubjectName();
  const [feedbackText, setFeedbackText] = useState("");
  const [submissionError, setSubmissionError] = useState<undefined | string>(
    undefined
  );
  const [submissionStatus, setSubmissionStatus] = useState(
    SubmissionStatus.idle
  );

  const onDismissError = () => {
    setSubmissionError(undefined);
  };

  const onSubmitFeedback = () => {
    if (!subjectName) return;
    setSubmissionStatus(SubmissionStatus.submitting);
    const api = new InsightReportsApi();

    const sectionContext = context?.find(({ key }) => key === "Section");
    const subsectionContext = context?.find(({ key }) => key === "Sub-section");

    api
      .provideFeedback({
        id: enquiryId,
        personaId: personaId,
        feedbackText,
        subjectName,
        section: sectionContext?.value as string | undefined,
        subsection: subsectionContext?.value as string | undefined
      })
      .then(response => {
        if (response.status) {
          setSubmissionStatus(SubmissionStatus.submitted);

          setTimeout(() => {
            setSubmissionStatus(SubmissionStatus.idle);
            onToggle();
          }, 2000);
          return;
        }

        setSubmissionStatus(SubmissionStatus.idle);
        setSubmissionError(response.message);
      })
      .catch(e => {
        console.error(e);
        setSubmissionError("An unexpected error has occured");
        setSubmissionStatus(SubmissionStatus.idle);
      });
  };

  const onFeedbackChange = (
    event: ChangeEvent & { target: HTMLTextAreaElement }
  ) => {
    const { target } = event;

    if (target) {
      setFeedbackText(target.value);
    }
  };

  const onCloseModal = () => {
    setSubmissionStatus(SubmissionStatus.idle);
    setSubmissionError(undefined);
    setFeedbackText("");
    onToggle();
  };

  return (
    <ModalContainer
      title="Xapien Insights Feedback"
      isOpen={isOpen}
      variant="light"
      toggleOpen={onCloseModal}
      onExitClick={onCloseModal}
    >
      <p>
        We would love to hear your thoughts, suggestions or problems with the
        selected information so we can improve.
      </p>
      {context && (
        <S.ContextItems>
          {context
            .filter(({ value }) => value)
            .map(({ key, value }) => (
              <S.ContextItem key={`ProvideFeedback-Context-${key}`}>
                <strong>{key}:</strong> {value}
              </S.ContextItem>
            ))}
        </S.ContextItems>
      )}
      <S.Textarea
        onChange={onFeedbackChange}
        placeholder="Start typing to leave feedback..."
        value={feedbackText}
      />

      {submissionError && (
        <ErrorBanner text={submissionError} onClick={onDismissError} />
      )}

      <S.Actions>
        <S.CloseButton
          onClick={onToggle}
          kind={ButtonKind.secondary}
          disabled={
            submissionStatus === SubmissionStatus.submitting ||
            submissionStatus === SubmissionStatus.submitted
          }
        >
          Cancel
        </S.CloseButton>

        <S.SubmitButton
          onClick={onSubmitFeedback}
          kind={ButtonKind.primary}
          disabled={
            submissionStatus === SubmissionStatus.submitting ||
            submissionStatus === SubmissionStatus.submitted
          }
        >
          {submissionStatus === SubmissionStatus.idle && "Submit"}
          {submissionStatus === SubmissionStatus.submitting && "Submitting"}
          {submissionStatus === SubmissionStatus.submitted && "Sent"}
        </S.SubmitButton>
      </S.Actions>
    </ModalContainer>
  );
};

export default ProvideFeedbackModal;
