import React, { FunctionComponent, useState } from "react";

import { Skeleton } from "@mui/material";

import Avatar from "components/atoms/Avatar";
import { AvatarSize } from "components/atoms/Avatar/types";
import { getInitials } from "util/getInitials";
import InsightsAnswer from "components/organisms/InsightsChat/InsightsAnswer";
import InsightsQuestion from "components/organisms/InsightsChat/InsightsQuestion";

import threePurpleBallsGif from "img/threePurpleBalls.gif";
import threeGreenBallsGif from "img/3-slow-balls.gif";

import {
  Error,
  Question,
  Message,
  MessageType,
  Subsection
} from "api/insight-reports/types";

import S, { classNameOverrides } from "./styles";

const LoadingSkeleton = () => {
  return (
    <S.Message>
      <Skeleton variant="circular" width={32} height={32} />
      <div style={{ width: "100%" }}>
        <Skeleton height={22} width="50%" />
        <Skeleton height={22} width="60%" />
      </div>
    </S.Message>
  );
};

const InsightsAnswerAvatar = ({
  isFetchingSources
}: {
  isFetchingSources: boolean;
}) => {
  return isFetchingSources ? (
    <S.FetchingSourcesImage src={threeGreenBallsGif} />
  ) : (
    <Avatar
      avatarSize={AvatarSize.Medium}
      className={classNameOverrides.AnswerAvatar}
    >
      <S.XapienLogo />
    </Avatar>
  );
};

const InsightsQuestionAvatar = ({ question }: { question: Question }) => {
  return (
    <Avatar
      avatarSize={AvatarSize.Medium}
      className={classNameOverrides.QuestionAvatar}
    >
      {question && "user" in question
        ? getInitials(`${question?.user.firstName} ${question?.user.lastName}`)
        : ""}
    </Avatar>
  );
};

interface InsightsMessageProps {
  message?: Message;
  fetchingMessage?: boolean;
  loading?: boolean;
}

const InsightsMessage: FunctionComponent<InsightsMessageProps> = ({
  message,
  fetchingMessage,
  loading
}) => {
  const [isFetchingSources, setIsFetchingSources] = useState(false);

  const renderMessage = () => {
    if (fetchingMessage) {
      return <S.FetchingImage src={threePurpleBallsGif} />;
    }

    if (message?.type === MessageType.Answer) {
      return (
        <InsightsAnswer
          answer={message as Subsection}
          isFetchingSources={isFetchingSources}
          onFetchSources={setIsFetchingSources}
        />
      );
    }

    if (message?.type === MessageType.Question) {
      return <InsightsQuestion question={message as Question} />;
    }

    return <S.Error>{(message as Error)?.text}</S.Error>;
  };

  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <S.Message>
      {message && message?.type === MessageType.Question && (
        <InsightsQuestionAvatar question={message as Question} />
      )}
      {message && message?.type === MessageType.Answer && (
        <InsightsAnswerAvatar isFetchingSources={isFetchingSources} />
      )}
      {renderMessage()}
    </S.Message>
  );
};

export default InsightsMessage;
