import { styled } from "@linaria/react";
import ButtonNew from "components/atoms/ButtonNew";
import { motion } from "framer-motion/dist/framer-motion";
import { grey } from "styles/colors";

const ModalContent = styled.div`
  border-top: 1px solid ${grey.mid};
  padding-top: 1.5rem;
`;

const ModalActions = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const Button = styled(ButtonNew)`
  flex: 1;
`;

const ModalContentText = styled(motion.div)`
  font-weight: 700;
`;

const S = {
  ModalContent,
  ModalActions,
  Button,
  ModalContentText
};

export default S;
