import type { UserPermissions } from "api/user/types";

export const MFA_CODE_LENGTH = 6;

export enum AuthenticationActions {
  updateEmail = "updateEmail",
  updatePassword = "updatePassword",
  updateMfaCode = "updateMfaCode",
  authenticate = "authenticate",
  authenticated = "authenticated",
  verifyPassword = "verifyPassword",
  passwordVerified = "passwordVerified",
  unauthenticated = "unauthenticated",
  dismissError = "dismissError",
  updatePermissions = "updatePermissions",
  mfaSetup = "mfaSetup",
  resetState = "resetState"
}

export enum AuthenticationStatus {
  unknown = "unknown",
  unauthenticated = "unauthenticated",
  authenticating = "authenticating",
  authenticated = "authenticated",
  verifyingPassword = "verifyingPassword",
  passwordVerified = "passwordVerified",
  mfaSetup = "mfaSetup"
}

export interface AuthenticationState {
  email: string;
  password: string;
  mfaCode: string;
  status: AuthenticationStatus;
  session?: any;
  error?: string;
  permissions: UserPermissions;
  isPermissionsLoaded: boolean;
}

interface UpdateMfaCodeAction {
  type: AuthenticationActions.updateMfaCode;
  mfaCode: string;
}

interface VerifyPasswordAction {
  type: AuthenticationActions.verifyPassword;
}

interface PasswordVerifiedAction {
  type: AuthenticationActions.passwordVerified;
  session: any;
}

interface MfaSetupAction {
  type: AuthenticationActions.mfaSetup;
}
interface ResetState {
  type: AuthenticationActions.resetState;
}

interface UpdatePermissionsAction {
  type: AuthenticationActions.updatePermissions;
  permissions: UserPermissions;
}

interface UpdateEmailAction {
  type: AuthenticationActions.updateEmail;
  email: string;
}

interface UpdatePasswordAction {
  type: AuthenticationActions.updatePassword;
  password: string;
}

interface AuthenticateAction {
  type: AuthenticationActions.authenticate;
}

interface AuthenticatedAction {
  type: AuthenticationActions.authenticated;
  session?: any;
}

interface UnauthenticatedAction {
  type: AuthenticationActions.unauthenticated;
  error?: string;
}

interface DismissErrorAction {
  type: AuthenticationActions.dismissError;
}

export type AuthenticationAction =
  | UpdateEmailAction
  | UpdatePasswordAction
  | AuthenticateAction
  | AuthenticatedAction
  | UnauthenticatedAction
  | DismissErrorAction
  | UpdatePermissionsAction
  | UpdateMfaCodeAction
  | VerifyPasswordAction
  | PasswordVerifiedAction
  | ResetState
  | MfaSetupAction;
