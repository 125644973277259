import { v4 as uuidv4 } from "uuid";

import { devOptions } from "config";
import http from "services/http";
import endpoints from "services/endpoints";
import { mutateToLowerCamelCase } from "util/common";
import { ReportService } from "api/portal";

const delay = async durationMs =>
  new Promise(resolve => {
    setTimeout(resolve, durationMs);
  });

export default class Proposal {
  constructor(inProgress) {
    this.inProgress = inProgress;
  }

  async exportToPdf(
    enquiryId,
    state,
    report,
    queryString,
    shareToken,
    stateId
  ) {
    const requestId = uuidv4();
    const url = `${endpoints.getReportPDF}`;
    const start = Date.now();
    let response;
    const { subject } = report;
    do {
      try {
        // eslint-disable-next-line no-await-in-loop
        response = await http.post(url, {
          enquiryId,
          requestId,
          state,
          subject,
          queryString,
          originUrl: window.location.host.startsWith("localhost")
            ? devOptions.pdfExportOriginUrl
            : window.location.origin,
          shareToken,
          stateId
        });
      } catch (e) {
        if (e.status === 423) {
          // eslint-disable-next-line no-await-in-loop
          await delay(1000);
        } else {
          throw e;
        }
      }
    } while (!response && Date.now() - start < 240 * 1000);
    if (response) {
      return response;
    }
    throw new Error("Timed out waiting for report pdf export");
  }

  async get(enquiryId, personaId, shareToken, isForPDFExport, runId) {
    this.inProgress(true);
    let reportMeta = {};
    try {
      if (shareToken) {
        reportMeta = await ReportService.getReports1({
          enquiryId,
          personaId,
          token: shareToken,
          runId,
          isForPdfExport: isForPDFExport
        });
      } else {
        reportMeta = await ReportService.getReports({
          enquiryId,
          personaId,
          runId
        });
      }

      const documentResponse = await fetch(new Request(reportMeta.reportUrl));
      const reportDocument = await http.processResultRaw(documentResponse);
      const report = this.mutateLowerCamelCaseObjectRecursively(reportDocument);
      // TODO: backend are restructuring this, we'll need to handle the "imageUrl" field instead.
      this.inProgress(false);
      return [report, reportMeta];
    } catch (error) {
      this.inProgress(false);
      if (error.status === 204) {
        return null;
      }
      throw error;
    }
  }

  mutateLowerCamelCaseObjectRecursively(obj) {
    const mutated = mutateToLowerCamelCase(obj);
    return mutated;
  }

  list() {
    const url = `${endpoints.listReports}`;
    return new Promise((resolve, reject) => {
      this.inProgress(true);
      http.post(url).then(
        reports => {
          this.inProgress(false);
          resolve(reports);
        },
        error => {
          this.inProgress(false);
          if (error.status === 204) {
            resolve(null);
          } else {
            reject(error);
          }
        }
      );
    });
  }

  async regenerate(enquiryId, userAssessments) {
    const url = `${endpoints.regenerateReport}`;
    this.inProgress(true);
    try {
      await http.post(url, {
        EnquiryId: enquiryId,
        UserAssessments: userAssessments
      });
    } finally {
      this.inProgress(false);
    }
  }

  async saveReportChanges(enquiryId, changes) {
    try {
      return {
        result: await http.post(endpoints.setRiskAssertions, {
          EnquiryId: enquiryId,
          RiskAssertions: changes
        }),
        hasErrored: false,
        error: null
      };
    } catch (error) {
      console.error(error);
      return { hasErrored: true, error };
    }
  }
}
