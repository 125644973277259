/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Idam_Contracts_Enums_OrganisationRole } from "../models/Idam_Contracts_Enums_OrganisationRole";
import type { Idam_Contracts_Enums_UsagePeriod } from "../models/Idam_Contracts_Enums_UsagePeriod";
import type { Idam_Contracts_Organisations_ExportOrganisationUsageResponse } from "../models/Idam_Contracts_Organisations_ExportOrganisationUsageResponse";
import type { Idam_Contracts_Organisations_GetOrganisationUsageResponse } from "../models/Idam_Contracts_Organisations_GetOrganisationUsageResponse";
import type { Idam_Contracts_Organisations_UpdateOrganisationUserRequest } from "../models/Idam_Contracts_Organisations_UpdateOrganisationUserRequest";
import type { Idam_Contracts_Reports_SearchResponse } from "../models/Idam_Contracts_Reports_SearchResponse";
import type { Idam_Contracts_Tenants_AssumeRoleResponse } from "../models/Idam_Contracts_Tenants_AssumeRoleResponse";
import type { Idam_Contracts_Tenants_CreateOrganisationRequest } from "../models/Idam_Contracts_Tenants_CreateOrganisationRequest";
import type { Idam_Contracts_Tenants_CreateOrganisationResponse } from "../models/Idam_Contracts_Tenants_CreateOrganisationResponse";
import type { Idam_Contracts_Tenants_GetTenantOrganisationResponse } from "../models/Idam_Contracts_Tenants_GetTenantOrganisationResponse";
import type { Idam_Contracts_Tenants_OrganisationGroup } from "../models/Idam_Contracts_Tenants_OrganisationGroup";
import type { Idam_Contracts_Tenants_OrganisationUser } from "../models/Idam_Contracts_Tenants_OrganisationUser";
import type { Idam_Contracts_Tenants_TenantInviteUserRequest } from "../models/Idam_Contracts_Tenants_TenantInviteUserRequest";
import type { Idam_Contracts_Tenants_TenantOrganisationSummary } from "../models/Idam_Contracts_Tenants_TenantOrganisationSummary";
import type { Idam_Contracts_Tenants_UpdateOrganisationFeaturesRequest } from "../models/Idam_Contracts_Tenants_UpdateOrganisationFeaturesRequest";
import type { Idam_Contracts_Tenants_UpdateOrganisationRequest } from "../models/Idam_Contracts_Tenants_UpdateOrganisationRequest";
import type { Idam_Contracts_Tenants_UpdateTenantAdminRequest } from "../models/Idam_Contracts_Tenants_UpdateTenantAdminRequest";
import type { PortalMockAPI_Models_Identity_Tenant } from "../models/PortalMockAPI_Models_Identity_Tenant";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class IdentityTenantApiService {
  /**
   * @returns PortalMockAPI_Models_Identity_Tenant Success
   * @throws ApiError
   */
  public static getTenants(): CancelablePromise<PortalMockAPI_Models_Identity_Tenant> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tenants"
    });
  }

  /**
   * @returns Idam_Contracts_Tenants_TenantOrganisationSummary Success
   * @throws ApiError
   */
  public static getTenantsOrganisations(): CancelablePromise<
    Array<Idam_Contracts_Tenants_TenantOrganisationSummary>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tenants/organisations"
    });
  }

  /**
   * @returns Idam_Contracts_Tenants_CreateOrganisationResponse Success
   * @throws ApiError
   */
  public static postTenantsOrganisations({
    requestBody
  }: {
    requestBody?: Idam_Contracts_Tenants_CreateOrganisationRequest;
  }): CancelablePromise<Idam_Contracts_Tenants_CreateOrganisationResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/tenants/organisations",
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns Idam_Contracts_Tenants_GetTenantOrganisationResponse Success
   * @throws ApiError
   */
  public static getTenantsOrganisations1({
    organisationId
  }: {
    organisationId: string;
  }): CancelablePromise<Idam_Contracts_Tenants_GetTenantOrganisationResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tenants/organisations/{organisationId}",
      path: {
        organisationId: organisationId
      }
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static putTenantsOrganisations({
    organisationId,
    requestBody
  }: {
    organisationId: string;
    requestBody?: Idam_Contracts_Tenants_UpdateOrganisationRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/tenants/organisations/{organisationId}",
      path: {
        organisationId: organisationId
      },
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static deleteTenantsOrganisations({
    organisationId
  }: {
    organisationId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/tenants/organisations/{organisationId}",
      path: {
        organisationId: organisationId
      }
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static postTenantsOrganisationsUsersInvite({
    organisationId,
    requestBody
  }: {
    organisationId: string;
    requestBody?: Idam_Contracts_Tenants_TenantInviteUserRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/tenants/organisations/{organisationId}/users/invite",
      path: {
        organisationId: organisationId
      },
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static postTenantsOrganisationsDisable({
    organisationId
  }: {
    organisationId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/tenants/organisations/{organisationId}/disable",
      path: {
        organisationId: organisationId
      }
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static postTenantsOrganisationsEnable({
    organisationId
  }: {
    organisationId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/tenants/organisations/{organisationId}/enable",
      path: {
        organisationId: organisationId
      }
    });
  }

  /**
   * @returns Idam_Contracts_Tenants_AssumeRoleResponse Success
   * @throws ApiError
   */
  public static postTenantsOrganisationsAssumeRole({
    organisationId
  }: {
    organisationId: string;
  }): CancelablePromise<Idam_Contracts_Tenants_AssumeRoleResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/tenants/organisations/{organisationId}/assumeRole",
      path: {
        organisationId: organisationId
      }
    });
  }

  /**
   * @returns Idam_Contracts_Organisations_GetOrganisationUsageResponse Success
   * @throws ApiError
   */
  public static getTenantsOrganisationsUsage({
    organisationId
  }: {
    organisationId: string;
  }): CancelablePromise<Idam_Contracts_Organisations_GetOrganisationUsageResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tenants/organisations/{organisationId}/usage",
      path: {
        organisationId: organisationId
      }
    });
  }

  /**
   * @returns Idam_Contracts_Organisations_ExportOrganisationUsageResponse Success
   * @throws ApiError
   */
  public static getTenantsOrganisationsUsageExport({
    organisationId,
    usagePeriod
  }: {
    organisationId: string;
    usagePeriod?: Idam_Contracts_Enums_UsagePeriod;
  }): CancelablePromise<Idam_Contracts_Organisations_ExportOrganisationUsageResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tenants/organisations/{organisationId}/usage/export",
      path: {
        organisationId: organisationId
      },
      query: {
        usagePeriod: usagePeriod
      }
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static putTenantsOrganisationsFeatures({
    organisationId,
    requestBody
  }: {
    organisationId: string;
    requestBody?: Idam_Contracts_Tenants_UpdateOrganisationFeaturesRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/tenants/organisations/{organisationId}/features",
      path: {
        organisationId: organisationId
      },
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns Idam_Contracts_Reports_SearchResponse Success
   * @throws ApiError
   */
  public static getTenantsOrganisationsReports({
    organisationId,
    start,
    limit = 25
  }: {
    organisationId: string;
    start?: number;
    limit?: number;
  }): CancelablePromise<Idam_Contracts_Reports_SearchResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tenants/organisations/{organisationId}/reports",
      path: {
        organisationId: organisationId
      },
      query: {
        start: start,
        limit: limit
      }
    });
  }

  /**
   * @returns Idam_Contracts_Tenants_OrganisationGroup Success
   * @throws ApiError
   */
  public static getTenantsOrganisationsGroups({
    organisationId
  }: {
    organisationId: string;
  }): CancelablePromise<Array<Idam_Contracts_Tenants_OrganisationGroup>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tenants/organisations/{organisationId}/groups",
      path: {
        organisationId: organisationId
      }
    });
  }

  /**
   * @returns Idam_Contracts_Tenants_OrganisationUser Success
   * @throws ApiError
   */
  public static getTenantsOrganisationsUsers({
    organisationId
  }: {
    organisationId: string;
  }): CancelablePromise<Array<Idam_Contracts_Tenants_OrganisationUser>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tenants/organisations/{organisationId}/users",
      path: {
        organisationId: organisationId
      }
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static deleteTenantsUsers({
    userId,
    deleteReports = false,
    newReportOwnerId
  }: {
    userId: string;
    deleteReports?: boolean;
    newReportOwnerId?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/tenants/users/{userId}",
      path: {
        userId: userId
      },
      query: {
        deleteReports: deleteReports,
        newReportOwnerId: newReportOwnerId
      }
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static putTenantsUsers({
    userId,
    requestBody
  }: {
    userId: string;
    requestBody?: Idam_Contracts_Organisations_UpdateOrganisationUserRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/tenants/users/{userId}",
      path: {
        userId: userId
      },
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static putTenantsUsersRole({
    userId,
    role
  }: {
    userId: string;
    role: Idam_Contracts_Enums_OrganisationRole;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/tenants/users/{userId}/role/{role}",
      path: {
        userId: userId,
        role: role
      }
    });
  }

  /**
   * @returns Idam_Contracts_Reports_SearchResponse Success
   * @throws ApiError
   */
  public static getTenantsUsersReports({
    userId,
    start,
    limit = 25
  }: {
    userId: string;
    start?: number;
    limit?: number;
  }): CancelablePromise<Idam_Contracts_Reports_SearchResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tenants/users/{userId}/reports",
      path: {
        userId: userId
      },
      query: {
        start: start,
        limit: limit
      }
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static putTenantsAdmins({
    requestBody
  }: {
    requestBody?: Idam_Contracts_Tenants_UpdateTenantAdminRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/tenants/admins",
      body: requestBody,
      mediaType: "application/json"
    });
  }
}
