/* eslint-disable @typescript-eslint/no-unused-vars */
import { FetchResult } from "api/types";
import { ApiError } from "api/old-insights";
import {
  ReportsService,
  Report as RawReport,
  Subsection as RawSubsection,
  ShareTokenService
} from "api/insights";
import { getErrorMessage } from "util/error-utils";
import { QuestionAndAnswer, Subsection } from "./types";

export enum GetReportErrorCodes {
  IN_PROGRESS_ERROR = "INSIGHTS_REPORT_IN_PROGRESS",
  NOT_FOUND_ERROR = "INSIGHTS_REPORT_NOT_FOUND",
  FAILED_GENERATING_ERROR = "INSIGHTS_REPORT_FAILED",
  NO_WAM_ERROR = "INSIGHTS_REPORT_NO_WAM",
  GENERIC_ERROR = "INTERNAL_SERVER_ERROR"
}

export type {
  H1ContentNode,
  H2ContentNode,
  H3ContentNode,
  H4ContentNode,
  PContentNode,
  DivContentNode,
  TextContentNode,
  ContentNode,
  InsightReportSection,
  InsightReport
} from "./types";

type ErrorResponseWithBody = {
  body: {
    error: string;
  };
};

const hasErrorCode = (response: unknown): response is ErrorResponseWithBody =>
  response !== null &&
  typeof response === "object" &&
  "body" in response &&
  response.body !== null &&
  typeof response.body === "object" &&
  "error" in response.body &&
  typeof response.body.error === "string";

export default class InsightReports {
  async getReport({
    id: reportId,
    personaId: personaId,
    runId: runId
  }: {
    id: string;
    personaId: string;
    runId: string | null;
  }): Promise<FetchResult<RawReport>> {
    try {
      const response = await ReportsService.getReports({ reportId, personaId, runId });

      return { status: true, response };
    } catch (e: unknown) {
      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }
      return { status: false, message: GetReportErrorCodes.GENERIC_ERROR };
    }
  }

  async getReportWithShareToken({
    id: reportId,
    shareToken
  }: {
    id: string;
    shareToken: string;
  }): Promise<FetchResult<RawReport>> {
    try {
      const response = await ShareTokenService.getReports({
        reportId,
        shareToken
      });

      return { status: true, response };
    } catch (e: unknown) {
      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }
      return { status: false, message: GetReportErrorCodes.GENERIC_ERROR };
    }
  }

  async getSources({
    reportId,
    personaId,
    sectionId,
    subsectionId,
    subjectName,
    shareToken
  }: {
    reportId: string;
    personaId: string;
    sectionId: string;
    subsectionId: string;
    subjectName: string;
    shareToken: string | null;
  }): Promise<FetchResult<RawSubsection>> {
    try {
      const requestBody = {
        subject_name: subjectName
      };
      const response = shareToken
        ? await ShareTokenService.postReportsSectionsSubsectionsSource({
            reportId,
            sectionId,
            subsectionId,
            requestBody,
            shareToken
          })
        : await ReportsService.postReportsSectionsSubsectionsSource({
            reportId,
            personaId,
            sectionId,
            subsectionId,
            requestBody
          });

      return { status: true, response };
    } catch (e) {
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async getSourcesWithShareToken({
    reportId,
    sectionId,
    subsectionId,
    subjectName,
    shareToken
  }: {
    reportId: string;
    sectionId: string;
    subsectionId: string;
    subjectName: string;
    shareToken: string;
  }): Promise<FetchResult<RawSubsection>> {
    try {
      const requestBody = {
        subject_name: subjectName
      };
      const response =
        await ShareTokenService.postReportsSectionsSubsectionsSource({
          reportId,
          sectionId,
          subsectionId,
          requestBody,
          shareToken
        });

      return { status: true, response };
    } catch (e) {
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async getQuestionsAndAnswers({
    enquiryId
  }: {
    enquiryId: string;
  }): Promise<FetchResult<QuestionAndAnswer[]>> {
    try {
      console.warn(`Get questionsAndAnswers Not implemented: ${enquiryId}`);

      return { status: true, response: [] };
    } catch (e) {
      if (e instanceof ApiError && e.status === 404) {
        return {
          status: true,
          response: []
        };
      }

      return { status: false, message: getErrorMessage(e) };
    }
  }

  async askSourcedQuestion({
    enquiryId,
    personaId,
    question,
    subjectName,
    shareToken
  }: {
    enquiryId: string;
    personaId: string;
    question: string;
    subjectName: string;
    shareToken: string | null;
  }): Promise<FetchResult<Subsection>> {
    try {
      const response = shareToken
        ? await ShareTokenService.postReportsQna({
            reportId: enquiryId,
            requestBody: { subject_name: subjectName, question },
            shareToken
          })
        : await ReportsService.postReportsQna({
            reportId: enquiryId,
            personaId: personaId,
            requestBody: { subject_name: subjectName, question }
          });

      const { id, title, elements, is_sourced: isSourced } = response;

      return {
        status: true,
        response: {
          id,
          title,
          isSourced,
          elements: elements.map(element => ({
            id: element.id,
            title: element.title,
            text: element.text,
            citedFragments: element.cited_fragments.map(fragment => ({
              text: fragment.text,
              supportingSourceSentences:
                fragment.supporting_source_sentences.map(sentence => ({
                  title: sentence.title,
                  text: sentence.text,
                  date: sentence.date,
                  wamSourceIds: sentence.wam_source_ids
                }))
            }))
          }))
        }
      };
    } catch (e) {
      console.error(e);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  // TODO: doesn't exist yet
  async getSuggestedQuestions(): Promise<FetchResult<string[]>> {
    try {
      return {
        status: true,
        response: [
          "Tell me about XXXX’s views on climate change",
          "Has XXXX ever been involved in racketeering?",
          "What does XXXX value most?"
        ]
      };
    } catch (e) {
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async addSourcesToElement(
    _sectionId: string,
    _enquiryId: string,
    _subjectName: string
  ): Promise<FetchResult<Subsection>> {
    try {
      // TODO: regen types - the return type will change...
      // const result =
      //   await DefaultService.addSourcingToAnswerEnquiryEnquiryIdAnswerAnswerIdPost(
      //     {
      //       answerId: sectionId,
      //       enquiryId,
      //       subjectName
      //     }
      //   );

      return {
        status: true,
        response: {
          id: "",
          title: "",
          elements: []
        }
      };
    } catch (e) {
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async provideFeedback({
    id,
    personaId,
    subjectName,
    feedbackText,
    section,
    subsection
  }: {
    id: string;
    personaId: string;
    subjectName: string;
    feedbackText: string;
    section?: string;
    subsection?: string;
  }): Promise<FetchResult> {
    try {
      const requestBody = {
        subject_name: subjectName,
        message: feedbackText,
        section,
        subsection
      };
      await ReportsService.postReportsFeedback({ reportId: id, personaId: personaId, requestBody });

      return { status: true };
    } catch (e) {
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async removeSection({
    reportId,
    personaId,
    sectionId
  }: {
    reportId: string;
    personaId: string;
    sectionId: string;
  }): Promise<FetchResult> {
    try {
      await ReportsService.deleteReportsSections({
        reportId,
        personaId,
        sectionId
      });

      return { status: true };
    } catch (e) {
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async removeSubSection({
    reportId,
    personaId,
    sectionId,
    subsectionId
  }: {
    reportId: string;
    personaId: string;
    sectionId: string;
    subsectionId: string;
  }): Promise<FetchResult> {
    try {
      await ReportsService.deleteReportsSectionsSubsections({
        reportId,
        personaId,
        sectionId,
        subsectionId
      });

      return { status: true };
    } catch (e) {
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async removeElement({
    reportId,
    personaId,
    sectionId,
    subsectionId,
    elementId
  }: {
    reportId: string;
    personaId: string;
    sectionId: string;
    subsectionId: string;
    elementId: string;
  }): Promise<FetchResult> {
    try {
      await ReportsService.deleteReportsSectionsSubsectionsElements({
        reportId,
        personaId,
        sectionId,
        subsectionId,
        elementId
      });
      return { status: true };
    } catch (e) {
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async removeReport({ reportId, personaId }: { reportId: string;
    personaId: string;
  }): Promise<FetchResult> {
    try {
      await ReportsService.deleteReports({ reportId, personaId });

      return { status: true };
    } catch (e) {
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async regenerateReport({
    reportId,
    personaId
  }: {
    reportId: string;
    personaId: string;
  }): Promise<FetchResult> {
    try {
      await ReportsService.postReportsRegenerate({ reportId, personaId });

      return { status: true };
    } catch (e) {
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }
}
