// To parse this data:
//
//   import { Convert, ReportTypes } from "./file";
//
//   const reportTypes = Convert.toReportTypes(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface ReportTypes {
    activeFeatures:             string[] | null;
    awards?:                    null | AwardsSectionInformation;
    businessAssociates:         BusinessAssociateInfo[] | null;
    contexts:                   ContextItem[] | null;
    dowPersonScreeningSection?: null | DowPersonScreeningInformation;
    education?:                 null | EducationSectionInformation;
    enquiryId:                  null | string;
    enquiryTenant:              EnquiryTenant;
    family?:                    null | FamilySectionInformation;
    fullName:                   null | string;
    generationVersion:          null | DiVersion;
    insightCentre?:             null | InsightCentreSectionInformation;
    location?:                  null | LocationSectionInformation;
    preparationTime:            null | ReportPreparationTime;
    preparedAtUtc:              Date | null;
    professionalOverview?:      null | ProfessionalOverviewInformation;
    reportAbstract?:            null | AbstractSectionInformation;
    reportEnvironment:          null | string;
    reportFailed:               boolean | null;
    reportIssues:               ReportIssue[] | null;
    reportMetadata:             null | ReportMetadata;
    reportSourcing:             null | ReportSourcing;
    screeningSection?:          null | PersonScreeningInformation;
    searchErrorInfo:            SearchErrorInfo[] | null;
    snapshotSection?:           null | SnapshotInformation;
    subject:                    null | string;
    subjectType:                Type;
    systemIssues:               SystemIssue[] | null;
    userEmail:                  null | string;
    [property: string]: any;
}

export interface AwardsSectionInformation {
    awardsCount:             number | null;
    listOfAwardsInformation: AwardInformation[] | null;
    sectionError?:           null | string;
    [property: string]: any;
}

export interface AwardInformation {
    awardDate?:       null | PartialDate;
    awardDescription: null | string;
    awardImage:       null | ReportImage1;
    awardName:        null | string;
    awardNameLong?:   null | string;
    awardType?:       null | string;
    sourceUrls:       string[] | null;
    [property: string]: any;
}

export interface PartialDate {
    day?:        number | null;
    month?:      number | null;
    monthShort?: null | string;
    year:        number | null;
    [property: string]: any;
}

export interface ReportImage1 {
    imageKey:   string;
    s3Key:      string;
    smallImage: boolean;
    src:        string;
    [property: string]: any;
}

export interface BusinessAssociateInfo {
    age?:                  null | Age;
    connectionList:        AssociateConnection[] | null;
    contextToUse:          null | string;
    dateOfBirth?:          null | PartialDate;
    image?:                null | FrontendImage;
    linkedInUrl?:          null | string;
    name:                  null | string;
    nameToSendToNewReport: null | string;
    onlineProfiles:        OnlineProfile[] | null;
    [property: string]: any;
}

export interface Age {
    months?: number | null;
    years:   number | null;
    [property: string]: any;
}

export interface AssociateConnection {
    text: null | string;
    url?: null | string;
    [property: string]: any;
}

export interface FrontendImage {
    altText?:                 null | string;
    containingDocumentTitle?: null | string;
    date?:                    null | DateWithUncertaintyWarning;
    height?:                  number | null;
    id:                       null | string;
    imageKey?:                null | string;
    originUrl?:               null | string;
    src?:                     null | string;
    thirdPartyContent:        boolean | null;
    width?:                   number | null;
    [property: string]: any;
}

export interface DateWithUncertaintyWarning {
    date:                      Date | null;
    uncertaintyThresholdDays?: number | null;
    [property: string]: any;
}

export interface OnlineProfile {
    bestAvailability:                   BestAvailability;
    investigationDetailLevelPerformed?: InvestigationDetailLevelPerformed;
    isUncertain:                        boolean | null;
    label:                              null | string;
    languageCode?:                      null | string;
    link:                               null | string;
    numberOfFollowers?:                 number | null;
    profileType:                        Unit | OneOfSocialMediaProfileTypesOnlineProfileTypesUnitEnum;
    sourceIds:                          string[] | null;
    sources:                            Array<null | InformationSource> | null;
    totalSourceCount:                   number | null;
    [property: string]: any;
}

export enum BestAvailability {
    Available = "Available",
    UnavailableWithLogin = "UnavailableWithLogin",
    UnavailableWithoutLogin = "UnavailableWithoutLogin",
    Unknown = "Unknown",
}

export enum InvestigationDetailLevelPerformed {
    None = "None",
    Some = "Some",
    Top = "Top",
}

export interface Unit {
}

export enum OneOfSocialMediaProfileTypesOnlineProfileTypesUnitEnum {
    AngelList = "AngelList",
    Crunchbase = "Crunchbase",
    Facebook = "Facebook",
    Flickr = "Flickr",
    Instagram = "Instagram",
    LinkedIn = "LinkedIn",
    Pinterest = "Pinterest",
    Reddit = "Reddit",
    Soundcloud = "Soundcloud",
    Twitter = "Twitter",
    Undefined = "Undefined",
    Vk = "VK",
    Wikidata = "Wikidata",
    Wikipedia = "Wikipedia",
    Youtube = "Youtube",
}

export interface InformationSource {
    dateAccessed?:     null | PartialDate;
    datePublished?:    null | PartialDate;
    description?:      null | string;
    id:                null | string;
    image?:            null | FrontendImage;
    isAvailableOnline: boolean | null;
    publisher?:        null | string;
    sourceType:        SourceType;
    title?:            null | string;
    url:               null | string;
    [property: string]: any;
}

export enum SourceType {
    Article = "Article",
    CommercialAggregator = "CommercialAggregator",
    CorporateRecord = "CorporateRecord",
    OnlineProfile = "OnlineProfile",
    ScreeningProvider = "ScreeningProvider",
    Unknown = "Unknown",
    Webpage = "Webpage",
}

export interface ContextItem {
    item: string;
    type: ContextType;
    [property: string]: any;
}

export enum ContextType {
    Freetext = "Freetext",
    Organisation = "Organisation",
    Person = "Person",
    SocialProfile = "SocialProfile",
    WebAddress = "WebAddress",
}

export interface DowPersonScreeningInformation {
    associates:  { [key: string]: DowJonesPersonResult };
    confirmed:   DowJonesPersonResult[];
    discarded:   DowJonesPersonResult[];
    unconfirmed: DowJonesPersonResult[];
    [property: string]: any;
}

export interface DowJonesPersonResult {
    addresses:                      BasicLocation[] | null;
    alternativeNames:               null | AlternativeNameDetails;
    associatedEntities:             DowAssociatedEntity[] | null;
    associatedOrgs:                 DowAssociatedEntity[] | null;
    associatedPeople:               DowAssociatedEntity[] | null;
    birthDate?:                     null | PartialDate;
    citizenship:                    EntityCountry[] | null;
    entityName:                     null | string;
    flags:                          Flag[] | null;
    gender?:                        null | string;
    images:                         string[] | null;
    isDeceased?:                    boolean | null;
    isRCA:                          boolean | null;
    jurisdiction:                   EntityCountry[] | null;
    lastReviewedDate?:              null | PartialDate;
    listReferences:                 DowJonesRiskGroup[] | null;
    nonWatchlistIdentifiers:        DowIdentifier[] | null;
    pepAssociatesCount:             number | null;
    pepRole?:                       null | PepRoleFrontEnd;
    previousNames:                  null | AlternativeNameDetails;
    profileId:                      null | string;
    profileNotes?:                  null | string;
    reportedAllegation:             EntityCountry[] | null;
    residence:                      EntityCountry[] | null;
    sanctionedAssociatesCount:      number | null;
    sources:                        string[] | null;
    specialInterestAssociatesCount: number | null;
    userAssessment:                 Assessment;
    userAssessmentId:               null | string;
    watchlistAssociatesCount:       number | null;
    watchlistIdentifiers:           DowIdentifier[] | null;
    [property: string]: any;
}

export interface BasicLocation {
    address:          string[] | null;
    countryCode?:     null | string;
    countryName?:     null | string;
    locationType:     LocationType;
    shortAddress?:    string[] | null;
    streetViewImage?: null | FrontendImage;
    [property: string]: any;
}

export enum LocationType {
    RegisteredAddress = "RegisteredAddress",
    Unknown = "Unknown",
}

export interface AlternativeNameDetails {
    allNames:             AlternativeName[];
    namesMatchingSubject: string[];
    [property: string]: any;
}

export interface AlternativeName {
    alternativeNameLanguages: AlternativeNameLanguage[];
    primaryRepresentation:    string;
    [property: string]: any;
}

export interface AlternativeNameLanguage {
    alias:    string;
    language: string;
    [property: string]: any;
}

export interface DowAssociatedEntity {
    entityLocation?: null | EntityCountry1;
    entityName:      null | string;
    entityType:      Type;
    flags:           FlagEnum[] | null;
    isCurrent:       boolean | null;
    profileId:       null | string;
    relationship:    null | string;
    [property: string]: any;
}

export interface EntityCountry1 {
    countryCode: string;
    countryName: string;
    [property: string]: any;
}

export enum Type {
    Organisation = "Organisation",
    Person = "Person",
}

export enum FlagEnum {
    AdverseMedia = "AdverseMedia",
    CloseAssociateOfRiskyEntity = "CloseAssociateOfRiskyEntity",
    LocationRisk = "LocationRisk",
    MarijuanaBusiness = "MarijuanaBusiness",
    MoneyServicesBusiness = "MoneyServicesBusiness",
    Pep = "Pep",
    RelatedToSanctionedParty = "RelatedToSanctionedParty",
    Sanction = "Sanction",
    SpecialInterest = "SpecialInterest",
    StateOwned = "StateOwned",
    Watchlist = "Watchlist",
}

export interface EntityCountry {
    countryCode: string;
    countryName: string;
    [property: string]: any;
}

export interface Flag {
    categoryHierarchy:  CategoryHierarchy[];
    control:            ValueWithLabel[];
    isActive:           boolean;
    locations:          Location[];
    ownerhipPercentage: OwnerhipPercentage[];
    type:               FlagType;
    [property: string]: any;
}

export interface CategoryHierarchy {
    description:     string;
    level:           number;
    subContentTypes: SubContentType[];
    [property: string]: any;
}

export interface SubContentType {
    categoryId:      number;
    description:     string;
    level:           number;
    subContentTypes: SubContentType[];
    [property: string]: any;
}

export interface ValueWithLabel {
    label: string;
    value: string;
    [property: string]: any;
}

export interface Location {
    countryCode:  string;
    countryName:  string;
    relationship: string;
    [property: string]: any;
}

export interface OwnerhipPercentage {
    label: string;
    notes: string;
    value: string;
    [property: string]: any;
}

export enum FlagType {
    Sanctioned = "Sanctioned",
    StateOwnedCompany = "State Owned Company",
}

export interface DowJonesRiskGroup {
    entries:   DowJonesRiskList[];
    listGroup: ListGroup;
    [property: string]: any;
}

export interface DowJonesRiskList {
    endDate?:           null | PartialDate;
    isKnownToBeOngoing: boolean | null;
    listName:           null | string;
    sourceUrl?:         null | string;
    startDate?:         null | PartialDate;
    [property: string]: any;
}

export enum ListGroup {
    None = "None",
    Pep = "Pep",
    Sanction = "Sanction",
    SpecialInterest = "SpecialInterest",
    WatchList = "WatchList",
}

export interface DowIdentifier {
    countryOfIssue?: null | EntityCountry1;
    dateOfIssue?:    null | PartialDate;
    label:           null | string;
    notes?:          null | string;
    value:           null | string;
    [property: string]: any;
}

export interface PepRoleFrontEnd {
    endDate?:   null | PartialDate;
    isOngoing?: boolean | null;
    roleTitle?: null | string;
    startDate?: null | PartialDate;
    [property: string]: any;
}

export enum Assessment {
    NoUserAssessment = "NoUserAssessment",
    RightPerson = "RightPerson",
    WrongPerson = "WrongPerson",
}

export interface EducationSectionInformation {
    educationCount:             number | null;
    listOfEducationInformation: EducationInformation[] | null;
    sectionError?:              null | string;
    [property: string]: any;
}

export interface EducationInformation {
    degreeName?:                 null | string;
    durationOfEngagement?:       null | YearsAndMonths;
    endDate?:                    null | PartialDate;
    establishmentDescription?:   null | string;
    establishmentGlobalRanking?: null | string;
    establishmentLocalRanking?:  null | string;
    establishmentLocation?:      null | string;
    establishmentLogo?:          null | ReportImage;
    establishmentName:           null | string;
    establishmentSize?:          null | string;
    sourceUrls:                  string[] | null;
    startDate?:                  null | PartialDate;
    thirdPartyContent:           boolean | null;
    [property: string]: any;
}

export interface YearsAndMonths {
    months?: number | null;
    years:   number | null;
    [property: string]: any;
}

export interface ReportImage {
    imageKey:   string;
    s3Key:      string;
    smallImage: boolean;
    src:        string;
    [property: string]: any;
}

export enum EnquiryTenant {
    ProjectDX = "ProjectDX",
    Xapien = "Xapien",
}

export interface FamilySectionInformation {
    listOfFamilyInformation: FamilyInformation[] | null;
    sectionError?:           null | string;
    [property: string]: any;
}

export interface FamilyInformation {
    age?:         null | string;
    dateOfBirth?: null | PartialDate;
    image?:       null | FrontendImage;
    name:         null | string;
    relation?:    null | string;
    [property: string]: any;
}

export interface DiVersion {
    display: string;
    major:   number;
    minor:   number;
    patch:   number;
    [property: string]: any;
}

export interface InsightCentreSectionInformation {
    acquaintanceRoles: AcquaintanceRole[] | null;
    affiliations:      Affiliation[] | null;
    akas:              Aka[] | null;
    assets:            PersonalAsset[] | null;
    bornPatterns:      BirthDeathPattern[] | null;
    bubbleChart?:      null | DynamicBubbleChartTabsContent;
    deceasedPattern:   null | DeceasedPattern;
    deceasedPatterns:  BirthDeathPattern[] | null;
    describedAs:       Descriptor[] | null;
    familyRoles:       FamilyRole[] | null;
    jobRoles:          ExperimentalOrgAndRoles[] | null;
    jobRolesNoOrgs:    ExperimentalJobRole[] | null;
    nationalities:     NationalityResidency[] | null;
    quotes:            Quote[] | null;
    residencies:       NationalityResidency[] | null;
    riskSummaries:     RiskClusterSummary[] | null;
    sanctions:         SanctionsBySanctioningBody[] | null;
    sectionError?:     null | string;
    wealth:            null | Wealth;
    [property: string]: any;
}

export interface AcquaintanceRole {
    person:    string;
    roles:     InnerRole[];
    source:    Array<null | InformationSource>;
    sourceIds: string[];
    sources:   Array<null | InformationSource>;
    [property: string]: any;
}

export interface InnerRole {
    modifiers: Modifier[];
    role:      string;
    sources:   Array<null | InformationSource>;
    [property: string]: any;
}

export interface Modifier {
    modifierText: string;
    source:       Array<null | InformationSource>;
    sourceIds:    string[];
    sources:      Array<null | InformationSource>;
    [property: string]: any;
}

export interface Affiliation {
    affiliationType: null | string;
    date?:           null | PartialDate;
    orgName:         null | string;
    sourceIds:       string[] | null;
    sources:         Array<null | InformationSource> | null;
    value?:          null | string;
    [property: string]: any;
}

export interface Aka {
    name:      string;
    source:    Array<null | InformationSource>;
    sourceIds: string[];
    sources:   Array<null | InformationSource>;
    type:      Modifier[];
    [property: string]: any;
}

export interface PersonalAsset {
    assetName:          null | string;
    dateDurations:      string[] | null;
    firstPurchaseDate?: null | PartialDate;
    lastPurchaseDate?:  null | PartialDate;
    purchasedFor?:      null | string;
    soldDate?:          null | PartialDate;
    soldFor?:           number | null;
    sourceIds:          string[] | null;
    sources:            Array<null | InformationSource> | null;
    valuedAt?:          number | null;
    [property: string]: any;
}

export interface BirthDeathPattern {
    dates:     DateSourcesAndModifiers[];
    phrase:    string;
    sourceIds: string[];
    sources:   Array<null | InformationSource>;
    [property: string]: any;
}

export interface DateSourcesAndModifiers {
    date?:     null | PartialDate;
    modifiers: Modifier[] | null;
    sourceIds: string[] | null;
    sources:   Array<null | InformationSource> | null;
    [property: string]: any;
}

export interface DynamicBubbleChartTabsContent {
    dynamicBubbleChartTabs: AllEntities;
    heading:                string;
    size:                   number;
    smallHeading:           string;
    [property: string]: any;
}

export interface AllEntities {
    locationsData:     Data[];
    organisationsData: Data[];
    personsData:       Data[];
    [property: string]: any;
}

export interface Data {
    cat:         string;
    description: string;
    icon:        string;
    name:        string;
    value:       number;
    [property: string]: any;
}

export interface DeceasedPattern {
    deceasedFlag: boolean;
    sources:      Array<null | InformationSource>;
    [property: string]: any;
}

export interface Descriptor {
    description: string;
    sourceIds:   string[];
    sources:     Array<null | InformationSource>;
    [property: string]: any;
}

export interface FamilyRole {
    modifiers: Modifier[];
    person:    string;
    role:      string;
    source:    Array<null | InformationSource>;
    sourceIds: string[];
    sources:   Array<null | InformationSource>;
    [property: string]: any;
}

export interface ExperimentalOrgAndRoles {
    org:   string;
    roles: ExperimentalJobRole[];
    [property: string]: any;
}

export interface ExperimentalJobRole {
    dateDurations: string[] | null;
    firstDate?:    null | PartialDate;
    lastDate?:     null | PartialDate;
    modifiers:     Modifier[] | null;
    org:           null | string;
    role:          null | string;
    sourceIds:     string[] | null;
    sources:       Array<null | InformationSource> | null;
    [property: string]: any;
}

export interface NationalityResidency {
    presentableText: string;
    sourceIds:       string[];
    sources:         Array<null | InformationSource>;
    [property: string]: any;
}

export interface Quote {
    date?:     null | PartialDate;
    quoteText: null | string;
    sourceIds: string[] | null;
    sources:   Array<null | InformationSource> | null;
    [property: string]: any;
}

export interface RiskClusterSummary {
    endDate?:               null | PartialDate;
    granularRiskCategories: string[] | null;
    riskCategories:         { [key: string]: { [key: string]: number } } | null;
    riskSources:            RiskSource[] | null;
    startDate?:             null | PartialDate;
    summary:                null | string;
    title:                  null | string;
    [property: string]: any;
}

export interface RiskSource {
    riskCategories: RiskCategory[];
    source:         null | InformationSource;
    [property: string]: any;
}

export interface RiskCategory {
    category: string;
    snippets: RiskSnippet[];
    [property: string]: any;
}

export interface RiskSnippet {
    highlightOffsets: RiskHighlight[];
    snippet:          string;
    [property: string]: any;
}

export interface RiskHighlight {
    highlightedText?: null | string;
    startIndex:       number | null;
    textLength:       number | null;
    [property: string]: any;
}

export interface SanctionsBySanctioningBody {
    sanctioningBodyText: string;
    sanctions:           Sanction[];
    [property: string]: any;
}

export interface Sanction {
    sentence?:   null | string;
    sourceDate?: null | PartialDate;
    sourceIds:   string[] | null;
    sources:     Array<null | InformationSource> | null;
    [property: string]: any;
}

export interface Wealth {
    wealthDescriptors: Descriptor[];
    wealthValues:      WealthValue[];
    [property: string]: any;
}

export interface WealthValue {
    source:    Array<null | InformationSource> | null;
    sourceIds: string[] | null;
    sources:   Array<null | InformationSource> | null;
    value:     null | string;
    year?:     number | null;
    [property: string]: any;
}

export interface LocationSectionInformation {
    listOfLocationInformation: LocationInformation[] | null;
    locationCount:             number | null;
    sectionError?:             null | string;
    [property: string]: any;
}

export interface LocationInformation {
    addressFullDisplay:         string[] | null;
    associatedOrganisations:    ValueTupleStringGUID[] | null;
    correspondenceAddressRoles: CorrespondenceAddressForRole[] | null;
    countryRiskFlags:           LocationRiskFlag[] | null;
    earliestEndDate?:           null | PartialDate;
    earliestStartDate?:         null | PartialDate;
    filterInfo:                 null | FilterInfo;
    isResolvedToSpecificLatLon: boolean | null;
    latitude?:                  number | null;
    locationMapAreaImage?:      null | FrontendImage;
    locationMapImage?:          null | FrontendImage;
    locationStreetViewImage?:   null | FrontendImage;
    longitude?:                 number | null;
    mostRecentEndDate?:         null | PartialDate;
    mostRecentStartDate?:       null | PartialDate;
    originalAddresses:          OriginalAddress[] | null;
    registeredAddressCompanies: CompanyRegisteredAddresses[] | null;
    [property: string]: any;
}

export interface ValueTupleStringGUID {
    item1: string;
    item2: string;
}

export interface CorrespondenceAddressForRole {
    end?:                   null | PartialDate;
    isOngoing?:             boolean | null;
    links:                  string[] | null;
    organisationIdentifier: null | string;
    organisationName:       null | string;
    start?:                 null | PartialDate;
    [property: string]: any;
}

export interface LocationRiskFlag {
    assigner: Assigner;
    [property: string]: any;
}

export enum Assigner {
    Eu = "EU",
    HMTreasury = "HMTreasury",
    TransparencyInternational = "TransparencyInternational",
}

export interface FilterInfo {
    city?:        null | string;
    countryCode?: null | string;
    countryName?: null | string;
    [property: string]: any;
}

export interface OriginalAddress {
    address:         string[];
    linkedCompanies: LinkedCompany[];
    [property: string]: any;
}

export interface LinkedCompany {
    companyIdentifier: string;
    relationTypes:     RelationType[];
    [property: string]: any;
}

export enum RelationType {
    Correspondence = "Correspondence",
    Registered = "Registered",
}

export interface CompanyRegisteredAddresses {
    end?:                   null | PartialDate;
    isOngoing?:             boolean | null;
    links:                  string[] | null;
    organisationIdentifier: null | string;
    organisationName:       null | string;
    start?:                 null | PartialDate;
    [property: string]: any;
}

export interface ReportPreparationTime {
    minutes: number;
    seconds: number;
    [property: string]: any;
}

export interface ProfessionalOverviewInformation {
    discardedListOfProfessionalInformation:   ProfessionalInformation[] | null;
    listOfProfessionalInformation:            ProfessionalInformation[] | null;
    professionalInformationCount:             number | null;
    sectionError?:                            null | string;
    unconfirmedListOfProfessionalInformation: ProfessionalInformation[] | null;
    [property: string]: any;
}

export interface ProfessionalInformation {
    addressFullDisplay:           string[] | null;
    addressShortDisplay?:         null | string;
    confidence:                   null | Confidence;
    countryInfo?:                 null | CountryInfo;
    durationContinuous?:          boolean | null;
    earliestStartDate?:           null | PartialDate;
    id:                           null | string;
    industryCategories:           string[] | null;
    insolvencyFilings:            CompanyFiling[] | null;
    isTenureOngoing:              boolean | null;
    latestEndDate?:               null | PartialDate;
    listOfOrgWarnings:            OrgWarningDetails[] | null;
    listOfRoles:                  RoleInfo[] | null;
    orgDescription?:              null | OrgDescription;
    orgLogo?:                     null | FrontendImage;
    orgName:                      null | string;
    orgStatus?:                   null | OrgStatusDetails;
    orgUrls:                      Array<null | URLWithLabel> | null;
    overallDurationOfEngagement?: null | YearsAndMonths;
    previousOrgNames:             PreviousOrgName[] | null;
    roleHolderDoB?:               null | PartialDate;
    roleHolderName:               null | string;
    roleHolderNationalities:      string[] | null;
    roleSources:                  Array<null | InformationSource> | null;
    userAssessment:               Assessment;
    userAssessmentIds:            string[] | null;
    [property: string]: any;
}

export interface Confidence {
    exclusionDistance: number;
    inclusionDistance: number;
    [property: string]: any;
}

export interface CountryInfo {
    countryCode?: null | string;
    countryName:  null | string;
    riskFlags:    LocationRiskFlag[] | null;
    sourceIds:    string[] | null;
    sources:      Array<null | InformationSource> | null;
    [property: string]: any;
}

export interface CompanyFiling {
    date?:       Date | null;
    description: null | string;
    link:        null | string;
    [property: string]: any;
}

export interface OrgWarningDetails {
    label: string;
    [property: string]: any;
}

export interface RoleInfo {
    endDate?:          null | PartialDate;
    isOfficership:     boolean | null;
    isOngoing?:        boolean | null;
    roleDuration?:     null | YearsAndMonths;
    roleTitle?:        null | string;
    roleTitleVariant?: null | string;
    startDate?:        null | PartialDate;
    [property: string]: any;
}

export interface OrgDescription {
    readMoreLink?: null | string;
    text:          null | string;
    [property: string]: any;
}

export interface OrgStatusDetails {
    label:     string;
    statusTag: StatusTag;
    [property: string]: any;
}

export enum StatusTag {
    Active = "Active",
    Inactive = "Inactive",
    Unknown = "Unknown",
    Warning = "Warning",
}

export interface URLWithLabel {
    label?: null | string;
    url:    null | string;
    [property: string]: any;
}

export interface PreviousOrgName {
    endDate?:   null | PartialDate;
    name:       null | string;
    startDate?: null | PartialDate;
    [property: string]: any;
}

export interface AbstractSectionInformation {
    age?:                          null | string;
    awards:                        TextWithSources[] | null;
    biography?:                    null | Biography;
    confirmedOfficershipIds:       string[] | null;
    dateOfBirth?:                  null | PartialDateWithSources;
    descriptorSentencesSourced:    DescriptorSentenceSourced[] | null;
    emailAddresses:                UncertainTextWithSources[] | null;
    firstName:                     TextWithSources[] | null;
    fullName:                      null | string;
    gender?:                       null | TextWithSources1;
    imageCount:                    number | null;
    images:                        ImageGalleryImage[] | null;
    imagesOfPerson:                ImageWithBoundingBox[] | null;
    lastName:                      TextWithSources[] | null;
    middleNames:                   Array<TextWithSources[]> | null;
    nameSuffix:                    TextWithSources[] | null;
    nationality:                   TextWithSources[] | null;
    netWorth?:                     null | TextWithSources1;
    onlineProfilesWithUncertainty: OnlineProfile[] | null;
    phoneNumbers:                  UncertainTextWithSources[] | null;
    placeOfBirth?:                 null | TextWithSources1;
    relatedCountries:              CountryInfoWihReferenceIDS[] | null;
    riskData:                      null | OverviewRiskData;
    screeningSummary:              OneOfPersonScreeningSummaryDowJonesPersonScreeningSummary;
    sectionError?:                 null | string;
    significantWebsites:           UncertainTextWithSources[] | null;
    socialMediaReach?:             null | SocialMediaReach;
    sourceMapping:                 { [key: string]: null | InformationSource } | null;
    textualSummaries?:             TextualSummaries | null;
    titles:                        TextWithSources[] | null;
    [property: string]: any;
}

export interface TextWithSources {
    sourceIds:        string[];
    sources:          Array<null | InformationSource>;
    text:             string;
    totalSourceCount: number;
    [property: string]: any;
}

export interface Biography {
    link?:            null | string;
    sourceIds:        string[] | null;
    sources:          Array<null | InformationSource> | null;
    text:             null | string;
    totalSourceCount: number | null;
    [property: string]: any;
}

export interface PartialDateWithSources {
    day?:             number | null;
    month?:           number | null;
    monthShort?:      null | string;
    sourceIds:        string[] | null;
    sources:          Array<null | InformationSource> | null;
    totalSourceCount: number | null;
    year:             number | null;
    [property: string]: any;
}

export interface DescriptorSentenceSourced {
    descriptorHighlights: Highlight[];
    descriptorSentence:   string;
    sourceIds:            string[];
    sources:              Array<null | InformationSource>;
    [property: string]: any;
}

export interface Highlight {
    startIndex: number;
    textLength: number;
    [property: string]: any;
}

export interface UncertainTextWithSources {
    isUncertain:      boolean;
    sourceIds:        string[];
    sources:          Array<null | InformationSource>;
    text:             string;
    totalSourceCount: number;
    [property: string]: any;
}

export interface TextWithSources1 {
    sourceIds:        string[];
    sources:          Array<null | InformationSource>;
    text:             string;
    totalSourceCount: number;
    [property: string]: any;
}

export interface ImageGalleryImage {
    imageKey:  null | string;
    originUrl: null | string;
    url?:      null | string;
    [property: string]: any;
}

export interface ImageWithBoundingBox {
    boundingBox?: null | BoundingBox;
    image:        null | FrontendImage1;
    [property: string]: any;
}

export interface BoundingBox {
    bottomRight: Point;
    topLeft:     Point;
    [property: string]: any;
}

export interface Point {
    x: number;
    y: number;
}

export interface FrontendImage1 {
    altText?:                 null | string;
    containingDocumentTitle?: null | string;
    date?:                    null | DateWithUncertaintyWarning;
    height?:                  number;
    id:                       string;
    imageKey?:                null | string;
    originUrl?:               null | string;
    src?:                     null | string;
    thirdPartyContent:        boolean;
    width?:                   number;
    [property: string]: any;
}

export interface CountryInfoWihReferenceIDS {
    countryCode?:        null | string;
    countryName:         null | string;
    referenceIds:        string[] | null;
    referredSectionName: null | string;
    riskFlags:           LocationRiskFlag[] | null;
    [property: string]: any;
}

export interface OverviewRiskData {
    esg:         OverviewRiskDetails;
    finCrime:    OverviewRiskDetails;
    otherFlags:  OverviewRiskDetails;
    proceedings: OverviewRiskDetails;
    [property: string]: any;
}

export interface OverviewRiskDetails {
    directRiskCategories:   RiskCategoryWithReferences[];
    indirectRiskCategories: RiskCategoryWithReferences[];
    [property: string]: any;
}

export interface RiskCategoryWithReferences {
    referenceIds:        string[];
    referredSectionName: string;
    riskTier2Cat:        string;
    [property: string]: any;
}

export interface OneOfPersonScreeningSummaryDowJonesPersonScreeningSummary {
    peps:                     Person;
    sanctions:                Person;
    watchlists:               Person;
    dowJonesSpecialInterest?: PersonAndAssociateScreeningSummaryItems;
    [property: string]: any;
}

export interface PersonAndAssociateScreeningSummaryItems {
    direct:                   PersonScreeningSummaryBuckets;
    relativeOrCloseAssociate: AssociateScreeningSummaryBuckets;
    [property: string]: any;
}

export interface PersonScreeningSummaryBuckets {
    confirmed:   PersonSDetails[];
    discarded:   PersonSDetails[];
    unconfirmed: PersonSDetails[];
    [property: string]: any;
}

export interface PersonSDetails {
    dateOfBirth?:    null | PartialDate;
    entityName:      null | string;
    listings?:       RiskListingSummary[] | null;
    nationality:     string[] | null;
    riskCategories?: SpecialInterestCategoryWithHighlights[] | null;
    source?:         null | InformationSource;
    [property: string]: any;
}

export interface RiskListingSummary {
    endDate?:           null | PartialDate;
    hasTranslation:     boolean | null;
    isKnownToBeOngoing: boolean | null;
    listName:           null | string;
    sources:            Array<null | InformationSource> | null;
    startDate?:         null | PartialDate;
    [property: string]: any;
}

export interface SpecialInterestCategoryWithHighlights {
    category:         string;
    highlightOffsets: RiskHighlight[];
    [property: string]: any;
}

export interface AssociateScreeningSummaryBuckets {
    confirmed:   AssociateSDetails[];
    discarded:   AssociateSDetails[];
    unconfirmed: AssociateSDetails[];
    [property: string]: any;
}

export interface AssociateSDetails {
    dateOfBirth?:    null | PartialDate;
    entityName:      null | string;
    listings?:       RiskListingSummary[] | null;
    nationality:     string[] | null;
    relation?:       null | string;
    riskCategories?: SpecialInterestCategoryWithHighlights[] | null;
    source?:         null | InformationSource;
    [property: string]: any;
}

export interface Person {
    confirmed?:                PersonSDetails[];
    discarded?:                PersonSDetails[];
    unconfirmed?:              PersonSDetails[];
    direct?:                   PersonScreeningSummaryBuckets;
    relativeOrCloseAssociate?: AssociateScreeningSummaryBuckets;
    [property: string]: any;
}

export interface SocialMediaReach {
    level:     Level;
    sourceIds: string[];
    sources:   Array<null | InformationSource>;
    [property: string]: any;
}

export enum Level {
    High = "High",
    Low = "Low",
    Medium = "Medium",
}

export interface TextualSummaries {
    experimentalSummaries: ExperimentalSummary[];
    summary:               OneOfTextualSummaryErrorTypeUnitEnum | TextualSummary;
}

export interface ExperimentalSummary {
    summary: OneOfTextualSummaryErrorTypeUnitEnum | TextualSummary;
    title:   string;
}

export enum OneOfTextualSummaryErrorTypeUnitEnum {
    OpenAIOutage = "OpenAIOutage",
    UnknownError = "UnknownError",
}

export interface TextualSummary {
    summaryStatements?: SummaryStatement[] | null;
    warning?:           Warning;
    [property: string]: any;
}

export interface SummaryStatement {
    sourceIds:        string[];
    sources:          Array<null | InformationSource>;
    sourceSnippets:   { [key: string]: string[] };
    text:             string;
    totalSourceCount: number;
}

export enum Warning {
    InputTooLargeSummaryIncomplete = "InputTooLargeSummaryIncomplete",
    Unknown = "Unknown",
}

export enum ReportIssue {
    SubjectCouldNotBeIdentified = "SubjectCouldNotBeIdentified",
}

export interface ReportMetadata {
    activeFeatures:    string[];
    contexts:          ContextItem[];
    enquiryId:         string;
    enquiryTenant:     EnquiryTenant;
    generationVersion: null | DiVersion;
    preparationTime:   null | ReportPreparationTime;
    preparedAtUtc:     Date;
    reportEnvironment: string;
    reportFailed:      boolean;
    reportIssues:      ReportIssue[];
    searchErrorInfo:   SearchErrorInfo[];
    subject:           string;
    subjectType:       Type;
    systemIssues:      SystemIssue[];
    userEmail:         string;
    [property: string]: any;
}

export interface SearchErrorInfo {
    impactOfError: string;
    type:          SearchErrorInfoType;
    [property: string]: any;
}

export enum SearchErrorInfoType {
    Corporate = "Corporate",
    GeneralInternet = "GeneralInternet",
    GoogleKnowledgeGraph = "GoogleKnowledgeGraph",
    KeyPeople = "KeyPeople",
    LinkedIn = "LinkedIn",
    Pipl = "Pipl",
    Screening = "Screening",
}

export interface SystemIssue {
    diagnosticsOnly: boolean;
    message:         string;
    [property: string]: any;
}

export interface ReportSourcing {
    nonRelevantSourceGroups: SourceGroup[];
    notProcessedSourceCount: number;
    notProcessedSourceGroups: SourceGroup[];
    notRelevantSourceCount: number;
    relevantSourceCount: number;
    relevantSourceGroups: SourceGroup[];
    likelySourceGroups: SourceGroup[];
    likelySourceCount: number;
    possibleSourceGroups: SourceGroup[];
    possibleSourceCount: number;
    [property: string]: any;
}


export interface SourceGroup {
    earliestDate?:   null | PartialDate;
    latestDate?:     null | PartialDate;
    sourceStacks:    SourceStack[] | null;
    summaryImage?:   null | FrontendImage;
    summarySnippet?: null | string;
    timeSpan?:       null | UncertainTimeDelta;
    title?:          null | string;
    [property: string]: any;
}

export interface SourceStack {
    sources: Source[];
    [property: string]: any;
}

export interface Source {
    confidence?:             number | null;
    detectedLanguage?:       null | string;
    fixedSource:             FixedSource;
    goldStandardAssessment:  Assessment;
    hasDirectSubjectMention: boolean | null;
    heading:                 null | string;
    image?:                  null | FrontendImage;
    provider:                null | string;
    publicationDate?:        null | string;
    publicationDateFull?:    Date | null;
    readingTime?:            null | ReadingTime;
    sourceHierarchy:         string[] | null;
    sourceId:                null | string;
    sourceType:              string[] | null;
    subHeading?:             null | string;
    tags:                    Tag[] | null;
    text?:                   null | string;
    translatedVersion?:      null | Source1;
    url:                     null | URL;
    userAssessment:          Assessment;
    [property: string]: any;
}

export enum FixedSource {
    Blog = "Blog",
    Entertainment = "Entertainment",
    NewsAndMedia = "NewsAndMedia",
    Other = "Other",
    SocialMedia = "SocialMedia",
}

export interface ReadingTime {
    hours:   number;
    minutes: number;
    [property: string]: any;
}

export interface Tag {
    diagnosticsModeOnly: boolean | null;
    entity?:             null | EntityInSourceInfo;
    hideByDefault:       boolean | null;
    isNotCloseToSubject: boolean | null;
    label:               null | string;
    riskCategoriesOld:   string[] | null;
    riskHierarchies:     Array<string[]> | null;
    snippets:            Snippet[] | null;
    tagId:               null | string;
    [property: string]: any;
}

export interface EntityInSourceInfo {
    id:             number;
    longerFormText: string;
    text:           string;
    type:           string;
    [property: string]: any;
}

export interface Snippet {
    text:       string;
    translated: boolean;
    [property: string]: any;
}

export interface Source1 {
    confidence?:             number;
    detectedLanguage?:       null | string;
    fixedSource:             FixedSource;
    goldStandardAssessment:  Assessment;
    hasDirectSubjectMention: boolean;
    heading:                 string;
    image?:                  null | FrontendImage;
    provider:                string;
    publicationDate?:        null | string;
    publicationDateFull?:    Date;
    readingTime?:            null | ReadingTime;
    sourceHierarchy:         string[];
    sourceId:                string;
    sourceType:              string[];
    subHeading?:             null | string;
    tags:                    Tag[];
    text?:                   null | string;
    translatedVersion?:      null | Source1;
    url:                     null | URL;
    userAssessment:          Assessment;
    [property: string]: any;
}

export interface URL {
    display: string;
    fullUrl: string;
    [property: string]: any;
}

export interface UncertainTimeDelta {
    lowerBound?:           null | string;
    originalPresentation?: null | string;
    shortDisplay:          null | string;
    upperBound?:           null | string;
    widthBetweenBounds?:   null | string;
    [property: string]: any;
}

export interface PersonScreeningInformation {
    disregardedPersonListings: null | PersonListingsData;
    possiblePersonListings:    null | PersonListingsData;
    sectionError?:             null | string;
    subjectPersonListings:     null | PersonListingsData;
    [property: string]: any;
}

export interface PersonListingsData {
    peps:       RiskListings;
    sanctions:  RiskListings;
    watchLists: RiskListings;
    [property: string]: any;
}

export interface RiskListings {
    counts: number;
    hits:   IRiskListingFrontend[];
    [property: string]: any;
}

export interface IRiskListingFrontend {
    associatedAddresses: BasicLocation[] | null;
    detectedLanguage:    null | string;
    dynamicFields:       DynamicField[] | null;
    endDate?:            null | PartialDate;
    entityAliases:       Array<null | StringWithTranslationFrontend> | null;
    entityName:          null | StringWithTranslationFrontend;
    entityType:          Type;
    isKnownToBeOngoing:  boolean | null;
    listName:            null | StringWithTranslationFrontend;
    sourceUrl:           null | URLWithLabel;
    startDate?:          null | PartialDate;
    [property: string]: any;
}

export interface DynamicField {
    label:  string;
    values: Array<null | StringWithTranslationFrontend>;
    [property: string]: any;
}

export interface StringWithTranslationFrontend {
    original:    null | string;
    translated?: null | string;
    [property: string]: any;
}

export interface SnapshotInformation {
    descriptors:       Descriptor[] | null;
    directorships:     null | Officerships;
    employmentHistory: null | EmploymentHistory;
    media?:            null | Media;
    photoMontage:      NewImageWithInformationSource[] | null;
    sectionError?:     null | string;
    sourceDates:       Date[] | null;
    [property: string]: any;
}

export interface Officerships {
    active:         number | null;
    earliestStart?: null | PartialDate;
    latestEnd?:     null | PartialDate;
    total:          number | null;
    [property: string]: any;
}

export interface EmploymentHistory {
    activeRoles:    number | null;
    averageTenure?: null | YearsAndMonths;
    endDate?:       null | PartialDate;
    industryCounts: ValueTupleStringInt32[] | null;
    startDate?:     null | PartialDate;
    totalJobs:      number | null;
    [property: string]: any;
}

export interface ValueTupleStringInt32 {
    item1: string;
    item2: number;
}

export interface Media {
    earliestDate?:   null | PartialDate;
    flaggedDirect:   number | null;
    flaggedIndirect: number | null;
    latestDate?:     null | PartialDate;
    sources:         MediaSource[] | null;
    total:           number | null;
    [property: string]: any;
}

export interface MediaSource {
    articleNumber: number;
    groupName:     string;
    [property: string]: any;
}

export interface NewImageWithInformationSource {
    image:        null | FrontendImage1;
    profileScore: number;
    relevance:    number;
    sourceIds:    string[];
    sources:      Array<null | InformationSource>;
    [property: string]: any;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
    public static toReportTypes(json: string): ReportTypes {
        return cast(JSON.parse(json), r("ReportTypes"));
    }

    public static reportTypesToJson(value: ReportTypes): string {
        return JSON.stringify(uncast(value, r("ReportTypes")), null, 2);
    }
}

function invalidValue(typ: any, val: any, key: any, parent: any = ''): never {
    const prettyTyp = prettyTypeName(typ);
    const parentText = parent ? ` on ${parent}` : '';
    const keyText = key ? ` for key "${key}"` : '';
    throw Error(`Invalid value${keyText}${parentText}. Expected ${prettyTyp} but got ${JSON.stringify(val)}`);
}

function prettyTypeName(typ: any): string {
    if (Array.isArray(typ)) {
        if (typ.length === 2 && typ[0] === undefined) {
            return `an optional ${prettyTypeName(typ[1])}`;
        } else {
            return `one of [${typ.map(a => { return prettyTypeName(a); }).join(", ")}]`;
        }
    } else if (typeof typ === "object" && typ.literal !== undefined) {
        return typ.literal;
    } else {
        return typeof typ;
    }
}

function jsonToJSProps(typ: any): any {
    if (typ.jsonToJS === undefined) {
        const map: any = {};
        typ.props.forEach((p: any) => map[p.json] = { key: p.js, typ: p.typ });
        typ.jsonToJS = map;
    }
    return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
    if (typ.jsToJSON === undefined) {
        const map: any = {};
        typ.props.forEach((p: any) => map[p.js] = { key: p.json, typ: p.typ });
        typ.jsToJSON = map;
    }
    return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = '', parent: any = ''): any {
    function transformPrimitive(typ: string, val: any): any {
        if (typeof typ === typeof val) return val;
        return invalidValue(typ, val, key, parent);
    }

    function transformUnion(typs: any[], val: any): any {
        // val must validate against one typ in typs
        const l = typs.length;
        for (let i = 0; i < l; i++) {
            const typ = typs[i];
            try {
                return transform(val, typ, getProps);
            } catch (_) {}
        }
        return invalidValue(typs, val, key, parent);
    }

    function transformEnum(cases: string[], val: any): any {
        if (cases.indexOf(val) !== -1) return val;
        return invalidValue(cases.map(a => { return l(a); }), val, key, parent);
    }

    function transformArray(typ: any, val: any): any {
        // val must be an array with no invalid elements
        if (!Array.isArray(val)) return invalidValue(l("array"), val, key, parent);
        return val.map(el => transform(el, typ, getProps));
    }

    function transformDate(val: any): any {
        if (val === null) {
            return null;
        }
        const d = new Date(val);
        if (isNaN(d.valueOf())) {
            return invalidValue(l("Date"), val, key, parent);
        }
        return d;
    }

    function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
        if (val === null || typeof val !== "object" || Array.isArray(val)) {
            return invalidValue(l(ref || "object"), val, key, parent);
        }
        const result: any = {};
        Object.getOwnPropertyNames(props).forEach(key => {
            const prop = props[key];
            const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
            result[prop.key] = transform(v, prop.typ, getProps, key, ref);
        });
        Object.getOwnPropertyNames(val).forEach(key => {
            if (!Object.prototype.hasOwnProperty.call(props, key)) {
                result[key] = transform(val[key], additional, getProps, key, ref);
            }
        });
        return result;
    }

    if (typ === "any") return val;
    if (typ === null) {
        if (val === null) return val;
        return invalidValue(typ, val, key, parent);
    }
    if (typ === false) return invalidValue(typ, val, key, parent);
    let ref: any = undefined;
    while (typeof typ === "object" && typ.ref !== undefined) {
        ref = typ.ref;
        typ = typeMap[typ.ref];
    }
    if (Array.isArray(typ)) return transformEnum(typ, val);
    if (typeof typ === "object") {
        return typ.hasOwnProperty("unionMembers") ? transformUnion(typ.unionMembers, val)
            : typ.hasOwnProperty("arrayItems")    ? transformArray(typ.arrayItems, val)
            : typ.hasOwnProperty("props")         ? transformObject(getProps(typ), typ.additional, val)
            : invalidValue(typ, val, key, parent);
    }
    // Numbers can be parsed by Date but shouldn't be.
    if (typ === Date && typeof val !== "number") return transformDate(val);
    return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
    return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
    return transform(val, typ, jsToJSONProps);
}

function l(typ: any) {
    return { literal: typ };
}

function a(typ: any) {
    return { arrayItems: typ };
}

function u(...typs: any[]) {
    return { unionMembers: typs };
}

function o(props: any[], additional: any) {
    return { props, additional };
}

function m(additional: any) {
    return { props: [], additional };
}

function r(name: string) {
    return { ref: name };
}

const typeMap: any = {
    "ReportTypes": o([
        { json: "activeFeatures", js: "activeFeatures", typ: u(a(""), null) },
        { json: "awards", js: "awards", typ: u(undefined, u(null, r("AwardsSectionInformation"))) },
        { json: "businessAssociates", js: "businessAssociates", typ: u(a(r("BusinessAssociateInfo")), null) },
        { json: "contexts", js: "contexts", typ: u(a(r("ContextItem")), null) },
        { json: "dowPersonScreeningSection", js: "dowPersonScreeningSection", typ: u(undefined, u(null, r("DowPersonScreeningInformation"))) },
        { json: "education", js: "education", typ: u(undefined, u(null, r("EducationSectionInformation"))) },
        { json: "enquiryId", js: "enquiryId", typ: u(null, "") },
        { json: "enquiryTenant", js: "enquiryTenant", typ: r("EnquiryTenant") },
        { json: "family", js: "family", typ: u(undefined, u(null, r("FamilySectionInformation"))) },
        { json: "fullName", js: "fullName", typ: u(null, "") },
        { json: "generationVersion", js: "generationVersion", typ: u(null, r("DiVersion")) },
        { json: "insightCentre", js: "insightCentre", typ: u(undefined, u(null, r("InsightCentreSectionInformation"))) },
        { json: "location", js: "location", typ: u(undefined, u(null, r("LocationSectionInformation"))) },
        { json: "preparationTime", js: "preparationTime", typ: u(null, r("ReportPreparationTime")) },
        { json: "preparedAtUtc", js: "preparedAtUtc", typ: u(Date, null) },
        { json: "professionalOverview", js: "professionalOverview", typ: u(undefined, u(null, r("ProfessionalOverviewInformation"))) },
        { json: "reportAbstract", js: "reportAbstract", typ: u(undefined, u(null, r("AbstractSectionInformation"))) },
        { json: "reportEnvironment", js: "reportEnvironment", typ: u(null, "") },
        { json: "reportFailed", js: "reportFailed", typ: u(true, null) },
        { json: "reportIssues", js: "reportIssues", typ: u(a(r("ReportIssue")), null) },
        { json: "reportMetadata", js: "reportMetadata", typ: u(null, r("ReportMetadata")) },
        { json: "reportSourcing", js: "reportSourcing", typ: u(null, r("ReportSourcing")) },
        { json: "screeningSection", js: "screeningSection", typ: u(undefined, u(null, r("PersonScreeningInformation"))) },
        { json: "searchErrorInfo", js: "searchErrorInfo", typ: u(a(r("SearchErrorInfo")), null) },
        { json: "snapshotSection", js: "snapshotSection", typ: u(undefined, u(null, r("SnapshotInformation"))) },
        { json: "subject", js: "subject", typ: u(null, "") },
        { json: "subjectType", js: "subjectType", typ: r("Type") },
        { json: "systemIssues", js: "systemIssues", typ: u(a(r("SystemIssue")), null) },
        { json: "userEmail", js: "userEmail", typ: u(null, "") },
    ], "any"),
    "AwardsSectionInformation": o([
        { json: "awardsCount", js: "awardsCount", typ: u(0, null) },
        { json: "listOfAwardsInformation", js: "listOfAwardsInformation", typ: u(a(r("AwardInformation")), null) },
        { json: "sectionError", js: "sectionError", typ: u(undefined, u(null, "")) },
    ], "any"),
    "AwardInformation": o([
        { json: "awardDate", js: "awardDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "awardDescription", js: "awardDescription", typ: u(null, "") },
        { json: "awardImage", js: "awardImage", typ: u(null, r("ReportImage1")) },
        { json: "awardName", js: "awardName", typ: u(null, "") },
        { json: "awardNameLong", js: "awardNameLong", typ: u(undefined, u(null, "")) },
        { json: "awardType", js: "awardType", typ: u(undefined, u(null, "")) },
        { json: "sourceUrls", js: "sourceUrls", typ: u(a(""), null) },
    ], "any"),
    "PartialDate": o([
        { json: "day", js: "day", typ: u(undefined, u(0, null)) },
        { json: "month", js: "month", typ: u(undefined, u(0, null)) },
        { json: "monthShort", js: "monthShort", typ: u(undefined, u(null, "")) },
        { json: "year", js: "year", typ: u(0, null) },
    ], "any"),
    "ReportImage1": o([
        { json: "imageKey", js: "imageKey", typ: "" },
        { json: "s3Key", js: "s3Key", typ: "" },
        { json: "smallImage", js: "smallImage", typ: true },
        { json: "src", js: "src", typ: "" },
    ], "any"),
    "BusinessAssociateInfo": o([
        { json: "age", js: "age", typ: u(undefined, u(null, r("Age"))) },
        { json: "connectionList", js: "connectionList", typ: u(a(r("AssociateConnection")), null) },
        { json: "contextToUse", js: "contextToUse", typ: u(null, "") },
        { json: "dateOfBirth", js: "dateOfBirth", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "image", js: "image", typ: u(undefined, u(null, r("FrontendImage"))) },
        { json: "linkedInUrl", js: "linkedInUrl", typ: u(undefined, u(null, "")) },
        { json: "name", js: "name", typ: u(null, "") },
        { json: "nameToSendToNewReport", js: "nameToSendToNewReport", typ: u(null, "") },
        { json: "onlineProfiles", js: "onlineProfiles", typ: u(a(r("OnlineProfile")), null) },
    ], "any"),
    "Age": o([
        { json: "months", js: "months", typ: u(undefined, u(0, null)) },
        { json: "years", js: "years", typ: u(0, null) },
    ], "any"),
    "AssociateConnection": o([
        { json: "text", js: "text", typ: u(null, "") },
        { json: "url", js: "url", typ: u(undefined, u(null, "")) },
    ], "any"),
    "FrontendImage": o([
        { json: "altText", js: "altText", typ: u(undefined, u(null, "")) },
        { json: "containingDocumentTitle", js: "containingDocumentTitle", typ: u(undefined, u(null, "")) },
        { json: "date", js: "date", typ: u(undefined, u(null, r("DateWithUncertaintyWarning"))) },
        { json: "height", js: "height", typ: u(undefined, u(0, null)) },
        { json: "id", js: "id", typ: u(null, "") },
        { json: "imageKey", js: "imageKey", typ: u(undefined, u(null, "")) },
        { json: "originUrl", js: "originUrl", typ: u(undefined, u(null, "")) },
        { json: "src", js: "src", typ: u(undefined, u(null, "")) },
        { json: "thirdPartyContent", js: "thirdPartyContent", typ: u(true, null) },
        { json: "width", js: "width", typ: u(undefined, u(0, null)) },
    ], "any"),
    "DateWithUncertaintyWarning": o([
        { json: "date", js: "date", typ: u(Date, null) },
        { json: "uncertaintyThresholdDays", js: "uncertaintyThresholdDays", typ: u(undefined, u(0, null)) },
    ], "any"),
    "OnlineProfile": o([
        { json: "bestAvailability", js: "bestAvailability", typ: r("BestAvailability") },
        { json: "investigationDetailLevelPerformed", js: "investigationDetailLevelPerformed", typ: u(undefined, r("InvestigationDetailLevelPerformed")) },
        { json: "isUncertain", js: "isUncertain", typ: u(true, null) },
        { json: "label", js: "label", typ: u(null, "") },
        { json: "languageCode", js: "languageCode", typ: u(undefined, u(null, "")) },
        { json: "link", js: "link", typ: u(null, "") },
        { json: "numberOfFollowers", js: "numberOfFollowers", typ: u(undefined, u(0, null)) },
        { json: "profileType", js: "profileType", typ: u(r("Unit"), r("OneOfSocialMediaProfileTypesOnlineProfileTypesUnitEnum")) },
        { json: "sourceIds", js: "sourceIds", typ: u(a(""), null) },
        { json: "sources", js: "sources", typ: u(a(u(null, r("InformationSource"))), null) },
        { json: "totalSourceCount", js: "totalSourceCount", typ: u(0, null) },
    ], "any"),
    "Unit": o([
    ], false),
    "InformationSource": o([
        { json: "dateAccessed", js: "dateAccessed", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "datePublished", js: "datePublished", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "description", js: "description", typ: u(undefined, u(null, "")) },
        { json: "id", js: "id", typ: u(null, "") },
        { json: "image", js: "image", typ: u(undefined, u(null, r("FrontendImage"))) },
        { json: "isAvailableOnline", js: "isAvailableOnline", typ: u(true, null) },
        { json: "publisher", js: "publisher", typ: u(undefined, u(null, "")) },
        { json: "sourceType", js: "sourceType", typ: r("SourceType") },
        { json: "title", js: "title", typ: u(undefined, u(null, "")) },
        { json: "url", js: "url", typ: u(null, "") },
    ], "any"),
    "ContextItem": o([
        { json: "item", js: "item", typ: "" },
        { json: "type", js: "type", typ: r("ContextType") },
    ], "any"),
    "DowPersonScreeningInformation": o([
        { json: "associates", js: "associates", typ: m(r("DowJonesPersonResult")) },
        { json: "confirmed", js: "confirmed", typ: a(r("DowJonesPersonResult")) },
        { json: "discarded", js: "discarded", typ: a(r("DowJonesPersonResult")) },
        { json: "unconfirmed", js: "unconfirmed", typ: a(r("DowJonesPersonResult")) },
    ], "any"),
    "DowJonesPersonResult": o([
        { json: "addresses", js: "addresses", typ: u(a(r("BasicLocation")), null) },
        { json: "alternativeNames", js: "alternativeNames", typ: u(null, r("AlternativeNameDetails")) },
        { json: "associatedEntities", js: "associatedEntities", typ: u(a(r("DowAssociatedEntity")), null) },
        { json: "associatedOrgs", js: "associatedOrgs", typ: u(a(r("DowAssociatedEntity")), null) },
        { json: "associatedPeople", js: "associatedPeople", typ: u(a(r("DowAssociatedEntity")), null) },
        { json: "birthDate", js: "birthDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "citizenship", js: "citizenship", typ: u(a(r("EntityCountry")), null) },
        { json: "entityName", js: "entityName", typ: u(null, "") },
        { json: "flags", js: "flags", typ: u(a(r("Flag")), null) },
        { json: "gender", js: "gender", typ: u(undefined, u(null, "")) },
        { json: "images", js: "images", typ: u(a(""), null) },
        { json: "isDeceased", js: "isDeceased", typ: u(undefined, u(true, null)) },
        { json: "isRCA", js: "isRCA", typ: u(true, null) },
        { json: "jurisdiction", js: "jurisdiction", typ: u(a(r("EntityCountry")), null) },
        { json: "lastReviewedDate", js: "lastReviewedDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "listReferences", js: "listReferences", typ: u(a(r("DowJonesRiskGroup")), null) },
        { json: "nonWatchlistIdentifiers", js: "nonWatchlistIdentifiers", typ: u(a(r("DowIdentifier")), null) },
        { json: "pepAssociatesCount", js: "pepAssociatesCount", typ: u(0, null) },
        { json: "pepRole", js: "pepRole", typ: u(undefined, u(null, r("PepRoleFrontEnd"))) },
        { json: "previousNames", js: "previousNames", typ: u(null, r("AlternativeNameDetails")) },
        { json: "profileId", js: "profileId", typ: u(null, "") },
        { json: "profileNotes", js: "profileNotes", typ: u(undefined, u(null, "")) },
        { json: "reportedAllegation", js: "reportedAllegation", typ: u(a(r("EntityCountry")), null) },
        { json: "residence", js: "residence", typ: u(a(r("EntityCountry")), null) },
        { json: "sanctionedAssociatesCount", js: "sanctionedAssociatesCount", typ: u(0, null) },
        { json: "sources", js: "sources", typ: u(a(""), null) },
        { json: "specialInterestAssociatesCount", js: "specialInterestAssociatesCount", typ: u(0, null) },
        { json: "userAssessment", js: "userAssessment", typ: r("Assessment") },
        { json: "userAssessmentId", js: "userAssessmentId", typ: u(null, "") },
        { json: "watchlistAssociatesCount", js: "watchlistAssociatesCount", typ: u(0, null) },
        { json: "watchlistIdentifiers", js: "watchlistIdentifiers", typ: u(a(r("DowIdentifier")), null) },
    ], "any"),
    "BasicLocation": o([
        { json: "address", js: "address", typ: u(a(""), null) },
        { json: "countryCode", js: "countryCode", typ: u(undefined, u(null, "")) },
        { json: "countryName", js: "countryName", typ: u(undefined, u(null, "")) },
        { json: "locationType", js: "locationType", typ: r("LocationType") },
        { json: "shortAddress", js: "shortAddress", typ: u(undefined, u(a(""), null)) },
        { json: "streetViewImage", js: "streetViewImage", typ: u(undefined, u(null, r("FrontendImage"))) },
    ], "any"),
    "AlternativeNameDetails": o([
        { json: "allNames", js: "allNames", typ: a(r("AlternativeName")) },
        { json: "namesMatchingSubject", js: "namesMatchingSubject", typ: a("") },
    ], "any"),
    "AlternativeName": o([
        { json: "alternativeNameLanguages", js: "alternativeNameLanguages", typ: a(r("AlternativeNameLanguage")) },
        { json: "primaryRepresentation", js: "primaryRepresentation", typ: "" },
    ], "any"),
    "AlternativeNameLanguage": o([
        { json: "alias", js: "alias", typ: "" },
        { json: "language", js: "language", typ: "" },
    ], "any"),
    "DowAssociatedEntity": o([
        { json: "entityLocation", js: "entityLocation", typ: u(undefined, u(null, r("EntityCountry1"))) },
        { json: "entityName", js: "entityName", typ: u(null, "") },
        { json: "entityType", js: "entityType", typ: r("Type") },
        { json: "flags", js: "flags", typ: u(a(r("FlagEnum")), null) },
        { json: "isCurrent", js: "isCurrent", typ: u(true, null) },
        { json: "profileId", js: "profileId", typ: u(null, "") },
        { json: "relationship", js: "relationship", typ: u(null, "") },
    ], "any"),
    "EntityCountry1": o([
        { json: "countryCode", js: "countryCode", typ: "" },
        { json: "countryName", js: "countryName", typ: "" },
    ], "any"),
    "EntityCountry": o([
        { json: "countryCode", js: "countryCode", typ: "" },
        { json: "countryName", js: "countryName", typ: "" },
    ], "any"),
    "Flag": o([
        { json: "categoryHierarchy", js: "categoryHierarchy", typ: a(r("CategoryHierarchy")) },
        { json: "control", js: "control", typ: a(r("ValueWithLabel")) },
        { json: "isActive", js: "isActive", typ: true },
        { json: "locations", js: "locations", typ: a(r("Location")) },
        { json: "ownerhipPercentage", js: "ownerhipPercentage", typ: a(r("OwnerhipPercentage")) },
        { json: "type", js: "type", typ: r("FlagType") },
    ], "any"),
    "CategoryHierarchy": o([
        { json: "description", js: "description", typ: "" },
        { json: "level", js: "level", typ: 0 },
        { json: "subContentTypes", js: "subContentTypes", typ: a(r("SubContentType")) },
    ], "any"),
    "SubContentType": o([
        { json: "categoryId", js: "categoryId", typ: 0 },
        { json: "description", js: "description", typ: "" },
        { json: "level", js: "level", typ: 0 },
        { json: "subContentTypes", js: "subContentTypes", typ: a(r("SubContentType")) },
    ], "any"),
    "ValueWithLabel": o([
        { json: "label", js: "label", typ: "" },
        { json: "value", js: "value", typ: "" },
    ], "any"),
    "Location": o([
        { json: "countryCode", js: "countryCode", typ: "" },
        { json: "countryName", js: "countryName", typ: "" },
        { json: "relationship", js: "relationship", typ: "" },
    ], "any"),
    "OwnerhipPercentage": o([
        { json: "label", js: "label", typ: "" },
        { json: "notes", js: "notes", typ: "" },
        { json: "value", js: "value", typ: "" },
    ], "any"),
    "DowJonesRiskGroup": o([
        { json: "entries", js: "entries", typ: a(r("DowJonesRiskList")) },
        { json: "listGroup", js: "listGroup", typ: r("ListGroup") },
    ], "any"),
    "DowJonesRiskList": o([
        { json: "endDate", js: "endDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "isKnownToBeOngoing", js: "isKnownToBeOngoing", typ: u(true, null) },
        { json: "listName", js: "listName", typ: u(null, "") },
        { json: "sourceUrl", js: "sourceUrl", typ: u(undefined, u(null, "")) },
        { json: "startDate", js: "startDate", typ: u(undefined, u(null, r("PartialDate"))) },
    ], "any"),
    "DowIdentifier": o([
        { json: "countryOfIssue", js: "countryOfIssue", typ: u(undefined, u(null, r("EntityCountry1"))) },
        { json: "dateOfIssue", js: "dateOfIssue", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "label", js: "label", typ: u(null, "") },
        { json: "notes", js: "notes", typ: u(undefined, u(null, "")) },
        { json: "value", js: "value", typ: u(null, "") },
    ], "any"),
    "PepRoleFrontEnd": o([
        { json: "endDate", js: "endDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "isOngoing", js: "isOngoing", typ: u(undefined, u(true, null)) },
        { json: "roleTitle", js: "roleTitle", typ: u(undefined, u(null, "")) },
        { json: "startDate", js: "startDate", typ: u(undefined, u(null, r("PartialDate"))) },
    ], "any"),
    "EducationSectionInformation": o([
        { json: "educationCount", js: "educationCount", typ: u(0, null) },
        { json: "listOfEducationInformation", js: "listOfEducationInformation", typ: u(a(r("EducationInformation")), null) },
        { json: "sectionError", js: "sectionError", typ: u(undefined, u(null, "")) },
    ], "any"),
    "EducationInformation": o([
        { json: "degreeName", js: "degreeName", typ: u(undefined, u(null, "")) },
        { json: "durationOfEngagement", js: "durationOfEngagement", typ: u(undefined, u(null, r("YearsAndMonths"))) },
        { json: "endDate", js: "endDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "establishmentDescription", js: "establishmentDescription", typ: u(undefined, u(null, "")) },
        { json: "establishmentGlobalRanking", js: "establishmentGlobalRanking", typ: u(undefined, u(null, "")) },
        { json: "establishmentLocalRanking", js: "establishmentLocalRanking", typ: u(undefined, u(null, "")) },
        { json: "establishmentLocation", js: "establishmentLocation", typ: u(undefined, u(null, "")) },
        { json: "establishmentLogo", js: "establishmentLogo", typ: u(undefined, u(null, r("ReportImage"))) },
        { json: "establishmentName", js: "establishmentName", typ: u(null, "") },
        { json: "establishmentSize", js: "establishmentSize", typ: u(undefined, u(null, "")) },
        { json: "sourceUrls", js: "sourceUrls", typ: u(a(""), null) },
        { json: "startDate", js: "startDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "thirdPartyContent", js: "thirdPartyContent", typ: u(true, null) },
    ], "any"),
    "YearsAndMonths": o([
        { json: "months", js: "months", typ: u(undefined, u(0, null)) },
        { json: "years", js: "years", typ: u(0, null) },
    ], "any"),
    "ReportImage": o([
        { json: "imageKey", js: "imageKey", typ: "" },
        { json: "s3Key", js: "s3Key", typ: "" },
        { json: "smallImage", js: "smallImage", typ: true },
        { json: "src", js: "src", typ: "" },
    ], "any"),
    "FamilySectionInformation": o([
        { json: "listOfFamilyInformation", js: "listOfFamilyInformation", typ: u(a(r("FamilyInformation")), null) },
        { json: "sectionError", js: "sectionError", typ: u(undefined, u(null, "")) },
    ], "any"),
    "FamilyInformation": o([
        { json: "age", js: "age", typ: u(undefined, u(null, "")) },
        { json: "dateOfBirth", js: "dateOfBirth", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "image", js: "image", typ: u(undefined, u(null, r("FrontendImage"))) },
        { json: "name", js: "name", typ: u(null, "") },
        { json: "relation", js: "relation", typ: u(undefined, u(null, "")) },
    ], "any"),
    "DiVersion": o([
        { json: "display", js: "display", typ: "" },
        { json: "major", js: "major", typ: 0 },
        { json: "minor", js: "minor", typ: 0 },
        { json: "patch", js: "patch", typ: 0 },
    ], "any"),
    "InsightCentreSectionInformation": o([
        { json: "acquaintanceRoles", js: "acquaintanceRoles", typ: u(a(r("AcquaintanceRole")), null) },
        { json: "affiliations", js: "affiliations", typ: u(a(r("Affiliation")), null) },
        { json: "akas", js: "akas", typ: u(a(r("Aka")), null) },
        { json: "assets", js: "assets", typ: u(a(r("PersonalAsset")), null) },
        { json: "bornPatterns", js: "bornPatterns", typ: u(a(r("BirthDeathPattern")), null) },
        { json: "bubbleChart", js: "bubbleChart", typ: u(undefined, u(null, r("DynamicBubbleChartTabsContent"))) },
        { json: "deceasedPattern", js: "deceasedPattern", typ: u(null, r("DeceasedPattern")) },
        { json: "deceasedPatterns", js: "deceasedPatterns", typ: u(a(r("BirthDeathPattern")), null) },
        { json: "describedAs", js: "describedAs", typ: u(a(r("Descriptor")), null) },
        { json: "familyRoles", js: "familyRoles", typ: u(a(r("FamilyRole")), null) },
        { json: "jobRoles", js: "jobRoles", typ: u(a(r("ExperimentalOrgAndRoles")), null) },
        { json: "jobRolesNoOrgs", js: "jobRolesNoOrgs", typ: u(a(r("ExperimentalJobRole")), null) },
        { json: "nationalities", js: "nationalities", typ: u(a(r("NationalityResidency")), null) },
        { json: "quotes", js: "quotes", typ: u(a(r("Quote")), null) },
        { json: "residencies", js: "residencies", typ: u(a(r("NationalityResidency")), null) },
        { json: "riskSummaries", js: "riskSummaries", typ: u(a(r("RiskClusterSummary")), null) },
        { json: "sanctions", js: "sanctions", typ: u(a(r("SanctionsBySanctioningBody")), null) },
        { json: "sectionError", js: "sectionError", typ: u(undefined, u(null, "")) },
        { json: "wealth", js: "wealth", typ: u(null, r("Wealth")) },
    ], "any"),
    "AcquaintanceRole": o([
        { json: "person", js: "person", typ: "" },
        { json: "roles", js: "roles", typ: a(r("InnerRole")) },
        { json: "source", js: "source", typ: a(u(null, r("InformationSource"))) },
        { json: "sourceIds", js: "sourceIds", typ: a("") },
        { json: "sources", js: "sources", typ: a(u(null, r("InformationSource"))) },
    ], "any"),
    "InnerRole": o([
        { json: "modifiers", js: "modifiers", typ: a(r("Modifier")) },
        { json: "role", js: "role", typ: "" },
        { json: "sources", js: "sources", typ: a(u(null, r("InformationSource"))) },
    ], "any"),
    "Modifier": o([
        { json: "modifierText", js: "modifierText", typ: "" },
        { json: "source", js: "source", typ: a(u(null, r("InformationSource"))) },
        { json: "sourceIds", js: "sourceIds", typ: a("") },
        { json: "sources", js: "sources", typ: a(u(null, r("InformationSource"))) },
    ], "any"),
    "Affiliation": o([
        { json: "affiliationType", js: "affiliationType", typ: u(null, "") },
        { json: "date", js: "date", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "orgName", js: "orgName", typ: u(null, "") },
        { json: "sourceIds", js: "sourceIds", typ: u(a(""), null) },
        { json: "sources", js: "sources", typ: u(a(u(null, r("InformationSource"))), null) },
        { json: "value", js: "value", typ: u(undefined, u(null, "")) },
    ], "any"),
    "Aka": o([
        { json: "name", js: "name", typ: "" },
        { json: "source", js: "source", typ: a(u(null, r("InformationSource"))) },
        { json: "sourceIds", js: "sourceIds", typ: a("") },
        { json: "sources", js: "sources", typ: a(u(null, r("InformationSource"))) },
        { json: "type", js: "type", typ: a(r("Modifier")) },
    ], "any"),
    "PersonalAsset": o([
        { json: "assetName", js: "assetName", typ: u(null, "") },
        { json: "dateDurations", js: "dateDurations", typ: u(a(""), null) },
        { json: "firstPurchaseDate", js: "firstPurchaseDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "lastPurchaseDate", js: "lastPurchaseDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "purchasedFor", js: "purchasedFor", typ: u(undefined, u(null, "")) },
        { json: "soldDate", js: "soldDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "soldFor", js: "soldFor", typ: u(undefined, u(3.14, null)) },
        { json: "sourceIds", js: "sourceIds", typ: u(a(""), null) },
        { json: "sources", js: "sources", typ: u(a(u(null, r("InformationSource"))), null) },
        { json: "valuedAt", js: "valuedAt", typ: u(undefined, u(3.14, null)) },
    ], "any"),
    "BirthDeathPattern": o([
        { json: "dates", js: "dates", typ: a(r("DateSourcesAndModifiers")) },
        { json: "phrase", js: "phrase", typ: "" },
        { json: "sourceIds", js: "sourceIds", typ: a("") },
        { json: "sources", js: "sources", typ: a(u(null, r("InformationSource"))) },
    ], "any"),
    "DateSourcesAndModifiers": o([
        { json: "date", js: "date", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "modifiers", js: "modifiers", typ: u(a(r("Modifier")), null) },
        { json: "sourceIds", js: "sourceIds", typ: u(a(""), null) },
        { json: "sources", js: "sources", typ: u(a(u(null, r("InformationSource"))), null) },
    ], "any"),
    "DynamicBubbleChartTabsContent": o([
        { json: "dynamicBubbleChartTabs", js: "dynamicBubbleChartTabs", typ: r("AllEntities") },
        { json: "heading", js: "heading", typ: "" },
        { json: "size", js: "size", typ: 0 },
        { json: "smallHeading", js: "smallHeading", typ: "" },
    ], "any"),
    "AllEntities": o([
        { json: "locationsData", js: "locationsData", typ: a(r("Data")) },
        { json: "organisationsData", js: "organisationsData", typ: a(r("Data")) },
        { json: "personsData", js: "personsData", typ: a(r("Data")) },
    ], "any"),
    "Data": o([
        { json: "cat", js: "cat", typ: "" },
        { json: "description", js: "description", typ: "" },
        { json: "icon", js: "icon", typ: "" },
        { json: "name", js: "name", typ: "" },
        { json: "value", js: "value", typ: 0 },
    ], "any"),
    "DeceasedPattern": o([
        { json: "deceasedFlag", js: "deceasedFlag", typ: true },
        { json: "sources", js: "sources", typ: a(u(null, r("InformationSource"))) },
    ], "any"),
    "Descriptor": o([
        { json: "description", js: "description", typ: "" },
        { json: "sourceIds", js: "sourceIds", typ: a("") },
        { json: "sources", js: "sources", typ: a(u(null, r("InformationSource"))) },
    ], "any"),
    "FamilyRole": o([
        { json: "modifiers", js: "modifiers", typ: a(r("Modifier")) },
        { json: "person", js: "person", typ: "" },
        { json: "role", js: "role", typ: "" },
        { json: "source", js: "source", typ: a(u(null, r("InformationSource"))) },
        { json: "sourceIds", js: "sourceIds", typ: a("") },
        { json: "sources", js: "sources", typ: a(u(null, r("InformationSource"))) },
    ], "any"),
    "ExperimentalOrgAndRoles": o([
        { json: "org", js: "org", typ: "" },
        { json: "roles", js: "roles", typ: a(r("ExperimentalJobRole")) },
    ], "any"),
    "ExperimentalJobRole": o([
        { json: "dateDurations", js: "dateDurations", typ: u(a(""), null) },
        { json: "firstDate", js: "firstDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "lastDate", js: "lastDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "modifiers", js: "modifiers", typ: u(a(r("Modifier")), null) },
        { json: "org", js: "org", typ: u(null, "") },
        { json: "role", js: "role", typ: u(null, "") },
        { json: "sourceIds", js: "sourceIds", typ: u(a(""), null) },
        { json: "sources", js: "sources", typ: u(a(u(null, r("InformationSource"))), null) },
    ], "any"),
    "NationalityResidency": o([
        { json: "presentableText", js: "presentableText", typ: "" },
        { json: "sourceIds", js: "sourceIds", typ: a("") },
        { json: "sources", js: "sources", typ: a(u(null, r("InformationSource"))) },
    ], "any"),
    "Quote": o([
        { json: "date", js: "date", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "quoteText", js: "quoteText", typ: u(null, "") },
        { json: "sourceIds", js: "sourceIds", typ: u(a(""), null) },
        { json: "sources", js: "sources", typ: u(a(u(null, r("InformationSource"))), null) },
    ], "any"),
    "RiskClusterSummary": o([
        { json: "endDate", js: "endDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "granularRiskCategories", js: "granularRiskCategories", typ: u(a(""), null) },
        { json: "riskCategories", js: "riskCategories", typ: u(m(m(0)), null) },
        { json: "riskSources", js: "riskSources", typ: u(a(r("RiskSource")), null) },
        { json: "startDate", js: "startDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "summary", js: "summary", typ: u(null, "") },
        { json: "title", js: "title", typ: u(null, "") },
    ], "any"),
    "RiskSource": o([
        { json: "riskCategories", js: "riskCategories", typ: a(r("RiskCategory")) },
        { json: "source", js: "source", typ: u(null, r("InformationSource")) },
    ], "any"),
    "RiskCategory": o([
        { json: "category", js: "category", typ: "" },
        { json: "snippets", js: "snippets", typ: a(r("RiskSnippet")) },
    ], "any"),
    "RiskSnippet": o([
        { json: "highlightOffsets", js: "highlightOffsets", typ: a(r("RiskHighlight")) },
        { json: "snippet", js: "snippet", typ: "" },
    ], "any"),
    "RiskHighlight": o([
        { json: "highlightedText", js: "highlightedText", typ: u(undefined, u(null, "")) },
        { json: "startIndex", js: "startIndex", typ: u(0, null) },
        { json: "textLength", js: "textLength", typ: u(0, null) },
    ], "any"),
    "SanctionsBySanctioningBody": o([
        { json: "sanctioningBodyText", js: "sanctioningBodyText", typ: "" },
        { json: "sanctions", js: "sanctions", typ: a(r("Sanction")) },
    ], "any"),
    "Sanction": o([
        { json: "sentence", js: "sentence", typ: u(undefined, u(null, "")) },
        { json: "sourceDate", js: "sourceDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "sourceIds", js: "sourceIds", typ: u(a(""), null) },
        { json: "sources", js: "sources", typ: u(a(u(null, r("InformationSource"))), null) },
    ], "any"),
    "Wealth": o([
        { json: "wealthDescriptors", js: "wealthDescriptors", typ: a(r("Descriptor")) },
        { json: "wealthValues", js: "wealthValues", typ: a(r("WealthValue")) },
    ], "any"),
    "WealthValue": o([
        { json: "source", js: "source", typ: u(a(u(null, r("InformationSource"))), null) },
        { json: "sourceIds", js: "sourceIds", typ: u(a(""), null) },
        { json: "sources", js: "sources", typ: u(a(u(null, r("InformationSource"))), null) },
        { json: "value", js: "value", typ: u(null, "") },
        { json: "year", js: "year", typ: u(undefined, u(0, null)) },
    ], "any"),
    "LocationSectionInformation": o([
        { json: "listOfLocationInformation", js: "listOfLocationInformation", typ: u(a(r("LocationInformation")), null) },
        { json: "locationCount", js: "locationCount", typ: u(0, null) },
        { json: "sectionError", js: "sectionError", typ: u(undefined, u(null, "")) },
    ], "any"),
    "LocationInformation": o([
        { json: "addressFullDisplay", js: "addressFullDisplay", typ: u(a(""), null) },
        { json: "associatedOrganisations", js: "associatedOrganisations", typ: u(a(r("ValueTupleStringGUID")), null) },
        { json: "correspondenceAddressRoles", js: "correspondenceAddressRoles", typ: u(a(r("CorrespondenceAddressForRole")), null) },
        { json: "countryRiskFlags", js: "countryRiskFlags", typ: u(a(r("LocationRiskFlag")), null) },
        { json: "earliestEndDate", js: "earliestEndDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "earliestStartDate", js: "earliestStartDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "filterInfo", js: "filterInfo", typ: u(null, r("FilterInfo")) },
        { json: "isResolvedToSpecificLatLon", js: "isResolvedToSpecificLatLon", typ: u(true, null) },
        { json: "latitude", js: "latitude", typ: u(undefined, u(3.14, null)) },
        { json: "locationMapAreaImage", js: "locationMapAreaImage", typ: u(undefined, u(null, r("FrontendImage"))) },
        { json: "locationMapImage", js: "locationMapImage", typ: u(undefined, u(null, r("FrontendImage"))) },
        { json: "locationStreetViewImage", js: "locationStreetViewImage", typ: u(undefined, u(null, r("FrontendImage"))) },
        { json: "longitude", js: "longitude", typ: u(undefined, u(3.14, null)) },
        { json: "mostRecentEndDate", js: "mostRecentEndDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "mostRecentStartDate", js: "mostRecentStartDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "originalAddresses", js: "originalAddresses", typ: u(a(r("OriginalAddress")), null) },
        { json: "registeredAddressCompanies", js: "registeredAddressCompanies", typ: u(a(r("CompanyRegisteredAddresses")), null) },
    ], "any"),
    "ValueTupleStringGUID": o([
        { json: "item1", js: "item1", typ: "" },
        { json: "item2", js: "item2", typ: "" },
    ], false),
    "CorrespondenceAddressForRole": o([
        { json: "end", js: "end", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "isOngoing", js: "isOngoing", typ: u(undefined, u(true, null)) },
        { json: "links", js: "links", typ: u(a(""), null) },
        { json: "organisationIdentifier", js: "organisationIdentifier", typ: u(null, "") },
        { json: "organisationName", js: "organisationName", typ: u(null, "") },
        { json: "start", js: "start", typ: u(undefined, u(null, r("PartialDate"))) },
    ], "any"),
    "LocationRiskFlag": o([
        { json: "assigner", js: "assigner", typ: r("Assigner") },
    ], "any"),
    "FilterInfo": o([
        { json: "city", js: "city", typ: u(undefined, u(null, "")) },
        { json: "countryCode", js: "countryCode", typ: u(undefined, u(null, "")) },
        { json: "countryName", js: "countryName", typ: u(undefined, u(null, "")) },
    ], "any"),
    "OriginalAddress": o([
        { json: "address", js: "address", typ: a("") },
        { json: "linkedCompanies", js: "linkedCompanies", typ: a(r("LinkedCompany")) },
    ], "any"),
    "LinkedCompany": o([
        { json: "companyIdentifier", js: "companyIdentifier", typ: "" },
        { json: "relationTypes", js: "relationTypes", typ: a(r("RelationType")) },
    ], "any"),
    "CompanyRegisteredAddresses": o([
        { json: "end", js: "end", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "isOngoing", js: "isOngoing", typ: u(undefined, u(true, null)) },
        { json: "links", js: "links", typ: u(a(""), null) },
        { json: "organisationIdentifier", js: "organisationIdentifier", typ: u(null, "") },
        { json: "organisationName", js: "organisationName", typ: u(null, "") },
        { json: "start", js: "start", typ: u(undefined, u(null, r("PartialDate"))) },
    ], "any"),
    "ReportPreparationTime": o([
        { json: "minutes", js: "minutes", typ: 0 },
        { json: "seconds", js: "seconds", typ: 0 },
    ], "any"),
    "ProfessionalOverviewInformation": o([
        { json: "discardedListOfProfessionalInformation", js: "discardedListOfProfessionalInformation", typ: u(a(r("ProfessionalInformation")), null) },
        { json: "listOfProfessionalInformation", js: "listOfProfessionalInformation", typ: u(a(r("ProfessionalInformation")), null) },
        { json: "professionalInformationCount", js: "professionalInformationCount", typ: u(0, null) },
        { json: "sectionError", js: "sectionError", typ: u(undefined, u(null, "")) },
        { json: "unconfirmedListOfProfessionalInformation", js: "unconfirmedListOfProfessionalInformation", typ: u(a(r("ProfessionalInformation")), null) },
    ], "any"),
    "ProfessionalInformation": o([
        { json: "addressFullDisplay", js: "addressFullDisplay", typ: u(a(""), null) },
        { json: "addressShortDisplay", js: "addressShortDisplay", typ: u(undefined, u(null, "")) },
        { json: "confidence", js: "confidence", typ: u(null, r("Confidence")) },
        { json: "countryInfo", js: "countryInfo", typ: u(undefined, u(null, r("CountryInfo"))) },
        { json: "durationContinuous", js: "durationContinuous", typ: u(undefined, u(true, null)) },
        { json: "earliestStartDate", js: "earliestStartDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "id", js: "id", typ: u(null, "") },
        { json: "industryCategories", js: "industryCategories", typ: u(a(""), null) },
        { json: "insolvencyFilings", js: "insolvencyFilings", typ: u(a(r("CompanyFiling")), null) },
        { json: "isTenureOngoing", js: "isTenureOngoing", typ: u(true, null) },
        { json: "latestEndDate", js: "latestEndDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "listOfOrgWarnings", js: "listOfOrgWarnings", typ: u(a(r("OrgWarningDetails")), null) },
        { json: "listOfRoles", js: "listOfRoles", typ: u(a(r("RoleInfo")), null) },
        { json: "orgDescription", js: "orgDescription", typ: u(undefined, u(null, r("OrgDescription"))) },
        { json: "orgLogo", js: "orgLogo", typ: u(undefined, u(null, r("FrontendImage"))) },
        { json: "orgName", js: "orgName", typ: u(null, "") },
        { json: "orgStatus", js: "orgStatus", typ: u(undefined, u(null, r("OrgStatusDetails"))) },
        { json: "orgUrls", js: "orgUrls", typ: u(a(u(null, r("URLWithLabel"))), null) },
        { json: "overallDurationOfEngagement", js: "overallDurationOfEngagement", typ: u(undefined, u(null, r("YearsAndMonths"))) },
        { json: "previousOrgNames", js: "previousOrgNames", typ: u(a(r("PreviousOrgName")), null) },
        { json: "roleHolderDoB", js: "roleHolderDoB", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "roleHolderName", js: "roleHolderName", typ: u(null, "") },
        { json: "roleHolderNationalities", js: "roleHolderNationalities", typ: u(a(""), null) },
        { json: "roleSources", js: "roleSources", typ: u(a(u(null, r("InformationSource"))), null) },
        { json: "userAssessment", js: "userAssessment", typ: r("Assessment") },
        { json: "userAssessmentIds", js: "userAssessmentIds", typ: u(a(""), null) },
    ], "any"),
    "Confidence": o([
        { json: "exclusionDistance", js: "exclusionDistance", typ: 3.14 },
        { json: "inclusionDistance", js: "inclusionDistance", typ: 3.14 },
    ], "any"),
    "CountryInfo": o([
        { json: "countryCode", js: "countryCode", typ: u(undefined, u(null, "")) },
        { json: "countryName", js: "countryName", typ: u(null, "") },
        { json: "riskFlags", js: "riskFlags", typ: u(a(r("LocationRiskFlag")), null) },
        { json: "sourceIds", js: "sourceIds", typ: u(a(""), null) },
        { json: "sources", js: "sources", typ: u(a(u(null, r("InformationSource"))), null) },
    ], "any"),
    "CompanyFiling": o([
        { json: "date", js: "date", typ: u(undefined, u(Date, null)) },
        { json: "description", js: "description", typ: u(null, "") },
        { json: "link", js: "link", typ: u(null, "") },
    ], "any"),
    "OrgWarningDetails": o([
        { json: "label", js: "label", typ: "" },
    ], "any"),
    "RoleInfo": o([
        { json: "endDate", js: "endDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "isOfficership", js: "isOfficership", typ: u(true, null) },
        { json: "isOngoing", js: "isOngoing", typ: u(undefined, u(true, null)) },
        { json: "roleDuration", js: "roleDuration", typ: u(undefined, u(null, r("YearsAndMonths"))) },
        { json: "roleTitle", js: "roleTitle", typ: u(undefined, u(null, "")) },
        { json: "roleTitleVariant", js: "roleTitleVariant", typ: u(undefined, u(null, "")) },
        { json: "startDate", js: "startDate", typ: u(undefined, u(null, r("PartialDate"))) },
    ], "any"),
    "OrgDescription": o([
        { json: "readMoreLink", js: "readMoreLink", typ: u(undefined, u(null, "")) },
        { json: "text", js: "text", typ: u(null, "") },
    ], "any"),
    "OrgStatusDetails": o([
        { json: "label", js: "label", typ: "" },
        { json: "statusTag", js: "statusTag", typ: r("StatusTag") },
    ], "any"),
    "URLWithLabel": o([
        { json: "label", js: "label", typ: u(undefined, u(null, "")) },
        { json: "url", js: "url", typ: u(null, "") },
    ], "any"),
    "PreviousOrgName": o([
        { json: "endDate", js: "endDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "name", js: "name", typ: u(null, "") },
        { json: "startDate", js: "startDate", typ: u(undefined, u(null, r("PartialDate"))) },
    ], "any"),
    "AbstractSectionInformation": o([
        { json: "age", js: "age", typ: u(undefined, u(null, "")) },
        { json: "awards", js: "awards", typ: u(a(r("TextWithSources")), null) },
        { json: "biography", js: "biography", typ: u(undefined, u(null, r("Biography"))) },
        { json: "confirmedOfficershipIds", js: "confirmedOfficershipIds", typ: u(a(""), null) },
        { json: "dateOfBirth", js: "dateOfBirth", typ: u(undefined, u(null, r("PartialDateWithSources"))) },
        { json: "descriptorSentencesSourced", js: "descriptorSentencesSourced", typ: u(a(r("DescriptorSentenceSourced")), null) },
        { json: "emailAddresses", js: "emailAddresses", typ: u(a(r("UncertainTextWithSources")), null) },
        { json: "firstName", js: "firstName", typ: u(a(r("TextWithSources")), null) },
        { json: "fullName", js: "fullName", typ: u(null, "") },
        { json: "gender", js: "gender", typ: u(undefined, u(null, r("TextWithSources1"))) },
        { json: "imageCount", js: "imageCount", typ: u(0, null) },
        { json: "images", js: "images", typ: u(a(r("ImageGalleryImage")), null) },
        { json: "imagesOfPerson", js: "imagesOfPerson", typ: u(a(r("ImageWithBoundingBox")), null) },
        { json: "lastName", js: "lastName", typ: u(a(r("TextWithSources")), null) },
        { json: "middleNames", js: "middleNames", typ: u(a(a(r("TextWithSources"))), null) },
        { json: "nameSuffix", js: "nameSuffix", typ: u(a(r("TextWithSources")), null) },
        { json: "nationality", js: "nationality", typ: u(a(r("TextWithSources")), null) },
        { json: "netWorth", js: "netWorth", typ: u(undefined, u(null, r("TextWithSources1"))) },
        { json: "onlineProfilesWithUncertainty", js: "onlineProfilesWithUncertainty", typ: u(a(r("OnlineProfile")), null) },
        { json: "phoneNumbers", js: "phoneNumbers", typ: u(a(r("UncertainTextWithSources")), null) },
        { json: "placeOfBirth", js: "placeOfBirth", typ: u(undefined, u(null, r("TextWithSources1"))) },
        { json: "relatedCountries", js: "relatedCountries", typ: u(a(r("CountryInfoWihReferenceIDS")), null) },
        { json: "riskData", js: "riskData", typ: u(null, r("OverviewRiskData")) },
        { json: "screeningSummary", js: "screeningSummary", typ: r("OneOfPersonScreeningSummaryDowJonesPersonScreeningSummary") },
        { json: "sectionError", js: "sectionError", typ: u(undefined, u(null, "")) },
        { json: "significantWebsites", js: "significantWebsites", typ: u(a(r("UncertainTextWithSources")), null) },
        { json: "socialMediaReach", js: "socialMediaReach", typ: u(undefined, u(null, r("SocialMediaReach"))) },
        { json: "sourceMapping", js: "sourceMapping", typ: u(m(u(null, r("InformationSource"))), null) },
        { json: "textualSummaries", js: "textualSummaries", typ: u(undefined, u(r("TextualSummaries"), null)) },
        { json: "titles", js: "titles", typ: u(a(r("TextWithSources")), null) },
    ], "any"),
    "TextWithSources": o([
        { json: "sourceIds", js: "sourceIds", typ: a("") },
        { json: "sources", js: "sources", typ: a(u(null, r("InformationSource"))) },
        { json: "text", js: "text", typ: "" },
        { json: "totalSourceCount", js: "totalSourceCount", typ: 0 },
    ], "any"),
    "Biography": o([
        { json: "link", js: "link", typ: u(undefined, u(null, "")) },
        { json: "sourceIds", js: "sourceIds", typ: u(a(""), null) },
        { json: "sources", js: "sources", typ: u(a(u(null, r("InformationSource"))), null) },
        { json: "text", js: "text", typ: u(null, "") },
        { json: "totalSourceCount", js: "totalSourceCount", typ: u(0, null) },
    ], "any"),
    "PartialDateWithSources": o([
        { json: "day", js: "day", typ: u(undefined, u(0, null)) },
        { json: "month", js: "month", typ: u(undefined, u(0, null)) },
        { json: "monthShort", js: "monthShort", typ: u(undefined, u(null, "")) },
        { json: "sourceIds", js: "sourceIds", typ: u(a(""), null) },
        { json: "sources", js: "sources", typ: u(a(u(null, r("InformationSource"))), null) },
        { json: "totalSourceCount", js: "totalSourceCount", typ: u(0, null) },
        { json: "year", js: "year", typ: u(0, null) },
    ], "any"),
    "DescriptorSentenceSourced": o([
        { json: "descriptorHighlights", js: "descriptorHighlights", typ: a(r("Highlight")) },
        { json: "descriptorSentence", js: "descriptorSentence", typ: "" },
        { json: "sourceIds", js: "sourceIds", typ: a("") },
        { json: "sources", js: "sources", typ: a(u(null, r("InformationSource"))) },
    ], "any"),
    "Highlight": o([
        { json: "startIndex", js: "startIndex", typ: 0 },
        { json: "textLength", js: "textLength", typ: 0 },
    ], "any"),
    "UncertainTextWithSources": o([
        { json: "isUncertain", js: "isUncertain", typ: true },
        { json: "sourceIds", js: "sourceIds", typ: a("") },
        { json: "sources", js: "sources", typ: a(u(null, r("InformationSource"))) },
        { json: "text", js: "text", typ: "" },
        { json: "totalSourceCount", js: "totalSourceCount", typ: 0 },
    ], "any"),
    "TextWithSources1": o([
        { json: "sourceIds", js: "sourceIds", typ: a("") },
        { json: "sources", js: "sources", typ: a(u(null, r("InformationSource"))) },
        { json: "text", js: "text", typ: "" },
        { json: "totalSourceCount", js: "totalSourceCount", typ: 0 },
    ], "any"),
    "ImageGalleryImage": o([
        { json: "imageKey", js: "imageKey", typ: u(null, "") },
        { json: "originUrl", js: "originUrl", typ: u(null, "") },
        { json: "url", js: "url", typ: u(undefined, u(null, "")) },
    ], "any"),
    "ImageWithBoundingBox": o([
        { json: "boundingBox", js: "boundingBox", typ: u(undefined, u(null, r("BoundingBox"))) },
        { json: "image", js: "image", typ: u(null, r("FrontendImage1")) },
    ], "any"),
    "BoundingBox": o([
        { json: "bottomRight", js: "bottomRight", typ: r("Point") },
        { json: "topLeft", js: "topLeft", typ: r("Point") },
    ], "any"),
    "Point": o([
        { json: "x", js: "x", typ: 3.14 },
        { json: "y", js: "y", typ: 3.14 },
    ], false),
    "FrontendImage1": o([
        { json: "altText", js: "altText", typ: u(undefined, u(null, "")) },
        { json: "containingDocumentTitle", js: "containingDocumentTitle", typ: u(undefined, u(null, "")) },
        { json: "date", js: "date", typ: u(undefined, u(null, r("DateWithUncertaintyWarning"))) },
        { json: "height", js: "height", typ: u(undefined, 0) },
        { json: "id", js: "id", typ: "" },
        { json: "imageKey", js: "imageKey", typ: u(undefined, u(null, "")) },
        { json: "originUrl", js: "originUrl", typ: u(undefined, u(null, "")) },
        { json: "src", js: "src", typ: u(undefined, u(null, "")) },
        { json: "thirdPartyContent", js: "thirdPartyContent", typ: true },
        { json: "width", js: "width", typ: u(undefined, 0) },
    ], "any"),
    "CountryInfoWihReferenceIDS": o([
        { json: "countryCode", js: "countryCode", typ: u(undefined, u(null, "")) },
        { json: "countryName", js: "countryName", typ: u(null, "") },
        { json: "referenceIds", js: "referenceIds", typ: u(a(""), null) },
        { json: "referredSectionName", js: "referredSectionName", typ: u(null, "") },
        { json: "riskFlags", js: "riskFlags", typ: u(a(r("LocationRiskFlag")), null) },
    ], "any"),
    "OverviewRiskData": o([
        { json: "esg", js: "esg", typ: r("OverviewRiskDetails") },
        { json: "finCrime", js: "finCrime", typ: r("OverviewRiskDetails") },
        { json: "otherFlags", js: "otherFlags", typ: r("OverviewRiskDetails") },
        { json: "proceedings", js: "proceedings", typ: r("OverviewRiskDetails") },
    ], "any"),
    "OverviewRiskDetails": o([
        { json: "directRiskCategories", js: "directRiskCategories", typ: a(r("RiskCategoryWithReferences")) },
        { json: "indirectRiskCategories", js: "indirectRiskCategories", typ: a(r("RiskCategoryWithReferences")) },
    ], "any"),
    "RiskCategoryWithReferences": o([
        { json: "referenceIds", js: "referenceIds", typ: a("") },
        { json: "referredSectionName", js: "referredSectionName", typ: "" },
        { json: "riskTier2Cat", js: "riskTier2Cat", typ: "" },
    ], "any"),
    "OneOfPersonScreeningSummaryDowJonesPersonScreeningSummary": o([
        { json: "peps", js: "peps", typ: r("Person") },
        { json: "sanctions", js: "sanctions", typ: r("Person") },
        { json: "watchlists", js: "watchlists", typ: r("Person") },
        { json: "dowJonesSpecialInterest", js: "dowJonesSpecialInterest", typ: u(undefined, r("PersonAndAssociateScreeningSummaryItems")) },
    ], "any"),
    "PersonAndAssociateScreeningSummaryItems": o([
        { json: "direct", js: "direct", typ: r("PersonScreeningSummaryBuckets") },
        { json: "relativeOrCloseAssociate", js: "relativeOrCloseAssociate", typ: r("AssociateScreeningSummaryBuckets") },
    ], "any"),
    "PersonScreeningSummaryBuckets": o([
        { json: "confirmed", js: "confirmed", typ: a(r("PersonSDetails")) },
        { json: "discarded", js: "discarded", typ: a(r("PersonSDetails")) },
        { json: "unconfirmed", js: "unconfirmed", typ: a(r("PersonSDetails")) },
    ], "any"),
    "PersonSDetails": o([
        { json: "dateOfBirth", js: "dateOfBirth", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "entityName", js: "entityName", typ: u(null, "") },
        { json: "listings", js: "listings", typ: u(undefined, u(a(r("RiskListingSummary")), null)) },
        { json: "nationality", js: "nationality", typ: u(a(""), null) },
        { json: "riskCategories", js: "riskCategories", typ: u(undefined, u(a(r("SpecialInterestCategoryWithHighlights")), null)) },
        { json: "source", js: "source", typ: u(undefined, u(null, r("InformationSource"))) },
    ], "any"),
    "RiskListingSummary": o([
        { json: "endDate", js: "endDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "hasTranslation", js: "hasTranslation", typ: u(true, null) },
        { json: "isKnownToBeOngoing", js: "isKnownToBeOngoing", typ: u(true, null) },
        { json: "listName", js: "listName", typ: u(null, "") },
        { json: "sources", js: "sources", typ: u(a(u(null, r("InformationSource"))), null) },
        { json: "startDate", js: "startDate", typ: u(undefined, u(null, r("PartialDate"))) },
    ], "any"),
    "SpecialInterestCategoryWithHighlights": o([
        { json: "category", js: "category", typ: "" },
        { json: "highlightOffsets", js: "highlightOffsets", typ: a(r("RiskHighlight")) },
    ], "any"),
    "AssociateScreeningSummaryBuckets": o([
        { json: "confirmed", js: "confirmed", typ: a(r("AssociateSDetails")) },
        { json: "discarded", js: "discarded", typ: a(r("AssociateSDetails")) },
        { json: "unconfirmed", js: "unconfirmed", typ: a(r("AssociateSDetails")) },
    ], "any"),
    "AssociateSDetails": o([
        { json: "dateOfBirth", js: "dateOfBirth", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "entityName", js: "entityName", typ: u(null, "") },
        { json: "listings", js: "listings", typ: u(undefined, u(a(r("RiskListingSummary")), null)) },
        { json: "nationality", js: "nationality", typ: u(a(""), null) },
        { json: "relation", js: "relation", typ: u(undefined, u(null, "")) },
        { json: "riskCategories", js: "riskCategories", typ: u(undefined, u(a(r("SpecialInterestCategoryWithHighlights")), null)) },
        { json: "source", js: "source", typ: u(undefined, u(null, r("InformationSource"))) },
    ], "any"),
    "Person": o([
        { json: "confirmed", js: "confirmed", typ: u(undefined, a(r("PersonSDetails"))) },
        { json: "discarded", js: "discarded", typ: u(undefined, a(r("PersonSDetails"))) },
        { json: "unconfirmed", js: "unconfirmed", typ: u(undefined, a(r("PersonSDetails"))) },
        { json: "direct", js: "direct", typ: u(undefined, r("PersonScreeningSummaryBuckets")) },
        { json: "relativeOrCloseAssociate", js: "relativeOrCloseAssociate", typ: u(undefined, r("AssociateScreeningSummaryBuckets")) },
    ], "any"),
    "SocialMediaReach": o([
        { json: "level", js: "level", typ: r("Level") },
        { json: "sourceIds", js: "sourceIds", typ: a("") },
        { json: "sources", js: "sources", typ: a(u(null, r("InformationSource"))) },
    ], "any"),
    "TextualSummaries": o([
        { json: "experimentalSummaries", js: "experimentalSummaries", typ: a(r("ExperimentalSummary")) },
        { json: "summary", js: "summary", typ: u(r("OneOfTextualSummaryErrorTypeUnitEnum"), r("TextualSummary")) },
    ], false),
    "ExperimentalSummary": o([
        { json: "summary", js: "summary", typ: u(r("OneOfTextualSummaryErrorTypeUnitEnum"), r("TextualSummary")) },
        { json: "title", js: "title", typ: "" },
    ], false),
    "TextualSummary": o([
        { json: "summaryStatements", js: "summaryStatements", typ: u(undefined, u(a(r("SummaryStatement")), null)) },
        { json: "warning", js: "warning", typ: u(undefined, r("Warning")) },
    ], "any"),
    "SummaryStatement": o([
        { json: "sourceIds", js: "sourceIds", typ: a("") },
        { json: "sources", js: "sources", typ: a(u(null, r("InformationSource"))) },
        { json: "sourceSnippets", js: "sourceSnippets", typ: m(a("")) },
        { json: "text", js: "text", typ: "" },
        { json: "totalSourceCount", js: "totalSourceCount", typ: 0 },
    ], false),
    "ReportMetadata": o([
        { json: "activeFeatures", js: "activeFeatures", typ: a("") },
        { json: "contexts", js: "contexts", typ: a(r("ContextItem")) },
        { json: "enquiryId", js: "enquiryId", typ: "" },
        { json: "enquiryTenant", js: "enquiryTenant", typ: r("EnquiryTenant") },
        { json: "generationVersion", js: "generationVersion", typ: u(null, r("DiVersion")) },
        { json: "preparationTime", js: "preparationTime", typ: u(null, r("ReportPreparationTime")) },
        { json: "preparedAtUtc", js: "preparedAtUtc", typ: Date },
        { json: "reportEnvironment", js: "reportEnvironment", typ: "" },
        { json: "reportFailed", js: "reportFailed", typ: true },
        { json: "reportIssues", js: "reportIssues", typ: a(r("ReportIssue")) },
        { json: "searchErrorInfo", js: "searchErrorInfo", typ: a(r("SearchErrorInfo")) },
        { json: "subject", js: "subject", typ: "" },
        { json: "subjectType", js: "subjectType", typ: r("Type") },
        { json: "systemIssues", js: "systemIssues", typ: a(r("SystemIssue")) },
        { json: "userEmail", js: "userEmail", typ: "" },
    ], "any"),
    "SearchErrorInfo": o([
        { json: "impactOfError", js: "impactOfError", typ: "" },
        { json: "type", js: "type", typ: r("SearchErrorInfoType") },
    ], "any"),
    "SystemIssue": o([
        { json: "diagnosticsOnly", js: "diagnosticsOnly", typ: true },
        { json: "message", js: "message", typ: "" },
    ], "any"),
    "ReportSourcing": o([
        { json: "nonRelevantSourceGroups", js: "nonRelevantSourceGroups", typ: a(r("SourceGroup")) },
        { json: "notProcessedSourceCount", js: "notProcessedSourceCount", typ: 0 },
        { json: "notProcessedSourceGroups", js: "notProcessedSourceGroups", typ: a(r("SourceGroup")) },
        { json: "notRelevantSourceCount", js: "notRelevantSourceCount", typ: 0 },
        { json: "relevantSourceCount", js: "relevantSourceCount", typ: 0 },
        { json: "relevantSourceGroups", js: "relevantSourceGroups", typ: a(r("SourceGroup")) },
    ], "any"),
    "SourceGroup": o([
        { json: "earliestDate", js: "earliestDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "latestDate", js: "latestDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "sourceStacks", js: "sourceStacks", typ: u(a(r("SourceStack")), null) },
        { json: "summaryImage", js: "summaryImage", typ: u(undefined, u(null, r("FrontendImage"))) },
        { json: "summarySnippet", js: "summarySnippet", typ: u(undefined, u(null, "")) },
        { json: "timeSpan", js: "timeSpan", typ: u(undefined, u(null, r("UncertainTimeDelta"))) },
        { json: "title", js: "title", typ: u(undefined, u(null, "")) },
    ], "any"),
    "SourceStack": o([
        { json: "sources", js: "sources", typ: a(r("Source")) },
    ], "any"),
    "Source": o([
        { json: "confidence", js: "confidence", typ: u(undefined, u(3.14, null)) },
        { json: "detectedLanguage", js: "detectedLanguage", typ: u(undefined, u(null, "")) },
        { json: "fixedSource", js: "fixedSource", typ: r("FixedSource") },
        { json: "goldStandardAssessment", js: "goldStandardAssessment", typ: r("Assessment") },
        { json: "hasDirectSubjectMention", js: "hasDirectSubjectMention", typ: u(true, null) },
        { json: "heading", js: "heading", typ: u(null, "") },
        { json: "image", js: "image", typ: u(undefined, u(null, r("FrontendImage"))) },
        { json: "provider", js: "provider", typ: u(null, "") },
        { json: "publicationDate", js: "publicationDate", typ: u(undefined, u(null, "")) },
        { json: "publicationDateFull", js: "publicationDateFull", typ: u(undefined, u(Date, null)) },
        { json: "readingTime", js: "readingTime", typ: u(undefined, u(null, r("ReadingTime"))) },
        { json: "sourceHierarchy", js: "sourceHierarchy", typ: u(a(""), null) },
        { json: "sourceId", js: "sourceId", typ: u(null, "") },
        { json: "sourceType", js: "sourceType", typ: u(a(""), null) },
        { json: "subHeading", js: "subHeading", typ: u(undefined, u(null, "")) },
        { json: "tags", js: "tags", typ: u(a(r("Tag")), null) },
        { json: "text", js: "text", typ: u(undefined, u(null, "")) },
        { json: "translatedVersion", js: "translatedVersion", typ: u(undefined, u(null, r("Source1"))) },
        { json: "url", js: "url", typ: u(null, r("URL")) },
        { json: "userAssessment", js: "userAssessment", typ: r("Assessment") },
    ], "any"),
    "ReadingTime": o([
        { json: "hours", js: "hours", typ: 0 },
        { json: "minutes", js: "minutes", typ: 0 },
    ], "any"),
    "Tag": o([
        { json: "diagnosticsModeOnly", js: "diagnosticsModeOnly", typ: u(true, null) },
        { json: "entity", js: "entity", typ: u(undefined, u(null, r("EntityInSourceInfo"))) },
        { json: "hideByDefault", js: "hideByDefault", typ: u(true, null) },
        { json: "isNotCloseToSubject", js: "isNotCloseToSubject", typ: u(true, null) },
        { json: "label", js: "label", typ: u(null, "") },
        { json: "riskCategoriesOld", js: "riskCategoriesOld", typ: u(a(""), null) },
        { json: "riskHierarchies", js: "riskHierarchies", typ: u(a(a("")), null) },
        { json: "snippets", js: "snippets", typ: u(a(r("Snippet")), null) },
        { json: "tagId", js: "tagId", typ: u(null, "") },
    ], "any"),
    "EntityInSourceInfo": o([
        { json: "id", js: "id", typ: 0 },
        { json: "longerFormText", js: "longerFormText", typ: "" },
        { json: "text", js: "text", typ: "" },
        { json: "type", js: "type", typ: "" },
    ], "any"),
    "Snippet": o([
        { json: "text", js: "text", typ: "" },
        { json: "translated", js: "translated", typ: true },
    ], "any"),
    "Source1": o([
        { json: "confidence", js: "confidence", typ: u(undefined, 3.14) },
        { json: "detectedLanguage", js: "detectedLanguage", typ: u(undefined, u(null, "")) },
        { json: "fixedSource", js: "fixedSource", typ: r("FixedSource") },
        { json: "goldStandardAssessment", js: "goldStandardAssessment", typ: r("Assessment") },
        { json: "hasDirectSubjectMention", js: "hasDirectSubjectMention", typ: true },
        { json: "heading", js: "heading", typ: "" },
        { json: "image", js: "image", typ: u(undefined, u(null, r("FrontendImage"))) },
        { json: "provider", js: "provider", typ: "" },
        { json: "publicationDate", js: "publicationDate", typ: u(undefined, u(null, "")) },
        { json: "publicationDateFull", js: "publicationDateFull", typ: u(undefined, Date) },
        { json: "readingTime", js: "readingTime", typ: u(undefined, u(null, r("ReadingTime"))) },
        { json: "sourceHierarchy", js: "sourceHierarchy", typ: a("") },
        { json: "sourceId", js: "sourceId", typ: "" },
        { json: "sourceType", js: "sourceType", typ: a("") },
        { json: "subHeading", js: "subHeading", typ: u(undefined, u(null, "")) },
        { json: "tags", js: "tags", typ: a(r("Tag")) },
        { json: "text", js: "text", typ: u(undefined, u(null, "")) },
        { json: "translatedVersion", js: "translatedVersion", typ: u(undefined, u(null, r("Source1"))) },
        { json: "url", js: "url", typ: u(null, r("URL")) },
        { json: "userAssessment", js: "userAssessment", typ: r("Assessment") },
    ], "any"),
    "URL": o([
        { json: "display", js: "display", typ: "" },
        { json: "fullUrl", js: "fullUrl", typ: "" },
    ], "any"),
    "UncertainTimeDelta": o([
        { json: "lowerBound", js: "lowerBound", typ: u(undefined, u(null, "")) },
        { json: "originalPresentation", js: "originalPresentation", typ: u(undefined, u(null, "")) },
        { json: "shortDisplay", js: "shortDisplay", typ: u(null, "") },
        { json: "upperBound", js: "upperBound", typ: u(undefined, u(null, "")) },
        { json: "widthBetweenBounds", js: "widthBetweenBounds", typ: u(undefined, u(null, "")) },
    ], "any"),
    "PersonScreeningInformation": o([
        { json: "disregardedPersonListings", js: "disregardedPersonListings", typ: u(null, r("PersonListingsData")) },
        { json: "possiblePersonListings", js: "possiblePersonListings", typ: u(null, r("PersonListingsData")) },
        { json: "sectionError", js: "sectionError", typ: u(undefined, u(null, "")) },
        { json: "subjectPersonListings", js: "subjectPersonListings", typ: u(null, r("PersonListingsData")) },
    ], "any"),
    "PersonListingsData": o([
        { json: "peps", js: "peps", typ: r("RiskListings") },
        { json: "sanctions", js: "sanctions", typ: r("RiskListings") },
        { json: "watchLists", js: "watchLists", typ: r("RiskListings") },
    ], "any"),
    "RiskListings": o([
        { json: "counts", js: "counts", typ: 0 },
        { json: "hits", js: "hits", typ: a(r("IRiskListingFrontend")) },
    ], "any"),
    "IRiskListingFrontend": o([
        { json: "associatedAddresses", js: "associatedAddresses", typ: u(a(r("BasicLocation")), null) },
        { json: "detectedLanguage", js: "detectedLanguage", typ: u(null, "") },
        { json: "dynamicFields", js: "dynamicFields", typ: u(a(r("DynamicField")), null) },
        { json: "endDate", js: "endDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "entityAliases", js: "entityAliases", typ: u(a(u(null, r("StringWithTranslationFrontend"))), null) },
        { json: "entityName", js: "entityName", typ: u(null, r("StringWithTranslationFrontend")) },
        { json: "entityType", js: "entityType", typ: r("Type") },
        { json: "isKnownToBeOngoing", js: "isKnownToBeOngoing", typ: u(true, null) },
        { json: "listName", js: "listName", typ: u(null, r("StringWithTranslationFrontend")) },
        { json: "sourceUrl", js: "sourceUrl", typ: u(null, r("URLWithLabel")) },
        { json: "startDate", js: "startDate", typ: u(undefined, u(null, r("PartialDate"))) },
    ], "any"),
    "DynamicField": o([
        { json: "label", js: "label", typ: "" },
        { json: "values", js: "values", typ: a(u(null, r("StringWithTranslationFrontend"))) },
    ], "any"),
    "StringWithTranslationFrontend": o([
        { json: "original", js: "original", typ: u(null, "") },
        { json: "translated", js: "translated", typ: u(undefined, u(null, "")) },
    ], "any"),
    "SnapshotInformation": o([
        { json: "descriptors", js: "descriptors", typ: u(a(r("Descriptor")), null) },
        { json: "directorships", js: "directorships", typ: u(null, r("Officerships")) },
        { json: "employmentHistory", js: "employmentHistory", typ: u(null, r("EmploymentHistory")) },
        { json: "media", js: "media", typ: u(undefined, u(null, r("Media"))) },
        { json: "photoMontage", js: "photoMontage", typ: u(a(r("NewImageWithInformationSource")), null) },
        { json: "sectionError", js: "sectionError", typ: u(undefined, u(null, "")) },
        { json: "sourceDates", js: "sourceDates", typ: u(a(Date), null) },
    ], "any"),
    "Officerships": o([
        { json: "active", js: "active", typ: u(0, null) },
        { json: "earliestStart", js: "earliestStart", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "latestEnd", js: "latestEnd", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "total", js: "total", typ: u(0, null) },
    ], "any"),
    "EmploymentHistory": o([
        { json: "activeRoles", js: "activeRoles", typ: u(0, null) },
        { json: "averageTenure", js: "averageTenure", typ: u(undefined, u(null, r("YearsAndMonths"))) },
        { json: "endDate", js: "endDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "industryCounts", js: "industryCounts", typ: u(a(r("ValueTupleStringInt32")), null) },
        { json: "startDate", js: "startDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "totalJobs", js: "totalJobs", typ: u(0, null) },
    ], "any"),
    "ValueTupleStringInt32": o([
        { json: "item1", js: "item1", typ: "" },
        { json: "item2", js: "item2", typ: 0 },
    ], false),
    "Media": o([
        { json: "earliestDate", js: "earliestDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "flaggedDirect", js: "flaggedDirect", typ: u(0, null) },
        { json: "flaggedIndirect", js: "flaggedIndirect", typ: u(0, null) },
        { json: "latestDate", js: "latestDate", typ: u(undefined, u(null, r("PartialDate"))) },
        { json: "sources", js: "sources", typ: u(a(r("MediaSource")), null) },
        { json: "total", js: "total", typ: u(0, null) },
    ], "any"),
    "MediaSource": o([
        { json: "articleNumber", js: "articleNumber", typ: 0 },
        { json: "groupName", js: "groupName", typ: "" },
    ], "any"),
    "NewImageWithInformationSource": o([
        { json: "image", js: "image", typ: u(null, r("FrontendImage1")) },
        { json: "profileScore", js: "profileScore", typ: 3.14 },
        { json: "relevance", js: "relevance", typ: 3.14 },
        { json: "sourceIds", js: "sourceIds", typ: a("") },
        { json: "sources", js: "sources", typ: a(u(null, r("InformationSource"))) },
    ], "any"),
    "BestAvailability": [
        "Available",
        "UnavailableWithLogin",
        "UnavailableWithoutLogin",
        "Unknown",
    ],
    "InvestigationDetailLevelPerformed": [
        "None",
        "Some",
        "Top",
    ],
    "OneOfSocialMediaProfileTypesOnlineProfileTypesUnitEnum": [
        "AngelList",
        "Crunchbase",
        "Facebook",
        "Flickr",
        "Instagram",
        "LinkedIn",
        "Pinterest",
        "Reddit",
        "Soundcloud",
        "Twitter",
        "Undefined",
        "VK",
        "Wikidata",
        "Wikipedia",
        "Youtube",
    ],
    "SourceType": [
        "Article",
        "CommercialAggregator",
        "CorporateRecord",
        "OnlineProfile",
        "ScreeningProvider",
        "Unknown",
        "Webpage",
    ],
    "ContextType": [
        "Freetext",
        "Organisation",
        "Person",
        "SocialProfile",
        "WebAddress",
    ],
    "LocationType": [
        "RegisteredAddress",
        "Unknown",
    ],
    "Type": [
        "Organisation",
        "Person",
    ],
    "FlagEnum": [
        "AdverseMedia",
        "CloseAssociateOfRiskyEntity",
        "LocationRisk",
        "MarijuanaBusiness",
        "MoneyServicesBusiness",
        "Pep",
        "RelatedToSanctionedParty",
        "Sanction",
        "SpecialInterest",
        "StateOwned",
        "Watchlist",
    ],
    "FlagType": [
        "Sanctioned",
        "State Owned Company",
    ],
    "ListGroup": [
        "None",
        "Pep",
        "Sanction",
        "SpecialInterest",
        "WatchList",
    ],
    "Assessment": [
        "NoUserAssessment",
        "RightPerson",
        "WrongPerson",
    ],
    "EnquiryTenant": [
        "ProjectDX",
        "Xapien",
    ],
    "Assigner": [
        "EU",
        "HMTreasury",
        "TransparencyInternational",
    ],
    "RelationType": [
        "Correspondence",
        "Registered",
    ],
    "StatusTag": [
        "Active",
        "Inactive",
        "Unknown",
        "Warning",
    ],
    "Level": [
        "High",
        "Low",
        "Medium",
    ],
    "OneOfTextualSummaryErrorTypeUnitEnum": [
        "OpenAIOutage",
        "UnknownError",
    ],
    "Warning": [
        "InputTooLargeSummaryIncomplete",
        "Unknown",
    ],
    "ReportIssue": [
        "SubjectCouldNotBeIdentified",
    ],
    "SearchErrorInfoType": [
        "Corporate",
        "GeneralInternet",
        "GoogleKnowledgeGraph",
        "KeyPeople",
        "LinkedIn",
        "Pipl",
        "Screening",
    ],
    "FixedSource": [
        "Blog",
        "Entertainment",
        "NewsAndMedia",
        "Other",
        "SocialMedia",
    ],
};
