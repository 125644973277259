import { styled } from "@linaria/react";
import BaseHeading from "components/atoms/Heading";

import { fontSize } from "styles/fonts";
import { blue, standardColors } from "styles/colors";

const Container = styled.div`
  cursor: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${standardColors.white};
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
`;

const Heading = styled(BaseHeading)`
  font-size: ${fontSize.md};
`;

const Paragraph = styled.p`
  font-size: ${fontSize.sm};
  text-align: center;
  margin: 0;

  a {
    color: ${blue.link};
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
`;

const S = { Container, Heading, Paragraph, CloseButton };

export default S;
