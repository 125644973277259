import React from "react";

import S from "./styles";

type SectionHeaderProps = {
  toggleExpanded: () => void;
  title: string;
  isExpanded: boolean;
  sectionCount?: number;
  isSubjectSection?: boolean;
  potentialSectionCount?: number;
  isContractable?: boolean;
};

export const SectionHeader = (props: SectionHeaderProps) => {
  const {
    toggleExpanded,
    title,
    isExpanded,
    sectionCount,
    isSubjectSection,
    potentialSectionCount,
    isContractable = true
  } = props;

  return (
    <S.SectionHeaderOuterContainer>
      <S.SectionHeaderContainer
        className={[
          isExpanded ? "isExpanded" : "",
          isContractable ? "isContractable" : ""
        ].join(" ")}
        onClick={() => {
          if (isContractable) {
            toggleExpanded();
          }
        }}
      >
        {title}
        <S.SectionHeaderRightSideContainer>
          {!isSubjectSection ? (
            <>
              <S.SectionCount>
                {sectionCount !== undefined && sectionCount !== null
                  ? sectionCount
                  : ""}
                {potentialSectionCount ? ` of ${potentialSectionCount}` : ""}
              </S.SectionCount>
              {isContractable && (
                <S.SectionCollapseIcon
                  className={isExpanded ? "isExpanded" : ""}
                />
              )}
            </>
          ) : (
            ""
          )}
        </S.SectionHeaderRightSideContainer>
      </S.SectionHeaderContainer>
    </S.SectionHeaderOuterContainer>
  );
};

export default SectionHeader;
